import React, { memo } from 'react';
import Title from '@Components/Title';
import PropTypes from 'prop-types';
import Filter from '../Filter';

function HeaderTransaction({ onSearch }) {
  return (
    <div className="wrap-header">
      <div className="title-header">
        <Title name="Sổ lệnh" />
      </div>
      <div className="filter-header w-100">
        <Filter onSearch={onSearch} />
      </div>
    </div>
  );
}

HeaderTransaction.propTypes = {
  onSearch: PropTypes.func,
};

export default memo(HeaderTransaction, () => true);
