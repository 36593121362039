export const TABS = (data) => [
  {
    id: 0,
    name: 'Tất cả',
    value: '',
    bage: data?.total,
  },
  {
    id: 1,
    name: 'Hoạt động',
    value: 'active',
    bage: data?.active,
  },
  {
    id: 2,
    name: 'Cảnh báo',
    value: 'warning',
    bage: data?.warning,
  },
  {
    id: 3,
    name: 'Đang vi phạm',
    value: 'indebted',
    bage: data?.indebted,
  },
  {
    id: 4,
    name: 'Đang bị khoá',
    value: 'suspended',
    bage: data?.suspended,
  },
];
