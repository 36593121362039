/* eslint-disable react/jsx-wrap-multilines */
import React, { useCallback, useState, useRef } from 'react';
import { checkRole, formatNumber, formatVND } from '@Utils/helpers';
import Tooltip from '@Components/TooltipNew';
import FieldInput from '@Views/Client/Recharge/BodyTab/Request/FieldInput';
import ButtonSubmit from '@Components/ButtonSubmit';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import useCustomQuery from '@Utils/useCustomQuery';
import { wareHouseIndex } from '@Apis/admin/warehouse';
import { transfer } from '@Apis/admin/groupPurchase';
import { useDispatch, useSelector } from 'react-redux';
import { hideGlobalLoading, showGlobalLoading } from '@Lib/slices/uiSlice';
import { toastSuccess } from '@Utils/toastrHelper';
import DropdownSelect from '@Components/DropdownSelect';

export const columns = () => {
  return [
    {
      name: 'MÃ CK',
      cell: (row) => <div className="">{row.stock_id}</div>,
      center: true,
    },
    {
      name: 'TỔNG KL',
      cell: (row) => <div>{formatVND(row.quantity)}</div>,
      center: true,
    },
    {
      name: 'KL Khả dụng',
      cell: (row) => <div>{formatVND(row.available_quantity)}</div>,
      center: true,
    },
    {
      name: 'Giá TT',
      cell: (row) => <div>{formatNumber(row.current_price)}</div>,
      center: true,
    },
    {
      name: 'TỔNG GIÁ TRỊ',
      cell: (row) => {
        return <div>{formatVND(row.current_price * Math.abs(row.quantity) * row.stock.multiplier)}</div>;
      },
      center: true,
    },
    {
      name: '',
      cell: (row) => <CreateCK data={row} />,
      center: true,
    },
  ];
};

function CreateCK({ data: dataProps }) {
  const [formValue, setFormValue] = useState({});
  const [errors, setError] = useState({});
  const { warehouseId } = useParams();
  const dispatch = useDispatch();
  const refTooltip = useRef();

  const role = useSelector((state) => state.user.role);

  const { data: optionCK } = useCustomQuery(wareHouseIndex, { per_page: 100000 }, true);

  const onChange = useCallback((name, value) => {
    let valueSet = { [name]: value };
    if (name === 'type') {
      valueSet = { ...valueSet, quantity: 0 };
    }
    setError({});
    setFormValue((pre) => ({ ...pre, ...valueSet }));
  }, []);

  const onSubmit = useCallback(async () => {
    const errs = {};
    if (!formValue.target_purchase_account_id) {
      errs.target_purchase_account_id = 'Vui lòng chọn kho đích';
    }
    if (!formValue.quantity) {
      errs.quantity = 'Khối lượng chuyển phải lớn hơn 0';
    }
    if (!formValue.type) {
      errs.type = 'Vui lòng chọn loại';
    }
    if (errs.quantity || errs.target_purchase_account_id) {
      setError(errs);
      return;
    }
    const data = {
      purchase_account_id: warehouseId,
      target_purchase_account_id: formValue.target_purchase_account_id,
      stock_id: dataProps.stock_id,
      quantity: formValue.quantity,
      type: formValue.type.replace('total_', ''),
    };
    close();
    dispatch(showGlobalLoading());
    try {
      await transfer(data);
      toastSuccess('Chuyển thành công');
      // eslint-disable-next-line no-empty
    } catch (e) {}
    dispatch(hideGlobalLoading());
  }, [formValue]);

  const close = useCallback(() => {
    setFormValue({});
    refTooltip?.current?.handleBlur?.();
  }, []);

  if (!checkRole(role, 'purchase-account/transfer')) {
    return null;
  }

  return (
    <Tooltip
      ref={refTooltip}
      skipBlur
      stopBlur
      overlayClassName="tooltip-custom-wrap"
      trigger="click"
      content={
        <div style={{ width: 340 }}>
          <FieldInput
            className="input-field-date"
            errorMessage={errors.target_purchase_account_id}
            component={
              <DropdownSelect
                id="dropdownSelectTooltip"
                labelKey="name"
                labelValue="id"
                styleWrap={{ width: '100%' }}
                allowSearch
                name="target_purchase_account_id"
                placeholder="Kho đích"
                valueSelected={formValue?.target_purchase_account_id || ''}
                setValueSelected={onChange}
                styleMenu={{ maxHeight: 200, overflow: 'auto', width: '100%' }}
                options={optionCK?.filter((e) => e.id !== warehouseId)}
                labelShow={false}
              />
            }
            label="Kho đích"
            required
          />
          <FieldInput
            className="input-field-date"
            errorMessage={errors.type}
            component={
              <DropdownSelect
                id="dropdownSelectTooltip"
                styleWrap={{ width: '100%' }}
                allowSearch
                name="type"
                placeholder="Loại"
                valueSelected={formValue?.type || ''}
                setValueSelected={onChange}
                styleMenu={{ maxHeight: 200, overflow: 'auto', width: '100%' }}
                options={[
                  { label: `T+ (${formatNumber(dataProps?.total_t0)})`, value: 'total_t0' },
                  { label: `T1 (${formatNumber(dataProps?.total_t1)})`, value: 'total_t1' },
                  { label: `T2 (${formatNumber(dataProps?.total_t2)})`, value: 'total_t2' },
                  {
                    label: `Khả dụng (${formatNumber(dataProps?.total_t3)})`,
                    value: 'total_t3',
                  },
                ]}
                labelShow={false}
              />
            }
            label="Loại"
            required
          />
          <FieldInput
            errorMessage={errors.quantity}
            value={formValue?.quantity}
            onChange={(v) => onChange('quantity', v)}
            type="number"
            max={dataProps[formValue?.type]}
            label={`Khối lượng chuyển ( Tối đa: ${formatNumber(dataProps[formValue?.type])})`}
            placeholder="Nhập khối lượng chuyển"
          />
          <div className="d-flex justify-content-center align-items-center mt-2">
            <ButtonSubmit
              onClick={onSubmit}
              name="Chuyển"
              className="px-2"
              style={{ width: 170, height: 54, marginRight: 5 }}
              size="md"
              color="primary"
            />
            <ButtonSubmit
              onClick={close}
              name="Hủy"
              style={{ width: 170, height: 54, marginLeft: 5 }}
              className="px-2"
              size="md"
            />
          </div>
        </div>
      }
    >
      <div style={{ color: '#E43925' }}>
        Chuyển kho <img src="/images/warehouseSource/Arrow_Box_Right.svg" alt="" />
      </div>
    </Tooltip>
  );
}

CreateCK.propTypes = {
  data: PropTypes.any,
};
