import React, { useCallback, useState } from 'react';
import Title from '@Components/Title';
import DataTableBasic from '@Components/DataTableBasic';
import { useNavigate } from 'react-router-dom';
import propTypes from 'prop-types';
import useCustomQuery from '@Utils/useCustomQuery';
import { columns } from './columns';

function ListDashboard({ title, api, filterAdmin }) {
  const navigate = useNavigate();

  const [filter, setFilter] = useState({ sort_by: 'total_quantity', order: 'DESC' });

  const [page, setPage] = useState(1);
  const { data } = useCustomQuery(api, { page, per_page: 2000, ...filter, ...filterAdmin }, true);

  const setOrderBy = useCallback((column, type = '') => {
    setFilter((prevValues) => {
      return {
        ...prevValues,
        sort_by: column.sortField,
        order: type.toUpperCase(),
      };
    });
  }, []);

  return (
    <div className="w-100">
      <Title name={title} />
      <div style={{ marginTop: 16 }}>
        <DataTableBasic
          onSort={setOrderBy}
          isPageEffect
          setPage={setPage}
          page={page}
          data={data}
          columns={columns(navigate)}
        />
      </div>
    </div>
  );
}

ListDashboard.propTypes = {
  title: propTypes.string,
  api: propTypes.func,
  filterAdmin: propTypes.object,
};

export default ListDashboard;
