/* eslint-disable indent */
/* eslint-disable no-nested-ternary */
import React, { useCallback, useEffect, useState } from 'react';
import Title from '@Components/Title';
import { renderType } from '@Utils/tradeHelpers';
import { formatNumber } from '@Utils/helpers';
import { useNavigate } from 'react-router-dom';
import { list } from '@Apis/client/transaction';
import dayjs from 'dayjs';
import { toastError } from '@Utils/toastrHelper';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { io } from 'socket.io-client';

function TransactionDashboard({ accountId }) {
  const navigate = useNavigate();
  const productType = useSelector((state) => state.ui.productType);

  const [data, setData] = useState([]);

  useEffect(() => {
    if (!accountId) return;
    getData();

    const token = localStorage.getItem(process.env.TOKEN);
    const socket = io(`${process.env.BACKEND_SOCKET}/customer/transaction/update`, {
      query: { token },
      transports: ['websocket'],
    });
    socket.on('connect', () => {
      socket.emit('register', 'your-client-id', { customer_id: accountId });
    });
    socket.on('data', (dataSocket) => {
      if (!dataSocket) return;
      setData((pre) => {
        return pre.map((d) => (d.id === dataSocket.id ? dataSocket : d));
      });
    });

    return () => {
      socket.disconnect();
    };
  }, [accountId]);

  const getData = useCallback(() => {
    list({ page: 1, per_page: 6, customer_id: accountId })
      .then((res) => {
        setData(res.data);
      })
      .catch((e) => {
        toastError(e);
      });
  }, [accountId]);

  const renderIcon = useCallback((d) => {
    if (d.status === 'completed') {
      return <img style={{ marginTop: 4 }} src="/images/dashboard/Icon_success.svg" alt="" />;
    }
    if (d.status === 'pending' || d.status === 'created') {
      return <img style={{ marginTop: 4 }} src="/images/dashboard/Icon_pending.svg" alt="" />;
    }
    return <img style={{ marginTop: 4 }} src="/images/dashboard/Icon_close.svg" alt="" />;
  }, []);

  const renderStatus = useCallback((d) => {
    if (d.status === 'completed') {
      return <div style={{ color: '#14B13B' }}>Đã khớp</div>;
    }
    if (d.status === 'part_completed') {
      return <div style={{ color: '#14B13B' }}>Khớp một phần</div>;
    }
    if (d.status === 'pending') {
      return <div style={{ color: '#14B13B' }}>Đã duyệt</div>;
    }
    if (d.status === 'in_process') {
      return <div style={{ color: '#D37E17' }}>Chờ khớp</div>;
    }
    if (d.status === 'created') {
      return <div style={{ color: '#D37E17' }}>Chờ duyệt</div>;
    }
    if (d.status === 'cancelled') {
      return <div style={{ color: '#FC3400' }}>Huỷ</div>;
    }
    return <div style={{ color: '#84818A' }}>Hết hạn</div>;
  }, []);

  return (
    <div className="dashboard-transaction">
      <div className="d-flex" style={{ marginBottom: 20 }}>
        <div className="title-transaction-dashboard">
          <Title name="Giao dịch" style={{ fontSize: 18 }} />
        </div>
        <div
          className="title-transaction-dashboard justify-content-end"
          onClick={() => navigate(`/${productType}/transaction/list`)}
          role="button"
          tabIndex="0"
        >
          <span style={{ color: '#2F69B3' }}>Xem tất cả</span>
        </div>
      </div>
      {data.map((e, i) => (
        <div key={i.toString()} className="d-flex" style={{ marginBottom: 15 }}>
          <div className="title-transaction-dashboard">
            <div className="d-flex align-items-start">
              {renderIcon(e)}
              <div style={{ marginLeft: 9 }}>
                <div className="d-flex align-items-center">
                  <div style={{ fontSize: 14, fontWeight: 600, marginRight: 5 }}>{e.stock?.id}</div>
                  {renderType(e.type)}
                </div>
                <div style={{ fontSize: 12, color: '#84818A', marginTop: 3 }}>
                  {dayjs.unix(e?.created_at).format('DD/MM/YYYY HH:mm')}
                </div>
              </div>
            </div>
          </div>
          <div className="title-transaction-dashboard justify-content-end align-items-start">
            <div style={{ fontSize: 12, fontWeight: 500, textAlign: 'end' }}>
              <div style={{ fontSize: 12, fontWeight: 500, marginTop: 2 }}>
                {formatNumber(
                  e.dca_purchase_price + e.dca_sell_price <= 0
                    ? e.price
                    : e.type === 'buy'
                    ? e.dca_purchase_price
                    : e.dca_sell_price,
                  '--',
                )}{' '}
                (
                {formatNumber(
                  e.purchase_quantity + e.sell_quantity <= 0
                    ? e.quantity
                    : e.type === 'buy'
                    ? e.purchase_quantity
                    : e.sell_quantity,
                  '--',
                )}
                )
              </div>
              <div style={{ marginTop: 3 }}>{renderStatus(e)}</div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

TransactionDashboard.propTypes = {
  accountId: PropTypes.any,
};

export default TransactionDashboard;
