import dayjs from 'dayjs';

export const parsePositiveNumber = (expression) => {
    if (expression) {
        return expression.toString().replace(/[^0-9-]/g, '');
    }
    return '';
};

export const parseNumber = (expression) => {
    if (expression) {
        return expression.toString().replace(/[^0-9\\-\\.]+/g, '');
    }
    return '';
};

export const parsePrice = (expression) => {
    if (expression) {
        return expression
            .toString()
            .replace(/[^0-9-]/g, '')
            .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }
    return '';
};

export const getInputErrorsObject = (name, errors) => {
    const propName = name.substr(0, name.indexOf('.'));
    const childrenPropsName = name.substr(name.indexOf('.') + 1, name.length);
    if (!(propName in errors)) {
        if (childrenPropsName in errors) {
            return errors[childrenPropsName];
        }
        return;
    }

    return getInputErrorsObject(childrenPropsName, errors[propName]);
};

export const handleResponse = (res) => {
    const data = res.data;
    const {success, title} = data;
    if (success) {
        return res.data.data;
    }
    throw title;
};

export const getLastName = (fullName) => {
    try {
        if (!fullName) {
            return '';
        }
        const split = fullName.split(' ');
        if (split.length > 0) {
            return split[split.length - 1];
        }
    } catch (e) {
        return fullName;
    }
    return fullName;
};

export const formatVND = (price, dValue) => {
    price = Math.trunc(price);
    if (price || price === 0) {
        return price.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1.');
    }
    return dValue || 0;
};

export const formatNumber = (price, defaultValue, zeroIsEmpty, maximumFractionDigits) => {
    if (maximumFractionDigits === undefined) {
        maximumFractionDigits = 2;
    }
    if ((price && Number(price)) || price === 0) {
        // return Intl.NumberFormat('vi-VN').format(price);
        return Intl.NumberFormat('en-US', {maximumFractionDigits: maximumFractionDigits}).format(price);
    }
    if (defaultValue !== undefined) {
        return defaultValue;
    }
    if (zeroIsEmpty === true) {
        return '';
    }
    return 0;
};

export const bankFormat = (price, defaultValue) => {
    if (price || price === 0) {
        return `${price.toString().replace(/(.)(?=(\d{4})+$)/g, '$1 ')}`;
    }
    return defaultValue || price;
};

export function randomId(length) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

export const getDatesBetweenDates = (dateRange) => {
    const [fromDate, toDate] = dateRange.split('-');
    let dates = [];
    let theDate = dayjs.unix(fromDate);
    const endDate = dayjs.unix(toDate);
    while (theDate <= endDate) {
        dates = [...dates, theDate];
        theDate.add(1, 'day');
        theDate = dayjs(theDate).add(1, 'day');
    }
    return dates;
};

export const checkRole = (role, roles) => {
    return (
        process.env.DASHBOARD_TYPE !== 'admin' ||
        roles === '' ||
        role?.includes?.('*/*') ||
        role?.includes?.(roles) ||
        (Array.isArray(roles) && role?.find?.((ro) => roles?.includes?.(ro)))
    );
};
