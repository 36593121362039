import styled from 'styled-components';

export const FlatPickrWrapper = styled.div`
  border-radius: 4px;
  color: #828282;
  font-size: 12px;
  display: flex;
  align-items: center;
  cursor: pointer;
  background: #ffffff;
  border: 1px solid #ebeaed;
  .flatpickr-input {
    padding: 11px 12px 9px 12px;
  }
`;
