import { accountsHome } from '@Apis/client/home';
import { cancelTransaction, list } from '@Apis/client/transaction';
import DataTableBasic from '@Components/DataTableBasic';
import useCustomQuery from '@Utils/useCustomQuery';
import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import PropType from 'prop-types';
import { useSearchParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { renderPSType } from '@Utils/tradeHelpers';
import { TRADE_STATUS } from '@Views/Client/Transaction/constants';
import { formatNumber } from '@Utils/helpers';
import { NavLink } from 'reactstrap';
import { Edit, HighlightOff } from '@mui/icons-material';
import CustomeModal from '@Components/CustomeModal';
import useCustomMutation from '@Utils/useCustomMuation';
import { toastError, toastSuccess } from '@Utils/toastrHelper';
import { io } from 'socket.io-client';
import TradeHistoryPopup from '../Transaction/components/TradeHistoryPopup';

const columns = ({ setItemDelete, setItemEdit }) => {
  return [
    {
      name: 'MÃ HĐ',
      cell: (row) => <div className="">{row.stock_id}</div>,
      width: '100px',
      center: true,
    },
    {
      name: 'THỜI GIAN ĐẶT',
      cell: (row) => (
        <div className="">{dayjs.unix(row.created_at).format('HH:mm - DD/MM/YYYY')}</div>
      ),
      width: '120px',
      center: true,
    },
    {
      name: 'LOẠI GD',
      cell: (row) => renderPSType(row.type),
      width: '120px',
      center: true,
    },
    {
      name: 'TRẠNG THÁI',
      cell: (row) => renderStatus(row.status),
      width: '120px',
      center: true,
    },
    {
      name: 'KL ĐẶT',
      cell: (row) => (
        <div style={{ width: '100%', textAlign: 'right' }}>{formatNumber(row.quantity)}</div>
      ),
      width: '120px',
      center: true,
    },
    {
      name: 'GIÁ ĐẶT',
      cell: (row) => <div style={{ width: '100%', textAlign: 'right' }}>{renderPrice(row)}</div>,
      width: '120px',
      right: true,
    },
    {
      name: 'TG KHỚP',
      cell: (row) => <div className="">{renderTime(1, row?.lastTradingItemTime?.created_at)}</div>,
      width: '120px',
      center: true,
    },
    {
      name: 'GIÁ KHỚP',
      cell: (row) => (
          <div style={{width: '100%', textAlign: 'right' }}>
          {formatNumber(renderDCAPrice(row), '--')}
        </div>
      ),
      width: '120px',
      right: true,
    },
    {
      name: 'KL KHỚP',
      cell: (row) => {
        if (row.type === 'buy') {
          return (
            <div style={{ width: '100%', textAlign: 'right' }}>
              <span className={`${row.purchase_quantity === 0 ? '' : 'text-success'} `}>
                {formatNumber(row.purchase_quantity, '--')}
              </span>
            </div>
          );
        }
        return (
          <div style={{ width: '100%', textAlign: 'right' }}>
            <span className={`${row.sell_quantity === 0 ? '' : 'text-danger'} `}>
              {formatNumber(row.sell_quantity, '--')}
            </span>
          </div>
        );
      },
      width: '120px',
      center: true,
    },
    {
      name: 'HÀNH ĐỘNG',
      cell: (row) => <div className="">{renderAction({ row, setItemDelete, setItemEdit })}</div>,
      width: '200px',
    },
  ];
};

function isShowButtonCancel(row) {
  const { status } = row;
  if (
    status === 'created' ||
    status === 'pending' ||
    status === 'part_completed' ||
    status === 'in_process'
  ) {
    return true;
  }
  return false;
}

function renderTime(activeTabId, createdAt) {

  if(createdAt === null || createdAt === undefined) return '--';

  if (activeTabId === 1) {
    return dayjs.unix(createdAt).format('HH:mm');
  }
  return dayjs.unix(createdAt).format('HH:mm - DD/MM/YY');
}

function renderAction({ row, setItemDelete, setItemEdit }) {
  return (
    <div className="d-flex align-items-center justify-content-between">
      {isShowButtonCancel(row) && row.is_cancel_request ? (
        <span
          style={{ color: '#FC3400', fontWeight: 600, fontSize: 14, padding: '0.358rem 0.5rem' }}
        >
          Yêu cầu huỷ đang đợi duyệt
        </span>
      ) : row.status === 'created' ||
        row.status === 'pending' ||
        row.status === 'in_process' ||
        row.status === 'part_completed' ? (
        <>
          <NavLink
            onClick={() => setItemEdit(row)}
            className="text-success d-flex align-items-center"
          >
            <span className="mr-25">Sừa lệnh</span>
            <Edit className="text-success" fontSize="14px" />
          </NavLink>
          <NavLink onClick={() => setItemDelete(row)} className="text-danger">
            <span className="mr-25">Huỷ</span>
            <HighlightOff color="error" fontSize="14px" />
          </NavLink>
        </>
      ) : null}
    </div>
  );
}

function renderPrice({ price_type, price, edit_price, is_edit_request }) {
  if (price_type !== 'price') {
    return (
        <div className="uppercase" style={{ textAlign: 'center' }}>
          <div style={{ background: 'rgba(32, 201, 172, 0.1)' }}>
            <p className="font-size-14 font-weight-bolder py-25 px-1 text-info">{price_type}</p>
          </div>
        </div>
    );
  }
  if( edit_price === null || edit_price === undefined || edit_price < 0) {
    return (
        <div>
          {formatNumber(price)}
        </div>
    );
  }
  return (
      <>
        <div>{formatNumber(edit_price)}</div>
        {(edit_price) > 0 && edit_price !== price ? (
            <span style={{ fontSize: 12, opacity: 0.5, textDecoration: 'line-through', marginLeft: 5 }}>
          {formatNumber(price)}
        </span>
        ) : (
            ''
        )}
      </>
  );
}

function renderDCAPrice(row) {
  const { type, dca_sell_price, dca_purchase_price } = row;
  if (type === 'sell') {
    return dca_sell_price;
  }
  if (type === 'buy') {
    return dca_purchase_price;
  }
  return '--';
}

function renderStatus(status) {
  const item = TRADE_STATUS.find((item) => item.value === status);
  if (item) {
    const { color, label } = item;
    return <div style={{ color }}>{label}</div>;
  }
  return '';
}

function Status({ accountId }) {
  const [itemDelete, setItemDelete] = useState(null);
  const [itemEdit, setItemEdit] = useState(null);
  const [searchParams] = useSearchParams();

  const page = searchParams.get('page');
  const productType = useSelector((state) => state.ui.productType);
  const { data: dataAccounts } = useCustomQuery(accountsHome);
  const { mutate, isSuccess, error } = useCustomMutation(cancelTransaction, true);

  const [data, setData] = useState([]);

  const account = dataAccounts?.accounts?.find((e) => e.trade_type === productType);

  const getData = useCallback(async () => {
    const { data: dataCall } = await list({
      is_today: 1,
      page,
      customer_id: account?.id,
    });
    setData(dataCall);
  }, [page, account?.id]);

  useEffect(() => {
    getData();
  }, [getData]);

  useEffect(() => {
    if (!account?.id) return;
    const token = localStorage.getItem(process.env.TOKEN);
    const socket = io(`${process.env.BACKEND_SOCKET}/customer/transaction/update`, {
      query: { token },
      transports: ['websocket'],
    });
    socket.on('connect', () => {
      socket.emit('register', 'your-client-id', { customer_id: account?.id });
    });
    socket.on('data', (dataSocket) => {
      if (data.find((d) => d.id === dataSocket.id)) {
        setData((pre) => pre.map((d) => (d.id === dataSocket.id ? dataSocket : d)));
      }
    });
    return () => {
      socket.disconnect();
    };
  }, [account?.id]);

  useEffect(() => {
    if (isSuccess) {
      if (itemDelete.status === 'in_process') {
        toastSuccess('Yêu cầu hủy của bạn đã được gửi');
      } else {
        toastSuccess('Huỷ lệnh thành công');
      }
      setItemDelete(null);
    }
    if (error) {
      toastError(error);
      setItemDelete(null);
    }
  }, [isSuccess, error]);

  const onConfirmDelete = () => {
    mutate({ id: itemDelete.id, customer_id: account?.id });
  };

  return (
    <div className="mt-1" style={{ background: '#fff' }}>
      <div style={{ padding: '24px 24px 10px' }}>
        <div className="title">Trạng thái lệnh</div>
      </div>
      <DataTableBasic data={data} columns={columns({ setItemDelete, setItemEdit })} />
      <CustomeModal
        isOpen={itemDelete !== null}
        closeModal={() => setItemDelete(null)}
        buttonName="Đồng ý"
        confirm={onConfirmDelete}
        type="delete"
        mess={`Bạn muốn huỷ lệnh này ${itemDelete?.stock_id}`}
      />
      {itemEdit && (
        <TradeHistoryPopup
          customer_id={accountId}
          isOpen={itemEdit !== null}
          closeModal={() => setItemEdit(null)}
          refetch={getData}
          item={itemEdit}
        />
      )}
    </div>
  );
}

Status.propTypes = {
  accountId: PropType.any,
};

export default Status;
