import axiosService from '@Lib/axiosService';
import { handleResponse } from '@Utils/helpers';

let host;
if (process.env.DASHBOARD_TYPE === 'customer') {
  host = `${process.env.API_CUSTOMER_URL}/stock`;
} else {
  host = `${process.env.API_ADMIN_URL}/stock`;
}

export const stockIndex = async (query) => {
  const res = await axiosService.get(`${host}/list`, query);
  return handleResponse(res);
};

export const stockAsset = async (query) => {
  const res = await axiosService.get(`${host}/assets`, query);
  return handleResponse(res);
};

export const stockConfig = async (query) => {
  const res = await axiosService.get(`${host}/stock-config`, query);
  return handleResponse(res);
};

export const refStock = async (query) => {
  const res = await axiosService.get(`${host}/ref-stocks`, query);
  return handleResponse(res);
};
