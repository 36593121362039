import { formatNumber } from '@Utils/helpers';
import React from 'react';
import { NavLink } from 'reactstrap';

export const columns = ({ itemSelected, setItemSelected }) => {
  return [
    {
      name: 'Mã CK',
      cell: (row) => <div className="">{row.name}</div>,
      center: true,
    },
    {
      name: 'Giá hiện tại',
      cell: (row) => (
        <div style={{ width: '100%', textAlign: 'right' }}>{formatNumber(row.price)}</div>
      ),
      center: true,
    },
    {
      name: 'Tỷ lệ ký quỹ',
      cell: (row) => (
          <div style={{ width: '100%', textAlign: 'right' }}>{formatNumber(row.margin_rate)}%</div>
      ),
      center: true,
    },
    {
      name: 'RTT',
      cell: (row) => (
          <div style={{ width: '100%', textAlign: 'right' }}>{formatNumber(row.call_rate)}%</div>
      ),
      center: true,
    },
    {
      name: '',
      cell: (row) => (
        <NavLink className="text-success" onClick={() => setItemSelected(row)}>
          Mua
        </NavLink>
      ),
      center: true,
    },
  ];
};
