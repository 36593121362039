/* eslint-disable react/prop-types */
import React from 'react';

export const renderType = (type) => {
  return type === 'buy' ? (
    <div style={{ background: 'rgba(32, 201, 172, 0.1)', borderRadius: 4, width: 62 }}>
      <p className="text-success text-align-center font-size-14 font-weight-bolder py-25 px-1">
        Mua
      </p>
    </div>
  ) : (
    <div style={{ background: 'rgba(252, 52, 0, 0.1)', borderRadius: 4, width: 62 }}>
      <p className="text-danger text-align-center font-size-14 font-weight-bolder py-25 px-1">
        Bán
      </p>
    </div>
  );
};

export const renderConditionOrder = (type, price) => {
  return type == 'up' ? (
    <>
      <div style={{ background: 'rgba(32, 201, 172, 0.1)', borderRadius: 4, marginRight: 5, padding: '0 5px' }}>
        <p className="text-success text-align-center text-nowrap font-size-12 font-weight-bolder py-25">
          UP
        </p>
      </div>
      <span>{price}</span>
    </>
  ) : (
    <>
      <div style={{ background: 'rgba(252, 52, 0, 0.1)', borderRadius: 4, marginRight: 5, padding: '0 5px'  }}>
        <p className="text-danger text-align-center text-nowrap font-size-12 font-weight-bolder py-25">
          DOWN
        </p>
      </div>
      <span>{price}</span>
    </>
  );
};

export const renderPSType = (type) => {
  return type === 'buy' ? (
      <div style={{ background: 'rgba(32, 201, 172, 0.1)', borderRadius: 4, width: 82 }}>
        <p className="text-success text-align-center font-size-14 font-weight-bolder py-25 px-1">
          LONG
        </p>
      </div>
  ) : (
      <div style={{ background: 'rgba(252, 52, 0, 0.1)', borderRadius: 4, width: 82 }}>
        <p className="text-danger text-align-center font-size-14 font-weight-bolder py-25 px-1">
          SHORT
        </p>
      </div>
  );
};

export const renderProduct = (type) => {
  if (type === 'base') {
    return (
      <div
        style={{
          background: 'rgba(47, 105, 179, 0.1)',
          borderRadius: 4,
          width: 62,
          color: '#2F69B3',
        }}
      >
        <p className="text-align-center font-size-14 font-weight-bolder py-25 px-1">CS</p>
      </div>
    );
  }
  if (type === 'spot') {
    return (
      <div
        style={{
          background: 'rgba(236, 96, 46, 0.1)',
          borderRadius: 4,
          width: 62,
          color: '#EC602E',
        }}
      >
        <p className="text-align-center font-size-14 font-weight-bolder py-25 px-1">T+</p>
      </div>
    );
  }
  if (type === 't0') {
    return (
      <div
        style={{
          background: 'rgba(236, 96, 46, 0.1)',
          borderRadius: 4,
          width: 62,
          color: '#EC602E',
        }}
      >
        <p className="text-align-center font-size-14 font-weight-bolder py-25 px-1">T+</p>
      </div>
    );
  }
  if (type === 't2') {
    return (
      <div
        style={{
          background: 'rgba(236, 96, 46, 0.1)',
          borderRadius: 4,
          width: 62,
          color: '#EC602E',
        }}
      >
        <p className="text-align-center font-size-14 font-weight-bolder py-25 px-1">T+</p>
      </div>
    );
  }
  return (
    <div
      style={{ background: 'rgba(237, 28, 36, 0.1)', borderRadius: 4, width: 62, color: '#E43925' }}
    >
      <p className="text-align-center font-size-14 font-weight-bolder py-25 px-1">PS</p>
    </div>
  );
};

export const canEditTransactionTellers = (item) => {
  const { status, is_cancel_request, is_completed } = item;
  const statusEdit = ['pending', 'in_process'];
  if (status === 'part_completed' && !is_completed) {
    return true;
  }
  if (statusEdit.includes(status) || is_cancel_request) {
    return true;
  }
  return false;
};

export const renderWareHouseStatus = (status) => {
  return status === 'completed' ? (
    <div style={{ background: 'rgba(32, 201, 172, 0.1)', borderRadius: 4 }}>
      <p className="text-success font-size-14 font-weight-bolder py-25 px-1">Đã tất toán</p>
    </div>
  ) : (
    <div style={{ background: 'rgba(252, 52, 0, 0.1)', borderRadius: 4 }}>
      <p className="text-danger font-size-14 font-weight-bolder py-25 px-1">Chưa tất toán</p>
    </div>
  );
};

export const getQuantityMatchingTeller = (item) => {
  const { type, status, buyItems } = item;
  const objectType = {
    buy: 'purchase_quantity',
    sell: 'sell_quantity',
  };
  const propertyQuantity = objectType[`${type}`];
  return item[propertyQuantity];
};

export const getQuantityMatching = (item) => {
  const { type, status, buyItems } = item;
  const objectType = {
    buy: 'purchase_quantity',
    sell: 'sell_quantity',
  };
  const propertyQuantity = objectType[`${type}`];
  let buyedQuantity = 0;
  if ((status === 'part_completed' || status === 'completed') && buyItems && buyItems.length > 0) {
    buyedQuantity = buyItems.reduce((acc, current) => {
      acc += current[propertyQuantity];
      return acc;
    }, 0);
  }
  return item[propertyQuantity] + parseInt(buyedQuantity);
};

export const getQuantityMatchPartCompleted = (item) => {
  const { type, status, buyItems } = item;
  const objectType = {
    buy: 'purchase_quantity',
    sell: 'sell_quantity',
  };
  const propertyQuantity = objectType[`${type}`];
  let quantityMatched = 0;
  if (status === 'part_completed' && buyItems && buyItems.length > 0) {
    quantityMatched = buyItems.reduce((arr, current) => {
      arr += current[propertyQuantity];
      return arr;
    }, 0);
  }
  return item[propertyQuantity] + quantityMatched;
};
/**
 * use in trade teller
 */
export const getPriceMatching = (item) => {
  const { type, buyItems, status } = item;
  if (status === 'pending') {
    return item.price;
  }
  const objectType = {
    buy: 'dca_purchase_price',
    sell: 'dca_sell_price',
  };
  const propertyPrice = objectType[`${type}`];
  if (!buyItems || buyItems.length === 0) {
    return item[propertyPrice];
  }

  const totalPrice = buyItems.reduce((acc, current) => {
    acc += current[propertyPrice];
    return acc;
  }, 0);

  const price = (item[propertyPrice] + totalPrice) / (1 + parseInt(buyItems.length));
  return price;
};
/**
 * use in trade
 */
export const getAveragePrice = (item) => {
  const objectType = {
    buy: 'dca_purchase_price',
    sell: 'dca_sell_price',
  };
  const { dca_purchase_price, dca_sell_price, type, subTransactionItems } = item;

  if (type === 'buy') {
    return dca_purchase_price;
  }
  return dca_sell_price;

  // const propertyPrice = objectType[`${type}`];
  // if (subTransactionItems && subTransactionItems.length === 0) {
  //   return item[propertyPrice];
  // }
  // const totalPrice = subTransactionItems.reduce((acc, current) => {
  //   acc += current[propertyPrice];
  //   return acc;
  // }, 0);
  // const average = totalPrice / subTransactionItems.length;
  // return average;
};
