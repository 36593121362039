/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/no-unused-class-component-methods */
import { Modal } from 'reactstrap';
import React, { Component } from 'react';
import Title from '@Components/Title';
import { Clear } from '@mui/icons-material';
import P from 'prop-types';
import DropdownStep from '../DropdownStep';
import FormUpdate from './FormUpdate';

const valueDrop = {
  1: 'unpaid',
  2: 'provisional',
  3: 'paid',
};
class ModalDetail extends Component {
  constructor(props) {
    super(props);
    this.state = { isOpen: false, data: undefined, isDetail: false };
  }

  close = () => {
    this.setState({ isOpen: false, data: undefined });
  };

  renderStatusInit = (data) => {
    if (data.status === 'paid' && data.is_settlement === 1) {
      return 3;
    }
    if (data.is_settlement === 1) {
      return 2;
    }
    return 1;
  };

  open = (data, isDetail) => {
    if (!data) {
      return this.setState({
        isOpen: true,
        data: undefined,
        isDetail: false,
      });
    }
    this.setState({
      isOpen: true,
      data: {
        ...data,
        statusInit: this.renderStatusInit(data),
        optionsSelect:
          data.is_settlement === 1 && data.status !== 'paid' ? 'provisional' : data.status,
      },
      isDetail,
    });
  };

  render() {
    const { isOpen, data, isDetail } = this.state;
    const { reload } = this.props;
    return (
      <Modal isOpen={isOpen} centered wrapClassName="custom-modal-detail">
        <div className="content-modal-detail">
          <div className="d-flex align-items-center">
            <Title
              style={{ fontSize: 32, fontWeight: 600 }}
              name={data ? 'Chi tiết thông tin' : 'Thêm kho huy động'}
            />
            {isDetail ? (
              <img
                onClick={() => this.setState({ isDetail: false })}
                style={{ marginLeft: 20, cursor: 'pointer' }}
                src="/images/warehouseSource/edit.svg"
                alt=""
              />
            ) : null}
          </div>
          {data && (
            <div className="d-flex justify-content-end align-items-center">
              <DropdownStep
                // onChange={(v) => {
                //   if (isDetail) {
                //     return;
                //   }
                //   if (v.id < data.statusInit) {
                //     return this.setState({
                //       data: { ...data, optionsSelect: valueDrop[data.statusInit] },
                //     });
                //   }
                //   this.setState({ data: { ...data, optionsSelect: v.value } });
                // }}
                labelKey="title"
                labelValue="value"
                value={data?.optionsSelect || 'unpaid'}
                steps={[
                  {
                    id: 1,
                    color: '#EC602E',
                    title: 'Chưa tất toán',
                    value: 'unpaid',
                    disabled: true,
                  },
                  {
                    id: 2,
                    color: '#84818A',
                    title: 'Đã tất toán',
                    value: 'provisional',
                    disabled: true,
                  },
                  {
                    id: 3,
                    color: '#14B13B',
                    title: 'Đã thanh toán',
                    value: 'paid',
                    disabled: true,
                  },
                ]}
              />
              <div
                className="icon-clear-modal"
                style={{ marginLeft: 24, cursor: 'pointer' }}
                onClick={this.close}
                role="button"
                tabIndex="0"
              >
                <Clear size={14} style={{ color: '#84818A' }} />
              </div>
            </div>
          )}
        </div>
        <FormUpdate
          reload={reload}
          data={data}
          isUpdate={!!data}
          isDetail={isDetail}
          onClose={this.close}
        />
      </Modal>
    );
  }
}

ModalDetail.propTypes = {
  reload: P.func,
};

export default ModalDetail;
