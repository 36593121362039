import axiosService from '@Lib/axiosService';
import { handleResponse } from '@Utils/helpers';

let url = '';
if (process.env.DASHBOARD_TYPE === 'admin') {
  url = `${process.env.API_ADMIN_URL}/stats`;
}

export const overview = async (query) => {
  const res = await axiosService.get(`${url}/overview`, query);
  return handleResponse(res);
};
