import React from 'react';
import Info from './components/Info';
import Transaction from './components/Transaction';
import Stock from './components/Stock';

function WarehouseAccountDetail() {
  return (
    <div className="content-wrapper">
      <Info />
      <div className="mb-2">
        <Transaction />
      </div>
      <div className="mb-2">
        <Stock />
      </div>
    </div>
  );
}

WarehouseAccountDetail.propTypes = {};

export default WarehouseAccountDetail;
