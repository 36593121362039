/* eslint-disable react/jsx-wrap-multilines */
import { refStock, stockIndex } from '@Apis/client/stock';
import ButtonSubmit from '@Components/ButtonSubmit';
import DropdownSelect from '@Components/DropdownSelect';
import { Checkbox } from '@mui/material';
import { formatNumber } from '@Utils/helpers';
import useCustomMutation from '@Utils/useCustomMuation';
import { renderPrice } from '@Views/Admin/CustomerDetail/component/Assets/TransactionTab3';
import { trade, warningTransaction } from '@Apis/client/trade';
import React, { useEffect, useState } from 'react';
import { toastError, toastSuccess } from '@Utils/toastrHelper';
import { Label, Modal } from 'reactstrap';
import { secondOverview } from '@Apis/client/account';
import PropType from 'prop-types';
import { useSelector } from 'react-redux';
import FieldInput from '../Recharge/BodyTab/Request/FieldInput';
import ManagementPosition from './ManagementPosition';
import Status from './Status';
import './styles.scss';

const renderPriceChange = (price, last_price) => {
  const changePrice = price - last_price;
  const changePriceAbs = changePrice < 0 ? changePrice * -1 : changePrice;
  const perChange = ((changePriceAbs * 100) / price).toFixed(2);

  return (
    <>
      <span style={{ color: changePrice >= 0 ? '#14B13B' : '#FC3400' }}>{formatNumber(price)}</span>
      <span
        style={{ fontSize: 13, color: changePrice >= 0 ? '#14B13B' : '#FC3400', marginLeft: 8 }}
      >
        {`${changePrice >= 0 ? '+' : '-'}${formatNumber(changePriceAbs)}`}
      </span>
      <span
        style={{ marginLeft: 5, color: changePrice >= 0 ? '#14B13B' : '#FC3400', fontSize: 13 }}
      >
        ({`${changePrice >= 0 ? '+' : '-'}${formatNumber(perChange)}%`})
      </span>
    </>
  );
};

function TransactionPS({ accountId }) {
  const [priceType, setPriceType] = useState('price');
  const [conditionType, setConditionType] = useState('up');
  const [price, setPrice] = useState(0);
  const [priceCondition, setPriceCondition] = useState(0);
  const [quantity, setQuantity] = useState(0);
  const [dataSecondOverview, setDataSecondOverview] = useState({});
  const [dataRefConfig, setDataRefConfig] = useState([]);
  const [dataStock, setDataStock] = useState([]);
  const [dataWarning, setDataWarning] = useState({});
  const [openWarning, setOpenWarning] = useState({ type: '' });
  const [typeConditionSelect, setTypeConditionSelecte] = useState(false);
  const [itemSelect, setItemSelect] = useState();
  const productType = useSelector((state) => state.ui.productType);
  const { mutate, isSuccess: isSuccessLock, error: errorLock } = useCustomMutation(trade, true);

  const loadData = () => {
    refStock({ customer_id: accountId })
      .then((res) => {
        setDataRefConfig(res);
      })
      .catch(toastError);

    stockIndex({
      customer_id: accountId,
      per_page: 1000000000,
      trade_type: productType,
    })
      .then((res) => {
        setDataStock(res.data);
      })
      .catch(toastError);

    secondOverview({ customer_id: accountId })
      .then((res) => {
        setDataSecondOverview(res);
      })
      .catch(toastError);
  };

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      loadData();
    }, 5000);
    return () => {
      clearInterval(interval);
    };
  }, [loadData]);

  useEffect(() => {
    if (isSuccessLock) {
      toastSuccess('Yêu cầu đặt lệnh thành công');
    }
    if (errorLock) {
      toastError(errorLock);
    }
  }, [isSuccessLock, errorLock]);

  useEffect(() => {
    if (itemSelect) {
      const newItemSelect = dataStock.filter((e) => e.id == itemSelect.id)?.[0];
      setItemSelect(newItemSelect);
    } else {
      setItemSelect(dataStock?.[0]);
    }
  }, [dataStock]);

  useEffect(() => {
    warningTransaction({
      stock_id: itemSelect?.id,
      trade_type: productType,
      customer_id: accountId,
    })
      .then((res) => {
        setDataWarning(res);
      })
      .catch(toastError);
  }, [itemSelect]);

  useEffect(() => {
    if (priceType !== 'price') {
      setPrice(itemSelect?.price);
    }
  }, [priceType]);

  useEffect(() => {
    setPrice(itemSelect?.price);
  }, [itemSelect?.price]);

  const onShowWarning = (type) => {
    setOpenWarning({ type });
  };

  const onClickBtnChangePrice = (type) => {
    const changeValue = parseFloat(0.1);
    let newPrice;
    if (type == 'insc') {
      newPrice = (parseFloat(price) - changeValue).toFixed(1);
    } else if (type == 'desc') {
      newPrice = (parseFloat(price) + changeValue).toFixed(1);
    }

    setPrice(newPrice);
  };

  const onClickBtnChangePriceCondition = (type) => {
    const changeValue = parseFloat(0.1);
    let newPrice;
    if (type === 'insc') {
      newPrice = (parseFloat(priceCondition) - changeValue).toFixed(1);
    } else if (type === 'desc') {
      newPrice = (parseFloat(priceCondition) + changeValue).toFixed(1);
    }

    setPriceCondition(newPrice);
  };

  const onClickBtnChangeQtt = (type) => {
    const changeValue = parseInt(1);
    let newQtt;
    if (type === 'insc') {
      newQtt = (parseInt(quantity) - changeValue).toFixed(0);
      newQtt = newQtt < 0 ? 0 : newQtt;
    } else if (type === 'desc') {
      newQtt = (parseInt(quantity) + changeValue).toFixed(0);
    }

    setQuantity(newQtt);
  };

  const onSubmit = (type) => {
    if (!itemSelect?.id) return;
    setOpenWarning({});
    const data = {
      stock_id: itemSelect.id,
      price,
      quantity,
      type,
      trade_type: productType,
      price_type: priceType,
      customer_id: accountId,
      condition_transaction: {
        type: conditionType,
        price: priceCondition,
      },
    };
    if (!typeConditionSelect) {
      delete data.condition_transaction;
    }
    mutate(data, {
      onSuccess: loadData,
    });
  };

  return (
    <div className="content-wrapper transaction-wrapper transaction-wrapper-ps">
      <div className="d-flex align-items-center justify-content-between header-body mb-2">
        <p className="font-size-28 font-weight-bolder">Đặt lệnh</p>
      </div>
      <div className="form-management-assest">
        <div className="form-management" style={{ background: '#fff' }}>
          <div style={{ padding: '24px 24px 0' }}>
            <div className="title">Quản lý tài sản</div>
            <div className="list-item-flex mt-1" style={{ gap: 0 }}>
              <div className="list-data-item" style={{ width: '50%' }}>
                <div className="fs-16">Phí GD/GD qua đêm:</div>
                <div className="fw-600 fs-16 item-value" style={{ whiteSpace: 'pre-wrap' }}>
                  {`${formatNumber(
                    dataSecondOverview?.today_transaction_fee,
                    '--',
                  )} / ${formatNumber(dataSecondOverview?.today_interest_fee, '--')}`}
                </div>
              </div>
              <div className="list-data-item" style={{ width: '50%' }}>
                <div className="fs-16">
                  Lãi lỗ vị thế (<span style={{ color: '#14B13B' }}>mở</span>/
                  <span style={{ color: '#ED1C24' }}>đóng</span>):
                </div>
                <div className="fw-600 fs-16 item-value">
                  <span style={{ color: '#14B13B' }}>
                    {formatNumber(dataSecondOverview?.profit_buy, '--', true, 0)}
                  </span>
                  {' / '}
                  <span style={{ color: '#ED1C24' }}>
                    {formatNumber(dataSecondOverview?.profit_sell, '--', true, 0)}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div
            className="list-item-flex mt-1 justify-content-between"
            style={{ padding: '0 24px 24px', gap: 0 }}
          >
            <div className="list-data-item" style={{ width: '33%' }}>
              <div className="fs-16">Tổng lãi lỗ:</div>
              <div className="fw-600 fs-16 item-value">
                {renderPrice(dataSecondOverview?.total_profit, 16)}
              </div>
            </div>
            <div className="list-data-item" style={{ width: '33%' }}>
              <div className="fs-16">Tổng tài sản:</div>
              <div className="fw-600 fs-16 item-value">
                {formatNumber(dataSecondOverview?.current_nav)}
              </div>
            </div>
            <div className="list-data-item" style={{ width: '33%' }}>
              <div className="fs-16">Tiền có thể rút:</div>
              <div className="fw-600 fs-16 item-value">
                {formatNumber(
                  dataSecondOverview?.withdraw_available > 0
                    ? dataSecondOverview?.withdraw_available
                    : 0,
                )}
              </div>
            </div>
          </div>
          <div className="" style={{ borderTop: '1px dashed #B6B4BA', padding: 24 }}>
            <div className="title">Cảnh báo lệnh</div>
            <div className="list-item-flex">
              <div className="list-item-flex mt-1" style={{ width: '100%', gap: 0 }}>
                <div className="list-data-item" style={{ width: '33%' }}>
                  <div className="fs-16">Bổ sung ký quỹ:</div>
                  <div className="fw-600 fs-16 item-value" style={{ color: '#ED1C24' }}>
                    {formatNumber(
                      dataSecondOverview?.withdraw_available < 0
                        ? dataSecondOverview?.withdraw_available
                        : 0,
                      '--',
                    )}
                  </div>
                </div>
                <div className="list-data-item" style={{ width: '33%' }}>
                  <div className="fs-16">Ký quỹ tối thiểu:</div>
                  <div className="fw-600 fs-16 item-value" style={{ color: '#ED1C24' }}>
                    {formatNumber(dataSecondOverview?.margin_require_amount, '--')}
                  </div>
                </div>
                <div className="list-data-item" style={{ width: '33%' }}>
                  <div className="fs-16">TLKQ hiện tại:</div>
                  <div className="fw-600 fs-16 item-value">
                    {`${formatNumber(dataSecondOverview?.current_margin_percent, '--')}%`}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="form-management">
          <div style={{ padding: 24 }}>
            <div className="d-flex justify-content-between align-items-center">
              <div className="title">Đặt lệnh</div>
              <DropdownSelect
                labelKey="name"
                labelValue="id"
                options={dataStock || []}
                valueSelected={itemSelect?.id}
                setValueSelected={(_, v) => {
                  const item = dataStock?.find((e) => e.id === v);
                  setItemSelect(item);
                }}
                style={{ width: 150, background: '#fff', borderWidth: 0, height: 42 }}
                labelShow={false}
              />
            </div>
            <div className="list-item-mck mt-1">
              {dataRefConfig?.map((el) => (
                <div className="item-mck" key={el.id}>
                  <div className="fw-600">{el.name}</div>
                  <div className="">{renderPriceChange(el.price, el.ref_price)}</div>
                </div>
              ))}
              <div className="item-mck">
                <div className="fw-600">{itemSelect?.name}</div>
                <div className="">
                  {renderPriceChange(itemSelect?.price, itemSelect?.ref_price)}
                </div>
              </div>
              <div className="item-mck">
                <div className="fw-600">Sàn:</div>
                <div className="" style={{ color: '#02a4ff' }}>
                  {formatNumber(itemSelect?.floor_price)}
                </div>
              </div>
              <div className="item-mck">
                <div className="fw-600">TC:</div>
                <div className="" style={{ color: '#d37e18' }}>
                  {formatNumber(itemSelect?.ref_price)}
                </div>
              </div>
              <div className="item-mck">
                <div className="fw-600">Trần:</div>
                <div className="" style={{ color: '#bd2dba' }}>
                  {formatNumber(itemSelect?.ceiling_price)}
                </div>
              </div>
            </div>
            <div className="mt-1">
              <div className="d-flex">
                <FieldInput
                  label="Giá đặt"
                  style={{ width: 240 }}
                  component={
                    <div className="d-flex">
                      <DropdownSelect
                        options={[
                          {
                            label: 'Giá',
                            value: 'price',
                          },
                          {
                            label: 'ATO',
                            value: 'ato',
                          },
                          {
                            label: 'ATC',
                            value: 'atc',
                          },
                        ]}
                        valueSelected={priceType}
                        setValueSelected={(_, v) => setPriceType(v)}
                        style={{
                          width: 80,
                          background: '#fff',
                          borderTopRightRadius: 0,
                          borderBottomRightRadius: 0,
                          textTransform: 'none',
                          borderWidth: 0,
                          height: 42,
                          borderRightWidth: 1,
                          borderStyle: 'solid',
                          borderColor: '#E3E1E5',
                        }}
                        labelShow={false}
                      />
                      {priceType == 'price' ? (
                        <div
                          className="wrap-btn-change bg-white d-flex"
                          style={{ width: '100%', height: 42 }}
                        >
                          <a
                            className="btn btn-change"
                            onClick={() => onClickBtnChangePrice('insc')}
                          >
                            -
                          </a>
                          <FieldInput
                            styleInput={{
                              borderRadius: 0,
                              width: 90,
                              border: 'none',
                              marginRight: 1,
                            }}
                            type="number"
                            placeholder="Nhập giá"
                            value={price}
                            onChange={setPrice}
                          />
                          <a
                            className="btn btn-change"
                            onClick={() => onClickBtnChangePrice('desc')}
                          >
                            +
                          </a>
                        </div>
                      ) : null}
                    </div>
                  }
                  required
                />
                <FieldInput
                  label="Khối lượng"
                  style={{ marginLeft: 10, width: 120 }}
                  component={
                    <div
                      className="wrap-btn-change bg-white d-flex"
                      style={{
                        height: 42,
                      }}
                    >
                      <a className="btn btn-change" onClick={() => onClickBtnChangeQtt('insc')}>
                        -
                      </a>
                      <FieldInput
                        isInt
                        zeroIsEmpty
                        type="number"
                        placeholder="0"
                        value={quantity}
                        styleInput={{
                          borderRadius: 0,
                          width: 68,
                          border: 'none',
                          marginRight: 1,
                        }}
                        onChange={setQuantity}
                      />
                      <a className="btn btn-change" onClick={() => onClickBtnChangeQtt('desc')}>
                        +
                      </a>
                    </div>
                  }
                />
              </div>
              <div style={{ marginTop: 16 }}>
                <div
                  className="d-flex align-items-center"
                  style={{ marginBottom: 2, width: 'max-content', marginLeft: -10 }}
                >
                  <Checkbox
                    id="checkbox-condition"
                    onChange={(ev) => {
                      setTypeConditionSelecte(ev.target.checked);
                    }}
                    size="small"
                    checked={typeConditionSelect}
                  />
                  <Label
                    style={{ marginBottom: 0, color: '#2E2C34', fontSize: 16 }}
                    for="checkbox-condition"
                  >
                    Lệnh điều kiện
                  </Label>
                </div>
                {typeConditionSelect ? (
                  <FieldInput
                    component={
                      <div className="d-flex">
                        <DropdownSelect
                          options={[
                            {
                              label: 'Up',
                              value: 'up',
                            },
                            {
                              label: 'Down',
                              value: 'down',
                            },
                          ]}
                          valueSelected={conditionType}
                          setValueSelected={(_, v) => setConditionType(v)}
                          style={{
                            width: 80,
                            background: '#FFFFFF',
                            borderTopRightRadius: 0,
                            borderBottomRightRadius: 0,
                            textTransform: 'none',
                            height: 42,
                            borderWidth: 0,
                            borderRightWidth: 1,
                            borderStyle: 'solid',
                          }}
                          labelShow={false}
                        />

                        <div className="wrap-btn-change bg-white d-flex" style={{ height: 42 }}>
                          <a
                            className="btn btn-change"
                            onClick={() => onClickBtnChangePriceCondition('insc')}
                          >
                            -
                          </a>
                          <FieldInput
                            styleInput={{
                              borderRadius: 0,
                              width: 90,
                              border: 'none',
                              marginRight: 1,
                            }}
                            type="number"
                            placeholder="Nhập giá"
                            value={priceCondition}
                            onChange={setPriceCondition}
                          />
                          <a
                            className="btn btn-change"
                            onClick={() => onClickBtnChangePriceCondition('desc')}
                          >
                            +
                          </a>
                        </div>
                      </div>
                    }
                    required
                  />
                ) : null}
              </div>
              <div className="d-flex" style={{ gap: 16, marginTop: 16 }}>
                <div className="d-flex">
                  Max Long:{' '}
                  <span className="fw-600" style={{ color: '#14B13B', marginLeft: 6 }}>
                    {formatNumber(dataWarning?.max_long, 0)}
                  </span>
                </div>
                <div className="d-flex">
                  Max Short:{' '}
                  <span className="fw-600" style={{ color: '#ED1C24', marginLeft: 6 }}>
                    {formatNumber(dataWarning?.max_short, 0)}
                  </span>
                </div>
              </div>
              <div className="d-flex mt-1" style={{ gap: 16 }}>
                <ButtonSubmit
                  name="LONG"
                  wrapClassName="button-long"
                  size="md"
                  onClick={() => onShowWarning('buy')}
                />
                <ButtonSubmit
                  style={{ width: 200 }}
                  name="SHORT"
                  wrapClassName="button-short"
                  size="md"
                  color="danger"
                  onClick={() => onShowWarning('sell')}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <ManagementPosition stockId={itemSelect?.id} />
      <Status accountId={accountId} />
      <Modal wrapClassName="modal-wrap-setting" isOpen={!!openWarning.type} centered>
        <div className="modal-setting">
          <div style={{ textAlign: 'center' }}>
            <h3 className="uppercase mb-1">Xác nhận giao dịch</h3>
          </div>
          <div className="mt-1 text-align-center">
            Bạn có thật sự muốn đặt lệnh{' '}
            <span
              style={{
                color: openWarning.type === 'sell' ? 'rgb(252, 52, 0)' : 'rgb(20, 177, 59)',
                marginLeft: 5,
                fontWeight: '700',
              }}
            >
              {openWarning.type === 'sell' ? 'SHORT' : 'LONG'}
            </span>
          </div>
          <div className="mt-1">
            <div className="d-flex mt-1 fw-600">
              <div style={{ width: 170 }}>Mã Hợp Đồng:</div>
              <div className="ml-2">{itemSelect?.id}</div>
            </div>
            <div className="d-flex mt-1 fw-600">
              <div style={{ width: 170 }}>Khối lượng:</div>
              <div className="ml-2">{quantity}</div>
            </div>
            <div className="d-flex mt-1 fw-600">
              <div style={{ width: 170 }}>Giá đặt:</div>
              <div className="ml-2 uppercase">
                {priceType !== 'price' ? priceType : formatNumber(price)}
              </div>
            </div>
          </div>
          <div className="mt-3 d-flex align-items-center justify-content-center">
            <ButtonSubmit
              onClick={() => onSubmit(openWarning.type)}
              name="Xác nhận"
              className="px-2"
              size="md"
              color="primary"
            />
            <ButtonSubmit
              onClick={() => setOpenWarning({})}
              outline
              name="Huỷ"
              className="px-2"
              wrapClassName="ml-1"
              size="md"
              color="primary"
              ghost
            />
          </div>
        </div>
      </Modal>
    </div>
  );
}

TransactionPS.propTypes = {
  accountId: PropType.any,
};

export default TransactionPS;
