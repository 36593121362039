/* eslint-disable import/no-cycle */
import React, { useRef, useState, useEffect } from 'react';
import Breadcrumb from '@Components/Breadcrumb';
import { Button } from 'reactstrap';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { customerDetail, customerLock, customerOverviewAccount } from '@Apis/admin/customer';
import useCustomQuery from '@Utils/useCustomQuery';
import NavigationBar from '@Components/NavigationBar';
import { Lock } from '@mui/icons-material';
import CustomeModal from '@Components/CustomeModal';
import useCustomMutation from '@Utils/useCustomMuation';
import { toastError, toastSuccess } from '@Utils/toastrHelper';
import Modify from './component/Modify';
import { TABS } from './constants';
import Assets from './component/Assets';
// import Activities from './component/Activities';
import './styles.scss';
import DashboardCustomer from './component/Dashboard';
import SupportConfig from './component/SupportConfig';
import AssetManager from '@Views/Client/AssetManager';
import { TYPE_CS, TYPE_PS, TYPE_TPLUS } from '@Components/Layouts/PrivateLayout';
import { useTitle } from '@Utils/useTitle';

function CustomerDetail() {
  useTitle('Chi tiết khách hàng');

  const { customerId } = useParams();
  const [activeTabId, setActiveTabId] = useState(0);
  const [userLock, setUserLock] = useState(null);
  const [searchParams] = useSearchParams();
  const tradeType = searchParams.get('trade_type');

  useEffect(() => {
    if (tradeType) {
      const tabId = tradeType == TYPE_PS ? 3 : tradeType == TYPE_TPLUS ? 2 : tradeType == TYPE_CS ? 1 : 0;
      setActiveTabId(tabId);
    }
  }, [tradeType]);

  const { data: dataDetail, refetch } = useCustomQuery(customerDetail, { id: customerId }, true, {
    enabled: !!customerId,
  });

  const { data } = customerId ? useCustomQuery(customerOverviewAccount, { customer_id: customerId }) : {};

  const { accounts } = data || {};

  const {
    mutate: mutateLock,
    isSuccess: isSuccessLock,
    error: errorLock,
  } = useCustomMutation(customerLock, true);

  const navigate = useNavigate();
  const refForm = useRef();
  const submitForm = () => {
    refForm.current.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
  };

  const onConfirm = () => {
    const { id, status } = dataDetail;
    mutateLock({
      id,
      is_lock: status === 'active' ? true : false,
    });
  };

  useEffect(() => {
    if (isSuccessLock) {
      const { status } = dataDetail;
      const state = status === 'active' ? 'Khoá' : 'Mở khoá';
      toastSuccess(`${state} khách hàng thành công`);
      navigate('/customer');
    }
    if (errorLock) {
      toastError(errorLock);
    }
  }, [isSuccessLock, errorLock]);

  return (
    <div className="content-wrapper detail-customer">
      {dataDetail ? (
        <>
          <div className="d-flex align-items-center justify-content-between header-body mb-2">
            <Breadcrumb
              childName="Chi tiết khách hàng"
              title={
                <span className="uppercase">{`${dataDetail.username} (ID: ${
                  dataDetail.code || '---'
                })`}</span>
              }
            />
            <div className="d-flex align-items-center gap-20">
              {activeTabId === 4 && (
                <Button color="primary" className="font-size-14 " onClick={() => submitForm()}>
                  Lưu thay đổi
                </Button>
              )}
              <Button
                color={`${dataDetail.status === 'active' ? 'secondary' : 'success'}`}
                className="font-size-14"
                onClick={() => setUserLock(dataDetail)}
              >
                <Lock fontSize="14px" className="mr-50" />
                {dataDetail.status === 'active' ? 'Khoá' : 'Mở khoá'}
              </Button>
            </div>
          </div>
          <div className="d-line" />
          <div className="mb-2">
            <NavigationBar tabs={TABS} activeTab={activeTabId} setActiveTab={setActiveTabId} />
          </div>
          {activeTabId === 0 && <DashboardCustomer setActiveTab={setActiveTabId} />}
          {activeTabId === 1 && <Assets accounts={accounts} type={TYPE_CS} />}
          {activeTabId === 2 && <AssetManager accounts={accounts} type={TYPE_TPLUS} />}
          {activeTabId === 3 && <AssetManager accounts={accounts} type={TYPE_PS} />}
          {activeTabId === 4 && <Modify refForm={refForm} item={dataDetail} refetch={refetch} />}
          {activeTabId === 5 && <SupportConfig item={dataDetail} refetch={refetch} />}
        </>
      ) : (
        <>
          <div className="d-flex align-items-center justify-content-between header-body mb-2">
            <Breadcrumb childName="Thêm khách hàng" title="Thêm khách hàng" />
            <div className="d-flex align-items-center gap-20">
              <Button color="primary" className="font-size-14 " onClick={() => submitForm()}>
                Lưu thay đổi
              </Button>
              <Button outline className="font-size-14" onClick={() => navigate('/customer')}>
                Hủy
              </Button>
            </div>
          </div>
          <Modify refForm={refForm} />
        </>
      )}
      <CustomeModal
        isOpen={userLock !== null}
        confirm={onConfirm}
        closeModal={() => setUserLock(null)}
        buttonName="Đồng ý"
        type="delete"
        mess={`${userLock?.status === 'active' ? 'Khoá' : 'Mở khoá'} khách hàng ${
          userLock?.username
        } `}
      />
    </div>
  );
}

CustomerDetail.propTypes = {};

export default CustomerDetail;
