/* eslint-disable import/no-cycle */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { io } from 'socket.io-client';
import TableEmpty from '@Components/DataTableBasic/TableEmpty';
import { formatNumber } from '@Utils/helpers';
import { renderQuantity, renderVTHT } from '@Views/Client/TransactionTPlus';
import { TYPE_TPLUS } from '@Components/Layouts/PrivateLayout';
import { stockAsset } from '@Apis/client/stock';
import { customerAssets } from '@Apis/admin/customer';

function TableTPlus({ accountId }) {
  const [dataAssets, setDataAsset] = useState({});

  const getData = async () => {
    let api = stockAsset;
    if (process.env.DASHBOARD_TYPE === 'admin') {
      api = customerAssets;
    }
    const data = await api({ trade_type: TYPE_TPLUS, customer_id: accountId });
    setDataAsset(data);
  };

  useEffect(() => {
    getData();
    const token = localStorage.getItem(process.env.TOKEN);
    const endpoint =
      process.env.DASHBOARD_TYPE === 'admin' ? '/admin/customer/assets' : '/customer/stock/assets';
    const socket = io(`${process.env.BACKEND_SOCKET}${endpoint}`, {
      query: { token },
      transports: ['websocket'],
    });
    socket.on('connect', () => {
      socket.emit('register', 'your-client-id', {
        trade_type: TYPE_TPLUS,
        customer_id: accountId,
      });
    });
    socket.on('data', ({ data: dataSocket }) => {
      setDataAsset(dataSocket);
    });

    return () => {
      socket.disconnect();
    };
  }, [accountId]);

  const { assets } = dataAssets || {};

  return (
    <div className="wrap-table">
      {!(assets && Object.keys(assets).length > 0) ? (
        <TableEmpty />
      ) : (
        <table>
          <thead>
            <tr>
              <th style={{ background: '#EBEAED' }} rowSpan={2}>
                Mã CK
              </th>
              <th colSpan={2} style={{ background: 'rgba(98, 52, 148, 0.1)', color: '#623494' }}>
                T+
              </th>
              {/* <th
                className="text-align-center"
                style={{ background: 'rgba(98, 52, 148, 0.1)', color: '#623494' }}
                colSpan={3}
              >
                T2
              </th> */}
              <th
                rowSpan={2}
                style={{ background: '#EBEAED', color: '#623494' }}
                className="text-align-center"
              >
                P/L
              </th>
              <th rowSpan={2} style={{ background: '#EBEAED' }} className="text-align-center">
                Tỷ lệ lãi/lỗ
              </th>
            </tr>
            <tr style={{ fontSize: 14 }}>
              <th style={{ background: 'rgba(98, 52, 148, 0.1)' }}>Vị thế hiện tại</th>
              <th style={{ background: 'rgba(98, 52, 148, 0.1)' }}>Giá vốn / Giá TT</th>
              {/* <th style={{ background: 'rgb(211, 126, 23, 0.1)' }}>Vị thế hiện tại</th>
              <th style={{ background: 'rgb(211, 126, 23, 0.1)' }}>Giá vốn / Giá TT</th>
              <th style={{ background: 'rgb(211, 126, 23, 0.1)' }}>Deadline trả VT</th> */}
            </tr>
          </thead>
          <tbody>
            {Object.keys(assets || {}).map((key) => {
              const t0 = assets[key].t0;
              const t2 = assets[key].t2;
              if (!t0 || !t2) return null;

              const startValue = t0.quantity * (t0?.dca_price || 0);
              let pl = t0.stock.price * t0.quantity - startValue;
              const t2startValue = t2.quantity * (t2?.dca_price || 0);
              pl += t2.stock.price * t2.quantity - t2startValue;
              const pnl_wait = t0.stock.price * t0.quantity - startValue;
              const percent = (pnl_wait / startValue) * 100 || 0;
              return (
                <tr key={key}>
                  <td>{key}</td>
                  <td>{renderVTHT(t0?.quantity)}</td>
                  <td style={{ textAlign: 'center' }}>
                    <div>
                      {formatNumber(t0.dca_price, '--')}/{formatNumber(t0.stock.price, '--')}
                    </div>
                  </td>
                  {/* <td>{renderVTHT(t2?.quantity)}</td>
                  <td style={{ textAlign: 'right' }}>
                    <div>
                      {formatNumber(t2.dca_price, '--')}/{formatNumber(t2.stock.price, '--')}
                    </div>
                  </td>
                  <td>
                    {t2?.liquidation_at
                      ? dayjs.unix(t2?.liquidation_at).format('DD/MM/YYYY HH:mm:ss')
                      : '--'}
                  </td> */}
                  <td style={{ textAlign: 'center' }}>{renderQuantity(pl, formatNumber(pl))}</td>
                  <td style={{ textAlign: 'center' }}>
                    {renderQuantity(percent, formatNumber(percent), true)}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
    </div>
  );
}

TableTPlus.propTypes = {
  accountId: PropTypes.any,
};

export default TableTPlus;
