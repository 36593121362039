import { wareHouseIndex } from '@Apis/admin/warehouse';
import DataTableBasic from '@Components/DataTableBasic';
import Title from '@Components/Title';
import useCustomQuery from '@Utils/useCustomQuery';
import React from 'react';
import { groupBy } from 'lodash';
import { useNavigate } from 'react-router-dom';
import { columns } from './columns';

function StatisRepo() {
  const navigate = useNavigate();

  const { data } = useCustomQuery(wareHouseIndex, { per_page: 6, page: 1 }, true);

  const newData = groupBy(data || [], (b) => b.purchaseAccountGroup?.name);

  return (
    <div className="w-100">
      <Title name="Thống kê kho" />
      <div style={{ marginTop: 16 }}>
        {Object.keys(newData).map((key, index) => {
          const dataTable = newData[key].concat([
            newData[key].reduce(
              (a, b) => {
                a.stock_value += b.stock_value;
                a.total_nav += b.total_nav;
                a.margin_debt += b.margin_debt;
                a.buy_ability += b.buy_ability;
                a.dividend_amount += b.dividend_amount;
                a.id += index;
                return a;
              },
              {
                stock_value: 0,
                total_nav: 0,
                margin_debt: 0,
                buy_ability: 0,
                dividend_amount: 0,
              },
            ),
          ]);
          return (
            <div key={key} className="stock-view-sum">
              <div
                style={{ padding: 10, background: '#1C70B8', color: '#fff' }}
                className="w-100 mt-1"
              >
                <span style={{ fontWeight: 600 }}>{(key && key != 'undefined') ? key : 'Khác'}</span>
              </div>
              <DataTableBasic data={dataTable} columns={columns(navigate)} />
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default StatisRepo;
