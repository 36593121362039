import React, { Component, Fragment, useEffect, useState } from 'react';
import Title from '@Components/Title';
import DatePicker from '@Components/DatePicker';
import NavigationBar from '@Components/NavigationBar';
import Prop from 'prop-types';
import { list, read } from '@Apis/client/notification';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { toastError } from '@Utils/toastrHelper';
import { accountsHome } from '@Apis/client/home';
import ReactDom from 'react-dom';
import GlobalLoading from '@Components/GlobalLoading';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import dayjs from 'dayjs';
import { io } from 'socket.io-client';
import ItemNotification from './ItemNotification';
import InfoNotification from './InfoNotification';
import './styles.scss';

class Notification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tab: 1,
      notifications: [],
      loading: true,
      total_page: 1,
      itemActive: undefined,
      iMobile: window.innerWidth <= 767,
      date_range: [],
    };
    this.page = 1;
    this.div = document.createElement('div');
    this.div.className = 'notifcation-drawer';
    this.div.style.display = 'none';
    document.querySelector('body')?.append(this.div);
    this.divApp = document.querySelector('#app');
    this.divApp.style.transition = 'all 0.5s';
  }

  UNSAFE_componentWillMount() {
    this.getNotification();
    this.connectSocket();
  }

  componentDidMount() {
    this.changeHeight();
    window.addEventListener('resize', this.changeHeight);
  }

  UNSAFE_componentWillReceiveProps(nProps) {
    const { id, notificationCount, customer_id } = this.props;
    if (id !== nProps.id && nProps.id) {
      const { notifications } = this.state;
      const itemActive = notifications.find((e) => e.id.toString() === nProps.id);
      this.setState({ itemActive });
      if (!itemActive.seen) this.readNotification([nProps.id]);
    } else if (!nProps.id && id) {
      this.setState({ itemActive: undefined });
      this.closeMobile();
    }

    if (notificationCount !== nProps.notificationCount) {
      this.reCallNotification();
    }
    if (customer_id !== nProps.customer_id) {
      this.socket?.disconnect();
      this.connectSocket();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.changeHeight);
    document.querySelector('body')?.removeChild(this.div);
    this.divApp.style.transform = '';
    this.divApp.style.transition = '';
    this.socket.disconnect();
  }

  connectSocket = () => {
    if (this.socket?.connected) {
      return;
    }
    const { customer_id } = this.props;
    const token = localStorage.getItem(process.env.TOKEN);
    this.socket = io(`${process.env.BACKEND_SOCKET}/customer/notification/new`, {
      query: { token },
      transports: ['websocket'],
    });
    this.socket.on('connect', () => {
      this.socket.emit('register', 'your-client-id', { customer_id });
    });
    this.socket.on('data', (data) => {
      const { notifications } = this.state;
      this.setState({ notifications: [data, ...notifications] });
    });
  };

  getNotification = (type, reset) => {
    const { notifications, date_range, iMobile } = this.state;
    const { customer_id } = this.props;
    this.type = type;
    list({
      per_page: 10,
      page: this.page,
      type,
      customer_id,
      date_range: date_range
        .map((e) => dayjs(e).unix())
        .toString()
        .replace(',', '-'),
    })
      .then(({ data, paging }) => {
        const { id } = this.props;
        let itemActive;
        if (id && this.page === 1) {
          itemActive = data.find((e) => e.id.toString() === id);
        }
        if (itemActive && iMobile) {
          this.openMobile();
        }
        if (itemActive) {
          this.readNotification([id]);
        }
        this.setState({
          notifications: reset ? data : [...notifications, ...data],
          loading: false,
          total_page: paging.total_page,
          itemActive,
        });
      })
      .catch((e) => {
        toastError(e);
        this.setState({ loading: false });
      });
  };

  readNotification = (ids) => {
    read({ id: ids || 'all' })
      .then(() => {
        this.reCallNotification();
      })
      .catch(toastError);
  };

  reCallNotification = () => {
    const { date_range, iMobile } = this.state;
    const { customer_id } = this.props;
    this.setState({ loading: true });
    list({
      per_page: 10 * this.page,
      page: 1,
      type: this.type,
      customer_id,
      date_range: date_range
        .map((e) => dayjs(e).unix())
        .toString()
        .replace(',', '-'),
    })
      .then(({ data, paging }) => {
        const { id } = this.props;
        let itemActive;
        if (id && this.page === 1) {
          itemActive = data.find((e) => e.id.toString() === id);
        }
        if (itemActive && iMobile) {
          this.openMobile();
        }
        this.setState({
          notifications: data,
          loading: false,
          total_page: paging.total_page,
          itemActive,
        });
      })
      .catch((e) => {
        toastError(e);
        this.setState({ loading: false });
      });
  };

  changeHeight = () => {
    const { iMobile, itemActive } = this.state;
    if (window.innerWidth <= 767) {
      if (!iMobile) {
        if (itemActive) this.openMobile();
        this.setState({ iMobile: true });
      }
    } else if (iMobile) {
      this.closeMobile();
      this.setState({ iMobile: false });
    }
    if (this.ref) {
      this.ref.style.height = `${
        window.innerHeight - ((window.innerWidth <= 767 ? 85 : 0) + 226)
      }px`;
    }
  };

  handleScroll = (e) => {
    const { total_page } = this.state;
    if (total_page <= this.page) {
      return;
    }
    if (e.target.scrollTop + e.target.clientHeight === e.target.scrollHeight) {
      this.page++;
      this.setState({ loading: true });
      this.getNotification();
    }
  };

  handleChangeRange = (v) => {
    this.setState({ date_range: v, loading: true });
    this.page = 1;
    this.getNotification(undefined, true);
  };

  renderChildMoible = () => {
    const { iMobile } = this.state;
    if (iMobile) {
      return ReactDom.createPortal(this.renderContentMobile(), this.div);
    }
  };

  openMobile = () => {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
    this.div.style.display = '';
    setTimeout(() => {
      this.div.style.right = 0;
      this.divApp.style.transform = 'translateX(-40%)';
    }, 0);
  };

  closeMobile = () => {
    this.div.style.right = '';
    this.divApp.style.transform = '';
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
    this.timeout = setTimeout(() => {
      this.timeout = undefined;
      this.div.style.display = 'none';
    }, 500);
  };

  renderContentMobile = () => {
    const { itemActive } = this.state;
    const { user, navigate } = this.props;
    return (
      <div className="content-drawer">
        <div
          className="header-content-mobile"
          role="button"
          tabIndex="0"
          onClick={() => {
            navigate('/notification');
            this.div.style.right = '';
            this.divApp.style.transform = '';
            setTimeout(() => {
              this.setState({ itemActive: undefined });
            }, 450);
          }}
        >
          <ArrowBackIosIcon />
          <span>Quay lại</span>
        </div>
        <InfoNotification user={user} itemActive={itemActive} />
      </div>
    );
  };

  render() {
    const { tab, itemActive, notifications, loading, iMobile, date_range } = this.state;
    const { user, navigate } = this.props;

    return (
      <div className="swap-notification">
        <div className="notification asset-management" style={{ position: 'relative' }}>
          <GlobalLoading loading={loading} style={{ position: 'absolute' }} />
          <div className="header-asset">
            <Title name="Thông báo" />
            <DatePicker.Range
              value={date_range}
              onChange={this.handleChangeRange}
              placeholder="Chọn ngày"
            />
          </div>
          <div className="swap-list-notifcation">
            <div className="swapBody">
              <div className="list-notifcation">
                <NavigationBar
                  tabs={[
                    {
                      id: 1,
                      name: 'Tất cả',
                    },
                    {
                      id: 2,
                      name: <span>Thông báo bổ sung cọc</span>,
                    },
                    {
                      id: 3,
                      name: (
                        <span style={{ color: '#2F69B3' }}>
                          <img
                            style={{ marginRight: 8 }}
                            src="/images/notification/done_all.svg"
                            alt=""
                          />
                          Đọc hết
                        </span>
                      ),
                    },
                  ]}
                  activeTab={tab}
                  setActiveTab={(id) => {
                    navigate('/notification');
                    if (id === 3) {
                      this.setState({ loading: true });
                      this.readNotification();
                      return;
                    }
                    this.setState({ tab: id, loading: true }, () => {
                      if (id === 1) {
                        this.page = 1;
                        this.getNotification(undefined, true);
                      } else {
                        this.getNotification('deposit', true);
                      }
                    });
                  }}
                />
                <div
                  className="info-notifcation"
                  onScroll={this.handleScroll}
                  ref={(r) => {
                    this.ref = r;
                  }}
                >
                  {notifications.map((e, i) => {
                    const date = dayjs.unix(e.created_at).format('DD/MM/YYYY');
                    const datePre =
                      notifications[i - 1] &&
                      dayjs.unix(notifications[i - 1].created_at).format('DD/MM/YYYY');

                    return (
                      <Fragment key={i.toString()}>
                        {(!i || date !== datePre) && (
                          <div
                            style={{
                              fontSize: 14,
                              fontWeight: 500,
                              background: '#fff',
                              paddingTop: 15,
                              paddingLeft: 20,
                              color: '#84818A',
                            }}
                          >
                            {date}
                          </div>
                        )}
                        <ItemNotification
                          active={itemActive?.id === e.id}
                          onSelect={() => {
                            navigate(`/notification?id=${e.id}`);
                            if (!e.seen) {
                              this.readNotification([e.id]);
                            }
                            this.setState({ itemActive: e }, () => {
                              if (iMobile) {
                                this.openMobile();
                              }
                            });
                          }}
                          data={e}
                          key={i.toString()}
                        />
                      </Fragment>
                    );
                  })}
                </div>
              </div>
              {!iMobile ? <InfoNotification user={user} itemActive={itemActive} /> : null}
            </div>
          </div>
        </div>
        {this.renderChildMoible()}
      </div>
    );
  }
}

Notification.propTypes = {
  id: Prop.string,
  customer_id: Prop.string,
  user: Prop.object,
  navigate: Prop.func,
  notificationCount: Prop.number,
};

SwapNotification.propTypes = {};

function SwapNotification() {
  const [searchParams] = useSearchParams();
  const id = searchParams.get('id');
  const navigate = useNavigate();
  const [data, setDataAccounts] = useState({ accounts: [] });

  const { user } = useSelector(({ user }) => ({ user: user.info }));
  const { notificationCount } = useSelector(({ ui }) => ({
    notificationCount: ui.notificationCount,
  }));

  const { accounts } = data;
  const productType = useSelector((state) => state.ui.productType);
  const account = accounts?.find((e) => e.trade_type === productType);

  useEffect(async () => {
    if (process.env.DASHBOARD_TYPE === 'admin') {
      return;
    }
    const data = await accountsHome();
    setDataAccounts(data);
  }, []);

  return (
    <Notification
      customer_id={account?.id}
      notificationCount={notificationCount}
      navigate={navigate}
      user={user}
      id={id}
    />
  );
}

export default SwapNotification;
