import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { UncontrolledDropdown, DropdownMenu, DropdownToggle, DropdownItem } from 'reactstrap';
import { Square } from '@mui/icons-material';

import { getInputErrorsObject } from '@Utils/helpers';
import { SelectWrapper } from './styles';

function TradeSelectInput({
  name,
  options,
  register,
  errors,
  defaultValue,
  setValue = null,
  className = '',
}) {
  const [selectValue, setSelectValue] = useState('');
  const [error, setError] = useState();

  useEffect(() => {
    if (name && errors) {
      setError(getInputErrorsObject(name, errors));
    }
  }, [name, errors && Object.keys(errors)]);

  useEffect(() => {
    if (setValue && defaultValue) {
      const detail = options.find((item) => item.value === defaultValue);
      setSelectValue(detail);
      setValue(name, defaultValue);
    }
  }, [defaultValue]);

  const handleChange = (item) => {
    setSelectValue(item);
    setValue(name, item.value);
  };

  const renderMenu = () => {
    const xhtml = options.map((option) => (
      <DropdownItem className="w-100" onClick={() => handleChange(option)} key={option.label}>
        <Square
          style={{
            color: option.color,
          }}
        />
        <span
          className="align-middle font-size-14 font-weight-bolder ml-50 text-uppercase"
          style={{ color: option.color }}
        >
          {option.label}
        </span>
      </DropdownItem>
    ));
    return xhtml;
  };

  return (
    <SelectWrapper>
      <UncontrolledDropdown className="nav-item">
        <DropdownToggle
          color="none"
          style={{ background: selectValue.color }}
          className="font-size-14 text-white text-uppercase"
          onClick={(e) => e.preventDefault()}
          caret
        >
          {selectValue.label}
        </DropdownToggle>
        <DropdownMenu right>{renderMenu()}</DropdownMenu>
      </UncontrolledDropdown>
      {error && (
        <p className="text-danger tx-12 mb-0" style={{ paddingTop: '2px' }}>
          {error.message || 'Trường bắt buộc'}
        </p>
      )}
    </SelectWrapper>
  );
}

TradeSelectInput.propTypes = {
  register: PropTypes.object,
  name: PropTypes.string,
  labelCol: PropTypes.string,
  inputCol: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      name: PropTypes.string,
    }),
  ),
  defaultValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  setValue: PropTypes.func,
  errors: PropTypes.object,
  icon: PropTypes.object,
  disabled: PropTypes.bool,
  className: PropTypes.string,
};

export default TradeSelectInput;
