/* eslint-disable react/jsx-wrap-multilines */
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { formatNumber, parsePrice } from '@Utils/helpers';
import Tooltip from '@Components/TooltipNew';
import { Button, Input } from 'reactstrap';
import { editReport } from '@Apis/admin/report';
import useCustomMutation from '@Utils/useCustomMuation';
import { toastError, toastSuccess } from '@Utils/toastrHelper';

function ToolTipEdit({ label, defaultValue, name, date, refetch, style, tradeType }) {
  const [value, setValue] = useState(0);
  const { mutate, isSuccess, error } = useCustomMutation(editReport, true);

  const refTooltip = useRef();

  useEffect(() => {
    if (defaultValue) {
      const format = parsePrice(defaultValue);
      setValue(format);
    }
  }, [defaultValue]);

  const handleChange = (value) => {
    const format = parsePrice(value);
    setValue(format);
  };
  useEffect(() => {
    if (isSuccess) {
      toastSuccess('Cập nhập thành công');
      refTooltip?.current.handleBlur();
      refetch();
    }
    if (error) {
      toastError(error);
    }
  }, [isSuccess, error]);
  const saveReport = () => {
    mutate({
      trade_type: tradeType,
      date,
      items: [
        {
          id: name,
          value: parseInt(value.replaceAll('.', '').replaceAll(',', '')),
        },
      ],
    });
  };

  return (
    <div style={{ width: 110 }}>
      <Tooltip
        skipScroll
        skipBlur
        ref={refTooltip}
        overlayClassName="tooltip-custom-wrap"
        content={
          <div className="note-tooltip">
            <p className="color-black pb-25">{label}</p>
            <Input
              placeholder={label}
              type="text"
              name={name}
              value={value}
              onChange={(e) => handleChange(e.target.value)}
              style={{ width: '100%' }}
            />
            <div className="d-flex" style={{ marginTop: 24 }}>
              <Button
                className="px-20"
                size="md"
                color="primary"
                style={{ marginRight: 12, width: '50%' }}
                disabled={!value}
                onClick={() => saveReport()}
              >
                Lưu
              </Button>
              <Button
                onClick={() => refTooltip?.current.handleBlur()}
                style={{ marginLeft: 12, width: '50%' }}
                name=""
                className="px-2"
                size="md"
              >
                Huỷ
              </Button>
            </div>
          </div>
        }
      >
        <div className="d-flex algin-items-center" style={{ justifyContent: 'center', ...style }}>
          <img src="/images/report/edit-btn.svg" alt="btn-edit" />
          <span className="ml-25 text-primary">
            {defaultValue ? formatNumber(defaultValue) : 'Thêm'}
          </span>
        </div>
      </Tooltip>
    </div>
  );
}

ToolTipEdit.propTypes = {
  allowAdd: PropTypes.bool,
  label: PropTypes.string,
  defaultValue: PropTypes.number,
  name: PropTypes.string,
  date: PropTypes.string,
  refetch: PropTypes.func,
  style: PropTypes.object,
  tradeType: PropTypes.any,
};

export default ToolTipEdit;
