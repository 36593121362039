import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Table } from 'reactstrap';
import { renderType, getQuantityMatching, getPriceMatching } from '@Utils/tradeHelpers';
import { formatNumber, formatVND } from '@Utils/helpers';

function ExpandTranction({ data }) {
  const [items, setItems] = useState([]);
  const { publicCustomer, purchaseAccount } = data;

  useEffect(() => {
    const { buyItems, ...restData } = data;
    const combined = [].concat(restData, buyItems);
    setItems(combined);
  }, [data]);

  return (
    <div>
      <Table className="transaction-expand">
        <tbody>
          {items.map((item) => {
            const quantityMatching = getQuantityMatching(item);
            if (quantityMatching) {
              return (
                <tr key={item.id}>
                  <td style={{ width: '248px' }} />
                  <td style={{ width: '100px' }}>{publicCustomer?.code}</td>
                  <td className="white-space-nowrap uppercase" style={{ width: '140px' }}>
                    {publicCustomer?.username}
                  </td>
                  <td className="" style={{ width: '100px' }}>
                    {renderType(item.type)}
                  </td>
                  <td className="" style={{ width: '100px' }}>
                    {renderType(item.type)}
                  </td>
                  <td className="" style={{ width: '70px', textAlign: 'center' }}>
                    {item.stock_id}
                  </td>
                  <td className="" style={{ width: '80px', textAlign: 'center' }}>
                    {formatVND(item.price)}
                  </td>
                  <td className="" style={{ width: '90px', textAlign: 'center' }}>
                    {formatNumber(item.quantity, '--')}
                  </td>
                  <td className="" style={{ width: 105, textAlign: 'center' }}></td>
                  <td className="white-space-nowrap" style={{ width: '120px' }}>
                    {purchaseAccount?.name}
                  </td>
                  <td
                    className="font-weight-bolder"
                    style={{ width: '120px', textAlign: 'center' }}
                  >
                    {formatNumber(getPriceMatching(item), '--')}
                  </td>
                  <td
                    className="text-danger font-weight-bolder"
                    style={{ width: '100px', textAlign: 'center' }}
                  >
                    {formatNumber(quantityMatching, '--')}
                  </td>
                  <td />
                </tr>
              );
            }
            return '';
          })}
        </tbody>
      </Table>
    </div>
  );
}

ExpandTranction.propTypes = {
  data: PropTypes.object,
};

export default ExpandTranction;
