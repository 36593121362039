import styled from 'styled-components';

export const StatusWrapper = styled.div`
  background: #ffffff;
  border-bottom: 1px solid #ebeaed;
  .nav-tabs {
    .nav-link {
      padding: 14px;
    }
  }
  .count-status {
    font-size: 12px;
    color: white;
    min-width: 20px;
    height: 20px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-left: 8px;
    padding: 2px 5px;
  }
`;
