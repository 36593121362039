import axiosService from '@Lib/axiosService';
import { handleResponse } from '@Utils/helpers';

let host = '';

if (process.env.DASHBOARD_TYPE === 'customer') {
  host = `${process.env.API_CUSTOMER_URL}/account`;
} else {
  host = `${process.env.API_ADMIN_URL}/customer`;
}

export const accountsHome = async (query) => {
  const res = await axiosService.get(`${host}/accounts`, query);
  return handleResponse(res);
};
