import axiosService from '@Lib/axiosService';
import { handleResponse } from '@Utils/helpers';

let url = '';
if (process.env.DASHBOARD_TYPE === 'admin') {
  url = `${process.env.API_ADMIN_URL}/purchase-account`;
}

export const listPurchase = async (query) => {
  const res = await axiosService.get(`${url}/group-list`, query);
  return handleResponse(res);
};

export const createPurchase = async (params) => {
  const res = await axiosService.post(`${url}/save-group`, params);
  return handleResponse(res);
};

export const listSelect = async (params) => {
  const res = await axiosService.post(`${url}/group-list-for-select`, params);
  return handleResponse(res);
};

export const transfer = async (req) => {
  const res = await axiosService.post(`${url}/transfer`, req);
  return handleResponse(res);
};
