import React from 'react';
import Transaction from './Transaction';
import Summary from './Summary';
import Stock from './Stock';
import PropTypes from 'prop-types';

function Assets({ type, accounts }) {
  const account =  accounts?.find((e) => e.trade_type === type);

  return (
    <div className="">
      <Summary type={type} />
      <Transaction accountId={account?.id} />
      <Stock />
    </div>
  );
}

Assets.propTypes = {
  type: PropTypes.any,
  accounts: PropTypes.any,
};

export default Assets;
