export const TABS = [
  {
    id: 0,
    name: 'Tổng quan',
  },
  {
    id: 1,
    name: 'Cơ sở',
  },
  {
    id: 2,
    name: 'CK T+',
  },
  {
    id: 3,
    name: 'Phái Sinh',
  },
  {
    id: 4,
    name: 'Thông tin chung',
  },
  {
    id: 5,
    name: 'Hỗ trợ và tỉ lệ',
  },
];

export const ASSETS_TAB = [
  {
    id: 0,
    name: 'Nộp/rút tiền',
  },
  {
    id: 1,
    name: 'Cổ phiếu',
  },
];
