import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

function Breadcrumb({ childName, title }) {
  const sidebar = useSelector((state) => state.ui.sidebar);
  const location = useLocation();

  const menuActive = sidebar.find((item) => {
    if (item.children) {
      return location.pathname.includes(item.relatedPath);
    }
    return location.pathname === item.path;
  });

  return (
    <div>
      <p className="font-size-14 mb-50">
        <span className="color-matte-gray">{menuActive?.name}</span>
        <span> / {childName}</span>
      </p>
      <p className="font-size-32 font-weight-bolder">{title}</p>
    </div>
  );
}

Breadcrumb.propTypes = {
  childName: PropTypes.string,
  title: PropTypes.any,
};

export default Breadcrumb;
