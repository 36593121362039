import React, { Component } from 'react';
import Select from 'react-select';
import SearchIcon from '@mui/icons-material/Search';
import { Button, Input } from 'reactstrap';
import { Button as ButtonMaterial, Modal } from '@mui/material';
import Proptypes from 'prop-types';
import DropdownSelect from '@Components/DropdownSelect';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import './styles.scss';
import { toastError, toastSuccess } from '@Utils/toastrHelper';
import ModalExport from '@Components/ModalExport';

class FilterComponent extends Component {
  constructor(props) {
    super(props);
    const { params, filters = [] } = props;
    const filter = {};
    filters.forEach((fil) => {
      const value = params.get(fil.key);
      filter[fil.key] = value || '';
    });
    this.state = { visible: false, filter };
  }

  open = () => {
    const { params, filters = [] } = this.props;
    const filter = {};
    filters.forEach((fil) => {
      const value = params.get(fil.key);
      filter[fil.key] = value || '';
    });
    this.setState({ visible: true, filter });
  };

  onChange = (event, key) => {
    const { filter } = this.state;
    this.setState({ filter: { ...filter, [key]: event.target.value } });
  };

  onChangeSelect = (key, value) => {
    const { filter } = this.state;
    this.setState({ filter: { ...filter, [key]: value } });
  };

  onChangeSelectMultiple = (key, values) => {
    const { filter } = this.state; 
    const searchText = values.map((e) => e.value).toString()

    this.setState({ filter: { ...filter, [key]: searchText } });
  };

  close = () => {
    this.setState({ visible: false, filter: {} });
  };

  search = () => {
    const { filter } = this.state;
    const { onSearch, setParams } = this.props;
    onSearch?.(filter);
    setParams?.(filter);
    this.setState({ visible: false });
  };

  onExport = async () => {
    const { filter } = this.state;
    const { onExport } = this.props;
    this.search();
    try {
      await onExport?.(filter);
      toastSuccess('Tạo yêu cầu xuất file thành công!');
    } catch (e) {
      toastError(e);
    }
  };

  renderContent = (filter) => {
    const { filter: filterState } = this.state;
    const { parseChange = (v) => v, parseValue = (v) => v } = filter;
    if (filter.options) {
      if (filter.isMulti) {
        return (
          <Select
            defaultValue={() => {
              const valueSeleted = filterState[filter.key].split(',');
              return filter.options.filter(function (e) {
                return valueSeleted.indexOf(e.value) > -1;
              });
            }}
            isMulti
            onChange={(values) => this.onChangeSelectMultiple(filter.key, values)}
            name={filter.key}
            options={filter.options}
            placeholder="Chọn.."
            className="basic-multi-select"
          />
        );
      }
      return (
        <DropdownSelect
          styleWrap={{ width: '100%' }}
          name={filter.key}
          valueSelected={filterState[filter.key]}
          setValueSelected={this.onChangeSelect}
          styleMenu={{ maxHeight: 200, overflow: 'auto', width: '100%' }}
          options={[
            { [filter.labelKey || 'label']: 'Tất cả', [filter.labelValue || 'value']: '' },
            ...filter.options,
          ]}
          labelShow={false}
          labelKey={filter.labelKey}
          labelValue={filter.labelValue}
        />
      );
    }
    if (filter.Component) {
      let props = {
        setValue: (k, v) => {
          this.onChangeSelect(k, parseChange(v));
        },
        defaultValue: filter.defaultValue || filterState[filter.key],
      };
      if (filter.type === 'onChange') {
        props = {
          onChange: (v) => {
            this.onChangeSelect(filter.key, parseChange(v));
          },
          value: parseValue(filterState[filter.key]),
        };
      }
      const { Component: ComponentFilter } = filter;
      return (
        <ComponentFilter
          allowAll
          style={{ marginBottom: 0 }}
          labelCol="12"
          inputCol="12"
          labelClassName="font-size-14 mb-50"
          name={filter.key}
          {...props}
        />
      );
    }
    return (
      <Input
        type={filter.type || 'text'}
        value={filterState[filter.key]}
        placeholder={filter.label}
        onChange={(e) => this.onChange(e, filter.key)}
      />
    );
  };

  render() {
    const { visible } = this.state;
    const { filters = [], onExport } = this.props;
    return (
      <div className="filter-component">
        <ButtonMaterial
          color="primary"
          className="d-flex align-items-center"
          style={{ height: 43.39, textTransform: 'unset', minWidth: 115 }}
          onClick={this.open}
        >
          <SearchIcon />
          <span className="font-size-14">Tìm kiếm</span>
        </ButtonMaterial>
        <Modal open={!!visible} onClose={this.close}>
          <div className="modal-create-group">
            <div className="modal-create-title">Tìm kiếm</div>
            {filters.map((filter) => (
              <div
                key={filter.key}
                className="d-flex align-items-center"
                style={{ fontWeight: 600, marginBottom: 10 }}
              >
                <div style={{ marginRight: 10, minWidth: filter.width || 100 }}>
                  {filter.label}:
                </div>
                <div style={{ width: '100%' }}>{this.renderContent(filter)}</div>
              </div>
            ))}
            <div className="d-flex justify-content-center mt-3">
              <Button
                onClick={this.search}
                className="d-flex align-items-center"
                style={{ height: 43.39, marginRight: 5 }}
                color="primary"
              >
                <span className="font-size-14">Tìm kiếm</span>
              </Button>
              {onExport ? (
                <ModalExport
                  classNameButton="d-flex align-items-center primary-light"
                  styleButton={{
                    height: 43.39,
                    marginRight: 5,
                    background: 'rgb(20, 177, 59)',
                    color: 'rgb(20, 177, 59)',
                  }}
                  onExport={this.onExport}
                />
              ) : null}
              <Button
                onClick={this.close}
                style={{ height: 43.39, marginRight: 5 }}
                className="mr-1"
              >
                <span className="font-size-14">Đóng</span>
              </Button>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

FilterComponent.propTypes = {
  filters: Proptypes.any,
  onSearch: Proptypes.any,
  onExport: Proptypes.any,
  setParams: Proptypes.any,
  params: Proptypes.any,
};

export function withRouter(Component) {
  return React.forwardRef((props, ref) => {
    const location = useLocation();
    const navigate = useNavigate();
    const params = new URLSearchParams(location.search);
    const [searchParams, setSearchParams] = useSearchParams();

    const setParams = (filter) => {
      Object.keys(filter).forEach((key) => {
        searchParams.set(key, filter[key]);
        setSearchParams(searchParams);
      });
    };

    return (
      <Component
        {...props}
        ref={ref}
        location={location}
        navigate={navigate}
        params={params}
        setParams={setParams}
      />
    );
  });
}

export default withRouter(FilterComponent);
