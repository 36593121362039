import React from 'react';
import {formatNumber, formatVND} from '@Utils/helpers';
import { renderPrice } from './TransactionTab3';

export const columns = () => {
  return [
    {
      name: 'MÃ CK',
      cell: (row) => row.stock_id,
      center: true,
    },
    {
      name: 'GIÁ VỐN',
      cell: (row) => (
        <div className="w-100 text-align-end">
          {formatVND(row?.lastTradingItem?.current_cost_price || 0)}
        </div>
      ),
    },
    {
      name: 'GIÁ THỊ TRƯỜNG',
      cell: (row) => <div className="w-100 text-align-end">{formatVND(row.stock?.price)}</div>,
    },
    {
      name: 'KL',
      cell: (row) => <div className="w-100 text-align-end">{formatVND(row.quantity)}</div>,
    },
    {
      name: 'KL khả dụng',
      cell: (row) => (
        <div className="w-100 text-align-end">{formatVND(row.available_quantity)}</div>
      ),
    },
    {
      name: 'T+',
      cell: (row) => <div className="w-100 text-align-end">{formatVND(row.t0)}</div>,
    },
    {
      name: 'T1',
      cell: (row) => <div className="w-100 text-align-end">{formatVND(row.t1)}</div>,
    },
    {
      name: 'P/L',
      cell: (row) => {
        const startValue = row.quantity * (row?.lastTradingItem?.current_cost_price || 0);
        const pnl_wait = row.stock.price * row.quantity - startValue;

        let color = '#14B13B';
        if (pnl_wait < 0) {
          color = '#ED1C24';
        }

        return (
          <div className="w-100 text-align-end" style={{ color }}>
            {formatNumber(pnl_wait, '--', true, 0)}
          </div>
        );
      },
    },
  ];
};
