/* eslint-disable no-nested-ternary */
import { accountsHome } from '@Apis/client/home';
import { TYPE_CS, TYPE_TPLUS } from '@Components/Layouts/PrivateLayout';
import useCustomQuery from '@Utils/useCustomQuery';
import React from 'react';
import { useSelector } from 'react-redux';
import Transaction from '../Transaction';
import TransactionPS from '../TransactionPS';
import TransactionTPlus from '../TransactionTPlus';

function TransactionGroupSwitch() {
  const productType = useSelector((state) => state.ui.productType);
  const { data: dataAccounts } = useCustomQuery(accountsHome);
  const account = dataAccounts?.accounts?.find((e) => e.trade_type === productType);

  if (!account?.id) return null;

  return productType === TYPE_CS ? (
    <Transaction accountId={account?.id} />
  ) : productType === TYPE_TPLUS ? (
    <TransactionTPlus accountId={account?.id} />
  ) : (
    <TransactionPS accountId={account?.id} />
  );
}

export default TransactionGroupSwitch;
