import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'reactstrap';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import DataTableBasic from '@Components/DataTableBasic';
import { loanIndex } from '@Apis/admin/loan';
import useCustomQuery from '@Utils/useCustomQuery';
import { columns } from './Columns';

function ConditionTable({
  item,
  idCondition,
  setIdCondition,
  filter,
  setItemEdit,
  setItemChangeStatus,
  isRefreshStatus,
}) {
  const { color, name, id, filter: itemFilter } = item;
  const [filterTable, setFilterTable] = useState(itemFilter);
  const [page, setPage] = useState(0);

  useEffect(() => {
    filterTable.keyword = filter.keyword;
    filterTable.date_range = filter.date_range;
    setFilterTable({ ...filterTable, type: 'loan', per_page: 5000, page });
  }, [filter, page]);

  const { data, refetch } = useCustomQuery(loanIndex, filterTable, true);
  useEffect(() => {
    if (isRefreshStatus) {
      refetch();
    }
  }, [isRefreshStatus]);

  const sortOrderBy = useCallback((column, type = '') => {
    setFilterTable((prevValues) => {
      return {
        ...prevValues,
        sort_by: column.sortField,
        order: type.toUpperCase(),
      };
    });
  }, []);

  return (
    <div className="bg-white mb-2" style={{ overflowX: 'auto', overflowY: 'hidden' }}>
      <NavLink
        className="d-flex justify-content-start py-1 align-items-center"
        onClick={() => setIdCondition(id)}
      >
        {idCondition === id ? <KeyboardArrowDown /> : <KeyboardArrowUp />}
        <p style={{ color }} className="font-weight-bold">
          {name}
        </p>
        {data && data.paging.total > 0 && (
          <div className="badge-quantity" style={{ background: color, color: 'white' }}>
            {data?.paging.total}
          </div>
        )}
      </NavLink>
      <div className="d-line" />
      {idCondition === id && (
        <DataTableBasic
          columns={columns({ setItemEdit, setItemChangeStatus })}
          data={data}
          isPageEffect
          page={page}
          onSort={sortOrderBy}
          setPage={setPage}
        />
      )}
    </div>
  );
}

ConditionTable.propTypes = {
  item: PropTypes.object,
  idCondition: PropTypes.number,
  setIdCondition: PropTypes.func,
  filter: PropTypes.object,
  setItemEdit: PropTypes.func,
  setItemChangeStatus: PropTypes.func,
  isRefreshStatus: PropTypes.object,
};

export default ConditionTable;
