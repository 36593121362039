import React from 'react';
import PropTypes from 'prop-types';
import { OpenInNew } from '@mui/icons-material';
import { NavLink } from 'reactstrap';

function CustomerInfo({ item }) {
  return (
    <>
      <p className="text-left color-matte-gray">KHÁCH HÀNG</p>
      <p
        className="font-size-32 text-left mt-1"
        style={{ lineHeight: '30px', textTransform: 'uppercase' }}
      >
        {item?.publicCustomer?.username}
      </p>
      <p className="font-size-18 text-left mt-50 font-weight-bold color-matte-gray">
        {item?.publicCustomer?.code}
      </p>
      <div className="d-flex align-items-center justify-content-start pt-5">
        <OpenInNew color="primary" />
        <NavLink
          onClick={() => {
            window.open(`/customer/detail/${item?.publicCustomer?.id}`);
          }}
          className="ml-50 text-primary font-weight-bolder"
        >
          Xem chi tiết khách hàng
        </NavLink>
      </div>
    </>
  );
}

CustomerInfo.propTypes = {
  item: PropTypes.object,
};

export default CustomerInfo;
