import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Input } from 'reactstrap';
import { Edit } from '@mui/icons-material';
import { parsePrice } from '@Utils/helpers';
import useCustomMutation from '@Utils/useCustomMuation';
import { editReport } from '@Apis/admin/report';
import { toastError, toastSuccess } from '@Utils/toastrHelper';

function InputEdit({ name, defaultValue, date }) {
  const [isEdit, setIsEdit] = useState(false);
  const [value, setValue] = useState(defaultValue);
  const { mutate, isSuccess, error } = useCustomMutation(editReport, true);
  const handleChange = (value) => {
    const format = parsePrice(value);
    setValue(format);
  };

  useEffect(() => {
    if (defaultValue) {
      const format = parsePrice(defaultValue);
      setValue(format);
    }
  }, [defaultValue]);

  const updateReport = () => {
    mutate({
      date,
      items: [{ id: name, value: parseInt(value.replaceAll(',', '')) }],
    });
  };

  useEffect(() => {
    if (isSuccess) {
      toastSuccess('Cập nhập thành công');
      setIsEdit(false);
    }
    if (error) {
      toastError(error);
    }
  }, [isSuccess, error]);

  return (
    <div className="d-flex gap-8">
      {isEdit ? (
        <div className="d-flex gap-8">
          <Button color="primary" onClick={() => updateReport()}>
            Lưu
          </Button>
          <Button color="secondary" onClick={() => setIsEdit(false)}>
            Huỷ
          </Button>
        </div>
      ) : (
        <Button
          outline
          color="primary"
          className="d-flex"
          style={{ padding: '10px' }}
          onClick={() => setIsEdit(true)}
        >
          <Edit color="primary" fontSize="14px" />
          <span className="">Sửa</span>
        </Button>
      )}

      <Input
        type="text"
        name={name}
        value={value}
        onChange={(e) => handleChange(e.target.value)}
        disabled={!isEdit}
        style={{ width: '160px' }}
      />
    </div>
  );
}

InputEdit.propTypes = {
  name: PropTypes.string,
  defaultValue: PropTypes.any,
  date: PropTypes.string,
};

export default InputEdit;
