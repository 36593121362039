import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, Row, Col, Container, Label } from 'reactstrap';
import { Clear } from '@mui/icons-material';
import { useForm } from 'react-hook-form';
import TextInput from '@Components/Input/TextInput';
import { securitieIndex } from '@Apis/admin/securitie';
import useCustomQuery from '@Utils/useCustomQuery';
import SelectInput from '@Components/Input/SelectInput';
import FieldInput from '@Views/Client/Recharge/BodyTab/Request/FieldInput';
import { listSelect } from '@Apis/admin/groupPurchase';
// import { usersSelect } from '@Apis/admin/user';
import { warehouseSave } from '@Apis/admin/warehouse';
import useCustomMutation from '@Utils/useCustomMuation';
import Switch from '@mui/material/Switch';
import { toastError, toastSuccess } from '@Utils/toastrHelper';
import SearchEmployer from '@Components/Input/SearchEmployer';
import { Title, ModalWrapper } from './PopUpUpdateStyle';
import { Checkbox } from '@mui/material';
import { typeConst } from '@Components/Layouts/PrivateLayout';
import { useState } from 'react';

function PopUpUpdate({ isOpen, closeModal, refetch, item }) {
  const { data: dataSecurities } = useCustomQuery(securitieIndex);
  const { data: listSelectOptions } = useCustomQuery(listSelect);
  // const { data: usersSelectData } = useCustomQuery(usersSelect);
  const { mutate, isSuccess, error } = useCustomMutation(warehouseSave, true);
  const [tradeTypeChecked, setTradeTypeChecked] = useState([]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm();

  useEffect(() => {
    if (isOpen) {
      setValue('buy_fee_percent', item?.buy_fee_percent);
      setValue('sell_fee_percent', item?.sell_fee_percent);
      setValue('margin_fee_percent', item?.margin_fee_percent);
      setValue('status', item?.status);
    }
  }, [isOpen]);

  const buy_fee_percent = watch('buy_fee_percent');
  const sell_fee_percent = watch('sell_fee_percent');
  const margin_fee_percent = watch('margin_fee_percent');
  const status = watch('status');

  const onChangeStatus = useCallback((event) => {
    setValue('status', event.target.checked ? 'active' : 'suspended');
  }, []);

  useEffect(() => {
    if (item?.purchaseAccountTradeTypes && item?.purchaseAccountTradeTypes.length > 0) {
      const currentTradeType = item.purchaseAccountTradeTypes.map(e => e.trade_type);
      setTradeTypeChecked(currentTradeType);
    }
  }, [item]);

  const handleTradeTypeCheck = (type) => {
    setTradeTypeChecked((prev) => {
      if (tradeTypeChecked.includes(type)) {
        return tradeTypeChecked.filter((e) => e !== type);
      } else {
        return [...prev, type];
      }
    });
  };

  const onSubmit = (formData) => {
    if (item) {
      formData.id = item.id;
    }

    const purchaseAccountTradeTypes = tradeTypeChecked.map((e) => {
      return { trade_type: e };
    });

    mutate({
      ...formData,
      status,
      buy_fee_percent: Number(buy_fee_percent),
      sell_fee_percent: Number(sell_fee_percent),
      margin_fee_percent: Number(margin_fee_percent),
      purchaseAccountTradeTypes
    });
  };
  useEffect(() => {
    if (isSuccess) {
      let message = 'Tạo mới kho thành công';
      if (item) {
        message = 'Sửa kho thành công';
      }
      toastSuccess(message);
      closeModal();
      refetch();
    }
    if (error) {
      toastError(error);
    }
  }, [isSuccess, error]);

  const optionsSecurities = () => {
    const itemDefaul = {
      label: 'Chọn CTCK',
      value: 0,
    };
    let options = [];
    if (dataSecurities && dataSecurities.length > 0) {
      options = dataSecurities.map((item) => ({ label: item.name, value: item.id }));
    }
    options.unshift(itemDefaul);
    return options;
  };

  // const optionsUser = () => {
  //   const itemDefaul = {
  //     label: 'Chọn',
  //     value: 0,
  //   };
  //   let options = [];
  //   if (usersSelectData && usersSelectData.length > 0) {
  //     options = usersSelectData.map((item) => ({ label: item.username, value: item.id }));
  //   }
  //   options.unshift(itemDefaul);
  //   return options;
  // };

  return (
    <Modal isOpen={isOpen} toggle={closeModal} className="model-warehouse">
      <ModalWrapper>
        <div className="d-flex justify-content-end">
          <Clear size={14} onClick={closeModal} />
        </div>
        <Title>{item ? 'Sửa' : 'Thêm'} tài khoản kho</Title>
        <Container className="pt-2">
          <form onSubmit={handleSubmit(onSubmit)}>
            <Row className="">
              <Col md="6">
                <TextInput
                  labelCol="12"
                  inputCol="12"
                  type="text"
                  labelClassName="font-size-16 text-left mb-50"
                  labelName="Tên kho *"
                  placeholder="Tên kho"
                  setValue={setValue}
                  name="name"
                  register={{ ...register('name', { required: true }) }}
                  errors={errors}
                  defaultValue={item ? item.name : ''}
                />
              </Col>
              <Col md="6">
                <SelectInput
                  labelCol="12"
                  inputCol="12"
                  options={optionsSecurities()}
                  errors={errors}
                  name="securities_service_id"
                  register={{ ...register('securities_service_id') }}
                  labelName="Chọn CTCK"
                  labelClassName="font-size-16 text-left"
                  setValue={setValue}
                  defaultValue={item ? item.securities_service_id : ''}
                />
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <SelectInput
                  labelCol="12"
                  inputCol="12"
                  options={[{ name: 'Chọn tài khoản kho', id: '' }]
                    .concat(listSelectOptions || [])
                    .map((e) => ({ label: e.name, value: e.id }))}
                  name="purchase_account_group_id"
                  register={{ ...register('securities_service_id') }}
                  labelName="Chọn Tài khoản kho"
                  labelClassName="font-size-16 text-left"
                  setValue={setValue}
                  defaultValue={item ? item.purchase_account_group_id : ''}
                />
              </Col>
              <Col md="6">
                <div style={{ marginBottom: '0.5rem' }}>Trạng thái: </div>
                <Switch checked={status === 'active'} onChange={onChangeStatus} />
              </Col>
            </Row>
            <Row className="">
              <Col md="6">
                <TextInput
                  labelCol="12"
                  inputCol="12"
                  type="price"
                  labelClassName="font-size-16 text-left mb-50"
                  labelName="Tổng sức mua tối đa *"
                  placeholder="Nhập số tiền"
                  setValue={setValue}
                  name="buy_ability"
                  register={{ ...register('buy_ability', { required: true }) }}
                  errors={errors}
                  defaultValue={item ? item.buy_ability : ''}
                />
              </Col>
              <Col md="6">
                <TextInput
                  labelCol="12"
                  inputCol="12"
                  type="price"
                  labelClassName="font-size-16 text-left mb-50"
                  labelName="Nợ margin"
                  placeholder="Nhập số tiền"
                  setValue={setValue}
                  name="margin_debt"
                  register={{ ...register('margin_debt') }}
                  errors={errors}
                  defaultValue={item ? item.margin_debt : ''}
                />
              </Col>
            </Row>
            <Row className="">
              <Col md="6">
                <FieldInput
                  colorRequire="#5e5873"
                  required
                  type="number"
                  placeholder="Phí mua"
                  label="Phí mua"
                  value={buy_fee_percent}
                  onChange={(v) => setValue('buy_fee_percent', v)}
                  errorMessage={errors?.buy_fee_percent}
                  init="%"
                />
              </Col>
              <Col md="6">
                <FieldInput
                  colorRequire="#5e5873"
                  required
                  type="number"
                  placeholder="Phí bán"
                  label="Phí bán"
                  value={sell_fee_percent}
                  onChange={(v) => setValue('sell_fee_percent', v)}
                  errorMessage={errors?.sell_fee_percent}
                  init="%"
                />
              </Col>
            </Row>
            <Row className="">
              <Col md="6">
                <FieldInput
                  colorRequire="#5e5873"
                  required
                  type="number"
                  placeholder="Lãi vay margin"
                  label="Lãi vay margin"
                  value={margin_fee_percent}
                  onChange={(v) => setValue('margin_fee_percent', v)}
                  errorMessage={errors?.margin_fee_percent}
                  init="%"
                />
              </Col>
              <Col md="6">
                <TextInput
                  labelCol="12"
                  inputCol="12"
                  type="price"
                  labelClassName="font-size-16 text-left mb-50"
                  labelName="NAV kho"
                  placeholder="NAV kho"
                  setValue={setValue}
                  name="total_nav"
                  register={{ ...register('total_nav') }}
                  errors={errors}
                  defaultValue={item ? item.total_nav : ''}
                />
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <TextInput
                  labelCol="12"
                  inputCol="12"
                  type="price"
                  labelClassName="font-size-16 text-left mb-50"
                  labelName="Cổ tức tiền"
                  placeholder="Nhập số tiền"
                  setValue={setValue}
                  name="dividend_amount"
                  register={{ ...register('dividend_amount') }}
                  errors={errors}
                  defaultValue={item ? item.dividend_amount : ''}
                />
              </Col>
              <Col md="6">
                <SearchEmployer
                  labelName="Chọn NV phụ trách *"
                  labelCol="12"
                  inputCol="12"
                  labelClassName="font-size-14 mb-50"
                  name="user_id"
                  register={{ ...register('user_id', { required: true }) }}
                  errors={errors}
                  setValue={setValue}
                  defaultValue={item?.user_id}
                />
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <TextInput
                    labelCol="12"
                    inputCol="12"
                    type="price"
                    labelClassName="font-size-16 text-left mb-50"
                    labelName="Phí GD phái sinh"
                    placeholder="Nhập phí/ hd"
                    setValue={setValue}
                    name="derivative_trade_fee"
                    register={{ ...register('derivative_trade_fee') }}
                    errors={errors}
                    defaultValue={item ? item.derivative_trade_fee : ''}
                />
              </Col>
              <Col md="6">
                <TextInput
                    labelCol="12"
                    inputCol="12"
                    type="price"
                    labelClassName="font-size-16 text-left mb-50"
                    labelName="Mã tích hợp BOT"
                    placeholder="Mã tích hợp BOT"
                    setValue={setValue}
                    name="bot_id"
                    register={{ ...register('bot_id') }}
                    errors={errors}
                    defaultValue={item ? item.bot_id : ''}
                />
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <p style={{ color: '#5e5873', fontSize: '1rem' }}>Sản phẩm</p>
                {Object.values(typeConst).map((type) => (
                  <div key={type.type}>
                    <Checkbox
                      id={'checkbox-' + type.type}
                      onChange={() => handleTradeTypeCheck(type.type)}
                      checked={tradeTypeChecked.includes(type.type)}
                    />
                    <Label for={'checkbox-' + type.type}>{type.label}</Label>
                  </div>
                ))}
              </Col>
            </Row>
            <div className="d-line mt-1" />
            <Row className="justify-content-center gap-20 py-2">
              <Button size="lg" color="primary">
                <p className="text-center font-size-16">{item?.id ? 'Sửa' : 'Thêm'}</p>
              </Button>
              <Button type="button" onClick={() => closeModal()}>
                <p className="text-center font-size-16">Hủy</p>
              </Button>
            </Row>
          </form>
        </Container>
      </ModalWrapper>
    </Modal>
  );
}

PopUpUpdate.propTypes = {
  isOpen: PropTypes.bool,
  closeModal: PropTypes.func,
  refetch: PropTypes.func,
  item: PropTypes.object,
};

export default PopUpUpdate;
