import React from 'react';
import PropTypes from 'prop-types';
import { formatVND } from '@Utils/helpers';

function TransactionInfo({ purchaseAccountName, quantityMatching, priceMatching }) {
  return (
    <>
      <div className="item-content">{purchaseAccountName}</div>
      <div className="item-content">{formatVND(quantityMatching)}</div>
      <div className="item-content">{formatVND(priceMatching)}</div>
    </>
  );
}

TransactionInfo.propTypes = {
  purchaseAccountName: PropTypes.string,
  quantityMatching: PropTypes.number,
  priceMatching: PropTypes.number,
};

export default TransactionInfo;
