/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-wrap-multilines */
import ButtonDownloadFile from '@Components/ButtonDownloadFile';
import CustomeModal from '@Components/CustomeModal';
import React, { Component } from 'react';
import { Button } from 'reactstrap';
import PropTypes from 'prop-types';
import { toastError } from '@Utils/toastrHelper';
import { useNavigate } from 'react-router-dom';

class ModalExport extends Component {
  constructor(props) {
    super(props);
    this.state = { isSuccess: false, openWarning: false };
  }

  componentWillUnmount() {
    this.setState = () => {};
  }

  onOpenWarning = () => {
    this.setState({ openWarning: true });
  };

  closeModalWarning = () => {
    this.setState({ openWarning: false });
  };

  handleExport = async () => {
    const { onExport } = this.props;
    if (onExport) {
      try {
        await onExport();
        this.setState({ openWarning: false, isSuccess: true });
      } catch (e) {
        toastError(e);
      }
    }
  };

  setSuccessExport = () => {
    this.setState({ isSuccess: false });
  };

  renderMessageSuccess = () => {
    const { onNavigate } = this.props;
    return (
      <>
        <div className="text-align-center" style={{ fontSize: 24, fontWeight: 500, height: 48 }}>
          Yêu cầu xuất file đã được tạo
        </div>
        <div
          className="text-align-center"
          style={{ fontSize: 20, fontWeight: 400, paddingBottom: 24 }}
        >
          Vui lòng kiểm tra trạng thái ở trang{' '}
          <span style={{ color: '#2F69B3', cursor: 'pointer' }} onClick={onNavigate}>
            Quản lý xuất file
          </span>
        </div>
      </>
    );
  };

  render() {
    const { styleButton, classNameButton, colorText = '#fff', navigate } = this.props;
    const { isSuccess, openWarning } = this.state;

    const { onNavigate = () => navigate('/my-export-management') } = this.props;
    return (
      <>
        <ButtonDownloadFile
          colorText={colorText}
          style={styleButton}
          className={classNameButton}
          onClick={this.onOpenWarning}
        />
        <CustomeModal
          closeModal={this.closeModalWarning}
          title="Xác nhận xuất báo cáo"
          mess="Bạn chắc chắn muốn tạo yêu cầu xuất dữ liệu?"
          isOpen={openWarning}
          type="confirm"
          buttonName="Đồng ý"
          confirm={this.handleExport}
        />
        <CustomeModal
          icon={
            <img
              style={{ marginBottom: 40 }}
              src="/images/customer/ImageExportSuccess.svg"
              alt=""
            />
          }
          width={578}
          hiddenTitle
          closeModal={this.setSuccessExport}
          mess={this.renderMessageSuccess()}
          isOpen={isSuccess}
          type="confirm"
          buttonName="Đồng ý"
          confirm={this.onDownload}
          footer={
            // eslint-disable-next-line react/jsx-wrap-multilines
            <div style={{ borderTop: '1px solid #EBEAED', paddingTop: 16 }}>
              <Button color="primary" onClick={onNavigate}>
                <span className="font-size-14">Quản lý xuất file</span>
              </Button>
            </div>
          }
        />
      </>
    );
  }
}

ModalExport.propTypes = {
  onNavigate: PropTypes.any,
  onExport: PropTypes.any,
  classNameButton: PropTypes.any,
  styleButton: PropTypes.any,
  navigate: PropTypes.any,
  colorText: PropTypes.string,
};

function ModalExportRouter(props) {
  const navigate = useNavigate();
  return <ModalExport {...props} navigate={navigate} />;
}

export default ModalExportRouter;
