export const TABS = [
  {
    id: 0,
    name: 'Tất cả',
    value: '',
  },
  {
    id: 1,
    name: 'Hoạt động',
    value: 'active',
  },
  {
    id: 2,
    name: 'Đang bị khoá',
    value: 'suspended',
  },
];
