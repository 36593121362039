import React, { useCallback, useState } from 'react';
import { Button, Label, Modal } from 'reactstrap';
import PropTypes from 'prop-types';
import FieldInput from '@Views/Client/Recharge/BodyTab/Request/FieldInput';
import { useDispatch } from 'react-redux';
import { hideGlobalLoading, showGlobalLoading } from '@Lib/slices/uiSlice';
import { memberPackageSave } from '@Apis/admin/memberPackage';
import { toastError, toastSuccess } from '@Utils/toastrHelper';
import {Checkbox, Radio} from '@mui/material';

function ModalAddCS({ isOpen, onClose, init = {}, modalType = '' }) {
  const [data, setData] = useState({ trade_type: modalType, ...init });
  const dispatch = useDispatch();

  console.log(data);

  const onChangeValue = useCallback((key, v) => {
    setData((pre) => ({ ...pre, [key]: v }));
  }, []);

  const onSubmit = useCallback(async () => {
    dispatch(showGlobalLoading());
    try {
      await memberPackageSave(data);
      if (data.id) {
        toastSuccess('Sửa gói sản phẩm thành công');
      } else {
        toastSuccess('Thêm gói sản phẩm thành công');
      }
    } catch (e) {
      toastError(e);
    }
    onClose();
    dispatch(hideGlobalLoading());
  }, [data, onClose]);

  return (
    <Modal wrapClassName="modal-update-tplus" isOpen={isOpen} centered>
      <div className="fw-600 text-align-center" style={{ fontSize: 32 }}>
        {data.id ? 'Sửa gói ' : 'Thêm gói '}
        <span style={{ color: '#2F69B3' }}>SP {data.trade_type === 'base' ? 'cơ sở' : 'T+'} {data.name}</span>
      </div>
      <div className="mt-1 d-flex" style={{ gap: 16 }}>
        <FieldInput
          onChange={(v) => onChangeValue('name', v)}
          value={data.name}
          label="Nhập tên gói"
          required
        />
        <FieldInput
          onChange={(v) => onChangeValue('number_of_cycles', v)}
          value={data.number_of_cycles}
          style={{ width: 200 }}
          label="Thời gian (Tháng)"
          type="number"
          required
        />
      </div>
      <div>
        <div style={{ color: '#84818A' }} className="uppercase fw-600">
          Điều kiện gói
        </div>
        <div className="mt-1 d-flex" style={{ gap: 16 }}>
          <FieldInput
            onChange={(v) => onChangeValue('min_transaction_value', v)}
            value={data.min_transaction_value}
            label="Tổng KLGD tối thiểu"
            required
            type="number"
          />
          <FieldInput
            onChange={(v) => onChangeValue('min_stock_value', v)}
            value={data.min_stock_value}
            label="Tổng GT GD tối thiểu"
            type="number"
            required
          />
          <FieldInput
            onChange={(v) => onChangeValue('min_debt', v)}
            value={data.min_debt}
            label="Tổng dư nợ tối thiểu"
            type="number"
            required
          />
        </div>
      </div>
        <div>
            <Label className="label-field-input" for="checkbox-is_non_term_interest">
                Trả lãi không kỳ hạn
            </Label>
            <Checkbox
                id="checkbox-is_non_term_interest"
                onChange={(e) => onChangeValue('is_non_term_interest', e.target.checked)}
                checked={!!data.is_non_term_interest}
            />
            <div hidden={!data.is_non_term_interest}>
                <div className="mt-1 d-flex align-items-center" style={{ marginLeft: -10 }}>
                    <div className="d-flex align-items-center">
                        <Radio checked={data.interest_by === 'debt'} onClick={() => onChangeValue('interest_by', 'debt')} />
                        <span>NAV-GTCK</span>
                    </div>
                    <div className="d-flex align-items-center ml-1">
                        <Radio checked={data.interest_by === 'nav'} onClick={() => onChangeValue('interest_by', 'nav')} />
                        <span>NAV</span>
                    </div>
                    <div className="d-flex align-items-center ml-1">
                        <Radio checked={data.interest_by === 'withdraw'} onClick={() => onChangeValue('interest_by', 'withdraw')} />
                        <span>Dư cọc</span>
                    </div>
            </div>
          </div>
        </div>
        <div hidden={data.trade_type != 'base'}>
            <Label className="label-field-input" for="checkbox-is_non_term_interest111">
                Tính lãi vay trên
            </Label>
            <div>
                <div className="d-flex align-items-center" style={{ marginLeft: -10 }}>
                    <div className="d-flex align-items-center">
                        <Radio checked={data.value_interest_by === 'total_stock_value'} onClick={() => onChangeValue('value_interest_by', 'total_stock_value')} />
                        <span>GTCK</span>
                    </div>
                    <div className="d-flex align-items-center ml-1">
                        <Radio checked={data.value_interest_by === 'total_stock_cost_value'} onClick={() => onChangeValue('value_interest_by', 'total_stock_cost_value')} />
                        <span>Giá trị mua</span>
                    </div>
                </div>
            </div>
        </div>

        <div className="field-input-custom">
            <Label className="label-field-input" for="checkbox-is-default">
                Gói mặc định
            </Label>
            <Checkbox
                id="checkbox-is-default"
                onChange={(e) => onChangeValue('is_default', e.target.checked)}
                checked={!!data.is_default}
            />
        </div>
      <div
        className="mt-1 d-flex justify-content-center"
        style={{ borderTop: '1px solid #EBEAED', padding: 16, gap: 16 }}
      >
        <Button color="primary" style={{ minWidth: 160, height: 48 }} onClick={onSubmit}>
          Lưu
        </Button>
        <Button style={{ minWidth: 160, height: 48 }} onClick={onClose}>
          Hủy
        </Button>
      </div>
    </Modal>
  );
}

ModalAddCS.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  init: PropTypes.object,
  modalType: PropTypes.string,
};

export default ModalAddCS;
