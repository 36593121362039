export const TRADE_STATUS = [
  {
    label: 'Chờ duyệt',
    value: 'created',
    color: '#00A5FF',
  },
  {
    label: 'Chờ vào lệnh',
    value: 'pending',
    color: '#D37E17',
  },
  {
    label: 'Đã duyệt',
    value: 'pending',
    color: '#5542F6',
  },
  {
    label: 'Khớp một phần',
    value: 'part_completed',
    color: '#2F69B3',
  },
  {
    label: 'Đã khớp',
    value: 'completed',
    color: '#14B13B',
  },
  {
    label: 'Hết hạn',
    value: 'expired',
    color: '#504F54',
  },
  {
    label: 'Hủy',
    value: 'cancelled',
    color: '#ED1C24',
  },
  {
    label: 'Đang vào lệnh',
    value: 'in_process',
    color: '#2F69B3',
  },
];

export const TAB_STATUS = [
  {
    id: 1,
    label: 'Chờ duyệt',
    child: ['created'],
    color: '#00A5FF',
    count: 0,
  },
  {
    id: 2,
    label: 'Đã duyệt',
    child: ['pending', 'in_process', 'part_completed', 'completed', 'expired', 'cancelled'],
    color: '#5542f6',
    count: 0,
  },
  {
    id: 3,
    label: 'Đang vào lệnh',
    child: ['pending', 'in_process', 'part_completed'],
    color: '#2F69B3',
    count: 0,
  },
  {
    id: 4,
    label: 'Đã khớp',
    child: ['completed'],
    color: '#14B13B',
    count: 0,
  },
  {
    id: 5,
    label: 'Hết hạn',
    child: ['expired'],
    color: '#504F54',
    count: 0,
  },
  {
    id: 6,
    label: 'Hủy',
    child: ['cancelled'],
    color: '#ED1C24',
    count: 0,
  },
];

export const TABS_POPUP_CREATE = [
  {
    id: 1,
    name: 'MUA',
  },
  {
    id: 2,
    name: 'BÁN',
  },
];
