/* eslint-disable import/no-cycle */
/* eslint-disable no-nested-ternary */
/* eslint-disable indent */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { overviewDashboard } from '@Apis/profile/account';
import { toastError } from '@Utils/toastrHelper';
import { formatNumber, randomId } from '@Utils/helpers';
import TooltipComment from '@Components/TooltipComment';
import GlobalLoading from '@Components/GlobalLoading';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { TYPE_CS, TYPE_PS, TYPE_TPLUS } from '@Components/Layouts/PrivateLayout';
import useCustomQuery from '@Utils/useCustomQuery';
import { accountsHome } from '@Apis/client/home';
import { useParams } from 'react-router-dom';
import { customerOverview } from '@Apis/admin/customer';
import { io } from 'socket.io-client';

class CardInfo extends Component {
  constructor(props) {
    super(props);
    const dataState = props.data ? this.buildData(props.data) : [];
    this.state = { loading: !props.data, dataState };
  }

  UNSAFE_componentWillMount() {
    const { data } = this.props;
    if (!data) {
      this.getData();
    }
    this.connectSocket();
  }

  UNSAFE_componentWillReceiveProps(nProps) {
    const { data } = nProps;
    if (data) {
      this.setState({ dataState: this.buildData(data) });
    }
  }

  shouldComponentUpdate(nProps, nState) {
    const { isGlobalLoading, productType } = this.props;
    const { loading, dataState } = this.state;
    return (
      isGlobalLoading !== nProps.isGlobalLoading ||
      productType !== nProps.productType ||
      loading !== nState.loading ||
      dataState !== nState.dataState
    );
  }

  componentWillUnmount() {
    this.socket?.disconnect();
  }

  buildData = (dataInfo) => {
    const { productType } = this.props;

    const newObj =
      productType === TYPE_CS
        ? [
            {
              icon: '/images/assetManager/stock_value.svg',
              text: <span style={{ fontWeight: 600 }}>Tổng GTCK hiện tại</span>,
              renderMoney: (value) => (
                <span style={{ fontWeight: 600, fontSize: 26 }}>{formatNumber(value)}</span>
              ),
              money: dataInfo.total_stock_value,
              info:
                dataInfo.total_stock_value_change ||
                dataInfo.total_stock_value - dataInfo.total_stock_value_last_day,
              percent: dataInfo.total_stock_value_change_percent,
            },
          ]
        : productType === TYPE_TPLUS
        ? [
            {
              icon: '/images/assetManager/stock_value.svg',
              text: (
                <span style={{ fontWeight: 600 }}>
                  {process.env.DASHBOARD_TYPE === 'admin' ? 'Tổng GTCK' : 'Sức mua T+'}
                </span>
              ),
              renderMoney: (value) => (
                <span style={{ fontWeight: 600, fontSize: 26 }}>
                  {formatNumber(
                    process.env.DASHBOARD_TYPE === 'admin' ? dataInfo.total_stock_value : value,
                  )}
                </span>
              ),
              money: dataInfo.t0_buy_ability,
            },
            // {
            //   icon: '/images/assetManager/stock_value.svg',
            //   text: <span style={{ fontWeight: 600 }}>Sức mua T2</span>,
            //   renderMoney: (value) => (
            //     <span style={{ fontWeight: 600, fontSize: 26 }}>{formatNumber(value)}</span>
            //   ),
            //   money: dataInfo.t2_buy_ability,
            // },
          ]
        : [
            {
              icon: '/images/assetManager/stock_value.svg',
              text: <span style={{ fontWeight: 600 }}>TIỀN KÝ QUỸ BẮT BUỘC</span>,
              renderMoney: (value) => (
                <span style={{ fontWeight: 600, fontSize: 26 }}>{formatNumber(value)}</span>
              ),
              money: dataInfo.margin_require_amount,
              info:
                dataInfo.total_stock_value_change ||
                dataInfo.total_stock_value - dataInfo.total_stock_value_last_day,
            },
            {
              icon: '/images/assetManager/stock_value.svg',
              text: <span style={{ fontWeight: 600 }}>TIỀN GỌI BỔ SUNG/RÚT</span>,
              renderMoney: (value) => (
                <span style={{ fontWeight: 600, fontSize: 26 }}>{formatNumber(value)}</span>
              ),
              money: dataInfo.margin_deposit_require,
              info:
                dataInfo.total_stock_value_change ||
                dataInfo.total_stock_value - dataInfo.total_stock_value_last_day,
            },
            {
              icon: '/images/assetManager/stock_value.svg',
              text: <span style={{ fontWeight: 600 }}>LÃI CỌC THIẾU TẠM TÍNH</span>,
              renderMoney: (value) => (
                <span style={{ fontWeight: 600, fontSize: 26 }}>{formatNumber(value)}</span>
              ),
              money: 0,
              info:
                dataInfo.total_stock_value_change ||
                dataInfo.total_stock_value - dataInfo.total_stock_value_last_day,
            },
          ];

    return [
      {
        icon: '/images/assetManager/Dola.svg',
        text: (
          <div className="d-flex" style={{ fontWeight: 600 }}>
            NAV
            <TooltipComment
              placement="top"
              item={{
                label: (
                  <img
                    style={{ marginLeft: 5, marginBottom: 5 }}
                    src="/images/icon/help.svg"
                    alt=""
                  />
                ),
                note: (
                  <span style={{ fontSize: 12 }}>
                    Tài sản sau khi khấu trừ thuế/phí, chưa bao gồm các khoản phí tạm tính.
                  </span>
                ),
                id: 'client-assetmanager-cardinfo',
              }}
            />
          </div>
        ),
        renderMoney: (value) => (
          <span style={{ fontWeight: 600, fontSize: 26 }}>{formatNumber(value)}</span>
        ),
        money: dataInfo.net_assets || dataInfo.current_nav,
        info: dataInfo.net_assets_change || dataInfo.current_nav - dataInfo.current_nav_last_day,
        percent: dataInfo.net_assets_change_percent,
      },
      ...newObj,
      productType !== TYPE_PS
        ? {
            icon: '/images/assetManager/debt.svg',
            text: (
              <span style={{ fontWeight: 600 }}>
                <span style={{ color: '#14B13B' }}>Số tiền được rút</span>
              </span>
            ),
            renderMoney: (value) => {
              const color = '#14B13B';
              if (value < 0) {
                return (
                  <span style={{ fontWeight: 600, fontSize: 28, color }}>{formatNumber(0)}</span>
                );
              }
              return (
                <span style={{ fontWeight: 600, fontSize: 28, color }}>
                  {`+${formatNumber(value)}`}
                </span>
              );
            },
            money: dataInfo.can_withdraw_amount > 0 ? dataInfo.can_withdraw_amount : 0,
          }
        : undefined,
      productType !== TYPE_PS
        ? {
            icon: '/images/assetManager/debt.svg',
            text: (
              <span style={{ fontWeight: 600 }}>
                <span style={{ color: '#ED1C24' }}>Số tiền bổ sung</span>
              </span>
            ),
            renderMoney: (value) => {
              const color = '#ED1C24';
              return (
                <span style={{ fontWeight: 600, fontSize: 28, color }}>
                  {formatNumber(value >= 0 ? 0 : Math.abs(value))}
                </span>
              );
            },
            money: dataInfo.withdraw_available > 0 ? 0 : dataInfo.withdraw_available,
            note:
              dataInfo.deposit_amount > 0 ? (
                ''
              ) : (
                <span>Thanh toán trước ngày {dayjs().add(1, 'day').format('DD/MM/YYYY')}</span>
              ),
          }
        : undefined,
    ].filter(Boolean);
  };

  getData = () => {
    const { handleSetInfo, accountId } = this.props;

    if (process.env.DASHBOARD_TYPE === 'admin') {
      customerOverview({ id: accountId })
        .then((res) => {
          this.setState({ dataState: this.buildData(res || {}), loading: false });
          handleSetInfo?.(res);
        })
        .catch((e) => {
          toastError(e);
          this.setState({ loading: false });
        });
    } else {
      overviewDashboard({ customer_id: accountId })
        .then(({ data }) => {
          const dataInfo = data?.data || {};
          this.setState({ dataState: this.buildData(dataInfo), loading: false });
          handleSetInfo?.(dataInfo);
        })
        .catch((e) => {
          toastError(e);
          this.setState({ loading: false });
        });
    }
  };

  connectSocket = () => {
    const { handleSetInfo, accountId } = this.props;
    const token = localStorage.getItem(process.env.TOKEN);
    this.socket = io(
      `${process.env.BACKEND_SOCKET}/${process.env.DASHBOARD_TYPE}/account/overview`,
      {
        query: { token },
        transports: ['websocket'],
      },
    );
    this.socket.on('connect', () => {
      this.socket.emit('register', 'your-client-id', { customer_id: accountId });
    });
    this.socket.on('data', ({ data }) => {
      this.setState({ dataState: this.buildData(data), loading: false });
      handleSetInfo?.(data);
    });
  };

  renderValue = (value, percent) => {
    if (value || value === 0) {
      if (value < 0) {
        return (
          <span style={{ color: '#ED1C24' }}>
            <img src="/images/assetManager/south_east.svg" alt="" />
            {percent}%<span style={{ marginLeft: 12 }}>{formatNumber(value)}</span>
          </span>
        );
      }
      return (
        <span style={{ color: '#14B13B' }}>
          <img src="/images/assetManager/call_made.svg" alt="" />
          {percent}%
          <span style={{ marginLeft: 12 }}>
            {value > 0 ? '+' : ''}
            {formatNumber(value)}
          </span>
        </span>
      );
    }
  };

  render() {
    const { isGlobalLoading } = this.props;
    const { loading, dataState } = this.state;
    return (
      <>
        <GlobalLoading
          loading={isGlobalLoading ? false : loading}
          style={{ position: 'absolute' }}
        />
        {dataState.map((d, i) => (
          <div className="card-info" key={i.toString()}>
            <div className="d-flex">
              <img src={d.icon} alt="" />
              <span style={{ marginLeft: 12 }}>{d.text}</span>
            </div>
            <div style={{ marginTop: 5 }}>{d.renderMoney(d.money)}</div>
            <div style={{ marginTop: 8, fontSize: 14 }}>
              {d.note || this.renderValue(d.info, d.percent)}
            </div>
          </div>
        ))}
      </>
    );
  }
}

function SwapLoadingGlobal(props) {
  const { type, accounts } = props;
  const isGlobalLoading = useSelector((state) => state.ui.isGlobalLoading);
  const productType = useSelector((state) => state.ui.productType);
  const { customerId } = useParams();

  const { data: dataAccounts } = useCustomQuery(
    process.env.DASHBOARD_TYPE === 'admin'
      ? () => ({
          data: {},
        })
      : accounts
      ? () => ({
          data: { accounts },
        })
      : accountsHome,
    { customer_id: customerId },
  );
  const account = (dataAccounts?.accounts || accounts)?.find(
    (e) => e.trade_type === (type || productType),
  );
  if (!account) return null;
  return (
    <CardInfo
      isGlobalLoading={isGlobalLoading}
      productType={type || productType}
      accountId={account?.id}
      {...props}
    />
  );
}

SwapLoadingGlobal.propTypes = {
  type: PropTypes.any,
  accounts: PropTypes.any,
};

CardInfo.propTypes = {
  handleSetInfo: PropTypes.func,
  isGlobalLoading: PropTypes.bool,
  data: PropTypes.any,
  productType: PropTypes.any,
  accountId: PropTypes.any,
};

export default SwapLoadingGlobal;
