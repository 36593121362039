/* eslint-disable import/no-cycle */
import React from 'react';
import { Container } from 'reactstrap';
import { customerOverview } from '@Apis/admin/customer';
import { useParams } from 'react-router-dom';
import { formatNumber } from '@Utils/helpers';
import TooltipComment from '@Components/TooltipComment';
import dayjs from 'dayjs';
import PropsType from 'prop-types';
import useCustomQuery from '@Utils/useCustomQuery';
import { SummaryWrapper } from './SummaryStyles';

const buildData = (dataInfo) => [
  {
    icon: '/images/assetManager/Dola.svg',
    text: (
      <div className="d-flex" style={{ fontWeight: 600 }}>
        NAV
        <TooltipComment
          placement="top"
          item={{
            label: (
              <img style={{ marginLeft: 5, marginBottom: 5 }} src="/images/icon/help.svg" alt="" />
            ),
            note: (
              <span style={{ fontSize: 12 }}>
                Tài sản sau khi khấu trừ thuế/phí, chưa bao gồm các khoản phí tạm tính.
              </span>
            ),
            id: 'admin-customer-detail-summary',
          }}
        />
      </div>
    ),
    renderMoney: (value) => (
      <span style={{ fontWeight: 600, fontSize: 26 }}>{formatNumber(value)}</span>
    ),
    money: dataInfo.net_assets || dataInfo.current_nav,
    info: dataInfo.net_assets_change || dataInfo.current_nav_change,
    percent: dataInfo.net_assets_change_percent || dataInfo.current_nav_change_percent,
  },
  {
    icon: '/images/assetManager/stock_value.svg',
    text: <span style={{ fontWeight: 600 }}>Tổng GTCK hiện tại</span>,
    renderMoney: (value) => (
      <span style={{ fontWeight: 600, fontSize: 26 }}>{formatNumber(value)}</span>
    ),
    money: dataInfo.total_stock_value,
    info: dataInfo.total_stock_value_change,
    percent: dataInfo.total_stock_value_change_percent,
  },
  {
    icon: '/images/assetManager/debt.svg',
    text: (
      <span style={{ fontWeight: 600 }}>
        <span style={{ color: '#ED1C24' }}>Số tiền bổ sung</span>
      </span>
    ),
    renderMoney: (value) => {
      return (
        <span style={{ fontWeight: 600, fontSize: 28, color: value > 0 ? '#14B13B' : '#ED1C24' }}>
          {formatNumber(value >= 0 ? 0 : Math.abs(value))}
        </span>
      );
    },
    money: dataInfo.withdraw_available > 0 ? 0 : dataInfo.withdraw_available,
  },
  {
    icon: '/images/assetManager/debt.svg',
    text: (
        <span style={{ fontWeight: 600 }}>
        <span style={{ color: '#14B13B' }}>Số tiền được rút</span>
      </span>
    ),
    renderMoney: (value) => {
      return (
        <span style={{ fontWeight: 600, fontSize: 28, color: '#14B13B' }}>
          {`${formatNumber(value)}`}
        </span>
      );
    },
    money: dataInfo.can_withdraw_amount > 0 ? dataInfo.can_withdraw_amount : 0,
    note:
      (dataInfo.deposit_amount || dataInfo.withdraw_available) >= 0 ? (
        ''
      ) : (
        <span>Thanh toán trước ngày {dayjs().add(1, 'day').format('DD/MM/YYYY')}</span>
      ),
  },
];

function Summary({ overview, type }) {
  const { customerId } = useParams();
  const { data: dataSummary } = useCustomQuery(customerOverview, { id: customerId, type }, true, {
    enabled: !!customerId,
  });

  const renderValue = (value, percent) => {
    if (value || value === 0) {
      if (value < 0) {
        return (
          <span style={{ color: '#ED1C24' }}>
            <img src="/images/assetManager/south_east.svg" alt="" />
            {percent}%<span style={{ marginLeft: 12 }}>{formatNumber(value)}</span>
          </span>
        );
      }
      return (
        <span style={{ color: '#14B13B' }}>
          <img src="/images/assetManager/call_made.svg" alt="" />
          {percent}%
          <span style={{ marginLeft: 12 }}>
            {value > 0 ? '+' : ''}
            {formatNumber(value)}
          </span>
        </span>
      );
    }
  };

  const newDataSummary = overview || dataSummary;

  return (
    <div className="mb-2 asset-management" style={{ padding: 0 }}>
      {newDataSummary && (
        <SummaryWrapper>
          <Container className="summary bg-white mb-1" style={{ padding: 0 }}>
            <div className="body-asset" style={{ paddingTop: 0, minHeight: 168 }}>
              {buildData(newDataSummary).map((d, i) => (
                <div className="card-info" key={i.toString()}>
                  <div className="d-flex">
                    <img src={d.icon} alt="" />
                    <span style={{ marginLeft: 12 }}>{d.text}</span>
                  </div>
                  <div style={{ marginTop: 25 }}>{d.renderMoney(d.money)}</div>
                  <div style={{ marginTop: 8, fontSize: 14 }}>
                    {d.note || renderValue(d.info, d.percent)}
                  </div>
                </div>
              ))}
            </div>
          </Container>
          <div className="info" style={{ fontSize: 14 }}>
            <div className="card-info-body">
              <div className="line-card-info">
                <div style={{ color: '#84818A' }}>
                  <span>Lãi vay lũy kế</span>
                  <span
                    style={{
                      display: 'inline-block',
                      cursor: 'pointer',
                      marginLeft: 10,
                    }}
                  >
                    <TooltipComment
                      placement="right"
                      item={{
                        label: (
                          <img
                            style={{ marginLeft: 5, marginBottom: 5, maxWidth: 15 }}
                            src="/images/icon/infor.jpeg"
                            alt=""
                          />
                        ),
                        note: (
                          <span>
                            <ul
                              style={{
                                paddingLeft: 10,
                                marginBottom: 5,
                                textAlign: 'left',
                                fontSize: 12,
                              }}
                            >
                              <li>
                                Lãi vay tạm tính:{' '}
                                {formatNumber(newDataSummary?.estimate_interest_fee, '--')}
                              </li>
                              <li>
                                Lãi suất không kỳ hạn tạm tính:{' '}
                                {formatNumber(newDataSummary?.estimate_non_term_interest_fee, '--')}
                              </li>
                              <li>
                                Lãi vay đã thực hiện:{' '}
                                {`${formatNumber(
                                  newDataSummary?.interest_fee -
                                    newDataSummary?.estimate_interest_fee,
                                  '--',
                                )}`}
                              </li>
                            </ul>
                          </span>
                        ),
                        id: 'admin-customer-detail-lai-vay-luy-ke',
                      }}
                    />
                  </span>
                </div>
                <div style={{ color: '#2E2C34' }}>
                  {formatNumber(newDataSummary?.interest_fee, '--')}
                </div>
              </div>
              <div className="line-card-info">
                <div style={{ color: '#84818A' }}>Phí giao dịch (Mua/Bán)</div>
                <div style={{ color: '#2E2C34' }}>
                  {formatNumber(newDataSummary?.buy_sell_fee, '--')}
                </div>
              </div>
              <div className="line-card-info">
                <div style={{ color: '#84818A' }}>Phí bán tạm thu</div>
                <div style={{ color: '#2E2C34' }}>
                  {formatNumber(newDataSummary?.estimated_sell_fee, '--')}
                </div>
              </div>
            </div>
            <div className="card-info-body">
              <div className="line-card-info">
                <div style={{ color: '#84818A' }}>Tổng GTGD tháng (Lũy kế)</div>
                <div style={{ color: '#2E2C34' }}>
                  {formatNumber(newDataSummary?.month_transaction_value, '--')}
                </div>
              </div>
              <div className="line-card-info">
                <div style={{ color: '#84818A' }}>Tổng GTGD mua</div>
                <div style={{ color: '#2E2C34' }}>
                  {formatNumber(newDataSummary?.month_buy_transaction_value, '--')}
                </div>
              </div>
              <div className="line-card-info">
                <div style={{ color: '#84818A' }}>Tổng GTGD bán</div>
                <div style={{ color: '##ED1C24' }}>
                  {formatNumber(newDataSummary?.month_sell_transaction_value, '--')}
                </div>
              </div>
            </div>
            <div className="card-info-body">
              <div className="line-card-info">
                <div style={{ color: '#84818A' }}>Lãi/lỗ lũy kế</div>
                <div style={{ color: newDataSummary?.current_pnl >= 0 ? '#14B13B' : '#ED1C24' }}>
                  {newDataSummary?.current_pnl > 0 ? '+' : ''}
                  {formatNumber(newDataSummary?.current_pnl, '--')}
                </div>
              </div>
              <div className="line-card-info">
                <div style={{ color: '#84818A' }}>Tỷ lệ đảm bảo tài khoản</div>
                <div style={{ color: '#2E2C34' }}>
                  {formatNumber(newDataSummary?.current_safety_rate, '--')}%
                </div>
              </div>
              <div className="line-card-info">
                <div style={{ color: '#84818A' }}>Tỷ lệ tài khoản hiện tại</div>
                <div style={{ color: '##ED1C24' }}>
                  {`${formatNumber(newDataSummary?.current_reality_margin_percent, '--')}%`}
                </div>
              </div>
            </div>
          </div>
        </SummaryWrapper>
      )}
    </div>
  );
}

Summary.propTypes = {
  overview: PropsType.object,
  type: PropsType.any,
};

export default Summary;
