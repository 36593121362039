import React from 'react';
import Title from '@Components/Title';
import './styles.scss';
import BodyTab from './BodyTab';

function Recharge() {
  return (
    <div className="charge-money">
      <Title name="Yêu cầu nộp/rút" />
      <BodyTab />
    </div>
  );
}

export default Recharge;
