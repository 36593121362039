import styled from 'styled-components';

export const TransactionWrapper = styled.div`
  .transaction-navigator-bar {
    border-bottom: none;
  }
  .nav-item:nth-child(1) {
    .active {
      span {
        color: #14b13b !important;
      }
    }
    .nav-link:after {
      background: #14b13b !important;
    }
  }
  .nav-item:nth-child(2) {
    .active {
      span {
        color: #fc3400 !important;
      }
    }
    .nav-link:after {
      background: #fc3400 !important;
    }
  }
  .transaction-sell {
    border: 1px solid #fc3400;
    border-radius: 8px;
  }
  .transaction-buy {
    border: 1px solid #14b13b;
    border-radius: 8px;
  }
  .search-ticker {
    // overflow-y: scroll;
  }
  .bg-sell {
    background: #fff7f7;
  }
  .bg-buy {
    background: rgba(20, 177, 59, 0.1);
  }
  .btn-danger {
    background: #fc3400;
    border-radius: 0;
    padding: 15px 20px;
  }
  .btn-success {
    background: #14b13b;
    border-radius: 0;
    padding: 15px 20px;
  }
  .transaction-buy,
  .transaction-sell {
    .rdt_Table {
      height: 379px;
      overflow-y: auto;
      overflow-x: hidden;
    }
  }
`;

export const TableWrapper = styled.div`
  > div {
    overflow-y: scroll;
    overflow-x: hidden;
  }
`;
