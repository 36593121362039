import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TableEmpty from '@Components/DataTableBasic/TableEmpty';
import CollapseRole from './CollapseRole';

class TableRole extends Component {
  render() {
    const {
      data = [],
      roles = [],
      allowEdit,
      rolesUser = {},
      listRoles,
      onChange,
      handleChangeRoleUser,
    } = this.props;

    if (!data.length) {
      return (
        <tr styke={{ position: 'relative' }}>
          <td
            className="d-flex justify-content-center w-100"
            style={{ position: 'absolute', top: 0, left: 0 }}
          >
            <TableEmpty />
          </td>
        </tr>
      );
    }

    return data.map((e, i) => (
      <CollapseRole
        handleChangeRoleUser={handleChangeRoleUser}
        onChange={onChange}
        listRoles={listRoles}
        allowEdit={allowEdit}
        roles={roles}
        rolesUser={rolesUser}
        key={i.toString()}
        data={e}
      />
    ));
  }
}

TableRole.propTypes = {
  data: PropTypes.array,
  roles: PropTypes.array,
  listRoles: PropTypes.array,
  rolesUser: PropTypes.object,
  allowEdit: PropTypes.bool,
  onChange: PropTypes.func,
  handleChangeRoleUser: PropTypes.func,
};

export default TableRole;
