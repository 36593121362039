export const TABS = [
  {
    id: 0,
    name: 'Tất cả',
    value: '',
  },
  {
    id: 1,
    name: 'Nộp tiền',
    value: 'deposit',
  },
  {
    id: 2,
    name: 'Rút tiền',
    value: 'withdraw',
  },
];

export const TABS_POPUP_CREATE = [
  {
    id: 1,
    name: 'Rút tiền',
    value: 'withdraw',
  },
  {
    id: 2,
    name: 'Nộp tiền',
    value: 'deposit',
  },
];

export const STATUS_BUTTON = [
  {
    value: 'completed',
    name: 'ĐÃ DUYỆT',
    color: '#14B13B',
    nameBtnEdit: 'ĐÃ DUYỆT',
  },
  {
    value: 'pending',
    name: 'CHỜ DUYỆT',
    color: '#EC602E',
    nameBtnEdit: 'CHỜ DUYỆT',
  },
  {
    value: 'rejected',
    name: 'ĐÃ HUỶ',
    color: '#828282',
    nameBtnEdit: 'HUỶ',
  },
];
