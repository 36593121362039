/* eslint-disable import/no-cycle */
import { randomId } from '@Utils/helpers';
import FieldInput from '@Views/Client/Recharge/BodyTab/Request/FieldInput';
import React, { useState, useCallback, useEffect } from 'react';
import { Modal } from 'reactstrap';
import { AddCircle, Delete } from '@mui/icons-material';
import PropTypes from 'prop-types';
import ButtonSubmit from '@Components/ButtonSubmit';
import DatePicker from '@Components/DatePicker';
import { stockConfig, stockDerivative } from '@Apis/admin/stock';
import { useSelector } from 'react-redux';
import useCustomMutation from '@Utils/useCustomMuation';
import DropdownSelect from '@Components/DropdownSelect';
import dayjs from 'dayjs';
import { toastError, toastSuccess } from '@Utils/toastrHelper';

function ModalUpdateTPlus({ isOpen, onCancel, isPS, refetch }) {
  const [listSelect, setListSelect] = useState([{ id: randomId(20) }]);
  const [errors, setErrors] = useState({});

  const {
    mutate: mutateTplus,
    isSuccess: isSuccessTP,
    error: errorTP,
  } = useCustomMutation(stockConfig, true);
  const {
    mutate: mutatePS,
    isSuccess: isSuccessPs,
    error: errorPs,
  } = useCustomMutation(stockDerivative, true);

  const { warehouses } = useSelector(({ transaction, ui }) => ({
    warehouses: transaction.warehouse,
    productType: ui.productType,
  }));

  useEffect(() => {
    if (isSuccessPs) {
      toastSuccess('Cập nhật thành công');
      onClose();
      refetch?.();
    } else if (errorPs) {
      toastError(errorPs);
    }
  }, [isSuccessPs, errorPs]);

  useEffect(() => {
    if (isSuccessTP) {
      toastSuccess('Cập nhật thành công');
      onClose();
      refetch?.();
    } else if (errorTP) {
      toastError(errorTP);
    }
  }, [isSuccessTP, errorTP]);

  const handleAdd = useCallback(() => {
    setListSelect((pre) => [...pre, { id: randomId(20) }]);
  }, []);

  const onRemove = useCallback((idRemove) => {
    setListSelect((pre) => pre.filter((el) => el.id !== idRemove));
  }, []);

  const onClose = useCallback(() => {
    setListSelect([{ id: randomId(20) }]);
    onCancel();
  }, []);

  const setValue = useCallback(
    (key, value, id) => {
      if (errors?.[id]?.[key]) {
        errors[id][key] = '';
        setErrors(errors);
      }
      setListSelect((pre) => pre.map((e) => (e.id === id ? { ...e, [key]: value } : e)));
    },
    [errors],
  );

  const onSubmit = useCallback(() => {
    if (!isPS) {
      const errors = {};
      listSelect.forEach((e) => {
        if (!e.stock_id) {
          if (!errors[e.id]) errors[e.id] = {};
          errors[e.id].stock_id = 'Vui lòng nhập mã CK';
        }
        if (!e.quantity) {
          if (!errors[e.id]) errors[e.id] = {};
          errors[e.id].quantity = 'Vui lòng nhập khối lượng';
        }
        if (!e.purchase_account_id) {
          if (!errors[e.id]) errors[e.id] = {};
          errors[e.id].purchase_account_id = 'Vui lòng chọn kho';
        }
        if (!e.valid_at) {
          if (!errors[e.id]) errors[e.id] = {};
          errors[e.id].valid_at = 'Vui lòng chọn ngày bắt đầu';
        }
        if (!e.valid_to) {
          if (!errors[e.id]) errors[e.id] = {};
          errors[e.id].valid_to = 'Vui lòng chọn ngày kết thúc';
        }
      });
      setErrors(errors);
      if (Object.keys(errors).length) return;
      mutateTplus({ data: listSelect.map((e) => ({ ...e, id: null })) });
    } else {
      const errors = {};
      listSelect.forEach((e) => {
        if (!e.stock_id) {
          if (!errors[e.id]) errors[e.id] = {};
          errors[e.id].stock_id = 'Vui lòng nhập mã CK';
        }
        if (!e.name) {
          if (!errors[e.id]) errors[e.id] = {};
          errors[e.id].name = 'Vui lòng nhập mã HĐ';
        }
        if (!e.expiration_at) {
          if (!errors[e.id]) errors[e.id] = {};
          errors[e.id].expiration_at = 'Vui lòng chọn ngày hết hạn';
        }
      });
      setErrors(errors);
      if (Object.keys(errors).length) return;
      mutatePS({
        data: listSelect.map((e) => ({
          id: e.stock_id,
          name: e.name,
          expiration_at: e.expiration_at,
        })),
      });
    }
  }, [listSelect, isPS]);

  return (
    <Modal
      isOpen={isOpen}
      centered
      modalClassName={`modal-update-tplus ${!isPS ? 'modal-tplus' : ''}`}
    >
      <div>
        <h3 className="text-align-center mt-1">
          {!isPS ? 'Cập nhật danh mục mã T+' : 'Cập nhật Hợp đồng phái sinh'}
        </h3>
      </div>
      <div className="mt-2">
        {listSelect.map((select) => (
          <div className="d-flex content-update-plus" key={select.id}>
            {!isPS ? (
              <>
                <FieldInput
                  onChange={(v) => setValue('stock_id', v, select.id)}
                  name="stock_id"
                  label="Nhập mã CK"
                  required
                  value={select.stock_id || ''}
                  errorMessage={errors[select.id]?.stock_id}
                />
                <FieldInput
                  onChange={(v) => setValue('quantity', v, select.id)}
                  label="KL được phép giao dịch"
                  name="quantity"
                  required
                  type="number"
                  value={select.quantity || ''}
                  errorMessage={errors[select.id]?.quantity}
                />
                <FieldInput
                  required
                  name="purchase_account_id"
                  label="Chọn kho"
                  options={warehouses}
                  labelShow={false}
                  setValueSelected={(_, v) => {
                    setValue('purchase_account_id', v, select.id);
                  }}
                  errorMessage={errors[select.id]?.purchase_account_id}
                  valueSelected={select.purchase_account_id}
                  value={select.purchase_account_id}
                  Component={DropdownSelect}
                />
                <FieldInput
                  required
                  value={select.valid_at ? dayjs.unix(select.valid_at) : undefined}
                  onChange={(v) => setValue('valid_at', dayjs(v).unix(), select.id)}
                  name="valid_at"
                  label="Ngày bắt đầu"
                  Component={DatePicker}
                  errorMessage={errors[select.id]?.valid_at}
                />
                <FieldInput
                  required
                  value={select.valid_to ? dayjs.unix(select.valid_to) : undefined}
                  onChange={(v) => setValue('valid_to', dayjs(v).unix(), select.id)}
                  name="valid_to"
                  label="Ngày kết thúc"
                  Component={DatePicker}
                  errorMessage={errors[select.id]?.valid_to}
                />
              </>
            ) : (
              <>
                <FieldInput
                  errorMessage={errors[select.id]?.stock_id}
                  value={select.stock_id || ''}
                  onChange={(v) => setValue('stock_id', v, select.id)}
                  placeholder="Mã Hợp đồng"
                  label="Mã Hợp đồng"
                  name="stock_id"
                />
                <FieldInput
                  errorMessage={errors[select.id]?.name}
                  value={select.name || ''}
                  onChange={(v) => setValue('name', v, select.id)}
                  placeholder="Tên hợp đồng"
                  name="name"
                  label="Tên hợp đồng"
                />
                <FieldInput
                  errorMessage={errors[select.id]?.expiration_at}
                  value={select.expiration_at ? dayjs.unix(select.expiration_at) : undefined}
                  onChange={(v) => setValue('expiration_at', dayjs(v).unix(), select.id)}
                  name="expiration_at"
                  label="Ngày hết hạn"
                  Component={DatePicker}
                />
              </>
            )}
            <div className="icon-remove">
              <Delete onClick={() => onRemove(select.id)} style={{ color: '#E43925' }} />
            </div>
          </div>
        ))}
        <div
          className="mt-1 d-flex align-items-center"
          role="button"
          tabIndex={0}
          style={{ width: 155 }}
          onClick={handleAdd}
        >
          <AddCircle style={{ color: '#2F69B3' }} fontSize="16px" />
          <span style={{ color: '#2F69B3', marginLeft: 5, fontWeight: 600 }}>
            {isPS ? 'Thêm Hợp đồng' : 'Thêm mã'}
          </span>
        </div>
      </div>
      <div className="w-100 mt-1" style={{ borderBottom: '1px solid #EBEAED' }} />
      <div className="d-flex align-items-center mt-1 justify-content-center" style={{ gap: 16 }}>
        <ButtonSubmit name="Lưu" className="px-2" size="md" color="primary" onClick={onSubmit} />
        <ButtonSubmit name="Hủy" className="px-2" size="md" onClick={onClose} />
      </div>
    </Modal>
  );
}

ModalUpdateTPlus.propTypes = {
  isOpen: PropTypes.bool,
  isPS: PropTypes.bool,
  onCancel: PropTypes.func,
  refetch: PropTypes.func,
};

export default ModalUpdateTPlus;
