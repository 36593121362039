import styled from 'styled-components';

export const Title = styled.div`
  font-size: 40px;
  text-align: center;
  padding: 30px 0 20px 0;
  font-weight: bold;
`;

export const BackButton = styled.button`
  background: #f6583a;
  border-radius: 35.1px;
  outline: none;
  border: none;
  color: white;
  padding: 15px 60px;
  margin-top: 30px;
`;
