/* eslint-disable react/prop-types */
import { formatNumber } from '@Utils/helpers';
import dayjs from 'dayjs';
import React from 'react';
import TooltipComment from '@Components/TooltipComment';
import { UncontrolledDropdown, DropdownMenu, DropdownToggle, DropdownItem } from 'reactstrap';
import { Square, MoreHoriz, Edit, Delete } from '@mui/icons-material';

export function TitleDownline({ lineOne, lineTwo }) {
  return (
    <div className="text-center">
      <p>{lineOne}</p>
      <p>{lineTwo}</p>
    </div>
  );
}
function StatusButton({ status, item, setItemEdit, setItemChangeStatus }) {
  return (
    <UncontrolledDropdown className="nav-item">
      <DropdownToggle
        color="none"
        className="font-size-14 text-white text-uppercase"
        onClick={(e) => e.preventDefault()}
        caret
      >
        <MoreHoriz color="primary" fontSize="large" />
      </DropdownToggle>
      <DropdownMenu right>
        {item.is_settlement ? null : (
          <DropdownItem className="w-100" onClick={() => setItemEdit({ ...item })}>
            <Edit color="primary" fontSize="12px" />
            <span className="align-middle font-size-12 font-weight-bolder ml-50 text-primary">
              SỬA THÔNG TIN
            </span>
          </DropdownItem>
        )}
        <DropdownItem
          className="w-100"
          disabled={item.is_settlement === 1}
          onClick={() =>
            setItemChangeStatus({
              itemChange: {
                is_settlement: true,
                modalType: 'confirm',
                modalTitle: 'ĐÃ TẤT TOÁN',
              },
              item,
            })
          }
        >
          <Square color="disable" fontSize="12px" />
          <span className="align-middle font-size-12 font-weight-bolder ml-50 text-secondary">
            ĐÃ TẤT TOÁN
          </span>
        </DropdownItem>
        <DropdownItem
          className="w-100"
          disabled={item.is_settlement === 1 && status === 'paid'}
          onClick={() =>
            setItemChangeStatus({
              itemChange: {
                is_paid: true,
                modalType: 'confirm',
                modalTitle: 'ĐÃ THANH TOÁN',
              },
              item,
            })
          }
        >
          <Square color="success" fontSize="12px" />
          <span className="align-middle font-size-12 font-weight-bolder ml-50 text-success">
            ĐÃ THANH TOÁN
          </span>
        </DropdownItem>
        <DropdownItem
          className="w-100"
          onClick={() =>
            setItemChangeStatus({
              itemChange: {
                status: 'delete',
                modalType: 'delete',
                modalTitle: ' BẠN MUỐN XOÁ KHO CHO VAY NÀY',
              },
              item,
            })
          }
        >
          <Delete color="error" fontSize="12px" />
          <span className="align-middle font-size-12 font-weight-bolder ml-50 text-danger">
            XOÁ
          </span>
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  );
}

export const columns = ({ setItemEdit, setItemChangeStatus }) => {
  return [
    {
      name: 'Khách hàng',
      cell: (row) => (
        <div className="white-space-norwrap font-weight-bolder uppercase">
          {row.publicCustomer?.username}
        </div>
      ),
      width: '170px',
    },
    {
      name: <TitleDownline lineOne="Ngày" lineTwo="Cho vay" />,
      cell: (row) => (
        <div className="">
          {row.start_at ? dayjs.unix(row.start_at).format('DD/MM/YYYY') : '--'}
        </div>
      ),
      center: true,
      sortable: true,
      sortField: 'start_at',
    },
    {
      name: 'Số tiền',
      cell: (row) => (
        <div className="w-100 text-align-end white-space-nowrap">
          {formatNumber(row.amount, '--')}
        </div>
      ),
      width: '150px',
      center: true,
      sortable: true,
      sortField: 'amount',
    },
    {
      name: <TitleDownline lineOne="Lãi suất" lineTwo="(Ngày)" />,
      cell: (row) => <div className="">{row.interest_rate} %</div>,
      center: true,
      sortable: true,
      sortField: 'interest_rate',
    },
    {
      name: <TitleDownline lineOne="Thời hạn" lineTwo="(Ngày)" />,
      cell: (row) => <div className="">{formatNumber(row.number_of_days, '--')}</div>,
      center: true,
      sortable: true,
      sortField: 'number_of_days',
    },
    {
      name: <TitleDownline lineOne="Ngày" lineTwo="Đáo hạn" />,
      cell: (row) => <div className="">{dayjs.unix(row.due_at).format('DD/MM/YYYY')}</div>,
      center: true,
      sortable: true,
      sortField: 'due_at',
    },
    {
      name: <TitleDownline lineOne="Ngày" lineTwo="Tất toán" />,
      cell: (row) => (
        <div className="">
          {row.settlement_at ? dayjs.unix(row.settlement_at).format('DD/MM/YYYY') : '--'}
        </div>
      ),
      center: true,
      sortable: true,
      sortField: 'settlement_at',
    },
    {
      name: <TitleDownline lineOne="Thời gian" lineTwo="còn lại" />,
      cell: (row) =>
        formatNumber(row.is_settlement === 0 ? row.number_of_days - row.current_days : 0, '--'),
      center: true,
      sortable: true,
      sortField: 'remaining_days',
    },
    {
      name: <TitleDownline lineOne="Lãi chưa" lineTwo="tất toán" />,
      cell: (row) => (
        <div className="white-space-nowrap w-100 text-align-end">
          {formatNumber(row.provisional_amount, '--')}
        </div>
      ),
      width: '150px',
      center: true,
      sortable: true,
      sortField: 'provisional_amount',
    },
    {
      name: <TitleDownline lineOne="Lãi đã" lineTwo="tất toán" />,
      cell: (row) => (
        <div className="white-space-nowrap w-100 text-align-end">
          {formatNumber(row.interest_amount, '--')}
        </div>
      ),
      width: '150px',
      center: true,
      sortable: true,
      sortField: 'interest_amount',
    },
    {
      name: <TitleDownline lineOne="Lãi đã" lineTwo="thanh toán" />,
      cell: (row) => (
        <div className="white-space-nowrap w-100 text-align-end">
          {formatNumber(row.paid_amount, '--')}
        </div>
      ),
      width: '150px',
      center: true,
      sortable: true,
      sortField: 'paid_amount',
    },
    {
      name: 'Ghi chú',
      cell: (row) => <div style={{ wordBreak: 'break-word' }}>{row.note}</div>,
      width: '170px',
    },
    {
      name: '',
      cell: (row) => (
        <div className="white-space-nowrap">
          <StatusButton
            status={row.status}
            item={row}
            setItemEdit={setItemEdit}
            setItemChangeStatus={setItemChangeStatus}
          />
        </div>
      ),
    },
  ];
};
