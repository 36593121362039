import React from 'react';
import PropTypes from 'prop-types';
import { Spinner } from 'reactstrap';

function TableLoader(props) {
  return (
    <div className="empty-datatable">
      <div>
        <div className="spinner-list">
          <Spinner type="grow" size="sm" color="primary" className="mr-25" />
          <Spinner type="grow" size="sm" color="secondary" className="mr-25" />
          <Spinner type="grow" size="sm" color="success" className="mr-25" />
          <Spinner type="grow" size="sm" color="danger" className="mr-25" />
          <Spinner type="grow" size="sm" color="warning" />
        </div>
      </div>
    </div>
  );
}

TableLoader.propTypes = {};

export default TableLoader;
