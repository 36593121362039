import axiosService from '@Lib/axiosService';
import { handleResponse } from '@Utils/helpers';

let url = '';
if (process.env.DASHBOARD_TYPE === 'admin') {
  url = `${process.env.API_ADMIN_URL}/report`;
}

export const getItemReport = async (query) => {
  const res = await axiosService.get(`${url}/get-item`, query);
  return handleResponse(res);
};

export const transactionReport = async (query) => {
  // const { dateRange, ...restQuery } = query;
  const res = await axiosService.get(`${url}/transactions`, query);
  return handleResponse(res);
};

export const profitReport = async (query) => {
  const res = await axiosService.get(`${url}/profit`, query);
  return handleResponse(res);
};

export const commissionReport = async (query) => {
  const res = await axiosService.get(`${url}/commission`, query);
  return handleResponse(res);
};

export const editReport = async (body) => {
  const res = await axiosService.post(`${url}/save-report-item`, body);
  return handleResponse(res);
};
