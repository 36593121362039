/* eslint-disable react/prop-types */
import React from 'react';
import { formatVND } from '@Utils/helpers';
import dayjs from 'dayjs';
import {
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
  Button,
} from 'reactstrap';
import { Square } from '@mui/icons-material';
import Type from '@Components/PaymentRequest/Type';
import Tooltip from '@Components/TooltipNew';

function StatusButton({ status, changeStatus, item }) {
  let buttonName;
  let buttonColor;
  let isButtonDropDown = false;
  switch (status) {
    case 'completed':
      buttonName = 'ĐÃ DUYỆT';
      buttonColor = 'success';
      break;
    case 'pending':
      buttonName = 'CHỜ DUYỆT';
      buttonColor = 'warning';
      isButtonDropDown = true;
      break;
    case 'rejected':
      buttonName = 'ĐÃ HỦY';
      buttonColor = 'secondary';
      break;
    default:
      break;
  }
  return isButtonDropDown ? (
    <UncontrolledDropdown className="nav-item">
      <DropdownToggle
        color={buttonColor}
        className="font-size-14"
        onClick={(e) => e.preventDefault()}
        caret
      >
        {buttonName}
      </DropdownToggle>
      <DropdownMenu right>
        <DropdownItem className="w-100" onClick={() => changeStatus(item, true)}>
          <Square color="success" />
          <span className="align-middle font-size-12 font-weight-bolder">DUYỆT</span>
        </DropdownItem>
        <DropdownItem className="w-100" onClick={() => changeStatus(item, false)}>
          <Square color="disabled" />
          <span className="align-middle font-size-12 font-weight-bolder">HỦY</span>
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  ) : (
    <Button color={buttonColor}>{buttonName}</Button>
  );
}

export const columns = ({ changeStatus, navigate }) => {
  return [
    {
      name: 'ID',
      cell: (row) => row.id,
      center: true,
      width: '80px',
      sortable: true,
      sortField: 'id',
    },
    {
      name: 'Loại GD',
      cell: (row) => <Type type={row.type} />,
      center: true,
      width: '100px',
    },
    {
      name: 'Loại SP',
      cell: (row) => <Type type={row.trade_type} />,
      center: true,
      width: '100px',
    },
    {
      name: 'ID KH',
      cell: (row) => row?.publicCustomer?.code,
      width: '120px',
      center: true,
    },
    {
      name: 'Khách hàng',
      cell: (row) => {
        const tradeType =
          row.trade_type == 't2' || row.trade_type == 't0' ? TYPE_TPLUS : row.trade_type;
        return (
          <div
            onClick={() =>
              navigate('/customer/detail/' + row?.publicCustomer?.primary_customer_id + '?trade_type=' + tradeType)
            }
            style={{ textTransform: 'uppercase', cursor: 'pointer', color: '#2F69B3' }}
          >
            {row?.publicCustomer?.username}
          </div>
        );
      },
      width: '180px',
    },
    {
      name: 'Số tiền',
      cell: (row) => (
        <div style={{ width: '100%', textAlign: 'right' }}>{formatVND(row.amount)}</div>
      ),
      sortable: true,
      sortField: 'amount',
      center: true,
    },
    {
      name: 'Ngày nộp/rút',
      cell: (row) => dayjs.unix(row.created_at).format('DD/MM/YYYY HH:mm:ss'),
      sortable: true,
      sortField: 'created_at',
      width: '150px',
      center: true,
    },
    {
      name: 'Người duyệt',
      cell: (row) => <div style={{ textTransform: 'uppercase' }}>{row?.publicUser?.username}</div>,
    },
    {
      name: 'GHI CHÚ',
      width: '200px',
      // eslint-disable-next-line no-confusing-arrow
      cell: (r) =>
        r.note?.length > 60 ? (
          <Tooltip content={r.note}>
            <span className="ml-25">{`${r.note.substr(0, 60)}...`}</span>
          </Tooltip>
        ) : (
          r.note
        ),
    },
    {
      width: '160px',
      name: 'Trạng thái',
      center: true,
      cell: (row) => <StatusButton item={row} status={row.status} changeStatus={changeStatus} />,
    },
  ];
};
