import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  FormGroup,
  Label,
  Col,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  NavLink,
} from 'reactstrap';
import { Eye, EyeOff } from 'react-feather';
import { VisibilityOff, Visibility } from '@mui/icons-material';
import { getInputErrorsObject } from '@Utils/helpers';

function PasswordInput({
  name,
  labelName,
  placeholder,
  register,
  errors,
  defaultValue,
  labelCol = '3',
  inputCol = '9',
  disabled = false,
  setValue = null,
  rows,
  hidden = false,
  labelClassName,
  inputClassName,
  validationText,
  isUnderline = false,
  watch,
  style,
}) {
  const ref = useRef();
  const [inputValue, setInputValue] = useState('');
  const [type, setType] = useState('password');
  const [isShowPass, setIsShowPass] = useState(false);
  const [errorDetail, setErrorDetail] = useState();

  useEffect(() => {
    if (name && errors) {
      setErrorDetail(getInputErrorsObject(name, errors));
    }
  }, [name, errors && Object.keys(errors)]);

  useEffect(() => {
    if (watch) {
      const subscription = watch((data, { name, type }) => handleReset(data, name, type));

      return () => subscription.unsubscribe();
    }
  }, [watch]);

  const handleReset = (data, name) => {
    if (data[name] === undefined) {
      setInputValue('');
      setValue(name, '');
    }
  };

  useEffect(() => {
    if (isShowPass) {
      setType('text');
    } else {
      setType('password');
    }
  }, [isShowPass]);

  const handleChange = (value) => {
    setInputValue(value);
    setValue(name, value);
  };

  return (
    <FormGroup row width="50%">
      {labelName && (
        <Label
          md={labelCol}
          for={name}
          className={`font-size-12 py-0 ${labelClassName ?? 'font-weight-bold'}`}
        >
          {labelName}
        </Label>
      )}
      <Col md={inputCol}>
        <InputGroup
          className={`input-group-merge ${isUnderline === true ? 'input-underline' : ''}`}
        >
          <Input
            className={inputClassName}
            type={type}
            name={name}
            {...register}
            placeholder={placeholder}
            value={inputValue}
            onChange={(e) => handleChange(e.target.value)}
            disabled={disabled}
            rows={rows}
            ref={ref}
            hidden={hidden}
            style={{...style, textTransform: 'none' }}
          />
          <InputGroupAddon addonType="prepend">
            <NavLink onClick={() => setIsShowPass(!isShowPass)} className="input-group-text">
              {isShowPass ? (
                <VisibilityOff sx={{ color: '#84818A' }} fontSize="small" />
              ) : (
                <Visibility sx={{ color: '#84818A' }} fontSize="small" />
              )}
            </NavLink>{' '}
          </InputGroupAddon>
        </InputGroup>

        {errorDetail && (
          <p className="text-danger font-size-12 mb-0" style={{ paddingTop: '2px' }}>
            {errorDetail.message ||
              (errorDetail.type === 'required' ? 'Trường bắt buộc.' : 'Giá trị sai định dạng.')}
          </p>
        )}
      </Col>
    </FormGroup>
  );
}

PasswordInput.propTypes = {
  name: PropTypes.string,
  labelName: PropTypes.string,
  placeholder: PropTypes.string,
  register: PropTypes.object,
  errors: PropTypes.object,
  defaultValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  labelCol: PropTypes.string,
  inputCol: PropTypes.string,
  disabled: PropTypes.bool,
  setValue: PropTypes.func,
  icon: PropTypes.object,
  style: PropTypes.object,
  rows: PropTypes.number,
  hidden: PropTypes.bool,
  labelClassName: PropTypes.string,
  validationText: PropTypes.string,
  watch: PropTypes.func,
  inputClassName: PropTypes.string,
  isUnderline: PropTypes.bool,
};

export default PasswordInput;
