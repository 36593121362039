import React, { useState } from 'react';
import ButtonDownloadFile from '@Components/ButtonDownloadFile';
import FlatPickrDateRange from '@Components/FlatPickrDateRange';
import useCustomQuery from '@Utils/useCustomQuery';
import { warehouseDetail } from '@Apis/admin/warehouse';
import { useParams } from 'react-router-dom';
import { Edit } from '@mui/icons-material';
import { Button } from 'reactstrap';
import WarehouseSummary from '@Components/WarehouseSummary';
import DatePicker from '@Components/DatePicker';
import PopUpUpdate from '../../WarehouseAccount/components/PopUpUpdate';

function WarehouseInfo() {
  const [itemEdit, setItemEdit] = useState(null);
  const { warehouseId } = useParams();
  const [filter, setFilter] = useState({
    date_range: '',
    keyword: '',
    status: '',
    id: warehouseId,
  });

  const filterOnChange = (name, value) => {
    setFilter((prevValues) => {
      return {
        ...prevValues,
        [name]: value,
      };
    });
  };

  const { data, refetch } = useCustomQuery(warehouseDetail, { id: warehouseId }, true, {
    enabled: !!warehouseId,
  });

  return (
    <>
      <div className="container mb-2">
        <div className="row align-items-center justify-content-between">
          <p className="font-size-28 font-weight-bolder">Chi tiết kho CK {data?.name}</p>
          <div className="d-flex gap-20">
            <ButtonDownloadFile />
            <FlatPickrDateRange
              isOpen={false}
              dateRange={filter.date_range}
              setDateRange={filterOnChange}
              className="filter-date-range"
              labelDefault="Chọn ngày"
              name="date_range"
            />
            <Button
              color="primary"
              className="d-flex align-items-center"
              onClick={() => setItemEdit(data)}
            >
              <Edit fontSize="13" color="white" />
              <span className="ml-50 font-size-14 white-space-nowrap">Sửa kho</span>
            </Button>
          </div>
        </div>
      </div>
      <div className="mb-2">{data ? <WarehouseSummary data={data} /> : ''}</div>
      <PopUpUpdate
        isOpen={itemEdit !== null}
        closeModal={() => setItemEdit(null)}
        refetch={refetch}
        item={data}
      />
    </>
  );
}

WarehouseInfo.propTypes = {};

export default WarehouseInfo;
