import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import SelectInput from '@Components/Input/SelectInput';
import TextInput from '@Components/Input/TextInput';
import { useSelector } from 'react-redux';

function WarehouseItem({
  item,
  register,
  errors,
  setValue,
  optionsWarehouse,
  optionsTeller,
  watch,
}) {
  const [isDisabled, setDisabled] = useState(false);
  const [defaultAccount, setDetaultAccount] = useState(0);
  const watchPurchaseAccount = watch(`${item.id}.purchase_account_id`);
  const warehouse = useSelector((state) => state.transaction.warehouse);
  useEffect(() => {
    const watchPurchaseAccountId = parseInt(watchPurchaseAccount);
    if (watchPurchaseAccountId) {
      const warehouseDetail = warehouse.find((item) => item.id === watchPurchaseAccountId);
      setDetaultAccount(warehouseDetail?.publicUser?.id);
      setDisabled(true);
    } else {
      setDisabled(false);
      setDetaultAccount('0');
    }
  }, [watchPurchaseAccount]);
  return (
    <>
      <Row>
        <Col>
          <SelectInput
            labelCol="12"
            inputClassName="uppercase"
            inputCol="12"
            options={optionsWarehouse}
            errors={errors}
            name={`${item.id}.purchase_account_id`}
            register={{ ...register(`${item.id}.purchase_account_id`) }}
            labelName="Chọn kho DT"
            labelClassName="font-size-14"
            setValue={setValue}
          />
        </Col>
        <Col>
          <TextInput
            labelCol="12"
            inputCol="12"
            type="price"
            labelClassName="font-size-14 mb-50"
            labelName="Khối lượng"
            placeholder="Khối lượng *"
            setValue={setValue}
            name={`${item.id}.quantity`}
            register={{ ...register(`${item.id}.quantity`, { required: true }) }}
            errors={errors}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <SelectInput
            inputClassName="uppercase"
            labelCol="12"
            inputCol="12"
            options={optionsTeller}
            errors={errors}
            name={`${item.id}.tellers_user_id`}
            register={{ ...register(`${item.id}.tellers_user_id`, { required: true }) }}
            labelClassName="font-size-14 mb-50"
            labelName="GDV *"
            placeholder="GDV"
            setValue={setValue}
            disabled={isDisabled}
            defaultValue={defaultAccount}
          />
        </Col>
      </Row>
    </>
  );
}

WarehouseItem.propTypes = {
  item: PropTypes.object,
  register: PropTypes.func,
  errors: PropTypes.object,
  setValue: PropTypes.func,
  optionsWarehouse: PropTypes.array,
  optionsTeller: PropTypes.array,
  watch: PropTypes.func,
};

export default WarehouseItem;
