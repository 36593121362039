import { createSlice, createSelector, createAsyncThunk } from '@reduxjs/toolkit';
import { usersSelect } from '@Apis/admin/user';
import { wareHouseIndexPublic } from '@Apis/admin/warehouse';

export const getTellers = createAsyncThunk('transaction/getTellers', async (thunkApi) => {
  try {
    return await usersSelect({ type: 'tellers' });
  } catch (error) {
    return thunkApi.rejectWithValue({ error: error.message });
  }
});

export const getWareHouse = createAsyncThunk('transaction/getWareHouse', async (thunkApi) => {
  try {
    return await wareHouseIndexPublic();
  } catch (error) {
    return thunkApi.rejectWithValue({ error: error.message });
  }
});

const userSlice = createSlice({
  name: 'transaction',
  initialState: {
    tellers: [],
    warehouse: [],
  },
  extraReducers: {
    [getTellers.fulfilled]: (state, action) => {
      const data = action.payload;
      const tellers = data.map((item) => {
        item.label = item.username;
        item.value = item.id;
        return item;
      });
      state.tellers = tellers;
    },
    [getWareHouse.fulfilled]: (state, action) => {
      const data = action.payload;
      const warehouse = data.map((item) => {
        item.label = item.name;
        item.value = item.id;
        return item;
      });
      state.warehouse = warehouse;
    },
  },
});

const { reducer } = userSlice;
export const selectUser = createSelector(
  (state) => ({
    tellers: state.transaction.tellers,
    warehouse: state.transaction.warehouse,
  }),
  (state) => state,
);

export default reducer;
