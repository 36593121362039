import styled from 'styled-components';

export const TransactionBuyedWrapper = styled.div`
  // margin-right: 45px;
  padding: 15px 0;
  border-bottom: 1px dashed #84818a;
  .items-buyed {
    max-height: 200px;
    overflow-y: auto;
    overflow-x: hidden;
  }
  .item-buyed {
    display: flex;
    justify-content: end;
    gap: 20px;
    .item-content {
      width: 112px;
    }
  }
`;
