/* eslint-disable react/no-unused-class-component-methods */
import { currentRoleGroup, listFunction } from '@Apis/admin/accessRole';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import HeaderRole from '../EmployeeDetail/component/RoleComponent/HeaderRole';
import TableRole from '../EmployeeDetail/component/RoleComponent/TableRole';

class ContentRolePermission extends Component {
  constructor(props) {
    super(props);
    this.state = { data: [], listRoles: [], listRolesInit: [] };
  }

  UNSAFE_componentWillMount() {
    const { dataInitListRole } = this.props;
    const promise = dataInitListRole.map(async (e) =>
      currentRoleGroup({ id: e.id }).then((res) => ({ ...res, idRole: e.id, nameRole: e.name })),
    );
    Promise.all([dataInitListRole, listFunction(), Promise.all(promise)]).then((data) => {
      this.setState({
        data: [dataInitListRole, data[1]],
        listRoles: data[2],
        listRolesInit: data[2],
      });
    });
    window.addEventListener('resize', this.handleResize);
  }

  componentDidMount() {
    this.handleResize();
  }

  shouldComponentUpdate(nProps, nState) {
    const { dataInitListRole, rolesUser, allowEdit } = this.props;
    const { data, listRoles } = this.state;
    return (
      dataInitListRole !== nProps.dataInitListRole ||
      rolesUser !== nProps.rolesUser ||
      data !== nState.data ||
      allowEdit !== nProps.allowEdit ||
      listRoles !== nState.listRoles
    );
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleRemapData = async () => {
    const { dataInitListRole } = this.props;
    const promise = dataInitListRole.map(async (e) =>
      currentRoleGroup({ id: e.id }).then((res) => ({ ...res, idRole: e.id, nameRole: e.name })),
    );
    const listRoles = await Promise.all(promise);
    this.setState({ listRoles, listRolesInit: listRoles });
  };

  handleResize = () => {
    this.divTable.style.height = `${window.innerHeight - 320}px`;
  };

  handleChangeAll = (checked, id) => {
    const { data, listRoles, listRolesInit } = this.state;
    const { setEdit, allowEdit } = this.props;
    if (!data[1] || !data[1].length) {
      return;
    }
    if (!allowEdit) {
      setEdit(checked, id);
      return;
    }
    const newList = [...listRoles];
    if (checked) {
      const objectMap = {};
      data[1].forEach((element) => {
        element.accessActions?.forEach?.((e) => {
          objectMap[e.id] = true;
        });
      });
      newList[id] = { ...newList[id], ...objectMap };
    } else {
      newList[id] = { _: '_', idRole: newList[id].idRole, nameRole: newList[id].nameRole };
    }
    setEdit(
      newList.some((e, i) => {
        return Object.keys(e).length !== Object.keys(listRolesInit[i]).length;
      }),
    );
    this.setState({ listRoles: newList });
  };

  onChangeRow = (role, checked, id) => {
    const { data, listRoles, listRolesInit } = this.state;
    const { setEdit } = this.props;
    if (!data[1] || !data[1].length) {
      return;
    }
    const newList = [...listRoles];
    if (checked) {
      const roleId = { ...newList[id] };
      roleId[role] = true;
      newList[id] = roleId;
    } else {
      const roleId = { ...newList[id] };
      delete roleId[role];
      newList[id] = roleId;
    }
    this.setState({ listRoles: newList });
    setEdit(
      newList.some((e, i) => {
        return Object.keys(e).length !== Object.keys(listRolesInit[i]).length;
      }),
    );
  };

  handleCheckAllTuyBien = () => {
    const { rolesUser } = this.props;
    const { data } = this.state;
    if (!data[1]) {
      return false;
    }
    return data[1]?.every((e) => {
      return e.accessActions?.every((k) => rolesUser?.assign?.[k.id]);
    });
  };

  render() {
    const { data, listRoles } = this.state;
    const {
      allowEdit = true,
      rolesUser,
      handleChangeRoleUser,
      handleCheckAllRoleUser,
    } = this.props;
    return (
      <div
        className="role"
        style={{ height: window.innerHeight - 250, overflow: 'auto' }}
        ref={(ref) => {
          this.divTable = ref;
        }}
      >
        <table
          style={{
            padding: 0,
            minWidth: (data[1]?.length || 0) * 100,
            overflowX: 'auto',
          }}
          className="table-role w-100"
        >
          <HeaderRole
            checkedAll={this.handleCheckAllTuyBien()}
            handleCheckAllRoleUser={(checked) => handleCheckAllRoleUser(checked, data[1])}
            rolesUser={rolesUser}
            listRoles={listRoles}
            onChange={this.handleChangeAll}
            allowEdit={allowEdit}
            roles={data[0]}
            data={data[1]}
          />
          <tbody>
            <TableRole
              handleChangeRoleUser={handleChangeRoleUser}
              rolesUser={rolesUser}
              onChange={this.onChangeRow}
              allowEdit={allowEdit}
              roles={data[0]}
              data={data[1]}
              listRoles={listRoles}
            />
          </tbody>
        </table>
      </div>
    );
  }
}

ContentRolePermission.propTypes = {
  dataInitListRole: PropTypes.array,
  setEdit: PropTypes.func,
  handleChangeRoleUser: PropTypes.func,
  handleCheckAllRoleUser: PropTypes.func,
  allowEdit: PropTypes.bool,
  rolesUser: PropTypes.object,
};

export default ContentRolePermission;
