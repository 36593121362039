import axiosService from '@Lib/axiosService';
import { handleResponse } from '@Utils/helpers';

let host;
if (process.env.DASHBOARD_TYPE === 'admin') {
  host = `${process.env.API_ADMIN_URL}`;
} else {
  host = `${process.env.API_CUSTOMER_URL}`;
}

const url = `${host}/account`;

export const currentProfile = async () => {
  const res = await axiosService.get(`${url}/profile`);
  return handleResponse(res);
};

export const changePassword = async (params) => {
  const res = await axiosService.post(`${url}/change-password`, params);
  return handleResponse(res);
};

export const overviewDashboard = async (params) => {
  return axiosService.get(`${url}/overview`, params);
};

export const getChartDashboard = async (params) => {
  return axiosService.get(`${url}/nav-report`, params);
};
