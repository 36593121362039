import React from 'react';
import PropTypes from 'prop-types';
import { TableWrapper } from '../../styled';
import './derivative.scss';
import { Table } from 'reactstrap';
import { formatNumber } from '@Utils/helpers';
import ToolTipEdit from './ToolTipEdit';
import { TYPE_CS } from '@Components/Layouts/PrivateLayout';

function TableBase({ datas, isExport, getData }) {
  let totalDathuchien = 0;
  let totalChuathuchien = 0;
  let totalChiphiHD = 0;
  let totalChiphiTaichinh = 0;

  return (
    <TableWrapper style={{ marginLeft: 540 }}>
      <Table>
        <thead>
          <tr>
            <th className="headcol font-size-12" style={{ boxShadow: 'none' }}>
              Chi tiêu
            </th>
            <th
              className="headcol font-size-12 text-center"
              style={{
                textAlign: 'center',
                boxShadow: '7px 0px 15px rgb(153 153 153 / 12%)',
                left: 290,
                margin: 0,
              }}
            >
              Tổng
            </th>
            {datas.headers.map((item) => {
              return (
                <th
                  key={item}
                  style={{
                    textAlign: 'end',
                  }}
                >
                  {item}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {Object.keys(datas.rows).map((keyRow, i) => {
            let total = 0;
            if (keyRow === 'DOANH THU ĐÃ THỰC HIỆN') {
              total += datas.rows['Lãi vay'].data.reduce((a, b) => a + b.total, 0);
              total += datas.rows['Phí giao dịch'].data.reduce((a, b) => a + b.total, 0);
              total += datas.rows['Lãi vay cọc thiếu'].data.reduce((a, b) => a + b.total, 0);
              total += datas.rows['Hoa hồng môi giới'].data.reduce((a, b) => a + b.total, 0);
              totalDathuchien = total;
            } else if (keyRow.includes('lũy kế')) {
              total = datas.rows[keyRow]?.data?.[datas.rows[keyRow].data.length - 1]?.total;
            } else if (keyRow === 'TỔNG DOANH THU') {
              total = totalChuathuchien + totalDathuchien;
            } else if (keyRow === 'Phí bán tạm thu') {
              total =
                datas.rows['Phí bán tạm thu'].data?.[datas.rows['Phí bán tạm thu'].data?.length - 1]
                  ?.total;
            } else if (keyRow === 'DOANH THU CHƯA THỰC HIỆN') {
              total =
                datas.rows['Phí bán tạm thu'].data?.[datas.rows['Phí bán tạm thu'].data?.length - 1]
                  ?.total;
              totalChuathuchien = total;
            } else if (keyRow === 'CHI PHÍ TÀI CHÍNH') {
              total += datas.rows['Lãi huy động'].data.reduce((a, b) => a + b.total, 0);
              total += datas.rows['Phí giao dịch trả CTCK'].data.reduce((a, b) => a + b.total, 0);
              total += datas.rows['Lãi vay margin'].data.reduce((a, b) => a + b.total, 0);
              totalChiphiTaichinh = total;
            } else if (keyRow === 'CHI PHÍ HOẠT ĐỘNG') {
              total += datas.rows['Phí Hợp động tư vấn dịch vụ'].data.reduce(
                (a, b) => a + b.total,
                0,
              );
              total += datas.rows['Hoa hồng Kho'].data.reduce((a, b) => a + b.total, 0);
              totalChiphiHD = total;
            } else if (keyRow === 'TỔNG CHI PHÍ') {
              total = totalChiphiHD + totalChiphiTaichinh;
            } else if (keyRow === 'LỢI NHUẬN THUẦN TỪ HĐKD') {
              total = totalChuathuchien + totalDathuchien - (totalChiphiHD + totalChiphiTaichinh);
            } else {
              total = datas.rows[keyRow]?.data?.reduce((a, b) => a + b.total, 0);
            }
            const { type, canEdit } = datas.rows[keyRow];
            return (
              <tr
                key={i.toString()}
                style={{
                  background: type === 'title' ? '#375c8a' : '',
                }}
              >
                <th
                  className={`headcol ${type}`}
                  style={{
                    boxShadow: 'none',
                    background: type === 'title' ? '#375c8a' : '',
                    color: type === 'title' ? '#fff' : '',
                  }}
                >
                  {keyRow}
                </th>
                <th
                  className={` headcol headcol-child ${type}`}
                  style={{
                    textAlign: 'end',
                    boxShadow: '7px 0px 15px rgb(153 153 153 / 12%)',
                    left: 290,
                    margin: 0,
                    background: type === 'title' ? '#375c8a' : '',
                    color: type === 'title' ? '#fff' : '',
                    paddingRight: 100,
                  }}
                >
                  {formatNumber(total)}
                </th>
                {datas.rows[keyRow]?.data?.map((item, index) => {
                  if (canEdit && !isExport) {
                    return (
                      <td key={index.toString()} className="text-center text-primary">
                        <div
                          className="d-flex align-items-center justify-content-end"
                          onClick={() => {}}
                          style={{ cursor: 'pointer' }}
                        >
                          {item.label ? (
                            item.total
                          ) : (
                            <ToolTipEdit
                              style={{ justifyContent: 'end' }}
                              name={datas.rows[keyRow].name}
                              label={keyRow}
                              defaultValue={item.total}
                              date={item.report_at}
                              tradeType={TYPE_CS}
                              refetch={getData}
                            />
                          )}
                        </div>
                      </td>
                    );
                  }
                  let totalCol = 0;
                  if (keyRow === 'DOANH THU ĐÃ THỰC HIỆN' || keyRow === 'TỔNG DOANH THU') {
                    totalCol += datas.rows['Lãi vay'].data?.[index]?.total || 0;
                    totalCol += datas.rows['Phí giao dịch'].data?.[index]?.total || 0;
                    totalCol += datas.rows['Lãi vay cọc thiếu'].data?.[index]?.total || 0;
                    totalCol += datas.rows['Hoa hồng môi giới'].data?.[index]?.total || 0;
                    if (keyRow === 'TỔNG DOANH THU') {
                      totalCol += datas.rows['Phí bán tạm thu'].data?.[index]?.total || 0;
                    }
                  } else if (keyRow === 'CHI PHÍ TÀI CHÍNH' || keyRow === 'TỔNG CHI PHÍ') {
                    totalCol += datas.rows['Lãi huy động'].data?.[index]?.total || 0;
                    totalCol += datas.rows['Phí giao dịch trả CTCK'].data?.[index]?.total || 0;
                    totalCol += datas.rows['Lãi vay margin'].data?.[index]?.total || 0;
                    if (keyRow === 'TỔNG CHI PHÍ') {
                      totalCol +=
                        datas.rows['Phí Hợp động tư vấn dịch vụ'].data?.[index]?.total || 0;
                      totalCol += datas.rows['Hoa hồng Kho'].data?.[index]?.total || 0;
                    }
                  } else {
                    totalCol = item.total;
                  }
                  return (
                    <td
                      key={index.toString()}
                      className="long"
                      style={{
                        fontWeight: type === 'title' ? 600 : 400,
                        color: type === 'title' ? '#fff' : '',
                        textAlign: 'end',
                      }}
                    >
                      {formatNumber(totalCol)}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </Table>
    </TableWrapper>
  );
}

TableBase.propTypes = {
  datas: PropTypes.any,
  getData: PropTypes.any,
  isExport: PropTypes.bool,
};

export default TableBase;
