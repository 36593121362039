/* eslint-disable indent */
import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, Row, Col, Container, NavLink } from 'reactstrap';
import {
  renderType,
  canEditTransactionTellers,
  getQuantityMatching,
  getPriceMatching,
} from '@Utils/tradeHelpers';
import { CheckBox, CheckBoxOutlineBlank } from '@mui/icons-material';
import dayjs from 'dayjs';
import { formatNumber, formatVND } from '@Utils/helpers';
import { warehouseDetail } from '@Apis/admin/warehouse';
import { transactionReject, transactionDetail } from '@Apis/admin/transaction';
import useCustomQuery from '@Utils/useCustomQuery';
import useCustomMutation from '@Utils/useCustomMuation';
import { toastError, toastSuccess } from '@Utils/toastrHelper';
import { useForm } from 'react-hook-form';
import TransactionButton from './TransactionButton';
import CustomerInfo from './CustomerInfo';
import TransactionInfo from './TransactionInfo';
import TransactionEdit from './TransactionEdit';
import TransactionEditPartCompleted from './TransactionEditPartCompleted';
import TransactionBuyed from './TransactionBuyed';

function PopUpEdit({
  isOpen,
  closeModal,
  refetch,
  item,
  setItemConfirm,
  optionsWarehouse,
  setIsRefreshStatus,
}) {
  const [isMatchComplete, setIsMatchComplete] = useState(false);

  const { data } = useCustomQuery(transactionDetail, { id: item.id }, false, null, 5000);
  const [itemDetail, setItemDetail] = useState(item);
  useEffect(() => {
    if (data) {
      setItemDetail(data);
    }
  }, [data]);
  const quantityMatching = itemDetail ? getQuantityMatching(itemDetail) : 0;
  const priceMatching = itemDetail ? getPriceMatching(itemDetail) : 0;
  const refForm = useRef();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  const [statusEdit, setStatusEdit] = useState(item.status);
  const { mutate, isSuccess, error } = useCustomMutation(transactionReject, true);
  // const { data: dataUser } = useCustomQuery(warehouseDetail, { id: item.user_id }, false, {
  //   enabled: !!item.user_id,
  // });

  const onSubmit = (formData) => {
    closeModal();
    if (isMatchComplete) {
      formData.is_completed = true;
    }
    setItemConfirm({
      itemConfirm: formData,
      transaction: item,
    });
  };

  const rejectTransaction = () => {
    const { id } = item;
    mutate({
      id,
      status: statusEdit,
    });
  };

  useEffect(() => {
    if (isSuccess) {
      toastSuccess('Huỷ lệnh thành công');
      closeModal();
      refetch();
      setIsRefreshStatus({ state: true });
    }
    if (error) {
      toastError(error);
    }
  }, [isSuccess, error]);

  function renderPrice({ price_type, price }) {
    if (price_type !== 'price') {
      return (
        <div className="uppercase" style={{ textAlign: 'center' }}>
          <div style={{ background: 'rgba(32, 201, 172, 0.1)' }}>
            <p className="font-size-14 font-weight-bolder py-25 px-1 text-info">{price_type}</p>
          </div>
        </div>
      );
    }
    return formatNumber(price);
  }

  return item ? (
    <Modal isOpen={isOpen} toggle={closeModal} className="text-center modal-admin-transaction">
      <Container>
        <Row>
          <Col md="4" className="border-right px-1 py-2">
            <CustomerInfo item={itemDetail} />
          </Col>
          <Col md="8" className="px-1 py-2">
            <div className="d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center gap-20">
                <p className="font-size-32 font-weight-normal text-left"> CHI TIẾT YÊU CẦU</p>
                <div className="text-uppercase">{renderType(itemDetail.type)}</div>
              </div>
              <TransactionButton
                status={statusEdit}
                changeStatus={setStatusEdit}
                itemTransaction={itemDetail}
              />
            </div>
            <form onSubmit={handleSubmit(onSubmit)} ref={refForm} className="">
              <div className="stock-info">
                <div className="item header">
                  <div className="item-content">THỜI GIAN</div>
                  {itemDetail?.cancel_request_at && <div className="item-content">THỜI GIAN HUỶ</div>}
                  <div className="item-content">LOẠI LỆNH</div>
                  <div className="item-content">CỔ PHIẾU</div>
                  <div className="item-content">GIÁ {item.type === 'buy' ? 'MUA' : 'BÁN'}</div>
                  <div className="item-content">KL</div>
                  <div className="item-content">KHO ĐT *</div>
                  <div className="item-content">KL KHỚP *</div>
                  <div className="item-content">GIÁ KHỚP *</div>
                </div>
                <div className="item stock-content">
                  <div className="font-size-14 item-content">
                    {dayjs.unix(itemDetail.created_at).format('HH:MM DD/MM/YYYY')}
                  </div>
                  {itemDetail?.cancel_request_at && (
                    <div className="font-size-14 item-content">
                      {dayjs.unix(itemDetail.cancel_request_at).format('HH:mm - DD/MM/YY')}
                    </div>
                  )}
                  <div className="item-content">{renderType(itemDetail.type)}</div>
                  <div className="item-content">{itemDetail.stock_id}</div>
                  <div className="item-content">{renderPrice(itemDetail)}</div>
                  <div className="item-content">{formatVND(itemDetail.quantity)}</div>
                  {canEditTransactionTellers(itemDetail) && item.status !== 'part_completed' && (
                    <TransactionEdit
                      itemDetail={itemDetail}
                      register={register}
                      setValue={setValue}
                      optionsWarehouse={optionsWarehouse}
                      errors={errors}
                      priceMatching={priceMatching}
                      quantityMatching={quantityMatching}
                    />
                  )}
                  {canEditTransactionTellers(itemDetail) && item.status === 'part_completed' && (
                    <TransactionEditPartCompleted
                      itemDetail={itemDetail}
                      register={register}
                      setValue={setValue}
                      optionsWarehouse={optionsWarehouse}
                      errors={errors}
                      priceMatching={priceMatching}
                      quantityMatching={quantityMatching}
                    />
                  )}
                  {!canEditTransactionTellers(itemDetail) && (
                    <TransactionInfo
                      purchaseAccountName={itemDetail.purchaseAccount?.name}
                      priceMatching={priceMatching}
                      quantityMatching={quantityMatching}
                    />
                  )}
                </div>
              </div>
              {item.status === 'part_completed' && (
                <TransactionBuyed
                  itemDetail={item}
                  register={register}
                  setValue={setValue}
                  errors={errors}
                  priceMatching={priceMatching}
                />
              )}
              <div className="d-flex stock-note align-items-center justify-content-between">
                <div className="pl-1 text-left">
                  <p className="font-weight-bolder" style={{ textTransform: 'uppercase' }}>
                    Kiểm soát viên: {itemDetail?.publicSurveyorUser?.username}
                  </p>
                </div>
                {item.status === 'part_completed' && !item.is_completed ? (
                  <NavLink
                    onClick={() => setIsMatchComplete(!isMatchComplete)}
                    className="d-flex align-items-center gap-8"
                  >
                    {isMatchComplete ? <CheckBox color="success" /> : <CheckBoxOutlineBlank />}
                    <p className="font-weight-bolder">Đã khớp tối đa</p>
                  </NavLink>
                ) : (
                  ''
                )}
              </div>
              {itemDetail.is_cancel_request ? (
                <div className="d-flex align-items-center justify-content-between note-cancel px-1">
                  <p className="text-danger">Khách hàng gửi yêu cầu ngừng giao dịch</p>
                  <p className="">
                    {dayjs.unix(itemDetail.cancel_request_at).format('DD/MM/YYYY - HH:mm')}
                  </p>
                </div>
              ) : (
                ''
              )}
              {canEditTransactionTellers(itemDetail) ? (
                <div className="pt-2 d-flex gap-20 align-items-center justify-content-center">
                  {statusEdit === 'expired' || statusEdit === 'cancelled' ? (
                    <Button
                      className="px-2"
                      color="primary"
                      type="button"
                      onClick={() => rejectTransaction()}
                    >
                      Xác nhận
                    </Button>
                  ) : (
                    <Button className="px-2" color="primary" type="submit">
                      Xác nhận
                    </Button>
                  )}
                  <Button type="button" onClick={() => closeModal()}>
                    Hủy
                  </Button>
                </div>
              ) : (
                ''
              )}
            </form>
          </Col>
        </Row>
      </Container>
    </Modal>
  ) : (
    ''
  );
}

PopUpEdit.propTypes = {
  isOpen: PropTypes.bool,
  closeModal: PropTypes.func,
  refetch: PropTypes.func,
  item: PropTypes.object,
  setItemConfirm: PropTypes.func,
  optionsWarehouse: PropTypes.array,
  optionsTeller: PropTypes.array,
  setIsRefreshStatus: PropTypes.func,
};

export default PopUpEdit;
