import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalBody, Container, Row, Col, Button, Label } from 'reactstrap';
import { PopUpWrapper } from './styles';
import { useForm } from 'react-hook-form';
import useCustomMutation from '@Utils/useCustomMuation';
import TextInput from '@Components/Input/TextInput';
import ButtonSubmit from '@Components/ButtonSubmit';
import SearchCustomer from '@Components/Input/SearchCustomer';
import { TYPE_TPLUS } from '@Components/Layouts/PrivateLayout';
import FieldInput from '@Views/Client/Recharge/BodyTab/Request/FieldInput';
import DropdownSelect from '@Components/DropdownSelect';
import { formatNumber } from '@Utils/helpers';
import { tradeTPlus } from '@Apis/client/trade';
import { toastError, toastSuccess } from '@Utils/toastrHelper';
import { customerAssets } from '@Apis/admin/customer';
import { renderVTHT } from '@Views/Client/TransactionTPlus';
import './styles.scss';
import { Radio } from '@mui/material';

function PopUpCreateTplus({ isOpen, closeModal, refetch }) {
  const [tplusType, setTplusType] = useState('t0');
  const [openWarning, setOpenWarning] = useState({ isShow: false, type: '', data: {} });
  const [buyType, setBuyType] = useState('buy');
  const [dataCurrentPosition, setDataCurrentPosition] = useState();

  const { mutate, isSuccess, error } = useCustomMutation(tradeTPlus, true);
  const {
    register,
    unregister,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm();
  const priceTypeWatch = watch('price_type') || 'price';
  const stockIdWatch = watch('stock_id');
  const quantityWatch = watch('quantity');
  const priceWatch = watch('price');
  const customerIdWatch = watch('customer_id');

  useEffect(() => {
    priceTypeWatch === 'price' ? register('price') : unregister('price');
  }, [priceTypeWatch]);

  useEffect(() => {
    if (customerIdWatch) {
      customerAssets({
        trade_type: TYPE_TPLUS,
        customer_id: customerIdWatch,
      })
        .then((data) => {
          setDataCurrentPosition(data);
        })
        .catch(toastError);
    }
  }, [customerIdWatch]);

  const onSubmit = (formData) => {
    if (Object.keys(errors).length == 0) {
      setOpenWarning((pre) => ({ ...pre, isShow: true, type: buyType, data: formData }));
    }
  };
  const submitConfirm = () => {
    const { customer_id, price, price_type, quantity, stock_id, note } = openWarning.data;
    const tmp = {
      stock_id,
      price,
      note,
      quantity,
      type: openWarning.type,
      trade_type: tplusType,
      price_type: price_type || 'price',
      customer_id,
    };

    mutate(tmp);
  };

  useEffect(() => {
    if (isSuccess) {
      toastSuccess('Lập lệnh thành công');
      setOpenWarning({});
      closeModal();
      refetch();
    }
    if (error) {
      toastError(error);
    }
  }, [isSuccess, error]);

  return (
    <>
      <Modal
        isOpen={isOpen}
        toggle={closeModal}
        style={{
          maxWidth: '800px',
          maxHeight: '716px',
          padding: '0',
        }}
        className="text-center popup-create-trade"
      >
        <PopUpWrapper>
          <ModalBody className="p-0">
            <h3 className="py-1 text-center">LẬP LỆNH T+</h3>
            <Container className="trade-buy">
              <form onSubmit={handleSubmit(onSubmit)}>
                <Row className="py-2">
                  <Col md="12" className="trade-buuy-left">
                    <Container>
                      <Row>
                        <Col>
                          <SearchCustomer
                            labelName="Chọn khách hàng *"
                            labelCol="12"
                            inputCol="12"
                            labelClassName="font-size-14 mb-50"
                            name="customer_id"
                            register={{ ...register('customer_id', { required: true }) }}
                            errors={errors}
                            setValue={setValue}
                            tradeType={TYPE_TPLUS}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <TextInput
                            labelName="Mã CK *"
                            labelCol="12"
                            inputCol="12"
                            type="text"
                            labelClassName="font-size-14 mb-50"
                            placeholder="Mã CK"
                            setValue={setValue}
                            name="stock_id"
                            register={{ ...register('stock_id', { required: true }) }}
                            errors={errors}
                          />
                        </Col>
                        <Col>
                          <FieldInput
                            label="Vị thế hiện tại:"
                            component={
                              <div>
                                {dataCurrentPosition &&
                                Object.keys(dataCurrentPosition.assets || {}).length > 0 ? (
                                  <div>
                                    {Object.keys(dataCurrentPosition.assets || {}).map(
                                      (stockId) => {
                                        const stock =
                                          dataCurrentPosition.assets[stockId]?.[tplusType];
                                        return stock?.stock_id && stock?.stock_id != 'null' ? (
                                          <div
                                            key={stockId}
                                            className="d-flex"
                                            style={{ margin: '3px 0' }}
                                          >
                                            <span style={{ marginRight: 10 }}>{stockId}: </span>
                                            <span>{renderVTHT(stock?.quantity, '--')}</span>
                                          </div>
                                        ) : null;
                                      },
                                    )}
                                  </div>
                                ) : (
                                  '---'
                                )}
                              </div>
                            }
                          />
                        </Col>
                      </Row>

                      {/* <hr></hr> */}

                      {/* <Row>
                        <Col>
                          <div className="d-flex align-items-center">
                            <div className="d-flex align-items-center">
                              <Radio
                                checked={tplusType === 't0'}
                                onClick={() => setTplusType('t0')}
                              />
                              <span>T+</span>
                            </div>
                            <div className="d-flex align-items-center ml-1">
                              <Radio
                                checked={tplusType === 't2'}
                                onClick={() => setTplusType('t2')}
                              />
                              <span>T2</span>
                            </div>
                          </div>
                        </Col>
                      </Row> */}

                      <hr></hr>
                      <br />

                      <Row>
                        <Col>
                          <div>
                            <div className="d-flex">
                              <FieldInput
                                label="Giá đặt"
                                style={{ width: 200, marginRight: 10 }}
                                component={
                                  <div className="d-flex">
                                    <DropdownSelect
                                      options={[
                                        {
                                          label: 'Giá',
                                          value: 'price',
                                        },
                                        {
                                          label: 'MP',
                                          value: 'mp',
                                        },
                                        {
                                          label: 'ATO',
                                          value: 'ato',
                                        },
                                        {
                                          label: 'ATC',
                                          value: 'atc',
                                        },
                                      ]}
                                      valueSelected={priceTypeWatch || 'price'}
                                      setValueSelected={(_, v) => setValue(_, v)}
                                      name="price_type"
                                      style={{
                                        width: 80,
                                        background: '#fff',
                                        borderTopRightRadius: 0,
                                        borderBottomRightRadius: 0,
                                        textTransform: 'none',
                                        height: 42,
                                        borderStyle: 'solid',
                                        borderColor: '#E3E1E5',
                                      }}
                                      labelShow={false}
                                    />
                                    {priceTypeWatch === 'price' ? (
                                      <TextInput
                                        labelCol="12"
                                        inputCol="12"
                                        type="pricenumber"
                                        labelClassName="font-size-14 mb-50"
                                        placeholder="Giá"
                                        setValue={setValue}
                                        name="price"
                                        register={{
                                          ...register('price', {
                                            required: true,
                                            valueAsNumber: true,
                                          }),
                                        }}
                                        errors={errors}
                                        styleInput={{
                                          borderTopLeftRadius: 0,
                                          borderBottomLeftRadius: 0,
                                          borderLeft: 0,
                                        }}
                                      />
                                    ) : null}
                                  </div>
                                }
                                required
                              />

                              <FieldInput
                                label="Khối lượng"
                                style={{ width: 200, marginRight: 10 }}
                                component={
                                  <TextInput
                                    labelCol="12"
                                    inputCol="12"
                                    type="quantity"
                                    labelClassName="font-size-14 mb-50"
                                    placeholder="Khối lượng"
                                    setValue={setValue}
                                    name="quantity"
                                    register={{
                                      ...register('quantity', {
                                        required: true,
                                        valueAsNumber: true,
                                      }),
                                    }}
                                    errors={errors}
                                  />
                                }
                                required
                              />
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <FieldInput.TextArea
                            onChange={(v) => setValue('note', v)}
                            placeholder="Ghi chú"
                            label="Ghi chú (nếu có)"
                          />
                        </Col>
                      </Row>
                    </Container>
                  </Col>
                </Row>

                <div className="d-flex mb-2 justify-content-center" style={{ gap: 16 }}>
                  <ButtonSubmit
                    name="LONG"
                    wrapClassName="button-long"
                    size="md"
                    onClick={() => setBuyType('buy')}
                  />
                  <ButtonSubmit
                    style={{ width: 200 }}
                    name="SHORT"
                    wrapClassName="button-short"
                    size="md"
                    color="danger"
                    onClick={() => setBuyType('sell')}
                  />
                  <Button outline type="button" onClick={() => closeModal()}>
                    Hủy
                  </Button>
                </div>
              </form>
            </Container>
          </ModalBody>
        </PopUpWrapper>
      </Modal>

      <Modal wrapClassName="modal-wrap-setting" isOpen={openWarning.isShow} centered>
        <div className="modal-setting">
          <div style={{ textAlign: 'center' }}>
            <h3 className="uppercase mb-1">Xác nhận giao dịch</h3>
          </div>
          <div className="mt-1 text-align-center">
            Bạn có thật sự muốn đặt lệnh{' '}
            <span
              style={{
                color: openWarning.type === 'sell' ? 'rgb(252, 52, 0)' : 'rgb(20, 177, 59)',
                marginLeft: 5,
                fontWeight: '700',
              }}
            >
              {openWarning.type === 'sell' ? 'SHORT' : 'LONG'}
            </span>
          </div>
          <div className="mt-1">
            <div className="d-flex mt-1 fw-600">
              <div style={{ width: 170 }}>Mã Hợp Đồng:</div>
              <div className="ml-2 text-uppercase">{stockIdWatch}</div>
            </div>
            <div className="d-flex mt-1 fw-600">
              <div style={{ width: 170 }}>Khối lượng:</div>
              <div className="ml-2">{quantityWatch}</div>
            </div>
            <div className="d-flex mt-1 fw-600">
              <div style={{ width: 170 }}>Giá đặt:</div>
              <div className="ml-2 uppercase">
                {priceTypeWatch !== 'price' ? priceTypeWatch : formatNumber(priceWatch)}
              </div>
            </div>
          </div>
          <div className="mt-3 d-flex align-items-center justify-content-center">
            <ButtonSubmit
              onClick={() => submitConfirm()}
              name="Xác nhận"
              className="px-2"
              size="md"
              color="primary"
            />
            <ButtonSubmit
              onClick={() => setOpenWarning({})}
              outline
              name="Huỷ"
              className="px-2"
              wrapClassName="ml-1"
              size="md"
              color="primary"
              ghost
            />
          </div>
        </div>
      </Modal>
    </>
  );
}

PopUpCreateTplus.propTypes = {
  isOpen: PropTypes.bool,
  closeModal: PropTypes.func,
  refetch: PropTypes.func,
};

export default PopUpCreateTplus;
