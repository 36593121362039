import React from 'react';
import PropTypes from 'prop-types';
import { OpenInNew } from '@mui/icons-material';
import { NavLink } from 'reactstrap';
import { customerDetail } from '@Apis/admin/customer';
import { holdByCustomer } from '@Apis/admin/stock';
import useCustomQuery from '@Utils/useCustomQuery';
import { formatNumber, formatVND } from '@Utils/helpers';

function CustomerInfo({ item }) {
  const customerId = item?.publicCustomer?.id || 0;
  const { data } = useCustomQuery(
    customerDetail,
    { id: customerId },
    {
      enabled: !!customerId,
    },
  );

  const { data: dataHold } = useCustomQuery(holdByCustomer, {
    customer_id: customerId,
    stock_id: item.stock_id,
  });

  return (
    <>
      <p className="text-left color-matte-gray">KHÁCH HÀNG</p>
      <p className="font-size-32 text-left mt-1" style={{ textTransform: 'uppercase' }}>
        {item?.publicCustomer?.username}
      </p>
      <p className="font-size-18 text-left mt-50 font-weight-bold color-matte-gray">
        {item?.publicCustomer?.code}
      </p>
      <div className="customer-info">
        <div className="item">
          <div className="font-size-12">NAV</div>
          <div className="font-size-14 font-weight-bolder">{formatVND(data?.current_nav)}</div>
        </div>
        <div className="item">
          <div className="font-size-12">
            <p className="">TỈ LỆ KÝ QUỸ</p>
            <p>(Ở thời điểm đặt lệnh)</p>
          </div>
          <div className="font-size-14 font-weight-bolder">
            {`${formatVND(item?.margin_percent_at)}%`}
          </div>
        </div>
        <div className="item">
          <div className="font-size-12">
            <p className="">DƯ CỌC</p>
            <p>(Ở thời điểm đặt lệnh)</p>
          </div>
          <div className="font-size-14 font-weight-bolder">{formatVND(data?.current_balance)}</div>
        </div>
        <div className="item">
          <div className="font-size-12">
            TỔNG KL{' '}
            <span className="font-weight-bolder" style={{ textTransform: 'uppercase' }}>
              {item.stock_id}
            </span>{' '}
            NẮM GIỮ
          </div>
          <div className="font-size-14 font-weight-bolder">{formatVND(dataHold?.quantity)}</div>
        </div>
      </div>
      <div className="d-flex align-items-center justify-content-center pt-5">
        <OpenInNew color="primary" />
        <NavLink
          onClick={() => {
            window.open(`/customer/detail/${item?.publicCustomer?.id}`);
          }}
          className="ml-50 text-primary font-weight-bolder"
        >
          Xem chi tiết khách hàng
        </NavLink>
      </div>
    </>
  );
}

CustomerInfo.propTypes = {
  item: PropTypes.object,
};

export default CustomerInfo;
