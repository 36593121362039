/* eslint-disable react/no-unused-class-component-methods */
import { Modal } from 'reactstrap';
import React, { Component } from 'react';
import Title from '@Components/Title';
import { toastError } from '@Utils/toastrHelper';
import { loanCustomerSummary } from '@Apis/admin/loan';
import { Clear } from '@mui/icons-material';
import { formatNumber } from '@Utils/helpers';

class ModalDetailSumary extends Component {
  constructor(props) {
    super(props);
    this.state = { isOpen: false, data: [] };
  }

  UNSAFE_componentWillMount() {
    loanCustomerSummary({ type: 'debt' })
      .then((res) => {
        this.setState({ data: res });
      })
      .catch(toastError);
  }

  close = () => {
    this.setState({ isOpen: false });
  };

  open = () => {
    this.setState({ isOpen: true });
  };

  render() {
    const { isOpen, data } = this.state;
    return (
      <Modal
        isOpen={isOpen}
        centered
        wrapClassName="custom-modal-detail custom-modal-detail-sumary"
      >
        <div className="content-modal-detail">
          <Title
            style={{
              fontSize: 32,
              fontWeight: 600,
              textAlign: 'center',
              width: '100%',
              paddingLeft: 48,
            }}
            name="Chi tiết tổng vốn các kho"
          />
          <div
            className="icon-clear-modal"
            style={{ marginLeft: 24, cursor: 'pointer' }}
            onClick={this.close}
            role="button"
            tabIndex="0"
          >
            <Clear size={14} style={{ color: '#84818A' }} />
          </div>
        </div>
        <div style={{ paddingTop: 42 }}>
          <div className="content-swap">
            <div className="title-content" style={{ fontSize: 12 }}>
              Loại nguồn
            </div>
            <div className="title-content" style={{ fontSize: 12 }}>
              Tổng vốn
            </div>
          </div>
          {data?.map((e, index) => (
            <div className="content-swap" key={index.toString()}>
              <div className="title-content title-content-data uppercase">
                {e.publicCustomer?.full_name}
              </div>
              <div className="title-content title-content-data">{formatNumber(e.total, '--')}</div>
            </div>
          ))}
        </div>
      </Modal>
    );
  }
}

export default ModalDetailSumary;
