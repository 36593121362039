import React from 'react';
import { useSelector } from 'react-redux';
import { Spinner } from 'reactstrap';
import PropTypes from 'prop-types';

function GlobalLoading(props) {
  const { loading, style } = props;
  let isGlobalLoading = useSelector((state) => state.ui.isGlobalLoading);
  if (loading !== undefined) {
    isGlobalLoading = loading;
  }
  return isGlobalLoading ? (
    <div className="loading-box" style={style}>
      <div className="spinner-list">
        <Spinner type="grow" size="sm" color="primary" />
        <Spinner type="grow" size="sm" color="secondary" />
        <Spinner type="grow" size="sm" color="success" />
        <Spinner type="grow" size="sm" color="danger" />
        <Spinner type="grow" size="sm" color="warning" />
      </div>
    </div>
  ) : null;
}

GlobalLoading.propTypes = {
  loading: PropTypes.bool,
  style: PropTypes.object,
};

export default GlobalLoading;
