import React from 'react';
import PropTypes from 'prop-types';

function Type({ type }) {
    const isAdmin = process.env.DASHBOARD_TYPE === 'admin';
  if (type === 'base') {
    return (
      <div style={{ background: 'rgba(47, 105, 179, 0.1)' }}>
        <p className="font-size-14 font-weight-bold py-25 px-25" style={{ color: '#2F69B3' }}>
          CS
        </p>
      </div>
    );
  }
  if (type === 'derivative') {
    return (
      <div style={{ background: 'rgba(237, 28, 36, 0.1)' }}>
        <p className="font-size-14 font-weight-bold py-25 px-25" style={{ color: '#ED1C24' }}>
          PS
        </p>
      </div>
    );
  }
  if (type === 'spot') {
    return (
      <div style={{ background: 'rgba(211, 126, 23, 0.1)' }}>
        <p className="font-size-14 font-weight-bold py-25 px-25" style={{ color: '#EC602E' }}>
          T+
        </p>
      </div>
    );
  }
  if (type === 't0') {
    return (
      <div style={{ background: 'rgba(211, 126, 23, 0.1)' }}>
        <p className="font-size-14 font-weight-bold py-25 px-25" style={{ color: '#EC602E' }}>
          T+
        </p>
      </div>
    );
  }
  if (type === 't2') {
    return (
      <div style={{ background: 'rgba(211, 126, 23, 0.1)' }}>
        <p className="font-size-14 font-weight-bold py-25 px-25" style={{ color: '#EC602E' }}>
          T+
        </p>
      </div>
    );
  }
  if (type === 'deposit') {
    return (
      <div style={{ background: 'rgba(32, 201, 172, 0.1)' }}>
        <p className="text-success font-size-14  font-weight-bold py-25 px-25">Nộp</p>
      </div>
    );
  } if (type === 'non-term-interest') {
    return (
      <div style={{ background: 'rgba(32, 201, 172, 0.1)' }}>
        <p className="text-success font-size-14  font-weight-bold py-25 px-25">Lãi không kỳ hạn</p>
      </div>
    );
  }
    if (type === 'transfer-in') {
        return (
            <div style={{ background: 'rgba(32, 201, 172, 0.1)' }}>
                <p className="text-success font-size-14  font-weight-bold py-25 px-25">Nhận nội bộ</p>
            </div>
        );
    } if (type === 'transfer-out') {
        return (
            <div style={{ background: 'rgba(32, 201, 172, 0.1)' }}>
                <p className="text-danger font-size-14 font-weight-bold py-25 px-25">Chuyển nội bộ</p>
            </div>
        );
    }
  if (type === 'trade-fee') {
    return (
      <div style={{ background: 'rgba(32, 201, 100, 0.1)' }}>
        <p className="font-size-14  font-weight-bold py-25 px-25" style={{ color: '#11C72F' }}>
          Phí giao dịch
        </p>
      </div>
    );
  }
    if (type === 'derivative-on') {
        return (
            <div style={{ background: 'rgba(32, 201, 100, 0.1)' }}>
                <p className="font-size-14  font-weight-bold py-25 px-25" style={{ color: '#11C72F' }}>
                    Phí QL vị thế
                </p>
            </div>
        );
    }
  if (type === 'interest') {
    return (
      <div style={{ background: 'rgba(22, 101, 172, 0.1)' }}>
        <p className="font-size-14  font-weight-bold py-25 px-25" style={{ color: '#2F69B3' }}>
          {isAdmin ? 'Lãi vay' : 'Phí hợp tác'}
        </p>
      </div>
    );
  }
  if (type === 'paybook') {
    return (
      <div style={{ background: 'rgba(12, 201, 102, 0.1)' }}>
        <p className="text-success font-size-14  font-weight-bold py-25 px-25">{isAdmin ? 'Lãi thiếu cọc' : 'Phí thiếu cọc'}</p>
      </div>
    );
  }
  return (
    <div style={{ background: 'rgba(252, 52, 0, 0.1)' }}>
      <p className="text-danger font-size-14 font-weight-bold py-25 px-25">Rút</p>
    </div>
  );
}

Type.propTypes = {
  type: PropTypes.string,
};

export default Type;
