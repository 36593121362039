import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { Edit, Delete } from '@mui/icons-material';
import { BankItem } from './bankAccountStyles';

function BankAccountList({ data, handleEdit, setDeleteItem }) {
  return (
    <div className="mb-1">
      {data &&
        data.length > 0 &&
        data.map((item) => {
          const { account_number, account_name, is_default, id, bank } = item || {};
          return (
            <BankItem key={id}>
              <div className="d-flex">
                <div>
                  <img src="/images/account/bank-icon.svg" alt="bank-icon" />
                </div>
                <div className="pl-2">
                  <p className="font-size-18 mb-0 font-weight-bolder">{bank?.name}</p>
                  <p className="font-size-14 mb-0">{account_number}</p>
                  <p className="font-size-14 m-0 text-uppercase">{account_name}</p>
                </div>
              </div>
              <div className="d-flex flex-column justify-content-between align-items-end">
                {is_default ? (
                  <p className="font-size-14 mb-25 font-weight-bolder color-matte-gray m-0">
                    Tài khoản chính
                  </p>
                ) : (
                  <Button
                    color="link"
                    className="p-0 font-size-14 text-danger"
                    onClick={() => setDeleteItem(item)}
                  >
                    <span className="pr-25">Xoá</span>
                    <Delete color="danger" fontSize="14px" />
                  </Button>
                )}
                <Button color="link" className="p-0 font-size-14" onClick={() => handleEdit(item)}>
                  <span className="pr-25">Thay đổi</span>
                  <Edit color="primary" fontSize="14px" />
                </Button>
              </div>
            </BankItem>
          );
        })}
    </div>
  );
}

BankAccountList.propTypes = {
  handleEdit: PropTypes.func,
  data: PropTypes.array,
  setDeleteItem: PropTypes.func,
};

export default BankAccountList;
