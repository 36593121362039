import React, { Suspense, useEffect } from 'react';
import ErrorBoundary from '@Components/ErrorBoundary';
import Loader from '@Components/Loader';
import { getCurrentRole, getProfile, handleBankList } from '@Lib/slices/userSlice';
import { getTellers, getWareHouse } from '@Lib/slices/transactionSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import axiosService from '@Lib/axiosService';
import PrivateRouter from './PrivateRouter';
import PublicRouter from './PublicRouter';

function Routers() {
  const { info, isLoading } = useSelector((state) => ({
    info: state.user.info,
    isLoading: state.user.isLoading,
  }));

  const token = localStorage.getItem(process.env.TOKEN);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (!token) {
      navigate('/login');
    } else if (!info) {
      dispatch(getProfile());
      dispatch(handleBankList());
      if (process.env.DASHBOARD_TYPE === 'admin') {
        dispatch(getTellers());
        dispatch(getWareHouse());
      }
    } else if (process.env.DASHBOARD_TYPE === 'admin') {
      dispatch(getCurrentRole());
    }
  }, [info, token]);

  if (token) {
    axiosService.setHeader('Authorization', `Bearer ${token}`);
  } else {
    return <PublicRouter />;
  }

  if (isLoading) {
    return null;
  }

  return (
    <ErrorBoundary>
      <Suspense fallback={<Loader />}>
        <PublicRouter />
        {token && <PrivateRouter />}
      </Suspense>
    </ErrorBoundary>
  );
}

export default Routers;
