import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import FlatPickrDate from '@Components/FlatPickrDate';
import dayjs from 'dayjs';

function FlatPickrInput({
  name,
  labelName,
  placeholder,
  setValue,
  defaultValue,
  register,
  errors,
}) {
  const [date, setDate] = useState();

  useEffect(() => {
    if (setValue && defaultValue) {
      setDate(defaultValue);
      setValue(name, defaultValue);
    }
  }, [defaultValue]);

  const onChangeDate = (e) => {
    const unitTime = dayjs(e).unix();
    setValue(name, unitTime);
    setDate(unitTime);
  };

  return (
    <div className="row form-group">
      <label htmlFor="dob" className=" py-0 font-size-14 mb-50 col-md-12 col-form-label">
        {labelName}
      </label>
      <div className="col-md-12 ">
        <FlatPickrDate
          name={name}
          placeholder={placeholder}
          date={date}
          setDate={(_, date) => onChangeDate(date)}
          className="bg-white flatpickr-input-custom"
        />
        <input name={name} type="hidden" {...register} />
        {errors && errors[`${name}`] && (
          <p className="text-danger font-size-12 mb-0" style={{ paddingTop: '2px' }}>
            {errors[`${name}`].message || 'Trường bắt buộc'}
          </p>
        )}
      </div>
    </div>
  );
}

FlatPickrInput.propTypes = {
  name: PropTypes.string,
  labelName: PropTypes.string,
  placeholder: PropTypes.string,
  register: PropTypes.object,
  setValue: PropTypes.func,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  errors: PropTypes.object,
};

export default FlatPickrInput;
