import axiosService from '@Lib/axiosService';
import { handleResponse } from '@Utils/helpers';

let url = '';
if (process.env.DASHBOARD_TYPE === 'admin') {
  url = `${process.env.API_ADMIN_URL}/payment-request`;
}

export const approve = async (body) => {
  const res = await axiosService.post(`${url}/approve`, body);
  return handleResponse(res);
};

export const paymentIndex = async (query) => {
  const res = await axiosService.get(`${url}/list`, query);
  return handleResponse(res);
};

export const paymentExport = async (query) => {
  const res = await axiosService.get(`${url}/export`, query);
  return handleResponse(res);
};

export const listDeposit = async (query) => {
  const res = await axiosService.get(`${url}/deposit-accounts`, query);
  return handleResponse(res);
};

export const paymentUpdate = async (body) => {
  const res = await axiosService.post(`${url}/save`, body);
  return handleResponse(res);
};

export const paymentReject = async (body) => {
  const res = await axiosService.post(`${url}/reject`, body);
  return handleResponse(res);
};
