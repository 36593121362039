import React from 'react';
import PropTypes from 'prop-types';
import { usePagination } from '@Utils/usePagination';
import { Pagination, PaginationItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import shortid from 'shortid';
import { ChevronRight, ChevronLeft } from 'react-feather';

function PaginateCustome({ data, pageSize, setPageSize, pageSizeName = 'page' }) {
  const { total_page: total, page: pageProps } = data;
  const current_page = Number(pageProps);
  const paginationRange = usePagination({
    total,
    siblingCount: 1,
    current_page,
  });

  const getPageLink = (index) => {
    const { pathname, search } = window.location;
    const urlParams = new URLSearchParams(search);
    urlParams.set(pageSizeName, index);
    return `${pathname}?${urlParams.toString()}`;
  };

  return (
    total > 1 && (
      <div className="d-flex justify-content-end align-items-center custom-paginate pr-75">
        <Pagination>
          <PaginationItem disabled={current_page === 1}>
            <Link className="page-link" to={getPageLink(current_page - 1)} aria-label="First">
              <span>
                <ChevronLeft />
              </span>
            </Link>
          </PaginationItem>
          {paginationRange?.map((pagi) => {
            if (pagi === 'DOTS') {
              return (
                <PaginationItem key={shortid.generate(3)}>
                  <NavLink className="page-link">...</NavLink>
                </PaginationItem>
              );
            }
            return (
              <PaginationItem active={pagi === current_page} key={pagi}>
                <Link className="page-link" to={getPageLink(pagi)}>
                  {pagi}
                </Link>
              </PaginationItem>
            );
          })}
          <PaginationItem disabled={current_page === total}>
            <Link className="page-link" to={getPageLink(current_page + 1)} aria-label="Last">
              <span>
                <ChevronRight />
              </span>
            </Link>
          </PaginationItem>
        </Pagination>
      </div>
    )
  );
}

PaginateCustome.propTypes = {
  data: PropTypes.object,
  query: PropTypes.object,
  pageSize: PropTypes.number,
  setPageSize: PropTypes.func,
  pageSizeName: PropTypes.string,
};

export default PaginateCustome;
