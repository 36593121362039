import React, { useState } from 'react';
import Title from '@Components/Title';
import { Row, Col } from 'reactstrap';
import TabPanel from '@Components/TabPanel';
import { currentProfile } from '@Apis/profile/account';
import useCustomQuery from '@Utils/useCustomQuery';
import { TAB_PANEL } from './constant';
import Info from './components/Info';
import FeesRates from './components/FeesRates';
import BankAccount from './components/BankAccount';
import ChangePassword from './components/ChangePassword';

function Account() {
  const [activeTab, setActiveTab] = useState(1);

  const toggleTab = (tabId) => {
    setActiveTab(tabId);
  };

  const { data: profile } = useCustomQuery(currentProfile, {}, true);

  if (!profile) {
    return null;
  }

  return (
    <div className="content-wrapper">
      <Title name="Tài khoản" />
      <Row className="pt-2">
        <Col md="3">
          <TabPanel activeTab={activeTab} toggleTab={toggleTab} tabs={TAB_PANEL} />
        </Col>
        <Col md="9">
          {activeTab === 1 && <Info profile={profile} />}
          {activeTab === 2 && <FeesRates profile={profile} />}
          {activeTab === 3 && <BankAccount />}
          {activeTab === 4 && <ChangePassword />}
        </Col>
      </Row>
    </div>
  );
}

Account.propTypes = {};

export default Account;
