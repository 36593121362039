/* eslint-disable indent */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { profitReport } from '@Apis/admin/report';
import { Container } from 'reactstrap';
import { toastError } from '@Utils/toastrHelper';
import NavigationBar from '@Components/NavigationBar';
import dayjs from 'dayjs';
import { LEFT_COLUMN_PROFIT, LEFT_COLUMN_PROFIT_PS } from '../../constant';
import TableBase from './TableBase';
import TablePS from './TablePS';
import { TYPE_CS, TYPE_PS } from '@Components/Layouts/PrivateLayout';
import './styles.scss';

const TABS = [
  { id: 0, name: 'Cơ sở/T+', left: true },
  { id: 1, name: 'Phái sinh' },
];

function ProfitLoss({ dateRange, isExport, setDataExcel, view_by }) {
  const [datas, setDatas] = useState({ rows: {}, headers: [] });
  const [tab, setTab] = useState(0);

  useEffect(() => {
    getData();
  }, [dateRange, view_by, tab]);

  const getData = useCallback(async () => {
    try {
      const response = await profitReport({
        date_range: dateRange,
        view_by,
        trade_type: tab == 0 ? TYPE_CS : TYPE_PS,
      });
      const headers = response.map(
        (item) => item.label || dayjs.unix(item.report_at).format('DD/MM/YYYY'),
      );
      const COLUMN_PROFIT = tab == 0 ? LEFT_COLUMN_PROFIT : LEFT_COLUMN_PROFIT_PS;
      const { rows } = COLUMN_PROFIT.reduce(
        (pre, current) => {
          pre.rows[current.title] = {
            name: current.name,
            data: response.map((item) => ({
              label: item.label,
              report_at: dayjs.unix(item.report_at).format('DD/MM/YYYY'),
              total: item[`${current.name}`] || 0,
            })),
            canEdit: current.canEdit,
            type: current.type,
          };
          return pre;
        },
        { rows: {} },
      );
      setDatas({ rows, headers });
      if (setDataExcel) {
        setDataExcel({ rows, headers }, tab == 0 ? TYPE_CS : TYPE_PS);
      }
    } catch (e) {
      toastError(e);
    }
  }, [dateRange, view_by, tab]);

  return (
    <Container className="profit-loss">
      <div style={{ paddingTop: 10 }}>
        <NavigationBar className="custom-bar" tabs={TABS} activeTab={tab} setActiveTab={setTab} />
      </div>

      {tab == 0 && <TableBase datas={datas} isExport={isExport} getData={getData} />}
      {tab == 1 && <TablePS datas={datas} isExport={isExport} getData={getData} />}
    </Container>
  );
}

ProfitLoss.propTypes = {
  dateRange: PropTypes.string,
  view_by: PropTypes.any,
  isExport: PropTypes.bool,
  setDataExcel: PropTypes.func,
};

export default ProfitLoss;
