import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalBody } from 'reactstrap';
import Tabs from './Tabs';
import { PopUpWrapper } from './styles';
import { TABS_POPUP_CREATE } from '../../constants';
import Buy from './Buy';
import Sell from './Sell';

function PopUpCreate({
  isOpen,
  closeModal,
  refetch,
  optionsTeller,
  optionsWarehouse,
  setIsRefreshStatus,
}) {
  const [activeTabId, setActiveTabId] = useState(1);

  return (
    <Modal
      isOpen={isOpen}
      toggle={closeModal}
      style={{
        maxWidth: `${activeTabId === 1 ? '900px' : '1200px'}`,
        maxHeight: '716px',
        padding: '0',
      }}
      className="text-center popup-create-trade"
    >
      <PopUpWrapper>
        <ModalBody className="p-0">
          <h3 className="py-1 text-center">LẬP LỆNH {activeTabId === 1 ? 'MUA' : 'BÁN'}</h3>
          <Tabs tabs={TABS_POPUP_CREATE} activeTab={activeTabId} setActiveTab={setActiveTabId} />
          {activeTabId === 1 && (
            <Buy
              closeModal={closeModal}
              refetch={refetch}
              optionsTeller={optionsTeller}
              optionsWarehouse={optionsWarehouse}
              setIsRefreshStatus={setIsRefreshStatus}
            />
          )}
          {activeTabId === 2 && (
            <Sell
              closeModal={closeModal}
              refetch={refetch}
              optionsTeller={optionsTeller}
              optionsWarehouse={optionsWarehouse}
              setIsRefreshStatus={setIsRefreshStatus}
            />
          )}
        </ModalBody>
      </PopUpWrapper>
    </Modal>
  );
}

PopUpCreate.propTypes = {
  isOpen: PropTypes.bool,
  closeModal: PropTypes.func,
  optionsTeller: PropTypes.array,
  optionsWarehouse: PropTypes.array,
  refetch: PropTypes.func,
  setIsRefreshStatus: PropTypes.func,
};

export default PopUpCreate;
