import axiosService from '@Lib/axiosService';
import { handleResponse } from '@Utils/helpers';

let host;
if (process.env.DASHBOARD_TYPE === 'customer') {
  host = `${process.env.API_CUSTOMER_URL}/trade`;
}

export const list = async (query) => {
  const res = await axiosService.get(`${host}/transactions`, query);
  return handleResponse(res);
};

export const stockCanSell = async (query) => {
  const res = await axiosService.get(`${host}/can-sell`, query);
  return handleResponse(res);
};

export const sellStock = async (body) => {
  const res = await axiosService.post(`${host}/trade/sell`, body);
  return handleResponse(res);
};

export const sellByStock = async (body) => {
  const res = await axiosService.post(`${host}/sell-by-stock`, body);
  return handleResponse(res);
};

export const buyStock = async (body) => {
  const res = await axiosService.post(`${host}/buy`, body);
  return handleResponse(res);
};

export const transactionHistory = async (query) => {
  const res = await axiosService.get(`${host}/transactions`, query);
  return handleResponse(res);
};

export const cancelTransaction = async (body) => {
  const res = await axiosService.post(`${host}/cancel`, body);
  return handleResponse(res);
};

export const editTransaction = async (body) => {
  const res = await axiosService.post(`${host}/edit`, body);
  return handleResponse(res);
};

export const closePosition = async (query) => {
  const res = await axiosService.get(`${host}/position-history`, query);
  return handleResponse(res);
};
