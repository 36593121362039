import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { Download } from '@mui/icons-material';

function ButtonDownloadFile({ onClick, style, className, colorText }) {
  return (
    <Button
      outline
      color="none"
      className={className || 'bg-white d-flex align-items-center'}
      onClick={onClick}
      style={{ border: '1px solid #EBEAED', ...style }}
    >
      <Download color="primary" fontSize="14px" />
      <span
        style={{ color: colorText || '#2F69B3' }}
        className="font-size-14 font-weight-bolder white-space-nowrap ml-25"
      >
        Xuất báo cáo
      </span>
    </Button>
  );
}

ButtonDownloadFile.propTypes = {
  onClick: PropTypes.func,
  style: PropTypes.object,
  className: PropTypes.string,
  colorText: PropTypes.string,
};

export default ButtonDownloadFile;
