import styled from 'styled-components';

export const SuggestWrapper = styled.div`
  width: 100%;
  .react-autosuggest__input {
    display: block;
    width: 100%;
    height: 2.5rem;
    padding: 0.438rem 1rem;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.45;
    color: #6e6b7b;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #d8d6de;
    border-radius: 4px;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
  .react-autosuggest__container {
    position: relative;
    .react-autosuggest__suggestions-container {
      position: absolute;
      width: 100%;
      z-index: 10;
    }
  }
  .react-autosuggest__input--focused {
    border: 1px solid #2f69b3;
    outline: none;
  }
  .react-autosuggest__suggestions-list {
    border: 1px solid #d8d6de;
    padding-inline-start: 0;
    list-style-type: none;
    background: #fff;
    margin-top: 3px;
    max-height: 200px;
    overflow-y: auto;
    text-align: left;
    li {
      padding: 8px 10px;
      cursor: pointer;
      border-top: 0;
    }
    li:hover {
      color: #2f69b3;
      background-color: rgba(47, 105, 179, 0.12);
    }
  }
`;
