import React from 'react';
import PropTypes from 'prop-types';
import { Nav, NavItem, NavLink } from 'reactstrap';

function TabPanel({ activeTab, toggleTab, tabs }) {
  return (
    <Nav className="nav-left" pills vertical>
      {tabs.map((tab) => {
        const { id, name, icon, iconActive } = tab;
        return (
          <NavItem key={id}>
            <NavLink active={activeTab === id} onClick={() => toggleTab(id)}>
              <img src={activeTab === id ? iconActive : icon} alt={name} />
              <span className="font-weight-bold ml-1 font-size-14">{name}</span>
            </NavLink>
          </NavItem>
        );
      })}
    </Nav>
  );
}

TabPanel.propTypes = {
  tabs: PropTypes.array,
  activeTab: PropTypes.number,
  toggleTab: PropTypes.func,
};

export default TabPanel;
