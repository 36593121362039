/* eslint-disable indent */
import React from 'react';
import { formatNumber, parseNumber } from '@Utils/helpers';
import { Link } from 'react-router-dom';

export const columnsDashboardPS = () => {
  return [
    {
      name: 'MÃ CK',
      cell: (row) => <div className="font-weight-bolder">{row.stock_id || row.id}</div>,
      center: true,
      sortable: true,
      sortField: 'id',
      width: '170px',
    },
    {
      name: 'GIÁ HIỆN TẠI',
      cell: (row) => (
        <div className="w-100 text-align-end">
          {parseNumber(typeof row.price === 'number' ? row.price : row.stock?.price)}
        </div>
      ),
      right: true,
      sortable: true,
      sortField: 'price',
      width: '170px',
    },
    {
      name: 'Số vị thế mở',
      cell: (row) => (
        <div className="w-100 text-align-end">
          {formatNumber(
            row.stock?.total_quantity || row.totalQuantity?.quantity || row?.quantity,
            0,
          )}
        </div>
      ),
      center: true,
      width: '170px',
      sortable: true,
      sortField: 'total_quantity',
    },
    {
      name: 'SỐ KH SỞ HỮU',
      cell: (row) => (
        <div className="text-primary">
          {formatNumber(row.stock?.customer_count || row.customerCount?.count, 0)}
        </div>
      ),
      right: true,
      width: '140px',
      sortable: true,
      sortField: 'customer_count',
    },
    {
      name: '',
      left: true,
      cell: (row) => (
        <Link className="d-flex align-items-center ml-1" to={`/stock/${row.trade_type}/${row.id}`}>
          <span className="text-primary font-weight-bolder mr-50">Chi tiết</span>
          <img src="/images/icon/arrow-box.svg" alt="view-detail" />
        </Link>
      ),
    },
  ];
};
