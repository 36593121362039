import React, { useState, useEffect, useCallback } from 'react';
import Title from '@Components/Title';
import ButtonSubmit from '@Components/ButtonSubmit';
import DatePicker from '@Components/DatePicker';
import { getChartDashboard } from '@Apis/profile/account';
import { useDispatch } from 'react-redux';
import { toastError } from '@Utils/toastrHelper';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { hideGlobalLoading, showGlobalLoading } from '@Lib/slices/uiSlice';
import ChartJS from './ChartJs';

function Chart(props) {
  const { apiGet = getChartDashboard, accountId } = props;
  const [dataState, setData] = useState({ dataPoint: [], labels: [], dataPoint2: [] });
  const [buttonFilter, setButtonFilter] = useState(1);
  const [date_range, setDateRange] = useState([dayjs().subtract(buttonFilter, 'month'), dayjs()]);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!accountId) return;

    const filter = {
      date_range: date_range.map((e) => dayjs(e).unix()).join('-'),
      customer_id: accountId,
    };
    const diffDays = dayjs(date_range[1]).diff(dayjs(date_range[0]), 'days');
    dispatch(showGlobalLoading());
    apiGet(filter)
      .then(({ data }) => {
        const d = [];
        const d2 = [];
        const l = [];
        for (let i = 0; i <= diffDays; i++) {
          const date = dayjs(date_range[0]).add(i, 'day');
          const dataPush = data.data.find(
            (e) => dayjs.unix(e.report_at).format('DD/MM/YYYY') === date.format('DD/MM/YYYY'),
          );
          d.push(dataPush?.daily_return || 0);
          d2.push(dataPush?.margin_rate || 0);
          l.push(date.format('DD/MM'));
        }
        setData({ dataPoint: d, labels: l, dataPoint2: d2 });
        dispatch(hideGlobalLoading());
      })
      .catch((e) => {
        toastError(e);
        setData({ dataPoint: [], labels: [], dataPoint2: [] });
        dispatch(hideGlobalLoading());
      });
  }, [accountId, date_range]);

  const changeRange = useCallback((v) => {
    setDateRange(v);
    if (v.length) {
      setButtonFilter(-1);
    } else {
      setButtonFilter(0);
    }
  }, []);

  const getActive = useCallback(
    (v) => {
      if (v === buttonFilter) {
        return 'primary';
      }
    },
    [buttonFilter],
  );

  const setButtonActive = useCallback((v) => {
    setDateRange([dayjs().subtract(v || 12, 'month'), dayjs()]);
    setButtonFilter(v);
  }, []);

  return (
    <div className="chart-dashboard">
      <div className="header-chart">
        <Title style={{ fontSize: 18 }} name="NAV tài sản" />
        <div className="search-chart" style={{ fontSize: 14 }}>
          <ButtonSubmit
            onClick={() => setButtonActive(1)}
            name="1 Tháng"
            size="md"
            outline={buttonFilter !== 1}
            style={{ color: '#000' }}
            color={getActive(1)}
          />
          <ButtonSubmit
            onClick={() => setButtonActive(3)}
            style={{ color: '#000' }}
            name="3 Tháng"
            outline={buttonFilter !== 3}
            size="md"
            color={getActive(3)}
          />
          <ButtonSubmit
            onClick={() => setButtonActive(6)}
            style={{ color: '#000' }}
            name="6 Tháng"
            outline={buttonFilter !== 6}
            size="md"
            color={getActive(6)}
          />
          <ButtonSubmit
            onClick={() => setButtonActive(0)}
            style={{ color: '#000' }}
            name="Tất cả"
            size="md"
            outline={buttonFilter !== 0}
            color={getActive(0)}
            wrapClassName="btn-last"
          />
          <DatePicker.Range
            onChange={changeRange}
            value={date_range}
            tintColor="#2E2C34"
            placeholder="Chọn ngày"
          />
        </div>
      </div>
      <div style={{ overflow: 'auto' }}>
        <ChartJS data={dataState} />
      </div>
    </div>
  );
}

Chart.propTypes = {
  apiGet: PropTypes.func,
};

export default Chart;
