/* eslint-disable import/no-cycle */
/* eslint-disable indent */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { sidebarActive } from '@Lib/slices/uiSlice';
import { Badge, NavLink } from 'reactstrap';

export default function Item({ item, closeMenu, className }) {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pathname } = location;
  const { notificationCountAdmin } = useSelector(({ ui }) => ({
    notificationCountAdmin: ui.notificationCountAdmin,
  }));
  const { notificationCount } = useSelector(({ ui }) => ({
    notificationCount: ui.notificationCount,
  }));

  const handleClickItem = () => {
    if (item.onClick) {
      return item.onClick();
    }
    dispatch(sidebarActive(item.id));
    navigate(`${item.path}`);
  };

  const isActive =
    pathname === item.path || (item.relatedPath && pathname.includes(item.relatedPath));
  useEffect(() => {
    if (isActive) {
      if (process.env.DASHBOARD_TYPE === 'admin') {
        const unread =
          notificationCountAdmin.un_read === undefined ? 0 : notificationCountAdmin.un_read;
        const request =
          (notificationCountAdmin.created_transaction === undefined
            ? 0
            : notificationCountAdmin.created_transaction) +
          (notificationCountAdmin.pending_transaction === undefined
            ? 0
            : notificationCountAdmin.pending_transaction);
        document.title = `(${unread}-${request}) ${item.name} - Admin Vietcaplink FS`;
      } else {
        const unread = notificationCount === undefined ? 0 : notificationCount;
        document.title = `(${unread}) ${item.name} - Vietcaplink`;
      }
    }
  });

  return (
    <li className={`nav-item ${isActive ? 'active' : ''} ${className || ''}`}>
      <NavLink
        className="d-flex align-items-center justify-content-between"
        type="button"
        onClick={() => handleClickItem()}
      >
        <div>
          {pathname === item.path || (item.relatedPath && pathname.includes(item.relatedPath)) ? (
            <img
              className="mr-1"
              width="18"
              alt="icon"
              src={`/images/icon/${item.icon}-active.svg`}
            />
          ) : (
            <img className="mr-1" width="18" alt="icon" src={`/images/icon/${item.icon}.svg`} />
          )}
          {closeMenu ? null : (
            <span className="font-size-16 tx-medium sidebar-menu-name">{item.name}</span>
          )}
        </div>
        {closeMenu ? null : (
          <>
            {item.path === '/transaction-tellers' &&
            notificationCountAdmin.pending_transaction > 0 ? (
              <Badge
                style={{ position: 'relative', top: 0, marginRight: 5 }}
                color="danger"
                className="badge-up"
              >
                {notificationCountAdmin.pending_transaction}
              </Badge>
            ) : null}
            {item.path === '/transaction' && notificationCountAdmin.created_transaction > 0 ? (
              <Badge
                style={{ position: 'relative', top: 0, marginRight: 5 }}
                color="danger"
                className="badge-up"
              >
                {notificationCountAdmin.created_transaction}
              </Badge>
            ) : null}
          </>
        )}
      </NavLink>
    </li>
  );
}

Item.propTypes = {
  item: PropTypes.object,
  closeMenu: PropTypes.bool,
  className: PropTypes.string,
};
