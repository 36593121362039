import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { Container, Row, Col, Button } from 'reactstrap';
import { useForm } from 'react-hook-form';
import TextInput from '@Components/Input/TextInput';
import { customerInsert, customerSaveFees } from '@Apis/admin/customer';
import ButtonSubmit from '@Components/ButtonSubmit';
import useCustomMutation from '@Utils/useCustomMuation';
import { toastError, toastSuccess } from '@Utils/toastrHelper';
import SearchEmployer from '@Components/Input/SearchEmployer';
import { Percent } from '@mui/icons-material';
import { accountsHome } from '@Apis/client/home';
import useCustomQuery from '@Utils/useCustomQuery';
import { typeConst } from '@Components/Layouts/PrivateLayout';
import { hideGlobalLoading } from '@Lib/slices/uiSlice';
import { useDispatch } from 'react-redux';

function SupportConfig({ item, refetch }) {
  const refForm = useRef();
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  const { data } = useCustomQuery(accountsHome, { id: item?.id });

  const onSubmit = (formData) => {
    const dataUpdate = {};
    Object.keys(formData).forEach((key) => {
      const accountId = key.split('_')[0];
      const dataKey = key.replace(`${accountId}_`, '');
      if (!dataUpdate[accountId]) dataUpdate[accountId] = {};
      dataUpdate[accountId][dataKey] = formData[key];
      dataUpdate[accountId].id = parseInt(accountId);
    });

    doUpdate(dataUpdate);
  };

  const doUpdate = (data) => {
    customerSaveFees({
      accounts: data,
    })
      .then(async (res) => {
        dispatch(hideGlobalLoading());
        toastSuccess('Lưu dữ liệu thành công.');
      })
      .catch((e) => {
        toastError(e);
        dispatch(hideGlobalLoading());
      });
  };

  return data && data.length > 0 ? (
    <Container className="pt-2">
      <form onSubmit={handleSubmit(onSubmit)} ref={refForm}>
        {data?.map((account) => (
          <div key={account.id}>
            <Row className="mb-3">
              <Col md="12" xs="12">
                <p className="font-size-24 font-weight-bold mb-1">
                  {typeConst[account.trade_type].label}
                </p>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col md="4" xs="12">
                <p className="font-size-16 mb-1">Nhân viên hỗ trợ</p>
              </Col>
              <Col md="8" xs="12">
                <Row>
                  <Col md="4">
                    <SearchEmployer
                      labelName="Sale *"
                      labelCol="12"
                      inputCol="12"
                      labelClassName="font-size-14 mb-50"
                      name={`${account.id}_user_id`}
                      register={{ ...register(`${account.id}_user_id`) }}
                      errors={errors}
                      setValue={setValue}
                      defaultValue={account?.user_id}
                    />
                  </Col>
                  <Col md="4">
                    <SearchEmployer
                      labelName="KSV *"
                      labelCol="12"
                      inputCol="12"
                      labelClassName="font-size-14 mb-50"
                      name={`${account.id}_surveyor_id`}
                      register={{ ...register(`${account.id}_surveyor_id`) }}
                      errors={errors}
                      setValue={setValue}
                      defaultValue={account?.surveyor_id}
                    />
                  </Col>
                  <Col md="4">
                    <SearchEmployer
                      labelName="GDV *"
                      labelCol="12"
                      inputCol="12"
                      labelClassName="font-size-14 mb-50"
                      name={`${account.id}_tellers_user_id`}
                      register={{
                        ...register(`${account.id}_tellers_user_id`),
                      }}
                      errors={errors}
                      setValue={setValue}
                      defaultValue={account?.tellers_user_id}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col md="4" xs="12">
                <p className="font-size-16 mb-1">Phí và Tỉ lệ</p>
              </Col>
              <Col md="8" xs="12">
                <Row>
                  <Col md="4">
                    <TextInput
                      labelCol="12"
                      inputCol="12"
                      type="number"
                      labelClassName="font-size-14 mb-50"
                      labelName="Phí vay/ngày *"
                      placeholder="Phí vay/ngày "
                      setValue={setValue}
                      name={`${account.id}_interest_rate`}
                      register={{ ...register(`${account.id}_interest_rate`) }}
                      errors={errors}
                      disabled
                      defaultValue={account ? account.interest_rate : ''}
                      icon={<Percent fontSize="14px" />}
                    />
                  </Col>
                  <Col md="4">
                    <TextInput
                      labelCol="12"
                      inputCol="12"
                      type="number"
                      labelClassName="font-size-14 mb-50"
                      labelName="Phí mua *"
                      placeholder="Phí mua"
                      setValue={setValue}
                      name={`${account.id}_buy_rate`}
                      register={{ ...register(`${account.id}_buy_rate`) }}
                      errors={errors}
                      disabled
                      defaultValue={account ? account.buy_rate : ''}
                      icon={<Percent fontSize="14px" />}
                    />
                  </Col>
                  <Col md="4">
                    <TextInput
                      labelCol="12"
                      inputCol="12"
                      type="number"
                      labelClassName="font-size-14 mb-50"
                      labelName="Phí bán *"
                      placeholder="Phí bán"
                      setValue={setValue}
                      name={`${account.id}_sell_rate`}
                      register={{ ...register(`${account.id}_sell_rate`) }}
                      errors={errors}
                      disabled
                      defaultValue={account ? account.sell_rate : ''}
                      icon={<Percent fontSize="14px" />}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md="6">
                    <TextInput
                      labelCol="12"
                      inputCol="12"
                      type="number"
                      labelClassName="font-size-14 mb-50"
                      labelName="Tỷ lệ ký quỹ *"
                      placeholder="Tỷ lệ ký quỹ"
                      setValue={setValue}
                      name={`${account.id}_margin_rate`}
                      register={{ ...register(`${account.id}_margin_rate`) }}
                      errors={errors}
                      disabled
                      defaultValue={account ? account.margin_rate : ''}
                      icon={<Percent fontSize="14px" />}
                    />
                  </Col>
                  <Col md="6">
                    <TextInput
                      labelCol="12"
                      inputCol="12"
                      type="number"
                      labelClassName="font-size-14 mb-50"
                      labelName="Tỷ lệ call *"
                      placeholder="Tỷ lệ call"
                      setValue={setValue}
                      name={`${account.id}_call_rate`}
                      register={{ ...register(`${account.id}_call_rate`) }}
                      errors={errors}
                      disabled
                      defaultValue={account ? account.call_rate : ''}
                      icon={<Percent fontSize="14px" />}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md="6">
                    <TextInput
                      labelCol="12"
                      inputCol="12"
                      type="number"
                      labelClassName="font-size-14 mb-50"
                      labelName="lãi thiếu cọc"
                      placeholder="lãi thiếu cọc"
                      setValue={setValue}
                      name={`${account.id}_margin_interest_rate`}
                      register={{ ...register(`${account.id}_margin_interest_rate`) }}
                      errors={errors}
                      disabled
                      defaultValue={account ? account.margin_interest_rate : ''}
                    />
                  </Col>
                  <Col md="6">
                    <TextInput
                      labelCol="12"
                      inputCol="12"
                      type="number"
                      labelClassName="font-size-14 mb-50"
                      labelName="Sức mua cố định"
                      placeholder="Sức mua cố định"
                      setValue={setValue}
                      name={`${account.id}_fixed_buy_ability`}
                      register={{ ...register(`${account.id}_fixed_buy_ability`) }}
                      errors={errors}
                      disabled
                      defaultValue={account ? account.fixed_buy_ability : ''}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <div className="d-line mb-3" />
          </div>
        ))}

        <Row className="justify-content-center gap-20" style={{ paddingBottom: 60 }}>
          <ButtonSubmit name="Lưu thay đổi" className="px-2" size="md" color="primary" />
          <Button outline type="button">
            Hủy
          </Button>
        </Row>
      </form>
    </Container>
  ) : null;
}

SupportConfig.propTypes = {
  item: PropTypes.object,
  refetch: PropTypes.func,
};

export default SupportConfig;
