/* eslint-disable import/no-cycle */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import FlatPickrDateRange from '@Components/FlatPickrDateRange';
import InputSearch from '@Components/InputSearch';
import { Add, AddCircleOutline } from '@mui/icons-material';
import { Button, Modal } from 'reactstrap';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import DataTableBasic from '@Components/DataTableBasic';
import NavigationBar from '@Components/NavigationBar';
import useCustomQuery from '@Utils/useCustomQuery';
import { customers, exportList, overview, setMemberPackage } from '@Apis/admin/customer';
import { TYPE_CS, TYPE_PS, TYPE_TPLUS } from '@Components/Layouts/PrivateLayout';
import FilterComponent from '@Components/FilterComponent';
import { TABS } from './constants';
import { columnsCustomerBase, columnsAll, columnsCustomerTPlus, columnsCustomerPS } from './components/Columns';
import Summary from './components/Summary';
import SelectInput from '@Components/Input/SelectInput';
import Title from '@Components/Title';
import TextInput from '@Components/Input/TextInput';
import { memberPackages } from '@Apis/admin/memberPackage';
import { toastError, toastSuccess } from '@Utils/toastrHelper';

export const TAB_PANEL = [
  { id: 1, name: 'Tất cả' },
  { id: 2, name: 'CK Cơ sở' },
  { id: 3, name: 'CK T+' },
  { id: 4, name: 'CK Phái sinh' },
];
function Customer() {
  const navigate = useNavigate();
  const { search: searchQuery } = useLocation();

  const [searchParams, setSearchParams] = useSearchParams();
  const page = searchParams.get('page');
  const keyword = searchParams.get('keyword');
  const dateRange = searchParams.get('date_range');
  const customerCode = searchParams.get('customer_code');

  const [selectedPackage, setSelectedPackage] = useState();
  const [userChangePackage, setUserChangePackage] = useState({});
  const [activeTabId, setActiveTabId] = useState(0);
  const [activeTabIdPanel, setActiveTabIdPanel] = useState(1);
  const [filter, setFilter] = useState({
    date_range: dateRange,
    keyword,
    customer_code: customerCode,
    status: '',
    per_page: 100000,
  });

  useEffect(() => {
    if (Object.keys(userChangePackage).length == 0)
      setSelectedPackage();
  }, [userChangePackage]);

  useEffect(() => {
    if (page) {
      setFilter({ ...filter, page });
    }
  }, [page]);

  const garenateTradeType = () => {
    if (activeTabIdPanel === 1) return {};
    if (activeTabIdPanel === 2) return { trade_type: TYPE_CS };
    if (activeTabIdPanel === 3) return { trade_type: TYPE_TPLUS };
    return { trade_type: TYPE_PS };
  };

  const { data, refetch: refetchListCustomer } = useCustomQuery(customers, { ...filter, ...garenateTradeType() }, true);
  const { data: listPackages } = useCustomQuery(
    memberPackages,
    activeTabIdPanel === 1 ? { trade_type: TYPE_CS } : garenateTradeType(),
  );
  const { data: countOverView } = useCustomQuery(overview, {...filter, ...garenateTradeType()}, true);
  
  const listCustomerPackages = useMemo(() => {
    if (listPackages && listPackages.data.length > 0) {
      return listPackages?.data.map((e) => {
        return {
          label: e.name,
          value: e.id,
        };
      });
    }
    return [];
  }, [listPackages]);

  useEffect(() => {
    navigate({
      pathname: '/customer',
      search: searchQuery,
    });
    const activeTab = TABS(countOverView).find((item) => item.id === activeTabId);
    if (activeTab) {
      const { value } = activeTab;
      if (activeTab.id !== 3 && activeTab.id !== 2) {
        filter.status = value;
        delete filter.indebted;
        delete filter.warning;
      } else if (activeTab.id === 3) {
        delete filter.status;
        filter.indebted = 1;
        delete filter.warning;
      } else if (activeTab.id === 2) {
        delete filter.status;
        delete filter.indebted;
        filter.warning = 1;
      }
      setFilter({ ...filter, page: 1 });
    }
  }, [activeTabId]);

  const filterOnChange = (name, value) => {
    searchParams.set(name, value);
    setSearchParams(searchParams);
    setFilter((prevValues) => {
      return {
        ...prevValues,
        [name]: value,
      };
    });
  };

  const setOrderBy = useCallback((column, type = '') => {
    console.log('setOrderBy: ', column, type)
    setFilter((prevValues) => {
      return {
        ...prevValues,
        sort_by: column.sortField,
        order: type.toUpperCase(),
      };
    });
  }, []);

  const getColumns = useCallback((params) => {
    return activeTabIdPanel === 2
      ? columnsCustomerBase(params)
      : activeTabIdPanel === 4
      ? columnsCustomerPS(params)
      : activeTabIdPanel === 3
      ? columnsCustomerTPlus(params)
      : columnsAll(params);
  }, [activeTabIdPanel, activeTabId]);

  const onSearch = useCallback((fil) => {
    setFilter((pre) => ({ ...pre, ...fil }));
  }, []);

  const onExport = useCallback(
    async (fil) => {
      return exportList({ ...filter, ...garenateTradeType(), ...fil });
    },
    [filter, activeTabIdPanel],
  );

  const handleChangeCustomerPackage = () => {
    setMemberPackage({ 
      customer_id: userChangePackage.id, 
      member_package_id: +selectedPackage 
    })
      .then(() => {
        toastSuccess('Cập nhật gói sản phẩm thành công.');
        refetchListCustomer();
        setUserChangePackage({});
      })
      .catch((e) => {
        setUserChangePackage({});
        toastError(e);
      });
  };

  return (
    <div className="content-wrapper">
      <div className="d-flex align-items-center justify-content-between header-body mb-2">
        <p className="font-size-28 font-weight-bolder">Khách hàng</p>
        <div className="d-flex align-items-center gap-20">
          <FilterComponent
            onSearch={onSearch}
            onExport={onExport}
            filters={[
              {
                key: 'customer_code',
                label: 'ID',
                width: 130,
              },
              // {
              //   key: 'user_id',
              //   label: 'Mã Sale',
              //   Component: SearchEmployer,
              //   width: 130,
              // },
            ]}
          />
          <FlatPickrDateRange
            isOpen={false}
            dateRange={filter.date_range}
            setDateRange={filterOnChange}
            className="filter-date-range"
            labelDefault="Chọn ngày"
            name="date_range"
          />
          <InputSearch
            placeholder="Nhập ID, tên KH"
            searchKey={filter.keyword}
            name="keyword"
            setSearchKey={filterOnChange}
            width="240px"
          />
          <Button color="primary" onClick={() => navigate('/customer/add-new')}>
            <Add size={13} color="white" />
            <span className="font-size-14">Thêm khách hàng</span>
          </Button>
        </div>
      </div>
      <NavigationBar
        style={{ background: 'transparent' }}
        tabs={TAB_PANEL}
        activeTab={activeTabIdPanel}
        setActiveTab={setActiveTabIdPanel}
      />
      <Summary
        tradeType={
          activeTabIdPanel === 4
            ? TYPE_PS
            : activeTabIdPanel === 3
            ? TYPE_TPLUS
            : activeTabIdPanel === 2
            ? TYPE_CS
            : ''
        }
      />
      <NavigationBar
        tabs={TABS(countOverView)}
        activeTab={activeTabId}
        setActiveTab={setActiveTabId}
      />
      <DataTableBasic
        fixedHeader
        columns={getColumns({ navigate, setUserChangePackage, listCustomerPackages })}
        onSort={setOrderBy}
        data={data}
        hasFixed={false}
      />

      <Modal isOpen={!!userChangePackage?.id} className="text-center" style={{ width: 400 }}>
        <div className="container">
          <Title name="Cập nhật gói sản phẩm" />
          <div className="mt-2 mb-2">
            <TextInput
              type="text"
              labelCol="12"
              inputCol="12"
              labelClassName="font-size-14 text-left mb-50"
              labelName="Username: "
              setValue={() => {}}
              defaultValue={userChangePackage?.name || 'abc'}
              disabled
            />
            <SelectInput
              labelName="Gói sản phẩm *"
              labelClassName="font-size-14 text-left"
              labelCol="12"
              inputCol="12"
              setValue={(_, v) => setSelectedPackage(v)}
              inputClassName="uppercase"
              options={[
                {
                  label: 'Chọn',
                  value: '',
                },
              ].concat(listCustomerPackages)}
              name="package"
              defaultValue={userChangePackage ? userChangePackage.member_package_id : 0}
            />
          </div>
          <Button className="mr-1" outline onClick={() => setUserChangePackage({})}>
            <span className="font-size-14 ml-25"> Huỷ</span>
          </Button>
          <Button
            color="primary"
            disabled={!selectedPackage}
            onClick={() => {
              handleChangeCustomerPackage();
            }}
          >
            <AddCircleOutline color="primay" fontSize="14px" />
            <span className="font-size-14 ml-25"> Cập nhật</span>
          </Button>
        </div>
      </Modal>
    </div>
  );
}

Customer.propTypes = {};

export default Customer;
