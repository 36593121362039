import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'reactstrap';
import { formatNumber } from '@Utils/helpers';
import { getPriceMatching, getQuantityMatching } from '@Utils/tradeHelpers';
import { TRADE_STATUS } from '../constants';
import dayjs from "dayjs";

function renderTime(activeTabId, createdAt) {

  if(createdAt === null || createdAt === undefined) return '--';

  if (activeTabId === 1) {
    return dayjs.unix(createdAt).format('HH:mm');
  }
  return dayjs.unix(createdAt).format('HH:mm - DD/MM/YY');
}
function ExpandTranction({ data }) {
  const renderStatus = (status) => {
    const objStatus = TRADE_STATUS.find((item) => item.value === status);
    if (!objStatus) {
      return status;
    }
    const { color, label } = objStatus;
    return (
      <div style={{ background: color }} className="text-center border-r-4 py-25 px-50">
        <span className="text-white font-size-14 font-weight-bolder white-space-norwrap">
          {label}
        </span>
      </div>
    );
  };
  return (
    <div>
      <Table className="transaction-expand">
        <thead>
          <tr>
            <th>MÃ GD</th>
            <th>MÃ GD gốc</th>
            <th>KHO</th>
            <th>GDV</th>
            <th>KHỐI LƯỢNG</th>
            <th>KL KHỚP</th>
            <th>GIÁ KHỚP</th>
            <th>TG KHỚP</th>
            <th>TRẠNG THÁI</th>
          </tr>
        </thead>
        <tbody>
          {data.subTransactionItems.map((item) => {
            const { quantity, buy_group_id, purchaseAccount, id, status, publicTellersUser } = item;
            const price = getPriceMatching(item);
            return (
              <tr key={id}>
                <th scope="row">{id}</th>
                <th scope="row">{buy_group_id === null ? id : buy_group_id}</th>
                <th scope="row">{purchaseAccount ? purchaseAccount.name : '--'}</th>
                <td className="font-weight-bolder" style={{ textTransform: 'uppercase' }}>
                  {publicTellersUser?.username}
                </td>
                <td className="font-weight-bolder">{formatNumber(quantity)}</td>
                <td className="font-weight-bolder">
                  <span className="text-danger">{formatNumber(getQuantityMatching(item))}</span>/
                  {formatNumber(quantity)}
                </td>
                <td className="font-weight-bolder">{formatNumber(price, '--')}</td>
                <td className="font-weight-bolder">{renderTime(2, item?.lastTradingSubItemTime?.created_at)} </td>
                <td className="font-weight-bolder">{renderStatus(status)}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
}

ExpandTranction.propTypes = {
  data: PropTypes.object,
};

export default ExpandTranction;
