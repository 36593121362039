/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { transactionReport } from '@Apis/admin/report';
import { Container } from 'reactstrap';
import NavigationBar from '@Components/NavigationBar';
import { TYPE_CS, TYPE_PS, TYPE_TPLUS } from '@Components/Layouts/PrivateLayout';
import { toastError } from '@Utils/toastrHelper';
import { hideGlobalLoading, showGlobalLoading } from '@Lib/slices/uiSlice';
import { useDispatch } from 'react-redux';
import ReportTable from './ReportTable';

const TABS = [
  { id: 0, name: 'Cơ sở', left: true },
  { id: 3, name: 'T+', left: false },
  { id: 1, name: 'Phái sinh' },
];

function TransactionReport({ dateRange, setDataExcel, view_by }) {
  const [tab, setTab] = useState(0);
  const [dataBase, setDataBase] = useState();
  const [dataSpot, setDataSpot] = useState();
  const [dataPS, setDataPS] = useState();
  const dispatch = useDispatch();

  const loadDataSpot = () => {
    dispatch(showGlobalLoading());
    transactionReport({ date_range: dateRange, view_by, trade_type: TYPE_TPLUS })
      .then((res) => {
        setDataSpot(res);
        dispatch(hideGlobalLoading());
      })
      .catch((e) => {
        toastError(e);
        dispatch(hideGlobalLoading());
      });
  };

  const loadDataBase = () => {
    dispatch(showGlobalLoading());
    transactionReport({ date_range: dateRange, view_by, trade_type: TYPE_CS })
      .then((res) => {
        setDataBase(res);
        dispatch(hideGlobalLoading());
      })
      .catch((e) => {
        toastError(e);
        dispatch(hideGlobalLoading());
      });
  };

  const loadDataPS = () => {
    dispatch(showGlobalLoading());
    transactionReport({ date_range: dateRange, view_by, trade_type: TYPE_PS })
      .then((res) => {
        setDataPS(res);
        dispatch(hideGlobalLoading());
      })
      .catch((e) => {
        toastError(e);
        dispatch(hideGlobalLoading());
      });
  };

  useEffect(() => {
    console.log("tab", tab)
    tab === 0 ? loadDataBase() : tab === 1 ? loadDataPS() : loadDataSpot();
  }, [tab, dateRange, view_by]);

  useEffect(() => {
    if (!setDataExcel) return;
    if (tab === 0 && dataBase) {
      setDataExcel(dataBase, 'base');
    } else if (tab === 3 && dataSpot) {
      setDataExcel(dataSpot, 'base');
    } else if (tab === 1 && dataPS) {
      setDataExcel(dataPS, 'derivative');
    }
  }, [dataPS, dataBase, setDataExcel, tab, dataSpot]);

  return (
    <Container>
      <div style={{ paddingTop: 10 }}>
        <NavigationBar className="custom-bar" tabs={TABS} activeTab={tab} setActiveTab={setTab} />
      </div>

      {tab === 0 && <ReportTable data={dataBase} />}
      {tab === 3 && <ReportTable data={dataSpot} />}
      {tab === 1 && <ReportTable isPS data={dataPS} />}
    </Container>
  );
}

TransactionReport.propTypes = {
  dateRange: PropTypes.string,
  view_by: PropTypes.any,
  setDataExcel: PropTypes.func,
};

export default TransactionReport;
