import React, { useState, useCallback } from 'react';
import { Add } from '@mui/icons-material';
import { Button } from 'reactstrap';
import { groupBy } from 'lodash';
import FlatPickrDateRange from '@Components/FlatPickrDateRange';
import { useNavigate } from 'react-router-dom';
import { Checkbox } from '@mui/material';
import useCustomQuery from '@Utils/useCustomQuery';
import { wareHouseExport, wareHouseIndex } from '@Apis/admin/warehouse';
import DataTableBasic from '@Components/DataTableBasic';
import ModalExport from '@Components/ModalExport';
import Summary from './components/Sumary';
import { columns } from './components/Columns';
import PopUpUpdate from './components/PopUpUpdate';

function WarehouseAccount() {
  const navigate = useNavigate();
  const [filter, setFilter] = useState({
    date_range: '',
    keyword: '',
    status: '',
  });
  const [openPopUp, setOpenPopUp] = useState(false);
  const [isViewHidden, setViewHidden] = useState(false);

  const filterOnChange = (name, value) => {
    setFilter((prevValues) => {
      return {
        ...prevValues,
        [name]: value,
      };
    });
  };

  const { data, refetch } = useCustomQuery(wareHouseIndex, filter, true);

  const newData = groupBy(
    (data || []).filter((e) => (isViewHidden ? true : e.status === 'active')),
    (b) => b.purchaseAccountGroup?.name,
  );

  const onExport = useCallback(async () => {
    return wareHouseExport(filter);
  }, [filter]);

  return (
    <div className="content-wrapper">
      <div className="d-flex align-items-center justify-content-between header-body mb-1">
        <p className="font-size-28 font-weight-bolder">Tài khoản kho</p>
        <div className="d-flex align-items-center gap-20">
          <ModalExport
            colorText="#1976d2"
            classNameButton="d-flex align-items-center"
            styleButton={{ height: 43.39, marginRight: 5 }}
            onExport={onExport}
          />
          <FlatPickrDateRange
            isOpen={false}
            dateRange={filter.date_range}
            setDateRange={filterOnChange}
            className="filter-date-range"
            labelDefault="Chọn ngày"
            name="date_range"
          />
          <Button color="primary" onClick={() => setOpenPopUp(true)}>
            <Add size={13} color="white" />
            <span className="font-size-14">Thêm kho</span>
          </Button>
        </div>
      </div>
      <div className="mb-2">
        <Summary />
      </div>
      <div className="d-flex w-100 justify-content-end">
        <div>
          <Checkbox
            checked={isViewHidden}
            size="small"
            onChange={(e) => setViewHidden(e.target.checked)}
          />
          <span>Hiển thị chưa kích hoạt</span>
        </div>
      </div>
      {Object.keys(newData).map((key) => (
        <div key={key} className="stock-view-sum">
          <div style={{ padding: 10, background: '#1C70B8', color: '#fff' }} className="w-100 mt-1">
            <span style={{ fontWeight: 600 }}>{(key && key != 'undefined') ? key : 'Khác'}</span>
          </div>
          <DataTableBasic
            columns={columns({ navigate })}
            data={newData[key].concat([
              newData[key].reduce(
                (a, b) => {
                  a.stock_value += b.stock_value;
                  a.total_nav += b.total_nav;
                  a.margin_debt += b.margin_debt;
                  a.buy_ability += b.buy_ability;
                  a.dividend_amount += b.dividend_amount;
                  return a;
                },
                {
                  stock_value: 0,
                  total_nav: 0,
                  margin_debt: 0,
                  buy_ability: 0,
                  dividend_amount: 0,
                },
              ),
            ])}
          />
        </div>
      ))}
      <PopUpUpdate isOpen={openPopUp} closeModal={() => setOpenPopUp(false)} refetch={refetch} />
    </div>
  );
}

WarehouseAccount.propTypes = {};

export default WarehouseAccount;
