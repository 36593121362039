import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import PasswordInput from '@Components/Input/PasswordInput';
import { Button, Col, Container, Row } from 'reactstrap';
import ButtonSubmit from '@Components/ButtonSubmit';
import { changePassword } from '@Apis/profile/account';
import useCustomMutation from '@Utils/useCustomMuation';
import { toastError } from '@Utils/toastrHelper';
import { useNavigate } from 'react-router-dom';
import CustomeModal from '@Components/CustomeModal';

function ChangePassword() {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
    reset,
  } = useForm();
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  const { mutate, isSuccess, error, isError, isLoading } = useCustomMutation(changePassword);
  const onSubmit = ({ current_password, new_password }) => {
    mutate({ current_password, new_password });
  };

  useEffect(() => {
    if (isSuccess) {
      setIsOpen(true);
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isError) {
      toastError(error);
    }
  }, [isError]);

  const toggleModal = () => {
    localStorage.removeItem(process.env.TOKEN);
    navigate('/login');
  };
  return (
    <div className="bg-white">
      <p className="font-size-18 m-0 font-weight-bold p-2">Đổi mật khẩu</p>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Container>
          <Row>
            <Col md="6" xs="12">
              <div className="pl-1 mb-2">
                <PasswordInput
                  labelCol="12"
                  labelClassName="font-size-12"
                  inputCol="12"
                  labelName="Mật khẩu hiện tại"
                  setValue={setValue}
                  name="current_password"
                  register={{
                    ...register('current_password', {
                      required: true,
                      minLength: { value: 6, message: 'Tối thiểu 6 kí tự' },
                    }),
                  }}
                  errors={errors}
                  watch={watch}
                  isUnderline
                />
                <PasswordInput
                  labelCol="12"
                  labelClassName="font-size-12"
                  inputCol="12"
                  labelName="Mật khẩu mới"
                  setValue={setValue}
                  name="new_password"
                  register={{
                    ...register('new_password', {
                      required: true,
                      minLength: { value: 6, message: 'Tối thiểu 6 kí tự' },
                    }),
                  }}
                  errors={errors}
                  watch={watch}
                  isUnderline
                />

                <PasswordInput
                  labelCol="12"
                  labelClassName="font-size-12"
                  inputCol="12"
                  labelName="Nhập lại mật khẩu mới"
                  setValue={setValue}
                  name="re_password"
                  register={{
                    ...register('re_password', {
                      required: true,
                      validate: (value) =>
                        value === watch('new_password') || 'Mật khẩu không khớp.',
                    }),
                  }}
                  errors={errors}
                  watch={watch}
                  isUnderline
                />
              </div>
              <div className="d-line" />
              <div className="d-flex p-2">
                <ButtonSubmit
                  styleText={{ fontSize: 14, textAlign: 'center' }}
                  name="Lưu thay đổi"
                  size="md"
                  color="primary"
                  isLoading={isLoading}
                />
                <ButtonSubmit
                  onClick={() => reset()}
                  style={{ marginLeft: 16 }}
                  outline
                  styleText={{ fontSize: 14, color: '#2E2C34' }}
                  name="Huỷ"
                  size="md"
                />
              </div>
            </Col>
          </Row>
        </Container>
      </form>
      <CustomeModal
        isOpen={isOpen}
        confirm={toggleModal}
        closeModal={toggleModal}
        buttonName="Đăng nhập lại"
        type="success"
        mess="Thay đổi mật khẩu thành công"
      />
    </div>
  );
}

ChangePassword.propTypes = {};

export default ChangePassword;
