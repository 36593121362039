import axiosService from '@Lib/axiosService';
import { handleResponse } from '@Utils/helpers';

let url = '';
if (process.env.DASHBOARD_TYPE === 'admin') {
  url = `${process.env.API_ADMIN_URL}/customer`;
}

export const customers = async (query) => {
  const res = await axiosService.get(`${url}/list`, query);
  return handleResponse(res);
};

export const customerOverviewAccount = async (query) => {
  const res = await axiosService.get(`${url}/account-overview`, query);
  return handleResponse(res);
};

export const exportList = async (query) => {
  const res = await axiosService.get(`${url}/export`, query);
  return handleResponse(res);
};

export const customersSuggest = async (query) => {
  const res = await axiosService.get(`${url}/list-search`, query);
  return handleResponse(res);
};

export const customerDetail = async (query) => {
  const res = await axiosService.get(`${url}/load`, query);
  return handleResponse(res);
};

export const customerSave = async (query) => {
  const res = await axiosService.post(`${url}/save`, query);
  return handleResponse(res);
};

export const customerInsert = async (query) => {
  const res = await axiosService.post(`${url}/insert`, query);
  return handleResponse(res);
};

export const customerSaveFees = async (query) => {
  const res = await axiosService.post(`${url}/save-fees`, query);
  return handleResponse(res);
};

export const activities = async (query) => {
  const res = await axiosService.get(`${url}/activities`, query);
  return handleResponse(res);
};

export const customerSummary = async (query) => {
  const res = await axiosService.post(`${url}/summary`, query);
  return handleResponse(res);
};

export const customerLock = async (query) => {
  const res = await axiosService.post(`${url}/lock`, query);
  return handleResponse(res);
};

export const customerBanks = async (query) => {
  const res = await axiosService.get(`${url}/list-bank-accounts`, query);
  return handleResponse(res);
};

export const customerOverview = async (query) => {
  const res = await axiosService.get(`${url}/overview`, query);
  return handleResponse(res);
};

export const selectCustomer = async (query) => {
  const res = await axiosService.get(`${url}/list-for-select`, query);
  return handleResponse(res);
};

export const overview = async (query) => {
  const res = await axiosService.get(`${url}/counts`, query);
  return handleResponse(res);
};

export const customerTransactions = async (query) => {
  const res = await axiosService.get(`${url}/transactions`, query);
  return handleResponse(res);
};

export const customerExport = async (query) => {
  const res = await axiosService.get(`${url}/transactions-export`, query);
  return handleResponse(res);
};

export const customerAssets = async (query) => {
  const res = await axiosService.get(`${url}/assets`, query);
  return handleResponse(res);
};

export const setMemberPackage = async (query) => {
  const res = await axiosService.post(`${url}/set-member-package`, query);
  return handleResponse(res);
};
