/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { getExport, myList } from '@Apis/admin/export';
import DataTableBasic from '@Components/DataTableBasic';
import DatePicker from '@Components/DatePicker';
import { toastError } from '@Utils/toastrHelper';
import dayjs from 'dayjs';
import React, { useCallback, useEffect, useState, useRef } from 'react';

const type = {
  'customer/list': 'Khách hàng',
  'trade/surveyor-transactions': 'Giao dịch KSV',
  'trade/teller-transactions': 'Giao dịch GDV',
  'stock/list': 'Mã CK',
  'payment-request/list': 'Nộp rút',
  'paybook/loan-list': 'Kho cho vay',
  'paybook/debt-list': 'Kho huy động',
  'purchase-account/list': 'Tài khoản kho',
};

const status = {
  created: {
    text: 'Đang chờ xử lý',
    color: '#EC602E',
  },
  in_process: {
    text: 'Đang xử lý',
    color: '#EC602E',
  },
  completed: { text: 'Hoàn thành', color: '#14B13B' },
  cancelled: { text: 'Lỗi', color: '#828282' },
};

const getExportClick = (id) => {
  getExport(id)
    .then(({ url }) => {
      window.open(`${process.env.API_ADMIN_URL}/${url}`, '__blank');
    })
    .catch(toastError);
};

const columns = [
  {
    name: 'Thời gian',
    cell: (row) => dayjs.unix(row.created_at).format('YYYY-MM-DD HH:mm'),
    center: true,
  },
  {
    name: 'Loại',
    cell: (row) => type[row.export_type],
    center: true,
  },
  {
    name: 'Tên file',
    cell: (row) => (
      <a style={{ color: 'rgb(47, 105, 179)' }} onClick={() => getExportClick(row.id)}>
        {row.file_url?.split('/').pop()}
      </a>
    ),
  },
  {
    name: 'Người tạo',
    cell: (row) => row.publicUser?.username?.toUpperCase(),
    width: '120px',
  },
  {
    name: 'Mô tả',
    cell: (row) => row.description,
  },
  {
    name: 'Trạng thái',
    center: true,
    cell: (row) => (
      <span style={{ color: status[row.status || 'created'].color }}>
        {status[row.status || 'created'].text}
      </span>
    ),
  },
];

function ExportManagement() {
  const [filter, setFilter] = useState({ per_page: 1000000 });
  const [data, setData] = useState([]);

  const interval = useRef();

  // const { data } = useCustomQuery(myList, filter);

  useEffect(() => {
    myList(filter).then(setData);
    interval.current = setInterval(() => {
      myList(filter).then(setData);
    }, 10000);
    return () => {
      clearInterval(interval.current);
    };
  }, []);

  useEffect(() => {
    myList(filter).then(setData);
    clearInterval(interval.current);
    interval.current = setInterval(() => {
      myList(filter).then(setData);
    }, 10000);
  }, [filter]);

  const onChangeDate = useCallback((v) => {
    setFilter((pre) => ({ ...pre, date_range: v.map((d) => dayjs(d).unix()).join('-') }));
  }, []);

  return (
    <div className="export-management">
      <div className="title">
        <div>Quản lý xuất file</div>
        <div className="d-flex">
          <DatePicker.Range
            onChange={onChangeDate}
            style={{ width: 240 }}
            placeholder="Chọn ngày"
          />
        </div>
      </div>
      <div style={{ marginTop: 31 }}>
        <DataTableBasic data={data} columns={columns} />
      </div>
    </div>
  );
}

export default ExportManagement;
