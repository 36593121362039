import React from 'react';
import PropTypes from 'prop-types';
import { TitleWrapper } from './styles';

function Title({ name, style }) {
  return <TitleWrapper style={style}>{name}</TitleWrapper>;
}

Title.propTypes = {
  name: PropTypes.string,
  style: PropTypes.object,
};

export default Title;
