/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable indent */
import ButtonSubmit from '@Components/ButtonSubmit';
import FieldInput from '@Views/Client/Recharge/BodyTab/Request/FieldInput';
import React, { useState, useCallback } from 'react';
import { holdByCustomer } from '@Apis/admin/stock';
import { Col, Modal, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import useCustomQuery from '@Utils/useCustomQuery';
import { useDispatch } from 'react-redux';
import { createTrade } from '@Apis/admin/trade';
import { toastError, toastSuccess } from '@Utils/toastrHelper';
import { hideGlobalLoading, showGlobalLoading } from '@Lib/slices/uiSlice';
import './styles.scss';
import DropdownSelect from '@Components/DropdownSelect';
import { formatNumber } from '@Utils/helpers';
import SearchCustomer from '@Components/Input/SearchCustomer';

function ModalCK({ onClose }) {
  const [valueForm, setValueForm] = useState({});
  const [errors, setErrors] = useState({});
  const [tradeType, setTradeType] = useState();
  const dispatch = useDispatch();

  const { data: optionCK } = useCustomQuery(holdByCustomer, {
    customer_id: valueForm?.customer_id,
    per_page: 100000,
  });

  const onChange = useCallback(
    (name, value, tradeType) => {
      setTradeType(tradeType);
      let valueNew = { [name]: value };
      if (name === 'stock_id') {
        const optionCKSelect = optionCK?.find((e) => e.stock_id === value);
        valueNew = { ...valueNew, price: optionCKSelect?.stock?.price || 0 };
      }
      setErrors((pre) => ({ ...pre, [name]: value || value === 0 ? '' : 'Vui lòng nhập dữ liệu' }));
      setValueForm((pre) => ({ ...pre, ...valueNew }));
    },
    [optionCK],
  );

  const handleCreate = useCallback(async () => {
    const errs = {};
    if (!valueForm?.customer_id) {
      errs.customer_id = 'Vui lòng nhập dữ liệu';
    }
    if (!valueForm?.stock_id) {
      errs.stock_id = 'Vui lòng nhập dữ liệu';
    }
    if (!valueForm?.target_customer_id) {
      errs.target_customer_id = 'Vui lòng nhập dữ liệu';
    }
    if (!valueForm?.type) {
      errs.type = 'Vui lòng nhập dữ liệu';
    }
    if (!valueForm?.quantity) {
      errs.quantity = 'Khối lượng phải lớn hơn 0';
    }
    if (Object.keys(errs).length) {
      setErrors(errs);
      return;
    }
    dispatch(showGlobalLoading());
    try {
      await createTrade(valueForm);
      toastSuccess('Thêm giao dịch thành công');
      onClose?.();
    } catch (e) {
      toastError(e);
    }
    dispatch(hideGlobalLoading());
  }, [valueForm]);

  const optionCKSelect = optionCK?.find((e) => e.stock_id === valueForm.stock_id);

  return (
    <Modal isOpen modalClassName="modal-ck">
      <div className="title-modal-ck mb-2">Chuyển chứng khoán</div>
      <div>
        <Row className="mt-1">
          <Col md="6">
            <FieldInput
              errorMessage={errors?.customer_id}
              className="input-field-date"
              component={
                <SearchCustomer
                  style={{ marginBottom: 0 }}
                  defaultValue={valueForm?.customer_id || ''}
                  labelCol="12"
                  inputCol="12"
                  labelClassName="font-size-14 mb-50"
                  name="customer_id"
                  setValue={onChange}
                />
              }
              label="Chọn khách hàng chuyển"
              required
            />
          </Col>
          <Col md="6">
            <FieldInput
              errorMessage={errors?.stock_id}
              className="input-field-date"
              component={
                <DropdownSelect
                  labelKey="stock_id"
                  labelValue="stock_id"
                  styleWrap={{ width: '100%' }}
                  allowSearch
                  name="stock_id"
                  placeholder="Chọn mã CK cần chuyển"
                  valueSelected={valueForm?.stock_id || ''}
                  setValueSelected={onChange}
                  styleMenu={{ maxHeight: 200, overflow: 'auto', width: '100%' }}
                  options={valueForm?.customer_id ? optionCK : []}
                  labelShow={false}
                />
              }
              label="Chọn mã CK cần chuyển"
              required
            />
          </Col>
        </Row>

        <FieldInput
          errorMessage={errors?.target_customer_id}
          className="input-field-date"
          component={
            <SearchCustomer
              emptyActive={!valueForm?.customer_id}
              tradeType={tradeType}
              style={{ marginBottom: 0 }}
              defaultValue={valueForm?.target_customer_id || ''}
              labelCol="12"
              inputCol="12"
              labelClassName="font-size-14 mb-50"
              name="target_customer_id"
              setValue={onChange}
            />
          }
          label="Chọn khách hàng nhận"
          required
        />
        <FieldInput
          className="input-field-date"
          errorMessage={errors.type}
          component={
            <DropdownSelect
              id="dropdownSelectTooltip"
              styleWrap={{ width: '100%' }}
              allowSearch
              name="type"
              placeholder="Loại"
              valueSelected={valueForm?.type || ''}
              setValueSelected={onChange}
              styleMenu={{ maxHeight: 200, overflow: 'auto', width: '100%' }}
              options={[
                { label: `T+ (${formatNumber(optionCKSelect?.t0)})`, value: 't0' },
                { label: `T1 (${formatNumber(optionCKSelect?.t1)})`, value: 't1' },
                { label: `T2 (${formatNumber(optionCKSelect?.t2)})`, value: 't2' },
                {
                  label: `Khả dụng (${formatNumber(optionCKSelect?.t3)})`,
                  value: 't3',
                },
              ]}
              labelShow={false}
            />
          }
          label="Loại"
          required
        />
        <Row className="mt-1">
          <Col md="6">
            <FieldInput
              value={valueForm?.quantity || ''}
              errorMessage={errors?.quantity}
              type="number"
              onChange={(v) => onChange('quantity', v)}
              label="Khối lượng chuyển"
              required
              max={optionCKSelect?.[valueForm.type]}
              placeholder="Nhập khối lượng chuyển"
            />
          </Col>
          <Col md="6">
            <FieldInput
              value={valueForm?.price || ''}
              errorMessage={errors?.price}
              type="number"
              onChange={(v) => onChange('price', v)}
              label="Giá"
              required
              placeholder="Nhập giá"
            />
          </Col>
        </Row>
        <Row className="mt-1">
          <Col md="6">
            <FieldInput
              value={valueForm?.buy_fee_percent || ''}
              onChange={(v) => onChange('buy_fee_percent', v)}
              type="number"
              label="% phí mua"
              errorMessage={errors?.buy_fee_percent}
              required
              placeholder="Nhập % phí mua"
            />
          </Col>
          <Col md="6">
            <FieldInput
              errorMessage={errors?.sell_fee_percent}
              value={valueForm?.sell_fee_percent || ''}
              onChange={(v) => onChange('sell_fee_percent', v)}
              type="number"
              label="% phí bán"
              required
              placeholder="Nhập % phí bán"
            />
          </Col>
        </Row>
        <div className="d-flex justify-content-center align-items-center mt-2">
          <ButtonSubmit
            onClick={handleCreate}
            name="Xác nhận"
            className="px-2"
            style={{ width: 170, height: 54, marginRight: 5 }}
            size="md"
            color="primary"
          />
          <ButtonSubmit
            onClick={onClose}
            name="Hủy"
            style={{ width: 170, height: 54, marginLeft: 5 }}
            className="px-2"
            size="md"
          />
        </div>
      </div>
    </Modal>
  );
}

ModalCK.propTypes = {
  onClose: PropTypes.any,
};

export default ModalCK;
