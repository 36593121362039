import styled from 'styled-components';

export const SummaryWrapper = styled.div`
  .with-border {
    .col {
      border-right: 1px solid #ebeaed;
    }
    .col:nth-last-child() {
      border-right: none;
    }
  }
`;
