import { Radio } from '@mui/material';
import { bankFormat } from '@Utils/helpers';
import PropTypes from 'prop-types';
import React, { memo } from 'react';

function CardContent(props) {
  const { style, data, customer, active, onClick } = props;
  return (
    <div
      className={`card-content${active ? ' card-content-active' : ''}`}
      style={style}
      onClick={onClick}
      role="button"
      tabIndex="0"
    >
      <div className="d-flex justify-content-between align-items-center">
        <div className="d-flex">
          <div>
            <div className="span-title">
              <span>Chủ tài khoản:</span>
            </div>
            <div className="span-title">
              <span>Số tài khoản:</span>
            </div>
            <div className="span-title">
              <span>Ngân hàng:</span>
            </div>
            <div className="span-title">
              <span>Nội dung:</span>
            </div>
          </div>
          <div>
            <div className="span-data">
              <span>{data?.account_name || '--'}</span>
            </div>
            <div className="span-data">
              <span>{data?.account_number}</span>
            </div>
            <div className="span-data">
              <span>{data?.bank?.name || '--'}</span>
            </div>
            <div className="span-data">
              <span>{customer.username} nop tien {customer.trade_type == 'base' ? 'CS' : customer.trade_type == 'spot' ? 'T+' : customer.trade_type == 'derivative' ? 'PS' : ''}</span>
            </div>
          </div>
        </div>
        <Radio checked={active} />
      </div>
    </div>
  );
}

CardContent.propTypes = {
  style: PropTypes.object,
  data: PropTypes.object,
  customer: PropTypes.object,
  active: PropTypes.bool,
  onClick: PropTypes.func,
};

export default memo(
  CardContent,
  (pre, next) =>
    pre.active === next.active && pre.data === next.data && pre.onClick === next.onClick,
);
