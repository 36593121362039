/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import './styles.scss';

class Tooltip extends Component {
  constructor(props) {
    super(props);
    const { overlayClassName = '' } = props;
    this.div = document.createElement('div');
    this.div.className = `tooltip-swap-body ${overlayClassName}`;
    this.isAppend = false;
    this.state = { isOpen: false, objectPosition: {} };
  }

  componentWillUnmount() {
    if (this.isAppend) {
      document.querySelector('body').removeAttribute('onclick');
      document.querySelector('body').removeAttribute('style');
      document.querySelector('body').removeChild(this.div);
    }
  }

  handleToggle = (e) => {
    const { isOpen } = this.state;
    const { skipBlur, skipScroll, trigger, stopBlur } = this.props;
    if (isOpen && this.refClick && this.refClick.contains(e.target) && trigger !== 'hover') {
      return;
    }
    const offset = this.tooltip.getBoundingClientRect();
    const { x, y, height, width } = offset;
    if (!this.isAppend) {
      document.querySelector('body').append(this.div);
    }
    if (isOpen) {
      if (!stopBlur) {
        this.setState({ isOpen: false, objectPosition: {} });
        document.querySelector('body').removeAttribute('style');
        document.querySelector('body').removeAttribute('onclick');
      }
    } else {
      if (skipBlur) {
        document.querySelector('body').onclick = this.handleClickBlurBody;
      }
      document.querySelector('body').style.position = 'relative';
      if (skipScroll) document.querySelector('body').style.overflow = 'hidden';
      this.setState({ isOpen: true, objectPosition: { x, y, height, width } });
    }
  };

  handleClickBlurBody = (e) => {
    const { stopBlur } = this.props;
    if (
      (this.refClick && this.refClick.contains(e.target)) ||
      (this.tooltip && this.tooltip.contains(e.target)) ||
      stopBlur
    ) {
      return;
    }
    this.handleBlur();
  };

  handleBlur = () => {
    this.setState({ isOpen: false, objectPosition: {} });
    document.querySelector('body').removeAttribute('style');
  };

  render() {
    const { children, content, className, skipBlur, style, trigger, align = 'right' } = this.props;
    const { isOpen, objectPosition } = this.state;
    return (
      <div
        style={{ userSelect: 'none', outline: 'none', boxShadow: 'none', border: 'none', ...style }}
        ref={(ref) => {
          this.tooltip = ref;
        }}
        role="button"
        tabIndex="0"
        onClick={(e) => {
          if (trigger !== 'hover') {
            this.handleToggle(e);
          }
        }}
        onMouseOver={(e) => {
          if (trigger === 'hover') {
            this.handleToggle(e);
          }
        }}
        onMouseLeave={(e) => {
          if (trigger === 'hover') {
            this.handleToggle(e);
          }
        }}
        onBlur={!skipBlur ? this.handleBlur : null}
      >
        {children}
        {isOpen &&
          ReactDOM.createPortal(
            <div
              className={className}
              ref={(ref) => {
                this.refClick = ref;
              }}
            >
              <div
                className="tooltip-swap-absolute"
                style={{
                  [align]:
                    -(align === 'left' ? -objectPosition.x - 10 : objectPosition.x) -
                    (align === 'left' ? 0 : objectPosition.width),
                  bottom: -objectPosition.y - window.scrollY,
                }}
              >
                <div className="swap-tooltip">
                  <div className="tooltip-arrow" style={{ [align]: 8 }}>
                    <div className="tooltip-arrow-icon" />
                  </div>
                  <div className="tooltip-body">{content}</div>
                </div>
              </div>
            </div>,
            this.div,
          )}
      </div>
    );
  }
}

Tooltip.propTypes = {
  children: PropTypes.any,
  content: PropTypes.any,
  style: PropTypes.object,
  trigger: PropTypes.string,
  align: PropTypes.string,
  overlayClassName: PropTypes.string,
  className: PropTypes.string,
  skipBlur: PropTypes.bool,
  stopBlur: PropTypes.bool,
  skipScroll: PropTypes.bool,
};

export default Tooltip;
