import React, { memo, useCallback, useEffect, useState } from 'react';
import DataTableBasic from '@Components/DataTableBasic';
import { useSearchParams, useNavigate } from 'react-router-dom';
import NavigationBar from '@Components/NavigationBar';
import { cancel, list } from '@Apis/client/payment-request';
import PropTypes from 'prop-types';
import Title from '@Components/Title';
import { useDispatch, useSelector } from 'react-redux';
import { showGlobalLoading, hideGlobalLoading } from '@Lib/slices/uiSlice';
import CustomeModal from '@Components/CustomeModal';
import DatePicker from '@Components/DatePicker';
import TransactionTab3 from '@Views/Admin/CustomerDetail/component/Assets/TransactionTab3';
import useCustomQuery from '@Utils/useCustomQuery';
import { accountsHome } from '@Apis/client/home';
import dayjs from 'dayjs';
import { io } from 'socket.io-client';
import { columns } from './columns';

function History(props) {
  const { refFunction } = props;
  const [activeTabId, setActiveTab] = useState('');
  const [valueRange, setValueRange] = useState([]);
  const [{ isOpenModal, typeOpen, rowId }, setModal] = useState({});
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const page = searchParams.get('page') || 1;
  const [data, setData] = useState([]);
  const dispatch = useDispatch();
  const productType = useSelector((state) => state.ui.productType);
  const { data: dataAccounts } = useCustomQuery(accountsHome);

  const account = dataAccounts?.accounts?.find((e) => e.trade_type === productType);

  useEffect(() => {
    if (activeTabId !== 'transactions' && account?.id) {
      callList();
    }
  }, [page, activeTabId, valueRange, account?.id]);

  const callList = useCallback(async () => {
    dispatch(showGlobalLoading());
    const res = await list({
      per_page: 20,
      page,
      type: activeTabId,
      date_range: valueRange?.toString().replace(',', '-'),
      customer_id: account?.id,
    });
    setData(res);
    dispatch(hideGlobalLoading());
  }, [page, activeTabId, valueRange, account?.id]);

  useEffect(() => {
    if (activeTabId === 'transactions' || !account?.id) return;
    const token = localStorage.getItem(process.env.TOKEN);
    const socket = io(`${process.env.BACKEND_SOCKET}/customer/payment-request/list`, {
      query: { token },
      transports: ['websocket'],
    });
    socket.on('connect', () => {
      socket.emit('register', 'your-client-id', {
        per_page: 20,
        page,
        type: activeTabId,
        date_range: valueRange?.toString().replace(',', '-'),
        customer_id: account?.id,
      });
    });
    socket.on('data', ({ data: dataSocket }) => {
      setData(dataSocket);
    });

    return () => {
      socket.disconnect();
    };
  }, [page, activeTabId, valueRange, account?.id]);

  useEffect(() => {
    refFunction?.(() => {
      setValueRange([]);
    });
  }, [refFunction, page, activeTabId, account?.id]);

  const onOpenModal = useCallback((v) => {
    setModal(v);
  }, []);

  const handleChangePicker = useCallback((v) => {
    // navigate(`/${productType}/recharge?page=1`);
    setValueRange(v.map((d) => dayjs(d).unix()).join('-'));
  }, []);

  const handleCancel = useCallback(() => {
    setModal({});
    dispatch(showGlobalLoading());
    cancel({ id: rowId }).finally(() => {
      dispatch(hideGlobalLoading());
    });
  }, [rowId, page, activeTabId]);

  if (data === undefined) {
    return null;
  }

  return (
    <div style={{ paddingTop: 25, paddingBottom: 25 }}>
      <div className="title-history">
        <Title style={{ paddingBottom: 10 }} name="Lịch sử nộp/rút" />
        <DatePicker.Range
          value={valueRange}
          onChange={handleChangePicker}
          placeholder="Chọn ngày"
        />
      </div>
      <NavigationBar
        setActiveTab={(tab) => {
          // navigate(`/${productType}/recharge?page=1`);
          setActiveTab(tab);
        }}
        activeTab={activeTabId}
        tabs={[
          { id: '', name: 'Tất cả' },
          { id: 'deposit', name: 'Nộp tiền' },
          { id: 'withdraw', name: 'Rút tiền' },
          { id: 'transactions', name: 'Giao dịch tiền' },
        ]}
      />
      {activeTabId !== 'transactions' ? (
        <DataTableBasic
          pageSize={5}
          page={Number(page)}
          data={data}
          columns={columns(page, 10, onOpenModal)}
        />
      ) : (
        <TransactionTab3 isClient filter={{ date_range: valueRange }} />
      )}
      <CustomeModal
        className="custom-modal-history"
        confirm={handleCancel}
        closeModal={() => onOpenModal({})}
        isOpen={isOpenModal}
        type={typeOpen}
        mess="Bạn chắc chắn thực hiện xóa dữ liệu này?"
        buttonName="Xác nhận"
      />
    </div>
  );
}

History.propTypes = {
  refFunction: PropTypes.func,
};

export default memo(History, (pre, next) => pre.refFunction === next.refFunction);
