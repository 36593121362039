import { TYPE_PS, TYPE_TPLUS, TYPE_CS } from '@Components/Layouts/PrivateLayout';
import { formatNumber } from '@Utils/helpers';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TooltipComment from '@Components/TooltipComment';

class Info extends Component {
  constructor(props) {
    super(props);
    this.state = { responseData: {} };
  }

  render() {
    const { responseData } = this.state;
    const { productType } = this.props;
    if (productType === TYPE_PS) {
      return (
        <div className="info" style={{ fontSize: 14 }}>
          <div className="card-info-body">
            <div className="line-card-info">
              <div style={{ color: '#84818A' }}>Phí GD ON</div>
              <div style={{ color: '#2E2C34' }}>
                {formatNumber(
                  responseData?.on_fee < 0 ? responseData?.on_fee * -1 : responseData?.on_fee,
                  '--',
                )}
              </div>
            </div>
            <div className="line-card-info">
              <div style={{ color: '#84818A' }}>Phí GD OFF</div>
              <div style={{ color: '#2E2C34' }}>{formatNumber(responseData?.off_fee, '--')}</div>
            </div>
            <div className="line-card-info">
              <div style={{ color: '#84818A' }}>Số HĐ còn lại</div>
              <div style={{ color: '#2E2C34' }}>
                {formatNumber(responseData?.current_position, '--')}
              </div>
            </div>
          </div>
          <div className="card-info-body">
            <div className="line-card-info">
              <div style={{ color: '#84818A' }}>Tổng HĐ (Lũy kế)</div>
              <div style={{ color: '#2E2C34' }}>
                {formatNumber(responseData?.month_transaction_value, '--')}
              </div>
            </div>
            <div className="line-card-info">
              <div style={{ color: '#84818A' }}>Tổng HD mua</div>
              <div style={{ color: '#2E2C34' }}>
                {formatNumber(responseData?.month_buy_transaction_value, '--')}
              </div>
            </div>
            <div className="line-card-info">
              <div style={{ color: '#84818A' }}>Tổng HD bán</div>
              <div style={{ color: '##ED1C24' }}>
                {formatNumber(responseData?.month_sell_transaction_value, '--')}
              </div>
            </div>
          </div>
          <div className="card-info-body">
            <div className="line-card-info">
              <div style={{ color: '#84818A' }}>Tổng số HĐ LONG</div>
              <div style={{ color: '#2E2C34' }}>{formatNumber(responseData?.sum_long, '--')}</div>
            </div>
            <div className="line-card-info">
              <div style={{ color: '#84818A' }}>Tổng số HĐ SHORT</div>
              <div style={{ color: '#2E2C34' }}>{formatNumber(responseData?.sum_short, '--')}</div>
            </div>
            <div className="line-card-info">
              <div style={{ color: '#84818A' }}>Tổng số HĐ</div>
              <div style={{ color: '##ED1C24' }}>
                {formatNumber(responseData?.sum_long + responseData?.sum_short, '--')}
              </div>
            </div>
          </div>
          <div className="card-info-body">
            <div className="line-card-info">
              <div style={{ color: '#84818A' }}>Tổng số lượng GD</div>
              <div style={{ color: '#2E2C34' }}>
                {formatNumber(responseData?.transaction_count, '--')}
              </div>
            </div>
            <div className="line-card-info">
              <div style={{ color: '#84818A' }}>Lãi/lỗ</div>
              <div style={{ color: responseData?.current_pnl >= 0 ? '#14B13B' : '#ED1C24' }}>
                {formatNumber(responseData?.current_pnl, '--')}
              </div>
            </div>
            <div className="line-card-info">
              <div style={{ color: '#84818A' }}>Tỷ lệ ký quỹ hiện tại</div>
              <div style={{ color: '##ED1C24' }}>
                {`${formatNumber(responseData?.current_reality_margin_percent, '--')}%`}
              </div>
            </div>
          </div>
        </div>
      );
    }
    return (
      <div className="info" style={{ fontSize: 14 }}>
        <div className="card-info-body">
          <div className="line-card-info">
            <div style={{ color: '#84818A' }}>
              <span>Phí hợp tác (lũy kế)</span>
              <span
                style={{
                  display: 'inline-block',
                  cursor: 'pointer',
                  marginLeft: 10,
                }}
              >
                <TooltipComment
                  placement="right"
                  item={{
                    label: (
                      <img
                        style={{ marginLeft: 5, marginBottom: 5, maxWidth: 15 }}
                        src="/images/icon/infor.jpeg"
                        alt=""
                      />
                    ),
                    note: (
                      <span>
                        <ul
                          style={{
                            paddingLeft: 10,
                            marginBottom: 5,
                            textAlign: 'left',
                            fontSize: 12,
                          }}
                        >
                          <li>
                            Phí hợp tác tạm tính:{' '}
                            {formatNumber(responseData?.estimate_interest_fee, '--')}
                          </li>
                          <li>
                            Phí hợp tác dư cọc:{' '}
                            {formatNumber(responseData?.estimate_non_term_interest_fee, '--')}
                          </li>
                          <li>
                            Phí hợp tác đã thực hiện:{' '}
                            {`${formatNumber(
                              responseData?.interest_fee - responseData?.estimate_interest_fee,
                              '--',
                            )}`}
                          </li>
                        </ul>
                      </span>
                    ),
                    id: 'client-asset-lai-vay-luy-ke',
                  }}
                />
              </span>
            </div>
            <div style={{ color: '#2E2C34' }}>{formatNumber(responseData?.interest_fee, '--')}</div>
          </div>
          <div className="line-card-info">
            <div style={{ color: '#84818A' }}>
              {productType == 'spot' ? 'Phí giao dịch đã thực hiện' : 'Phí giao dịch (Mua/Bán)'}
            </div>
            <div style={{ color: '#2E2C34' }}>{formatNumber(responseData?.buy_sell_fee, '--')}</div>
          </div>
          <div className="line-card-info">
            <div style={{ color: '#84818A' }}>
              {productType == 'spot' ? 'Phí trả vị thế tạm thu' : 'Phí giao dịch - bán (tạm tính)'}
            </div>
            <div style={{ color: '#2E2C34' }}>
              {formatNumber(responseData?.estimated_sell_fee, '--')}
            </div>
          </div>
        </div>
        <div className="card-info-body">
          <div className="line-card-info">
            <div style={{ color: '#84818A' }}>Tổng GTGD tháng (Lũy kế)</div>
            <div style={{ color: '#2E2C34' }}>
              {formatNumber(responseData?.month_transaction_value, '--')}
            </div>
          </div>
          <div className="line-card-info">
            <div style={{ color: '#84818A' }}>Tổng GTGD mua</div>
            <div style={{ color: '#2E2C34' }}>
              {formatNumber(responseData?.month_buy_transaction_value, '--')}
            </div>
          </div>
          <div className="line-card-info">
            <div style={{ color: '#84818A' }}>Tổng GTGD bán</div>
            <div style={{ color: '##ED1C24' }}>
              {formatNumber(responseData?.month_sell_transaction_value, '--')}
            </div>
          </div>
        </div>
        <div className="card-info-body">
          <div className="line-card-info">
            <div style={{ color: '#84818A' }}>Lãi/lỗ lũy kế</div>
            <div style={{ color: responseData?.current_pnl >= 0 ? '#14B13B' : '#ED1C24' }}>
              {formatNumber(responseData?.current_pnl, '--')}
            </div>
          </div>

          {productType == TYPE_CS && (
            <div className="line-card-info">
              <div style={{ color: '#84818A' }}>Tỷ lệ đảm bảo tài khoản</div>
              <div style={{ color: '#2E2C34' }}>
                {formatNumber(responseData?.current_safety_rate, '--')}%
              </div>
            </div>
          )}

          {productType == TYPE_TPLUS && (
            <div className="line-card-info">
              <div style={{ color: '#84818A' }}>Lãi/lỗ thực</div>
              <div style={{ color: '##ED1C24' }}>
                {`${formatNumber(
                  responseData?.current_pnl -
                    responseData?.interest_fee -
                    responseData?.buy_sell_fee -
                    responseData?.estimated_sell_fee,
                  '--',
                )}`}
              </div>
            </div>
          )}
          <div className="line-card-info">
            <div style={{ color: '#84818A' }}>Tỷ lệ tài khoản hiện tại</div>
            <div style={{ color: '##ED1C24' }}>
              {`${formatNumber(responseData?.current_reality_margin_percent, '--')}%`}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Info.propTypes = {
  productType: PropTypes.string,
};

export default Info;
