import axiosService from '@Lib/axiosService';
import { handleResponse } from '@Utils/helpers';

let host;
if (process.env.DASHBOARD_TYPE === 'admin') {
  host = `${process.env.API_ADMIN_URL}`;
} else {
  host = `${process.env.API_CUSTOMER_URL}/bank-account`;
}

export const list = async () => {
  const res = await axiosService.get(`${host}/list`);
  return handleResponse(res);
};

export const listDeposit = async () => {
  const res = await axiosService.get(`${host}/deposit-accounts`);
  return handleResponse(res);
};

export const bankList = async () => {
  const res = await axiosService.get(`${host}/bank-list`);
  return handleResponse(res);
};

export const modifyBank = async (body) => {
  const res = await axiosService.post(`${host}/save`, body);
  return handleResponse(res);
};

export const deleteBank = async (body) => {
  const res = await axiosService.post(`${host}/delete`, body);
  return handleResponse(res);
};
