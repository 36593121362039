import React, { useCallback, useState } from 'react';
import { Button, Label, Modal } from 'reactstrap';
import PropTypes from 'prop-types';
import FieldInput from '@Views/Client/Recharge/BodyTab/Request/FieldInput';
import { useDispatch } from 'react-redux';
import { hideGlobalLoading, showGlobalLoading } from '@Lib/slices/uiSlice';
import { memberPackageSave } from '@Apis/admin/memberPackage';
import { toastError, toastSuccess } from '@Utils/toastrHelper';
import {Checkbox, Radio} from '@mui/material';

function ModalAddPS({ isOpen, onClose, init }) {
  const [data, setData] = useState({ trade_type: 'derivative', ...init });
  const dispatch = useDispatch();

  const onChangeValue = useCallback((key, v) => {
    setData((pre) => ({ ...pre, [key]: v }));
  }, []);

  const onSubmit = useCallback(async () => {
    dispatch(showGlobalLoading());
    try {
      await memberPackageSave(data);
      if (data.id) {
        toastSuccess('Sửa gói sản phẩm thành công');
      } else {
        toastSuccess('Thêm gói sản phẩm thành công');
      }
    } catch (e) {
      toastError(e);
    }
    onClose();
    dispatch(hideGlobalLoading());
  }, [data, onClose]);

  return (
    <Modal wrapClassName="modal-update-tplus" isOpen={isOpen} centered>
      <div className="fw-600 text-align-center" style={{ fontSize: 32 }}>
        {data.id ? 'Sửa thông tin gói ' : 'Thêm mới gói '}
        <span style={{ color: '#D91E04' }}>Phái sinh</span>
      </div>
      <div className="mt-1 d-flex" style={{ gap: 16 }}>
        <FieldInput
          onChange={(v) => onChangeValue('name', v)}
          value={data.name}
          label="Nhập tên gói"
          required
        />
        <FieldInput
          onChange={(v) => onChangeValue('number_of_cycles', v)}
          value={data.number_of_cycles}
          style={{ width: 200 }}
          label="Thời gian (Tháng)"
          type="number"
          required
        />
      </div>
      <div>
        <div style={{ color: '#84818A' }} className="uppercase fw-600">
          Điều kiện gói
        </div>
        <div className="mt-1 d-flex" style={{ gap: 16 }}>
          <FieldInput
            onChange={(v) => onChangeValue('min_transaction_value', v)}
            value={data.min_transaction_value}
            label="Tổng SL Hợp đồng"
            required
            type="number"
          />
          <FieldInput
            onChange={(v) => onChangeValue('average_margin', v)}
            value={data.average_margin}
            label="Số tiền ký quỹ trung bình"
            type="number"
            required
          />
        </div>
        <div>
          <Label className="label-field-input" for="checkbox-is_non_term_interest">
            Trả lãi không kỳ hạn
          </Label>
          <Checkbox
              id="checkbox-is_non_term_interest"
              onChange={(e) => onChangeValue('is_non_term_interest', e.target.checked)}
              checked={!!data.is_non_term_interest}
          />
          <div hidden={!data.is_non_term_interest}>
            <div className="mt-1 d-flex align-items-center" style={{ marginLeft: -10 }}>
              <div className="d-flex align-items-center">
                <Radio checked={data.interest_by === 'debt'} onClick={() => onChangeValue('interest_by', 'debt')} />
                <span>Dư nợ</span>
              </div>
              <div className="d-flex align-items-center ml-1">
                <Radio checked={data.interest_by === 'nav'} onClick={() => onChangeValue('interest_by', 'nav')} />
                <span>NAV</span>
              </div>
              <div className="d-flex align-items-center ml-1">
                <Radio checked={data.interest_by === 'withdraw'} onClick={() => onChangeValue('interest_by', 'withdraw')} />
                <span>Dư cọc</span>
              </div>
            </div>
          </div>
        </div>
        <div className="field-input-custom">
          <Label className="label-field-input" for="checkbox-is-default">
            Gói mặc định
          </Label>
          <Checkbox
              id="checkbox-is-default"
              onChange={(e) => onChangeValue('is_default', e.target.checked)}
              checked={!!data.is_default}
          />
        </div>
      </div>
      {/* <div>
        <div style={{ color: '#84818A' }} className="uppercase fw-600">
          Quyền lợi gói
        </div>
        <div className="mt-1 d-flex" style={{ gap: 16 }}>
          <FieldInput
            onChange={(v) => onChangeValue('buy_rate', v)}
            value={data.buy_rate}
            label="Phí GD ON"
            required
            type="number"
          />
          <FieldInput
            onChange={(v) => onChangeValue('sell_rate', v)}
            value={data.sell_rate}
            label="Phí GD OFF"
            type="number"
            required
          />
          <FieldInput
            onChange={(v) => onChangeValue('margin_rate', v)}
            value={data.margin_rate}
            label="Tỉ lệ ký quỹ ON"
            type="number"
            required
          />
        </div>
        <div className="d-flex" style={{ gap: 16 }}>
          <FieldInput
            onChange={(v) => onChangeValue('sell_margin_rate', v)}
            value={data.sell_margin_rate}
            label="Tỉ lệ ký quỹ OFF"
            required
            type="number"
          />
          <FieldInput
            onChange={(v) => onChangeValue('interest_rate', v)}
            value={data.interest_rate}
            label="Lãi vay"
            type="number"
            required
          />
          <div className="w-100" />
        </div>
      </div> */}
      <div
        className="mt-1 d-flex justify-content-center"
        style={{ borderTop: '1px solid #EBEAED', padding: 16, gap: 16 }}
      >
        <Button onClick={onSubmit} color="primary" style={{ minWidth: 160, height: 48 }}>
          Lưu
        </Button>
        <Button style={{ minWidth: 160, height: 48 }} onClick={onClose}>
          Hủy
        </Button>
      </div>
    </Modal>
  );
}

ModalAddPS.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  init: PropTypes.object,
};

export default ModalAddPS;
