import React from 'react';
import PropTypes from 'prop-types';
import SelectInput from '@Components/Input/SelectInput';
import TextInput from '@Components/Input/TextInput';
import { formatNumber } from '@Utils/helpers';
import { getPriceMatching } from '@Utils/tradeHelpers';

function TransactionEditPartCompleted({
  itemDetail,
  register,
  setValue,
  optionsWarehouse,
  errors,
  quantityMatching,
  priceMatching,
}) {
  const { buyItems } = itemDetail;
  let averagePrice;
  if (buyItems.length === 0) {
    averagePrice = priceMatching;
  } else {
    const totalPrice = buyItems.reduce((acc, current) => {
      acc += getPriceMatching(current);
      return acc;
    }, 0);
    averagePrice = totalPrice / buyItems.length;
  }

  return (
    <div className="d-flex gap-20 align-items-center justify-content-between">
      {itemDetail.purchaseAccount ? (
        <div className="item-content">{itemDetail.purchaseAccount.name}</div>
      ) : (
        <SelectInput
          labelCol="12"
          inputCol="12"
          options={optionsWarehouse}
          errors={errors}
          inputClassName="item-content"
          name="purchase_account_id"
          register={{
            ...register('purchase_account_id', { required: true }),
          }}
          setValue={setValue}
        />
      )}

      <div className="item-content" style={{ width: '100px' }}>
        <span className="text-danger">{quantityMatching}</span>
        <span>{`/${itemDetail.quantity}`}</span>
      </div>
      <div className="item-content">{formatNumber(averagePrice)}</div>
    </div>
  );
}

TransactionEditPartCompleted.propTypes = {
  itemDetail: PropTypes.object,
  optionsWarehouse: PropTypes.array,
  register: PropTypes.func,
  errors: PropTypes.object,
  setValue: PropTypes.func,
  quantityMatching: PropTypes.number,
  priceMatching: PropTypes.number,
};

export default TransactionEditPartCompleted;
