import React from 'react';
import DataTableBasic from '@Components/DataTableBasic';
import useCustomQuery from '@Utils/useCustomQuery';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { warehouseStockDetail } from '@Apis/admin/warehouse';
import { formatNumber } from '@Utils/helpers';
import { columns } from './StockColumns';

function Stock() {
  const navigate = useNavigate();
  const location = useLocation();
  const { warehouseId } = useParams();
  const { data: dataStock } = useCustomQuery(warehouseStockDetail, { id: warehouseId });

  return (
    <div className="bg-white p-2">
      <div className="container mb-2">
        <div className="row justify-content-between align-items-center">
          <p className="font-size-24 font-weight-bold">Danh mục</p>
          <p className="font-size-24 font-weight-bold">
            Tổng:{' '}
            {formatNumber(dataStock?.data?.reduce?.((a, b) => a + b.current_price * Math.abs(b.quantity) * b?.stock.multiplier , 0))}
          </p>
        </div>
      </div>
      {dataStock && (
        <DataTableBasic
          columns={columns({ navigate, pathname: location.pathname })}
          data={dataStock.data}
          isPageEffect
        />
      )}
    </div>
  );
}

Stock.propTypes = {};

export default Stock;
