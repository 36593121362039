/* eslint-disable import/no-cycle */
import { customerOverviewAccount } from '@Apis/admin/customer';
import { typeConst } from '@Components/Layouts/PrivateLayout';
import { formatNumber } from '@Utils/helpers';
import useCustomQuery from '@Utils/useCustomQuery';
import React from 'react';
import { useParams } from 'react-router-dom';
import Summary from '../Assets/Summary';

function DashboardCustomer({ setActiveTab }) {
  const { customerId } = useParams();

  const { data: dataSummary } = useCustomQuery(
    customerOverviewAccount,
    { customer_id: customerId },
    true,
    {
      enabled: !!customerId,
    },
  );

  const { overview = {}, accounts = [] } = dataSummary || {};

  if (!dataSummary) return null;

  return (
    <div>
      <Summary overview={overview} />
      <div className="info-product">
        {accounts.map((account, index) => {
          const type = account.trade_type;
            if(type === 'derivative'){
                return (
                    <div className="type-info-product" key={type}>
                        <div
                            className="information-product"
                            style={{ background: typeConst[type].background }}
                        >
                            <div
                                className="d-flex align-items-center justify-content-center"
                                onClick={() => setActiveTab(index + 1)}
                            >
                                <img src={typeConst[type].logo} alt="" style={{ width: 36, height: 36 }} />
                                <span
                                    style={{
                                        marginLeft: 12,
                                        fontWeight: 600,
                                        color: typeConst[type].color,
                                        fontSize: 24,
                                        cursor: 'pointer',
                                    }}
                                >
                    {typeConst[type].label}
                  </span>
                            </div>
                            <div
                                className="d-flex align-items-center justify-content-between mt-2"
                                style={{ fontSize: 14, fontWeight: 700, color: '#504F54', padding: 8 }}
                            >
                                <div>Tổng GTVT</div>
                                <div>{formatNumber(account.total_stock_value, '--')}</div>
                            </div>
                            <div
                                className="d-flex align-items-center justify-content-between"
                                style={{
                                    fontSize: 14,
                                    fontWeight: 700,
                                    color: '#504F54',
                                    padding: 8,
                                    borderTop: `1px solid ${typeConst[type].background}`,
                                }}
                            >
                                <div>Tổng NAV</div>
                                <div>{formatNumber(account.current_nav, '--')}</div>
                            </div>
                        </div>
                        <div>
                            <div
                                className="d-flex align-items-center justify-content-between mt-1"
                                style={{ fontSize: 14, fontWeight: 700, color: '#2F69B3', padding: 8 }}
                            >
                                <div>Gói sản phẩm</div>
                                <div>{account.memberPackage?.name || '--'}</div>
                            </div>
                            <div
                                className="d-flex align-items-center justify-content-between"
                                style={{
                                    fontSize: 14,
                                    color: '#504F54',
                                    padding: 16,
                                    borderTop: '1px solid #EBEAED',
                                }}
                            >
                                <div>Mức hợp tác yêu cầu</div>
                                <div>{formatNumber(account.margin_per_contract, '--')}</div>
                            </div>
                            <div
                                className="d-flex align-items-center justify-content-between"
                                style={{
                                    fontSize: 14,
                                    color: '#504F54',
                                    padding: 16,
                                    borderTop: '1px solid #EBEAED',
                                }}
                            >
                                <div>Phí giao dịch mua</div>
                                <div>{formatNumber(account.buy_rate, '--')}</div>
                            </div>
                            <div
                                className="d-flex align-items-center justify-content-between"
                                style={{
                                    fontSize: 14,
                                    color: '#504F54',
                                    padding: 16,
                                    borderTop: '1px solid #EBEAED',
                                }}
                            >
                                <div>Phí giao dịch bán</div>
                                <div>{formatNumber(account.sell_rate, '--')}</div>
                            </div>
                            <div
                                className="d-flex align-items-center justify-content-between"
                                style={{
                                    fontSize: 14,
                                    color: '#504F54',
                                    padding: 16,
                                    borderTop: '1px solid #EBEAED',
                                }}
                            >
                                <div>Phí quản lý vị thế qua đêm</div>
                                <div>{formatNumber(account.on_fee, '--')}</div>
                            </div>
                            <div
                                className="d-flex align-items-center justify-content-between"
                                style={{
                                    fontSize: 14,
                                    color: '#504F54',
                                    padding: 16,
                                    borderTop: '1px solid #EBEAED',
                                }}
                            >
                                <div>Phí chậm nộp</div>
                                <div>{formatNumber(account.margin_interest_rate, '--')}%</div>
                            </div>

                            <div
                                className="d-flex align-items-center justify-content-between"
                                style={{
                                    fontSize: 14,
                                    color: '#504F54',
                                    padding: 16,
                                    borderTop: '1px solid #EBEAED',
                                }}
                            >
                                <div>RTT</div>
                                <div>{formatNumber(50, '--')}%</div>
                            </div>
                            <div
                                className="d-flex align-items-center justify-content-center fw-600"
                                style={{ fontSize: 14, color: '#2F69B3', padding: 16, cursor: 'pointer' }}
                                role="button"
                                tabIndex={0}
                                onClick={() => {
                                    setActiveTab(index + 1);
                                    // setProductTypeFunc(typeConst[type].role);
                                    // navigate('/dashboard');
                                }}
                            >
                                <div>Xem chi tiết</div>
                                <img style={{ marginLeft: 10 }} src="/images/logo/xem_chi_tiet.svg" alt="" />
                            </div>
                        </div>
                    </div>
                );
            }
          return (
            <div className="type-info-product" key={type}>
              <div
                className="information-product"
                style={{ background: typeConst[type].background }}
              >
                <div
                  className="d-flex align-items-center justify-content-center"
                  onClick={() => setActiveTab(index + 1)}
                >
                  <img src={typeConst[type].logo} alt="" style={{ width: 36, height: 36 }} />
                  <span
                    style={{
                      marginLeft: 12,
                      fontWeight: 600,
                      color: typeConst[type].color,
                      fontSize: 24,
                      cursor: 'pointer',
                    }}
                  >
                    {typeConst[type].label}
                  </span>
                </div>
                <div
                  className="d-flex align-items-center justify-content-between mt-2"
                  style={{ fontSize: 14, fontWeight: 700, color: '#504F54', padding: 8 }}
                >
                  <div>Tổng GTCK</div>
                  <div>{formatNumber(account.total_stock_value, '--')}</div>
                </div>
                <div
                  className="d-flex align-items-center justify-content-between"
                  style={{
                    fontSize: 14,
                    fontWeight: 700,
                    color: '#504F54',
                    padding: 8,
                    borderTop: `1px solid ${typeConst[type].background}`,
                  }}
                >
                  <div>Tổng NAV</div>
                  <div>{formatNumber(account.current_nav, '--')}</div>
                </div>
              </div>
              <div>
                <div
                  className="d-flex align-items-center justify-content-between mt-1"
                  style={{ fontSize: 14, fontWeight: 700, color: '#2F69B3', padding: 8 }}
                >
                  <div>Gói sản phẩm</div>
                  <div>{account.memberPackage?.name || '--'}</div>
                </div>
                <div
                  className="d-flex align-items-center justify-content-between"
                  style={{
                    fontSize: 14,
                    color: '#504F54',
                    padding: 16,
                    borderTop: '1px solid #EBEAED',
                  }}
                >
                  <div>Phí vay/ngày</div>
                  <div>{formatNumber(account.interest_rate, '--')}%</div>
                </div>
                <div
                  className="d-flex align-items-center justify-content-between"
                  style={{
                    fontSize: 14,
                    color: '#504F54',
                    padding: 16,
                    borderTop: '1px solid #EBEAED',
                  }}
                >
                  <div>Phí mua</div>
                  <div>{formatNumber(account.buy_rate, '--')}%</div>
                </div>
                <div
                  className="d-flex align-items-center justify-content-between"
                  style={{
                    fontSize: 14,
                    color: '#504F54',
                    padding: 16,
                    borderTop: '1px solid #EBEAED',
                  }}
                >
                  <div>Phí bán</div>
                  <div>{formatNumber(account.sell_rate, '--')}%</div>
                </div>
                <div
                  className="d-flex align-items-center justify-content-between"
                  style={{
                    fontSize: 14,
                    color: '#504F54',
                    padding: 16,
                    borderTop: '1px solid #EBEAED',
                  }}
                >
                  <div>Tỷ lệ ký quỹ</div>
                  <div>{formatNumber(account.margin_rate, '--')}%</div>
                </div>
                <div
                  className="d-flex align-items-center justify-content-between"
                  style={{
                    fontSize: 14,
                    color: '#504F54',
                    padding: 16,
                    borderTop: '1px solid #EBEAED',
                  }}
                >
                  <div>Tỷ lệ call</div>
                  <div>{formatNumber(account.call_rate, '--')}%</div>
                </div>
                <div
                  className="d-flex align-items-center justify-content-between"
                  style={{
                    fontSize: 14,
                    color: '#504F54',
                    padding: 16,
                    borderTop: '1px solid #EBEAED',
                  }}
                >
                  <div>Phí vay cọc thiếu</div>
                  <div>{formatNumber(account.margin_interest_rate, '--')}%</div>
                </div>
                <div
                  className="d-flex align-items-center justify-content-center fw-600"
                  style={{ fontSize: 14, color: '#2F69B3', padding: 16, cursor: 'pointer' }}
                  role="button"
                  tabIndex={0}
                  onClick={() => {
                    setActiveTab(index + 1);
                    // setProductTypeFunc(typeConst[type].role);
                    // navigate('/dashboard');
                  }}
                >
                  <div>Xem chi tiết</div>
                  <img style={{ marginLeft: 10 }} src="/images/logo/xem_chi_tiet.svg" alt="" />
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default DashboardCustomer;
