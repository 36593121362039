import React, { useState } from 'react';
import DataTableBasic from '@Components/DataTableBasic';
import { formatNumber } from '@Utils/helpers';
import useCustomQuery from '@Utils/useCustomQuery';
import { commissionStats } from '@Apis/admin/user';
import DatePicker from '@Components/DatePicker';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

const columns = [
  {
    name: 'THÁNG',
    cell: (r) => r.label?.split('/')?.[0],
    center: true,
    width: '80px',
  },
  {
    name: 'TỔNG SALE',
    cell: (row) => (
      <div className="w-100 text-align-end" style={{ minWidth: 120 }}>
        {formatNumber(row.sales_total)}
      </div>
    ),
    center: true,
  },
  {
    name: 'HH SALE',
    cell: (row) => (
      <div className="w-100 text-align-end" style={{ minWidth: 120 }}>
        {formatNumber(row.sales)}
      </div>
    ),
    center: true,
  },
  {
    name: 'TỔNG KSV',
    cell: (row) => (
      <div className="w-100 text-align-end" style={{ minWidth: 120 }}>
        {formatNumber(row.support_total)}
      </div>
    ),
    center: true,
  },
  {
    name: 'HH KSV',
    cell: (row) => (
      <div className="w-100 text-align-end" style={{ minWidth: 120 }}>
        {formatNumber(row.support)}
      </div>
    ),
    center: true,
  },
  {
    name: 'TỔNG GDV',
    cell: (row) => (
      <div className="w-100 text-align-end" style={{ minWidth: 120 }}>
        {formatNumber(row.tellers_total)}
      </div>
    ),
    center: true,
  },
  {
    name: 'HH GDV',
    cell: (row) => (
      <div className="w-100 text-align-end" style={{ minWidth: 120 }}>
        {formatNumber(row.tellers)}
      </div>
    ),
    center: true,
  },
  {
    name: 'TỔNG HOA HỒNG',
    cell: (row) => (
      <div className="w-100 text-align-end" style={{ minWidth: 120 }}>
        {formatNumber(row.total_commission)}
      </div>
    ),
    center: true,
  },
];

function Month({ api, columns: cls, title }) {
  const { employeeId } = useParams();
  const [date, setDate] = useState(dayjs().format('YYYY'));
  const { data } = useCustomQuery(
    api || commissionStats,
    { year: date, user_id: employeeId },
    true,
  );

  return (
    <>
      <div className="commission-content">
        <span style={{ fontSize: 24, fontWeight: 600 }}>
          {title || 'Thống kê hoa hồng theo tháng'}
        </span>
        <div>
          <DatePicker
            onChange={setDate}
            value={date}
            onlyYear
            style={{ minWidth: 218 }}
            placeholder="Chọn năm"
          />
        </div>
      </div>
      <div style={{ marginTop: 22 }}>
        <DataTableBasic columns={cls || columns} data={data} />
      </div>
    </>
  );
}

Month.propTypes = {
  api: PropTypes.func,
  columns: PropTypes.array,
  title: PropTypes.string,
};

export default Month;
