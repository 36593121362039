import styled from 'styled-components';

export const ModalWrapper = styled.div`
  button {
    width: 164px;
    text-align: left;
  }
`;

export const Title = styled.div`
  font-size: 24px;
  font-weight: 600;
  text-align: center;
`;
