import Title from '@Components/Title';
import React, { useCallback, useState } from 'react';
import NavigationBar from '@Components/NavigationBar';
import DataTableBasic from '@Components/DataTableBasic';
import useCustomQuery from '@Utils/useCustomQuery';
import { columns } from './columns';
import './styles.scss';
import { transactionStats } from '@Apis/admin/trade';
import DatePicker from '@Components/DatePicker';
import dayjs from 'dayjs';
import { Button } from 'reactstrap';
import * as XLSX from 'xlsx-js-style';
import { typeConst } from '@Components/Layouts/PrivateLayout';

function TransactionStats() {
  const [activeTabId, setActiveTab] = useState('base');
  const [filter, setFilter] = useState({ per_page: 1000000 });

  const setOrderBy = useCallback((column, type = '') => {
    setFilter((prevValues) => {
      return {
        ...prevValues,
        sort_by: column.sortField,
        order: type.toUpperCase(),
      };
    });
  }, []);

  const onChangeDate = useCallback((v) => {
    setFilter((pre) => ({ ...pre, date_range: v.map((d) => dayjs(d).unix()).join('-') }));
  }, []);

  const { data } = useCustomQuery(transactionStats, { ...filter, trade_type: activeTabId }, true, {
    enabled: !!filter.date_range
  });

  const getDefaultDateRange = () => {
    const end = dayjs();
    const start = dayjs().subtract(1, 'month');
    if (!filter.date_range)
      setFilter((pre) => ({
        ...pre,
        date_range: [start, end].map((d) => dayjs(d).unix()).join('-'),
      }));

    return [start, end];
  };

  const exportStats = (data, titles) => {
    const typeTitle = typeConst[activeTabId].label;
    const customersExcel = data.map((customer) => {
      const { username, sum, avg, interest_amount, buy_fee, sell_fee } = customer;
      const customerMapping = [
        username,
        sum,
        avg,
        interest_amount,
        buy_fee,
        sell_fee,
      ];
      const customerExcel = Object.assign({}, customerMapping);
      return customerExcel;
    });
    const dataRows = [...customersExcel];
    const ws = XLSX.utils.json_to_sheet(dataRows);
    const wb = XLSX.utils.book_new();
    ws['!cols'] = [{ width: 20 }, { width: 30 }];
    XLSX.utils.sheet_add_aoa(ws, [titles], { origin: 'A1' });
    XLSX.utils.book_append_sheet(wb, ws);
    XLSX.writeFile(wb, `Thống kê giao dịch - ${typeTitle}.xlsx`);
  };

  return (
    <div className="transaction-stats">
      <div className="d-flex align-items-center justify-content-between">
        <Title name="Thống kê giao dịch" />
        <div className="d-flex align-items-center justify-content-between">
          <Button
            style={{ marginRight: 5 }}
            outline
            color="primary"
            onClick={() =>
              exportStats(
                data,
                columns().map((e) => e.name),
              )
            }
          >
            <div className="">
              <img src="/images/report/excel.svg" alt="" />
              <span className="ml-50">Xuất báo cáo</span>
            </div>
          </Button>
          <DatePicker.Range
            onChange={onChangeDate}
            style={{ width: 240 }}
            placeholder="Chọn ngày.."
            defaultValue={getDefaultDateRange()}
          />
        </div>
      </div>
      <NavigationBar
        setActiveTab={(v) => {
          setActiveTab(v);
        }}
        style={{ background: 'transparent', paddingLeft: 0 }}
        activeTab={activeTabId}
        tabs={[
          { id: 'base', name: 'Cơ sở' },
          { id: 'spot', name: 'CK T+' },
          { id: 'derivative', name: 'Phái sinh' },
        ]}
      />

      <div className="mt-1">
        <DataTableBasic onSort={setOrderBy} columns={columns()} data={data} />
      </div>
    </div>
  );
}

export default TransactionStats;
