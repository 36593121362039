import axiosService from '@Lib/axiosService';
import { handleResponse } from '@Utils/helpers';

let host;
if (process.env.DASHBOARD_TYPE === 'admin') {
  host = `${process.env.API_ADMIN_URL}/bank`;
} else {
  host = `${process.env.API_CUSTOMER_URL}`;
}

export const adminBankList = async () => {
  const res = await axiosService.get(`${host}/list-for-select`);
  return handleResponse(res);
};
