import React, { Component } from 'react';
import DataTableBasic from '@Components/DataTableBasic';
import P from 'prop-types';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { hideGlobalLoading, showGlobalLoading } from '@Lib/slices/uiSlice';
import useCustomQuery from '@Utils/useCustomQuery';
import { accountsHome } from '@Apis/client/home';
import { list } from '@Apis/client/transaction';
import { toastError } from '@Utils/toastrHelper';
import { io } from 'socket.io-client';
import { columns } from './columns';
import HeaderTransaction from './Header';
import './styles.scss';

class TransactionList extends Component {
  constructor(props) {
    super(props);
    this.state = { data: {}, filter: {} };
  }

  UNSAFE_componentWillMount() {
    const { dispatch } = this.props;
    dispatch(showGlobalLoading());
    // this.connectSocket();
    this.getData();
  }

  UNSAFE_componentWillReceiveProps(nProps) {
    const { page, accountId } = this.props;
    if (page !== nProps.page || accountId !== nProps.accountId) {
      const { dispatch } = this.props;
      if (page !== nProps.page) dispatch(showGlobalLoading());
      this.getData(this.state.filter, nProps.page);
      // this.socket?.disconnect();
      // this.connectSocket(nProps);
    }
  }

  shouldComponentUpdate(nProps, nState) {
    const { data } = this.state;
    const { page } = this.props;
    return (
      page !== nProps.page ||
      data?.data?.length !== nState.data?.data?.length ||
      !!data?.data?.some((e, index) => {
        const n = nState.data?.data?.[index];
        return e.is_settlement !== n?.is_settlement || e.stock?.price !== n?.stock?.price;
      })
    );
  }

  componentWillUnmount() {
    clearInterval(this.interVal);
  }

  getData = (filter, page = 1, checkLoading = true) => {
    const { dispatch, accountId } = this.props;
    if (checkLoading) dispatch(showGlobalLoading());
    list({
      ...filter,
      page,
      per_page: 10,
      status: 'completed,part_completed',
      customer_id: accountId,
    })
      .then((res) => {
        this.setState({ data: res, filter });
      })
      .catch(toastError)
      .finally(() => {
        if (checkLoading) dispatch(hideGlobalLoading());
      });
  };

  // connectSocket = (props = this.props) => {
  //   const token = localStorage.getItem(process.env.TOKEN);
  //   this.socket = io(`${process.env.BACKEND_SOCKET}/customer/trade/transactions`, {
  //     query: { token },
  //     transports: ['websocket'],
  //   });
  //   const { filter, accountId } = this.state;
  //   const { page, dispatch } = props;
  //   this.socket.on('connect', () => {
  //     this.socket.emit('register', 'your-client-id', {
  //       ...filter,
  //       page,
  //       per_page: 10,
  //       customer_id: accountId,
  //     });
  //   });
  //   this.socket.on('data', ({ data: dataSocket }) => {
  //     this.setState({ data: dataSocket, filter });
  //     dispatch(hideGlobalLoading());
  //   });
  //   this.socket.on('connect_error', () => {
  //     dispatch(hideGlobalLoading());
  //   });
  // };

  handleSearch = (f) => {
    const { dispatch } = this.props;
    dispatch(showGlobalLoading());
    const { navigate, productType } = this.props;
    navigate(`/${productType}/transaction/list?page=1`);
    // this.socket?.disconnect();
    // this.setState({ filter: f }, this.connectSocket);
    this.getData(f);
  };

  render() {
    const { data } = this.state;
    const { page } = this.props;
    return (
      <div className="transaction-list">
        <HeaderTransaction onSearch={this.handleSearch} />
        <div style={{ marginTop: 32 }}>
          <DataTableBasic pageSize={10} page={Number(page)} data={data} columns={columns} />
        </div>
      </div>
    );
  }
}

function TransactionListSwap() {
  const [searchParams] = useSearchParams();
  const page = searchParams.get('page') || 1;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const productType = useSelector((state) => state.ui.productType);
  const { data: dataAccounts } = useCustomQuery(accountsHome);
  const account = dataAccounts?.accounts?.find((e) => e.trade_type === productType);

  if (!account?.id) return null;
  return (
    <TransactionList
      accountId={account?.id}
      dispatch={dispatch}
      page={Number(page)}
      navigate={navigate}
      productType={productType}
    />
  );
}

TransactionList.propTypes = {
  navigate: P.func,
  dispatch: P.func,
  page: P.number,
  accountId: P.any,
  productType: P.any,
};

export default TransactionListSwap;
