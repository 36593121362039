import React from 'react';
import { customerSummary } from '@Apis/admin/customer';
import { Row, Col, Container } from 'reactstrap';
import { formatVND } from '@Utils/helpers';
import useCustomQuery from '@Utils/useCustomQuery';
import { SummaryWrapper } from '../styles';

function Summary(props) {
  const { tradeType } = props;
  const { data } = useCustomQuery(customerSummary, {trade_type: tradeType});
  return data ? (
    <SummaryWrapper>
      <Container className="my-2 bg-white">
        <Row>
          <Col className="">
            <div className="d-flex align-items-center mb-2">
              <img src="/images/employee/total-sale.svg" alt="Tổng giá trị CK" className="mr-50" />
              <p className="font-weight-bolder">Tổng giá trị CK</p>
            </div>
            <p className="font-size-28 font-weigth-bolder">{formatVND(data.total_stock_value)}</p>
          </Col>
          <Col className="">
            <div className="d-flex align-items-center mb-2">
              <img src="/images/employee/total-support.svg" alt="Tổng tiền mặt" className="mr-50" />
              <p className="font-weight-bolder">
                {data.total_balance > 0 ? 'Tổng dư cọc' : 'Tổng thiếu cọc'}
              </p>
            </div>
            <p className="font-size-28 font-weigth-bolder">{formatVND(data.total_balance)}</p>
          </Col>
          <Col className="">
            <div className="d-flex align-items-center mb-2">
              <img
                src="/images/employee/total-tellers.svg"
                alt="Tổng NAV khách"
                className="mr-50"
              />
              <p className="font-weight-bolder">Tổng NAV khách</p>
            </div>
            <p className="font-size-28 font-weigth-bolder">{formatVND(data.total_nav)}</p>
          </Col>
          <Col className="">
            <div className="d-flex align-items-center mb-2">
              <img
                src="/images/employee/total-user.svg"
                alt="KH hoạt động/tổng KH"
                className="mr-50"
              />
              <p className="font-weight-bolder">KH hoạt động/tổng KH</p>
            </div>
            <p className="font-size-28 font-weigth-bolder">
              {formatVND(data.active_count)}/{data.count}
            </p>
          </Col>
        </Row>
      </Container>
    </SummaryWrapper>
  ) : (
    ''
  );
}

Summary.propTypes = {};

export default Summary;
