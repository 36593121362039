import React, { useState, useRef, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import useCustomQuery from '@Utils/useCustomQuery';
import { stocks } from '@Apis/admin/stock';
import SuggestSearch from '.';

function StockSuggest({
  name,
  labelName,
  labelCol,
  labelClassName,
  inputCol,
  setSelected,
  errorDetail,
  defaultValue,
  tradeType,
}) {
  const typingRef = useRef(null);
  const [value, setValue] = useState('');
  const [search, setSearch] = useState('');
  const [suggestions, setSuggestions] = useState([]);

  const getSuggestionValue = (suggestion) => {
    if (suggestion) {
      setSelected(suggestion.id);
    } else {
      setSelected('');
    }
    return suggestion.id;
  };

  useEffect(() => {
    if (!search) {
      setSelected('');
    }
  }, [search]);
  const { data } = useCustomQuery(stocks, { keyword: search, trade_type: tradeType });
  // const { data: dataDefault } = useCustomQuery(customerDetail, { id: defaultValue }, false, {
  //   enabled: !!defaultValue,
  // });

  const onChange = useCallback((event, { newValue }) => {
    setValue(newValue);
    if (typingRef.current) {
      clearTimeout(typingRef.current);
    }
    typingRef.current = setTimeout(() => {
      setSearch(newValue);
    }, 300);
  }, []);

  useEffect(() => {
    if (data) {
      setSuggestions(data.data);
    } else {
      setSuggestions([]);
    }
  }, [data]);

  // useEffect(() => {
  //   if (dataDefault) {
  //     setValue(dataDefault.username);
  //     setSelected(dataDefault.id);
  //   }
  // }, [dataDefault]);

  const renderSuggestion = (suggestion) => {
    return (
      <div>
        <p className="mb-0">{suggestion?.id}</p>
      </div>
    );
  };

  const inputProps = {
    placeholder: 'Nhập mã CK',
    type: 'search',
    value,
    onChange,
  };
  return (
    <SuggestSearch
      name={name}
      labelName={labelName}
      labelCol={labelCol}
      labelClassName={labelClassName}
      inputCol={inputCol}
      errorDetail={errorDetail}
      suggestions={suggestions}
      inputProps={inputProps}
      getSuggestionValue={getSuggestionValue}
      renderSuggestion={renderSuggestion}
    />
  );
}

StockSuggest.propTypes = {
  name: PropTypes.string,
  labelName: PropTypes.string,
  labelClassName: PropTypes.string,
  labelCol: PropTypes.string,
  inputCol: PropTypes.string,
  setSelected: PropTypes.func,
  errorDetail: PropTypes.object,
  defaultValue: PropTypes.number,
  tradeType: PropTypes.any,
};

export default StockSuggest;
