import axiosService from '@Lib/axiosService';
import { handleResponse } from '@Utils/helpers';

let host;
if (process.env.DASHBOARD_TYPE === 'customer') {
  host = `${process.env.API_CUSTOMER_URL}/account`;
}

export const buyAbility = async (params) => {
  const res = await axiosService.get(`${host}/buy-ability`, params);
  return handleResponse(res);
};

export const secondOverview = async (params) => {
  const res = await axiosService.get(`${host}/second-overview`, params);
  return handleResponse(res);
};

export const transactions = async (params) => {
  const res = await axiosService.get(`${host}/transactions`, params);
  return handleResponse(res);
};

export const currentPosition = async (params) => {
  const res = await axiosService.get(`${host}/current-position`, params);
  return handleResponse(res);
};

export const accountOverview = async (params) => {
  const res = await axiosService.get(`${host}/overview`, params);
  return handleResponse(res);
};
