import axiosService from '@Lib/axiosService';
import { handleResponse } from '@Utils/helpers';

let host;
if (process.env.DASHBOARD_TYPE === 'customer') {
  host = `${process.env.API_CUSTOMER_URL}/image`;
}

export const upload = async (data) => {
  const res = await axiosService.post(`${host}/upload-avatar`, data);
  return handleResponse(res);
};
