import React from 'react';
import dayjs from 'dayjs';
import { formatNumber } from '@Utils/helpers';

export const columnsViTheDong = [
  {
    name: 'Mã HĐ',
    cell: (row) => <div className="">{row.stock_id}</div>,
    width: '100px',
    center: true,
  },
  {
    name: 'Số vị thế mua',
    cell: (row) => (
      <div className="">
        {row.total_buy_quantity > row.total_sell_quantity
          ? row.total_sell_quantity
          : row.total_buy_quantity || 0}
      </div>
    ),
    width: '130px',
    right: true,
  },
  {
    name: 'số vị thế bán',
    cell: (row) => (
      <div className="">
        {row.total_buy_quantity > row.total_sell_quantity
          ? row.total_sell_quantity
          : row.total_buy_quantity || 0}
      </div>
    ),
    width: '130px',
    right: true,
  },
  {
    name: 'Net off',
    cell: (row) => (
      <div className="">
        {row.total_buy_quantity > row.total_sell_quantity
          ? row.total_sell_quantity * 2
          : row.total_buy_quantity * 2}
      </div>
    ),
    width: '130px',
    right: true,
  },
  {
    name: 'Giá mua TB',
    cell: (row) => <div className="">{formatNumber(row?.avg_buy)}</div>,
    width: '130px',
    right: true,
  },
  {
    name: 'Giá bán TB',
    cell: (row) => <div className="">{formatNumber(row?.avg_sell)}</div>,
    width: '130px',
    right: true,
  },
  {
    name: 'P/L',
    cell: (row) => <div className="">{formatNumber(row?.profit, '--', true, 0)}</div>,
    width: '130px',
    right: true,
  },
];

export const columnsViTheMo = [
  {
    name: 'Mã HĐ',
    cell: (row) => <div className="">{row.stock?.id}</div>,
    width: '100px',
    center: true,
  },
  {
    name: 'Vị thế mua',
    cell: (row) => <div className="">{row.quantity > 0 ? row.quantity : 0}</div>,
    width: '130px',
    right: true,
  },
  {
    name: 'Vị thế bán',
    cell: (row) => <div className="">{row.quantity < 0 ? Math.abs(row.quantity) : 0}</div>,
    width: '130px',
    right: true,
  },
  {
    name: 'NET',
    cell: (row) => <div className="">{row.quantity}</div>,
    width: '130px',
    right: true,
  },
  {
    name: 'Giá bình quân',
    cell: (row) => <div className="">{formatNumber(row?.lastTradingItem?.current_cost_price)}</div>,
    width: '130px',
    right: true,
  },
  {
    name: 'GIÁ TT',
    cell: (row) => <div className="">{formatNumber(row.stock?.price)}</div>,
    width: '130px',
    right: true,
  },
  {
    name: 'Giá trị vị thế',
    cell: (row) => (
      <div className="">
        {formatNumber(Math.abs(row.quantity) * row.stock?.price * row.stock?.multiplier)}
      </div>
    ),
    width: '130px',
    right: true,
  },
  {
    name: 'LỜI/LỖ',
    cell: (row) => {
      const startValue = row.quantity * (row?.lastTradingItem?.current_cost_price || 0);
      const pnl_wait = row.stock.price * row.quantity - startValue;
      return (
        <div style={{ color: pnl_wait >= 0 ? '#14B13B' : '#FC3400' }}>
          {pnl_wait >= 0 ? '+ ' : ''}
          {formatNumber(pnl_wait * row?.lastTradingItem?.multiplier, '--', true, 0)}
        </div>
      );
    },
    width: '130px',
    right: true,
  },
];

export const columnsLichSuDongViThe = [
  {
    name: 'Ngày',
    cell: (row) => <div className="">{dayjs.unix(row.unix_time_id).format(' DD/MM/YY')}</div>,
    width: '100px',
    center: true,
  },
  {
    name: 'Mã HĐ',
    cell: (row) => <div className="">{row.stock_id}</div>,
    width: '100px',
    center: true,
  },
  {
    name: 'KL mua',
    cell: (row) => <div className="">{row.total_buy_quantity}</div>,
    width: '130px',
    right: true,
  },
  {
    name: 'Giá mua TB',
    cell: (row) => <div className="">{formatNumber(row?.avg_buy)}</div>,
    width: '130px',
    right: true,
  },
  {
    name: 'KL bán',
    cell: (row) => <div className="">{row.total_sell_quantity}</div>,
    width: '130px',
    right: true,
  },
  {
    name: 'Giá bán TB',
    cell: (row) => <div className="">{formatNumber(row?.avg_sell)}</div>,
    width: '130px',
    right: true,
  },
  {
    name: 'P/L',
    cell: (row) => <div className="">{formatNumber(row?.profit)}</div>,
    width: '130px',
    right: true,
  },
];

export const columnsAdminViTheMoPS = [
  {
    name: 'KHÁCH HÀNG',
    cell: (row) => <div className="">{row.protectedCustomer?.username}</div>,
    width: '160px',
    left: true,
  },
].concat(columnsViTheMo);
