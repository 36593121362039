import Title from '@Components/Title';
import React, { useState } from 'react';
import NavigationBar from '@Components/NavigationBar';
import ButtonDownloadFile from '@Components/ButtonDownloadFile';
import dayjs from 'dayjs';
import FlatPickrDateRange from '@Components/FlatPickrDateRange';
import DropdownSelect from '@Components/DropdownSelect';
import { COMISSION_TYPE, TABS } from './constant';
import BalanceSheet from './components/BalanceSheet';
import TransactionReport from './components/TransactionReport';
import Commission from './components/Commission';
import ProfitLoss from './components/ProfitLoss';
import ModalExport from './components/ModalExport';
import { ReportWrapper } from './styled';
import './styles.scss';

function Report() {
  const [tab, setTab] = useState(1);
  const startMonth = dayjs().startOf('month').unix();
  const endMonth = dayjs().endOf('month').unix();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [filter, setFilter] = useState({
    date_range: `${startMonth}-${endMonth}`,
    types: 'sales,surveyor,tellers',
    view_by: 'day',
  });

  const filterOnChange = (name, value) => {
    setFilter((prevValues) => {
      return {
        ...prevValues,
        [name]: value,
      };
    });
  };

  const handleExportExcel = () => {
    setIsOpenModal(true);
  };

  return (
    <ReportWrapper className="report">
      <div className="d-flex justify-content-between">
        <Title name="Báo cáo" />
        <div className="d-flex gap-8">
          <ButtonDownloadFile onClick={() => handleExportExcel()} />
          {tab === 4 && (
            <DropdownSelect
              name="types"
              style={{ height: '100%' }}
              options={COMISSION_TYPE}
              valueSelected={filter.types}
              setValueSelected={filterOnChange}
            />
          )}
          <DropdownSelect
            labelShow={false}
            name="view_by"
            style={{ height: '100%' }}
            options={[
              {
                label: 'Ngày',
                value: 'day',
              },
              {
                label: 'Tuần',
                value: 'week',
              },
              {
                label: 'Tháng',
                value: 'month',
              },
            ]}
            valueSelected={filter.view_by}
            setValueSelected={filterOnChange}
          />
          {tab !== 1 && (
            <FlatPickrDateRange
              isOpen={false}
              dateRange={filter.date_range}
              setDateRange={filterOnChange}
              className="filter-date-range"
              labelDefault="Chọn ngày"
              name="date_range"
            />
          )}
        </div>
      </div>
      <div style={{ paddingTop: 10 }}>
        <NavigationBar className="custom-bar" tabs={TABS} activeTab={tab} setActiveTab={setTab} />
      </div>
      {tab === 1 && <BalanceSheet />}
      {tab === 2 && <ProfitLoss view_by={filter.view_by} dateRange={filter.date_range} />}
      {tab === 3 && <TransactionReport view_by={filter.view_by} dateRange={filter.date_range} />}
      {tab === 4 && (
        <Commission view_by={filter.view_by} dateRange={filter.date_range} types={filter.types} />
      )}
      <ModalExport
        isOpen={isOpenModal}
        tab={tab}
        closeModal={() => setIsOpenModal(false)}
        dateRange={filter.date_range}
        filterOnChange={filterOnChange}
        types={filter.types}
      />
    </ReportWrapper>
  );
}

export default Report;
