import styled from 'styled-components';

export const TradeHistoryWrapper = styled.div`
  .model-trade-edit {
    background: red;
  }
  .bg-buy {
    background: rgba(20, 177, 59, 0.1);
  }

  .bg-sell {
    background: #fff7f7;
  }
`;
