import React from 'react';
import PropTypes from 'prop-types';
import useCustomQuery from '@Utils/useCustomQuery';
import { loanSummary } from '@Apis/admin/loan';
import { Row, Col, Container } from 'reactstrap';
import { formatVND } from '@Utils/helpers';

function Summary(props) {
  const { data } = useCustomQuery(loanSummary, { type: 'loan' });
  return data ? (
    <Container className="y-2 mb-2">
      <Row className="gap-20">
        <Col className="p-1 bg-white ">
          <div className="d-flex align-items-center mb-1">
            <p className="font-weight-bold font-size-12 color-matte-gray">LÃI CHƯA TẤT TOÁN</p>
          </div>
          <p className="font-size-28 font-weigth-bolder">{formatVND(data.provisional_amount)}</p>
        </Col>
        <Col className="p-1 bg-white ">
          <div className="d-flex align-items-center mb-1">
            <p className="font-weight-bold font-size-12 color-matte-gray">LÃI ĐÃ TẤT TOÁN</p>
          </div>
          <p className="font-size-28 font-weigth-bolder">{formatVND(data.unpaid_amount)}</p>
        </Col>
        <Col className="p-1 bg-white ">
          <div className="d-flex align-items-center mb-1">
            <p className="font-weight-bold font-size-12 color-matte-gray">LÃI ĐÃ THANH TOÁN</p>
          </div>
          <p className="font-size-28 font-weigth-bolder">{formatVND(data.paid_amount)}</p>
        </Col>
        <Col className="p-1 bg-white ">
          <div className="d-flex align-items-center mb-1">
            <p className="font-weight-bold font-size-12 color-matte-gray">DƯ NỢ GỐC</p>
          </div>
          <p className="font-size-28 font-weigth-bolder">{formatVND(data.total)}</p>
        </Col>
      </Row>
    </Container>
  ) : (
    ''
  );
}

Summary.propTypes = {};

export default Summary;
