/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { setProductType, setSideBar } from '@Lib/slices/uiSlice';
import { Link, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { checkRole } from '@Utils/helpers';
import Item from './Item';
import ItemHasChild from './ItemHasChild';
import { privateConstant } from '../../../../routers/privateContant';
import PermissionMenu from '../PermissionMenu';
import { TYPE_CS, TYPE_HOME, TYPE_PS, TYPE_TPLUS } from '..';

function VerticalMenu({ setCloseMenu, closeMenu, menuVisibility, product }) {
  const dispatch = useDispatch();
  const { sidebar, role, productType } = useSelector((state) => ({
    sidebar: state.ui.sidebar,
    productType: state.ui.productType,
    role: state.user.role,
  }));

  useEffect(() => {
    if (sidebar.length === 0) {
      const sidebarConstant = privateConstant.map((item) => {
        if (item.child) {
          item.child.map((itemChild) => {
            delete itemChild.component;
            return itemChild;
          });
        } else {
          delete item.component;
        }
        return item;
      });
      dispatch(setSideBar(sidebarConstant));
    }
  }, [sidebar]);

  const shadowRef = useRef(null);

  const scrollMenu = (container) => {
    if (shadowRef && container.scrollTop > 0) {
      if (!shadowRef.current.classList.contains('d-block')) {
        shadowRef.current.classList.add('d-block');
      }
    } else if (shadowRef.current.classList.contains('d-block')) {
      shadowRef.current.classList.remove('d-block');
    }
  };

  const getLogoUrl = () => {
    if (process.env.DASHBOARD_TYPE === 'admin') return '/dashboard';
    if (productType === TYPE_CS) return `/${TYPE_CS}/dashboard`;
    if (productType === TYPE_PS) return `/${TYPE_PS}/dashboard`;
    if (productType === TYPE_TPLUS) return `/${TYPE_TPLUS}/dashboard`;
    return '/home';
  };

  return (
    <div
      className="main-menu menu-fixed menu-light menu-accordion"
      style={{
        width: closeMenu ? 75 : 250,
        overflow: 'auto',
        transform: menuVisibility ? 'none' : '',
        zIndex: 998,
      }}
    >
      <div
        className="navbar-header"
        style={{
          backgroundColor: process.env.DASHBOARD_TYPE === 'admin' ? '#fff' : product?.background,
        }}
      >
        <ul className="nav navbar-nav flex-row">
          <li className="nav-item">
            <div className="d-flex align-items-center" style={{ marginTop: closeMenu ? 9 : 7 }}>
              {closeMenu ? null : (
                <Link
                  to={getLogoUrl()}
                  className="d-flex align-items-center"
                  style={{ width: 171 }}
                >
                  {process.env.DASHBOARD_TYPE === 'admin' || !product ? (
                    <img className="img-fluid" src="/images/logo/logo-vcl.svg" alt="Logo" />
                  ) : (
                    <>
                      <img
                        className="img-fluid"
                        src={product?.logo}
                        alt="Login V2"
                        style={{ marginLeft: -5, width: 40, height: 40 }}
                      />
                      <span style={{ marginLeft: 16, fontWeight: 600, fontSize: 26 }}>
                        {product?.label}
                      </span>
                    </>
                  )}
                </Link>
              )}
              {!closeMenu ? (
                <img
                  onClick={() => setCloseMenu(true)}
                  src="/images/menu/open_close_menu.svg"
                  alt=""
                  style={{ paddingLeft: 5, cursor: 'pointer' }}
                />
              ) : (
                <img
                  style={{ cursor: 'pointer', marginLeft: -3 }}
                  onClick={() => setCloseMenu(false)}
                  src="/images/menu/close_menu.svg"
                  alt=""
                />
              )}
            </div>
          </li>
        </ul>
      </div>
      <div className="shadow-bottom" />
      <PerfectScrollbar
        className="main-menu-content pt-1"
        options={{ wheelPropagation: false }}
        onScrollY={(container) => scrollMenu(container)}
      >
        <ul className="navigation navigation-main">
          {sidebar.length > 0 &&
            sidebar
              .map((route) => {
                if (route && route.hasSub && !route.hidden) {
                  return (
                    <PermissionMenu isPermission={checkRole(role, route.role)} key={route.id}>
                      <ItemHasChild closeMenu={closeMenu} item={route} />
                    </PermissionMenu>
                  );
                }
                if (route && !route.hidden) {
                  return (
                    <PermissionMenu
                      isPermission={
                        process.env.DASHBOARD_TYPE !== 'admin' ||
                        role?.includes?.('*/*') ||
                        route.role === '' ||
                        role?.includes?.(route.role) ||
                        role?.find?.((ro) => route.role?.includes?.(ro))
                      }
                      key={route.id}
                    >
                      <Item closeMenu={closeMenu} item={route} />
                    </PermissionMenu>
                  );
                }
                return '';
              })
              .filter(Boolean)}
        </ul>
      </PerfectScrollbar>
    </div>
  );
}

VerticalMenu.propTypes = {
  setCloseMenu: PropTypes.func,
  closeMenu: PropTypes.bool,
  menuVisibility: PropTypes.bool,
  product: PropTypes.object,
};

export default VerticalMenu;
