import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { Container, Row, Col, Button } from 'reactstrap';
import { useForm } from 'react-hook-form';
import TextInput from '@Components/Input/TextInput';
import { roleSelect } from '@Apis/admin/role';
import useCustomQuery from '@Utils/useCustomQuery';
import SelectInput from '@Components/Input/SelectInput';
import FlatPickrInput from '@Components/Input/FlatPickrInput';
import { userSave } from '@Apis/admin/user';
import PasswordInput from '@Components/Input/PasswordInput';
import useCustomMutation from '@Utils/useCustomMuation';
import { Percent, ContentCopy } from '@mui/icons-material';
import ButtonSubmit from '@Components/ButtonSubmit';
import { toastError, toastSuccess } from '@Utils/toastrHelper';

function Modify({ item, refForm }) {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  const { data: dataRole } = useCustomQuery(roleSelect);
  const { mutate, isSuccess, error } = useCustomMutation(userSave, true);

  const optionsRole = () => {
    const itemDefaul = {
      label: 'Chọn',
      value: 0,
    };
    let options = [];
    if (dataRole && dataRole.length > 0) {
      options = dataRole.map((item) => ({ label: item.name, value: item.id }));
    }
    options.unshift(itemDefaul);
    return options;
  };

  const onSubmit = (formData) => {
    if (item) {
      formData.id = item.id;
    } else {
      formData.status = 'active';
    }
    mutate(formData);
  };

  useEffect(() => {
    if (isSuccess) {
      const message = item ? 'Cập nhập khách hàng thành công' : 'Tạo mới khách hàng thành công';
      toastSuccess(message);
      if (!item) {
        navigate('/employee');
      }
    }
    if (error) {
      toastError(error);
    }
  }, [isSuccess, error]);

  return (
    <Container className="pt-2">
      <form onSubmit={handleSubmit(onSubmit)} ref={refForm}>
        <Row className="mb-3">
          <Col md="4" xs="12">
            <p className="font-size-20 mb-1">Thông tin cá nhân</p>
          </Col>
          <Col md="8" xs="12">
            <Row>
              <Col md="6">
                <TextInput
                  labelCol="12"
                  inputCol="12"
                  type="text"
                  labelClassName="font-size-14 mb-50"
                  labelName="Họ tên *"
                  placeholder="Họ tên"
                  setValue={setValue}
                  name="full_name"
                  register={{ ...register('full_name', { required: true }) }}
                  errors={errors}
                  defaultValue={item ? item.full_name : ''}
                />
              </Col>
              <Col md="6">
                <TextInput
                  labelCol="12"
                  inputCol="12"
                  type="email"
                  labelClassName="font-size-14 mb-50"
                  labelName="Email *"
                  placeholder="Email"
                  setValue={setValue}
                  name="email"
                  register={{ ...register('email', { required: true }) }}
                  errors={errors}
                  defaultValue={item ? item.email : ''}
                />
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <SelectInput
                  labelCol="12"
                  inputCol="12"
                  options={optionsRole()}
                  errors={errors}
                  inputClassName="uppercase"
                  name="base_role"
                  register={{ ...register('base_role', { required: true }) }}
                  labelName="Vị trí *"
                  labelClassName="font-size-14"
                  setValue={setValue}
                  defaultValue={item ? item.base_role : ''}
                />
              </Col>
              <Col md="6">
                <FlatPickrInput
                  name="member_at"
                  register={{ ...register('member_at') }}
                  labelName="Ngày bắt đầu làm việc"
                  placeholder="dd/mm/yy"
                  setValue={setValue}
                  defaultValue={item ? item.member_at : ''}
                  errors={errors}
                />
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <TextInput
                  labelCol="12"
                  inputCol="12"
                  type="phone"
                  labelClassName="font-size-14 mb-50"
                  labelName="Số điện thoại 1 *"
                  placeholder="Số điện thoại 1"
                  setValue={setValue}
                  name="phone"
                  register={{
                    ...register('phone', {
                      required: true,
                      validate: (value) => {
                        if (value.length > 12) {
                          return 'Số điện thoại không hợp lệ.';
                        }
                      },
                    }),
                  }}
                  errors={errors}
                  defaultValue={item ? item.phone : ''}
                />
              </Col>
              <Col md="6">
                <TextInput
                  labelCol="12"
                  inputCol="12"
                  type="phone"
                  labelClassName="font-size-14 mb-50"
                  labelName="Số điện thoại 2 "
                  placeholder="Số điện thoại 2"
                  setValue={setValue}
                  name="backup_phone"
                  register={{
                    ...register('backup_phone', {
                      validate: (value) => {
                        if (value.length > 12) {
                          return 'Số điện thoại không hợp lệ.';
                        }
                      },
                    }),
                  }}
                  errors={errors}
                  defaultValue={item ? item.backup_phone : ''}
                />
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <TextInput
                  labelCol="12"
                  inputCol="12"
                  type="text"
                  labelClassName="font-size-14 mb-50"
                  labelName="Địa chỉ"
                  placeholder="Địa chỉ"
                  setValue={setValue}
                  name="address"
                  register={{ ...register('address') }}
                  errors={errors}
                  defaultValue={item ? item.address : ''}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col md="4" xs="12">
            <p className="font-size-20 mb-1">Tỷ lệ hoa hồng</p>
          </Col>
          <Col md="8" xs="12">
            <Row>
              <Col md="4">
                <TextInput
                  labelCol="12"
                  inputCol="12"
                  type="number"
                  labelClassName="font-size-14 mb-50"
                  labelName="Sale *"
                  placeholder="Tỉ lệ"
                  setValue={setValue}
                  name="sales_commission_rate"
                  register={{ ...register('sales_commission_rate', { required: true }) }}
                  errors={errors}
                  defaultValue={item ? item.sales_commission_rate : ''}
                  icon={<Percent fontSize="14px" />}
                />
              </Col>
              <Col md="4">
                <TextInput
                  labelCol="12"
                  inputCol="12"
                  type="number"
                  labelClassName="font-size-14 mb-50"
                  labelName="KSV *"
                  placeholder="Tỉ lệ"
                  setValue={setValue}
                  name="surveyor_commission_rate"
                  register={{ ...register('surveyor_commission_rate', { required: true }) }}
                  errors={errors}
                  defaultValue={item ? item.surveyor_commission_rate : ''}
                  icon={<Percent fontSize="14px" />}
                />
              </Col>
              <Col md="4">
                <TextInput
                  labelCol="12"
                  inputCol="12"
                  type="number"
                  labelClassName="font-size-14 mb-50"
                  labelName="GDV *"
                  placeholder="Tỉ lệ"
                  setValue={setValue}
                  name="tellers_commission_rate"
                  register={{ ...register('tellers_commission_rate', { required: true }) }}
                  errors={errors}
                  defaultValue={item ? item.tellers_commission_rate : ''}
                  icon={<Percent fontSize="14px" />}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="">
          <Col md="4" xs="12">
            <p className="font-size-20 mb-1">Thông tin đăng nhập</p>
          </Col>
          <Col md="8" xs="12">
            <Row>
              <Col md="6" xs="12">
                <TextInput
                  labelCol="12"
                  inputCol="12"
                  type="text"
                  labelClassName="font-size-14 mb-50"
                  labelName="Tên đăng nhập *"
                  placeholder="Tên đăng nhập *"
                  setValue={setValue}
                  name="username"
                  register={{ ...register('username', { required: true }) }}
                  errors={errors}
                  defaultValue={item ? item.username : ''}
                  icon={<ContentCopy fontSize="14px" />}
                />
              </Col>
              <Col md="6" xs="12">
                <PasswordInput
                  style={{ textTransform: 'none' }}
                  labelCol="12"
                  inputCol="12"
                  type="password"
                  labelClassName="font-size-14 mb-50"
                  inputClassName=""
                  labelName="Mật khẩu *"
                  placeholder="Mật khẩu *"
                  setValue={setValue}
                  name="new_password"
                  register={{ ...register('new_password', { required: !item }) }}
                  errors={errors}
                  defaultValue=""
                />
              </Col>
            </Row>
          </Col>
          <div className="d-line" />
        </Row>
        <Row className="justify-content-center gap-20 py-3">
          <ButtonSubmit
            name="Lưu thay đổi"
            className="px-2"
            size="md"
            color="primary"
            // isLoading={isLoading}
          />
          <Button outline type="button">
            Hủy
          </Button>
        </Row>
      </form>
    </Container>
  );
}

Modify.propTypes = {
  item: PropTypes.object,
  refForm: PropTypes.object,
};

export default Modify;
