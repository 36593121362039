/* eslint-disable react/jsx-wrap-multilines */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Button } from 'reactstrap';
import { Add } from '@mui/icons-material';
import NavigationBar from '@Components/NavigationBar';
import CustomeModal from '@Components/CustomeModal';
import { useDispatch } from 'react-redux';
import FieldInput from '@Views/Client/Recharge/BodyTab/Request/FieldInput';
import TableEmpty from '@Components/DataTableBasic/TableEmpty';
import { toastError, toastSuccess } from '@Utils/toastrHelper';
import { createRole, list, removeRole, saveRoleAssign } from '@Apis/admin/accessRole';
import { hideGlobalLoading, showGlobalLoading } from '@Lib/slices/uiSlice';
import ContentRolePermission from './ContentRolePermission';
import GroupRole from './GroupRole';

const tabs = [
  { id: 1, name: 'Phân quyền nhóm' },
  { id: 2, name: 'Nhóm quyền' },
];
function EmployeeRole() {
  const [{ input, errorMessage }, setInput] = useState({ input: '' });
  const [openAdd, setOpenAdd] = useState(false);
  const [data, setData] = useState([]);
  const [tab, setTab] = useState(2);
  const [rowDelete, setRowDelete] = useState();

  const refContent = useRef();

  const [isEdit, setEdit] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(showGlobalLoading());
    getList();
  }, []);

  const getList = useCallback(() => {
    list()
      .then((res) => setData(res))
      .finally(() => {
        dispatch(hideGlobalLoading());
      });
  }, []);

  const onSave = useCallback(() => {
    if (errorMessage) {
      return;
    }
    if (!input) {
      return setInput({ ...input, errorMessage: 'Vui lòng nhập tên nhóm quyền' });
    }
    dispatch(showGlobalLoading());
    createRole({ name: input })
      .then(() => {
        list()
          .then((res) => setData(res))
          .finally(() => {
            dispatch(hideGlobalLoading());
          });
      })
      .catch(() => {
        dispatch(hideGlobalLoading());
      });
  }, [input, errorMessage]);

  const changeEditRow = useCallback(
    (i) => {
      const newRow = [...data];
      newRow[i] = { ...data[i], isEdit: true };
      setData(newRow);
    },
    [data],
  );

  const onSaveEdit = useCallback(
    (row, name) => {
      console.log('row ==>', row);
      dispatch(showGlobalLoading());
      createRole({ id: row?.id, name })
        .then(getList)
        .catch(() => {
          dispatch(hideGlobalLoading());
        });
    },
    [data],
  );

  const setSave = useCallback(() => {
    if (refContent.current) {
      if (refContent.current?.state?.listRoles?.length) {
        const dataUpdate = refContent.current?.state?.listRoles.map((e) => ({
          id: e.idRole,
          name: e.nameRole,
          assigns: {
            ...e,
            idRole: undefined,
            nameRole: undefined,
          },
        }));
        dispatch(showGlobalLoading());
        saveRoleAssign({ roles: dataUpdate })
          .then(async () => {
            await refContent.current?.handleRemapData?.();
            setEdit(false);
            dispatch(hideGlobalLoading());
            toastSuccess('Sửa phân quyền nhóm thành công');
          })
          .catch((e) => {
            toastError(e);
            dispatch(hideGlobalLoading());
          });
      }
    }
  }, []);

  const onRemove = useCallback((row) => {
    setRowDelete(row);
  }, []);

  const onRemoveRole = useCallback(async () => {
    if (!rowDelete) return;
    dispatch(showGlobalLoading());
    try {
      await removeRole(rowDelete.id);
      await refContent.current?.handleRemapData?.();
      toastSuccess('Xoá nhóm quyền thành công!');
    } catch (e) {
      toastError(e);
    }
    setRowDelete();
    dispatch(hideGlobalLoading());
  }, [rowDelete]);

  return (
    <div className="content-wrapper">
      <div className="d-flex align-items-center justify-content-between header-body mb-2">
        <p className="font-size-28 font-weight-bolder">Phân quyền nhóm</p>
        <div className="d-flex align-items-center gap-20">
          {tab === 1 ? (
            <Button disabled={!isEdit} color="primary" onClick={() => setSave()}>
              <span className="font-size-14">Lưu phân quyền</span>
            </Button>
          ) : null}
          <Button color="primary" onClick={() => setOpenAdd(true)}>
            <Add size={13} color="white" />
            <span className="font-size-14">Thêm nhóm quyền</span>
          </Button>
        </div>
      </div>
      <CustomeModal
        mess="Bạn chắc chắn muốn xoá?"
        isOpen={!!rowDelete}
        buttonName="Đồng ý"
        type="delete"
        confirm={onRemoveRole}
        closeModal={setRowDelete}
      />
      {data?.length ? (
        <div style={{ paddingTop: 10 }}>
          <CustomeModal
            title="Tạo mới nhóm quyền"
            icon={null}
            isOpen={openAdd}
            buttonName="Đồng ý"
            type="confirm"
            confirm={() => {
              onSave();
              setOpenAdd(false);
            }}
            closeModal={() => setOpenAdd(false)}
            mess={
              <div>
                <FieldInput
                  errorMessage={errorMessage}
                  value={input}
                  onChange={(v) => setInput({ errorMessage: '', input: v })}
                  label="Nhập tên nhóm quyền"
                  required
                  placeholder="VD: CEO"
                />
              </div>
            }
          />
          <NavigationBar
            className="custom-bar"
            tabs={tabs}
            activeTab={tab}
            setActiveTab={(v) => {
              setTab(v);
              setEdit(false);
            }}
          />
          {tab === 1 && (
            <ContentRolePermission
              allowEdit
              ref={refContent}
              setEdit={setEdit}
              dataInitListRole={data}
            />
          )}
          {tab === 2 && (
            <GroupRole
              onRemove={onRemove}
              onSave={onSaveEdit}
              changeEditRow={changeEditRow}
              data={data}
            />
          )}
        </div>
      ) : (
        <div
          className="d-flex justify-content-center"
          style={{ background: '#fff', padding: '40px 0' }}
        >
          <TableEmpty
            style={{ minHeight: 250 }}
            text={
              <>
                <div className="mt-2" style={{ fontSize: 20, color: '#504F54', fontWeight: 700 }}>
                  Chưa có nhóm quyền
                </div>
                <div style={{ fontSize: 16, color: '#504F54', fontWeight: 600 }}>
                  Vui lòng thêm nhanh một nhóm quyền để bắt đầu
                </div>
                <div style={{ paddingTop: 33 }}>
                  <FieldInput
                    errorMessage={errorMessage}
                    value={input}
                    onChange={(v) => setInput({ errorMessage: '', input: v })}
                    label="Nhập tên nhóm quyền"
                    required
                    placeholder="VD: CEO"
                  />
                  <div style={{ textAlign: 'center' }}>
                    <Button color="primary" onClick={onSave}>
                      <Add size={13} color="white" />
                      <span className="font-size-14">Thêm nhóm quyền</span>
                    </Button>
                  </div>
                </div>
              </>
            }
          />
        </div>
      )}
    </div>
  );
}

EmployeeRole.propTypes = {};

export default EmployeeRole;
