import React from 'react';
import PropTypes from 'prop-types';
import { TableWrapper } from '../../styled';
import { Table } from 'reactstrap';
import { formatNumber } from '@Utils/helpers';
import ToolTipEdit from './ToolTipEdit';
import { TYPE_PS } from '@Components/Layouts/PrivateLayout';
import './derivative.scss';

function TablePS({ datas, isExport, getData }) {
  let totalDoanhThuHDKD = 0;
  let totalChiPhiHDKD = 0;

  return (
    <TableWrapper style={{ marginLeft: 540 }}>
      <Table>
        <thead>
          <tr>
            <th className="headcol font-size-12" style={{ boxShadow: 'none' }}>
              Chi tiêu
            </th>
            <th
              className="headcol font-size-12 text-center"
              style={{
                textAlign: 'center',
                boxShadow: '7px 0px 15px rgb(153 153 153 / 12%)',
                left: 290,
                margin: 0,
              }}
            >
              Tổng
            </th>
            {datas.headers.map((item) => {
              return (
                <th
                  key={item}
                  style={{
                    textAlign: 'end',
                  }}
                >
                  {item}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {Object.keys(datas.rows).map((keyRow, i) => {
            let total = 0;
            if (keyRow === 'DOANH THU HĐKD') {
              total += datas.rows['Phí GD'].data.reduce((a, b) => a + b.total, 0);
              total += datas.rows['Phí QL VT'].data.reduce((a, b) => a + b.total, 0);
              total += datas.rows['Lãi vay ký quỹ'].data.reduce((a, b) => a + b.total, 0);
              totalDoanhThuHDKD = total;
            } else if (keyRow === 'CHI PHÍ HĐKD') {
              total += datas.rows['Phí GD VSD'].data.reduce((a, b) => a + b.total, 0);
              total += datas.rows['Phí QL vị thế VSD'].data.reduce((a, b) => a + b.total, 0);
              total += datas.rows['Phí GD Kho'].data.reduce((a, b) => a + b.total, 0);
              total += datas.rows['Thuế'].data.reduce((a, b) => a + b.total, 0);
              totalChiPhiHDKD = total;
            } else if (keyRow === 'LỢI NHUẬN HĐKD') {
              total = totalDoanhThuHDKD - totalChiPhiHDKD;
            } else if (keyRow === 'CHI PHÍ CHUNG') {
              total += datas.rows['Phí QL TS ký quỹ'].data.reduce((a, b) => a + b.total, 0);
              total += datas.rows['Phí nộp rút tiền'].data.reduce((a, b) => a + b.total, 0);
              total += datas.rows['Phí hoa hồng KD'].data.reduce((a, b) => a + b.total, 0);
            } else {
              total = datas.rows[keyRow]?.data?.reduce((a, b) => a + b.total, 0);
            }
            const { type, canEdit } = datas.rows[keyRow];
            return (
              <tr
                key={i.toString()}
                style={{
                  background: type === 'title' ? '#375c8a' : '',
                }}
              >
                <th
                  className={`headcol ${type}`}
                  style={{
                    boxShadow: 'none',
                    background: type === 'title' ? '#375c8a' : '',
                    color: type === 'title' ? '#fff' : '',
                  }}
                >
                  {keyRow}
                </th>
                <th
                  className={` headcol headcol-child ${type}`}
                  style={{
                    textAlign: 'end',
                    boxShadow: '7px 0px 15px rgb(153 153 153 / 12%)',
                    left: 290,
                    margin: 0,
                    background: type === 'title' ? '#375c8a' : '',
                    color: type === 'title' ? '#fff' : '',
                    paddingRight: 100,
                  }}
                >
                  {formatNumber(total)}
                </th>
                {datas.rows[keyRow]?.data?.map((item, index) => {
                  if (canEdit && !isExport) {
                    return (
                      <td key={index.toString()} className="text-center text-primary">
                        <div
                          className="d-flex align-items-center justify-content-end"
                          onClick={() => {}}
                          style={{ cursor: 'pointer' }}
                        >
                          {item.label ? (
                            item.total
                          ) : (
                            <ToolTipEdit
                              style={{ justifyContent: 'end' }}
                              name={datas.rows[keyRow].name}
                              label={keyRow}
                              defaultValue={item.total}
                              date={item.report_at}
                              tradeType={TYPE_PS}
                              refetch={getData}
                            />
                          )}
                        </div>
                      </td>
                    );
                  }
                  let totalCol = 0;
                  if (keyRow === 'DOANH THU HĐKD') {
                    totalCol += datas.rows['Phí GD'].data?.[index]?.total || 0;
                    totalCol += datas.rows['Phí QL VT'].data?.[index]?.total || 0;
                    totalCol += datas.rows['Lãi vay ký quỹ'].data?.[index]?.total || 0;
                  } else if (keyRow === 'CHI PHÍ HĐKD') {
                    totalCol += datas.rows['Phí GD VSD'].data?.[index]?.total || 0;
                    totalCol += datas.rows['Phí QL vị thế VSD'].data?.[index]?.total || 0;
                    totalCol += datas.rows['Phí GD Kho'].data?.[index]?.total || 0;
                    totalCol += datas.rows['Thuế'].data?.[index]?.total || 0;
                  } else if (keyRow === 'LỢI NHUẬN HĐKD') {
                    totalCol += datas.rows['Phí GD'].data?.[index]?.total || 0;
                    totalCol += datas.rows['Phí QL VT'].data?.[index]?.total || 0;
                    totalCol += datas.rows['Lãi vay ký quỹ'].data?.[index]?.total || 0;

                    totalCol -= datas.rows['Phí GD VSD'].data?.[index]?.total || 0;
                    totalCol -= datas.rows['Phí QL vị thế VSD'].data?.[index]?.total || 0;
                    totalCol -= datas.rows['Phí GD Kho'].data?.[index]?.total || 0;
                    totalCol -= datas.rows['Thuế'].data?.[index]?.total || 0;
                  } else {
                    totalCol = item.total;
                  }
                  return (
                    <td
                      key={index.toString()}
                      className="long"
                      style={{
                        fontWeight: type === 'title' ? 600 : 400,
                        color: type === 'title' ? '#fff' : '',
                        textAlign: 'end',
                      }}
                    >
                      {formatNumber(totalCol)}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </Table>
    </TableWrapper>
  );
}

TablePS.propTypes = {
  datas: PropTypes.any,
  getData: PropTypes.any,
  isExport: PropTypes.bool,
};

export default TablePS;
