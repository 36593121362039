import { localTransfer } from '@Apis/client/payment-request';
import ButtonSubmit from '@Components/ButtonSubmit';
import DropdownSelect from '@Components/DropdownSelect';
import { formatNumber } from '@Utils/helpers';
import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import useCustomMutation from '@Utils/useCustomMuation';
import { useNavigate } from 'react-router-dom';
import { toastError, toastSuccess } from '@Utils/toastrHelper';
import useCustomQuery from '@Utils/useCustomQuery';
import { accountsHome } from '@Apis/client/home';
import FieldInput from '../FieldInput';
import './styles.scss';
import { author } from '@Apis/auth';
import { useEffect } from 'react';

function MoneyTransfer({ refechDateHistory, account }) {
  const productType = useSelector((state) => state.ui.productType);
  const [values, setValue] = useState({});
  const [withdrawAvailable, setwithdrawAvailable] = useState(account.can_withdraw_amount || 0);
  const [receiveOptions, setReceiveOptions] = useState([]);
  const { mutate } = useCustomMutation(localTransfer, true);
  const navigate = useNavigate();
  const { data } = useCustomQuery(accountsHome);
  const { accounts = [] } = data || {};

  const cs = accounts.find((e) => e.trade_type === 'base')?.id;
  const ps = accounts.find((e) => e.trade_type === 'derivative')?.id;
  const tplus = accounts.find((e) => e.trade_type === 'spot')?.id;

  const options = [
    {
      label: 'Cơ sở',
      value: cs,
    },
    {
      label: 'T+',
      value: tplus,
    },
    {
      label: 'Phái sinh',
      value: ps,
    },
  ];

  const submit = useCallback(() => {
    mutate(values, {
      onSuccess: () => {
        navigate(`/${productType}/recharge?page=1`);
        setValue({});
        refechDateHistory();
        toastSuccess('Cập nhật yêu cầu thành công');
      },
      onError: toastError,
    });
  }, [values]);

  useEffect(() => {
    setValue({ source_customer_id: account.id });
  }, [accounts]);

  useEffect(() => {
    setReceiveOptions(options.filter(e => e.value != values.source_customer_id));
  }, [values]);

  return (
    <div className="re-charge-money-transfer">
      <div className="d-flex form-input-transfer">
        <FieldInput
          style={{ maxWidth: 200 }}
          label="Tiền đi"
          labelDropdown="Chuyển đi: "
          required
          Component={DropdownSelect}
          options={options}
          valueSelected={values.source_customer_id}
          setValueSelected={(_, v) => {
            setwithdrawAvailable(accounts.filter(acc => acc.id == v)[0].withdraw_available || 0);
            setValue((pre) => ({ ...pre, source_customer_id: v }));
          }}
        />
        <FieldInput
          style={{ maxWidth: 200 }}
          label="Tiền đến"
          labelDropdown="Chuyển đến: "
          labelShow
          required
          Component={DropdownSelect}
          options={receiveOptions}
          valueSelected={values.target_customer_id}
          setValueSelected={(_, v) => {
            setValue((pre) => ({ ...pre, target_customer_id: v }));
          }}
        />
        <FieldInput
          style={{ maxWidth: 200 }}
          label="Số tiền cần chuyển"
          required
          placeholder="Nhập số tiền"
          value={values.amount}
          type="number"
          max={withdrawAvailable}
          onChange={(v) => setValue((pre) => ({ ...pre, amount: v }))}
        />
        <div className="w-100">
          <div>Số tiền tối đa được chuyển</div>
          <div className="mt-2 fw-600">
            {formatNumber(withdrawAvailable > 0 ? withdrawAvailable : 0, '--')}
          </div>
        </div>
      </div>
      <div className="w-100 text-align-center mt-2 mb-1">
        <ButtonSubmit
          onClick={submit}
          style={{ height: 44, width: 200 }}
          color="primary"
          size="sm"
          name="Chuyển tiền"
        />
      </div>
    </div>
  );
}

MoneyTransfer.propTypes = {
  refechDateHistory: PropTypes.func,
  account: PropTypes.any,
};

export default MoneyTransfer;
