import React, { useRef, useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { InputGroup, InputGroupAddon, Input, InputGroupText } from 'reactstrap';
import { Search } from '@mui/icons-material';
import { InputSearchWrapper } from './styles';

function InputSearch({
  placeholder,
  searchKey,
  setSearchKey,
  name,
  width,
  className,
  style,
  minWidth,
}) {
  const typingRef = useRef(null);
  const [value, setValue] = useState('');

  const onChange = useCallback((newValue) => {
    setValue(newValue);
    if (typingRef.current) {
      clearTimeout(typingRef.current);
    }
    typingRef.current = setTimeout(() => {
      setSearchKey(name, newValue);
    }, 300);
  }, []);

  useEffect(() => {
    if (searchKey) {
      setValue(searchKey);
    }
  }, [searchKey]);

  return (
    <InputSearchWrapper style={{ width, minWidth }} className={className}>
      <InputGroup className="input-group-merge" type="search" name="Search">
        <Input
          style={style}
          className=""
          placeholder={placeholder}
          name={name}
          value={value}
          onChange={(e) => onChange(e.target.value)}
          type="search"
        />
        <InputGroupAddon addonType="append">
          <InputGroupText className="cursor-pointer">
            <Search size={14} />
          </InputGroupText>
        </InputGroupAddon>
      </InputGroup>
    </InputSearchWrapper>
  );
}

InputSearch.propTypes = {
  placeholder: PropTypes.string,
  searchKey: PropTypes.string,
  setSearchKey: PropTypes.func,
  name: PropTypes.string,
  width: PropTypes.string,
  minWidth: PropTypes.number,
  className: PropTypes.string,
  style: PropTypes.object,
};

export default InputSearch;
