/* eslint-disable indent */
import React from 'react';
import { formatNumber, parseNumber, parsePrice } from '@Utils/helpers';
import { LockOutlined, Edit } from '@mui/icons-material';
import { NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import LockOpenIcon from '@mui/icons-material/LockOpen';

export const columns = ({ setItemUpdate, activeTab, onEdit }) => {
  return [
    {
      name: 'MÃ CK',
      cell: (row) => <div className="font-weight-bolder">{row.stock_id || row.id}</div>,
      center: true,
      sortable: true,
      sortField: 'id',
    },
    {
      name: 'GIÁ HIỆN TẠI',
      cell: (row) => (
        <div className="w-100 text-align-end">
          {activeTab == 3
            ? parseNumber(typeof row.price === 'number' ? row.price : row.stock?.price)
            : parsePrice(typeof row.price === 'number' ? row.price : row.stock?.price)}
        </div>
      ),
      right: true,
      sortable: true,
      sortField: 'price',
      width: '150px',
    },
    {
      name: activeTab == 3 ? 'SỐ VỊ THẾ MỞ' : 'TỔNG KHỐI LƯỢNG',
      cell: (row) => activeTab != 2 ? (
        <div className="w-100 text-align-end">
          {formatNumber(
            row.stock?.total_quantity || row.totalQuantity?.quantity || row?.quantity,
            0,
          )}
        </div>
      ):(
          <div className="w-100 text-align-end">
              {formatNumber(
                  row?.quantity,
                  0,
              )}
          </div>
      ),
      center: true,
      width: '170px',
      sortable: true,
      sortField: 'total_quantity',
    },
      activeTab === 1
          ? {
              name: 'Tỷ lệ ký quỹ',
              cell: (row) => (
                  <div className="w-100 text-align-end">
                      {parseNumber(row?.margin_rate)}%
                  </div>
              ),
              right: true,
              sortable: true,
              sortField: 'price',
              width: '150px',
          }
          : undefined,activeTab === 1
          ? {
              name: 'RTT',
              cell: (row) => (
                  <div className="w-100 text-align-end">
                      {parseNumber(row?.call_rate)}%
                  </div>
              ),
              right: true,
              sortable: true,
              sortField: 'price',
              width: '150px',
          }
          : undefined,
    activeTab === 2
      ? {
          name: 'KL đã GD',
          cell: (row) => (
            <div className="w-100 text-align-end">{formatNumber(row.total_trade_quantity, 0)}</div>
          ),
          center: true,
          width: '170px',
          sortable: true,
          sortField: 'total_quantity',
        }
      : undefined,
    activeTab !== 3
      ? {
          name: 'KL KHẢ DỤNG',
          cell: (row) => (
            <div className="w-100 text-align-end">
              {formatNumber(
                row.available_quantity ||
                  (row.totalPendingQuantity && row.totalQuantity
                    ? row.totalQuantity.quantity - row.totalPendingQuantity?.quantity
                    : row.totalQuantity?.quantity),
                0,
              )}
            </div>
          ),
          right: true,
          sortable: true,
          sortField: 'quantity_available',
          width: '150px',
        }
      : undefined,
    activeTab !== 3 && activeTab !== 2
      ? {
          name: 'TỔNG GIÁ TRỊ',
          cell: (row) => (
            <div className="w-100 text-align-end">
              {formatNumber(
                row.stock?.total_quantity || row.price * (row.totalQuantity?.quantity || 0),
                '--',
              )}
            </div>
          ),
          right: true,
          width: '150px',
          sortable: true,
          sortField: 'total_value',
        }
      : undefined,
    activeTab !== 2
      ? {
          name: 'SỐ KH SỞ HỮU',
          cell: (row) => (
            <div className="text-primary">
              {formatNumber(row.stock?.customer_count || row.customerCount?.count, 0)}
            </div>
          ),
          right: true,
          width: '140px',
          sortable: true,
          sortField: 'customer_count',
        }
      : undefined,
      activeTab !== 3 && activeTab !== 2
      ? {
          name: 'Sàn',
          cell: (row) => <div>{row.exchange || row.stock?.exchange}</div>,
          center: true,
        }
      : undefined,
    activeTab === 2
      ? {
          name: 'Kho',
          cell: (row) => <div>{row.purchaseAccount?.name}</div>,
          center: true,
          width: '170px',
          sortable: true,
        }
      : undefined,
    activeTab === 2
      ? {
          name: 'Bắt đầu',
          cell: (row) => (
            <div className="w-100 text-align-end">
              {row.valid_at ? dayjs.unix(row.valid_at).format('DD/MM/YYYY') : '--'}
            </div>
          ),
          center: true,
          width: '170px',
          sortable: true,
          sortField: 'total_quantity',
        }
      : undefined,
    activeTab === 2
      ? {
          name: 'Kết thúc',
          cell: (row) => (
            <div className="w-100 text-align-end">
              {row.valid_to ? dayjs.unix(row.valid_to).format('DD/MM/YYYY') : '--'}
            </div>
          ),
          center: true,
          width: '170px',
          sortable: true,
          sortField: 'total_quantity',
        }
      : undefined,
    {
      name: '',
      center: true,
      cell: (row) => (
        <Link
          className="d-flex align-items-center"
          to={`/stock/${row.trade_type}/${activeTab === 2 ? row.stock_id : row.id}`}
        >
          <span className="text-primary font-weight-bolder mr-50">Chi tiết</span>
          <img src="/images/icon/arrow-box.svg" alt="view-detail" />
        </Link>
      ),
    },
    activeTab === 2
      ? undefined
      : {
          name: '',
          center: true,
          cell: (row) => (
            <div className="d-flex" style={{ gap: 16 }}>
              {row.status === 'active' ? (
                <>
                  <Edit
                    onClick={() => onEdit(row)}
                    style={{ color: '#7367F0', cursor: 'pointer' }}
                    fontSize="16px"
                  />
                  <LockOutlined
                    onClick={() => setItemUpdate(row)}
                    color="error"
                    fontSize="16px"
                    style={{ cursor: 'pointer' }}
                  />
                </>
              ) : (
                <>
                  <Edit
                    onClick={() => onEdit(row)}
                    style={{ color: '#7367F0', cursor: 'pointer' }}
                    fontSize="16px"
                  />
                  <LockOpenIcon
                    onClick={() => setItemUpdate(row)}
                    color="success"
                    fontSize="16px"
                    style={{ cursor: 'pointer' }}
                  />
                </>
              )}
            </div>
          ),
        },
  ].filter((e) => e);
};
