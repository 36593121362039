import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Modal, Container, Row, Col, Button } from 'reactstrap';
import { Close, Percent } from '@mui/icons-material';
import { useForm } from 'react-hook-form';
import SearchCustomer from '@Components/Input/SearchCustomer';
import FlatPickrInput from '@Components/Input/FlatPickrInput';
import TextInput from '@Components/Input/TextInput';
import ButtonSubmit from '@Components/ButtonSubmit';
import { saveLoan } from '@Apis/admin/loan';
import dayjs from 'dayjs';
import { toastError, toastSuccess } from '@Utils/toastrHelper';
import TradeSelectInput from '@Components/Input/TradeSelectInput';
import useCustomMutation from '@Utils/useCustomMuation';
import { PopUpModifyWrapper } from './PopUpModifyStyles';
import { STATUS } from '../constant';

function PopUpModify({ isOpen, closeModal, setIsRefreshStatus, item }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm();

  const { mutate, isSuccess, error } = useCustomMutation(saveLoan, true);

  const onSubmit = (formData) => {
    let id;
    const type = 'loan';
    const {
      amount,
      customer_id,
      interest_rate,
      start_at,
      due_at,
      note,
      status = 'unpaid',
    } = formData;
    if (item) {
      id = item.id;
    }
    mutate({
      amount,
      customer_id,
      due_at: due_at ? dayjs.unix(due_at).endOf('d').unix() : undefined,
      interest_rate,
      start_at,
      note,
      type,
      id,
      status,
    });
  };

  useEffect(() => {
    if (isSuccess) {
      toastSuccess('Thêm mới khoản vay thành công');
      setIsRefreshStatus({
        state: true,
      });
      closeModal();
    }
    if (error) {
      toastError(error);
    }
  }, [isSuccess, error]);

  return (
    <Modal isOpen={isOpen} toggle={closeModal} style={{ maxWidth: '969px' }}>
      <PopUpModifyWrapper>
        <form className="" onSubmit={handleSubmit(onSubmit)}>
          <div className="d-flex align-items-center justify-content-between py-1">
            <h3 className="font-weight-bolder">Thêm mới khoản vay</h3>
            <div className="d-flex align-items-center justify-content-between gap-20">
              {item && (
                <TradeSelectInput
                  setValue={setValue}
                  options={STATUS}
                  register={{ ...register('status', { required: true }) }}
                  name="status"
                  defaultValue={item.status}
                />
              )}
              <Close onClick={closeModal} />
            </div>
          </div>
          <div className="">
            <Container>
              <Row className="pb-3">
                <Col>
                  <SearchCustomer
                    labelName="Khách hàng *"
                    labelCol="12"
                    inputCol="12"
                    labelClassName="font-size-14 mb-50"
                    name="customer_id"
                    register={{ ...register('customer_id', { required: true }) }}
                    errors={errors}
                    setValue={setValue}
                    defaultValue={item?.customer_id}
                  />
                </Col>
                <Col>
                  <TextInput
                    labelCol="12"
                    inputCol="12"
                    type="price"
                    labelClassName="font-size-14 mb-50"
                    labelName="Số tiền *"
                    placeholder="Số tiền"
                    setValue={setValue}
                    name="amount"
                    register={{ ...register('amount', { required: true }) }}
                    errors={errors}
                    defaultValue={item ? item.amount : ''}
                  />
                </Col>
                <Col>
                  <TextInput
                    labelCol="12"
                    inputCol="12"
                    type="number"
                    labelClassName="font-size-14 mb-50"
                    labelName="Lãi suất (ngày) *"
                    placeholder="Lãi suất (ngày)"
                    setValue={setValue}
                    name="interest_rate"
                    register={{ ...register('interest_rate', { required: true }) }}
                    errors={errors}
                    defaultValue={item ? item.interest_rate : ''}
                    icon={<Percent fontSize="14px" />}
                  />
                </Col>
              </Row>
              <Row className="pb-3">
                <Col>
                  <FlatPickrInput
                    name="start_at"
                    register={{ ...register('start_at', { required: true }) }}
                    labelName="Ngày cấp vay *"
                    placeholder="dd/mm/yy"
                    setValue={setValue}
                    defaultValue={item ? item.start_at : ''}
                    errors={errors}
                  />
                </Col>
                <Col>
                  <FlatPickrInput
                    name="due_at"
                    register={{
                      ...register('due_at', {
                        required: true,
                        validate: (value) => {
                          const startAt = watch('start_at');
                          if (value < startAt) {
                            return 'Ngày đáo hạn phải sau Ngày cấp vay';
                          }
                        },
                      }),
                    }}
                    labelName="Ngày đáo hạn *"
                    placeholder="dd/mm/yy"
                    setValue={setValue}
                    defaultValue={item ? item.due_at : ''}
                    errors={errors}
                  />
                </Col>
                <Col>
                  <TextInput
                    labelCol="12"
                    inputCol="12"
                    type="number"
                    labelClassName="font-size-14 mb-50"
                    labelName="Thời hạn (ngày)"
                    defaultValue=""
                    disabled
                  />
                </Col>
                <Col>
                  <TextInput
                    labelCol="12"
                    inputCol="12"
                    type="number"
                    labelClassName="font-size-14 mb-50"
                    labelName="Thời gian còn lại"
                    defaultValue=""
                    disabled
                  />
                </Col>
              </Row>
              <Row className="pb-3">
                <Col>
                  <TextInput
                    labelCol="12"
                    inputCol="12"
                    type="number"
                    labelClassName="font-size-14 mb-50"
                    labelName="Lãi chưa tất toán"
                    defaultValue=""
                    disabled
                  />
                </Col>
                <Col>
                  <TextInput
                    labelCol="12"
                    inputCol="12"
                    type="number"
                    labelClassName="font-size-14 mb-50"
                    labelName="Lãi đã tất toán"
                    defaultValue=""
                    disabled
                  />
                </Col>
                <Col>
                  <TextInput
                    labelCol="12"
                    inputCol="12"
                    type="number"
                    labelClassName="font-size-14 mb-50"
                    labelName="Lãi đã thanh toán"
                    errors={errors}
                    disabled
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <TextInput
                    inputClassName="uppercase"
                    labelCol="12"
                    inputCol="12"
                    type="textarea"
                    labelClassName="font-size-14 mb-50"
                    labelName="Ghi chú"
                    setValue={setValue}
                    register={{ ...register('note') }}
                    errors={errors}
                    defaultValue={item?.note}
                  />
                </Col>
              </Row>
              <Row className="justify-content-center gap-20 py-2">
                <ButtonSubmit
                  name="Lưu"
                  className="px-2"
                  size="md"
                  color="primary"
                  // isLoading={isLoading}
                />
                <Button type="button" onClick={() => closeModal()}>
                  Hủy
                </Button>
              </Row>
            </Container>
          </div>
        </form>
      </PopUpModifyWrapper>
    </Modal>
  );
}

PopUpModify.propTypes = {
  isOpen: PropTypes.bool,
  closeModal: PropTypes.func,
  setIsRefreshStatus: PropTypes.func,
  item: PropTypes.object,
};

export default PopUpModify;
