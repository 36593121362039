import styled from 'styled-components';

export const FlatPickrWrapper = styled.div`
  background: #f7f7f7;
  border-radius: 4px;

  color: #828282;
  font-size: 14px;
  display: flex;
  align-items: center;
  cursor: pointer;
  white-space: nowrap;
  &.flatpickr-input-custom {
    height: 2.5rem;
    padding: 8px 12px;
  }
`;
