import { formatNumber, formatVND } from '@Utils/helpers';
import React from 'react';

export const columns = () => {
  return [
    {
      name: 'ID',
      cell: (row) => row.protectedCustomer?.code,
      width: '110px',
      fixed: 'left',
      center: true,
    },
    {
      name: 'HỌ TÊN',
      cell: (row) => <div className="uppercase">{row.protectedCustomer?.username}</div>,
      width: '180px',
      fixed: 'left',
    },
    {
      name: 'TỔNG KL SỞ HỮU',
      cell: (row) => formatNumber(row.stock?.total_quantity, '--'),
      width: '150px',
      center: true,
    },
    {
      name: 'T+',
      cell: (row) => formatVND(row.t0),
      width: '150px',
      center: true,
    },
    {
      name: 'T1',
      cell: (row) => formatVND(row.t1),
      width: '150px',
      center: true,
    },
    // {
    //   name: 'T2',
    //   cell: (row) => formatVND(row.t2),
    //   width: '150px',
    //   center: true,
    // },
    {
      name: 'TỔNG KL khả dụng',
      cell: (row) => formatNumber(row.stock?.available_quantity, '--'),
      width: '170px',
      center: true,
    },
    {
      name: 'TỈ LỆ CALL',
      cell: (row) => `${row.protectedCustomer?.call_rate} %`,
      width: '120px',
      center: true,
    },
    {
      name: 'TỔNG GTCK',
      cell: (row) => formatVND(row.protectedCustomer?.total_stock_value),
      width: '160px',
      center: true,
    },
    {
      name: 'NAV',
      cell: (row) => formatVND(row.protectedCustomer?.current_nav),
      width: '160px',
      fixed: 'right',
    },
  ];
};
