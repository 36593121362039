/* eslint-disable import/no-cycle */
import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { io } from 'socket.io-client';
import { useSearchParams } from 'react-router-dom';
import DropdownSelect from '@Components/DropdownSelect';
import InputSearch from '@Components/InputSearch';
import DataTableBasic from '@Components/DataTableBasic';
import { transactionHistory, cancelTransaction } from '@Apis/client/transaction';
import NavigationBar from '@Components/NavigationBar';
import CustomeModal from '@Components/CustomeModal';
import useCustomMutation from '@Utils/useCustomMuation';
import { toastError, toastSuccess } from '@Utils/toastrHelper';
import { TRADE_STATUS, HISTORY_TABS } from '../constants';
import { columns } from './TradeHistoryColumns';
import TradeHistoryPopup from './TradeHistoryPopup';

function TradeHistory({ idRefresh, accountId }) {
  const [activeTabId, setActiveTabId] = useState(1);
  const [itemDelete, setItemDelete] = useState(null);
  const [itemEdit, setItemEdit] = useState(null);
  const [data, setData] = useState({});

  const [searchParams] = useSearchParams();
  const page = searchParams.get('page') || 1;

  let filDate_range;
  if (activeTabId === 1) {
    const now = dayjs();
    const fromTime = dayjs(now.format('YYYY-MM-DD')).unix();
    const toTime = dayjs().unix();
    filDate_range = `${fromTime}-${toTime}`;
  }

  const [filter, setFilter] = useState({
    stock_id: '',
    status: '',
    date_range: filDate_range,
  });

  const { mutate, isSuccess, error } = useCustomMutation(cancelTransaction, true);
  const filterOnChange = (name, value) => {
    setFilter((prevValues) => {
      return {
        ...prevValues,
        [name]: value,
      };
    });
  };

  useEffect(() => {
    let date_range;
    if (activeTabId === 1) {
      const now = dayjs();
      const fromTime = dayjs(now.format('YYYY-MM-DD')).unix();
      const toTime = dayjs().add(10, 'second').unix();
      date_range = `${fromTime}-${toTime}`;
    }
    const token = localStorage.getItem(process.env.TOKEN);
    const socket = io(`${process.env.BACKEND_SOCKET}/customer/trade/transactions`, {
      query: { token },
      transports: ['websocket'],
    });
    socket.on('connect', () => {
      socket.emit('register', 'your-client-id', {
        ...filter,
        page,
        per_page: 5,
        date_range,
        customer_id: accountId,
      });
    });
    socket.on('data', ({ data: dataSocket }) => {
      setData(dataSocket);
    });

    return () => {
      socket.disconnect();
    };
  }, [filter, accountId, activeTabId, page]);

  const loadData = useCallback(() => {
    let date_range;
    if (activeTabId === 1) {
      const now = dayjs();
      const fromTime = dayjs(now.format('YYYY-MM-DD')).unix();
      const toTime = dayjs().add(10, 'second').unix();
      date_range = `${fromTime}-${toTime}`;
    }
    transactionHistory({ ...filter, page, per_page: 5, date_range, customer_id: accountId })
      .then((res) => {
        setData(res);
      })
      .catch(toastError);
  }, [filter, page, activeTabId, accountId]);

  useEffect(() => {
    loadData();
  }, [idRefresh, filter.date_range, page, filter.status, filter.status, activeTabId, accountId]);

  useEffect(() => {
    if (isSuccess) {
      if (itemDelete.status === 'in_process') {
        toastSuccess('Yêu cầu hủy của bạn đã được gửi');
      } else {
        toastSuccess('Huỷ lệnh thành công');
      }
      setItemDelete(null);
    }
    if (error) {
      toastError(error);
      setItemDelete(null);
    }
  }, [isSuccess, error]);

  const onConfirmDelete = () => {
    mutate(
      { id: itemDelete.id, customer_id: accountId },
      {
        onSuccess: loadData,
      },
    );
  };

  return (
    <div className="bg-white p-2 mt-2 trade-history">
      <div className="header-title">
        <p className="font-size-20 font-weight-bold">Sổ lệnh</p>
        <div className="filter-search-trade">
          <DropdownSelect
            name="status"
            style={{ height: 43.339 }}
            options={TRADE_STATUS}
            valueSelected={filter.status}
            setValueSelected={filterOnChange}
          />
          <InputSearch
            placeholder="Nhập mã CK"
            searchKey={filter.stock_id}
            name="stock_id"
            setSearchKey={filterOnChange}
            width="240px"
          />
        </div>
      </div>
      <NavigationBar tabs={HISTORY_TABS} activeTab={activeTabId} setActiveTab={setActiveTabId} />
      <DataTableBasic
        columns={columns({
          activeTabId,
          setItemEdit,
          setItemDelete,
        })}
        data={data}
        page={Number(page)}
      />
      <CustomeModal
        isOpen={itemDelete !== null}
        closeModal={() => setItemDelete(null)}
        buttonName="Đồng ý"
        confirm={onConfirmDelete}
        type="delete"
        mess={`Bạn muốn huỷ lệnh này ${itemDelete?.stock_id}`}
      />
      {itemEdit && (
        <TradeHistoryPopup
          customer_id={accountId}
          isOpen={itemEdit !== null}
          closeModal={() => setItemEdit(null)}
          refetch={loadData}
          item={itemEdit}
        />
      )}
    </div>
  );
}

TradeHistory.propTypes = {
  idRefresh: PropTypes.bool,
  accountId: PropTypes.any,
};

export default TradeHistory;
