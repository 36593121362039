import React from 'react';
import PropTypes from 'prop-types';
import { usePagination } from '@Utils/usePagination';
import { Pagination, PaginationItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import shortid from 'shortid';
import { ChevronRight, ChevronLeft } from 'react-feather';

function PaginateEffect({ data, page, setPage }) {
  const { total_page: total, page: pageProps } = data;
  const current_page = Number(pageProps);
  const paginationRange = usePagination({
    total,
    siblingCount: 1,
    current_page,
  });

  const getPageLink = (index) => {};

  return (
    total > 1 && (
      <div className="d-flex justify-content-end align-items-center custom-paginate pr-75">
        <Pagination>
          <PaginationItem disabled={current_page === 1}>
            <NavLink
              className="page-link"
              aria-label="First"
              onClick={() => setPage(current_page - 1)}
            >
              <span>
                <ChevronLeft />
              </span>
            </NavLink>
          </PaginationItem>
          {paginationRange.map((pagi) => {
            if (pagi === 'DOTS') {
              return (
                <PaginationItem key={shortid.generate(3)}>
                  <NavLink className="page-link">...</NavLink>
                </PaginationItem>
              );
            }
            return (
              <PaginationItem active={pagi === current_page} key={pagi}>
                <NavLink className="page-link" onClick={() => setPage(pagi)}>
                  {pagi}
                </NavLink>
              </PaginationItem>
            );
          })}
          <PaginationItem disabled={current_page === total}>
            <NavLink
              className="page-link"
              onClick={() => setPage(current_page + 1)}
              aria-label="Last"
            >
              <span>
                <ChevronRight />
              </span>
            </NavLink>
          </PaginationItem>
        </Pagination>
      </div>
    )
  );
}

PaginateEffect.propTypes = {
  data: PropTypes.object,
  page: PropTypes.number,
  setPage: PropTypes.func,
};

export default PaginateEffect;
