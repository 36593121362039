import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { paymentIndex } from '@Apis/admin/paymentRequest';
import useCustomQuery from '@Utils/useCustomQuery';
import DataTableBasic from '@Components/DataTableBasic';
import { columns } from './TransactionRequestPaymentColumns';

function TransactionRequestPayment({ filter: filterProps, accountId, setWrapFilter }) {
  const [filter, setFilter] = useState({
    customer_id: accountId,
    per_page: 12,
  });

  useEffect(() => {
    setWrapFilter && setWrapFilter((pre) => ({ ...pre, ...filterProps, ...filter }));
  }, [filter]);

  const [page, setPage] = useState(0);
  useEffect(() => {
    if (page) {
      setFilter({ ...filter, page });
    }
  }, [page]);

  const setOrderBy = useCallback((column, type = '') => {
    setFilter((prevValues) => {
      return {
        ...prevValues,
        sort_by: column.sortField,
        order: type.toUpperCase(),
      };
    });
  }, []);

  const { data: dataPayment } = useCustomQuery(paymentIndex, { ...filterProps, ...filter });

  return (
    <div>
      <DataTableBasic
        onSort={setOrderBy}
        columns={columns()}
        data={dataPayment}
        isPageEffect
        page={page}
        setPage={setPage}
      />
    </div>
  );
}

TransactionRequestPayment.propTypes = {
  filter: PropTypes.object,
  accountId: PropTypes.any,
  setWrapFilter: PropTypes.any,
};

export default TransactionRequestPayment;
