import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';

function ButtonSubmit(props) {
  const {
    color,
    name,
    isLoading,
    className,
    size,
    styleText,
    outline,
    style,
    onClick,
    type = 'submit',
    icon,
    wrapClassName,
    disabled,
  } = props;
  return (
    <Button
      className={wrapClassName}
      onClick={onClick}
      style={style}
      outline={!!outline}
      color={color}
      type={onClick ? '' : type}
      size={size}
      disabled={isLoading || disabled}
    >
      {!isLoading ? (
        <span className={className} style={styleText}>
          {icon && <span style={{ marginRight: 5 }}>{icon}</span>}
          {name}
        </span>
      ) : (
        <>
          <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
          <span className="ml-50 font-size-14">Loading ...</span>
        </>
      )}
    </Button>
  );
}

ButtonSubmit.propTypes = {
  color: PropTypes.string,
  name: PropTypes.string,
  isLoading: PropTypes.bool,
  className: PropTypes.string,
  wrapClassName: PropTypes.string,
  size: PropTypes.string,
  styleText: PropTypes.object,
  style: PropTypes.object,
  outline: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  type: PropTypes.string,
  icon: PropTypes.any,
};

export default ButtonSubmit;
