/* eslint-disable import/no-cycle */
import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Button, Input, Modal } from 'reactstrap';
import { AddCircleOutline } from '@mui/icons-material';
import useCustomMutation from '@Utils/useCustomMuation';
import { addList } from '@Apis/admin/stock';
import { toastError, toastSuccess } from '@Utils/toastrHelper';
import Title from '@Components/Title';
import FieldInput from '@Views/Client/Recharge/BodyTab/Request/FieldInput';
import ModalUpdateTPlus from './ModalUpdateTPlus';

function AddStock({ refetch, activeTab, rowEdit }) {
  const [instrument, setInstrument] = useState('');
  const [margin_rate, setMarginRate] = useState(0);
  const [call_rate, setCallRate] = useState(0);
  const [isOpen, setOpen] = useState(false);
  const { mutate, isSuccess, error } = useCustomMutation(addList, true);

  const addInstrument = () => {
    mutate({ id: instrument, margin_rate, call_rate });
  };
  useEffect(() => {
    if (isSuccess) {
      toastSuccess('Thêm mã CK thái thành công');
      refetch();
    }
    if (error) {
      toastError(error);
    }
  }, [isSuccess, error]);

  useEffect(() => {
    setInstrument(rowEdit?.id);
    setMarginRate(rowEdit?.margin_rate || 0);
    setCallRate(rowEdit?.call_rate || 0);
    setOpen(!!rowEdit);
  }, [rowEdit?.id]);

  const renderTitle = useCallback(() => {
    if (activeTab === 2) {
      return 'Cập nhật danh mục mã T+';
    }
    if (activeTab === 3) {
      return 'Cập nhật Hợp đồng Phái Sinh';
    }
    return 'Thêm mã CK được phép GD';
  }, [activeTab]);

  return (
    <div className="" style={{ margin: '0 15px' }}>
      <Button color="primary" onClick={() => setOpen(true)}>
        <AddCircleOutline color="primay" fontSize="14px" />
        <span className="font-size-13 ml-25">{renderTitle()}</span>
      </Button>
      {activeTab !== 1 ? (
        <ModalUpdateTPlus
          isPS={activeTab === 3}
          isOpen={isOpen}
          onCancel={() => setOpen(false)}
          refetch={refetch}
        />
      ) : (
        <Modal
          isOpen={isOpen}
          toggle={() => setOpen(false)}
          className="text-center"
          style={{ width: 600 }}
        >
          <div className="container">
            <Title name={`${rowEdit ? 'Sửa' : 'Thêm'} mã chứng khoán`} />
            <div className="mt-3 mb-1 d-flex" style={{ gap: 16 }}>
              <FieldInput
                onChange={setInstrument}
                value={instrument}
                label="Mã chứng khoán"
                required
                disabled={!!rowEdit}
              />
              <FieldInput
                onChange={(v) => setMarginRate(v)}
                style={{ width: 200 }}
                label="TLKQ(%)"
                type="number"
                required
                value={margin_rate}
              />
              <FieldInput
                  onChange={(v) => setCallRate(v)}
                  style={{ width: 200 }}
                  label="RTT(%)"
                  type="number"
                  required
                  value={call_rate}
              />
            </div>
            <Button
              color="primary"
              disabled={!instrument}
              onClick={() => {
                setOpen(false);
                addInstrument();
              }}
            >
              <AddCircleOutline color="primay" fontSize="14px" />
              <span className="font-size-14 ml-25">
                {rowEdit ? 'Cập nhật' : 'Thêm vào danh sách'}
              </span>
            </Button>
          </div>
        </Modal>
      )}
    </div>
  );
}

AddStock.propTypes = {
  refetch: PropTypes.func,
  activeTab: PropTypes.number,
  rowEdit: PropTypes.object,
};

export default AddStock;
