import { accountsHome } from '@Apis/client/home';
import useCustomQuery from '@Utils/useCustomQuery';
import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

import History from './History';
import Request from './Request';

function BodyTab() {
  let refFunction = useMemo(() => null);
  const refechDateHistory = useCallback(() => {
    refFunction?.();
  }, [refFunction]);

  const productType = useSelector((state) => state.ui.productType);
  const { data: dataAccounts } = useCustomQuery(accountsHome);
  const account = dataAccounts?.accounts?.find((e) => e.trade_type === productType);

  return (
    <div>
      <div className="body-tab">
        <Request account={account || {}} refechDateHistory={refechDateHistory} />
      </div>
      <div className="body-tab">
        <History
          refFunction={(refect) => {
            refFunction = refect;
          }}
        />
      </div>
    </div>
  );
}

export default BodyTab;
