import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import useCustomQuery from '@Utils/useCustomQuery';
import DataTableBasic from '@Components/DataTableBasic';
import { transactionSurveyorIndex, transactionTellerIndex } from '@Apis/admin/transaction';
import { columns } from './TransactionStockColumns';

function TransactionStock({ filter: filterProps, accountId, setWrapFilter }) {
  const [filter, setFilter] = useState({
    customer_id: accountId,
    per_page: 12,
    surveyor_user_id: '*',
    mode: 'surveyor',
  });
  const [page, setPage] = useState(0);

  useEffect(() => {
    setWrapFilter && setWrapFilter((pre) => ({ ...pre, ...filterProps, ...filter }));
  }, [filter]);

  useEffect(() => {
    if (page) {
      setFilter({ ...filter, page });
    }
  }, [page]);
  const route = filter.mode === 'surveyor' ? transactionSurveyorIndex : transactionTellerIndex;
  const { data } = useCustomQuery(route, { ...filterProps, ...filter });

  const setOrderBy = useCallback((column, type = '') => {
    setFilter((prevValues) => {
      return {
        ...prevValues,
        sort_by: column.sortField,
        order: type.toUpperCase(),
      };
    });
  }, []);

  return (
    <div>
      <DataTableBasic
        columns={columns()}
        onSort={setOrderBy}
        data={data}
        isPageEffect
        page={page}
        setPage={setPage}
      />
    </div>
  );
}

TransactionStock.propTypes = {
  filter: PropTypes.object,
  accountId: PropTypes.any,
  setWrapFilter: PropTypes.any,
};

export default TransactionStock;
