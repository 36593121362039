import styled from 'styled-components';

export const CardTitle = styled.div`
  font-size: 36px;
  font-weight: 600;
  line-height: 48px;
`;

export const ImageWrapper = styled.div`
  position: relative;
  width: 100%;
  display: inline-block;
  height: 100vh;
  img {
    position: absolute;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
`;

export const LoginWrapper = styled.div`
  padding: 2rem 8rem 2rem 7rem;
  display: flex;
  /* align-items: center; */
  justify-content: space-between;
  flex-flow: column;
  height: 100%;
`;
