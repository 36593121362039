import styled from 'styled-components';

export const PopUpWrapper = styled.div`
  .nav-tabs {
    margin: auto;
  }
  .nav-tabs .nav-link:after {
    background: #2f69b3 !important;
  }
`;
