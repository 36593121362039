import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Label, Col } from 'reactstrap';
import { getInputErrorsObject } from '@Utils/helpers';

function SelectInput({
  name,
  labelName,
  options,
  register,
  setValue,
  defaultValue,
  inputClassName,
  labelClassName,
  labelCol = '3',
  inputCol = '9',
  errors,
  value,
  // validationText,
  disabled = false,
  styleInput,
  required,
  placeholder,
  styleCol,
}) {
  const ref = useRef();
  const [errorDetail, setErrorDetail] = useState();
  const [inputValue, setInputValue] = useState(value || defaultValue || '');

  useEffect(() => {
    if (setValue && defaultValue) {
      setValue(name, defaultValue);
      setInputValue(defaultValue);
    }
  }, [defaultValue]);

  useEffect(() => {
    if (value !== inputValue) {
      setInputValue(value);
    }
  }, [value]);

  useEffect(() => {
    if (name && errors) {
      setErrorDetail(getInputErrorsObject(name, errors));
    }
  }, [name, errors && Object.keys(errors)]);

  const handleChange = (value) => {
    setValue(name, value);
    setInputValue(value);
  };

  return (
    <FormGroup row>
      {labelName && (
        <Label md={labelCol} for={name} className={`font-size-12 py-0 mb-50 ${labelClassName} `}>
          {labelName}
          {required && <span style={{ color: '#ea5455', margin: '0 2px' }}>*</span>}
        </Label>
      )}
      <Col md={inputCol} style={styleCol}>
        <select
          style={styleInput}
          name={name}
          placeholder={placeholder}
          {...register}
          className={`${inputClassName} form-control`}
          onChange={(e) => handleChange(e.target.value)}
          value={inputValue}
          ref={ref}
          disabled={disabled}
        >
          {options.map((item) => {
            return (
              <option key={item.value} value={item.value}>
                {item.label}
              </option>
            );
          })}
        </select>
        {errorDetail && (
          <p className="text-danger font-size-12 mb-0" style={{ paddingTop: '2px' }}>
            {errorDetail.type === 'validate' ? errorDetail.message : 'Trường bắt buộc'}
          </p>
        )}
      </Col>
    </FormGroup>
  );
}

SelectInput.propTypes = {
  register: PropTypes.object,
  name: PropTypes.string,
  labelName: PropTypes.any,
  labelCol: PropTypes.string,
  inputCol: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      name: PropTypes.string,
    }),
  ),
  defaultValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  setValue: PropTypes.func,
  inputClassName: PropTypes.string,
  labelClassName: PropTypes.string,
  errors: PropTypes.object,
  styleInput: PropTypes.object,
  validationText: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.any,
  styleCol: PropTypes.any,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
};

export default SelectInput;
