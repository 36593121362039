import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col, NavLink, Button } from 'reactstrap';
import { Layers, LayersClear } from '@mui/icons-material';
import shortid from 'shortid';
import { useForm } from 'react-hook-form';
import TextInput from '@Components/Input/TextInput';
import ButtonSubmit from '@Components/ButtonSubmit';
import SearchCustomer from '@Components/Input/SearchCustomer';
import { createBuyTransaction } from '@Apis/admin/transaction';
import useCustomMutation from '@Utils/useCustomMuation';
import { toastError, toastSuccess } from '@Utils/toastrHelper';
import SearchStock from '@Components/Input/SearchStock';
import { TYPE_CS } from '@Components/Layouts/PrivateLayout';
import WarehouseItem from './WarehouseItem';
import BuyStockInfo from './BuyStockInfo';

function Buy({ closeModal, refetch, optionsTeller, optionsWarehouse, setIsRefreshStatus }) {
  const { mutate, isSuccess, error } = useCustomMutation(createBuyTransaction, true);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    unregister,
    watch,
  } = useForm();
  const customerId = watch('customer_id');
  const stockId = watch('stock_id');

  const onSubmit = (formData) => {
    const { customer_id, note, price, quantity, stock_id, ...restForm } = formData;
    const transactions = Object.values(restForm);
    mutate({
      customer_id,
      note,
      price,
      quantity,
      stock_id,
      transactions,
    });
  };

  useEffect(() => {
    if (isSuccess) {
      toastSuccess('Lập lệnh thành công');
      closeModal();
      refetch();
      setIsRefreshStatus({ state: true });
    }
    if (error) {
      toastError(error);
    }
  }, [isSuccess, error]);

  return (
    <Container className="trade-buy">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Row className="bg-buy py-2">
          <Col md="12" className="trade-buuy-left">
            <Container>
              <BuyStockInfo customerId={customerId} stockId={stockId} />
              <Row>
                <Col>
                  <SearchCustomer
                    labelName="Chọn khách hàng *"
                    labelCol="12"
                    inputCol="12"
                    labelClassName="font-size-14 mb-50"
                    name="customer_id"
                    register={{ ...register('customer_id', { required: true }) }}
                    errors={errors}
                    tradeType={TYPE_CS}
                    setValue={setValue}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <SearchStock
                    labelName="Mã CK *"
                    labelCol="12"
                    inputCol="12"
                    labelClassName="font-size-14 mb-50"
                    name="stock_id"
                    register={{ ...register('stock_id', { required: true }) }}
                    errors={errors}
                    setValue={setValue}
                  />
                </Col>
                <Col>
                  <TextInput
                    labelCol="12"
                    inputCol="12"
                    type="price"
                    labelClassName="font-size-14 mb-50"
                    labelName="Khối lượng *"
                    placeholder="Khối lượng"
                    setValue={setValue}
                    name="quantity"
                    register={{ ...register('quantity', { required: true }) }}
                    errors={errors}
                  />
                </Col>
                <Col>
                  <TextInput
                    labelCol="12"
                    inputCol="12"
                    type="price"
                    labelClassName="font-size-14 mb-50"
                    labelName="Giá mua *"
                    placeholder="Giá mua"
                    setValue={setValue}
                    name="price"
                    register={{ ...register('price', { required: true }) }}
                    errors={errors}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <TextInput
                    inputClassName="uppercase"
                    labelCol="12"
                    inputCol="12"
                    type="textarea"
                    labelClassName="font-size-14 mb-50"
                    labelName="Ghi chú"
                    placeholder="Ghi chú"
                    setValue={setValue}
                    name="note"
                    register={{ ...register('note') }}
                    errors={errors}
                  />
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
        <Row className="justify-content-center gap-20 py-2">
          <ButtonSubmit
            name="Xác nhận"
            className="px-2"
            size="md"
            color="primary"
            // isLoading={isLoading}
          />
          <Button outline type="button" onClick={() => closeModal()}>
            Hủy
          </Button>
        </Row>
      </form>
    </Container>
  );
}

Buy.propTypes = {
  closeModal: PropTypes.func,
  refetch: PropTypes.func,
  optionsWarehouse: PropTypes.array,
  optionsTeller: PropTypes.array,
  setIsRefreshStatus: PropTypes.func,
};

export default Buy;
