import React from 'react';
import PropTypes from 'prop-types';

function StatusPayment({ status }) {
  let buttonName;
  let buttonColor;

  switch (status) {
    case 'completed':
      buttonName = 'Đã duyệt';
      buttonColor = 'success';
      break;
    case 'pending':
      buttonName = 'Chờ duyệt';
      buttonColor = 'warning';
      break;
    case 'rejected':
      buttonName = 'Đã huỷ';
      buttonColor = 'danger';
      break;
    default:
      break;
  }
  return <p className={`text-${buttonColor}`}>{buttonName}</p>;
}

StatusPayment.propTypes = {
  status: PropTypes.string,
};

export default StatusPayment;
