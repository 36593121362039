import React from 'react';
import PropTypes from 'prop-types';
import SelectInput from '@Components/Input/SelectInput';
import TextInput from '@Components/Input/TextInput';

function TransactionEdit({
  itemDetail,
  register,
  setValue,
  optionsWarehouse,
  errors,
  quantityMatching,
  priceMatching,
}) {
  return (
    <div className="d-flex gap-20 align-items-center justify-content-between">
      {itemDetail.purchaseAccount ? (
        <div className="item-content">{itemDetail.purchaseAccount.name}</div>
      ) : (
        <SelectInput
          labelCol="12"
          inputCol="12"
          options={optionsWarehouse}
          errors={errors}
          inputClassName="item-content"
          name="purchase_account_id"
          register={{
            ...register('purchase_account_id', { required: true }),
          }}
          setValue={setValue}
        />
      )}
      <TextInput
        labelCol="12"
        inputCol="12"
        type="number"
        inputClassName="item-content"
        placeholder="KL Khớp"
        setValue={setValue}
        name="quantity"
        register={{
          ...register('quantity', {
            required: true,
            validate: (value) => {
              if (value > itemDetail.quantity - quantityMatching) {
                return 'Khới lượng không hợp lệ';
              }
            },
          }),
        }}
        errors={errors}
        defaultValue={itemDetail.quantity - quantityMatching}
      />
      <div className="ml-1">
        <TextInput
          labelCol="12"
          inputCol="12"
          type="pricenumber"
          inputClassName="item-content"
          placeholder="Giá Khớp"
          setValue={setValue}
          name="price"
          register={{ ...register('price', { required: true }) }}
          errors={errors}
          defaultValue={priceMatching}
        />
      </div>
    </div>
  );
}

TransactionEdit.propTypes = {
  itemDetail: PropTypes.object,
  optionsWarehouse: PropTypes.array,
  register: PropTypes.any,
  errors: PropTypes.any,
  setValue: PropTypes.func,
  quantityMatching: PropTypes.number,
  priceMatching: PropTypes.number,
};

export default TransactionEdit;
