import React, { useEffect, useRef, useState } from 'react';
import {
  FormGroup,
  Label,
  Col,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
} from 'reactstrap';
import PropTypes from 'prop-types';
import { parsePositiveNumber, parseNumber, parsePrice, getInputErrorsObject, formatNumber } from '@Utils/helpers';

export default function TextInput({
  name,
  labelName,
  placeholder,
  register,
  errors,
  defaultValue,
  labelCol = '3',
  inputCol = '9',
  type = 'text',
  disabled = false,
  setValue = null,
  icon,
  rows,
  hidden = false,
  labelClassName,
  inputClassName,
  styleInput,
  styleGroup,
  max,
}) {
  const ref = useRef();
  const [inputValue, setInputValue] = useState('');
  const [errorDetail, setErrorDetail] = useState();

  useEffect(() => {
    if (setValue) {
      if (defaultValue !== undefined) {
        let format = defaultValue;
        if (type === 'price') {
          format = parsePrice(parseInt(defaultValue).toString());
        }
        if (type === 'pricenumber') {
          format = formatNumber(defaultValue);
        }
        setInputValue(format);
        setValue(name, defaultValue);
      } else {
        setInputValue('');
        setValue(name, '');
      }
    }
  }, [defaultValue]);

  useEffect(() => {
    if (name && errors) {
      setErrorDetail(getInputErrorsObject(name, errors));
    }
  }, [name, errors && Object.keys(errors)]);

  const handleChange = (value) => {
    if (type === 'phone') {
      const format = parsePositiveNumber(value);
      setInputValue(format);
      setValue(name, format);
    }
    if (type === 'text' || type === 'textarea' || type === 'email') {
      setInputValue(value);
      setValue(name, value);
    }
    if (type === 'quantity') {
      const format = parsePositiveNumber(value);
      setInputValue(format);
      setValue(name, parseInt(format.replaceAll('.', '')));
    }
    if (type === 'pricenumber') {
      const format = formatNumber(value.toString().replaceAll(',', ''));
      const valueToSet = parseFloat(format.toString().replaceAll(',', ''))

      if (value.split('.').length - 1 >= 2) return;
 
      if (value.includes('.') && !value.split('.')[1]) {
        setInputValue(format + '.');
        setValue(name, valueToSet);
      } else {
        setInputValue(format);
        setValue(name, valueToSet);
      }
    }
    if (type === 'number') {
      const format = parseNumber(value);
      setInputValue(format);
      setValue(name, parseFloat(format));
    }
    if (type === 'price') {
      const format = parsePrice(value);
      if (parseInt(format.replaceAll(',', '')) > max) {
        setInputValue(parsePrice(max));
        setValue(name, max);
      } else {
        setInputValue(format);
        setValue(name, parseInt(format.replaceAll(',', '')));
      }
    }
  };

  return (
    <FormGroup row style={styleGroup}>
      {labelName && (
        <Label
          md={labelCol}
          for={name}
          className={`font-size-12 py-0 ${labelClassName ?? 'font-weight-bold'}`}
        >
          {labelName}
        </Label>
      )}
      <Col md={inputCol}>
        {icon ? (
          <InputGroup className="input-group-merge">
            <Input
              style={styleInput}
              className={inputClassName}
              type={type}
              name={name}
              {...register}
              placeholder={placeholder}
              value={inputValue}
              onChange={(e) => handleChange(e.target.value)}
              disabled={disabled}
              rows={rows}
              ref={ref}
              hidden={hidden}
            />
            <InputGroupAddon addonType="prepend">
              <InputGroupText className={disabled ? 'bg-disabled' : ''}>{icon}</InputGroupText>
            </InputGroupAddon>
          </InputGroup>
        ) : (
          <Input
            style={styleInput}
            className={inputClassName}
            type={type}
            name={name}
            {...register}
            placeholder={placeholder}
            value={inputValue || ''}
            onChange={(e) => handleChange(e.target.value)}
            disabled={disabled}
            rows={rows}
            ref={ref}
            hidden={hidden}
          />
        )}

        {errorDetail && (
          <p className="text-danger font-size-12 mb-0" style={{ paddingTop: '2px' }}>
            {errorDetail.message ||
              (errorDetail.type === 'required' ? 'Trường bắt buộc.' : 'Giá trị sai định dạng.')}
          </p>
        )}
      </Col>
    </FormGroup>
  );
}

TextInput.propTypes = {
  name: PropTypes.string,
  labelName: PropTypes.any,
  placeholder: PropTypes.string,
  register: PropTypes.object,
  errors: PropTypes.object,
  styleGroup: PropTypes.object,
  styleInput: PropTypes.object,
  defaultValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  labelCol: PropTypes.string,
  inputCol: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  setValue: PropTypes.func,
  icon: PropTypes.any,
  rows: PropTypes.number,
  max: PropTypes.number,
  hidden: PropTypes.bool,
  labelClassName: PropTypes.string,
  inputClassName: PropTypes.string,
  validationText: PropTypes.string,
};
