/* eslint-disable import/no-cycle */
import React, { useEffect, useState, useCallback } from 'react';
import { SwapHorizontalCircle } from '@mui/icons-material';
import {
  Button,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from 'reactstrap';
import dayjs from 'dayjs';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { io } from 'socket.io-client';
import DatePicker from '@Components/DatePicker';
import {
  transactionSurveyorIndex,
  transactionReject,
  transactionSurveyorExport,
  cancelRequestTrade,
} from '@Apis/admin/transaction';
import DataTableBasic from '@Components/DataTableBasic';
import CustomeModal from '@Components/CustomeModal';
import useCustomMutation from '@Utils/useCustomMuation';
import { toastError, toastSuccess } from '@Utils/toastrHelper';
import FilterComponent from '@Components/FilterComponent';
import { showGlobalLoading, hideGlobalLoading } from '@Lib/slices/uiSlice';
import SearchCustomer from '@Components/Input/SearchCustomer';
import SearchEmployer from '@Components/Input/SearchEmployer';
import { TransactionWrapper } from './styles';
import { columns } from './components/Columns';
import Status from './components/Status';
import PopUpEdit from './components/PopUpEdit';
import ExpandTranction from './components/ExpandTranction';
import PopUpCreate from './components/PopUpCreate';
import { TAB_STATUS } from './constants';
import ModalCK from './components/ModalCK';
import PopUpCreatePS from './components/PopUpCreatePS';
import PopUpCreateTplus from './components/PopUpCreateTplus';

function Transaction() {
  const mode = 'surveyor';
  const [searchParams, setSearchParams] = useSearchParams();
  const [isOpenCk, setCKOpen] = useState(false);
  const page = searchParams.get('page') || 1;
  const customerId = searchParams.get('customer_id');
  const surveyorUserId = searchParams.get('surveyor_user_id');
  const tradeType = searchParams.get('trade_type');
  const activeTab = searchParams.get('activeTab') || 1;
  const dateRange = searchParams.get('date_range');
  const id = searchParams.get('id');
  const stockId = searchParams.get('stock_id');
  const type = searchParams.get('type');
  const [itemChangeStatus, setItemChangeStatus] = useState(null);
  const [itemCancel, setItemCancel] = useState(null);
  const [itemPopUp, setItemPopUp] = useState(null);
  const [isRefreshStatus, setIsRefreshStatus] = useState(0);
  const [dataTable, setDataTable] = useState(null);
  const [isOpenPopUpCreate, setIsOpenPopupCreate] = useState(false);
  const [isOpenPopUpCreatePS, setIsOpenPopUpCreatePS] = useState(false);
  const [isOpenPopUpCreateTplus, setIsOpenPopUpCreateTplus] = useState(false);
  const [optionsWarehouse, setOptionsWarehouse] = useState([]);
  const [optionsTeller, setOptionsTeller] = useState([]);
  const warehouse = useSelector((state) => state.transaction.warehouse);
  const tellers = useSelector((state) => state.transaction.tellers);
  const currentUser = useSelector((state) => state.user);
  const [dataState, setData] = useState();

  const [filter, setFilter] = useState({
    trade_type: tradeType,
    id,
    stock_id: stockId,
    type,
    customer_id: customerId,
    surveyor_user_id: surveyorUserId || currentUser.info.id,
    date_range: dateRange,

    status: TAB_STATUS[activeTab - 1]?.child?.join(','),
    mode,
    sort_by: 'id',
    order: activeTab === 1 ? 'ASC' : 'DESC',
    page,
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (page !== filter.page) {
      setFilter({ ...filter, page });
    }
  }, [page]);

  const filterOnChange = (fil) => {
    setFilter((prevValues) => {
      return {
        ...prevValues,
        ...fil,
      };
    });
  };

  useEffect(() => {
    // if (!customerId) return;
    const token = localStorage.getItem(process.env.TOKEN);
    const socket = io(`${process.env.BACKEND_SOCKET}/admin/transaction/created-transaction`, {
      query: { token },
      transports: ['websocket'],
    });
    socket.on('connect', () => {
      socket.emit('register', 'your-client-id');
    });
    socket.on('data', () => {
      if (activeTab === 1) {
        refetch();
      }
      setIsRefreshStatus((pre) => pre + 1);
    });
    return () => {
      socket.disconnect();
    };
  }, [activeTab, refetch, customerId]);

  useEffect(() => {
    const token = localStorage.getItem(process.env.TOKEN);
    const socket = io(`${process.env.BACKEND_SOCKET}/admin/transaction/created-transaction`, {
      query: { token },
      transports: ['websocket'],
    });
    socket.on('connect', () => {
      socket.emit('register', 'your-client-id');
    });
    socket.on('data', () => {
      if (activeTab === 1) {
        refetch();
      }
      setIsRefreshStatus((pre) => pre + 1);
    });
    return () => {
      socket.disconnect();
    };
  }, [activeTab, refetch, customerId]);

  useEffect(() => {
    refetch();
  }, [filter]);

  const refetch = useCallback(() => {
    dispatch(showGlobalLoading());
    transactionSurveyorIndex(filter)
      .then((res) => setData(res))
      .finally(() => dispatch(hideGlobalLoading()));
  }, [filter]);

  const { mutate, isSuccess, error } = useCustomMutation(transactionReject, true);
  const { mutate: mutateCancelRequest } = useCustomMutation(cancelRequestTrade, true);

  const changeStatus = (status) => {
    const { child, id } = status;
    searchParams.set('activeTab', id);
    setSearchParams(searchParams);
    filterOnChange({
      status: child.join(','),
      page: 1,
      sort_by: 'id',
      order: id === 1 ? 'ASC' : 'DESC',
    });
  };

  const editStatus = (item, status) => {
    item.status_change = status;
    setItemChangeStatus(item);
  };

  const onConfirm = () => {
    const { status_change, id } = itemChangeStatus;
    mutate({
      id,
      status: status_change,
    });
  };

  const onCancelRequest = () => {
    const { id } = itemCancel || {};
    mutateCancelRequest({ id });
    setItemCancel(null);
  };

  useEffect(() => {
    if (dataState) {
      dataState.data.map((item) => {
        if (item.status === 'created') {
          return item;
        }
        const {
          publicTellersUser,
          subTransactionItems,
          quantity,
          id,
          status,
          purchaseAccount,
          price,
        } = item;
        if (publicTellersUser && subTransactionItems.length === 0) {
          subTransactionItems.push({
            id,
            quantity,
            publicTellersUser,
            purchaseAccount,
            status,
            price,
          });
        }
        if (subTransactionItems.length > 0) {
          item.defaultExpanded = true;
        }
        return item;
      });
      setDataTable(dataState);
    }
  }, [dataState]);

  useEffect(() => {
    if (isSuccess) {
      toastSuccess('Huỷ giao dịch thành công');
      refetch();
      setItemChangeStatus(null);
      setIsRefreshStatus((pre) => pre + 1);
    }
    if (error) {
      toastError(error);
    }
  }, [isSuccess, error]);

  const onRowClicked = (row) => {
    setItemPopUp(row);
  };

  useEffect(() => {
    if (warehouse) {
      const optionDefault = {
        label: 'Chọn',
        value: '',
      };
      setOptionsWarehouse([optionDefault, ...warehouse]);
    }
  }, [warehouse]);

  useEffect(() => {
    if (tellers) {
      const optionDefault = {
        label: 'Chọn',
        value: 0,
      };
      setOptionsTeller([optionDefault, ...tellers]);
    }
  }, [tellers]);

  const setOrderBy = useCallback((column, type = '') => {
    setFilter((prevValues) => {
      return {
        ...prevValues,
        sort_by: column.sortField,
        order: type.toUpperCase(),
      };
    });
  }, []);

  const onSearch = useCallback((fil) => {
    setFilter((pre) => ({ ...pre, ...fil }));
  }, []);

  const onExport = useCallback(
    async (fil) => {
      return transactionSurveyorExport({ ...filter, ...fil });
    },
    [filter],
  );

  return (
    <div className="content-wrapper">
      <TransactionWrapper>
        <div className="d-flex align-items-center justify-content-between header-body mb-2">
          <p className="font-size-28 font-weight-bolder">Giao dịch</p>
          <div className="d-flex align-items-center gap-20">
            <FilterComponent
              onSearch={onSearch}
              onExport={onExport}
              filters={[
                {
                  key: 'trade_type',
                  label: 'Sản phẩm',
                  width: 130,
                  isMulti: true,
                  options: [
                    {
                      label: 'CK cơ sở',
                      value: 'base',
                    },
                    {
                      label: 'CK T+',
                      value: 'spot',
                    },
                    {
                      label: 'CK Phái sinh',
                      value: 'derivative',
                    },
                  ],
                },
                {
                  key: 'id',
                  label: 'Mã GD',
                  width: 130,
                },
                {
                  key: 'stock_id',
                  label: 'Mã CK',
                  width: 130,
                },
                {
                  key: 'type',
                  label: 'Loại',
                  width: 130,
                  options: [
                    {
                      label: 'Mua',
                      value: 'buy',
                    },
                    {
                      label: 'Bán',
                      value: 'sell',
                    },
                  ],
                },
                {
                  key: 'customer_id',
                  label: 'Tên Khách hàng',
                  width: 130,
                  Component: SearchCustomer,
                },
                {
                  key: 'surveyor_user_id',
                  label: 'Kiểm soát viên',
                  width: 130,
                  defaultValue: surveyorUserId || currentUser.info.id,
                  Component: SearchEmployer,
                },
                {
                  key: 'date_range',
                  label: 'Chọn ngày',
                  width: 130,
                  Component: DatePicker.Range,
                  type: 'onChange',
                  parseChange: (v) =>
                    v
                      .map?.((e) => dayjs(e).unix())
                      ?.toString()
                      ?.replace(',', '-'),
                  parseValue: (v) => (v ? v?.split('-')?.map((e) => dayjs.unix(e).toDate()) : []),
                },
              ]}
            />

            <UncontrolledDropdown className="nav-item">
              <DropdownToggle
                color="primary"
                style={{ minWidth: 150, maxHeight: 40, padding: 0 }}
                className="font-size-14 text-white text-uppercase white-space-nowrap"
                onClick={(e) => e.preventDefault()}
                caret
              >
                <SwapHorizontalCircle size={11} color="white" />
                <span className="font-size-14 ml-50">Lập lệnh</span>
              </DropdownToggle>
              <DropdownMenu right>
                <DropdownItem className="w-100" onClick={() => setIsOpenPopupCreate(true)}>
                  <span className="align-middle font-size-14 font-weight-bolder ml-50 text-primary">
                    Cơ sở
                  </span>
                </DropdownItem>
                <DropdownItem className="w-100" onClick={() => setIsOpenPopUpCreateTplus(true)}>
                  <span className="align-middle font-size-14 font-weight-bolder ml-50 text-primary">
                    CK T+
                  </span>
                </DropdownItem>
                <DropdownItem className="w-100" onClick={() => setIsOpenPopUpCreatePS(true)}>
                  <span className="align-middle font-size-14 font-weight-bolder ml-50 text-primary">
                    Phái Sinh
                  </span>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>

            <Button
              className="button-swap-ck"
              style={{ minWidth: 150, maxHeight: 40, padding: 0 }}
              onClick={() => setCKOpen(true)}
            >
              <SwapHorizontalCircle size={11} color="white" />
              <span className="font-size-14 ml-50">Chuyển CK</span>
            </Button>
          </div>
        </div>
        <div className="">
          <Status
            activeTab={Number(activeTab)}
            handleChangeStatus={changeStatus}
            filter={filter}
            isRefreshStatus={isRefreshStatus}
          />
        </div>
        <DataTableBasic
          onSort={setOrderBy}
          data={dataTable}
          columns={columns({ editStatus, navigate, openCancel: setItemCancel, activeTab })}
          onRowClicked={onRowClicked}
          expandableRowsComponent={ExpandTranction}
          expandableRows
        />
        <CustomeModal
          isOpen={itemCancel !== null}
          confirm={onCancelRequest}
          buttonName="Xác nhận"
          type="delete"
          mess={`Huỷ giao dịch ${itemCancel?.id}`}
          closeModal={() => setItemCancel(null)}
        />
        <CustomeModal
          isOpen={itemChangeStatus !== null}
          confirm={onConfirm}
          buttonName="Xác nhận"
          type="delete"
          mess={
            itemChangeStatus?.status_change === 'cancelled'
              ? `Huỷ giao dịch ${itemChangeStatus?.id} ?`
              : `Giao dịch ${itemChangeStatus?.id} đã hết hạn ?`
          }
          closeModal={() => setItemChangeStatus(null)}
        />
        {itemPopUp && (
          <PopUpEdit
            isOpen={itemPopUp !== null}
            closeModal={() => setItemPopUp(null)}
            item={itemPopUp}
            refetch={refetch}
            setIsRefreshStatus={() => setIsRefreshStatus(isRefreshStatus + 1)}
            optionsWarehouse={optionsWarehouse}
            optionsTeller={optionsTeller}
          />
        )}
        {isOpenPopUpCreate && (
          <PopUpCreate
            isOpen={isOpenPopUpCreate}
            closeModal={() => setIsOpenPopupCreate(false)}
            refetch={refetch}
            setIsRefreshStatus={() => setIsRefreshStatus(isRefreshStatus + 1)}
            optionsWarehouse={optionsWarehouse}
            optionsTeller={optionsTeller}
          />
        )}
        {isOpenPopUpCreatePS && (
          <PopUpCreatePS
            isOpen={isOpenPopUpCreatePS}
            closeModal={() => setIsOpenPopUpCreatePS(false)}
            refetch={refetch}
          />
        )}
        {isOpenPopUpCreateTplus && (
          <PopUpCreateTplus
            isOpen={isOpenPopUpCreateTplus}
            closeModal={() => setIsOpenPopUpCreateTplus(false)}
            refetch={refetch}
          />
        )}
        {isOpenCk ? <ModalCK onClose={() => setCKOpen(false)} /> : null}
      </TransactionWrapper>
    </div>
  );
}

Transaction.propTypes = {};

export default Transaction;
