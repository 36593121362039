export const TRADE_STATUS = [
  {
    label: 'Tất cả lệnh',
    value: '',
    color: '#00A5FF',
    count: 0,
  },
  {
    label: 'Chờ vào lệnh',
    value: 'pending',
    color: '#D37E17',
    count: 0,
  },
  {
    label: 'Đang vào lệnh',
    value: 'in_process',
    color: '#2F69B3',
    count: 0,
  },
  {
    label: 'Khớp một phần',
    value: 'part_completed',
    color: '#2F69B3',
    count: 0,
  },
  {
    label: 'Đã khớp',
    value: 'completed',
    color: '#14B13B',
    count: 0,
  },
  {
    label: 'Yêu cầu huỷ',
    value: 'request_cancel',
    color: '#623494',
    count: 0,
  },
  {
    label: 'Hết hạn',
    value: 'expired',
    color: '#504F54',
    count: 0,
  },
  {
    label: 'Đã hủy',
    value: 'cancelled',
    color: '#ED1C24',
    count: 0,
  },
];
