/* eslint-disable no-return-assign */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';

class InfoNotification extends Component {
  constructor(props) {
    super(props);
    const { itemActive } = props;
    if (this.refDiv && itemActive) {
      this.refDiv.innerHTML = itemActive.content;
    }
  }

  componentDidMount() {
    this.changeHeight();
    window.addEventListener('resize', this.changeHeight);
  }

  UNSAFE_componentWillReceiveProps(nProps) {
    const { itemActive } = nProps;
    if (this.refDiv && itemActive) {
      this.refDiv.innerHTML = itemActive.content;
    }
  }

  shouldComponentUpdate(nProps) {
    const { itemActive } = this.props;
    return itemActive !== nProps.itemActive;
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.changeHeight);
  }

  changeHeight = () => {
    if (this.ref) {
      const width = window.innerWidth;
      this.ref.style.height = width <= 767 ? '' : `${window.innerHeight - 168.7}px`;
    }
  };

  render() {
    const { itemActive } = this.props;
    return (
      <div
        className="info-notification"
        style={{ padding: !itemActive ? '40px 0' : 0, overflow: 'auto' }}
        ref={(r) => {
          this.ref = r;
        }}
      >
        {!itemActive ? null : (
          <div className="header-info-notification">
            <div>Chi tiết thông báo</div>
            <div style={{ fontSize: 14, color: '#84818A' }}>
              {dayjs.unix(itemActive?.created_at).format('DD/MM/YYYY HH:mm')}
            </div>
          </div>
        )}
        <div style={{ fontWeight: 400 }} className="body-info-notification">
          {!itemActive ? null : (
            <div className="title-notification">
              <span>{itemActive?.title}</span>
            </div>
          )}
          <div ref={(ref) => (this.refDiv = ref)} />
        </div>
        {!itemActive ? (
          <div
            className="info-notification d-flex align-items-center justify-content-center"
            style={{ height: '100%' }}
          >
            <div>
              <img src="/images/notification/Empty_Notification.svg" alt="" />
              <div style={{ fontWeight: 600, paddingTop: 26 }}>Bạn chưa chọn thông báo nào</div>
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

InfoNotification.propTypes = {
  itemActive: PropTypes.object,
  user: PropTypes.object,
};

export default InfoNotification;
