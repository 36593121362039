import React from 'react';
import PropTypes from 'prop-types';
import SelectInput from '@Components/Input/SelectInput';
import useCustomQuery from '@Utils/useCustomQuery';
import { wareHouseIndex } from '@Apis/admin/warehouse';

function WithdrawBankList({ labelName, labelCol, inputCol, name, register, setValue, errors }) {
  const { data } = useCustomQuery(wareHouseIndex);
  const optionsBank = () => {
    const itemDefaul = {
      label: 'Chọn tài khoản chuyển',
      value: '',
    };
    let options = [];
    if (data && data.length > 0) {
      options = data.map((item) => ({ label: item.name, value: item.id }));
    }
    options.unshift(itemDefaul);
    return options;
  };

  return (
    <SelectInput
      labelCol={labelCol}
      inputCol={inputCol}
      options={optionsBank()}
      errors={errors}
      name={name}
      register={register}
      labelName={labelName}
      labelClassName="font-size-14"
      setValue={setValue}
    />
  );
}

WithdrawBankList.propTypes = {
  labelName: PropTypes.string,
  name: PropTypes.string,
  inputCol: PropTypes.string,
  labelCol: PropTypes.string,
  register: PropTypes.object,
  setValue: PropTypes.func,
  errors: PropTypes.object,
};

export default WithdrawBankList;
