/* eslint-disable no-nested-ternary */
import React, { Component } from 'react';
import Title from '@Components/Title';
import ButtonSubmit from '@Components/ButtonSubmit';
import DatePicker from '@Components/DatePicker';
import ModalExport from '@Components/ModalExport';
import { expotDebt } from '@Apis/admin/loan';
import dayjs from 'dayjs';
import TableDrop from './TableDrop';
import CardRepo from './CardRepo';
import ModalDetail from './ModalDetail';
import ModalWarningDelete from './ModalWarningDelete';
import './styles.scss';

class WarehouseSource extends Component {
  constructor(props) {
    super(props);
    this.state = { date_range: [] };
  }

  handleAdd = () => {
    this.refModal?.open();
  };

  handleRenderMessage = (type) => {
    if (type === 2) {
      return 'Bạn muốn đổi trạng thái sang đã tất toán?';
    }
    if (type === 3) {
      return 'Bạn muốn đổi trạng thái sang đã thanh toán?';
    }
    return undefined;
  };

  handleSelect = (type, data, isDetail) => {
    if (type !== 1 && type) {
      this.handleRemove(data, type !== 4 ? 'confirm' : 'delete', this.handleRenderMessage(type));
    } else {
      this.refModal?.open(data, isDetail);
    }
  };

  reload = () => {
    this.table1?.();
    this.table2?.();
    this.table3?.();
    this.table4?.();
    this.table5?.();
  };

  handleRemove = (record, type, mess) => {
    this.modalWarningDelete?.open(record, type, mess);
  };

  onExport = () => {
    const { date_range } = this.state;
    const d = date_range.map((e) => dayjs(e).unix()).join('-');
    return expotDebt({ date_range: d });
  };

  render() {
    const { date_range } = this.state;
    const d = date_range.map((e) => dayjs(e).unix()).join('-');
    return (
      <div className="warehouse-source" style={{ paddingBottom: 80 }}>
        <div className="header-warehouse">
          <Title name="Kho huy động" />
          <div className="filter-warehouse">
            <ModalExport
              colorText="#1976d2"
              classNameButton="d-flex align-items-center"
              styleButton={{ height: 43.39, marginRight: 5 }}
              onExport={this.onExport}
            />
            <div className="select-dropdown-search">
              <DatePicker.Range
                value={date_range}
                onChange={(v) => this.setState({ date_range: v })}
                placeholder="Chọn ngày"
              />
            </div>
            <div className="select-dropdown-search">
              <ButtonSubmit
                onClick={this.handleAdd}
                name="Thêm mới"
                color="primary"
                icon={<img src="/images/warehouseSource/add_circle.svg" alt="" />}
              />
            </div>
          </div>
        </div>
        <CardRepo />
        <TableDrop
          refReload={(ref) => {
            this.table1 = ref;
          }}
          handleSelect={this.handleSelect}
          filter={{
            less_than_days: 3,
            date_range: d,
            is_settlement: 0,
            status: 'unpaid',
          }}
          style={{ marginTop: 32 }}
          color="#E43925"
          title="Thời hạn dưới 3 ngày"
        />
        <TableDrop
          refReload={(ref) => {
            this.table2 = ref;
          }}
          handleSelect={this.handleSelect}
          filter={{
            less_than_days: 7,
            greater_than_days: 4,
            date_range: d,
            is_settlement: 0,
            status: 'unpaid',
          }}
          color="#D37E17"
          title="Thời hạn từ 4 - 7 ngày"
        />
        <TableDrop
          refReload={(ref) => {
            this.table3 = ref;
          }}
          handleSelect={this.handleSelect}
          color="#5542F6"
          filter={{ greater_than_days: 8, date_range: d, is_settlement: 0, status: 'unpaid' }}
          title="Thời hạn trên 7 ngày"
        />
        <TableDrop
          refReload={(ref) => {
            this.table4 = ref;
          }}
          handleSelect={this.handleSelect}
          filter={{ is_settlement: 1, date_range: d, status: 'unpaid' }}
          color="#504F54"
          title="Đã tất toán"
        />
        <TableDrop
          refReload={(ref) => {
            this.table5 = ref;
          }}
          handleSelect={this.handleSelect}
          filter={{ status: 'paid', date_range: d, is_settlement: 1 }}
          color="#14B13B"
          title="Đã thanh toán"
        />
        <ModalDetail
          reload={this.reload}
          ref={(ref) => {
            this.refModal = ref;
          }}
        />
        <ModalWarningDelete
          reload={this.reload}
          ref={(ref) => {
            this.modalWarningDelete = ref;
          }}
        />
      </div>
    );
  }
}

WarehouseSource.propTypes = {};

export default WarehouseSource;
