import React, { useState, useEffect } from 'react';
import { holdByCustomer } from '@Apis/admin/stock';
import useCustomQuery from '@Utils/useCustomQuery';
import { useParams } from 'react-router-dom';
import DataTableBasic from '@Components/DataTableBasic';
import { columns } from './StockColumns';

function Stock() {
  const { customerId } = useParams();
  const [filter, setFilter] = useState({
    customer_id: customerId,
    per_page: 5,
  });
  const [page, setPage] = useState(0);
  useEffect(() => {
    if (page) {
      setFilter({ ...filter, page });
    }
  }, [page]);
  const { data } = useCustomQuery(holdByCustomer, filter);
  return (
    <div className="bg-white p-2">
      <div className="d-flex align-items-center justify-content-between">
        <p className="font-size-24 mb-2">Cổ phiếu</p>
      </div>
      {data && (
        <DataTableBasic
          columns={columns()}
          data={data}
          isPageEffect
          page={page}
          setPage={setPage}
        />
      )}
    </div>
  );
}

Stock.propTypes = {};

export default Stock;
