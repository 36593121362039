/* eslint-disable no-unsafe-optional-chaining */
import { formatNumber } from '@Utils/helpers';
import React from 'react';

export const columns = (navigate, productType) => [
  {
    name: (
      <div
        className="w-100 h-100 d-flex align-items-center justify-content-center"
        style={{ borderRight: '1px solid #cbcbcb', height: '100%' }}
      >
        MÃ CK
      </div>
    ),
    cell: (row) => (
      <div
        className="w-100 h-100 d-flex align-items-center justify-content-center"
        style={{ fontWeight: 600, height: '100%', borderRight: '1px solid #cbcbcb' }}
      >
        {row.stock_id}
      </div>
    ),
    width: '90px',
    center: true,
    fixed: 'left',
  },
  {
    name: (
      <div className="w-100 h-100" style={{ textAlign: 'center' }}>
        <div className="headerTwo">Tỷ lệ hợp tác </div>
        <div className="headerTwo headerTwoLow">RTT</div>
      </div>
    ),
    cell: (row) => {
      if (row.columnTotal) return null;
      return (
        <div className="w-100 h-100" style={{ textAlign: 'right' }}>
          <div className="headerRowTwo">{formatNumber(row.stock?.margin_rate, '--')}%</div>
          <div className="headerRowTwo headerTwoLow">
            {formatNumber(row.stock?.call_rate, '--')}%
          </div>
        </div>
      );
    },
    center: true,
    width: '160px',
  },
  {
    name: (
      <div className="w-100 h-100" style={{ textAlign: 'center' }}>
        <div className="headerTwo">TỔNG KL</div>
        <div className="headerTwo headerTwoLow">KL KHẢ DỤNG</div>
      </div>
    ),
    cell: (row) => {
      if (row.columnTotal) return null;
      return (
        <div className="w-100 h-100" style={{ textAlign: 'right' }}>
          <div className="headerRowTwo">{formatNumber(row.quantity, '--')}</div>
          <div className="headerRowTwo headerTwoLow">
            {formatNumber(row.available_quantity, '--')}
          </div>
        </div>
      );
    },
    center: true,
    width: '140px',
    fixed: 'left',
  },
  {
    name: (
      <div className="w-100 h-100" style={{ textAlign: 'center' }}>
        <div className="headerTwo">T+/mua</div>
        <div className="headerTwo headerTwoLow">T+/bán</div>
      </div>
    ),
    cell: (row) => {
      if (row.columnTotal) return null;
      return (
        <div className="w-100 h-100" style={{ textAlign: 'right' }}>
          <div className="headerRowTwo">{formatNumber(row.t0, '--')}</div>
          <div className="headerRowTwo headerTwoLow">{formatNumber(row.t0_sell, '--')}</div>
        </div>
      );
    },
    center: true,
    width: '140px',
  },
  {
    name: (
      <div className="w-100 h-100" style={{ textAlign: 'center' }}>
        <div className="headerTwo">T1/mua</div>
        <div className="headerTwo headerTwoLow">T1/bán</div>
      </div>
    ),
    cell: (row) => {
      if (row.columnTotal) return null;
      return (
        <div className="w-100 h-100" style={{ textAlign: 'right' }}>
          <div className="headerRowTwo">{formatNumber(row.t1, '--')}</div>
          <div className="headerRowTwo headerTwoLow">{formatNumber(row.t1_sell, '--')}</div>
        </div>
      );
    },
    center: true,
    width: '140px',
  },
  // {
  //   name: (
  //     <div className="w-100 h-100" style={{ textAlign: 'center' }}>
  //       <div className="headerTwo">T2/mua</div>
  //       <div className="headerTwo headerTwoLow">T2/bán</div>
  //     </div>
  //   ),
  //   cell: (row) => (
  //     <div className="w-100 h-100" style={{ textAlign: 'right' }}>
  //       <div className="headerRowTwo">{formatNumber(row.t2, '--')}</div>
  //       <div className="headerRowTwo headerTwoLow">{formatNumber(row.t2_sell, '--')}</div>
  //     </div>
  //   ),
  //   center: true,
  //   width: '140px',
  // },
  {
    name: (
      <div className="w-100 h-100" style={{ textAlign: 'center' }}>
        <div className="headerTwo">GIÁ TB</div>
        <div className="headerTwo headerTwoLow">GIÁ TT</div>
      </div>
    ),
    cell: (row) => {
      if (row.columnTotal) return null;
      return (
        <div className="w-100 h-100" style={{ textAlign: 'right' }}>
          <div className="headerRowTwo">
            {formatNumber(row?.lastTradingItem?.current_cost_price || 0, '--')}
          </div>
          <div className="headerRowTwo headerTwoLow">{formatNumber(row.stock?.price, '--')}</div>
        </div>
      );
    },
    center: true,
    width: '160px',
  },
  {
    name: (
      <div className="w-100 h-100" style={{ textAlign: 'center' }}>
        <div className="headerTwo">GIÁ TRỊ </div>
        <div className="headerTwo headerTwoLow">GIÁ TRỊ TT</div>
      </div>
    ),
    cell: (row) => {
        if (row.columnTotal) {
            return (
                <div className="w-100 h-100" style={{ textAlign: 'right' }}>
                    <div className="headerRowTwo"><b>{formatNumber(row.total_cost_value, '--')}</b></div>
                    <div className="headerRowTwo headerTwoLow"><b>{formatNumber(row.total_value, '--')}</b></div>
                </div>
            );
        }
      return (
        <div className="w-100 h-100" style={{ textAlign: 'right' }}>
          <div className="headerRowTwo">
            {formatNumber(row.quantity * (row?.lastTradingItem?.current_cost_price || 0), '--')}
          </div>
          <div className="headerRowTwo headerTwoLow">
            {formatNumber(row.quantity * (row.stock?.price || 0), '--')}
          </div>
        </div>
      );
    },
    center: true,
    width: '160px',
  },
  {
    name: (
      <div className="w-100 h-100" style={{ textAlign: 'center' }}>
        <div className="headerTwo">Ký quỹ yêu cầu </div>
        <div className="headerTwo headerTwoLow">RTT</div>
      </div>
    ),
    cell: (row) => {
      if (row.columnTotal) {
        return (
          <div className="w-100 h-100" style={{ textAlign: 'right' }}>
            <div className="headerRowTwo">
              <b>{formatNumber(row.total1, '--')}</b>
            </div>
            <div className="headerRowTwo headerTwoLow"><b>{formatNumber(row.total2, '--')}</b></div>
          </div>
        );
      }
      return (
        <div className="w-100 h-100" style={{ textAlign: 'right' }}>
          <div className="headerRowTwo">
            {formatNumber(row.quantity * ((row.stock?.price * row.stock?.margin_rate) / 100), '--')}
          </div>
          <div className="headerRowTwo headerTwoLow">
            {formatNumber(row.quantity * ((row.stock?.price * row.stock?.call_rate) / 100), '--')}
          </div>
        </div>
      );
    },
    center: true,
    width: '160px',
  },
  {
    name: (
      <div className="w-100 h-100" style={{ textAlign: 'center' }}>
        <div className="headerTwo">LÃI/LỖ</div>
        <div className="headerTwo headerTwoLow">%LÃI/LỖ</div>
      </div>
    ),
    cell: (row) => {
      if (row.columnTotal) return null;
      const startValue = row.quantity * (row?.lastTradingItem?.current_cost_price || 0);
      const pnl_wait = row.stock.price * row.quantity - startValue;
      const percent = (pnl_wait / startValue) * 100 || 0;
      return (
        <div className="w-100 h-100" style={{ textAlign: 'right' }}>
          <div style={{ color: pnl_wait >= 0 ? '#14B13B' : '#FC3400' }} className="headerRowTwo">
            {pnl_wait >= 0 ? '+ ' : ''}
            {formatNumber(pnl_wait, '--')}
          </div>
          <div
            className="headerRowTwo headerTwoLow"
            style={{ color: percent >= 0 ? '#14B13B' : '#FC3400' }}
          >
            {percent >= 0 ? '+ ' : ''}
            {formatNumber(percent ? percent.toFixed(2) : 0, '--')}%
          </div>
        </div>
      );
    },
    width: '160px',
  },
  {
    name: '',
    cell: (row) => {
      if (row.columnTotal) return null;
      return (
        <div className="w-100 h-100" style={{ textAlign: 'right', paddingRight: 20 }}>
          <div
            role="button"
            tabIndex="0"
            style={{ color: '#FC3400' }}
            onClick={() => navigate(`/${productType}/transaction?tab=2`)}
          >
            Bán <img src="/images/assetManager/double_arrow.svg" alt="" />
          </div>
        </div>
      );
    },
    width: '120px',
    right: true,
    fixed: 'right',
  },
];
