import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { read } from '@Apis/client/notification';
import { toastError } from '@Utils/toastrHelper';

function ItemNotification(props) {
  const { data, onSelect, active } = props;

  const renderImage = useCallback((d) => {
    const { icon } = d;
    return <img style={{ marginRight: 16 }} src={icon} alt="" />;
  }, []);

  const unRead = (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (!data.seen) {
      read({ id: [data.id] }).catch(toastError);
    }
  };

  return (
    <div
      className={`item-notification-list ${active ? 'item-notification-list-active' : ''}`}
      role="button"
      tabIndex="0"
      onClick={onSelect}
    >
      <div className="d-flex align-items-center">
        {renderImage(data)}
        <div className="w-100">
          <div
            className="tilte-notification"
            style={{
              color: data.seen ? '' : '#2E2C34',
              fontWeight: data.seen ? '' : 700,
            }}
          >
            {data.title}
          </div>
          <div
            style={{
              color: data.seen ? '' : '#2E2C34',
              fontWeight: data.seen ? '' : 600,
            }}
            className="tilte-notification-description"
          >
            {data.description}
          </div>
        </div>
        <div style={{ minWidth: 50 }}>
          <div className="time-notification">{dayjs.unix(data.created_at).format('HH:mm')}</div>
          <div className="time-notification" style={{ marginTop: 5 }} onClick={unRead}>
            <img
              src={`/images/notification/${data.seen ? 'read_notification.svg' : 'unread.svg'}`}
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  );
}

ItemNotification.propTypes = {
  data: PropTypes.object,
  active: PropTypes.bool,
  onSelect: PropTypes.func,
};

export default memo(ItemNotification, (n, p) => {
  const { data, onSelect, active } = n;
  return data === p.data && onSelect === p.onSelect && active === p.active;
});
