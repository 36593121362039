import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { renderType } from '@Utils/tradeHelpers';
import TransactionStatusButton from '@Components/TransactionStatusButton';
import { formatNumber } from '@Utils/helpers';
import { Notes } from '@mui/icons-material';

function TransactionInfo({ item, statusEdit, setStatusEdit }) {
  const renderPurchase = useCallback((item) => {
    if (item.type === 'sell') {
      return item?.sell_quantity;
    }
    return item?.purchase_quantity;
  }, []);

  function renderPrice({ price_type, price }) {
    if (price_type !== 'price') {
      return (
        <div className="uppercase" style={{ textAlign: 'center' }}>
          <div style={{ background: 'rgba(32, 201, 172, 0.1)' }}>
            <p className="font-size-14 font-weight-bolder py-25 px-1 text-info">{price_type}</p>
          </div>
        </div>
      );
    }
    return formatNumber(price);
  }

  return (
    <>
      <div className="d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center gap-20">
          <p className="font-size-32 font-weight-normal text-left"> CHI TIẾT YÊU CẦU</p>
          <div className="text-uppercase">{renderType(item.type)}</div>
        </div>
        <TransactionStatusButton
          status={statusEdit}
          changeStatus={setStatusEdit}
          itemTransaction={item}
        />
      </div>
      <div className="stock-info">
        <div className="item header">
          <div className="item-content">THỜI GIAN ĐẶT</div>
          {item?.cancel_request_at && <div className="item-content">THỜI GIAN HUỶ</div>}
          <div className="item-content">LOẠI LỆNH</div>
          <div className="item-content">CỔ PHIẾU</div>
          <div className="item-content">GIÁ {item.type === 'buy' ? 'MUA' : 'BÁN'}</div>
          <div className="item-content">KHỐI LƯỢNG</div>
          <div className="item-content">ĐÃ KHỚP</div>
        </div>
        <div className="item">
          <div className="font-size-14 item-content">
            {dayjs.unix(item.created_at).format('HH:mm - DD/MM/YY')}
          </div>
          {item?.cancel_request_at && (
            <div className="font-size-14 item-content">
              {dayjs.unix(item.cancel_request_at).format('HH:mm - DD/MM/YY')}
            </div>
          )}
          <div className="item-content px-1">{renderType(item.type)}</div>
          <div className="item-content">{item.stock_id}</div>
          <div className="item-content">{renderPrice(item)}</div>
          <div className="item-content">{formatNumber(item.quantity, '--')}</div>
          <div className="item-content">{formatNumber(renderPurchase(item), '--')}</div>
        </div>
      </div>
      <div className="d-flex stock-note">
        <Notes />
        <div className="pl-1 text-left">
          <p className="font-size-14 font-weight-bolder">Ghi chú</p>
          <p className="font-size-14 mt-25">{item.note}</p>
        </div>
      </div>
      {item.is_cancel_request ? (
        <div className="d-flex align-items-center justify-content-between note-cancel px-1">
          <p className="text-danger">Khách hàng gửi yêu cầu ngừng giao dịch</p>
          <p className="">{dayjs.unix(item.cancel_request_at).format('DD/MM/YYYY - HH:mm')}</p>
        </div>
      ) : (
        ''
      )}
    </>
  );
}

TransactionInfo.propTypes = {
  item: PropTypes.object,
  statusEdit: PropTypes.string,
  setStatusEdit: PropTypes.func,
};

export default TransactionInfo;
