/* eslint-disable import/no-cycle */
import React, { useEffect, useState } from 'react';
import useCustomQuery from '@Utils/useCustomQuery';
import PropTypes from 'prop-types';
import { countStautus } from '@Apis/admin/transaction';
import { Nav, NavItem, NavLink } from 'reactstrap';
import { TAB_STATUS } from '../constants';
import { StatusWrapper } from './StatusStyles';

function Status({ activeTab, handleChangeStatus, filter, isRefreshStatus }) {
  const toggle = (tab) => {
    if (activeTab !== tab) {
      handleChangeStatus(tab);
    }
  };

  const { data, refetch } = useCustomQuery(countStautus, filter);
  const [statusCount, setStatusCount] = useState([]);

  useEffect(() => {
    refetch();
  }, [filter]);

  useEffect(() => {
    if (isRefreshStatus) {
      refetch();
    }
  }, [isRefreshStatus]);

  useEffect(() => {
    if (data) {
      const mapStatus = TAB_STATUS.map((status) => {
        const { child } = status;
        const countStatus = child.reduce((prev, current) => {
          const objectStatus = data[`${current}`];
          if (objectStatus) {
            return prev + objectStatus.count;
          }
          return prev;
        }, 0);
        if (countStatus) {
          status.count = countStatus;
        } else {
          status.count = 0;
        }
        return status;
      });
      setStatusCount(mapStatus);
    }
  }, [data]);

  return (
    <StatusWrapper>
      <Nav tabs className="mb-0">
        {statusCount.map((item) => {
          if (!item) {
            return '';
          }
          return (
            <NavItem key={item.label} className="">
              <NavLink
                active={activeTab === item.id}
                onClick={() => {
                  toggle(item);
                }}
              >
                <div className="d-flex align-items-center justify-content-between">
                  <span
                    style={{ color: item.color }}
                    className="align-middle font-weight-bolder pl-50"
                  >
                    {item.label}
                  </span>
                  <div style={{ background: item.color }} className="count-status">
                    <span>{item.count}</span>
                  </div>
                </div>
              </NavLink>
            </NavItem>
          );
        })}
      </Nav>
    </StatusWrapper>
  );
}

Status.propTypes = {
  activeTab: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  handleChangeStatus: PropTypes.func,
  filter: PropTypes.any,
  isRefreshStatus: PropTypes.number,
};

export default Status;
