import axiosService from '@Lib/axiosService';
import { handleResponse } from '@Utils/helpers';

let host;
if (process.env.DASHBOARD_TYPE === 'customer') {
  host = `${process.env.API_CUSTOMER_URL}/notification`;
} else if (process.env.DASHBOARD_TYPE === 'admin') {
  host = `${process.env.API_ADMIN_URL}/notification`;
}

export const list = async (query) => {
  const res = await axiosService.get(`${host}/list`, query);
  return handleResponse(res);
};

export const read = async (data) => {
  const res = await axiosService.post(`${host}/read`, data);
  return handleResponse(res);
};
