import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import CustomerSuggest from '@Components/SuggestSearch/CustomerSuggest';
import { getInputErrorsObject } from '@Utils/helpers';

function SearchCustomer({
  name,
  labelName,
  labelCol,
  labelClassName,
  style,
  errors,
  setValue,
  allowAll,
  defaultValue,
  tradeType,
  emptyActive,
}) {
  const [errorDetail, setErrorDetail] = useState();
  const [selected, setSelected] = useState();

  useEffect(() => {
    if (name && errors) {
      setErrorDetail(getInputErrorsObject(name, errors));
    }
  }, [name, errors && Object.keys(errors)]);

  useEffect(() => {
    if (selected) {
      setValue(name, selected?.value, selected?.tradeType);
    } else {
      setValue(name, '');
    }
  }, [selected]);

  return (
    <CustomerSuggest
      emptyActive={emptyActive}
      allowAll={allowAll}
      name={name}
      style={style}
      labelName={labelName}
      labelCol={labelCol}
      labelClassName={labelClassName}
      selected={selected?.value}
      setSelected={(v, tradeType) => setSelected({ value: v, tradeType })}
      errorDetail={errorDetail}
      defaultValue={defaultValue}
      tradeType={tradeType}
    />
  );
}

SearchCustomer.propTypes = {
  name: PropTypes.string,
  labelName: PropTypes.string,
  labelClassName: PropTypes.string,
  labelCol: PropTypes.string,
  register: PropTypes.object,
  style: PropTypes.object,
  errors: PropTypes.object,
  setValue: PropTypes.func,
  defaultValue: PropTypes.any,
  allowAll: PropTypes.bool,
  emptyActive: PropTypes.bool,
  tradeType: PropTypes.any,
};

export default SearchCustomer;
