/* eslint-disable jsx-a11y/control-has-associated-label */
import TableEmpty from '@Components/DataTableBasic/TableEmpty';
import { formatNumber } from '@Utils/helpers';
import { renderQuantity, renderVTHT } from '@Views/Client/TransactionTPlus';
import dayjs from 'dayjs';
import React from 'react';

function TableTPlusDOM({ data }) {
  return (
    <div className="table-list-plus">
      <div className="wrap-table">
        {!(data && Object.keys(data).length > 0) ? (
          <TableEmpty />
        ) : (
          <table>
            <thead>
              <tr>
                <th style={{ background: '#EBEAED', width: 190 }} rowSpan={2}>
                  KHÁCH HÀNG
                </th>
                <th style={{ background: '#EBEAED' }} rowSpan={2}>
                  Mã CK
                </th>
                <th colSpan={2} style={{ background: 'rgba(98, 52, 148, 0.1)', color: '#623494' }}>
                  T+
                </th>
                {/* <th
                  className="text-align-center"
                  style={{ background: 'rgba(98, 52, 148, 0.1)', color: '#623494' }}
                  colSpan={3}
                >
                  T2
                </th> */}
                <th
                  rowSpan={2}
                  style={{ background: '#EBEAED', color: '#623494' }}
                  className="text-align-center"
                >
                  P/L
                </th>
              </tr>
              <tr style={{ fontSize: 14 }}>
                <th style={{ background: 'rgba(98, 52, 148, 0.1)' }}>Vị thế hiện tại</th>
                <th style={{ background: 'rgba(98, 52, 148, 0.1)' }}>Giá vốn / Giá TT</th>
                {/* <th style={{ background: 'rgb(211, 126, 23, 0.1)' }}>Vị thế hiện tại</th>
                <th style={{ background: 'rgb(211, 126, 23, 0.1)' }}>Giá vốn / Giá TT</th>
                <th style={{ background: 'rgb(211, 126, 23, 0.1)' }}>Deadline trả VT</th> */}
              </tr>
            </thead>
            <tbody>
              {Object.keys(data || {}).map((customerId) => {
                const customer = data[customerId];
                return Object.keys(customer || {}).map((stockId) => {
                  const t0 = customer[stockId].t0;
                  const t2 = customer[stockId].t2;
                  if (!t0 || !t2) return null;

                  const startValue = t0.quantity * (t0?.dca_price || 0);
                  let pl = t0.stock?.price * t0.quantity - startValue;
                  const t2startValue = t2.quantity * (t2?.dca_price || 0);
                  pl += t2.stock?.price * t2.quantity - t2startValue;
                  return (
                    <tr key={stockId}>
                      <td style={{ textAlign: 'left' }}>
                        {t0.protectedCustomer?.username || t2.protectedCustomer?.username}
                      </td>
                      <td>{stockId}</td>
                      <td>{renderVTHT(t0?.quantity)}</td>
                      <td style={{ textAlign: 'center' }}>
                        <div>
                          {formatNumber(t0.dca_price, '--')}/{formatNumber(t0.stock?.price, '--')}
                        </div>
                      </td>
                      {/* <td>{renderVTHT(t2?.quantity)}</td>
                      <td style={{ textAlign: 'right' }}>
                        <div>
                          {formatNumber(t2.dca_price, '--')}/{formatNumber(t2.stock?.price, '--')}
                        </div>
                      </td>
                      <td>
                        {t2?.liquidation_at
                          ? dayjs.unix(t2?.liquidation_at).format('DD/MM/YYYY HH:mm:ss')
                          : t2.t3 > 0
                          ? dayjs().format('DD/MM/YYYY') + ': ' + t2.t3
                          : '--'}
                      </td> */}
                      <td style={{ textAlign: 'center' }}>
                        {renderQuantity(pl, formatNumber(pl))}
                      </td>
                    </tr>
                  );
                });
              })}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
}

export default TableTPlusDOM;
