/* eslint-disable import/no-cycle */
/* eslint-disable react/jsx-wrap-multilines */
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import InputSearch from '@Components/InputSearch';
import DataTableBasic from '@Components/DataTableBasic';
import useCustomQuery from '@Utils/useCustomQuery';
import {
  stocks,
  changeStatus,
  overview,
  exportStock,
  stockConfigGet,
  holdByCustomer,
} from '@Apis/admin/stock';
import CustomeModal from '@Components/CustomeModal';
import useCustomMutation from '@Utils/useCustomMuation';
import { toastError, toastSuccess } from '@Utils/toastrHelper';
import ModalExport from '@Components/ModalExport';
import DropdownSelect from '@Components/DropdownSelect';
import { TYPE_CS, TYPE_PS, TYPE_TPLUS } from '@Components/Layouts/PrivateLayout';
import NavigationBar from '@Components/NavigationBar';
import { columns } from './components/Columns';
import AddStock from './components/AddStock';
import CardInfo from '../Dashboard/CardInfo';
import './styles.scss';
import { columnsAdminViTheMoPS } from '@Views/Client/TransactionPS/columns';
import { defaultPaging } from '@Views/Client/TransactionTPlus';
import TableTPlusDOM from '@Views/Client/AssetManager/TableListPlus/TableTPlus/TableTPlusDOM';
import { tplusActiveColumns } from './components/TplusActiveStockColumns';

export const TAB_PANEL = [
  { id: 1, name: 'CK Cơ sở' },
  { id: 2, name: 'CK T+' },
  { id: 3, name: 'CK Phái sinh' },
];

function Stock() {
  const [itemUpdate, setItemUpdate] = useState(null);
  const [page, setPage] = useState(0);
  const [filter, setFilter] = useState({ keyword: '', per_page: 10000000 });
  const [isActive, setIsActive] = useState(1);
  const [activeTab, setActiveTab] = useState(1);
  const [rowEdit, setRowEdit] = useState();

  const arrayData = useMemo(() => {
    if (activeTab === 2) {
      return [];
    }
    if (activeTab === 3) {
      return [
        // {
        //   label: 'Tổng mua - SL HD - Giá trị HD',
        //   key: 'total_quantity',
        //   icon: '/images/assetManager/Dola.svg',
        // },
        // {
        //   label: 'Tổng bán - SL HD - Giá trị HD',
        //   key: 'total_quantity',
        //   icon: '/images/assetManager/Dola.svg',
        // },
        // {
        //   label: 'Tổng tiền mặt',
        //   key: 'total_quantity',
        //   icon: '/images/assetManager/bank.svg',
        // },
      ];
    }
    return [
      {
        key: 'total_quantity',
        label: 'Tổng khối lượng',
        icon: '/images/assetManager/Dola.svg',
      },
      {
        key: 'total_value',
        label: 'Tổng giá trị',
        icon: '/images/assetManager/bank.svg',
      },
    ];
  }, [activeTab]);

  const filterOnChange = (name, value) => {
    setFilter((prevValues) => {
      return {
        ...prevValues,
        [name]: value,
      };
    });
    setPage(1);
  };

  useEffect(() => {
    if (page) {
      setFilter({ ...filter, page });
    }
  }, [page]);

  const { mutate, isSuccess, error } = useCustomMutation(changeStatus, true);

  const { data: overviewData } = useCustomQuery(overview);

  const dataState = arrayData.map((ele) => ({
    icon: ele.icon,
    text: ele.label,
    money: overviewData?.[ele.key],
  }));

  const onConfirm = () => {
    const { id, status } = itemUpdate;
    const dataUpdate = {
      id,
    };
    if (status === 'active') {
      dataUpdate.active = false;
    } else {
      dataUpdate.active = true;
    }
    mutate(dataUpdate);
  };

  useEffect(() => {
    if (isSuccess) {
      toastSuccess('Thay đổi trạng thái thành công');
      setItemUpdate(null);
      refetch();
      refechTP();
    }
    if (error) {
      toastError(error);
    }
  }, [isSuccess, error]);

  const { data, refetch } = useCustomQuery(
    stocks,
    { ...filter, trade_type: activeTab === 3 ? TYPE_PS : activeTab === 2 ? TYPE_TPLUS : TYPE_CS },
    true,
  );

  const { data: dataTP, refetch: refechTP } = useCustomQuery(
    stockConfigGet,
    { ...filter, is_active: isActive },
    true,
  );

  const { data: dataPosition } = useCustomQuery(
    holdByCustomer,
    {
      ...filter,
      stock_id: null,
      trade_type: activeTab === 3 ? TYPE_PS : activeTab === 2 ? TYPE_TPLUS : TYPE_CS,
    },
    true,
  );

  const dataDerivative = {};
  if (activeTab === 3 && dataPosition) {
    dataPosition.forEach((row) => {
      if (!dataDerivative[row.stock_id] || dataDerivative[row.stock_id].length == 0) {
        dataDerivative[row.stock_id] = [];
      }
      dataDerivative[row.stock_id].push(row);
    });
  }

  const setOrderBy = useCallback((column, type = '') => {
    setFilter((prevValues) => {
      return {
        ...prevValues,
        sort_by: column.sortField,
        order: type.toUpperCase(),
      };
    });
  }, []);

  const onExport = useCallback(async () => {
    return exportStock(filter);
  }, [filter]);

  const onEdit = useCallback((row) => {
    setRowEdit(row);
  }, []);

  return (
    <div className="content-wrapper asset-management ">
      <div className="d-flex align-items-center justify-content-between">
        <p className="font-size-28 font-weight-bolder">Danh mục mã chứng khoán</p>
        <ModalExport
          colorText="#1976d2"
          classNameButton="d-flex align-items-center"
          styleButton={{ height: 43.39, marginRight: 5, background: '#fff' }}
          onExport={onExport}
        />
      </div>
      <div className="d-flex justify-content-between align-items-center">
        <NavigationBar
          style={{ background: 'transparent' }}
          className="mt-1"
          activeTab={activeTab}
          tabs={TAB_PANEL}
          setActiveTab={setActiveTab}
        />
        <AddStock
          rowEdit={rowEdit}
          activeTab={activeTab}
          refetch={() => {
            refetch();
            refechTP();
          }}
        />
      </div>
      <div className="d-flex mb-2 align-items-end">
        <CardInfo
          style={{
            width: '100%',
            minHeight: activeTab !== 1 ? 112 : 'auto',
            backgroundColor: activeTab !== 1 ? '#fff' : null,
            marginTop: activeTab !== 1 ? 24 : null,
            paddingTop: activeTab !== 1 ? 0 : null,
          }}
          styleCard={{ width: `${100}%` }}
          dataState={dataState}
          childrenCustom={
            <div
              className="bg-white d-flex pt-2 justify-content-end"
              style={{
                minWidth: activeTab === 2 ? 512 : 300,
                padding: 24,
                borderLeft: activeTab === 2 ? 0 : '1px solid #ebeaed',
              }}
            >
              {activeTab === 2 ? (
                <DropdownSelect
                  styleWrap={{ width: '100%', marginRight: 10 }}
                  allowSearch
                  name="is_active"
                  valueSelected={isActive}
                  setValueSelected={(_, v) => setIsActive(v)}
                  styleMenu={{ maxHeight: 200, overflow: 'auto', width: '100%', marginRight: 10 }}
                  options={[
                    { label: 'Tất cả', value: 0 },
                    { label: 'Mã đang hiệu lực', value: 1 },
                  ]}
                  labelShow={false}
                />
              ) : null}
              <InputSearch
                minWidth={215}
                placeholder="Tìm mã..."
                searchKey={filter.keyword}
                name="keyword"
                setSearchKey={filterOnChange}
              />
            </div>
          }
        />
      </div>
      <div>
        {activeTab === 2 && <h4>Danh sách cấu hình</h4>}
        {activeTab === 3 && <h4>Danh sách hợp đồng</h4>}
        <DataTableBasic
          defaultSortFieldId="id"
          defaultSortAsc
          onSort={setOrderBy}
          columns={columns({ setItemUpdate, activeTab, onEdit }).filter(Boolean)}
          data={activeTab === 2 ? dataTP : data}
          isPageEffect
          page={page}
          setPage={setPage}
          fixedHeader
        />

        {activeTab === 2 && (
          <>
            <br />
            <br />
            <h4>Danh sách mã hoạt động</h4>
            <DataTableBasic
              defaultSortFieldId="id"
              defaultSortAsc
              onSort={setOrderBy}
              columns={tplusActiveColumns().filter(Boolean)}
              data={data}
              isPageEffect
              page={page}
              setPage={setPage}
            />

            <br />
            <br />
            <h4>Danh sách vị thế</h4>

            <TableTPlusDOM data={dataPosition} />
          </>
        )}

        {activeTab === 3 && (
          <>
            {Object.keys(dataDerivative || {}).length > 0 ? (
              <>
                {Object.keys(dataDerivative || {}).map((stockId) => {
                  const rows = dataDerivative[stockId];
                  return (
                    <>
                      <br />
                      <br />
                      <h4>Danh sách vị thế hợp đồng {stockId}</h4>
                      <DataTableBasic
                        setPage={setPage}
                        page={page}
                        data={{ data: rows || [], paging: defaultPaging }}
                        columns={columnsAdminViTheMoPS}
                      />
                    </>
                  );
                })}
              </>
            ) : null}
          </>
        )}
      </div>
      <CustomeModal
        isOpen={itemUpdate !== null}
        confirm={onConfirm}
        closeModal={() => setItemUpdate(null)}
        buttonName="Đồng ý"
        type="confirm"
        mess={`Thay đổi trạng thái mã CK ${itemUpdate ? itemUpdate.id : ''} `}
      />
    </div>
  );
}

export default Stock;
