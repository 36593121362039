import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Title, BackButton } from './styles';

function PageNotFound() {
  const navigate = useNavigate();
  const location = useLocation();

  if (location.pathname === '/login') {
    return null;
  }

  return (
    <div>
      <div className="d-flex justify-content-center pt-2">
        <img src="/images/logo/logo-vcl.svg" width="222" alt="logo" />
      </div>
      <div className="container pt-4">
        <div className="content-public-wrapper">
          <div className="d-flex justify-content-center">
            <div>
              <img src="/images/page/not-found.svg" width="700" alt="page not found" />
              <Title>Không tìm thấy trang.</Title>
              <p className="font-size-18 text-center">
                Vui lòng thử quay lại trang trước hoặc trở về trang chủ
              </p>
              <div className="w-100 text-center">
                <BackButton
                  onClick={() =>
                    navigate(process.env.DASHBOARD_TYPE === 'admin' ? '/dashboard' : '/home')
                  }
                >
                  Trang chủ
                </BackButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

PageNotFound.propTypes = {};

export default PageNotFound;
