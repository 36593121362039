/* eslint-disable no-unsafe-optional-chaining */
import { formatNumber } from '@Utils/helpers';
import React from 'react';

const renderValue = (value, type) => {
  if (type === '%') {
    return (
      <div style={{ color: value < 0 ? '#ED1C24' : '#14B13B' }}>
        {value < 0 ? (
          <img src="/images/assetManager/south_east.svg" alt="" />
        ) : (
          <img src="/images/assetManager/north_east.svg" alt="" />
        )}
        {value}%
      </div>
    );
  }
  if (value < 0) {
    return (
      <div style={{ color: '#ED1C24', width: '100%', textAlign: 'right' }}>
        {formatNumber(value)}
      </div>
    );
  }
  return (
    <div style={{ color: '#14B13B', width: '100%', textAlign: 'right' }}>
      {value > 0 ? '+' : ''}
      {formatNumber(value)}
    </div>
  );
};

export const columns = [
  {
    name: 'CỔ PHIẾU',
    cell: (row) => <div style={{ textAlign: 'center', fontWeight: 600 }}>{row.stock?.id}</div>,
    center: true,
    width: '90px',
    fixed: 'left',
  },
  {
    name: 'Giá mua',
    width: '80px',
    cell: (row) => {
      return (
        <div style={{ textAlign: 'right', width: '100%' }}>
          {formatNumber(row?.lastTradingItem?.current_cost_price || 0, '--')}
        </div>
      );
    },
    center: true,
    fixed: 'left',
  },
  {
    name: 'KHỐI LƯỢNG',
    width: '110px',
    center: true,
    cell: (row) => (
      <div style={{ width: '100%', textAlign: 'right' }}>{formatNumber(row.quantity, '--')}</div>
    ),
  },
  {
    name: 'Giá trị',
    width: '140px',
    cell: (row) => (
      <div style={{ width: '100%', textAlign: 'right' }}>
        {formatNumber(row?.lastTradingItem?.current_cost_price * row?.quantity, '--')}
      </div>
    ),
    center: true,
  },
  {
    name: 'GIÁ HIỆN TẠI',
    width: '120px',
    cell: (row) => (
      <div style={{ width: '100%', textAlign: 'right' }}>
        {formatNumber(row.stock?.price, '--')}
      </div>
    ),
    center: true,
  },
  {
    name: 'GT HIỆN TẠI',
    width: '140px',
    cell: (row) => (
      <div style={{ width: '100%', textAlign: 'right' }}>
        {formatNumber(row.quantity * row.stock?.price, '--')}
      </div>
    ),
    center: true,
  },
  {
    name: 'Lãi / LỖ ',
    width: '150px',
    cell: (row) => {
      const startValue = row.quantity * (row?.lastTradingItem?.current_cost_price || 0);
      const pnl_wait = row.stock.price * row.quantity - startValue;
      return renderValue(pnl_wait);
    },
    center: true,
  },
  {
    name: '% lãi / LỖ ',
    center: true,
    cell: (row) => {
      const startValue = row.quantity * (row?.lastTradingItem?.current_cost_price || 0);
      const pnl_wait = row.stock.price * row.quantity - startValue;
      const percent = (pnl_wait / startValue) * 100 || 0;
      return renderValue(percent.toFixed(2), '%');
    },
  },
];
