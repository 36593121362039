import axiosService from '@Lib/axiosService';
import { handleResponse } from '@Utils/helpers';

let url = '';
if (process.env.DASHBOARD_TYPE === 'admin') {
  url = `${process.env.API_ADMIN_URL}/purchase-account`;
}

export const wareHouseSummary = async () => {
  const res = await axiosService.get(`${url}/summary`);
  return handleResponse(res);
};

export const wareHouseIndex = async (query) => {
  const res = await axiosService.get(`${url}/list`, query);
  return handleResponse(res);
};

export const wareHouseExport = async (query) => {
  const res = await axiosService.get(`${url}/export`, query);
  return handleResponse(res);
};

export const wareHouseIndexPublic = async (query) => {
  const res = await axiosService.get(`${url}/list-for-select`, query);
  return handleResponse(res);
};

export const warehouseSave = async (body) => {
  const res = await axiosService.post(`${url}/save`, body);
  return handleResponse(res);
};

export const warehouseDetail = async (query) => {
  const res = await axiosService.get(`${url}/detail`, query);
  return handleResponse(res);
};

export const warehouseTransactionDetail = async (query) => {
  const res = await axiosService.get(`${url}/transaction-list`, query);
  return handleResponse(res);
};

export const warehouseStockDetail = async (query) => {
  const res = await axiosService.get(`${url}/customer-stock-list?`, query);
  return handleResponse(res);
};

export const ListAvailable = async (query) => {
  const res = await axiosService.get(`${url}/sell-availability`, query);
  return handleResponse(res);
};
