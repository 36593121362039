import styled from 'styled-components';

export const SummaryWrapper = styled.div`
  .summary {
    .col {
      border-right: 1px solid #ebeaed;
    }
    .col:last-child {
      border-right: none;
    }
  }
  .summary-description {
    .row {
      gap: 13px;
    }
    p {
      font-size: 14px;
    }
    .col {
      .summary-description-item {
        border-bottom: 1px solid #ebeaed;
        padding: 16px 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      .summary-description-item:last-child {
        border-bottom: none;
      }
    }
  }
`;
