import React, { useMemo } from 'react';
import { Row, Col } from 'reactstrap';
import TextInput from '@Components/MUInput/TextInput';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import './Info/styles.scss';

const arrayFieldProfile = [
  { title: 'Phí hợp tác', name: 'interest_rate' },
  { title: 'Phí giao dịch - mua', name: 'buy_rate' },
  { title: 'Phí giao dịch - bán', name: 'sell_rate' },
  // { title: 'Tỷ lệ ký quỹ', name: 'margin_rate' },
  { title: 'RTT', name: 'call_rate' },
  { title: 'Phí cọc thiếu', name: 'margin_interest_rate' },
];

function FeesRates(props) {
  const { profile } = props;
  const defaultValues = useMemo(() => {
    const data = {};
    arrayFieldProfile.forEach((key) => {
      data[key.name] = profile[key.name] ? `${profile[key.name]}%`.replace('.', ',') : '0';
    });
    return data;
  }, [profile]);

  const { register } = useForm({ defaultValues });

  return (
    <div className="profile">
      <div className="profile-title">Phí & Tỉ lệ</div>
      <Row className="profile-detail">
        <Col md="8">
          <form>
            {arrayFieldProfile.map((f) => (
              <TextInput
                key={f.name}
                labelName={f.title}
                placeholder={f.title}
                name={f.name}
                disabled
                register={register(f.name)}
              />
            ))}
          </form>
        </Col>
      </Row>
    </div>
  );
}

FeesRates.propTypes = {
  profile: PropTypes.object,
};

export default FeesRates;
