import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalBody } from 'reactstrap';
import NavigationBar from '@Components/NavigationBar';
import Deposit from './Deposit';
import Withdraw from './Withdraw';
import { TABS_POPUP_CREATE } from '../../constants';
import { PopUpWrapper } from './styles';

function PopupCreate({ isOpen, closeModal, refetch }) {
  const [activeTabId, setActiveTabId] = useState(1);

  return (
    <Modal
      isOpen={isOpen}
      toggle={closeModal}
      style={{
        maxWidth: '578px',
        maxHeight: '743px',
        padding: '0',
      }}
      className="text-center popup-create-trade"
    >
      <PopUpWrapper>
        <ModalBody className="p-0">
          <h3 className="py-1 text-center">TẠO LỆNH NỘP/RÚT</h3>
          <NavigationBar
            tabs={TABS_POPUP_CREATE}
            activeTab={activeTabId}
            setActiveTab={setActiveTabId}
          />
          <div className=" text-left p-2">
            {activeTabId === 1 && <Withdraw refetch={refetch} closeModal={closeModal} />}
            {activeTabId === 2 && (
              <Deposit type="Deposit" refetch={refetch} closeModal={closeModal} />
            )}
          </div>
        </ModalBody>
      </PopUpWrapper>
    </Modal>
  );
}

PopupCreate.propTypes = {
  isOpen: PropTypes.bool,
  closeModal: PropTypes.func,
  refetch: PropTypes.func,
};

export default PopupCreate;
