import dayjs from 'dayjs';
import React from 'react';

export const columns = (func) => [
  {
    name: 'NGÀY',
    cell: (r) => {
      const { to, from } = r;
      if (!to && !from) {
        return '--';
      }
      const dateTo = dayjs.unix(to).format('DD/MM/YYYY');
      const dateFrom = dayjs.unix(from).format('DD/MM/YYYY');
      if (dateTo === dateFrom) {
        return dateFrom;
      }
      return `${dateFrom} - ${dateTo}`;
    },
    width: '200px',
  },
  {
    name: 'Mô tả',
    cell: (r) => (
      <div className="uppercase" style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-all' }}>
        {r.description}
      </div>
    ),
  },
  {
    name: '',
    cell: (r) => (
      <div
        style={{ color: '#FC3400', fontSize: 14, cursor: 'pointer' }}
        className="d-flex align-items-center"
        onClick={() => func(r)}
        role="button"
        tabIndex="0"
      >
        Xoá
        <img style={{ marginLeft: 4 }} src="/images/payment-request/cancel.svg" alt="" />
      </div>
    ),
    center: true,
    width: '100px',
  },
];
