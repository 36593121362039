import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Comment } from '@mui/icons-material';
import { Tooltip } from 'reactstrap';

function TooltipComment({ item, placement = 'bottom', className }) {
  const [isOpenTooltip, setIsOpenTooltip] = useState(false);

  return item && item.note ? (
    <div className="d-flex align-items-center">
      <div id={`tooltip-comment-${item.id}`}>
        {item.label || (
          <>
            <Comment color="primary" />
            <span className="ml-25 text-primary">Xem</span>
          </>
        )}
      </div>
      <Tooltip
        className={className}
        autohide
        placement={placement}
        isOpen={isOpenTooltip}
        target={`tooltip-comment-${item.id}`}
        toggle={() => setIsOpenTooltip(!isOpenTooltip)}
      >
        {item.note}
      </Tooltip>
    </div>
  ) : (
    ''
  );
}

TooltipComment.propTypes = {
  item: PropTypes.object,
  placement: PropTypes.string,
  className: PropTypes.string,
};

export default TooltipComment;
