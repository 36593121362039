import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { NavLink } from 'reactstrap';
import { toggleMenuHasChild } from '@Lib/slices/uiSlice';
import { ChevronRight, KeyboardArrowDown } from '@mui/icons-material';
import { checkRole } from '@Utils/helpers';
import MenuChild from './MenuChild';
import PermissionMenu from '../PermissionMenu';

function ItemHasChild({ item, closeMenu }) {
  const location = useLocation();

  const role = useSelector((state) => state.user.role);

  const dispatch = useDispatch();
  const handleClickItem = () => {
    dispatch(toggleMenuHasChild(item));
  };

  const { pathname } = location;

  return (
    <li
      className={`nav-item ${
        item.isOpen || (pathname.includes(item.relatedPath) && item.isOpen)
          ? 'has-sub open menu-collapsed-open sidebar-group-active'
          : ''
      } ${pathname.includes(item.relatedPath) ? ' sidebar-group-active' : ''}`}
    >
      <NavLink
        className="d-flex align-items-center justify-content-between"
        type="button"
        onClick={() => handleClickItem()}
      >
        <div>
          {pathname.includes(item.relatedPath) ? (
            <img
              className="mr-1"
              width="18"
              alt="icon"
              src={`/images/icon/${item.icon}-active.svg`}
            />
          ) : (
            <img className="mr-1" width="18" alt="icon" src={`/images/icon/${item.icon}.svg`} />
          )}
          {closeMenu ? null : (
            <span className="font-size-16 tx-medium sidebar-menu-name">{item.name}</span>
          )}
        </div>
        {item.isOpen || pathname.includes(item.relatedPath) ? (
          <KeyboardArrowDown className="mr-0" fontSize="16px" />
        ) : (
          <ChevronRight className="mr-0" fontSize="16px" />
        )}
      </NavLink>
      {item.children && item.children.length && (
        <ul className="menu-content">
          <div className="collapse show">
            {item.children.map((item) => {
              if (!item.hidden) {
                return (
                  <PermissionMenu isPermission={checkRole(role, item.role)} key={item.id}>
                    <MenuChild closeMenu={closeMenu} pathname={pathname} item={item} />
                  </PermissionMenu>
                );
              }
            })}
          </div>
        </ul>
      )}
    </li>
  );
}

ItemHasChild.propTypes = {
  item: PropTypes.object,
  closeMenu: PropTypes.bool,
};

export default ItemHasChild;
