import React, { useCallback, useEffect, useRef, useState } from 'react';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import PropTypes from 'prop-types';
import { getInputErrorsObject } from '@Utils/helpers';

function TextInput({
  labelName,
  setValue,
  name,
  errors,
  validationText,
  register,
  disabled,
  style,
  className = 'form-input-custom',
}) {
  const [errorDetail, setErrorDetail] = useState();

  const handleChange = useCallback(
    (value) => {
      setValue(name, value);
    },
    [setValue],
  );

  useEffect(() => {
    if (name && errors) {
      setErrorDetail(getInputErrorsObject(name, errors));
    }
  }, [name, errors && Object.keys(errors)]);

  return (
    <FormControl fullWidth sx={{ m: 1 }} className={`m-0 pb-2 ${className}`} style={style}>
      <TextField
        {...register}
        disabled={!!disabled}
        id="standard-basic"
        onChange={(e) => handleChange(e.target.value)}
        label={labelName}
        variant="standard"
      />
      {errorDetail && (
        <p className="text-danger font-size-12 mb-0" style={{ paddingTop: '2px' }}>
          {errorDetail.type === 'validate'
            ? validationText
            : errorDetail.message || 'Trường bắt buộc'}
        </p>
      )}
    </FormControl>
  );
}

TextInput.propTypes = {
  name: PropTypes.string,
  labelName: PropTypes.string,
  setValue: PropTypes.func,
  errors: PropTypes.object,
  validationText: PropTypes.string,
  className: PropTypes.string,
  register: PropTypes.object,
  style: PropTypes.object,
  disabled: PropTypes.bool,
};

export default TextInput;
