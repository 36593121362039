import React from 'react';
import CardInfo from '@Views/Admin/Dashboard/CardInfo';
import './commission.scss';
import { formatNumber } from '@Utils/helpers';
import { commissionStatsCustomer, userSummary } from '@Apis/admin/user';
import useCustomQuery from '@Utils/useCustomQuery';
import { useParams } from 'react-router-dom';
import Month from './Month';

const columnsCustomer = [
  {
    name: 'Stt',
    cell: (_, index) => index + 1,
    center: true,
    width: '70px',
  },
  {
    name: 'KHÁCH HÀNG',
    cell: (r) => (
      <div style={{ minWidth: 120, textAlign: 'center' }} className="uppercase">
        {r.publicCustomer?.username}
      </div>
    ),
    center: true,
  },
  {
    name: 'TỔNG SALE',
    cell: (r) => (
      <div style={{ minWidth: 120, textAlign: 'center' }}>
        {formatNumber(r.salesCommission?.amount)}
      </div>
    ),
    center: true,
  },
  {
    name: 'TỔNG KSV',
    cell: (r) => (
      <div style={{ minWidth: 120, textAlign: 'center' }}>
        {formatNumber(r.supportCommission?.amount)}
      </div>
    ),
    center: true,
  },
  {
    name: 'TỔNG GDV',
    cell: (r) => <div style={{ minWidth: 120, textAlign: 'center' }}>{formatNumber(r.amount)}</div>,
    center: true,
  },
  {
    name: 'TỔNG HOA HỒNG',
    cell: (r) => (
      <div style={{ minWidth: 120, textAlign: 'center' }}>
        {formatNumber(r.tellersCommission?.amount)}
      </div>
    ),
    center: true,
  },
];

function Commission() {
  const { employeeId } = useParams();
  const { data } = useCustomQuery(userSummary, { user_id: employeeId }, true);

  const newData = [
    {
      icon: '/images/assetManager/Dola.svg',
      text: 'Tổng sale',
      money: data?.total_sales_amount || 0,
    },
    {
      icon: '/images/assetManager/balance.svg',
      text: 'Tổng KSV',
      money: data?.total_support_amoun || 0,
    },
    {
      icon: '/images/assetManager/stock_value.svg',
      text: 'Tổng GDV',
      money: data?.total_tellers_amount || 0,
    },
    {
      icon: '/images/assetManager/debt.svg',
      text: 'Tổng hoa hồng',
      money: data?.total_commission || 0,
      style: { color: '#14B13B' },
    },
  ];
  return (
    <div className="asset-management commission">
      <CardInfo
        style={{ paddingTop: 13, minHeight: 132 }}
        styleMoney={{ fontSize: 22 }}
        dataState={newData}
      />
      <Month />
    </div>
  );
}

Commission.propTypes = {};

export default Commission;
