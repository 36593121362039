/* eslint-disable import/no-cycle */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, Row, Col, Container } from 'reactstrap';
import { Clear } from '@mui/icons-material';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import useCustomMutation from '@Utils/useCustomMuation';
import { editTransaction } from '@Apis/client/transaction';
import { stockAsset } from '@Apis/client/stock';
import TextInput from '@Components/Input/TextInput';
import { toastError, toastSuccess } from '@Utils/toastrHelper';
import useCustomQuery from '@Utils/useCustomQuery';
import { TradeHistoryWrapper } from './TradeHistoryStyles';

function TradeHistoryPopup({ isOpen, closeModal, refetch, item, customer_id }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();
  const { mutate, isSuccess, error } = useCustomMutation(editTransaction, true);

  const { data: dataAssets } = useCustomQuery(stockAsset, { stock_id: item.stock_id }, false, {
    enabled: item.type === 'buy',
  });
  const info = useSelector((state) => state.user.info);
  const isDer = item?.stock?.trade_type === 'derivative'
  const onSubmit = (formData) => {
    formData.id = item.id;
    formData.customer_id = customer_id;
    mutate(formData);
  };
  useEffect(() => {
    if (isSuccess) {
      toastSuccess('Sửa lệnh thành công');
      closeModal();
      refetch();
    }
    if (error) {
      toastError(error);
    }
  }, [isSuccess, error]);

  const calcMaxQuantity = (item) => {
    const { purchase_quantity, sell_quantity, selling_quantity } = item;
    const itemMax = purchase_quantity - sell_quantity - selling_quantity;
    return itemMax;
  };

  const calcMaxQuantityCanBuy = () => {
    // current_balance * margin_rate / giá của stock

    const { current_balance, margin_rate } = info;
    if (!current_balance || !margin_rate || !dataAssets?.stock?.price) {
      return 0;
    }
    return Math.floor((current_balance * margin_rate) / dataAssets.stock.price);
  };

  return (
    <TradeHistoryWrapper>
      <Modal
        isOpen={isOpen}
        toggle={closeModal}
        className="text-center model-warehouse model-trade-edit"
      >
        <div className={`${item.type === 'buy' ? 'bg-buy' : 'bg-sell'}`}>
          <div className="d-flex justify-content-end">
            <Clear size={14} onClick={closeModal} />
          </div>
          {item.type === 'buy' ? (
            <>
              <p className="font-size-24">SỬA LỆNH MUA</p>
              <div className="d-flex align-items-center justify-content-center my-1">
                <span className="font-weight-bold">Sức mua: </span>
                <span className="font-size-32 font-weight-100 px-2 color-matte-gray"> | </span>
                <span className="font-weight-bold">Đang sở hữu: {dataAssets?.quantity}</span>
                <span className="font-size-32 font-weight-100 px-2 color-matte-gray"> | </span>
                <span className="font-weight-bold">KL tối đa: {calcMaxQuantityCanBuy()}</span>
              </div>
            </>
          ) : (
            <>
              <p className="font-size-24">SỬA LỆNH BÁN</p>
              <div className="d-flex align-items-center justify-content-center my-1">
                <span className="font-weight-bold">Sức mua: </span>
                <span className="font-size-32 font-weight-100 px-2 color-matte-gray"> | </span>
                <span className="font-weight-bold">Đang sở hữu: {item.purchase_quantity}</span>
                <span className="font-size-32 font-weight-100 px-2 color-matte-gray"> | </span>
                <span className="font-weight-bold">KL tối đa: {calcMaxQuantity(item)}</span>
              </div>
            </>
          )}

          <Container className="">
            <form onSubmit={handleSubmit(onSubmit)}>
              <Row className="">
                <Col>
                  <TextInput
                    labelCol="12"
                    inputCol="12"
                    type="text"
                    labelClassName="font-size-14 mb-50"
                    labelName="Mã CK *"
                    placeholder="Mã CK"
                    setValue={setValue}
                    name="stock_id"
                    register={{ ...register('stock_id') }}
                    errors={errors}
                    defaultValue={item ? item.stock_id : ''}
                    disabled
                  />
                </Col>
                <Col>
                  <TextInput
                      disabled={true}
                    labelCol="12"
                    inputCol="12"
                    type="number"
                    labelClassName="font-size-14 mb-50"
                    labelName="Khối lượng *"
                    placeholder="Khối lượng *"
                    setValue={setValue}
                    name="quantity"
                    register={{
                      ...register('quantity', {
                        required: true,
                        // validate: (value) => value >= 100 || 'Số lượng phải lớn hơn hoặc bằng 100',
                      }),
                    }}
                    errors={errors}
                    defaultValue={item ? item.quantity : ''}
                  />
                </Col>
                <Col>
                  <TextInput
                    labelCol="12"
                    inputCol="12"
                    type={isDer ? 'pricenumber' : 'price'}
                    labelClassName="font-size-14 mb-50"
                    labelName="Giá bán *"
                    placeholder="Giá bán *"
                    setValue={setValue}
                    name="price"
                    register={{ ...register('price', { required: true }) }}
                    errors={errors}
                    defaultValue={item ? item?.price : ''}
                  />
                </Col>
              </Row>
              <TextInput
                labelCol="12"
                inputCol="12"
                type="textarea"
                labelClassName="font-size-14 mb-50"
                labelName="Ghi chú "
                placeholder="Ghi chú "
                setValue={setValue}
                name="note"
                register={{ ...register('note') }}
                errors={errors}
              />
              <div className="d-line mt-1" />
              <Row className="justify-content-center gap-20 py-2">
                <Button size="lg" color="primary">
                  <p className="text-center font-size-16">Sửa</p>
                </Button>
                <Button type="button" onClick={() => closeModal()}>
                  <p className="text-center font-size-16">Hủy</p>
                </Button>
              </Row>
            </form>
          </Container>
        </div>
      </Modal>
    </TradeHistoryWrapper>
  );
}

TradeHistoryPopup.propTypes = {
  isOpen: PropTypes.bool,
  closeModal: PropTypes.func,
  customer_id: PropTypes.number,
  refetch: PropTypes.func,
  item: PropTypes.object,
};

export default TradeHistoryPopup;
