/* eslint-disable react/jsx-wrap-multilines */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import TextInput from '@Components/Input/TextInput';
import { Button, Container, Row, Col } from 'reactstrap';
import SelectInput from '@Components/Input/SelectInput';
import ButtonSubmit from '@Components/ButtonSubmit';
import { useSelector } from 'react-redux';
import useCustomMutation from '@Utils/useCustomMuation';
import { modifyBank } from '@Apis/profile/bank-account';
import { toastError, toastSuccess } from '@Utils/toastrHelper';
import FieldInput from '@Views/Client/Recharge/BodyTab/Request/FieldInput';
import DropdownSelect from '@Components/DropdownSelect';

function BankAccountModify({ hide, item }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm();
  const bankList = useSelector((state) => state.user.bankList);
  const { mutate, isSuccess, error, isLoading } = useCustomMutation(modifyBank);

  const bankId = watch('bank_id');

  const onSubmit = (data) => {
    if (item) {
      data.id = item.id;
    }
    mutate(data);
  };

  useEffect(() => {
    if (isSuccess) {
      if (item) {
        toastSuccess('Cập nhật tài khoản thành công');
      } else {
        toastSuccess('Thêm tài khoản thành công');
      }
      hide(false);
    }
    if (error) {
      toastError(error);
    }
  }, [isSuccess, error, item]);

  return (
    <div className="bg-white">
      <p className="font-size-18 m-0 font-weight-bold p-2">
        {item ? 'Sửa tài khoản rút tiền' : 'Thêm tài khoản rút tiền'}
      </p>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Container>
          <Row>
            <Col md="6">
              <TextInput
                labelCol="12"
                inputCol="12"
                type="text"
                labelClassName="font-size-12"
                inputClassName="input-simple"
                labelName="Tên tài khoản"
                setValue={setValue}
                name="account_name"
                register={{
                  ...register('account_name', { required: true }),
                }}
                errors={errors}
                defaultValue={item ? item.account_name : ''}
              />
              <TextInput
                labelCol="12"
                inputCol="12"
                labelClassName="font-size-12"
                inputClassName="input-simple"
                type="number"
                labelName="Số tài khoản"
                setValue={setValue}
                name="account_number"
                register={{
                  ...register('account_number', { required: true }),
                }}
                errors={errors}
                defaultValue={item ? item.account_number : ''}
              />
              <FieldInput
                errorMessage={errors.bank_id}
                className="select-user"
                required
                component={
                  <DropdownSelect
                    allowSearch
                    setValueSelected={(_, v) => setValue?.('bank_id', v)}
                    valueSelected={bankId}
                    styleMenu={{ maxHeight: 200, overflow: 'auto', width: '100%' }}
                    options={bankList}
                    labelKey="name"
                    labelValue="id"
                    labelShow={false}
                  />
                }
                label="Ngân hàng"
              />
            </Col>

            <div className="d-line" />
          </Row>
        </Container>

        <div className="d-flex p-2">
          <ButtonSubmit color="primary" size="sm" name="Lưu thay đổi" isLoading={isLoading} />
          <Button type="button" outline size="sm" className="ml-1" onClick={() => hide(false)}>
            Hủy
          </Button>
        </div>
      </form>
    </div>
  );
}

BankAccountModify.propTypes = {
  hide: PropTypes.func,
  item: PropTypes.object,
};

export default BankAccountModify;
