/* eslint-disable react/prop-types */
import React from 'react';
import { formatVND } from '@Utils/helpers';
import dayjs from 'dayjs';

export const columns = () => {
  return [
    {
      name: 'ID',
      cell: (r) => r.id,
      center: true,
      width: '100px',
    },
    {
      name: 'HỌ TÊN',
      cell: (r) => <div className="uppercase">{r.publicCustomer?.username}</div>,
    },
    {
      name: 'Loại',
      cell: (r) => {
        const { type } = r;
        return type === 'buy' ? (
          <div style={{ background: 'rgba(32, 201, 172, 0.1)' }}>
            <p className="text-success font-size-14 font-weight-bolder py-25 px-1">Mua</p>
          </div>
        ) : (
          <div style={{ background: 'rgba(252, 52, 0, 0.1)' }}>
            <p className="text-danger font-size-14 font-weight-bolder py-25 px-1">Bán</p>
          </div>
        );
      },
      center: true,
    },
    {
      name: 'mã CK',
      cell: (r) => r.stock_id,
      center: true,
    },
    {
      name: 'Giá khớp',
      cell: (r) => formatVND(r.price),
      center: true,
    },
    {
      name: 'KL khớp',
      cell: (r) => formatVND(r.quantity),
      center: true,
    },
    {
      name: 'Giá trị',
      cell: (r) => formatVND(r.total),
      center: true,
    },
    {
      name: 'Ngày GD',
      cell: (r) => (r.created_at ? dayjs.unix(r.created_at).format('DD/MM/YYYY') : '--'),
    },
  ];
};
