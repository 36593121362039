import { accountsHome } from '@Apis/client/home';
import { TYPE_TPLUS } from '@Components/Layouts/PrivateLayout';
import useCustomQuery from '@Utils/useCustomQuery';
import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import TablePS from './TablePS';
import TableTPlus from './TableTPlus';
import './styles.scss';

function TableListPlus({ type, accounts }) {
  let productType = useSelector(({ ui }) => ui.productType);
  if (type) {
    productType = type;
  }
  const { customerId } = useParams();

  const { data: dataAccounts } = useCustomQuery(
    process.env.DASHBOARD_TYPE === 'admin' ? () => ({ data: {} }) : accountsHome,
    { customer_id: customerId },
  );
  const account = (dataAccounts?.accounts || accounts)?.find((e) => e.trade_type === productType);

  if (!account?.id) return null;

  return (
    <div className="table-list-plus p-2">
      <div className="title-plus">Vị thế</div>
      {productType === TYPE_TPLUS ? (
        <TableTPlus accountId={account?.id} />
      ) : (
        <TablePS accountId={account?.id} />
      )}
    </div>
  );
}

TableListPlus.propTypes = {
  type: PropTypes.any,
  accounts: PropTypes.any,
};

export default TableListPlus;
