import styled from 'styled-components';

export const SelectWrapper = styled.div`
  .form-control {
    option {
      padding: 5px 0 !important;
      height: 40px !important;
    }
  }
`;
