export const LEFT_COLUMN_PROFIT = [
  {
    type: 'title',
    title: 'DOANH THU ĐÃ THỰC HIỆN',
    name: 'revenue',
  },
  // {
  //   type: 'child',
  //   title: 'Lãi vay lũy kế',
  //   name: 'accumulated_loan_interest',
  // },
  {
    type: 'child',
    title: 'Lãi vay',
    name: 'loan_interest',
  },
  // {
  //   type: 'child',
  //   title: 'Phí giao dịch lũy kế',
  //   name: 'accumulated_transaction_fees',
  // },
  {
    type: 'child',
    title: 'Phí giao dịch',
    name: 'transaction_fees',
  },
  {
    type: 'child',
    title: 'Lãi vay cọc thiếu',
    name: 'short_interest_loan',
  },
  {
    type: 'child',
    title: 'Hoa hồng môi giới',
    name: 'brokerage_commission',
    canEdit: true,
  },
  {
    type: 'title',
    title: 'DOANH THU CHƯA THỰC HIỆN',
    name: 'provisional_revenue',
  },
  {
    type: 'child',
    title: 'Phí bán tạm thu',
    name: 'provisional_sell_fees',
  },
  {
    type: 'title',
    title: 'TỔNG DOANH THU',
    name: 'total_revenue',
  },
  {
    type: 'title',
    title: 'CHI PHÍ TÀI CHÍNH',
    name: 'financial_expenses',
  },
  // {
  //   type: 'child',
  //   title: 'Lãi huy động lũy kế',
  //   name: 'accumulated_deposit_interest',
  // },
  {
    type: 'child',
    title: 'Lãi huy động',
    name: 'deposit_interest',
  },
  {
    type: 'child',
    title: 'Phí giao dịch trả CTCK',
    name: 'transaction_fee',
    canEdit: true,
  },
  {
    type: 'child',
    title: 'Lãi vay margin',
    name: 'margin_loan_interest',
    canEdit: true,
  },
  {
    type: 'title',
    title: 'CHI PHÍ HOẠT ĐỘNG',
    name: 'operating_costs',
  },

  {
    type: 'child',
    title: 'Phí Hợp động tư vấn dịch vụ',
    name: 'consultant_fee',
    canEdit: true,
  },
  {
    type: 'child',
    title: 'Hoa hồng Kho',
    name: 'warehouse_commission',
    canEdit: true,
  },
  {
    type: 'title',
    title: 'TỔNG CHI PHÍ',
    name: 'total_cost',
  },
  {
    type: 'title',
    title: 'LỢI NHUẬN THUẦN TỪ HĐKD',
    name: 'profit',
  },
];

// derivative
export const LEFT_COLUMN_PROFIT_PS = [
  {
    type: 'title',
    title: 'DOANH THU HĐKD',
    name: 'derivative_total_revenue',
  },
  {
    type: 'child',
    title: 'Phí GD',
    name: 'derivative_transaction_fees',
  },
  {
    type: 'child',
    title: 'Phí QL VT',
    name: 'derivative_rollover_rate',
  },
  {
    type: 'child',
    title: 'Lãi vay ký quỹ',
    name: 'derivative_short_interest_loan',
  },
  {
    type: 'title',
    title: 'CHI PHÍ HĐKD',
    name: 'derivative_total_cost',
  },
  {
    type: 'child',
    title: 'Phí GD VSD',
    name: 'derivative_pay_transaction_fees',
  },
  {
    type: 'child',
    title: 'Phí QL vị thế VSD',
    name: 'derivative_pay_rollover_rate',
  },
  {
    type: 'child',
    title: 'Phí GD Kho',
    name: 'derivative_purhcase_fee',
  },
  {
    type: 'child',
    title: 'Thuế',
    name: 'derivative_tax',
  },
  {
    type: 'title',
    title: 'LỢI NHUẬN HĐKD',
    name: 'derivative_profit_hdkd',
  },
  {
    type: 'title',
    title: 'CHI PHÍ CHUNG',
    name: 'derivative_operating_costs',
  },
  {
    type: 'child',
    title: 'Phí QL TS ký quỹ',
    name: 'derivative_property_management_fees',
    canEdit: true,
  },
  {
    type: 'child',
    title: 'Phí nộp rút tiền',
    name: 'derivative_deposit_withdraw_fees',
    canEdit: true,
  },
  {
    type: 'child',
    title: 'Phí hoa hồng KD',
    name: 'derivative_commission',
    canEdit: true,
  },
  {
    type: 'title',
    title: 'LỢI NHUẬN RÒNG',
    name: 'derivative_profit',
  },
];

export const COMISSION_TYPE = [
  {
    label: 'Tất cả',
    value: 'sales,surveyor,tellers',
  },
  {
    label: 'Sale',
    value: 'sale',
  },
  {
    label: 'Kiểm soát viên',
    value: 'surveyor',
  },
  {
    label: 'Giao dịch viên',
    value: 'tellers',
  },
];

export const TABS = [
  { id: 1, name: 'BALANCE SHEET', slug: 'balance-sheet' },
  { id: 2, name: 'LÃI/LỖ', slug: 'profit-loss-report' },
  { id: 3, name: 'TỔNG HỢP GIAO DỊCH', slug: 'transaction-report' },
  { id: 4, name: 'HOA HỒNG KHO', slug: 'commission-report' },
];
