import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { customerTransactions } from '@Apis/admin/customer';
import DataTableBasic from '@Components/DataTableBasic';
import Type from '@Components/PaymentRequest/Type';
import {formatNumber, formatVND} from '@Utils/helpers';
import dayjs from 'dayjs';
import { transactions } from '@Apis/client/account';
import { useSelector } from 'react-redux';
import useCustomQuery from '@Utils/useCustomQuery';
import { accountsHome } from '@Apis/client/home';

export function renderPrice(price, fontSize = 14) {
  if (price >= 0) {
    return (
      <div style={{ fontSize, color: '#14B13B' }}>
        {price !== 0 ? '+' : ''}
        {formatNumber(price)}
      </div>
    );
  }
  return <div style={{ fontSize, color: '#FC3400' }}>{formatNumber(price)}</div>;
}

const columns = () => {
  return [
    {
      name: 'STT',
      cell: (row) => row.id,
      width: '110px',
      center: true,
      sortable: true,
      sortField: 'id',
    },
    {
      name: 'Loại',
      cell: (row) => <Type type={row.sub_type} />,
      width: '150px',
      center: true,
    },
    {
      name: 'Số tiền',
      cell: (row) => (
        <div style={{ width: '100%', textAlign: 'right' }}>{renderPrice(row.amount)}</div>
      ),
      center: true,
      width: '140px',
      sortable: true,
      sortField: 'amount',
    },
    {
      name: 'Thời gian',
      cell: (row) => dayjs.unix(row.created_at).format('HH:mm - DD/MM/YYYY'),
      center: true,
      width: '155px',
      sortable: true,
      sortField: 'created_at',
    },
    {
      name: 'Ghi chú',
      cell: (row) => row.note,
    },
  ];
};

function TransactionTab3({ isClient, filter: filterProps, accountId, setWrapFilter }) {
  const productType = useSelector((state) => state.ui.productType);

  const { data: dataAccounts } = useCustomQuery(accountsHome);

  const account = dataAccounts?.accounts?.find((e) => e.trade_type === productType);

  const [data, setData] = useState([]);

  const [filter, setFilter] = useState({
    customer_id: accountId,
    per_page: 12,
  });

  useEffect(() => {
    setWrapFilter && setWrapFilter((pre) => ({ ...pre, ...filterProps, ...filter }));
  }, [filter]);

  const search = useCallback(() => {
    let api = customerTransactions;
    if (isClient) {
      api = transactions;
    }
    api({ ...filterProps, ...filter, customer_id: isClient ? account?.id : accountId }).then(
      setData,
    );
  }, [filter, filterProps, account?.id, accountId]);

  const [page, setPage] = useState(0);

  useEffect(() => {
    if (page) {
      setFilter({ ...filter, page });
    }
  }, [page]);

  useEffect(() => {
    search();
  }, [filter, filterProps, accountId, account?.id]);

  const setOrderBy = useCallback((column, type = '') => {
    setFilter((prevValues) => {
      return {
        ...prevValues,
        sort_by: column.sortField,
        order: type.toUpperCase(),
      };
    });
  }, []);

  return (
    <div>
      <DataTableBasic
        onSort={setOrderBy}
        columns={columns()}
        data={data}
        isPageEffect
        page={page}
        setPage={setPage}
      />
    </div>
  );
}

TransactionTab3.propTypes = {
  filter: PropTypes.object,
  isClient: PropTypes.bool,
  accountId: PropTypes.any,
  setWrapFilter: PropTypes.any,
};

export default TransactionTab3;
