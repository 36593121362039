import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from 'reactstrap';
import { Clear } from '@mui/icons-material';
import { Title, ModalWrapper } from './styles';

function CustomeModal({
  isOpen,
  confirm,
  type,
  buttonName,
  closeModal,
  mess,
  className,
  title,
  icon,
  sourceIcon,
  footer,
  hiddenTitle,
  width,
}) {
  let color;
  switch (type) {
    case 'success':
      color = 'success';
      break;
    case 'delete':
      color = 'danger';
      break;
    case 'confirm':
      color = 'primary';
      break;
    default:
      break;
  }
  return (
    <Modal
      isOpen={isOpen}
      style={{ maxWidth: width }}
      toggle={closeModal}
      className={`text-center ${className}`}
    >
      <ModalWrapper>
        <div className="d-flex justify-content-end">
          <Clear size={14} onClick={closeModal} />
        </div>
        {!hiddenTitle ? <Title>{title || 'Xác nhận'}</Title> : null}
        {icon !== undefined ? (
          icon
        ) : (
          <img
            src={sourceIcon || '/images/page/confirm.svg'}
            alt="confirm"
            width="300"
            className="mx-auto"
          />
        )}
        {typeof mess === 'string' ? (
          <p className="font-size-20 font-weight-600 my-2">{mess}</p>
        ) : (
          mess
        )}
        {footer !== undefined ? (
          footer
        ) : (
          <>
            <div className="d-line" />
            <div>
              <Button
                color={color}
                size="sm"
                className="mt-1 py-1 px-2 mr-1"
                onClick={() => confirm()}
              >
                {buttonName}
              </Button>
              <Button size="sm" className="mt-1 py-1 px-2 " onClick={() => closeModal()}>
                Huỷ
              </Button>
            </div>
          </>
        )}
      </ModalWrapper>
    </Modal>
  );
}

CustomeModal.propTypes = {
  confirm: PropTypes.func,
  isOpen: PropTypes.bool,
  hiddenTitle: PropTypes.bool,
  toggle: PropTypes.func,
  type: PropTypes.string,
  className: PropTypes.string,
  buttonName: PropTypes.string,
  closeModal: PropTypes.func,
  mess: PropTypes.any,
  title: PropTypes.string,
  sourceIcon: PropTypes.string,
  icon: PropTypes.any,
  footer: PropTypes.any,
  width: PropTypes.any,
};

export default CustomeModal;
