import React, { useState, useRef, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { customersSuggest, customerDetail } from '@Apis/admin/customer';
import useCustomQuery from '@Utils/useCustomQuery';
import { typeConst } from '@Components/Layouts/PrivateLayout';
import SuggestSearch from '.';

function CustomerSuggest({
  name,
  labelName,
  labelCol,
  labelClassName,
  inputCol,
  selected,
  setSelected,
  errorDetail,
  defaultValue,
  allowAll,
  style,
  tradeType,
  emptyActive,
}) {
  const typingRef = useRef(null);
  const [value, setValue] = useState('');
  const [search, setSearch] = useState('');
  const [suggestions, setSuggestions] = useState([]);

  const getSuggestionValue = (suggestion) => {
    if (suggestion) {
      setSelected(suggestion.id, suggestion.trade_type);
    } else {
      setSelected('');
    }
    return suggestion.username;
  };

  useEffect(() => {
    if (!search) {
      setSelected('');
    }
  }, [search]);
  const { data } = useCustomQuery(
    customersSuggest,
    { keyword: search, status: 'active', trade_type: tradeType ?? 'all' },
    false,
    {
      enabled: !selected,
    },
  );
  const { data: dataDefault } = useCustomQuery(customerDetail, { id: defaultValue }, false, {
    enabled: !!defaultValue && selected == false,
  });

  const onChange = useCallback((event, { newValue }) => {
    if (event.type == 'change') setSelected();

    setValue(newValue);
    if (typingRef.current) {
      clearTimeout(typingRef.current);
    }
    typingRef.current = setTimeout(() => {
      setSearch(newValue);
    }, 300);
  }, []);

  useEffect(() => {
    if (data && !selected) {
      setSuggestions(data.data);
    } else {
      setSuggestions([]);
    }
  }, [data, selected]);

  useEffect(() => {
    if (dataDefault) {
      const typeName = typeConst[dataDefault.trade_type]?.label || '';
      const displayName = typeName ? `${dataDefault.username} (${typeName})` : dataDefault.username;
      setValue(displayName);
      setSelected(dataDefault.id);
    }
  }, [dataDefault]);

  const renderSuggestion = (suggestion) => {
    return (
      <div className="uppercase">
        <p className="mb-0">{suggestion.username}</p>
      </div>
    );
  };

  const inputProps = {
    placeholder: 'Chọn khách hàng',
    type: 'search',
    value,
    onChange,
  };

  let suggestionsNew = suggestions.map((u) => {
    const typeName = typeConst[u.trade_type]?.label || '';
    const displayName = typeName ? `${u.username} (${typeName})` : u.username;
    return {
      username: displayName,
      id: u.id,
      trade_type: u.trade_type,
    };
  });

  if (allowAll) {
    suggestionsNew =
      suggestions.length > 0 ? [{ username: 'Tất cả', id: '' }, ...suggestionsNew] : suggestionsNew;
  }

  return (
    <SuggestSearch
      style={style}
      name={name}
      labelName={labelName}
      labelCol={labelCol}
      labelClassName={labelClassName}
      inputCol={inputCol}
      errorDetail={errorDetail}
      suggestions={emptyActive ? [] : suggestionsNew}
      inputProps={inputProps}
      getSuggestionValue={getSuggestionValue}
      renderSuggestion={renderSuggestion}
    />
  );
}

CustomerSuggest.propTypes = {
  name: PropTypes.string,
  labelName: PropTypes.string,
  labelClassName: PropTypes.string,
  labelCol: PropTypes.string,
  inputCol: PropTypes.string,
  selected: PropTypes.any,
  setSelected: PropTypes.func,
  errorDetail: PropTypes.object,
  style: PropTypes.object,
  defaultValue: PropTypes.any,
  allowAll: PropTypes.bool,
  emptyActive: PropTypes.bool,
  tradeType: PropTypes.any,
};

export default CustomerSuggest;
