import React, { useState, useCallback, memo } from 'react';
import DropdownSelect from '@Components/DropdownSelect';
import InputSearch from '@Components/InputSearch';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import DatePicker from '@Components/DatePicker';
import dayjs from 'dayjs';

const optionsSelect = [
  { label: 'Tất cả', value: '' },
  {
    label: 'Chưa tất toán',
    value: 0,
  },
  {
    label: 'Đã tất toán',
    value: 1,
  },
];

function Filter(props) {
  const { onSearch } = props;
  const [valueState, setValueSelected] = useState({
    is_settlement: '',
    stock_id: '',
    date_range: [],
  });

  const handleSetValue = useCallback(
    (_, value) => {
      setValueSelected({ ...valueState, is_settlement: value });
      onSearch?.({ ...valueState, is_settlement: value });
    },
    [valueState, onSearch],
  );

  const handleSetValueInput = useCallback(
    (_, value) => {
      setValueSelected({ ...valueState, stock_id: value });
      onSearch?.({ ...valueState, stock_id: value });
    },
    [valueState, onSearch],
  );

  const handleChange = useCallback(
    (v) => {
      setValueSelected({ ...valueState, date_range: v });
      onSearch?.({
        ...valueState,
        date_range: v
          .map((e) => dayjs(e).unix())
          .toString()
          .replace(',', '-'),
      });
    },
    [onSearch, valueState],
  );

  return (
    <div className="transaction-list-filter">
      <div className="dropdown-select">
        <DropdownSelect
          setValueSelected={handleSetValue}
          valueSelected={valueState.is_settlement}
          options={optionsSelect}
        />
      </div>
      <div className="dropdown-select">
        <InputSearch
          className="input-search-transaction-list"
          setSearchKey={handleSetValueInput}
          placeholder="Tìm Mã CK..."
          name="stock_id"
        />
      </div>
      <div className="date-range-select" style={{ minWidth: 226 }}>
        <DatePicker.Range
          value={valueState.date_range}
          placeholder="Chọn ngày"
          onChange={handleChange}
        />
      </div>
    </div>
  );
}

Filter.propTypes = {
  onSearch: PropTypes.func,
};

export default memo(Filter, () => true);
