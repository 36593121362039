import { listDeposit } from '@Apis/profile/bank-account';
import useCustomQuery from '@Utils/useCustomQuery';
import useCustomMutation from '@Utils/useCustomMuation';
import ButtonSubmit from '@Components/ButtonSubmit';
import { save } from '@Apis/client/payment-request';
import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toastSuccess } from '@Utils/toastrHelper';
import PropTypes from 'prop-types';
import CardContent from './CardContent';
import FieldInput from '../FieldInput';
import { useSelector } from 'react-redux';

function Payment(props) {
  const { refechDateHistory, account } = props;
  const [active, setActive] = useState(0);
  const [{ amount, errorMessage }, setAmount] = useState({ amount: '', errorMessage: '' });
  const { mutate, isLoading } = useCustomMutation(save, true);
  const { data: listData = [] } = useCustomQuery(listDeposit, { customer_id: account.id });
  const navigate = useNavigate();
  const productType = useSelector((state) => state.ui.productType);

  const handleChange = useCallback((v) => {
    setAmount({ amount: v });
  }, []);

  const handleSubmit = useCallback(() => {
    if (!amount && amount !== 0) {
      setAmount({ errorMessage: 'Vui lòng nhập số tiền đã nộp' });
    } else if (listData[active]?.id) {
      mutate(
        {
          customer_id: account.id,
          type: 'deposit',
          bank_account_id: listData[active]?.id,
          amount: Number(amount),
          note: '',
        },
        {
          onSuccess: () => {
            navigate(`/${productType}/recharge?page=1`);
            setAmount({ amount: '' });
            refechDateHistory();
            toastSuccess('Cập nhật yêu cầu thành công');
          },
        },
      );
    }
  }, [amount, active, listData]);

  return (
    <div className="payment">
      <span className="title">Chọn ngân hàng bạn đã chuyển tiền</span>
      <div className="over-scroll-payment">
        <div style={{ marginTop: 17, minWidth: 350 }} className="wrap-list-card">
          {listData.map((d, key) => (
            <CardContent
              key={key.toString()}
              onClick={() => setActive(key)}
              active={active === key}
              data={d}
              customer={account}
            />
          ))}
        </div>
      </div>
      <div className="d-flex justify-content-center">
        <div className="field-input-payment">
          <FieldInput
            min={0}
            type="number"
            onChange={handleChange}
            value={amount}
            placeholder="Nhập số tiền đã nộp"
            required
            label="Nhập số tiền đã nộp"
            errorMessage={errorMessage}
          />
        </div>
      </div>
      <div className="d-flex justify-content-center" style={{ paddingTop: 16 }}>
        <ButtonSubmit
          onClick={handleSubmit}
          style={{ width: '100%', maxWidth: 200 }}
          styleText={{ fontSize: 14 }}
          name="Gửi yêu cầu"
          size="md"
          color="primary"
          isLoading={isLoading}
        />
      </div>
    </div>
  );
}

Payment.propTypes = {
  refechDateHistory: PropTypes.func,
  account: PropTypes.any,
};

export default Payment;
