import React from 'react';
import { useRoutes } from 'react-router-dom';
import { publicConstant } from './constants';

function PublicRouter() {
  const routes = useRoutes(publicConstant);
  return routes;
}

export default PublicRouter;
