import React from 'react';
import PropTypes from 'prop-types';
import { Nav, NavItem, NavLink } from 'reactstrap';

function Tabs({ tabs, activeTab, setActiveTab }) {
  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };
  return (
    <Nav tabs className="mb-0 justify-content-center">
      {tabs.map((item) => (
        <NavItem key={item.id} className="">
          <NavLink
            active={activeTab === item.id}
            onClick={() => {
              toggle(item.id);
            }}
          >
            {item.icon && (
              <img src={activeTab === item.id ? item.iconActive : item.icon} alt={item.name} />
            )}
            <span className="align-middle pl-50">{item.name}</span>
          </NavLink>
        </NavItem>
      ))}
    </Nav>
  );
}

Tabs.propTypes = {
  tabs: PropTypes.array,
  activeTab: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  setActiveTab: PropTypes.func,
};

export default Tabs;
