import ButtonSubmit from '@Components/ButtonSubmit';
import DataTableBasic from '@Components/DataTableBasic';
import DatePicker from '@Components/DatePicker';
import Title from '@Components/Title';
import FieldInput from '@Views/Client/Recharge/BodyTab/Request/FieldInput';
import React, { useCallback, useEffect, useState } from 'react';
import { list, remove, create } from '@Apis/admin/dayoff';
import { useSearchParams } from 'react-router-dom';
import CustomeModal from '@Components/CustomeModal';
import { toastError, toastSuccess } from '@Utils/toastrHelper';
import { useDispatch } from 'react-redux';
import dayjs from 'dayjs';
import { showGlobalLoading, hideGlobalLoading } from '@Lib/slices/uiSlice';
import { columns } from './columns';
import './styles.scss';

function Setting() {
  const [data, setData] = useState({});
  const [fillData, setFillData] = useState({});
  const [idRemove, setIdRemove] = useState();
  const [searchParams] = useSearchParams();
  const page = searchParams.get('page') || 1;
  const dispatch = useDispatch();

  useEffect(() => {
    callApi();
  }, [page]);

  const callApi = useCallback(() => {
    list({ page, per_page: 10 })
      .then((res) => setData(res))
      .catch(toastError);
  }, [page]);

  const handleConfirm = useCallback(() => {
    dispatch(showGlobalLoading());
    setIdRemove();
    remove({ id: idRemove.id })
      .then(() => {
        callApi();
        toastSuccess('Xoá dữ liệu thành công');
      })
      .catch(toastError)
      .finally(() => dispatch(hideGlobalLoading()));
  }, [idRemove]);

  const handleAddData = useCallback(() => {
    setFillData({});
    dispatch(showGlobalLoading());
    create({
      description: fillData.description,
      to: dayjs(fillData.date?.[1]).unix(),
      from: dayjs(fillData.date?.[0]).unix(),
    })
      .then(() => {
        callApi();
      })
      .catch(toastError)
      .finally(() => dispatch(hideGlobalLoading()));
  }, [fillData]);

  return (
    <div className="setting">
      <Title name="Thiết lập ngày nghỉ lễ" />
      <div className="list-filter">
        <FieldInput
          value={fillData.date}
          onChange={(v) => {
            setFillData({ ...fillData, date: v });
          }}
          className="input-field-date"
          Component={DatePicker.Range}
          placeholder="Chọn ngày"
          label="Chọn ngày nghỉ lễ"
          required
        />
        <FieldInput
          value={fillData.description || ''}
          onChange={(v) => {
            setFillData({ ...fillData, description: v });
          }}
          className="input-field-text"
          label="Mô tả"
          placeholder="Nhập Mô tả"
        />
        <ButtonSubmit
          onClick={handleAddData}
          disabled={!fillData.date?.length}
          style={{ marginTop: 20 }}
          icon={<img src="/images/warehouseSource/add_circle.svg" alt="" />}
          color="primary"
          name="Thêm vào danh sách"
        />
      </div>
      <div style={{ marginTop: 24, paddingLeft: 10, background: '#fff' }}>
        <DataTableBasic data={data} columns={columns(setIdRemove)} />
      </div>
      <CustomeModal
        isOpen={!!idRemove}
        confirm={handleConfirm}
        closeModal={() => setIdRemove()}
        buttonName="Xoá"
        type="delete"
        mess="Bạn muốn xoá thiết lập này?"
      />
    </div>
  );
}

export default Setting;
