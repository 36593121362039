/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable import/extensions */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import 'flatpickr/dist/themes/light.css';
import flatpickr from 'flatpickr/dist/flatpickr.js';
import { X } from 'react-feather';
import { Event } from '@mui/icons-material';
import shortid from 'shortid';
import dayjs from 'dayjs';
import { FlatPickrWrapper } from './styles';

export default function FlatPickrDateRange({
  dateRange,
  setDateRange,
  labelDefault,
  className = '',
  isOpen = true,
  name = 'date_range',
}) {
  const flatPickrId = `flatpickr-from-${shortid.generate(4)}`;
  const flatPickRef = useRef();
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setTodate] = useState(null);

  useEffect(() => {
    if (dateRange) {
      const arrDate = dateRange.split('-');
      setFromDate(dayjs.unix(arrDate[0]));
      setTodate(dayjs.unix(arrDate[1]));
    }
  }, [dateRange]);

  useEffect(() => {
    flatpickr(`#${flatPickrId}`, {
      mode: 'range',
      showMonths: 1,
      dateFormat: 'd-m-Y',
      disableMobile: true,
      maxDate: 'today',
      allowInput: true,
      defaultDate: [fromDate, toDate],
      onChange: (selectedDates) => {
        if (selectedDates[1]) {
          setFromDate(selectedDates[0]);
          setTodate(selectedDates[1]);
          const unixFrom = dayjs(selectedDates[0]).unix();
          const unixTo = dayjs(selectedDates[1]).hour(23).minute(59).unix();
          setDateRange(name, `${unixFrom}-${unixTo}`);
        }
      },
    });
  }, []);

  useEffect(() => {
    if (flatPickRef && isOpen) {
      flatPickRef.current.click();
    }
  }, [flatPickRef, isOpen]);

  const clearDateRange = () => {
    setDateRange(name, '');
  };

  return (
    <FlatPickrWrapper className={className}>
      <div id={flatPickrId} ref={flatPickRef} className="w-100">
        <div className="d-flex align-items-center">
          <Event fontSize="small" className="mr-50" />
          {dateRange ? (
            <div className="pd-r-20 d-flex">
              <p className="mb-0 font-size-14 text-primary font-weight-bolder">
                {fromDate && <span>{dayjs(fromDate).format('DD/MM/YYYY')} -</span>}
                {toDate && <span> {dayjs(toDate).format('DD/MM/YYYY')}</span>}
              </p>
            </div>
          ) : (
            <div className="pd-r-20 font-size-14 text-primary font-weight-bolder">
              {labelDefault}
            </div>
          )}
        </div>
      </div>
      {dateRange && (
        <X color="#eb5757" className="mr-50" size={18} onClick={() => clearDateRange()} />
      )}
    </FlatPickrWrapper>
  );
}

FlatPickrDateRange.propTypes = {
  name: PropTypes.string,
  dateRange: PropTypes.string,
  setDateRange: PropTypes.func,
  labelDefault: PropTypes.string,
  className: PropTypes.string,
  isOpen: PropTypes.bool,
  isShowClear: PropTypes.bool,
};
