import { formatNumber } from '@Utils/helpers';
import React from 'react';

export const columns = (navigate) => [
  {
    name: 'MÃ CK',
    cell: (row) => row.name,
    center: true,
    width: '110px',
    sortable: true,
    sortField: 'name',
  },
  {
    name: 'GIÁ HIỆN TẠI',
    cell: (row) => (
      <div style={{ width: '100%', textAlign: 'right' }}>{formatNumber(row.price, '--')}</div>
    ),
    center: true,
  },
  {
    name: 'TỔNG KL',
    cell: (row) => (
      <div style={{ width: '100%', textAlign: 'right' }}>
        {formatNumber(row.totalQuantity?.quantity, '--')}
      </div>
    ),
    center: true,
  },
  {
    name: 'KL KHẢ DỤNG',
    cell: (row) => (
      <div style={{ width: '100%', textAlign: 'right' }}>
        {formatNumber(
          row.totalPendingQuantity !== null && row.totalQuantity !== null
            ? (row.totalQuantity?.quantity || 0) - row.totalPendingQuantity?.quantity
            : row.totalQuantity?.quantity,
          0,
        )}
      </div>
    ),
    center: true,
  },
  {
    name: 'TỔNG GT CK',
    cell: (row) => (
      <div style={{ width: '100%', textAlign: 'right' }}>
        {formatNumber((row.totalQuantity?.quantity || 0) * row.price, '--')}
      </div>
    ),
    center: true,
  },
  {
    name: 'SL KH NẮM GIỮ',
    cell: (row) => formatNumber(row.customerCount?.count, '--'),
    center: true,
  },
  {
    name: '',
    cell: (row) => (
      <div
        style={{ width: '100%', display: 'flex', justifyContent: 'flex-end', paddingRight: 20 }}
        role="button"
        tabIndex="0"
        onClick={() => navigate(`/stock/${row.trade_type}/${row.name}`)}
      >
        <span className="text-danger font-weight-bolder mr-50">Chi tiết</span>
        <img src="/images/icon/arrow-box-red.svg" alt="view-detail" />
      </div>
    ),
  },
];
