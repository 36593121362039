/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-else-return */
/* eslint-disable indent */
/* eslint-disable jsx-a11y/control-has-associated-label */
import InputSearch from '@Components/InputSearch';
import { formatNumber } from '@Utils/helpers';
import React, { useState, useEffect, useCallback } from 'react';
import ButtonSubmit from '@Components/ButtonSubmit';
import { useSelector } from 'react-redux';
import useCustomQuery from '@Utils/useCustomQuery';
import { stockAsset, stockConfig } from '@Apis/client/stock';
import { accountsHome } from '@Apis/client/home';
import SelectInput from '@Components/Input/SelectInput';
import TextInput from '@Components/Input/TextInput';
import useCustomMutation from '@Utils/useCustomMuation';
import { toastError, toastSuccess } from '@Utils/toastrHelper';
import { currentPosition } from '@Apis/client/account';
import TableEmpty from '@Components/DataTableBasic/TableEmpty';
import { trade, warningTransaction } from '@Apis/client/trade';
import { Modal } from 'reactstrap';
import FieldInput from '../Recharge/BodyTab/Request/FieldInput';
import TradeHistory from '../Transaction/components/TradeHistory';
import './styles.scss';
import DataTableBasic from '@Components/DataTableBasic';
import NavigationBar from '@Components/NavigationBar';
import { closePosition } from '@Apis/client/transaction';
import { columnsLichSuDongViTheTPlus } from './columns';

export const defaultPaging = { page: 1, per_page: 5, total: 5, total_page: 1, sort_by: 'id' };

export const renderVTHT = (quantity) => {
  if (quantity > 0) {
    return (
      <div className="d-flex align-items-center justify-content-center">
        <div
          style={{ background: '#14B13B', borderRadius: 4, width: 20, height: 20, color: '#fff' }}
          className="fw-600 d-flex align-items-center justify-content-center"
        >
          L
        </div>
        <div style={{ marginLeft: 8, color: '#14B13B' }}>{formatNumber(quantity)}</div>
      </div>
    );
  } else if (quantity < 0) {
    return (
      <div className="d-flex align-items-center justify-content-center">
        <div
          style={{ background: 'red', borderRadius: 4, width: 20, height: 20, color: '#fff' }}
          className="fw-600 d-flex align-items-center justify-content-center"
        >
          S
        </div>
        <div style={{ marginLeft: 8, color: 'red' }}>{formatNumber(Math.abs(quantity))}</div>
      </div>
    );
  }
  if (quantity > 0) {
    return (
      <div className="d-flex align-items-center justify-content-center">
        <div style={{ background: '#FC3400', borderRadius: 4, width: 20, height: 20 }}>S</div>
        <div
          className="d-flex align-items-center justify-content-center"
          style={{ color: '#FC3400' }}
        >
          {formatNumber(quantity)}
        </div>
      </div>
    );
  }
};

export const renderQuantity = (quantity, value, isPercent = false) => {
  if (quantity >= 0) {
    return <div style={{ color: '#14B13B' }}>+{value}{isPercent? '%' : ''}</div>;
  }
  if (quantity < 0) {
    return <div style={{ color: '#FC3400' }}>{value}{isPercent? '%' : ''}</div>;
  }
};

function renderType(type) {
  return (
    <div className="">
      {type === 'buy' ? (
        <div style={{ background: 'rgba(32, 201, 172, 0.1)' }}>
          <p className="text-success font-size-14 font-weight-bolder py-25 px-1">Mua</p>
        </div>
      ) : (
        <div style={{ background: 'rgba(252, 52, 0, 0.1)' }}>
          <p className="text-danger font-size-14 font-weight-bolder py-25 px-1">Bán</p>
        </div>
      )}
    </div>
  );
}

function TransactionTPlus() {
  const [dataWarning, setDataWarning] = useState({});
  const [tab, setTab] = useState(1);
  const [trade_type, setSelect] = useState('t0');
  const productType = useSelector((state) => state.ui.productType);
  const [priceType, setPriceType] = useState('price');
  const [price, setPrice] = useState('');
  const [quantity, setQuantity] = useState(0);
  const [note, setNote] = useState('');
  const { data: dataAccounts } = useCustomQuery(accountsHome);
  const { data = [] } = useCustomQuery(
    stockConfig,
    {
      type: 'spot',
    },
    false,
    null,
    5000,
  );
  const [itemTransfer, setItemTransfer] = useState();
  const [itemSelected, setItemSelected] = useState({});
  const { mutate, isSuccess: isSuccessLock, error: errorLock } = useCustomMutation(trade, true);

  const account = dataAccounts?.accounts?.find((e) => e.trade_type === productType);

  const { data: dataAssets } = useCustomQuery(
    stockAsset,
    {
      trade_type: productType,
      customer_id: account?.id,
    },
    false,
    null,
    5000,
  );

  const { data: dataLichSuDongViThe } = useCustomQuery(
    closePosition,
    {
      customer_id: account?.id,
      stock_id: itemSelected?.stock?.id,
    },
    false,
    null,
    5000,
  );

  useEffect(() => {
    if (priceType !== 'price') {
      setPrice(itemSelected?.stock?.price);
    }
  }, [priceType]);

  useEffect(() => {
    warningTransaction({
      stock_id: itemSelected?.stock?.id,
      customer_id: account?.id,
      trade_type,
    })
      .then((res) => {
        setDataWarning(res);
      })
      .catch(toastError);
  }, [itemSelected]);

  useEffect(() => {
    setPrice(itemSelected?.stock?.price);
  }, [itemSelected?.stock?.price]);

  const onSubmit = (type) => {
    const data = {
      stock_id: itemSelected?.stock?.id,
      stock_config_id: itemSelected?.id,
      price,
      quantity,
      note,
      type,
      trade_type,
      price_type: priceType,
      customer_id: account.id,
    };
    mutate(data, {
      onSuccess: () => {
        setItemSelected({});
        setQuantity(0);
        setNote();
      },
    });
  };

  const onSubmitTransfer = () => {
    if (itemTransfer?.data && itemTransfer?.type === 'close') {
      const pricesell =
        (itemTransfer?.data?.lastTradingItem?.current_cost_price * quantity +
          (itemTransfer?.data?.lastTradingItem?.current_cost_price || 0) *
            itemTransfer?.data?.quantity) /
        (quantity + itemTransfer?.data?.quantity);

      const data = {
        stock_id: itemTransfer?.data?.stock?.id,
        price: itemTransfer?.data?.stock?.price,
        quantity: Math.abs(itemTransfer?.data?.quantity),
        type: itemTransfer?.data?.quantity > 0 ? 'sell' : 'buy',
        trade_type: itemTransfer?.trade_type,
        price_type: 'price',
        customer_id: account.id,
      };
      mutate(data);
      setItemTransfer();
    }
  };

  useEffect(() => {
    if (isSuccessLock) {
      toastSuccess('Yêu cầu đặt lệnh thành công');
    }
    if (errorLock) {
      toastError(errorLock);
    }
  }, [isSuccessLock, errorLock]);

  const kltoida = Math.floor((account?.buy_ability || 0) / (price || 1));

  const dataSelectAssets = dataAssets?.assets?.[itemSelected?.stock?.id];

  const actionT0 = useCallback((t0) => {
    return (
      <div style={{ textAlign: 'center' }}>
        <div
          style={{ cursor: 'pointer' }}
          onClick={() => setItemTransfer({ type: 'close', data: t0, trade_type: 't0' })}
        >
          <img src="/images/transaction/cancel.svg" alt="cancel" style={{ marginTop: -2 }} />
          <span style={{ marginLeft: 5, color: '#FC3400' }}>Đóng vị thế</span>
        </div>
        {/* <div
          style={{ cursor: 'pointer' }}
          onClick={() => setItemTransfer({ type: 'transfer', data: t0, trade_type: 't0' })}
        >
          <img
            style={{ marginTop: -2 }}
            src="/images/transaction/swap_horizontal_circle.svg"
            alt="swap"
          />
          <span style={{ marginLeft: 5, color: '#2F69B3' }}>Chuyển đổi T2</span>
        </div> */}
      </div>
    );
  }, []);

  const actionT2 = useCallback((t2) => {
    return (
      <div
        style={{ cursor: 'pointer' }}
        onClick={() => setItemTransfer({ type: 'close', data: t2, trade_type: 't2' })}
      >
        <img style={{ marginTop: -2 }} src="/images/transaction/cancel.svg" alt="cancel" />
        <span style={{ marginLeft: 5, color: '#FC3400' }}>Đóng vị thế</span>
      </div>
    );
  }, []);

  const onClickBtnChangeQtt = (type) => {
    const changeValue = parseInt(1);
    let newQtt;
    if (type == 'insc') {
      newQtt = (parseInt(quantity) - changeValue).toFixed(0);
      newQtt = newQtt < 0 ? 0 : newQtt;
    } else if (type == 'desc') {
      newQtt = (parseInt(quantity) + changeValue).toFixed(0);
    }

    setQuantity(newQtt);
  };

  return (
    <div className="content-wrapper transaction-wrapper transaction-wrapper-tplus">
      <div className="d-flex align-items-center justify-content-between header-body mb-2">
        <p className="font-size-28 font-weight-bolder">Đặt lệnh</p>
      </div>
      <div className="list-buy-sell-group" style={{ minHeight: 500 }}>
        <div className="w-100 pb-2" style={{ background: '#fff' }}>
          <div className="input-search-plus">{/* <InputSearch placeholder="Tìm mã CK " /> */}</div>
          <div className="table-scroll">
            <table className="table-buy-sell-group w-100">
              <thead>
                <tr>
                  <th className="th-group text-align-center" colSpan={3}>
                    Danh sách mã có thể giao dịch
                  </th>
                  <th className="th-group text-align-center" colSpan={3}>
                    Vị thế đang nắm giữ
                  </th>
                </tr>
                <tr className="tr-buy-sell-header">
                  <th className="text-align-center">Mã CK</th>
                  <th className="text-align-center">Giá hiện tại</th>
                  <th className="text-align-center">Tỷ lệ ký quỹ</th>
                  <th className="text-align-center">KL khả dụng</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {data?.map((d) => (
                  <tr
                    className={d.id === itemSelected?.id ? 'tr-buy-sell-select' : 'tr-buy-sell'}
                    key={d.id}
                    role="button"
                    onClick={() => setItemSelected(d)}
                  >
                    <td className="uppercase text-align-center">{d.stock?.id}</td>
                    <td className="text-align-end">{formatNumber(d.stock?.price)}</td>
                    <td className="text-align-end">{formatNumber(d.stock?.margin_rate)}%</td>
                    <td className="text-align-end">{formatNumber(d.available_quantity)}</td>
                    <td className="text-align-end">
                      <div
                        className="d-flex align-items-center justify-content-center"
                        style={{ color: '#2F69B3', cursor: 'pointer' }}
                      >
                        <span>Đặt lệnh</span>
                        <img src="/images/icon/icon_dat_lenh.svg" alt="" />
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="info-buy-sell-group">
          <span className="uppercase" style={{ color: '#84818A', fontSize: 16 }}>
            Vị thế đang nắm giữ
          </span>
          {/* <div className="mt-1 d-flex align-items-center" style={{ marginLeft: -10 }}>
            <div className="d-flex align-items-center">
              <Radio checked={trade_type === 't0'} onClick={() => setSelect('t0')} />
              <span>T+</span>
            </div>
            <div className="d-flex align-items-center ml-1">
              <Radio checked={trade_type === 't2'} onClick={() => setSelect('t2')} />
              <span>T2</span>
            </div>
          </div> */}
          <div className="d-flex mt-1">
            <div hidden className="w-100">
              <div>Sức mua/bán:</div>
              <div className="fw-600" style={{ paddingTop: 4, color: '#000000' }}>
                {formatNumber(account?.buy_ability, '--')}
              </div>
            </div>
            <div className="w-100">
              <div>Vị thế hiện tại:</div>
              <div className="fw-600" style={{ paddingTop: 4, color: '#000000' }}>
                {renderVTHT(dataSelectAssets?.[trade_type]?.quantity, '--')}
              </div>
            </div>
            <div className="w-100">
              <div>Max Short:</div>
              <div className="fw-600" style={{ paddingTop: 4, color: '#000000' }}>
                {formatNumber(dataWarning?.max_short, 0)}
              </div>
            </div>
            <div className="w-100">
              <div>Max Long:</div>
              <div className="fw-600" style={{ paddingTop: 4, color: '#000000' }}>
                {formatNumber(dataWarning?.max_long, 0)}
              </div>
            </div>
          </div>

          <div className="d-flex" style={{ marginBottom: 24 }} />

          <div className="reponsive-row">
            <div className="reponsive-col">
              <div className="d-flex align-items-end">
                <FieldInput
                  style={{ width: 70, minWidth: 70 }}
                  className="mr-1 form-group"
                  label="Mã CK"
                  required
                  disabled
                  value={itemSelected?.stock?.id || ''}
                />
                <SelectInput
                  styleInput={{
                    borderTopRightRadius: 0,
                    borderBottomRightRadius: 0,
                    borderRightWidth: 0,
                    textTransform: 'none',
                    width: 67,
                    height: 42,
                  }}
                  styleCol={{ paddingRight: 0, marginTop: 4, width: 57 }}
                  labelName="Giá *"
                  labelCol="12"
                  inputCol="12"
                  setValue={(_, v) => setPriceType(v)}
                  inputClassName="uppercase"
                  options={[
                    {
                      label: 'Giá',
                      value: 'price',
                    },
                    {
                      label: 'MP',
                      value: 'mp',
                    },
                    {
                      label: 'ATO',
                      value: 'ato',
                    },
                    {
                      label: 'ATC',
                      value: 'atc',
                    },
                  ]}
                  placeholder="Giá bán *"
                  labelClassName="font-size-14"
                  name="price_type"
                  value={priceType}
                  defaultValue="price"
                />
                <TextInput
                  styleInput={{
                    borderTopLeftRadius: 0,
                    borderBottomLeftRadius: 0,
                    borderLeftWidth: 0.5,
                    opacity: priceType !== 'price' ? 0 : 1,
                    height: 42,
                  }}
                  setValue={(_, v) => setPrice(v)}
                  labelCol="12"
                  inputCol="12"
                  type="price"
                  labelClassName="font-size-14 mb-50"
                  placeholder="Giá bán *"
                  name="price"
                  defaultValue={price !== undefined ? price : itemSelected?.stock?.price}
                />
              </div>
            </div>
            <div className="reponsive-col">
              <FieldInput
                label="Khối lượng *"
                component={
                  <div className="wrap-btn-change bg-white d-flex" style={{ height: 42 }}>
                    <a className="btn btn-change" onClick={() => onClickBtnChangeQtt('insc')}>
                      -
                    </a>
                    <FieldInput
                      isInt
                      zeroIsEmpty
                      type="number"
                      placeholder="0"
                      labelCol="12"
                      inputCol="12"
                      value={quantity}
                      defaultValue={quantity}
                      styleInput={{
                        borderRadius: 0,
                        width: 110,
                        border: 'none',
                      }}
                      onChange={setQuantity}
                    />
                    <a
                      className="btn btn-change"
                      style={{ marginLeft: 1 }}
                      onClick={() => onClickBtnChangeQtt('desc')}
                    >
                      +
                    </a>
                  </div>
                }
              />
            </div>
          </div>
          <div className="d-flex " style={{ marginBottom: 8 }} />

          <FieldInput.TextArea
            value={note}
            onChange={(v) => setNote(v)}
            placeholder="Ghi chú"
            label="Ghi chú (nếu có)"
          />
          <div className="d-flex" style={{ marginTop: 24 }}>
            <ButtonSubmit
              onClick={() => onSubmit('buy')}
              name="Mua"
              wrapClassName="mr-1 w-100 button-wrap-color"
              size="md"
              color="primary"
              style={{ borderRadius: 4, height: 54 }}
            />
            <ButtonSubmit
              onClick={() => onSubmit('sell')}
              name="Bán"
              wrapClassName="ml-1 w-100"
              size="md"
              color="danger"
              style={{ borderRadius: 4, height: 54 }}
            />
          </div>
        </div>
      </div>

      <div className="mt-1" style={{ padding: 24, background: '#fff' }}>
        <div className="title">Quản lý vị thế</div>
        <NavigationBar
          className="transaction-navigator-bar"
          tabs={[
            {
              id: 1,
              name: 'Vị thế đang mở',
            },
            {
              id: 2,
              name: 'Lịch sử đóng vị thế',
            },
          ]}
          activeTab={tab}
          setActiveTab={(t) => {
            setTab(t);
          }}
        />

        {tab === 1 ? (
          <>
            <div className="table-list-plus">
              <div className="wrap-table">
                {!(dataAssets?.assets && Object.keys(dataAssets?.assets).length > 0) ? (
                  <TableEmpty />
                ) : (
                  <table>
                    <thead>
                      <tr>
                        <th style={{ background: '#EBEAED' }} rowSpan={2}>
                          Mã CK
                        </th>
                        <th
                          colSpan={3}
                          style={{ background: 'rgba(98, 52, 148, 0.1)', color: '#623494' }}
                        >
                          T+
                        </th>
                        {/* <th
                          className="text-align-center"
                          style={{ background: 'rgba(98, 52, 148, 0.1)', color: '#623494' }}
                          colSpan={4}
                        >
                          T2
                        </th> */}
                        <th
                          rowSpan={2}
                          style={{ background: '#EBEAED', color: '#623494' }}
                          className="text-align-center"
                        >
                          P/L
                        </th>
                        <th
                            rowSpan={2}
                            style={{ background: '#EBEAED' }}
                            className="text-align-center"
                        >
                          Tỷ lệ lãi/lỗ
                        </th>
                      </tr>
                      <tr style={{ fontSize: 14 }}>
                        <th style={{ background: 'rgba(98, 52, 148, 0.1)' }}>Vị thế hiện tại</th>
                        <th style={{ background: 'rgba(98, 52, 148, 0.1)' }}>Giá vốn / Giá TT</th>
                        <th style={{ background: 'rgba(98, 52, 148, 0.1)' }}>Hành động</th>
                        {/* <th style={{ background: 'rgb(211, 126, 23, 0.1)' }}>Vị thế hiện tại</th>
                        <th style={{ background: 'rgb(211, 126, 23, 0.1)' }}>Giá vốn / Giá TT</th>
                        <th style={{ background: 'rgb(211, 126, 23, 0.1)' }}>Deadline trả VT</th>
                        <th style={{ background: 'rgb(211, 126, 23, 0.1)' }}>Hành động</th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {Object.keys(dataAssets?.assets || {}).map((key) => {
                        const t0 = dataAssets.assets[key].t0;
                        const t2 = dataAssets.assets[key].t2;
                        if (!t0 || !t2) return null;

                        const startValue = t0.quantity * (t0?.dca_price || 0);
                        let pl = t0.stock.price * t0.quantity - startValue;
                        const t2startValue = t2.quantity * (t2?.dca_price || 0);
                        pl += t2.stock.price * t2.quantity - t2startValue;

                        const pnl_wait = t0.stock.price * t0.quantity - startValue;
                        const percent = (pnl_wait / startValue) * 100 || 0;

                        return (
                          <tr key={key}>
                            <td>{key}</td>
                            <td>{renderVTHT(t0?.quantity)}</td>
                            <td style={{ textAlign: 'center' }}>
                              <div>
                                {formatNumber(t0.dca_price, '--')}/
                                {formatNumber(t0.stock.price, '--')}
                              </div>
                            </td>
                            <td style={{ textAlign: 'center' }}>{actionT0(t0)}</td>
                            {/* <td>{renderVTHT(t2?.quantity)}</td>
                            <td style={{ textAlign: 'right' }}>
                              <div>
                                {formatNumber(t2.dca_price, '--')}/
                                {formatNumber(t2.stock.price, '--')}
                              </div>
                            </td>
                            <td>
                              {t2?.liquidation_at
                                ? dayjs.unix(t2?.liquidation_at).format('DD/MM/YYYY HH:mm:ss')
                                : '--'}
                            </td>
                            <td>{actionT2(t0)}</td> */}
                            <td style={{ textAlign: 'right' }}>
                              {renderQuantity(pl, formatNumber(pl))}
                            </td>
                            <td style={{ textAlign: 'right' }}>
                              {renderQuantity(percent, formatNumber(percent), true)}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                )}
              </div>
            </div>
            <Modal wrapClassName="modal-wrap-setting" isOpen={!!itemTransfer} centered>
              <div className="modal-setting">
                <div style={{ textAlign: 'center' }}>
                  <h3 className="uppercase mb-1">Xác nhận giao dịch</h3>
                  <div className="d-flex justify-content-center align-items-center">
                    <span className="mr-1">LOẠI LỆNH</span>
                    {renderType('buy')}
                  </div>
                </div>
                <div className="d-flex align-items-center mt-1">
                  <div className="pr-2" style={{ width: '50%', borderRight: '1px dashed' }}>
                    <h3
                      style={{
                        color:
                          itemTransfer?.type === 'close' ? 'rgb(252, 52, 0)' : 'rgb(20, 177, 59)',
                      }}
                    >
                      Vị thế hiện tại
                    </h3>
                    <div className="mt-2">
                      <div className="d-flex justify-content-between align-items-center">
                        <h5 className="uppercase mt-1">Cổ phiếu</h5>
                        <div className="uppercase">{itemTransfer?.data?.stock_id}</div>
                      </div>
                      <div className="d-flex justify-content-between align-items-center">
                        <h5 className="uppercase mt-1">Khối lượng</h5>
                        <div className="uppercase">
                          {formatNumber(Math.abs(itemTransfer?.data?.quantity || 0))}
                        </div>
                      </div>
                      <div className="d-flex justify-content-between align-items-center">
                        <h5 className="uppercase mt-1">Giá vốn</h5>
                        <div className="uppercase">
                          {(itemTransfer?.data?.lastTradingItem?.current_cost_price * quantity +
                            (itemTransfer?.data?.lastTradingItem?.current_cost_price || 0) *
                              itemTransfer?.data?.quantity) /
                            (quantity + itemTransfer?.data?.quantity)}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="pl-2" style={{ width: '50%' }}>
                    <h3
                      style={{
                        color:
                          itemTransfer?.type !== 'close' ? 'rgb(252, 52, 0)' : 'rgb(20, 177, 59)',
                      }}
                    >
                      Lệnh đóng vị thế
                    </h3>
                    <div className="mt-2">
                      <div className="d-flex justify-content-between align-items-center">
                        <h5 className="uppercase mt-1">Cổ phiếu</h5>
                        <div className="uppercase">{itemTransfer?.data?.stock_id}</div>
                      </div>
                      <div className="d-flex justify-content-between align-items-center">
                        <h5 className="uppercase mt-1">Khối lượng đóng</h5>
                        <div
                          className="uppercase"
                          style={{
                            color:
                              itemTransfer?.type === 'close'
                                ? 'rgb(252, 52, 0)'
                                : 'rgb(20, 177, 59)',
                          }}
                        >
                          {formatNumber(Math.abs(itemTransfer?.data?.quantity || 0))}
                        </div>
                      </div>
                      <div className="d-flex justify-content-between align-items-center">
                        <h5 className="uppercase mt-1">Giá đóng</h5>
                        <div
                          className="uppercase"
                          style={{
                            color:
                              itemTransfer?.type === 'close'
                                ? 'rgb(252, 52, 0)'
                                : 'rgb(20, 177, 59)',
                          }}
                        >
                          {formatNumber(itemTransfer?.data?.stock?.price)}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-3 d-flex align-items-center justify-content-center">
                  <ButtonSubmit
                    onClick={onSubmitTransfer}
                    name="Xác nhận"
                    className="px-2"
                    size="md"
                    color="primary"
                  />
                  <ButtonSubmit
                    onClick={() => setItemTransfer()}
                    outline
                    name="Huỷ"
                    className="px-2"
                    wrapClassName="ml-1"
                    size="md"
                    color="primary"
                    ghost
                  />
                </div>
              </div>
            </Modal>
          </>
        ) : null}

        {tab === 2 ? (
          <DataTableBasic
            page={1}
            data={{ data: dataLichSuDongViThe, paging: defaultPaging }}
            columns={columnsLichSuDongViTheTPlus}
          />
        ) : null}
      </div>

      <TradeHistory accountId={account?.id} />
    </div>
  );
}

export default TransactionTPlus;
