import React, { useState, useEffect, useCallback } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { io } from 'socket.io-client';
import {
  transactionReject,
  transactionChangeInProcess,
  transactionTellerIndex,
  transactionTellerExport,
} from '@Apis/admin/transaction';
import FlatPickrDateRange from '@Components/FlatPickrDateRange';
import DataTableBasic from '@Components/DataTableBasic';
import { TransactionWrapper } from '@Components/TransactionWrapper/styles';
import CustomeModal from '@Components/CustomeModal';
import useCustomMutation from '@Utils/useCustomMuation';
import { toastError, toastSuccess } from '@Utils/toastrHelper';
import { hideGlobalLoading, showGlobalLoading } from '@Lib/slices/uiSlice';
import FilterComponent from '@Components/FilterComponent';
import SearchCustomer from '@Components/Input/SearchCustomer';
import { wareHouseIndex } from '@Apis/admin/warehouse';
import Status from './components/Status';
import { columns } from './components/Columns';
import PopUpEdit from './components/PopUpEdit';
import PopUpConfirm from './components/PopUpConfirm';
import ExpandTranction from './components/ExpandTranction';

function TransactionTelles() {
  const mode = 'tellers';
  const [searchParams, setSearchParams] = useSearchParams();
  const page = searchParams.get('page') || 1;
  const customerId = searchParams.get('customer_id');
  const tradeType = searchParams.get('trade_type');
  const dateRange = searchParams.get('date_range') || '';
  const id = searchParams.get('id');
  const type = searchParams.get('type');
  const stockId = searchParams.get('stock_id');
  const purchaseAccountId = searchParams.get('purchase_account_id');
  const [activeTab, setActiveTab] = useState(searchParams.get('activeTab') || '');
  const [itemPopUp, setItemPopUp] = useState(null);
  const [itemChangeStatus, setItemChangeStatus] = useState(null);
  const [isRefreshStatus, setIsRefreshStatus] = useState(null);
  const [itemConfirm, setItemConfirm] = useState(null);
  const [optionsWarehouse, setOptionsWarehouse] = useState([]);
  const [optionsTeller, setOptionsTeller] = useState([]);
  const tellers = useSelector((state) => state.transaction.tellers);
  const [dataState, setData] = useState();
  const navigate = useNavigate();
  const [filter, setFilter] = useState({
    date_range: dateRange,
    trade_type: tradeType,
    id,
    type,
    customer_id: customerId,
    stock_id: stockId,
    purchase_account_id: purchaseAccountId,
    status: activeTab,
    mode,
    sort_by: 'id',
    order: activeTab === 'pending' ? 'ASC' : 'DESC',
    page,
  });
  const dispatch = useDispatch();

  useEffect(() => {
    if (itemPopUp) {
      wareHouseIndex({
        stock_id: itemPopUp?.stock_id,
        trade_type: itemPopUp?.trade_type,
      })
        .then((data) => {
          const options = [
            {
              label: 'Chọn',
              value: '',
            },
          ].concat(
            data.map((e) => ({
              label: `${e.name} (${e.stock_can_sell}) `,
              value: e.id,
            })),
          );
          setOptionsWarehouse(options);
        })
        .catch(toastError);
    }
  }, [itemPopUp]);

  const warehouses = useSelector(({ transaction }) => transaction.warehouse);

  useEffect(() => {
    if (tellers) {
      const optionDefault = {
        label: 'Chọn',
        value: 0,
      };
      setOptionsTeller([optionDefault, ...tellers]);
    }
  }, [tellers]);

  useEffect(() => {
    const token = localStorage.getItem(process.env.TOKEN);
    const socket = io(`${process.env.BACKEND_SOCKET}/admin/transaction/pending-transaction`, {
      query: { token },
      transports: ['websocket'],
    });
    socket.on('connect', () => {
      socket.emit('register', 'your-client-id', { customer_id: customerId });
    });
    socket.on('data', () => {
      if (activeTab === 'pending') {
        refetch();
      }
      setIsRefreshStatus((pre) => pre + 1);
    });
    return () => {
      socket.disconnect();
    };
  }, [activeTab, refetch, customerId]);

  useEffect(() => {
    if (page) {
      setFilter({ ...filter, page });
    }
  }, [page]);

  const refetch = useCallback(() => {
    dispatch(showGlobalLoading());
    transactionTellerIndex(filter)
      .then((res) => {
        setData({
          ...res,
          data: res.data?.map((e) => {
            if (e.status === 'part_completed') {
              e.defaultExpanded = true;
            }
            return e;
          }),
        });
      })
      .finally(() => dispatch(hideGlobalLoading()));
  }, [filter]);

  const { mutate, isSuccess, error } = useCustomMutation(transactionReject, true);
  const {
    mutate: mutateInProcess,
    isSuccess: isSuccessInProcess,
    error: errorInProcess,
  } = useCustomMutation(transactionChangeInProcess, true);

  const filterOnChange = (fil) => {
    setFilter((prevValues) => {
      return {
        ...prevValues,
        ...fil,
      };
    });
  };
  const changeStatus = (status) => {
    setActiveTab(status);
    navigate('/transaction-tellers');
    searchParams.set('activeTab', status);
    setSearchParams(searchParams);
    filterOnChange({
      status,
      page: 1,
      sort_by: 'id',
      order: status === 'pending' ? 'ASC' : 'DESC',
    });
  };

  useEffect(() => {
    refetch();
  }, [filter]);

  const editStatus = (item, status) => {
    item.status_change = status;
    setItemChangeStatus(item);
  };

  const onRowClicked = (row) => {
    setItemPopUp(row);
  };

  const onConfirm = () => {
    const { status_change, id } = itemChangeStatus;
    if (status_change === 'in_process') {
      mutateInProcess({
        id,
      });
    } else {
      mutate({
        id,
        status: status_change,
      });
    }
  };

  useEffect(() => {
    if (isSuccess) {
      toastSuccess('Huỷ giao dịch thành công');
      refetch();
      setItemChangeStatus(null);
      setIsRefreshStatus({ state: true });
    }
    if (error) {
      toastError(error);
    }
  }, [isSuccess, error]);

  useEffect(() => {
    if (isSuccessInProcess) {
      toastSuccess('Chuyển đổi trạng thái giao dịch thành công');
      setItemChangeStatus(null);
      setIsRefreshStatus({ state: true });
    }
    if (errorInProcess) {
      toastError(errorInProcess);
    }
  }, [isSuccessInProcess, errorInProcess]);

  const getMessageModal = (item) => {
    const { status_change, id } = item;
    let message;
    switch (status_change) {
      case 'cancelled':
        message = `Huỷ giao dịch ${id} ?`;
        break;
      case 'expired':
        message = `Giao dịch ${id} đã hết hạn`;
        break;
      case 'in_process':
        message = 'Chuyển sang trạng thái Đang vào lệnh';
        break;
      default:
        break;
    }
    return message;
  };

  const setOrderBy = useCallback((column, type = '') => {
    setFilter((prevValues) => {
      return {
        ...prevValues,
        sort_by: column.sortField,
        order: type.toUpperCase(),
      };
    });
  }, []);

  const onSearch = useCallback((fil) => {
    setFilter((pre) => ({ ...pre, ...fil }));
  }, []);

  const onExport = useCallback(
    async (fil) => {
      return transactionTellerExport({ ...filter, ...fil });
    },
    [filter],
  );

  return (
    <div className="content-wrapper">
      <TransactionWrapper>
        <div className="d-flex align-items-center justify-content-between header-body mb-2">
          <p className="font-size-28 font-weight-bolder">Giao dịch</p>
          <div className="d-flex align-items-center gap-20">
            <FilterComponent
              onSearch={onSearch}
              onExport={onExport}
              filters={[
                {
                  key: 'trade_type',
                  label: 'Sản phẩm',
                  width: 130,
                  options: [
                    {
                      label: 'CK cơ sở',
                      value: 'base',
                    },
                    {
                      label: 'CK T+',
                      value: 'spot',
                    },
                    {
                      label: 'CK Phái sinh',
                      value: 'derivative',
                    },
                  ],
                },
                {
                  key: 'id',
                  label: 'Mã GD',
                  width: 130,
                },
                {
                  key: 'type',
                  label: 'Loại',
                  width: 130,
                  options: [
                    {
                      label: 'Mua',
                      value: 'buy',
                    },
                    {
                      label: 'Bán',
                      value: 'sell',
                    },
                  ],
                },
                {
                  key: 'customer_id',
                  label: 'Tên Khách hàng',
                  width: 130,
                  Component: SearchCustomer,
                },
                {
                  key: 'stock_id',
                  label: 'Mã CK',
                  width: 130,
                },
                {
                  key: 'purchase_account_id',
                  label: 'Kho',
                  options: warehouses,
                  width: 130,
                },
              ]}
            />
            <FlatPickrDateRange
              isOpen={false}
              dateRange={filter.date_range}
              setDateRange={(name, v) => {
                searchParams.set(name, v);
                setSearchParams(searchParams);
                setFilter((pre) => {
                  return {
                    ...pre,
                    [name]: v,
                  };
                });
              }}
              className="filter-date-range"
              labelDefault="Chọn ngày"
              name="date_range"
            />
            {/* <Button color="primary" onClick={() => {}}>
              <SwapHorizontalCircle size={13} color="white" />
              <span className="font-size-14 ml-50">Lập lệnh</span>
            </Button> */}
          </div>
        </div>
        <div className="">
          <Status
            activeTab={activeTab}
            handleChangeStatus={changeStatus}
            filter={filter}
            isRefreshStatus={isRefreshStatus}
          />
        </div>
        <DataTableBasic
          onSort={setOrderBy}
          data={dataState}
          columns={columns({ editStatus, navigate })}
          onRowClicked={onRowClicked}
          expandableRowsComponent={ExpandTranction}
          expandableRows
        />
        {itemChangeStatus && (
          <CustomeModal
            isOpen={itemChangeStatus !== null}
            confirm={onConfirm}
            buttonName="Xác nhận"
            type="delete"
            mess={getMessageModal(itemChangeStatus)}
            closeModal={() => setItemChangeStatus(null)}
          />
        )}
        {itemPopUp && (
          <PopUpEdit
            isOpen={itemPopUp !== null}
            closeModal={() => setItemPopUp(null)}
            item={itemPopUp}
            optionsWarehouse={optionsWarehouse}
            optionsTeller={optionsTeller}
            refetch={refetch}
            setItemConfirm={setItemConfirm}
            setIsRefreshStatus={setIsRefreshStatus}
          />
        )}
        {itemConfirm && (
          <PopUpConfirm
            item={itemConfirm}
            isOpen={itemConfirm !== null}
            closeModal={() => setItemConfirm()}
            refetch={refetch}
            setIsRefreshStatus={setIsRefreshStatus}
          />
        )}
      </TransactionWrapper>
    </div>
  );
}

TransactionTelles.propTypes = {};

export default TransactionTelles;
