import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, Row, Col, Container, Table } from 'reactstrap';
import { formatVND } from '@Utils/helpers';
import dayjs from 'dayjs';
import TextInput from '@Components/Input/TextInput';
import { useForm } from 'react-hook-form';
import ButtonSubmit from '@Components/ButtonSubmit';
import useCustomMutation from '@Utils/useCustomMuation';
import { paymentUpdate, paymentReject } from '@Apis/admin/paymentRequest';
import { toastError, toastSuccess } from '@Utils/toastrHelper';
import { canEditPaymentRequest } from '@Utils/paymentRequestHelpers';
import CustomerInfo from './CustomerInfo';
import PaymentType from '../PaymentType';
import { PopupWrrapper } from './styles';
import ButtonStatus from './ButtonStatus';

function PopupDetail({ isOpen, closeModal, refetch, item }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();
  const [statusEdit, setStatusEdit] = useState(item.status);
  const { mutate, isSuccess, error } = useCustomMutation(paymentUpdate, true);

  const {
    mutate: muatateReject,
    isSuccess: isSuccessReject,
    error: errorReject,
  } = useCustomMutation(paymentReject, true);

  const onSubmit = (formData) => {
    const { id } = item;
    if (statusEdit === 'rejected') {
      muatateReject({
        id,
        note: formData.note,
      });
    } else {
      formData.id = id;
      formData.is_completed = true;
      mutate(formData);
    }
  };

  useEffect(() => {
    if (isSuccess) {
      toastSuccess('Duyệt yêu cầu nạp/rút thành công');
      closeModal();
      refetch();
    }
    if (error) {
      toastError(error);
    }
  }, [isSuccess, error]);

  useEffect(() => {
    if (isSuccessReject) {
      toastSuccess('Huỷ yêu cầu nạp/rút thành công');
      closeModal();
      refetch();
    }
    if (errorReject) {
      toastError(errorReject);
    }
  }, [isSuccessReject, errorReject]);

  return (
    <Modal
      isOpen={isOpen}
      toggle={closeModal}
      className="text-center"
      style={{ maxWidth: '1012px' }}
    >
      <PopupWrrapper>
        <Container>
          <Row>
            <Col md="4" className="border-right px-1 py-2">
              <CustomerInfo item={item} />
            </Col>
            <Col md="8" className="px-1 py-2">
              <div className="d-flex align-items-center justify-content-between">
                <div>
                  <p className="font-size-24 font-weight-normal text-left">
                    {item.type === 'deposit' && 'Yêu Cầu Nộp Tiền'}
                    {item.type === 'withdraw' && 'Yêu Cầu Rút Tiền'}
                  </p>
                  <p className="color-matte-gray text-left mt-25">Mã GD: {item.id}</p>
                </div>
                <ButtonStatus item={item} status={statusEdit} changeStatus={setStatusEdit} />
              </div>
              <div className="pt-2">
                <Table>
                  <thead>
                    <tr>
                      <th>THỜI GIAN </th>
                      <th>LOẠI LỆNH</th>
                      <th>SỐ TIỀN</th>
                      <th>NH {item.type === 'deposit' ? 'NỘP' : 'RÚT'} </th>
                      <th>SỐ TÀI KHOẢN</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row">
                        {dayjs.unix(item.created_at).format('HH:mm:ss - DD/MM/YYYY')}
                      </th>
                      <td>
                        <PaymentType type={item.type} />
                      </td>
                      <td className="font-size-14">{formatVND(item.amount)}</td>
                      {item.type === 'deposit' ? (
                        <>
                          <td className="font-size-14">{item?.bankAccount?.bank?.name}</td>
                          <td className="font-size-14">{item?.bankAccount?.account_number}</td>
                        </>
                      ) : (
                        <>
                          <td className="font-size-14">{item?.bankAccount?.bank?.name}</td>
                          <td className="font-size-14">{item?.bankAccount?.account_number}</td>
                        </>
                      )}
                    </tr>
                  </tbody>
                </Table>
                {canEditPaymentRequest(item) ? (
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <Row className="pb-1">
                      <Col md="6">
                        <TextInput
                          labelCol="12"
                          inputCol="12"
                          type="text"
                          labelClassName="font-size-14 mb-50"
                          labelName="Mã giao dịch ngân hàng (nếu có)"
                          placeholder="Mã giao dịch ngân hàng (nếu có)"
                          setValue={setValue}
                          name="transaction_code"
                          register={{ ...register('transaction_code') }}
                          errors={errors}
                        />
                      </Col>
                      <Col md="6">
                        <TextInput
                          labelCol="12"
                          inputCol="12"
                          type="text"
                          labelClassName="font-size-14 mb-50"
                          labelName="Ghi chú cho khách"
                          placeholder="Ghi chú cho khách"
                          setValue={setValue}
                          name="note"
                          register={{ ...register('note') }}
                          errors={errors}
                        />
                      </Col>
                    </Row>
                    <div className="d-line" />
                    <Row className="justify-content-center gap-20 pt-2 pl-2">
                      <ButtonSubmit
                        name="Xác nhận"
                        className="px-2"
                        size="md"
                        color="primary"
                        // isLoading={isLoading}
                      />
                      <Button type="button" onClick={closeModal}>
                        Hủy
                      </Button>
                    </Row>
                  </form>
                ) : (
                  <div className="d-flex justify-content-center">
                    <Button type="button" onClick={closeModal}>
                      Đóng
                    </Button>
                  </div>
                )}
              </div>
              {item.status === 'rejected' || item.status === 'completed' ? (<div className="pt-2">
                <div>
                  <label>
                    {item.status === 'rejected' ? 'Hủy' : 'Duyệt'} lúc:
                    {dayjs.unix(item.updated_at).format('HH:mm:ss - DD/MM/YYYY')}
                  </label>
                </div>
              </div>):null}
            </Col>
          </Row>
        </Container>
      </PopupWrrapper>
    </Modal>
  );
}

PopupDetail.propTypes = {
  isOpen: PropTypes.bool,
  closeModal: PropTypes.func,
  refetch: PropTypes.func,
  item: PropTypes.object,
};

export default PopupDetail;
