import { configureStore } from '@reduxjs/toolkit';
import uiReducer from '@Lib/slices/uiSlice';
import userSlice from '@Lib/slices/userSlice';
import transactionSlice from '@Lib/slices/transactionSlice';

export default configureStore({
  reducer: {
    ui: uiReducer,
    user: userSlice,
    transaction: transactionSlice,
  },
  devTools: true,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }),
  // enhancers: [devToolsEnhancer({ realtime: true, port: 8080 })],
});
