import React from 'react';
import PropTypes from 'prop-types';
import { Nav, NavItem, NavLink } from 'reactstrap';
import { NavigationBarWrapper } from './styles';

function NavigationBar({ tabs, activeTab, setActiveTab, className, style }) {
  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  return (
    <NavigationBarWrapper className={className} style={style}>
      <Nav tabs className="mb-0">
        {tabs.map((item) => (
          <NavItem key={item.id} className="" style={{ position: 'relative' }}>
            <NavLink
              active={activeTab === item.id}
              onClick={() => {
                toggle(item.id);
              }}
            >
              {item.icon && (
                <img src={activeTab === item.id ? item.iconActive : item.icon} alt={item.name} />
              )}
              <span className="align-middle pl-50">
                {item.name} {`${item.bage || item.bage === 0 ? `(${item.bage})` : ''}`}
              </span>
            </NavLink>
          </NavItem>
        ))}
      </Nav>
    </NavigationBarWrapper>
  );
}

NavigationBar.propTypes = {
  tabs: PropTypes.array,
  activeTab: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  setActiveTab: PropTypes.func,
  className: PropTypes.string,
  style: PropTypes.object,
};

export default NavigationBar;
