import styled from 'styled-components';

export const NavigationBarWrapper = styled.div`
  display: flex;
  background: white;
  border-bottom: 1px solid rgb(226, 226, 234);
  padding-left: 10px;
  ul {
    li {
      .nav-link {
        padding: 15px;
      }
      img {
        width: 16px;
        height: 16px;
      }
    }
  }
`;
