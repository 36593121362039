import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import useCustomQuery from '@Utils/useCustomQuery';
import { warehouseTransactionDetail } from '@Apis/admin/warehouse';
import InputSearch from '@Components/InputSearch';
import FlatPickrDateRange from '@Components/FlatPickrDateRange';
import DataTableBasic from '@Components/DataTableBasic';
import { columns } from './TransactionColumns';
import { TableWrapper } from './TransactionStyles';

function Transaction() {
  const { warehouseId } = useParams();
  const [page, setPage] = useState(0);
  const [filter, setFilter] = useState({
    id: warehouseId,
  });
  const { data } = useCustomQuery(warehouseTransactionDetail, filter);

  useEffect(() => {
    if (page) {
      setFilter({ ...filter, page });
    }
  }, [page]);

  const filterOnChange = (name, value) => {
    setFilter((prevValues) => {
      return {
        ...prevValues,
        [name]: value,
      };
    });
  };

  return (
    <div className="bg-white p-2">
      <div className="container mb-2">
        <div className="row justify-content-between align-items-center">
          <p className="font-size-24 font-weight-bold">Giao dịch</p>
          <div className="d-flex algin-items-center gap-20">
            <InputSearch
              placeholder="Nhập ID, tên KH"
              searchKey={filter.keyword}
              name="keyword"
              setSearchKey={filterOnChange}
              width="240px"
            />
            <FlatPickrDateRange
              isOpen={false}
              dateRange={filter.date_range}
              setDateRange={filterOnChange}
              className="filter-date-range"
              labelDefault="Chọn ngày"
              name="date_range"
            />
          </div>
        </div>
      </div>
      <TableWrapper>
        {data && (
          <DataTableBasic
            columns={columns(data?.paging?.page || 1, data?.paging?.per_page || 20)}
            data={data}
            isPageEffect
            page={page}
            setPage={setPage}
          />
        )}
      </TableWrapper>
    </div>
  );
}

Transaction.propTypes = {};

export default Transaction;
