export const CONDITIONS = [
  {
    id: 1,
    name: 'Thời hạn dưới 3 ngày',
    color: '#E43925',
    filter: {
      less_than_days: 3,
      is_settlement: 0,
      status: 'unpaid',
    },
    className: '',
  },
  {
    id: 2,
    name: 'Thời hạn 4-7 ngày',
    color: '#D37E17',
    filter: {
      less_than_days: 7,
      greater_than_days: 4,
      is_settlement: 0,
      status: 'unpaid',
    },
    title: '',
    className: '',
  },
  {
    id: 3,
    name: 'Thời hạn 8-14 ngày',
    color: '#5542F6',
    filter: {
      less_than_days: 14,
      greater_than_days: 8,
      is_settlement: 0,
      status: 'unpaid',
    },
    title: '',
    className: '',
  },
  {
    id: 4,
    name: 'Thời hạn trên 14 ngày',
    color: '#1785fd',
    filter: {
      greater_than_days: 15,
      is_settlement: 0,
      status: 'unpaid',
    },
    title: '',
    className: '',
  },
  {
    id: 5,
    name: 'Đã tất toán',
    color: '#504F54',
    filter: {
      is_settlement: 1,
      status: 'unpaid',
    },
    title: '',
    className: '',
  },
  {
    id: 6,
    name: 'Đã thanh toán',
    filter: {
      status: 'paid',
      is_settlement: 1,
    },
    color: '#14B13B',
    title: '',
    className: '',
  },
];

export const STATUS = [
  {
    label: 'Chưa tất toán',
    value: 'unpaid',
    color: '#84818A',
  },
  // {
  //   label: 'Đã tất toán',
  //   value: 'pending',
  //   color: '#84818A',
  // },
  {
    label: 'Đã thanh toán',
    value: 'paid',
    color: '#14B13B',
  },
];
