/* eslint-disable no-unsafe-optional-chaining */
import React, { Component, Fragment } from 'react';
import { formatNumber } from '@Utils/helpers';
import useCustomQuery from '@Utils/useCustomQuery';
import { loanSummary } from '@Apis/admin/loan';
import P from 'prop-types';
import ModalDetailSumary from '../ModalDetailSumary';

function CardRepo({ handleOpen }) {
  const { data: summary } = useCustomQuery(loanSummary, { type: 'debt' }, true);

  return (
    <div className="card-repo">
      <div className="card-repo-content">
        <div className="header-card-repo" style={{ background: '#504F54' }}>
          <div>TỔNG VỐN KHO</div>
        </div>
        <div className="card-repo-body">
          <div
            className="d-flex align-items-center justify-content-between"
            style={{ paddingBottom: 16 }}
          >
            <div className="view-money" style={{ fontSize: 40 }}>
              {formatNumber(summary?.total_amount, '--')}
            </div>
          </div>
          <div style={{ cursor: 'pointer' }} role="button" tabIndex="0" onClick={handleOpen}>
            <img src="/images/warehouseSource/view_detail.svg" alt="" />
          </div>
        </div>
      </div>
      <div className="card-repo-content">
        <div className="header-card-repo" style={{ background: '#84818A' }}>
          <div>CHI PHÍ LÃI VAY</div>
        </div>
        <div className="card-repo-body">
          <div
            className="d-flex align-items-center justify-content-between"
            style={{ paddingBottom: 16 }}
          >
            <div style={{ color: '#84818A' }}>lãi chưa tất toán</div>
            <div className="view-money">{formatNumber(summary?.provisional_amount, '--')}</div>
          </div>
          <div
            style={{ paddingBottom: 16 }}
            className="d-flex align-items-center justify-content-between"
          >
            <div style={{ color: '#84818A' }}>lãi đã tất toán, chưa thanh toán </div>
            <div className="view-money">{formatNumber(summary?.unpaid_amount, '--')}</div>
          </div>
          <div className="d-flex align-items-center justify-content-between">
            <div style={{ color: '#84818A' }}>lãi đã thanh toán</div>
            <div className="view-money">{formatNumber(summary?.paid_amount, '--')}</div>
          </div>
        </div>
      </div>
    </div>
  );
}

CardRepo.propTypes = {
  handleOpen: P.func,
};

class ExtendCard extends Component {
  handleOpen = () => {
    this.modalDetailSumary?.open();
  };

  render() {
    return (
      <>
        <ModalDetailSumary
          ref={(ref) => {
            this.modalDetailSumary = ref;
          }}
        />
        <CardRepo handleOpen={this.handleOpen} />
      </>
    );
  }
}

export default ExtendCard;
