import React, { useEffect, useState } from 'react';
import { Button } from 'reactstrap';
import { list, deleteBank } from '@Apis/profile/bank-account';
import useCustomQuery from '@Utils/useCustomQuery';
import CustomeModal from '@Components/CustomeModal';
import { AddCircle } from '@mui/icons-material';
import useCustomMutation from '@Utils/useCustomMuation';
import { toastError, toastSuccess } from '@Utils/toastrHelper';

import BankAccountList from './BankAccountList';
import BankAccountModify from './BankAccountModify';

function BankAccount(props) {
  const [isShowModify, setIsShowModify] = useState(false);
  const [editItem, setEditItem] = useState(null);
  const [deleteItem, setDeleteItem] = useState(null);

  const { data, refetch } = useCustomQuery(list);
  const { mutate, isSuccess, error, isError, isLoading } = useCustomMutation(deleteBank, true);

  const handleEdit = (item) => {
    setEditItem(item);
    setIsShowModify(true);
  };

  const handleHide = () => {
    setIsShowModify(false);
    setEditItem(null);
    refetch();
  };

  const handleDelete = () => {
    const { id } = deleteItem;
    mutate({ id });
  };

  useEffect(() => {
    if (isSuccess) {
      toastSuccess('Xoá tài khoản thành công');
      setDeleteItem(null);
      refetch();
    }
    if (error) {
      toastError(error);
    }
  }, [isSuccess, error]);

  return (
    <>
      <BankAccountList data={data} handleEdit={handleEdit} setDeleteItem={setDeleteItem} />
      {!isShowModify && (
        <Button
          color="link"
          className="d-flex align-items-center p-0"
          onClick={() => setIsShowModify(true)}
        >
          <AddCircle sx={{ color: '#2F69B3', fontSize: 14 }} />
          <span className="ml-50 font-size-14"> Thêm tài khoản</span>
        </Button>
      )}
      {isShowModify && <BankAccountModify hide={handleHide} item={editItem} />}
      <CustomeModal
        isLoading={isLoading}
        isOpen={deleteItem !== null}
        confirm={handleDelete}
        closeModal={() => setDeleteItem(null)}
        buttonName="Xoá"
        type="delete"
        mess="Bạn muốn xoá tài khoản ngân hàng này"
      />
    </>
  );
}

BankAccount.propTypes = {};

export default BankAccount;
