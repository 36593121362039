/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import dayjs from 'dayjs';
import React from 'react';
import EditIcon from '@mui/icons-material/Edit';
import { formatNumber } from '@Utils/helpers';
import SettingsIcon from '@mui/icons-material/Settings';

export const columns = (type, onEdit, onConfig) => {
  const isPS = type === 'derivative';
  const isBase = type === 'base';

  return [
    {
      name: 'TÊN GÓI',
      cell: (row) => (
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            marginLeft: 10,
          }}
        >
          <div className="uppercase">{row.name}</div>
          {!!row.is_default && (
            <div style={{ background: 'rgba(32, 201, 172, 0.1)', borderRadius: 4, marginLeft: 10 }}>
              <p className="text-success text-align-center font-size-14 font-weight-bolder py-25 px-1">
                Mặc định
              </p>
            </div>
          )}
        </div>
      ),
      center: true,
      width: '300px',
      fixed: 'left',
    },
    {
      name: 'THỜI GIAN TẠO',
      cell: (row) => dayjs.unix(row.created_at).format('HH:mm - DD/MM/YYYY'),
      center: true,
      width: '150px',
    },
    {
      name: 'Thời gian\n(Tháng)',
      cell: (row) => <div className="uppercase">{row.number_of_cycles || '--'}</div>,
      center: true,
      width: '200px',
    },
    {
      name: isPS ? 'Tổng SL\nHợp đồng' : 'TỔNG KLGD\nTỐI THIỂU',
      cell: (row) => <div className="uppercase">{row.min_transaction_value || '--'}</div>,
      center: true,
      width: '200px',
    },
    isPS
      ? {
        name: 'Số tiền KQ TB',
        cell: (row) => <div className="uppercase">{row.average_margin || '--'}</div>,
        center: true,
        width: '180px',
      }
      : undefined,
    isBase
      ? {
        name: 'Tổng GT GD\ntối thiểu',
        cell: (row) => <div className="uppercase">{row.min_stock_value || '--'}</div>,
        center: true,
        width: '180px',
      }
      : undefined,
    isBase
      ? {
        name: 'Tổng dư nợ\ntối thiểu',
        cell: (row) => <div className="uppercase">{row.min_debt || '--'}</div>,
        center: true,
        width: '180px',
      }
      : undefined,
    {
      name: 'SL KH',
      cell: (row) => (
        <div className="w-100 text-align-end">{formatNumber(row.customerCount?.count, '--')}</div>
      ),
      center: true,
    },
    {
      name: '',
      cell: (row) => (
        <div className="d-flex align-items-center" style={{ gap: 10 }}>
          {/* <div style={{ color: '#d32f2f' }}>
            <LockOutlined color="error" fontSize="16px" />
            <span>Khoá</span>
          </div> */}
          <div role="button" onClick={() => onConfig?.(row)} style={{ color: '#3b4253' }}>
            <SettingsIcon fontSize="16px" />
            <span>Cấu hình</span>
          </div>
          <div role="button" onClick={() => onEdit?.(row)} style={{ color: '#2F69B3' }}>
            <EditIcon fontSize="16px" />
            <span>Sửa</span>
          </div>
        </div>
      ),
      center: true,
      width: '300px',
      fixed: 'right',
    },
  ].filter((e) => e);
};
