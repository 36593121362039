export const TABS = [
  {
    id: 1,
    name: 'MUA',
  },
  {
    id: 2,
    name: 'BÁN',
  },
];

export const HISTORY_TABS = [
  {
    id: 1,
    name: 'Hôm nay',
  },
  {
    id: 2,
    name: 'Tất cả',
  },
];

export const TRADE_STATUS = [
  {
    label: 'Tất cả',
    value: '',
    color: '',
  },
  {
    label: 'Chờ duyệt',
    value: 'created',
    color: '#F2994A',
  },
  {
    label: 'Chờ duyệt',
    value: 'pending',
    color: '#F2994A',
  },
  {
    label: 'Chờ khớp',
    value: 'in_process',
    color: '#5542F6',
  },
  {
    label: 'Đã khớp hết',
    value: 'completed',
    color: '#219653',
  },
  {
    label: 'Khớp 1 phần',
    value: 'part_completed',
    color: '#219653',
  },
  {
    label: 'Đã hủy',
    value: 'cancelled',
    color: '#EB5757',
  },
  {
    label: 'Hết hạn',
    value: 'expired',
    color: '#828282',
  },
];
