import React, { useCallback, useState } from 'react';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import Ptype from 'prop-types';

function DropdownStep(props) {
  const { value, labelKey = 'label', labelValue = '_id', steps = [], onChange } = props;
  const [isOpen, setOpen] = useState();

  const handleClick = useCallback(() => {
    const index = steps.findIndex((e) => e[labelValue] === value);
    onChange?.(steps[index + 1 >= steps.length ? 0 : index + 1]);
  }, [value, steps, onChange, labelValue]);

  const isDisabled = steps?.every((e) => e.disabled);

  return (
    <div className="dropdown-step">
      <UncontrolledDropdown
        onToggle={() => {
          if (isDisabled) {
            return;
          }
          setOpen(!isOpen);
        }}
        isOpen={isOpen}
        style={{ display: 'flex' }}
      >
        <DropdownToggle
          color=""
          style={{
            backgroundColor: steps?.find((e) => e[labelValue] === value)?.color,
            color: '#fff',
            fontWeight: 500,
            borderRadius: isDisabled ? 4 : '4px 0 0 4px',
            minWidth: 156,
            cursor: isDisabled ? 'not-allowed' : 'pointer',
          }}
        >
          {steps?.find((e) => e[labelValue] === value)?.[labelKey]}
        </DropdownToggle>
        {!isDisabled ? (
          <div
            onClick={handleClick}
            role="button"
            tabIndex="0"
            className="button-next-step"
            style={{
              backgroundColor: steps?.find((e) => e[labelValue] === value)?.color,
            }}
          >
            <ArrowRightIcon style={{ color: '#fff' }} />
          </div>
        ) : null}
        <DropdownMenu>
          {steps?.map((e, i) => (
            <DropdownItem
              disabled={e.disabled}
              key={i.toString()}
              className="w-100"
              onClick={() => onChange?.(e)}
            >
              <span style={{ color: e.color }}>{e[labelKey]}</span>
            </DropdownItem>
          ))}
        </DropdownMenu>
      </UncontrolledDropdown>
    </div>
  );
}

DropdownStep.propTypes = {
  onChange: Ptype.func,
  steps: Ptype.array,
  labelKey: Ptype.string,
  labelValue: Ptype.string,
  value: Ptype.any,
};

export default DropdownStep;
