/* eslint-disable jsx-a11y/no-static-element-interactions */
import { TYPE_CS, TYPE_PS, TYPE_TPLUS } from '@Components/Layouts/PrivateLayout';
import Title from '@Components/Title';
import { setProductType } from '@Lib/slices/uiSlice';
import { formatNumber } from '@Utils/helpers';
import dayjs from 'dayjs';
import React, { useCallback, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { io } from 'socket.io-client';
import { accountsHome } from '@Apis/client/home';
import CardInfo from '../AssetManager/CardInfo';
import './styles.scss';

const arrayInfo = [
  {
    key: 'full_name',
    title: 'Họ tên',
  },
  {
    key: 'email',
    title: 'Email',
  },
  {
    key: 'identity_card',
    title: 'CMT',
  },
  {
    key: 'birthday',
    render: (v) => (v ? dayjs.unix(v).format('DD/MM/YYYY') : '--'),
    title: 'Ngày sinh',
  },
  {
    key: 'code',
    title: 'Mã Khách hàng',
  },
  {
    key: 'phone',
    title: 'Số điện thoại',
  },
  {
    key: 'created_at',
    render: (v) => (v ? dayjs.unix(v).format('DD/MM/YYYY') : '--'),
    title: 'Ngày đăng ký',
  },
  {
    key: 'address',
    title: 'Địa chỉ',
  },
];

const typeConst = {
  base: {
    label: 'CK Cơ sở',
    color: '#2F69B3',
    background: 'rgba(47, 105, 179, 0.1)',
    logo: '/images/logo/logo_cs.svg',
    role: TYPE_CS,
  },
  spot: {
    label: 'CK T+',
    color: '#5542F6',
    background: 'rgba(85, 66, 246, 0.1)',
    logo: '/images/logo/log_t_plus.svg',
    role: TYPE_TPLUS,
  },
  derivative: {
    label: 'Phái sinh',
    color: '#E43925',
    background: 'rgba(237, 28, 36, 0.1)',
    logo: '/images/logo/logo_ps.svg',
    role: TYPE_PS,
  },
};

function HomeClient() {
  const user = useSelector(({ user }) => user.info);

  const [data, setData] = useState({});
  const { accounts = [], overview = {} } = data;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const setProductTypeFunc = useCallback((type) => {
    dispatch(setProductType(type));
  }, []);

  useEffect(() => {
    const token = localStorage.getItem(process.env.TOKEN);
    const socket = io(`${process.env.BACKEND_SOCKET}/customer/account/accounts`, {
      query: { token },
      transports: ['websocket'],
    });
    socket.on('connect', () => {
      socket.emit('register', 'your-client-id');
    });
    socket.on('data', ({ data }) => {
      setData(data);
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  useEffect(async () => {
    const data = await accountsHome();
    setData(data);
  }, []);

  return (
    <div className="home-client asset-management">
      <Title name="Trang chủ" />
      <div className="info-user">
        <div className="profile-user mb-1">Thông tin cá nhân</div>
        <div className="d-flex" style={{ flexWrap: 'wrap', gap: 10 }}>
          {arrayInfo.map((info) => (
            <div key={info.key} className="info-item-profile">
              <div className="info-title">{info.title}</div>
              <div className="info-value">
                {info.render ? info.render(user[info.key]) : user[info.key] || '--'}
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="body-asset">
        <CardInfo data={overview} />
      </div>
      <div className="body-asset">
        <div className="info-product">
          {accounts.map((account) => {
            const type = account.trade_type;

            if (type === 'derivative') {
              return (
                <div className="type-info-product" key={type}>
                  <div
                    className="information-product"
                    style={{ background: typeConst[type].background }}
                  >
                    <div
                      className="d-flex align-items-center justify-content-center"
                      onClick={() => {
                        setProductTypeFunc(typeConst[type].role);
                        navigate(`/${type}/dashboard`);
                      }}
                    >
                      <img src={typeConst[type].logo} alt="" style={{ width: 36, height: 36 }} />
                      <span
                        style={{
                          marginLeft: 12,
                          fontWeight: 600,
                          color: typeConst[type].color,
                          fontSize: 24,
                          cursor: 'pointer',
                        }}
                      >
                        {typeConst[type].label}
                      </span>
                    </div>
                    <div
                      className="d-flex align-items-center justify-content-between mt-2"
                      style={{ fontSize: 14, fontWeight: 700, color: '#504F54', padding: 8 }}
                    >
                      <div>Tổng GTVT</div>
                      <div>{formatNumber(account.total_stock_value, '--', false, 6)}</div>
                    </div>
                    <div
                      className="d-flex align-items-center justify-content-between"
                      style={{
                        fontSize: 14,
                        fontWeight: 700,
                        color: '#504F54',
                        padding: 8,
                        borderTop: `1px solid ${typeConst[type].background}`,
                      }}
                    >
                      <div>Tổng NAV</div>
                      <div>{formatNumber(account.current_nav, '--', false, 6)}</div>
                    </div>
                  </div>
                  <div>
                    <div
                      className="d-flex align-items-center justify-content-between mt-1"
                      style={{ fontSize: 14, fontWeight: 700, color: '#2F69B3', padding: 8 }}
                    >
                      <div>Gói sản phẩm</div>
                      <div>{account.memberPackage?.name || '--'}</div>
                    </div>
                    <div
                      className="d-flex align-items-center justify-content-between"
                      style={{
                        fontSize: 14,
                        color: '#504F54',
                        padding: 16,
                        borderTop: '1px solid #EBEAED',
                      }}
                    >
                      <div>Mức hợp tác yêu cầu</div>
                      <div>{formatNumber(account.margin_per_contract, '--', false, 6)}</div>
                    </div>
                    <div
                      className="d-flex align-items-center justify-content-between"
                      style={{
                        fontSize: 14,
                        color: '#504F54',
                        padding: 16,
                        borderTop: '1px solid #EBEAED',
                      }}
                    >
                      <div>Phí giao dịch - mua</div>
                      <div>
                        {formatNumber(account.buy_rate, '--', false, 6)}
                        {account.trade_type !== 'derivative' ? '%' : ''}
                      </div>
                    </div>
                    <div
                      className="d-flex align-items-center justify-content-between"
                      style={{
                        fontSize: 14,
                        color: '#504F54',
                        padding: 16,
                        borderTop: '1px solid #EBEAED',
                      }}
                    >
                      <div>Phí giao dịch - bán</div>
                      <div>
                        {formatNumber(account.sell_rate, '--', false, 6)}
                        {account.trade_type !== 'derivative' ? '%' : ''}
                      </div>
                    </div>
                    <div
                      className="d-flex align-items-center justify-content-between"
                      style={{
                        fontSize: 14,
                        color: '#504F54',
                        padding: 16,
                        borderTop: '1px solid #EBEAED',
                      }}
                    >
                      <div>Phí vị thế qua đêm</div>
                      <div>{formatNumber(account.on_fee, '--', false, 6)}</div>
                    </div>
                    <div
                      className="d-flex align-items-center justify-content-between"
                      style={{
                        fontSize: 14,
                        color: '#504F54',
                        padding: 16,
                        borderTop: '1px solid #EBEAED',
                      }}
                    >
                      <div>Phí chậm nộp</div>
                      <div>{formatNumber(account.margin_interest_rate, '--', false, 6)}%</div>
                    </div>
                    <div
                      className="d-flex align-items-center justify-content-between"
                      style={{
                        fontSize: 14,
                        color: '#504F54',
                        padding: 16,
                        borderTop: '1px solid #EBEAED',
                      }}
                    >
                      <div>RTT</div>
                      <div>{formatNumber(50, '--', false, 6)}%</div>
                    </div>

                    <div
                      className="d-flex align-items-center justify-content-center fw-600"
                      style={{ fontSize: 14, color: '#2F69B3', padding: 16, cursor: 'pointer' }}
                      role="button"
                      tabIndex={0}
                      onClick={() => {
                        setProductTypeFunc(typeConst[type].role);
                        navigate(`/${type}/dashboard`);
                      }}
                    >
                      <div>Xem chi tiết</div>
                      <img style={{ marginLeft: 10 }} src="/images/logo/xem_chi_tiet.svg" alt="" />
                    </div>
                  </div>
                </div>
              );
            }
            return (
              <div className="type-info-product" key={type}>
                <div
                  className="information-product"
                  style={{ background: typeConst[type].background }}
                >
                  <div
                    className="d-flex align-items-center justify-content-center"
                    onClick={() => {
                      setProductTypeFunc(typeConst[type].role);
                      navigate(`/${type}/dashboard`);
                    }}
                  >
                    <img src={typeConst[type].logo} alt="" style={{ width: 36, height: 36 }} />
                    <span
                      style={{
                        marginLeft: 12,
                        fontWeight: 600,
                        color: typeConst[type].color,
                        fontSize: 24,
                        cursor: 'pointer',
                      }}
                    >
                      {typeConst[type].label}
                    </span>
                  </div>
                  <div
                    className="d-flex align-items-center justify-content-between mt-2"
                    style={{ fontSize: 14, fontWeight: 700, color: '#504F54', padding: 8 }}
                  >
                    <div>Tổng GTCK</div>
                    <div>{formatNumber(account.total_stock_value, '--', false, 6)}</div>
                  </div>
                  <div
                    className="d-flex align-items-center justify-content-between"
                    style={{
                      fontSize: 14,
                      fontWeight: 700,
                      color: '#504F54',
                      padding: 8,
                      borderTop: `1px solid ${typeConst[type].background}`,
                    }}
                  >
                    <div>Tổng NAV</div>
                    <div>{formatNumber(account.current_nav, '--', false, 6)}</div>
                  </div>
                </div>
                <div>
                  <div
                    className="d-flex align-items-center justify-content-between mt-1"
                    style={{ fontSize: 14, fontWeight: 700, color: '#2F69B3', padding: 8 }}
                  >
                    <div>Gói sản phẩm</div>
                    <div>{account.memberPackage?.name || '--'}</div>
                  </div>
                  <div
                    className="d-flex align-items-center justify-content-between"
                    style={{
                      fontSize: 14,
                      color: '#504F54',
                      padding: 16,
                      borderTop: '1px solid #EBEAED',
                    }}
                  >
                    <div>Phí hợp tác</div>
                    <div>{formatNumber(account.interest_rate, '--', false, 6)}%</div>
                  </div>
                  <div
                    className="d-flex align-items-center justify-content-between"
                    style={{
                      fontSize: 14,
                      color: '#504F54',
                      padding: 16,
                      borderTop: '1px solid #EBEAED',
                    }}
                  >
                    <div>Phí giao dịch - mua</div>
                    <div>
                      {formatNumber(account.buy_rate, '--', false, 6)}
                      {account.trade_type !== 'derivative' ? '%' : ''}
                    </div>
                  </div>
                  <div
                    className="d-flex align-items-center justify-content-between"
                    style={{
                      fontSize: 14,
                      color: '#504F54',
                      padding: 16,
                      borderTop: '1px solid #EBEAED',
                    }}
                  >
                    <div>Phí giao dịch - bán</div>
                    <div>
                      {formatNumber(account.sell_rate, '--', false, 6)}
                      {account.trade_type !== 'derivative' ? '%' : ''}
                    </div>
                  </div>
                  <div
                    className="d-flex align-items-center justify-content-between"
                    style={{
                      fontSize: 14,
                      color: '#504F54',
                      padding: 16,
                      borderTop: '1px solid #EBEAED',
                    }}
                  >
                    <div>RTT</div>
                    <div>{formatNumber(account.call_rate, '--', false, 6)}%</div>
                  </div>
                  <div
                    className="d-flex align-items-center justify-content-between"
                    style={{
                      fontSize: 14,
                      color: '#504F54',
                      padding: 16,
                      borderTop: '1px solid #EBEAED',
                    }}
                  >
                    <div>Phí cọc thiếu</div>
                    <div>{formatNumber(account.margin_interest_rate, '--', false, 6)}%</div>
                  </div>
                  <div
                    className="d-flex align-items-center justify-content-between"
                    style={{
                      fontSize: 14,
                      color: '#504F54',
                      padding: 16,
                      borderTop: '1px solid #EBEAED',
                    }}
                  >
                    <div />
                    <div />
                  </div>
                  <div
                    className="d-flex align-items-center justify-content-center fw-600"
                    style={{ fontSize: 14, color: '#2F69B3', padding: 16, cursor: 'pointer' }}
                    role="button"
                    tabIndex={0}
                    onClick={() => {
                      setProductTypeFunc(typeConst[type].role);
                      navigate(`/${type}/dashboard`);
                    }}
                  >
                    <div>Xem chi tiết</div>
                    <img style={{ marginLeft: 10 }} src="/images/logo/xem_chi_tiet.svg" alt="" />
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default HomeClient;
