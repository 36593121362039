/* eslint-disable import/no-cycle */
import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import {
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
  Badge,
} from 'reactstrap';
import { pushNotificaiton, pushNotificaitonAdmin } from '@Lib/slices/uiSlice';
import { Bell } from 'react-feather';
import ItemNotification from '@Views/Client/Notification/ItemNotification';
import { Link, useNavigate } from 'react-router-dom';
import { list, read } from '@Apis/client/notification';
import { toastError } from '@Utils/toastrHelper';
import dayjs from 'dayjs';
import { io } from 'socket.io-client';
import { useDispatch, useSelector } from 'react-redux';
import { accountsHome } from '@Apis/client/home';

function NotificationDropDown() {
  const [notifications, setNotification] = useState([]);
  const [count, setCount] = useState(0);
  const navigate = useNavigate();
  const refController = useRef();
  const dispatch = useDispatch();
  const [data, setDataAccounts] = useState({ accounts: [] });

  const { accounts } = data;
  const productType = useSelector((state) => state.ui.productType);
  const account = accounts?.find((e) => e.trade_type === productType);

  useEffect(async () => {
    if (process.env.DASHBOARD_TYPE === 'admin') {
      return;
    }
    const data = await accountsHome();
    setDataAccounts(data);
  }, []);

  useEffect(() => {
    getList();
  }, [account?.id]);

  useEffect(() => {
    const token = localStorage.getItem(process.env.TOKEN);
    const socket = io(
      `${process.env.BACKEND_SOCKET}/${process.env.DASHBOARD_TYPE}/notification/new`,
      {
        query: { token },
        transports: ['websocket'],
      },
    );
    socket.on('connect', () => {
      socket.emit('register', 'your-client-id', { customer_id: account?.id });
    });
    socket.on('data', (data) => {
      setNotification((pre) => [data, ...pre]);
    });
    return () => {
      socket.disconnect();
    };
  }, [account?.id]);

  useEffect(() => {
    getList();
    dispatch(pushNotificaiton(count));
  }, [count]);

  useEffect(() => {
    const token = localStorage.getItem(process.env.TOKEN);
    const socket = io(
      `${process.env.BACKEND_SOCKET}/${process.env.DASHBOARD_TYPE}/notification/unread-count`,
      {
        query: { token },
        transports: ['websocket'],
      },
    );
    socket.on('connect', () => {
      socket.emit('register', 'your-client-id', { customer_id: account?.id });
    });
    socket.on('data', ({ data }) => {
      setCount(data.un_read);
      if (process.env.DASHBOARD_TYPE === 'admin') {
        dispatch(pushNotificaitonAdmin(data));
      }
    });
    return () => {
      socket.disconnect();
    };
  }, []);

  const getList = useCallback(() => {
    list({ customer_id: account?.id })
      .then(({ data }) => {
        setNotification(data);
      })
      .catch(toastError);
  }, [account?.id]);

  const readNotification = () => {
    read({ id: 'all' }).catch(toastError);
  };

  return (
    <UncontrolledDropdown
      ref={refController}
      tag="li"
      className="dropdown-notification flex-shrink nav-item mr-25 d-sm-flex d-none "
    >
      <DropdownToggle className="nav-link" onClick={(e) => e.preventDefault()} nav>
        <div className="d-sm-flex d-none align-items-center bell">
          <Bell size={21} color="#171725" />
          {count > 0 && (
            <Badge color="danger" className="badge-up">
              {count > 9 ? '9+' : count}
            </Badge>
          )}
        </div>
      </DropdownToggle>
      <DropdownMenu right style={{ minWidth: 300 }}>
        <DropdownItem tag="div" header style={{ padding: 0 }}>
          <div className="info-notifcation" style={{ maxHeight: 392, overflow: 'auto' }}>
            {notifications.map((e, i) => {
              const date = dayjs.unix(e.created_at).format('DD/MM/YYYY');
              const datePre =
                notifications[i - 1] &&
                dayjs.unix(notifications[i - 1].created_at).format('DD/MM/YYYY');

              return (
                <Fragment key={i.toString()}>
                  {(!i || date !== datePre) && (
                    <div
                      style={{
                        fontSize: 14,
                        fontWeight: 500,
                        background: '#fff',
                        paddingTop: 15,
                        paddingLeft: 20,
                        paddingRight: 20,
                        color: '#84818A',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                    >
                      {date}
                      <span
                        onClick={readNotification}
                        style={{ color: '#2F69B3', cursor: 'pointer' }}
                      >
                        <img
                          style={{ marginRight: 8 }}
                          src="/images/notification/done_all.svg"
                          alt=""
                        />
                        Đọc hết
                      </span>
                    </div>
                  )}
                  <ItemNotification
                    onSelect={() => {
                      refController.current?.toggle?.();
                      navigate(`/notification?id=${e.id}`);
                    }}
                    data={e}
                    key={i.toString()}
                  />
                </Fragment>
              );
            })}
          </div>
          <div
            role="button"
            tabIndex="0"
            onClick={() => {
              refController.current?.toggle?.();
              navigate('/notification');
            }}
            style={{ paddingTop: 14, paddingBottom: 19, textAlign: 'center' }}
          >
            <Link to="/notification">Xem tất cả</Link>
          </div>
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  );
}

NotificationDropDown.propTypes = {};

export default NotificationDropDown;
