/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/prop-types */
import dayjs from 'dayjs';
import React from 'react';
import {
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
  Button,
} from 'reactstrap';
import { Square } from '@mui/icons-material';
import { TYPE_TPLUS } from '@Components/Layouts/PrivateLayout';
import {
  renderType,
  getQuantityMatching,
  getAveragePrice,
  renderProduct,
  renderConditionOrder,
} from '@Utils/tradeHelpers';
import { formatVND, formatNumber } from '@Utils/helpers';
import TooltipComment from '@Components/TooltipComment';
import { TRADE_STATUS } from '../constants';

function StatusButton({ status, changeStatus, item, openCancel, activeTab }) {
  let isButtonDropDown = false;
  if (!status) {
    return '';
  }
  if (['created'].includes(status)) {
    isButtonDropDown = true;
  }
  if (['pending', 'in_process', 'part_completed'].includes(status) && String(activeTab) === '3') {
    isButtonDropDown = true;
  }

  const objStatus = TRADE_STATUS.find((item) => item.value === status);
  return isButtonDropDown ? (
    <UncontrolledDropdown className="nav-item">
      <DropdownToggle
        color="none"
        style={{ background: objStatus?.color }}
        className="font-size-14 text-white text-uppercase white-space-nowrap"
        onClick={(e) => e.preventDefault()}
        caret
      >
        {objStatus?.label}
      </DropdownToggle>
      <DropdownMenu right>
        {status === 'created' ? (
          <DropdownItem className="w-100" onClick={() => changeStatus(item, 'expired')}>
            <Square color="disable" />
            <span className="align-middle font-size-14 font-weight-bolder ml-50 text-secondary">
              HẾT HẠN
            </span>
          </DropdownItem>
        ) : null}
        <DropdownItem
          className="w-100"
          onClick={() => {
            if (status === 'created') {
              changeStatus(item, 'cancelled');
            } else openCancel();
          }}
        >
          <Square color="error" />
          <span className="align-middle font-size-14 font-weight-bolder ml-50 text-danger">
            {status === 'created' ? 'HỦY' : 'Yêu cầu huỷ'}
          </span>
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  ) : (
    <Button
      color="none"
      className="text-white text-uppercase font-size-14 white-space-nowrap"
      style={{ background: objStatus?.color }}
    >
      {objStatus?.label}
    </Button>
  );
}

function renderPrice({ price_type, price }) {
  if (price_type !== 'price') {
    return (
      <div className="uppercase" style={{ textAlign: 'center' }}>
        <div style={{ background: 'rgba(32, 201, 172, 0.1)' }}>
          <p className="font-size-14 font-weight-bolder py-25 px-1 text-info">{price_type}</p>
        </div>
      </div>
    );
  }
  return formatNumber(price);
}

export const columns = ({ editStatus, navigate, openCancel, activeTab }) => {
  return [
    {
      name: 'Mã GD',
      selector: (row) =>
        row.edited_transaction_item_id !== undefined && row.edited_transaction_item_id === null ? (
          <>{row.id}</>
        ) : (
          <>
            <div
              class="text-white"
              style={{
                textAlign: 'center',
                backgroundColor: '#d37e17',
                padding: '2px',
                borderRadius: '3px',
              }}
            >
              <b>{row.id}</b>
            </div>
          </>
        ),
      center: true,
      width: '70px',
    },
    {
      name: 'THỜI GIAN',
      selector: (row) => (
        <>
          <div style={{ textAlign: 'center' }}>{dayjs.unix(row.created_at).format('HH:mm ')}</div>
          <div style={{ textAlign: 'center' }}>
            {dayjs.unix(row.created_at).format(' DD/MM/YY')}
          </div>
        </>
      ),
      center: true,
      sortable: true,
      width: '120px',
      sortField: 'created_at',
    },
    {
      name: 'ID KH',
      cell: (row) => row.publicCustomer.code,
      width: '100px',
      center: true,
    },
    {
      name: 'KHÁCH HÀNG',
      cell: (row) => {
        const tradeType =
          row.trade_type == 't2' || row.trade_type == 't0' ? TYPE_TPLUS : row.trade_type;
        return (
          <div
            onClick={() =>
              navigate(`/customer/detail/${row?.publicCustomer?.id}?trade_type=${tradeType}`)
            }
            style={{ textTransform: 'uppercase', cursor: 'pointer', color: '#2F69B3' }}
          >
            {row?.publicCustomer?.username}
          </div>
        );
      },
      width: '150px',
    },
    {
      name: 'LOẠI SP',
      selector: (row) => renderProduct(row.trade_type),
      width: '100px',
      center: true,
    },
    {
      name: 'LOẠI LỆNH',
      selector: (row) => renderType(row.type),
      width: '100px',
      center: true,
    },
    {
      name: 'CỔ PHIẾU',
      cell: (row) => row.stock_id,
      width: '90px',
      center: true,
    },
    {
      name: 'GIÁ ĐẶT',
      cell: (row) => <div style={{ width: '100%', textAlign: 'right' }}>{renderPrice(row)}</div>,
      center: true,
      sortable: true,
      sortField: 'price',
    },
    {
      name: 'KHỐI LƯỢNG',
      cell: (row) => (
        <div style={{ width: '100%', textAlign: 'right' }}>{formatVND(row.quantity)}</div>
      ),
      width: '130px',
      sortable: true,
      sortField: 'quantity',
    },
    {
      name: 'LỆNH ĐIỀU KIỆN',
      cell: (row) =>
        row.condition_type ? renderConditionOrder(row.condition_type, row.condition_price) : null,
      width: '120px',
      center: true,
    },
    {
      name: 'GIÁ KHỚP',
      cell: (row) => (
        <div style={{ width: '100%', textAlign: 'right' }}>
          {formatNumber(getAveragePrice(row), '--')}
        </div>
      ),
      width: '120px',
      sortField: 'dca_price',
      sortable: true,
    },
    {
      name: 'KL KHỚP',
      cell: (row) => (
        <div style={{ width: '100%', textAlign: 'right' }}>
          {formatNumber(getQuantityMatching(row))}
        </div>
      ),
      width: '120px',
      sortable: true,
      sortField: 'final_quantity',
    },
    {
      name: 'Kiểm soát viên',
      cell: (row) => <div className="uppercase">{row?.publicSurveyorUser?.username}</div>,
      width: '130px',
    },
    {
      name: 'GHI CHÚ',
      cell: (row) => <TooltipComment item={row} />,
    },
    {
      name: '',
      cell: (row) => (
        <StatusButton
          activeTab={activeTab}
          status={row.status}
          openCancel={() => openCancel(row)}
          changeStatus={editStatus}
          item={row}
        />
      ),
      center: true,
      width: '220px',
    },
  ];
};
