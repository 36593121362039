import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Button } from 'reactstrap';
import { useForm } from 'react-hook-form';
import SearchCustomer from '@Components/Input/SearchCustomer';
import ButtonSubmit from '@Components/ButtonSubmit';
import TextInput from '@Components/Input/TextInput';
import useCustomMutation from '@Utils/useCustomMuation';
import { paymentUpdate } from '@Apis/admin/paymentRequest';
import { toastError, toastSuccess } from '@Utils/toastrHelper';

import BankReceive from './BankReceive';

function Deposit({ refetch, closeModal }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm();
  const { mutate, isSuccess, error } = useCustomMutation(paymentUpdate, true);

  const onSubmit = (formData) => {
    formData.type = 'deposit';
    formData.is_completed = false;
    mutate(formData);
  };

  useEffect(() => {
    if (isSuccess) {
      toastSuccess('Tạo yêu cầu thành công');
      closeModal();
      refetch();
    }
    if (error) {
      toastError(error);
    }
  }, [isSuccess, error]);

  return (
    <Container>
      <form onSubmit={handleSubmit(onSubmit)}>
        <SearchCustomer
          labelName="Chọn khách hàng *"
          labelCol="12"
          inputCol="12"
          labelClassName="font-size-14 mb-50"
          name="customer_id"
          register={{ ...register('customer_id', { required: true }) }}
          errors={errors}
          setValue={setValue}
        />
        <BankReceive
          type="Deposit"
          labelName="Chọn tài khoản nộp *"
          labelCol="12"
          inputCol="12"
          errors={errors}
          name="bank_account_id"
          setValue={setValue}
          register={{ ...register('bank_account_id', { required: true }) }}
          watch={watch}
        />
        <TextInput
          labelCol="12"
          inputCol="12"
          type="price"
          labelClassName="font-size-14 mb-50"
          labelName="Nhập số tiền nộp *"
          placeholder="Nhập số tiền nộp"
          setValue={setValue}
          name="amount"
          register={{ ...register('amount', { required: true }) }}
          errors={errors}
        />
        <TextInput
          labelCol="12"
          inputCol="12"
          type="text"
          labelClassName="font-size-14 mb-50"
          labelName="Mã giao dịch ngân hàng (nếu có)"
          placeholder="Mã giao dịch ngân hàng (nếu có)"
          setValue={setValue}
          name="transaction_code"
          register={{ ...register('transaction_code') }}
          errors={errors}
        />
        <Row className="justify-content-center gap-20 py-2">
          <ButtonSubmit
            name="Xác nhận"
            className="px-2"
            size="md"
            color="primary"
            // isLoading={isLoading}
          />
          <Button type="button" onClick={closeModal}>
            Hủy
          </Button>
        </Row>
      </form>
    </Container>
  );
}

Deposit.propTypes = {
  closeModal: PropTypes.func,
  refetch: PropTypes.func,
};

export default Deposit;
