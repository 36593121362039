import React from 'react';
import { wareHouseSummary } from '@Apis/admin/warehouse';
import useCustomQuery from '@Utils/useCustomQuery';
import WarehouseSummary from '@Components/WarehouseSummary';

function Summary() {
  const { data } = useCustomQuery(wareHouseSummary);
  return data ? <WarehouseSummary data={data} /> : '';
}

Summary.propTypes = {};

export default Summary;
