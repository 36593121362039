/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable import/no-cycle */
import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { io } from 'socket.io-client';
import Title from '@Components/Title';
import DataTableBasic from '@Components/DataTableBasic';
import useCustomQuery from '@Utils/useCustomQuery';
import { accountsHome } from '@Apis/client/home';
import { stockAsset } from '@Apis/client/stock';
import { columns } from './columns';

function TableList() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const page = searchParams.get('page') || 1;

  const productType = useSelector((state) => state.ui.productType);
  const { data: dataAccounts } = useCustomQuery(accountsHome);
  const account = dataAccounts?.accounts?.find((e) => e.trade_type === productType);

  const [data, setDataAssetCustomer] = useState({ assets: [] });

  const getData = async () => {
    const data = await stockAsset({ page, per_page: 20, customer_id: account?.id });
    setDataAssetCustomer(data);
  };

  useEffect(() => {
    getData();
    const token = localStorage.getItem(process.env.TOKEN);
    const socket = io(`${process.env.BACKEND_SOCKET}/customer/stock/assets`, {
      query: { token },
      transports: ['websocket'],
    });
    socket.on('connect', () => {
      socket.emit('register', 'your-client-id', { page, per_page: 20, customer_id: account?.id });
    });
    socket.on('data', ({ data: dataSocket }) => {
      setDataAssetCustomer(dataSocket);
    });

    return () => {
      socket.disconnect();
    };
  }, [page, account?.id]);

  return (
    <div className="table-list-info" style={{ paddingBottom: '36px' }}>
      <div className="header-asset-manager" style={{ padding: '11px 24px' }}>
        <Title name="Danh mục của bạn" style={{ fontSize: 18 }} />
      </div>
      <DataTableBasic
        className="data-table-header-two"
        data={{
          data: data?.assets.concat({
            columnTotal: true,
            stock_id: 'Tổng',
            stock: {
              price: 0,
            },
            total1: data?.assets?.reduce((a, b) => {
              a += b.quantity * ((b.stock?.price * b.stock?.margin_rate) / 100);
              return a;
            }, 0),
            total_cost_value: data?.assets?.reduce((a, b) => {
              a += b.quantity * b?.lastTradingItem?.current_cost_price;
              return a;
            }, 0),
            total_value: data?.assets?.reduce((a, b) => {
              a += b.quantity * b.stock?.price;
              return a;
            }, 0),
            total2: data?.assets?.reduce((a, b) => {
              a += b.quantity * ((b.stock?.price * b.stock?.call_rate) / 100);
              return a;
            }, 0),
          }),
          paging: data?.paging,
        }}
        columns={columns(navigate, productType)}
        page={1}
        pageSize={5}
      />
    </div>
  );
}

export default TableList;
