/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/sort-comp */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { formatNumber } from '@Utils/helpers';
import './styles.scss';
import { ContentCopy } from '@mui/icons-material';

class FieldInput extends Component {
  constructor(props) {
    super(props);
    const { defaultValue, value, isInt, zeroIsEmpty } = props;
    const values = (value?.toString() || defaultValue?.toString() || '').toString().split('.');
    this.state = {
      zeroIsEmpty: zeroIsEmpty,
      valueFirst: values[0]?.toString() || '',
      valueLast: values[1]?.toString() ? `.${values[1]}` : '',
      value: value?.toString() || defaultValue?.toString() || '',
      copySuccess: '',
    };

    this.inputCopyRef = React.createRef();
  }

  shouldComponentUpdate(nProps, nState) {
    const arrayState = ['valueFirst', 'valueLast', 'value', 'copySuccess'];
    const arrayProps = [
      'type',
      'label',
      'required',
      'placeholder',
      'errorMessage',
      'component',
      'style',
      'disabled',
      'className',
      'styleInput',
      'init',
      'Component',
      'options',
      'canCopy',
    ];
    return (
      arrayState.some((e) => this.state[e] !== nState[e]) ||
      arrayProps.some((e) => this.props[e] !== nProps[e])
    );
  }

  UNSAFE_componentWillReceiveProps(nProps) {
    const { value, type } = nProps;
    const { valueFirst, valueLast, value: valueState } = this.state;
    if (type === 'number') {
      if (value?.toString() !== `${valueFirst}${valueLast ? `.${valueLast}` : ''}`) {
        const values = value?.toString().split('.');
        this.setState({
          valueFirst: values?.[0] || '',
          valueLast: values?.[1] ? `.${values?.[1]}` : '',
        });
      }
    } else if (value?.toString() !== valueState?.toString()) {
      this.setState({ value });
    }
  }

  handleChange = (e) => {
    const { onChange, type, min, max } = this.props;
    var { isInt, zeroIsEmpty } = this.props;
    let value = e.target.value || '';
    if (value === '.') {
      value = '0.';
    }
    isInt = isInt === true;
    // console.log(isInt);
    if (type === 'number') {
      if (isInt) {
        value = `${parseInt(value.replaceAll(',', ''))}`;
      }
      value = value.replace(/,/g, '').replace(/[^0-9-.]/g, '');

      // tính toán số âm
      if (value.includes('-')) {
        if (value.replace(/[0-9.]/g, '').length % 2 === 0) {
          value = value.replace(/-/g, '');
        } else {
          value = `-${value.replace(/-/g, '')}`;
        }
      }
      if ((min || min === 0) && Number(value || 0) < min) {
        return;
      }
      if (max < min) {
        return '';
      }
      if ((max || max === 0) && Number(value || 0) > max) {
        value = max.toString();
      }
      if (min >= 0) {
        value = value.replace(/-/g, '');
      }
      const values = value.split('.');
      const valueLast = value.includes('.') ? '.' : '';
      if (type === 'number') {
        if ((value.includes('.') && value.split('.')[1]) || !value.includes('.')) {
          onChange?.(value);
        }
      } else {
        onChange?.(value);
      }
      this.setState({ valueFirst: values[0], valueLast: valueLast + (values[1] || '') });
    } else {
      onChange?.(e.target.value);
      this.setState({ value: e.target.value });
    }
  };

  handleCopy = () => {
    this.setState({ copySuccess: 'Copied!' });
    navigator.clipboard.writeText(this.inputCopyRef.current.value);
  };

  render() {
    const {
      label,
      required,
      placeholder,
      errorMessage,
      type,
      component,
      disabled,
      style,
      className,
      styleInput,
      init,
      colorRequire,
      labelDropdown,
      canCopy,
      Component: ComponentCustom,
      ...p
    } = this.props;
    const { valueFirst, valueLast, value, zeroIsEmpty, copySuccess } = this.state;

    return (
      <div
        className={`field-input-custom${className ? ` ${className}` : ''}${
          errorMessage ? ' input-field-error' : ''
        }`}
        style={style}
      >
        {label ? (
          <div className="label-field-input">
            {label}
            {required ? <span style={{ color: colorRequire || '#FC3400' }}> *</span> : ''}
          </div>
        ) : null}
        <div className={`${init ? ' input-field-init' : ''} input-field-wrapper`}>
          {component ||
            (ComponentCustom ? (
              <ComponentCustom
                {...p}
                label={labelDropdown}
                placeholder={placeholder}
                style={styleInput}
              />
            ) : canCopy && value ? (
              <div className="d-flex align-items-center">
                <input
                  type={type !== 'number' ? type : ''}
                  disabled={!!disabled}
                  onChange={this.handleChange}
                  value={
                    type === 'number'
                      ? formatNumber(valueFirst, undefined, zeroIsEmpty) +
                        valueLast.toString().replace(',', '')
                      : value
                  }
                  placeholder={placeholder}
                  style={styleInput}
                  ref={this.inputCopyRef}
                />
                <ContentCopy
                  onClick={this.handleCopy}
                  style={{ cursor: 'pointer', marginLeft: 10 }}
                  fontSize="14px"
                />
                <span className="copy-success">{copySuccess}</span>
              </div>
            ) : (
              <input
                type={type !== 'number' ? type : ''}
                disabled={!!disabled}
                onChange={this.handleChange}
                value={
                  type === 'number'
                    ? formatNumber(valueFirst, undefined, zeroIsEmpty) +
                      valueLast.toString().replace(',', '')
                    : value
                }
                placeholder={placeholder}
                style={styleInput}
              />
            ))}
          <div className="init-field-preview" style={{ color: '#2E2C34' }}>
            {init}
          </div>
        </div>
        {errorMessage && (
          <div style={{ color: '#FC3400', fontSize: 13, height: 17 }}>{errorMessage}</div>
        )}
      </div>
    );
  }
}

// eslint-disable-next-line react/function-component-definition
FieldInput.TextArea = (props) => {
  const { disabled, placeholder, onChange, styleInput, value, defaultValue, ...p } = props;
  return (
    <FieldInput
      {...p}
      component={
        // eslint-disable-next-line react/jsx-wrap-multilines
        <textarea
          disabled={!!disabled}
          onChange={(e) => onChange?.(e.target.value)}
          placeholder={placeholder}
          style={styleInput}
          value={value || defaultValue}
        />
      }
    />
  );
};

FieldInput.propTypes = {
  label: PropTypes.any,
  defaultValue: PropTypes.any,
  value: PropTypes.any,
  placeholder: PropTypes.string,
  labelDropdown: PropTypes.string,
  component: PropTypes.any,
  Component: PropTypes.any,
  errorMessage: PropTypes.string,
  type: PropTypes.any,
  required: PropTypes.bool,
  onChange: PropTypes.func,
  min: PropTypes.number,
  max: PropTypes.number,
  disabled: PropTypes.bool,
  style: PropTypes.object,
  styleInput: PropTypes.object,
  className: PropTypes.string,
  init: PropTypes.any,
  colorRequire: PropTypes.string,
};

FieldInput.TextArea.propTypes = {
  label: PropTypes.any,
  defaultValue: PropTypes.any,
  value: PropTypes.any,
  placeholder: PropTypes.string,
  component: PropTypes.any,
  Component: PropTypes.any,
  errorMessage: PropTypes.string,
  type: PropTypes.any,
  required: PropTypes.bool,
  onChange: PropTypes.func,
  min: PropTypes.number,
  max: PropTypes.number,
  disabled: PropTypes.bool,
  style: PropTypes.object,
  styleInput: PropTypes.object,
  className: PropTypes.string,
  colorRequire: PropTypes.string,
  init: PropTypes.any,
};

export default FieldInput;
