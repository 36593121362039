import React from 'react';
import { Navigate } from 'react-router-dom';
import PrivateLayout, { TYPE_CS, TYPE_PS, TYPE_TPLUS } from '@Components/Layouts/PrivateLayout';
import TransactionGroupSwitch from '@Views/Client/TransactionGroupSwitch';
import AssetManager from '../../views/Client/AssetManager';
import Recharge from '../../views/Client/Recharge';
import Account from '../../views/Client/Account';
import TransactionList from '../../views/Client/TransactionList';
import Dashboard from '../../views/Client/Dashboard';
import Notification from '../../views/Client/Notification';
import { useSelector } from 'react-redux';
import PageNotFound from '@Views/PageNotFound';

export default function getClientConstant() {
  const productType = useSelector((state) => state.ui.productType);
  const typeArray = [TYPE_CS, TYPE_TPLUS, TYPE_PS];

  let routes = [
    {
      path: '/',
      element: <PrivateLayout />,
      children: [
        {
          path: '/',
          element: <Navigate to="/home" />,
          hidden: true,
        },
        {
          name: 'Trang chủ',
          path: '/home',
          icon: 'dashboard',
          element: <Navigate to="/home" />,
        },
      ],
    },
  ];

  typeArray.forEach((type) => {
    const tmp = {
      path: '/' + type,
      hidden: productType !== type,
      element: <PrivateLayout />,
      children: [
        {
          name: 'Tổng quan',
          path: '/' + type + '/dashboard',
          icon: 'instrument',
          element: <Dashboard />,
        },
        {
          name: 'Giao dịch',
          path: '/' + type,
          icon: 'transaction',
          hasSub: true,
          relatedPath: '/' + type + '/transaction',
          children: [
            {
              name: 'Đặt lệnh',
              path: '/' + type + '/transaction',
              element: <TransactionGroupSwitch />,
            },
            {
              name: 'Sổ lệnh',
              path: '/' + type + '/transaction/list',
              element: <TransactionList />,
            },
          ],
        },
        {
          name: 'Tài Sản',
          path: '/' + type + '/asset-management',
          element: <AssetManager />,
          icon: 'asset-management',
        },
        {
          name: 'Sổ tiền',
          path: '/' + type + '/recharge',
          element: <Recharge />,
          icon: 'recharge',
        },
        { path: '*', element: <PageNotFound />, role: '', hidden: true },
      ],
    };

    routes.push(tmp);
  });

  routes.push({
    path: '/',
    element: <PrivateLayout />,
    children: [
      {
        name: 'Thông báo',
        path: '/notification',
        element: <Notification />,
        icon: 'notification',
      },
      {
        name: 'Tài khoản',
        path: '/account',
        element: <Account />,
        icon: 'user',
      },
    ],
  });

  return routes;
}
