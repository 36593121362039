import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { getInputErrorsObject } from '@Utils/helpers';
import StockSuggest from '@Components/SuggestSearch/StockSuggest';

function SearchStock({
  name,
  labelName,
  labelCol,
  labelClassName,
  register,
  errors,
  setValue,
  defaultValue,
  tradeType,
}) {
  const [errorDetail, setErrorDetail] = useState();
  const [selected, setSelected] = useState();

  useEffect(() => {
    if (name && errors) {
      setErrorDetail(getInputErrorsObject(name, errors));
    }
  }, [name, errors && Object.keys(errors)]);

  useEffect(() => {
    if (selected) {
      setValue(name, selected);
    } else {
      setValue(name, '');
    }
  }, [selected]);
  return (
    <div>
      <StockSuggest
        name={name}
        labelName={labelName}
        labelCol={labelCol}
        labelClassName={labelClassName}
        setSelected={setSelected}
        errorDetail={errorDetail}
        defaultValue={defaultValue}
        tradeType={tradeType}
      />
    </div>
  );
}

SearchStock.propTypes = {
  name: PropTypes.string,
  labelName: PropTypes.string,
  labelClassName: PropTypes.string,
  labelCol: PropTypes.string,
  register: PropTypes.object,
  errors: PropTypes.object,
  setValue: PropTypes.func,
  defaultValue: PropTypes.number,
  tradeType: PropTypes.any,
};

export default SearchStock;
