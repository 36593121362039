import axiosService from '@Lib/axiosService';
import { handleResponse } from '@Utils/helpers';

let url = '';
if (process.env.DASHBOARD_TYPE === 'admin') {
  url = `${process.env.API_ADMIN_URL}/day-off`;
}

export const list = async (query) => {
  const res = await axiosService.get(`${url}/list`, query);
  return handleResponse(res);
};

export const remove = async (dataReq) => {
  const res = await axiosService.post(`${url}/delete`, dataReq);
  return handleResponse(res);
};

export const create = async (dataReq) => {
  const res = await axiosService.post(`${url}/save`, dataReq);
  return handleResponse(res);
};
