import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
// import SuggestSearchCustomer from '@Components/SuggestSearch/CustomerSuggest';
import { Container, Row, Col } from 'reactstrap';
import TextInput from '@Components/Input/TextInput';
import { useForm } from 'react-hook-form';
import InputSearch from '@Components/InputSearch';
import useCustomQuery from '@Utils/useCustomQuery';
import { canSellNew, createSellTransactionNew } from '@Apis/admin/transaction';
import DataTableBasic from '@Components/DataTableBasic';
// import shortid from 'shortid';
// import { Layers, LayersClear } from '@mui/icons-material';
import ButtonSubmit from '@Components/ButtonSubmit';
import useCustomMutation from '@Utils/useCustomMuation';
import { toastError, toastSuccess } from '@Utils/toastrHelper';
import SearchCustomer from '@Components/Input/SearchCustomer';
import SelectInput from '@Components/Input/SelectInput';
import { formatNumber } from '@Utils/helpers';
import { TYPE_CS } from '@Components/Layouts/PrivateLayout';
import { columns } from './SellColumns';
// import WarehouseItem from './WarehouseItem';

function Sell({ closeModal, refetch, setIsRefreshStatus }) {
  const [stock, setStock] = useState('');
  const [itemSelected, setItemSelected] = useState(null);
  const [page, setPage] = useState(0);
  const { mutate, isSuccess, error } = useCustomMutation(createSellTransactionNew, true);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm();

  const price = watch('price');
  const priceType = watch('price_type');

  useEffect(() => {
    if (priceType !== 'price') {
      setValue('price', itemSelected?.stock?.price);
    }
  }, [priceType]);

  useEffect(() => {
    setValue('price', itemSelected?.stock?.price);
  }, [itemSelected]);

  const { data } = useCustomQuery(
    canSellNew,
    {
      customer_id: watch('customer_id'),
      keyword: stock,
    },
    true,
  );

  const searchOnChange = (name, value) => {
    setStock(value);
  };

  const toggleItem = (item) => {
    if (item.id === itemSelected?.id) {
      setItemSelected(null);
    } else {
      setItemSelected(item);
    }
  };

  const onSubmit = (formData) => {
    const { id } = itemSelected;
    const { customer_id, note, price, quantity, stock_id, ...restForm } = formData;
    const transactions = Object.values(restForm);
    mutate({
      id,
      customer_id,
      note,
      price,
      quantity,
      stock_id,
      transactions,
    });
  };

  useEffect(() => {
    if (isSuccess) {
      toastSuccess('Lập lệnh thành công');
      closeModal();
      refetch();
      setIsRefreshStatus({ state: true });
    }
    if (error) {
      toastError(error);
    }
  }, [isSuccess, error]);

  const getCallSell = (item) => {
    if (!itemSelected) return;
    if (item?.available_quantity <= 0) return 0;
    const pending =
      itemSelected.sellingQuantity === undefined || itemSelected.sellingQuantity === null
        ? 0
        : itemSelected.sellingQuantity.selling_quantity;
    return item.available_quantity - pending;
  };

  return (
    <Container className="trade-sell">
      <Row className="">
        <Col md="7" className="trade-sell-left py-2">
          <Container>
            <Row style={{ gap: 16 }}>
              <Col style={{ padding: 0 }}>
                <SearchCustomer
                  tradeType={TYPE_CS}
                  inputClassName="uppercase"
                  labelCol="12"
                  inputCol="12"
                  labelClassName="font-size-14 mb-50"
                  name="customer_id"
                  register={{ ...register('customer_id', { required: true }) }}
                  errors={errors}
                  setValue={setValue}
                />
              </Col>
              <Col style={{ padding: 0 }}>
                <InputSearch
                  style={{ height: 40 }}
                  inputClassName="uppercase"
                  placeholder="Nhập mã CK"
                  searchKey={stock}
                  name="stock"
                  setSearchKey={searchOnChange}
                />
              </Col>
            </Row>
            <Row className="stock-trade">
              {watch('customer_id') ? (
                <DataTableBasic
                  className="w-100"
                  columns={columns({ itemSelected, toggleItem })}
                  data={data}
                  fixedHeader
                  fixedHeaderScrollHeight="500px"
                  isPageEffect
                  page={page}
                  setPage={setPage}
                />
              ) : (
                <div className="d-flex flex-column w-100 justify-content-center  mx-auto align-items-center">
                  <img src="/images/page/stock-trade.svg" alt="" width="150px" />
                  <p className="color-matte-gray">Vui lòng chọn khách hàng trước</p>
                </div>
              )}
            </Row>
          </Container>
        </Col>
        <Col md="5" className="py-2 bg-sell">
          <form onSubmit={handleSubmit(onSubmit)} style={{ height: '100%' }}>
            <div className="pb-2">
              <Row className="pb-1">
                <Col sm="6">Tổng KL: {formatNumber(itemSelected?.quantity, '0')}</Col>
                <Col sm="6">KL khả dụng: {formatNumber(getCallSell(itemSelected), '0')}</Col>
              </Row>
            </div>
            <div className="reponsive-row">
              <div className="reponsive-col">
                <TextInput
                  labelCol="12"
                  inputCol="12"
                  type="text"
                  labelClassName="font-size-14 mb-50"
                  labelName="Mã CK *"
                  placeholder="Mã CK"
                  setValue={setValue}
                  name="stock_id"
                  register={{ ...register('stock_id') }}
                  errors={errors}
                  defaultValue={itemSelected?.stock_id || ''}
                  disabled
                />
              </div>
              <div className="reponsive-col">
                <div className="d-flex align-items-end">
                  <SelectInput
                    styleInput={{
                      borderTopRightRadius: 0,
                      borderBottomRightRadius: 0,
                      borderRightWidth: 0.5,
                      textTransform: 'none',
                      minWidth: 65,
                    }}
                    labelName="Giá bán *"
                    labelCol="12"
                    inputCol="12"
                    inputClassName="uppercase"
                    options={[
                      {
                        label: 'Giá',
                        value: 'price',
                      },
                      {
                        label: 'MP',
                        value: 'mp',
                      },
                      {
                        label: 'ATO',
                        value: 'ato',
                      },
                      {
                        label: 'ATC',
                        value: 'atc',
                      },
                    ]}
                    placeholder="Giá bán *"
                    errors={errors}
                    labelClassName="font-size-14"
                    name="price_type"
                    register={register('price_type')}
                    setValue={setValue}
                    defaultValue="price"
                  />
                  <TextInput
                    styleInput={{
                      borderTopLeftRadius: 0,
                      borderBottomLeftRadius: 0,
                      borderLeftWidth: 0.5,
                      opacity: priceType !== 'price' ? 0 : 1,
                      padding: '0 7px',
                    }}
                    labelCol="12"
                    inputCol="12"
                    type="price"
                    labelClassName="font-size-14 mb-50"
                    placeholder="Giá bán *"
                    setValue={setValue}
                    name="price"
                    register={{ ...register('price', { required: true }) }}
                    errors={errors}
                    defaultValue={price !== undefined ? price : itemSelected?.price}
                  />
                </div>
              </div>
              <div className="reponsive-col">
                <TextInput
                  labelCol="12"
                  inputCol="12"
                  type="price"
                  labelClassName="font-size-14 mb-50"
                  labelName="Khối lượng *"
                  placeholder="Khối lượng *"
                  setValue={setValue}
                  name="quantity"
                  register={{
                    ...register('quantity', {
                      required: true,
                      validate: (value) => {
                        if (
                          value >
                          (itemSelected?.available_quantity || 0) -
                            (itemSelected?.sell_quantity || 0)
                        ) {
                          return 'Không thể lớn hơn KL khả dụng';
                        }
                        if (!value) {
                          return 'KL phải lớn hơn 0';
                        }
                      },
                    }),
                  }}
                  errors={errors}
                />
              </div>
            </div>
            <Row>
              <Col>
                <TextInput
                  labelCol="12"
                  inputCol="12"
                  type="textarea"
                  labelClassName="font-size-14 mb-50"
                  labelName="Ghi chú "
                  placeholder="Ghi chú "
                  setValue={setValue}
                  name="note"
                  register={{ ...register('note') }}
                  errors={errors}
                />
              </Col>
            </Row>
            <ButtonSubmit
              name="Bán"
              className="px-2"
              size="md"
              color="danger"
              // isLoading={isLoading}
            />
          </form>
        </Col>
      </Row>
    </Container>
  );
}

Sell.propTypes = {
  dataWarehouse: PropTypes.array,
  closeModal: PropTypes.func,
  refetch: PropTypes.func,
  optionsWarehouse: PropTypes.array,
  optionsTeller: PropTypes.array,
  setIsRefreshStatus: PropTypes.func,
};

export default Sell;
