import React from 'react';
import Type from '@Components/PaymentRequest/Type';
import { formatVND } from '@Utils/helpers';
import dayjs from 'dayjs';
import StatusPayment from '@Components/PaymentRequest/StatusPayment';

export const columns = () => {
  return [
    {
      name: 'ID',
      cell: (row) => row.id,
      width: '110px',
      center: true,
      sortable: true,
      sortField: 'id',
    },
    {
      name: 'Loại',
      cell: (row) => <Type type={row.type} />,
      width: '100px',
      center: true,
    },
    {
      name: 'Số tiền',
      cell: (row) => <div className="w-100 text-align-end">{formatVND(row.amount)}</div>,
      width: '150px',
      sortable: true,
      sortField: 'amount',
    },
    {
      name: 'NH  nộp/rút',
      cell: (row) => row.processBankAccount?.bank_id,
    },
    {
      name: 'Ngày nộp/rút ',
      cell: (row) => dayjs.unix(row.created_at).format('DD/MM/YYYY'),
      center: true,
      width: '130px',
      sortable: true,
      sortField: 'created_at',
    },
    {
      name: 'Trạng thái',
      cell: (row) => <StatusPayment status={row.status} />,
      center: true,
      width: '110px',
    },
    {
      name: 'Ghi chú',
      cell: (row) => row.note,
    },
  ];
};
