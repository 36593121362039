import React from 'react';
import GlobalLoading from '@Components/GlobalLoading';
import { Provider } from 'react-redux';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Routers from './routers';
import store from './store';

const queryClient = new QueryClient();

export default function App() {
  return (
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <Routers />
        <GlobalLoading />
        <ToastContainer />
      </QueryClientProvider>
    </Provider>
  );
}
