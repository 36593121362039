import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalBody, Container, Row, Col, Button, Label } from 'reactstrap';
import { PopUpWrapper } from './styles';
import { useForm } from 'react-hook-form';
import useCustomMutation from '@Utils/useCustomMuation';
import TextInput from '@Components/Input/TextInput';
import ButtonSubmit from '@Components/ButtonSubmit';
import SearchStock from '@Components/Input/SearchStock';
import SearchCustomer from '@Components/Input/SearchCustomer';
import { TYPE_PS } from '@Components/Layouts/PrivateLayout';
import FieldInput from '@Views/Client/Recharge/BodyTab/Request/FieldInput';
import DropdownSelect from '@Components/DropdownSelect';
import { Checkbox } from '@mui/material';
import { formatNumber } from '@Utils/helpers';
import { trade } from '@Apis/client/trade';
import { toastError, toastSuccess } from '@Utils/toastrHelper';
import { customerAssets } from '@Apis/admin/customer';
import { maxLongShort } from '@Apis/admin/trade';
import { renderVTHT } from '@Views/Client/TransactionTPlus';
import './styles.scss';

function PopUpCreatePS({ isOpen, closeModal, refetch }) {
  const [openWarning, setOpenWarning] = useState({ isShow: false, type: '', data: {} });
  const [buyType, setBuyType] = useState('buy');
  const [dataCurrentPosition, setDataCurrentPosition] = useState();
  const [dataMaxLongShort, setDataMaxLongShort] = useState();

  const { mutate, isSuccess, error } = useCustomMutation(trade, true);
  const {
    register,
    unregister,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm();
  const priceTypeWatch = watch('price_type') || 'price';
  const conditionTypeWatch = watch('condition_type') || 'up';
  const isShowConditionWatch = watch('is_show_condition');
  const stockIdWatch = watch('stock_id');
  const quantityWatch = watch('quantity');
  const priceWatch = watch('price');
  const customerIdWatch = watch('customer_id');

  useEffect(() => {
    priceTypeWatch === 'price' ? register('price') : unregister('price');
  }, [priceTypeWatch]);

  useEffect(() => {
    isShowConditionWatch ? register('price_condition') : unregister('price_condition');
  }, [isShowConditionWatch]);

  useEffect(() => {
    if (customerIdWatch && stockIdWatch) {
      customerAssets({
        trade_type: TYPE_PS,
        customer_id: customerIdWatch,
        stock_id: stockIdWatch,
      })
        .then((data) => {
          setDataCurrentPosition(data);
        })
        .catch(toastError);

      maxLongShort({
        customer_id: customerIdWatch,
        stock_id: stockIdWatch,
      })
        .then((data) => {
          setDataMaxLongShort(data);
        })
        .catch(toastError);
    }
  }, [customerIdWatch, stockIdWatch]);

  const onSubmit = (formData) => {
    if (Object.keys(errors).length == 0) {
      setOpenWarning((pre) => ({ ...pre, isShow: true, type: buyType, data: formData }));
    }
  };
  const submitConfirm = () => {
    const { customer_id, price, price_type, quantity, stock_id, condition_type, price_condition } =
      openWarning.data;
    const tmp = {
      stock_id,
      price,
      quantity,
      type: openWarning.type,
      trade_type: TYPE_PS,
      price_type: price_type || 'price',
      customer_id,
    };

    const postData = isShowConditionWatch
      ? {
          ...tmp,
          condition_transaction: {
            type: condition_type || 'up',
            price: price_condition,
          },
        }
      : tmp;

    mutate(postData);
  };

  useEffect(() => {
    if (isSuccess) {
      toastSuccess('Lập lệnh thành công');
      setOpenWarning({});
      closeModal();
      refetch();
    }
    if (error) {
      toastError(error);
    }
  }, [isSuccess, error]);

  return (
    <>
      <Modal
        isOpen={isOpen}
        toggle={closeModal}
        style={{
          maxWidth: '800px',
          maxHeight: '716px',
          padding: '0',
        }}
        className="text-center popup-create-trade"
      >
        <PopUpWrapper>
          <ModalBody className="p-0">
            <h3 className="py-1 text-center">LẬP LỆNH PS</h3>
            <Container className="trade-buy">
              <form onSubmit={handleSubmit(onSubmit)}>
                <Row className="py-2">
                  <Col md="12" className="trade-buuy-left">
                    <Container>
                      <Row>
                        <Col>
                          <SearchCustomer
                            labelName="Chọn khách hàng *"
                            labelCol="12"
                            inputCol="12"
                            labelClassName="font-size-14 mb-50"
                            name="customer_id"
                            register={{ ...register('customer_id', { required: true }) }}
                            errors={errors}
                            setValue={setValue}
                            tradeType={TYPE_PS}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <SearchStock
                            labelName="Mã CK *"
                            labelCol="12"
                            inputCol="12"
                            labelClassName="font-size-14 mb-50"
                            name="stock_id"
                            register={{ ...register('stock_id', { required: true }) }}
                            errors={errors}
                            setValue={setValue}
                            tradeType={TYPE_PS}
                          />
                        </Col>
                        <Col>
                          <FieldInput
                            label="Vị thế hiện tại:"
                            component={
                              <div>
                                {dataCurrentPosition ? (
                                  <div
                                    key={dataCurrentPosition?.stock_id}
                                    className="d-flex"
                                    style={{ margin: '3px 0' }}
                                  >
                                    <span style={{ marginRight: 10 }}>
                                      {dataCurrentPosition?.stock_id}:{' '}
                                    </span>
                                    <span>{renderVTHT(dataCurrentPosition?.quantity)}</span>
                                  </div>
                                ) : '---'}
                              </div>
                            }
                          />
                        </Col>
                      </Row>

                      <hr></hr>

                      <Row>
                        <Col>
                          <div>
                            <div className="d-flex">
                              <FieldInput
                                label="Giá đặt"
                                style={{ width: 200, marginRight: 10 }}
                                component={
                                  <div className="d-flex">
                                    <DropdownSelect
                                      options={[
                                        {
                                          label: 'Giá',
                                          value: 'price',
                                        },
                                        {
                                          label: 'MP',
                                          value: 'mp',
                                        },
                                        {
                                          label: 'ATO',
                                          value: 'ato',
                                        },
                                        {
                                          label: 'ATC',
                                          value: 'atc',
                                        },
                                      ]}
                                      valueSelected={priceTypeWatch || 'price'}
                                      setValueSelected={(_, v) => setValue(_, v)}
                                      name="price_type"
                                      style={{
                                        width: 80,
                                        background: '#fff',
                                        borderTopRightRadius: 0,
                                        borderBottomRightRadius: 0,
                                        textTransform: 'none',
                                        height: 42,
                                        borderStyle: 'solid',
                                        borderColor: '#E3E1E5',
                                      }}
                                      labelShow={false}
                                    />
                                    {priceTypeWatch === 'price' ? (
                                      <TextInput
                                        labelCol="12"
                                        inputCol="12"
                                        type="pricenumber"
                                        labelClassName="font-size-14 mb-50"
                                        placeholder="Giá"
                                        setValue={setValue}
                                        name="price"
                                        register={{
                                          ...register('price', {
                                            required: true,
                                            valueAsNumber: true,
                                          }),
                                        }}
                                        errors={errors}
                                        styleInput={{
                                          borderTopLeftRadius: 0,
                                          borderBottomLeftRadius: 0,
                                          borderLeft: 0,
                                        }}
                                      />
                                    ) : null}
                                  </div>
                                }
                                required
                              />

                              <FieldInput
                                label="Khối lượng"
                                style={{ width: 200, marginRight: 10 }}
                                component={
                                  <TextInput
                                    labelCol="12"
                                    inputCol="12"
                                    type="quantity"
                                    labelClassName="font-size-14 mb-50"
                                    placeholder="Khối lượng"
                                    setValue={setValue}
                                    name="quantity"
                                    register={{
                                      ...register('quantity', {
                                        required: true,
                                        valueAsNumber: true,
                                      }),
                                    }}
                                    errors={errors}
                                  />
                                }
                                required
                              />

                              <div style={{ marginLeft: 18, marginTop: -9 }}>
                                <div
                                  className="d-flex align-items-center"
                                  style={{ marginBottom: 2 }}
                                >
                                  <Checkbox
                                    id="checkbox-condition"
                                    name="is_show_condition"
                                    onChange={(ev) => {
                                      setValue('is_show_condition', ev.target.checked);
                                    }}
                                    size="small"
                                    checked={!!isShowConditionWatch}
                                  />
                                  <Label
                                    style={{ marginBottom: 0, color: '#2E2C34' }}
                                    for="checkbox-condition"
                                  >
                                    Lệnh điều kiện
                                  </Label>
                                </div>
                                {isShowConditionWatch ? (
                                  <FieldInput
                                    component={
                                      <div className="d-flex">
                                        <DropdownSelect
                                          options={[
                                            {
                                              label: 'Up',
                                              value: 'up',
                                            },
                                            {
                                              label: 'Down',
                                              value: 'down',
                                            },
                                          ]}
                                          valueSelected={conditionTypeWatch || 'up'}
                                          setValueSelected={(_, v) => setValue(_, v)}
                                          name="condition_type"
                                          style={{
                                            width: 80,
                                            background: '#fff',
                                            borderTopRightRadius: 0,
                                            borderBottomRightRadius: 0,
                                            textTransform: 'none',
                                            height: 42,
                                            borderStyle: 'solid',
                                            borderColor: '#E3E1E5',
                                          }}
                                          labelShow={false}
                                        />

                                        <TextInput
                                          labelCol="12"
                                          inputCol="12"
                                          type="pricenumber"
                                          labelClassName="font-size-14 mb-50"
                                          placeholder="Giá"
                                          setValue={setValue}
                                          name="price_condition"
                                          register={{
                                            ...register('price_condition', {
                                              required: true,
                                              valueAsNumber: true,
                                            }),
                                          }}
                                          errors={errors}
                                          styleInput={{
                                            borderTopLeftRadius: 0,
                                            borderBottomLeftRadius: 0,
                                            borderLeft: 0,
                                          }}
                                        />
                                      </div>
                                    }
                                    required
                                  />
                                ) : null}
                              </div>
                            </div>
                            <div className="d-flex" style={{ gap: 16 }}>
                              <div className="d-flex">
                                Max Long:{' '}
                                <span
                                  className="fw-600"
                                  style={{ color: '#14B13B', marginLeft: 6 }}
                                >
                                  {formatNumber(dataMaxLongShort?.max_long, 0)}
                                </span>
                              </div>
                              <div className="d-flex">
                                Max Short:{' '}
                                <span
                                  className="fw-600"
                                  style={{ color: '#ED1C24', marginLeft: 6 }}
                                >
                                  {formatNumber(dataMaxLongShort?.max_short, 0)}
                                </span>
                              </div>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </Container>
                  </Col>
                </Row>

                <div className="d-flex mb-2 justify-content-center" style={{ gap: 16 }}>
                  <ButtonSubmit
                    name="LONG"
                    wrapClassName="button-long"
                    size="md"
                    onClick={() => setBuyType('buy')}
                  />
                  <ButtonSubmit
                    style={{ width: 200 }}
                    name="SHORT"
                    wrapClassName="button-short"
                    size="md"
                    color="danger"
                    onClick={() => setBuyType('sell')}
                  />
                  <Button outline type="button" onClick={() => closeModal()}>
                    Hủy
                  </Button>
                </div>
              </form>
            </Container>
          </ModalBody>
        </PopUpWrapper>
      </Modal>

      <Modal wrapClassName="modal-wrap-setting" isOpen={openWarning.isShow} centered>
        <div className="modal-setting">
          <div style={{ textAlign: 'center' }}>
            <h3 className="uppercase mb-1">Xác nhận giao dịch</h3>
          </div>
          <div className="mt-1 text-align-center">
            Bạn có thật sự muốn đặt lệnh{' '}
            <span
              style={{
                color: openWarning.type === 'sell' ? 'rgb(252, 52, 0)' : 'rgb(20, 177, 59)',
                marginLeft: 5,
                fontWeight: '700',
              }}
            >
              {openWarning.type === 'sell' ? 'SHORT' : 'LONG'}
            </span>
          </div>
          <div className="mt-1">
            <div className="d-flex mt-1 fw-600">
              <div style={{ width: 170 }}>Mã Hợp Đồng:</div>
              <div className="ml-2 text-uppercase">{stockIdWatch}</div>
            </div>
            <div className="d-flex mt-1 fw-600">
              <div style={{ width: 170 }}>Khối lượng:</div>
              <div className="ml-2">{quantityWatch}</div>
            </div>
            <div className="d-flex mt-1 fw-600">
              <div style={{ width: 170 }}>Giá đặt:</div>
              <div className="ml-2 uppercase">
                {priceTypeWatch !== 'price' ? priceTypeWatch : formatNumber(priceWatch)}
              </div>
            </div>
          </div>
          <div className="mt-3 d-flex align-items-center justify-content-center">
            <ButtonSubmit
              onClick={() => submitConfirm()}
              name="Xác nhận"
              className="px-2"
              size="md"
              color="primary"
            />
            <ButtonSubmit
              onClick={() => setOpenWarning({})}
              outline
              name="Huỷ"
              className="px-2"
              wrapClassName="ml-1"
              size="md"
              color="primary"
              ghost
            />
          </div>
        </div>
      </Modal>
    </>
  );
}

PopUpCreatePS.propTypes = {
  isOpen: PropTypes.bool,
  closeModal: PropTypes.func,
  refetch: PropTypes.func,
};

export default PopUpCreatePS;
