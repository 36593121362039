/* eslint-disable react/jsx-wrap-multilines */
import React, { useState, useEffect, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import DataTable from 'react-data-table-component';
import styled from 'styled-components';
import PaginateCustome from '@Components/PaginateCustome';
import PaginateEffect from '@Components/PaginateEffect';
import TableEmpty from './TableEmpty';
import TableLoader from './TableLoader';

const DivWrap = styled.div`
  max-height: unset;
`;

function DataTableBasic({
  columns = [],
  data,
  pageSize,
  setPageSize,
  isLoading,
  pageSizeName,
  onSelectedRowsChange,
  selectableRows = false,
  clearSelectedRows = false,
  noPagination = false,
  onRowClicked,
  fixedHeader = false,
  fixedHeaderScrollHeight,
  isPageEffect = false,
  page,
  setPage,
  expandableRowsComponent,
  expandableRows = false,
  conditionalRowStyles,
  empty,
  className,
  onSort,
  defaultSortFieldId,
  defaultSortAsc,
  hasFixed = true,
}) {
  const [pagi, setPaginate] = useState(null);
  const [dataTable, setDataTable] = useState([]);
  const refCustom = useRef();
  const [Div, setDiv] = useState(DivWrap);

  useEffect(() => {
    if (!hasFixed) return;

    let width = 0;
    let indexLeft = -1;
    let indexRight = -1;
    let widthRight = columns.reduce((a, b) => {
      if (b.fixed === 'right') {
        a += Number((b.width || '').replace('px', '')) || 0;
      }
      return a;
    }, 0);
    let string = '';
    columns
      .map((e, i) => {
        const widthNow = Number((e.width || '').replace('px', '')) || 0;
        if (e.fixed === 'left') {
          width += widthNow;
          indexLeft = i;
          string += `
            .rdt_TableCol:nth-child(${i + 1}),
            .rdt_TableCell:nth-child(${i + 1}) {
              position: sticky;
              left: ${width - widthNow}px;
              background-color: #fff;
              z-index: 1;
              @media only screen and (max-width: 767px) and (min-width: 0px) {
                position: initial;
                z-index: 0;
              }
            }`;
        }
        if (e.fixed === 'right') {
          if (indexRight === -1) {
            indexRight = i;
          }
          widthRight -= widthNow;
          string += `\n
            .rdt_TableCol:nth-child(${i + 1}),
            .rdt_TableCell:nth-child(${i + 1}) {
              position: sticky;
              right: ${widthRight}px;
              background-color: #fff;
              z-index: 1;
              @media only screen and (max-width: 767px) and (min-width: 0px) {
                position: initial;
                z-index: 0;
              }
            }`;
        }
      })
      .filter(Boolean);
    if (string.length) {
      if (indexLeft !== -1) {
        string += `\n
          &.custom-table-scroll-fixed-left {
            .rdt_TableCol:nth-child(${indexLeft + 1}),
            .rdt_TableCell:nth-child(${indexLeft + 1}) {
              &::after {
                box-shadow: inset 10px 0 8px -8px #00000026;
                position: absolute;
                top: 0;
                right: 0;
                bottom: -1px;
                width: 30px;
                transform: translate(100%);
                transition: box-shadow 0.3s;
                content: '';
                @media only screen and (max-width: 767px) and (min-width: 0px) {
                  box-shadow: none;
                  position: initial;
                  width: 0;
                }
              }
            }
          }
        `;
      }
      if (indexRight !== -1) {
        string += `\n
        &.custom-table-scroll-fixed-right {
          .rdt_TableCol:nth-child(${indexRight + 1}),
          .rdt_TableCell:nth-child(${indexRight + 1}) {
            &::after {
              position: absolute;
              top: 0;
              bottom: -1px;
              left: 0;
              width: 30px;
              transform: translate(-100%);
              transition: box-shadow 0.3s;
              content: '';
              pointer-events: none;
              box-shadow: inset -10px 0 8px -8px #00000026;
              @media only screen and (max-width: 767px) and (min-width: 0px) {
                box-shadow: none;
                position: initial;
                width: 0;
              }
            }
          }
        }
      `;
      }
      const divNew = styled(DivWrap)`
        ${string}
      `;
      setDiv(divNew);
    }
  }, [JSON.stringify(columns)]);

  useEffect(() => {
    if (data) {
      const { paging, data: dataTable } = data;
      if (paging) {
        setPaginate({ ...paging });
        setDataTable(dataTable);
      } else {
        setDataTable(data);
        setPaginate(null);
      }
    }
  }, [data]);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    handleResize();
  }, [refCustom.current]);

  const handleResize = useCallback(() => {
    if (refCustom.current) {
      setTimeout(() => {
        const div = refCustom.current?.querySelector('div:nth-child(1)');
        if (div) {
          const columnWidth = div.querySelector('div:nth-child(1)').clientWidth;
          if (columnWidth > div.clientWidth) {
            if (!refCustom.current.className?.includes('custom-table-scroll-fixed-right')) {
              refCustom.current.className += ' custom-table-scroll-fixed-right';
            }
          } else if (refCustom.current.className?.includes('custom-table-scroll-fixed-right')) {
            refCustom.current.className = refCustom.current.className.replace(
              ' custom-table-scroll-fixed-right',
              '',
            );
          }
          div.onscroll = handleScroll;
        }
      }, 100);
    }
  }, [refCustom.current, columns]);

  const handleScroll = useCallback((e) => {
    if (e.target.scrollLeft > 0) {
      if (!refCustom.current.className?.includes('custom-table-scroll-fixed-left')) {
        refCustom.current.className += ' custom-table-scroll-fixed-left';
      }
      if (e.target.clientWidth + e.target.scrollLeft === e.target.scrollWidth) {
        if (refCustom.current.className?.includes('custom-table-scroll-fixed-right')) {
          refCustom.current.className = refCustom.current.className.replace(
            ' custom-table-scroll-fixed-right',
            '',
          );
        }
      } else if (!refCustom.current.className?.includes('custom-table-scroll-fixed-right')) {
        refCustom.current.className += ' custom-table-scroll-fixed-right';
      }
    } else if (refCustom.current.className?.includes('custom-table-scroll-fixed-left')) {
      refCustom.current.className = refCustom.current.className.replace(
        ' custom-table-scroll-fixed-left',
        '',
      );
    }
  }, []);

  return (
    <Div className={`custom-table-scroll ${className || ''}`} ref={refCustom}>
      <DataTable
        defaultSortFieldId={defaultSortFieldId}
        defaultSortAsc={defaultSortAsc}
        onSort={onSort}
        noHeader
        sortServer
        selectableRows={selectableRows}
        columns={columns}
        className="react-dataTable table-responsive"
        data={dataTable}
        onSelectedRowsChange={onSelectedRowsChange}
        noDataComponent={<TableEmpty style={empty?.style} />}
        progressPending={isLoading}
        progressComponent={<TableLoader />}
        clearSelectedRows={clearSelectedRows}
        onRowClicked={onRowClicked}
        fixedHeader={fixedHeader}
        fixedHeaderScrollHeight={fixedHeaderScrollHeight}
        expandableRowsComponent={expandableRowsComponent}
        expandableRowExpanded={(row) => row.defaultExpanded}
        expandableRows={expandableRows}
        conditionalRowStyles={conditionalRowStyles}
      />
      {pagi && !noPagination && !isPageEffect && (
        <PaginateCustome
          data={pagi}
          pageSize={pageSize}
          setPageSize={setPageSize}
          pageSizeName={pageSizeName}
        />
      )}
      {pagi && !noPagination && isPageEffect && (
        <PaginateEffect data={pagi} page={page} setPage={setPage} />
      )}
    </Div>
  );
}

DataTableBasic.propTypes = {
  columns: PropTypes.array,
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  pageSize: PropTypes.number,
  setPageSize: PropTypes.func,
  onSort: PropTypes.func,
  isLoading: PropTypes.bool,
  pageSizeName: PropTypes.string,
  onSelectedRowsChange: PropTypes.func,
  selectableRows: PropTypes.bool,
  clearSelectedRows: PropTypes.bool,
  noPagination: PropTypes.bool,
  onRowClicked: PropTypes.func,
  fixedHeader: PropTypes.bool,
  fixedHeaderScrollHeight: PropTypes.string,
  className: PropTypes.string,
  defaultSortFieldId: PropTypes.string,
  defaultSortAsc: PropTypes.bool,
  isPageEffect: PropTypes.bool,
  page: PropTypes.number,
  setPage: PropTypes.func,
  expandableRowsComponent: PropTypes.func,
  expandableRowExpanded: PropTypes.bool,
  expandableRows: PropTypes.bool,
  conditionalRowStyles: PropTypes.array,
  empty: PropTypes.object,
  sortIcon: PropTypes.any,
  hasFixed: PropTypes.any,
};

export default DataTableBasic;
