import React, { useCallback, useEffect, useState } from 'react';
import FilterComponent from '@Components/FilterComponent';
import DatePicker from '@Components/DatePicker';
import SearchCustomer from '@Components/Input/SearchCustomer';
import dayjs from 'dayjs';
import { Add } from '@mui/icons-material';
import { Button } from 'reactstrap';
import CustomeModal from '@Components/CustomeModal';
import useCustomMuation from '@Utils/useCustomMuation';
import { deleteLoanNew, editLoan, exportLoan } from '@Apis/admin/loan';
import { toastError, toastSuccess } from '@Utils/toastrHelper';
import Summary from './components/Summary';
import { CONDITIONS } from './constant';
import ConditionTable from './components/ConditionTable';
import PopUpModify from './components/PopUpModify';
import { ConditionTableWrapper } from './styles';

function WarehouseLoan() {
  const [idCondition, setIdCondition] = useState(1);
  const [isOpenPopup, setIsOpenPopup] = useState(false);
  const [itemEdit, setItemEdit] = useState(null);
  const [itemChangeStatus, setItemChangeStatus] = useState(null);
  const [isRefreshStatus, setIsRefreshStatus] = useState(null);
  const {
    mutate: mutateUpdate,
    isSuccess: isSuccessEdit,
    error: errorEdit,
  } = useCustomMuation(editLoan, true);
  const {
    mutate: mutateDelete,
    isSuccess: isSuccessDelete,
    error: errorDelete,
  } = useCustomMuation(deleteLoanNew, true);
  const [filter, setFilter] = useState({
    date_range: '',
    keyword: '',
  });

  useEffect(() => {
    if (isSuccessDelete) {
      toastSuccess('Xoá thành công');
      setIsRefreshStatus({
        status: true,
      });
      handleClosePopUp();
    }
    if (errorDelete) {
      toastError(errorDelete);
    }
  }, [isSuccessDelete, errorDelete]);

  useEffect(() => {
    if (isSuccessEdit) {
      toastSuccess('Cập nhập thành công');
      setIsRefreshStatus({
        status: true,
      });
      handleClosePopUp();
    }
    if (errorEdit) {
      toastError(errorEdit);
    }
  }, [isSuccessEdit, errorEdit]);

  const handleClosePopUp = () => {
    setItemEdit(null);
    setIsOpenPopup(false);
    setItemChangeStatus(null);
  };

  useEffect(() => {
    if (itemEdit) {
      setIsOpenPopup(true);
    } else {
      setIsOpenPopup(false);
    }
  }, [itemEdit]);

  const onConfirm = () => {
    const { itemChange, item } = itemChangeStatus;
    if (itemChange.status === 'delete') {
      mutateDelete({
        id: item.id,
      });
    } else {
      const { modalType, modalTitle, ...restItemChange } = itemChange;
      mutateUpdate({
        id: item.id,
        ...restItemChange,
      });
    }
  };

  const onSearch = useCallback((fil) => {
    setFilter((pre) => ({ ...pre, ...fil }));
  }, []);

  const onExport = useCallback(
    async (fil) => {
      return exportLoan({ ...filter, ...fil });
    },
    [filter],
  );

  return (
    <div className="content-wrapper">
      <div className="d-flex align-items-center justify-content-between header-body mb-2">
        <p className="font-size-28 font-weight-bolder">Kho cho vay</p>
        <div className="d-flex align-items-center gap-20">
          <FilterComponent
            onSearch={onSearch}
            onExport={onExport}
            filters={[
              {
                key: 'customer_id',
                label: 'Tên Khách hàng',
                width: 130,
                Component: SearchCustomer,
              },
              {
                key: 'date_range',
                label: 'Chọn ngày',
                width: 130,
                Component: DatePicker.Range,
                type: 'onChange',
                parseChange: (v) =>
                  v
                    .map?.((e) => dayjs(e).unix())
                    ?.toString()
                    ?.replace(',', '-'),
                parseValue: (v) => (v ? v?.split('-')?.map((e) => dayjs.unix(e).toDate()) : []),
              },
            ]}
          />
          <Button color="primary" onClick={() => setIsOpenPopup(true)}>
            <Add fontSize="13" color="white" />
            <span className="ml-50 font-size-14">Thêm khoản vay</span>
          </Button>
        </div>
      </div>
      <div className="mb-2">
        <Summary />
      </div>
      <ConditionTableWrapper>
        {CONDITIONS.map((item) => (
          <ConditionTable
            item={item}
            key={item.id}
            idCondition={idCondition}
            setIdCondition={setIdCondition}
            filter={filter}
            setItemEdit={setItemEdit}
            setItemChangeStatus={setItemChangeStatus}
            isRefreshStatus={isRefreshStatus}
          />
        ))}
      </ConditionTableWrapper>
      {isOpenPopup && (
        <PopUpModify
          isOpen={isOpenPopup}
          closeModal={() => handleClosePopUp()}
          setIsRefreshStatus={setIsRefreshStatus}
          item={itemEdit}
          setItemEdit={setItemEdit}
        />
      )}
      {itemChangeStatus && (
        <CustomeModal
          isOpen={itemChangeStatus !== null}
          confirm={onConfirm}
          closeModal={() => handleClosePopUp()}
          buttonName="Xác nhận"
          type={itemChangeStatus?.itemChange?.modalType}
          mess={itemChangeStatus?.itemChange?.modalTitle}
        />
      )}
    </div>
  );
}

WarehouseLoan.propTypes = {};

export default WarehouseLoan;
