import React from 'react';
import PropTypes from 'prop-types';
import Autosuggest from 'react-autosuggest';
import { FormGroup, Label, Col } from 'reactstrap';
import { SuggestWrapper } from './styles';

function SuggestSearch({
  name,
  labelName,
  labelCol,
  labelClassName,
  inputCol,
  errorDetail,
  suggestions,
  inputProps,
  getSuggestionValue,
  renderSuggestion,
  style,
}) {
  const onSuggestionsFetchRequested = (value) => {};
  const onSuggestionsClearRequested = () => {};

  return (
    <FormGroup row style={style}>
      <SuggestWrapper>
        {labelName && (
          <Label
            md={labelCol}
            for={name}
            className={`font-size-12 py-0 ${labelClassName ?? 'font-weight-bold'}`}
          >
            {labelName}
          </Label>
        )}
        <Col md={inputCol}>
          <Autosuggest
            suggestions={suggestions}
            onSuggestionsFetchRequested={onSuggestionsFetchRequested}
            onSuggestionsClearRequested={onSuggestionsClearRequested}
            getSuggestionValue={getSuggestionValue}
            renderSuggestion={renderSuggestion}
            shouldRenderSuggestions={renderSuggestion}
            inputProps={inputProps}
            className="form-control"
          />
          {errorDetail && (
            <p className="text-danger font-size-12 mb-0" style={{ paddingTop: '2px' }}>
              {errorDetail.type === 'validate' ? errorDetail.message : 'Trường bắt buộc'}
            </p>
          )}
        </Col>
      </SuggestWrapper>
    </FormGroup>
  );
}

SuggestSearch.propTypes = {
  name: PropTypes.string,
  labelName: PropTypes.string,
  labelClassName: PropTypes.string,
  labelCol: PropTypes.string,
  inputCol: PropTypes.string,
  setCustomer: PropTypes.func,
  errorDetail: PropTypes.object,
  defaultValue: PropTypes.number,
  suggestions: PropTypes.array,
  inputProps: PropTypes.object,
  style: PropTypes.object,
  getSuggestionValue: PropTypes.func,
  renderSuggestion: PropTypes.func,
};

export default SuggestSearch;
