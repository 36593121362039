/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-wrap-multilines */
import React, { useState, useRef } from 'react';
import { formatNumber } from '@Utils/helpers';
import useCustomQuery from '@Utils/useCustomQuery';
import { getItemReport } from '@Apis/admin/report';
import dayjs from 'dayjs';
import DatePicker from '@Components/DatePicker';
import PropTypes from 'prop-types';
import InputEdit from '../InputEdit';

function RenderChild({ keyValue, title, isEdit, dateApi, value = '', dataGroupTotal = [] }) {
  const [showDetail, setDetail] = useState(false);
  const refOpen = useRef();

  if (isEdit) {
    return (
      <div className="line-div d-flex justify-content-between">
        <div>{title}</div>
        <InputEdit name={keyValue} defaultValue={value} date={dateApi} />
      </div>
    );
  }
  return (
    <div>
      <div
        className="line-div"
        style={{ padding: '14px 24px 12px 8px', cursor: 'pointer' }}
        role="button"
        tabIndex={0}
        onClick={() => setDetail(!showDetail)}
      >
        <div style={{ color: '#84818A', fontWeight: 500 }} className="d-flex align-items-center">
          <img
            style={{
              padding: '0 5px',
              transform: showDetail ? 'rotate(0deg)' : 'rotate(-90deg)',
              transition: 'all 0.3s',
            }}
            alt=""
            src="/images/report/dropdown_down.svg"
          />
          <div>{title}</div>
        </div>
        <span style={{ color: '#2E2C34', fontWeight: 500 }}>{formatNumber(value)}</span>
      </div>
      <div
        style={{
          transition: 'all 0.3s',
          overflow: 'hidden',
          height: showDetail ? (refOpen.current?.clientHeight || 0) + 1 : 0,
        }}
      >
        <div ref={refOpen} style={{ borderBottom: '1px solid #E3E1E5' }}>
          {dataGroupTotal.map((group, i) => (
            <div key={group.id}>
              <div
                style={{
                  padding: '14px 24px 12px 40px',
                  background: group.purchaseAccounts ? '#E9E9E9' : !(i % 2) ? '#F5F5F5' : '#fff',
                }}
                className="d-flex align-items-center justify-content-between uppercase"
              >
                <span>{group.name || group.publicCustomer?.username}</span>
                <span>{formatNumber(group.publicCustomer ? group.amount : group.value, '---')}</span>
              </div>
              {group.purchaseAccounts?.map((account, index) => (
                <ul
                  key={account.id}
                  style={{
                    padding: '14px 24px 12px 60px',
                    margin: 0,
                    background: index % 2 ? '#F5F5F5' : '#fff',
                  }}
                >
                  <li>
                    <div className="d-flex align-items-center justify-content-between">
                      <span>{account.purchaseAccount?.name}</span>
                      <span>{formatNumber(account?.value, '---')}</span>
                    </div>
                  </li>
                </ul>
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

function BalanceSheet({ isExport }) {
  const [date, setDate] = useState(dayjs().format('YYYY-MM-DD'));
  const [dateApi, setDateApi] = useState(dayjs().format('D/M/YYYY'));
  const { data } = useCustomQuery(getItemReport, { date: dateApi }, true);

  const onChangeDate = (dateChangeArr) => {
    const dateChange = dateChangeArr[0];
    const dateFormat = dayjs(dateChange).format('DD/MM/YYYY');
    setDateApi(dateFormat);
    setDate(dateChangeArr);
  };

  return (
    <div className="report">
      <div className="d-flex align-items-center py-2">
        <p className="mr-2">Chọn ngày</p>
        <DatePicker
          onChange={onChangeDate}
          value={date}
          style={{ minWidth: 218 }}
          placeholder="Chọn ngày"
        />
      </div>
      <div style={{ fontSize: 14 }}>
        <div className="tab1">
          <div className="total">
            <div className="header">
              <span>Tổng tài sản</span>
              <span>{formatNumber(data?.total_assets)}</span>
            </div>
            <RenderChild
              keyValue="cash_amount"
              title="TIỀN MẶT"
              value={data?.cash_amount}
              isEdit={!isExport}
              dateApi={dateApi}
            />
            <RenderChild
              keyValue="total_stock_value"
              title="TỔNG GTCK HIỆN TẠI"
              value={data?.total_stock_value}
              dataGroupTotal={data?.purchaseAccountGroupData}
            />
            <RenderChild
              keyValue="receivables"
              title="PHẢI THU"
              value={data?.receivables}
              dataGroupTotal={data?.customerReceivablesReportItems}
            />
            <RenderChild
              keyValue="payable"
              title="PHẢI TRẢ"
              value={data?.payable}
              dataGroupTotal={data?.customerPayableReportItems}
            />
          </div>
          <div className="total-right">
            <div className="header-right">
              <span>Tổng nguồn vốn</span>
              <span>{formatNumber(data?.total_capital)}</span>
            </div>
            <div className="line-div">
              <span style={{ color: '#84818A', fontWeight: 500, textTransform: 'uppercase' }}>
                Vốn chủ sở hữu
              </span>
              <span style={{ color: '#2E2C34', fontWeight: 500 }}>
                {formatNumber(data?.equity)}
              </span>
            </div>
            <div className="line-div">
              <span style={{ color: '#84818A', fontWeight: 500, textTransform: 'uppercase' }}>
                Vốn vay
              </span>
              <span style={{ color: '#2E2C34', fontWeight: 500 }}>
                {formatNumber(data?.loan_capital)}
              </span>
            </div>
            {/*<div className="line-div">*/}
            {/*  <span style={{ color: '#84818A', fontWeight: 500, textTransform: 'uppercase' }}>*/}
            {/*    NAV khách hàng*/}
            {/*  </span>*/}
            {/*  <span style={{ color: '#2E2C34', fontWeight: 500 }}>*/}
            {/*    {formatNumber(data?.customer_nav)}*/}
            {/*  </span>*/}
            {/*</div>*/}
            <RenderChild
                keyValue="receivables"
                title="NAV khách hàng"
                value={data?.customer_nav}
                dataGroupTotal={data?.customerNAVReportItems}
            />
            <div className="line-div">
              <span style={{ color: '#84818A', fontWeight: 500, textTransform: 'uppercase' }}>
                vốn vay margin
              </span>
              <span style={{ color: '#2E2C34', fontWeight: 500 }}>
                {isExport ? (
                  formatNumber(data?.margin_loan)
                ) : (
                  <InputEdit name="margin_loan" defaultValue={data?.margin_loan} date={dateApi} />
                )}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

BalanceSheet.propTypes = {
  isExport: PropTypes.bool,
};

RenderChild.propTypes = {
  keyValue: PropTypes.any,
  title: PropTypes.any,
  isEdit: PropTypes.any,
  dateApi: PropTypes.any,
  value: PropTypes.any,
  dataGroupTotal: PropTypes.any,
};

export default BalanceSheet;
