import React from 'react';
import dayjs from 'dayjs';
import { formatNumber } from '@Utils/helpers';
import TooltipComment from '@Components/TooltipComment';
import { TRADE_STATUS } from '../Transaction/constants';

function renderType(type, arrayText = ['Mua', 'Bán']) {
  if (type === 'buy') {
    return (
      <div className="span-type span-buy span-color-buy">
        <span>{arrayText[0]}</span>
      </div>
    );
  }
  return (
    <div className="span-type span-sell span-color-sell">
      <span>{arrayText[1]}</span>
    </div>
  );
}

function renderPrice({ price_type, price }) {
  if (price_type !== 'price') {
    return (
      <div className="uppercase" style={{ textAlign: 'center' }}>
        <div style={{ background: 'rgba(32, 201, 172, 0.1)' }}>
          <p className="font-size-14 font-weight-bolder py-25 px-1 text-info">{price_type}</p>
        </div>
      </div>
    );
  }
  return formatNumber(price);
}

function renderDCAPrice(row) {
  const { type, dca_sell_price, dca_purchase_price } = row;
  if (type === 'sell') {
    return dca_sell_price;
  }
  if (type === 'buy') {
    return dca_purchase_price;
  }
  return '--';
}

export const columns = [
  {
    name: 'MÃ GD',
    cell: (row) => <div className="">{row.id}</div>,
    center: true,
    width: '100px',
  },
  {
    name: 'THỜI GIAN',
    selector: (row) => (
      <>
        <div style={{ textAlign: 'center' }}>{dayjs.unix(row.created_at).format('HH:mm ')}</div>
        <div style={{ textAlign: 'center' }}>{dayjs.unix(row.created_at).format(' DD/MM/YY')}</div>
      </>
    ),
    center: true,
    width: '120px',
  },
  {
    name: 'TG KHỚP',
    selector: (row) => (
      <>
        <div style={{ textAlign: 'center' }}>{dayjs.unix(row?.lastTradingItemTime?.created_at).format('HH:mm ')}</div>
        <div style={{ textAlign: 'center' }}>{dayjs.unix(row?.lastTradingItemTime?.created_at).format(' DD/MM/YY')}</div>
      </>
    ),
    center: true,
    width: '120px',
  },
  {
    name: 'CỔ PHIẾU',
    cell: (row) => <div className="">{row.stock_id}</div>,
    center: true,
  },
  {
    name: 'LOẠI  LỆNH',
    cell: (row) => renderType(row.type),
    center: true,
  },
  {
    name: 'KL ĐẶT',
    cell: (row) => (
      <div style={{ width: '100%', textAlign: 'right' }}>{formatNumber(row.quantity)}</div>
    ),
    center: true,
  },
  {
    name: 'GIÁ ĐẶT',
    cell: (row) => <div style={{ width: '100%', textAlign: 'right' }}>{renderPrice(row)}</div>,
    center: true,
  },
  {
    name: 'TRẠNG THÁI',
    cell: (row) => <div className="">{renderStatus(row.status)}</div>,
    width: '110px',
  },
  {
    name: 'KL KHỚP',
    center: true,
    cell: (row) => {
      if (row.type === 'buy') {
        return (
          <div style={{ width: '100%', textAlign: 'right' }}>
            <span className={`${row.purchase_quantity === 0 ? '' : 'text-success'} `}>
              {formatNumber(row.purchase_quantity, '--')}
            </span>
          </div>
        );
      }
      return (
        <div style={{ width: '100%', textAlign: 'right' }}>
          <span className={`${row.sell_quantity === 0 ? '' : 'text-danger'} `}>
            {formatNumber(row.sell_quantity, '--')}
          </span>
        </div>
      );
    },
  },
  {
    name: 'GIÁ KHỚP',
    cell: (row) => (
      <div style={{ width: '100%', textAlign: 'right' }}>
        {formatNumber(renderDCAPrice(row), '--')}
      </div>
    ),
    width: '140px',
    center: true,
  },
  {
    name: 'KL HUỶ',
    center: true,
    cell: (row) => {
      return (
        <div style={{ width: '100%', textAlign: 'right' }}>
          {formatNumber(
            row.quantity - (row.type === 'buy' ? row.purchase_quantity : row.sell_quantity),
          )}
        </div>
      );
    },
  },
  {
    name: 'GHI CHÚ',
    cell: (row) => <TooltipComment item={row} />,
  },
];

function renderStatus(status) {
  const item = TRADE_STATUS.find((item) => item.value === status);
  if (item) {
    const { color, label } = item;
    return <div style={{ color }}>{label}</div>;
  }
  return '';
}
