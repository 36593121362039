import axiosService from '@Lib/axiosService';
import { handleResponse } from '@Utils/helpers';

let url = '';
if (process.env.DASHBOARD_TYPE === 'admin') {
  url = `${process.env.API_ADMIN_URL}/user`;
}

export const usersSelect = async (query) => {
  const res = await axiosService.get(`${url}/list-for-select`, query);
  return handleResponse(res);
};

export const users = async (query) => {
  const res = await axiosService.get(`${url}/list`, query);
  return handleResponse(res);
};

export const userDetail = async (query) => {
  const res = await axiosService.get(`${url}/load`, query);
  return handleResponse(res);
};

export const userSave = async (query) => {
  const res = await axiosService.post(`${url}/save`, query);
  return handleResponse(res);
};

export const userSummary = async (query) => {
  const res = await axiosService.get(`${url}/summary`, query);
  return handleResponse(res);
};

export const userLock = async (query) => {
  const res = await axiosService.post(`${url}/lock`, query);
  return handleResponse(res);
};

export const commissionStats = async (query) => {
  const res = await axiosService.get(`${url}/commission-stats?`, query);
  return handleResponse(res);
};

export const commissionStatsCustomer = async (query) => {
  const res = await axiosService.get(`${url}/customer-commission-stats?`, query);
  return handleResponse(res);
};

export const listAssignProduct = async (query) => {
  const res = await axiosService.get(`${url}/assign-product-list?`, query);
  return handleResponse(res);
};

export const saveAssignProduct = async (data) => {
  const res = await axiosService.post(`${url}/save-assign-product`, data);
  return handleResponse(res);
};

export const deleteAssignProduct = async (data) => {
  const res = await axiosService.post(`${url}/delete-assign-product`, data);
  return handleResponse(res);
};
