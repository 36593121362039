import React, { useCallback, useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import Flatpickr from 'react-flatpickr';
import dayjs from 'dayjs';
import { X } from 'react-feather';
import RangePicker from './RangePicker';
import 'flatpickr/dist/flatpickr.css';
import './styles.scss';

function CustomInput({
  value,
  tintColor = '#2F69B3',
  placeholder = 'DD/MM/YYYY',
  inputRef,
  styleInput,
  placeholderColor,
  icon,
  allowNext,
  onlyYear,
}) {
  const renderValue = useCallback(() => {
    if (onlyYear && value) {
      return value;
    }
    if (value) {
      return dayjs(value).format('YYYY-MM-DD');
    }
    return placeholder || '';
  }, [value, onlyYear]);

  const renderChild = useCallback(() => {
    if (allowNext) {
      return (
        <>
          {icon || <img src="/images/icon/IconDate.svg" alt="" style={{ marginRight: 5 }} />}
          {renderValue()}
        </>
      );
    }
    return (
      <div className="d-flex justify-content-between w-100">
        {renderValue()}
        {icon || <img src="/images/icon/IconDate.svg" alt="" style={{ marginRight: 5 }} />}
      </div>
    );
  }, [allowNext, icon, value, placeholder]);

  return (
    <div ref={inputRef} className="swap-input-date">
      <div
        dir="ltr"
        className={`font-size-14 input-date${!allowNext ? ' w-100' : ''}`}
        style={{ color: value ? tintColor : placeholderColor, ...styleInput }}
      >
        {renderChild()}
      </div>
    </div>
  );
}

function DatePicker(props) {
  const {
    placeholder,
    className = '',
    defaultValue,
    value: valueProps,
    tintColor,
    styleInput,
    icon,
    onChange,
    allowClear = true,
    placeholderColor,
    style,
    allowNext = true,
    disabled,
    onlyYear = false,
  } = props;
  const initValue = defaultValue || valueProps ? new Date(valueProps || defaultValue) : '';
  const [value, setValue] = useState(onlyYear ? defaultValue || valueProps : initValue);

  const refDate = useRef();

  useEffect(() => {
    if (valueProps !== value && valueProps) {
      setValue(onlyYear ? valueProps : new Date(valueProps));
    }
  }, [valueProps, onlyYear]);

  const handlePreDate = useCallback(() => {
    if (value) {
      if (onlyYear) {
        setValue(value - 1);
        onChange?.(value - 1);
        return;
      }
      const newValue = dayjs(value).add(-1, 'day');
      setValue(newValue);
      onChange?.(newValue);
    }
  }, [value, onChange]);

  const handleNextDate = useCallback(() => {
    if (value) {
      if (onlyYear) {
        setValue(value + 1);
        onChange?.(value + 1);
        return;
      }
      const newValue = dayjs(value).add(1, 'day');
      setValue(newValue);
      onChange?.(newValue);
    }
  }, [value, onlyYear, onChange]);

  const setValueDate = useCallback(
    (v) => {
      if (onlyYear) {
        const year = dayjs(v).get('y');
        setValue(year);
        onChange?.(year);
        return;
      }
      setValue(v);
      onChange?.(v);
    },
    [onlyYear, onChange],
  );

  const handleClearData = useCallback(() => {
    setValue(undefined);
    onChange?.(undefined);
  }, [onChange]);

  const onChangeYear = useCallback(
    (_, _v, z) => {
      if (onlyYear) {
        const { currentYear } = z;
        setValue(currentYear);
        onChange?.(currentYear);
        refDate.current?.flatpickr?.close();
      }
    },
    [onlyYear, onChange, refDate],
  );

  const renderValueFlat = useCallback(() => {
    if (onlyYear && value) {
      return `01-01-${value}`;
    }
    if (value) {
      return dayjs(value).format('YYYY-MM-DD');
    }
    return '';
  }, [value, onlyYear]);

  return (
    <div
      className={`date-picker-custom ${className}${disabled ? ' date-picker-custom-disabled' : ''}`}
      style={style}
    >
      {value && allowNext ? (
        <div
          className="d-flex align-items-center"
          onClick={handleNextDate}
          style={{ cursor: 'pointer', marginLeft: allowClear ? 23 : 0 }}
          role="button"
          tabIndex="0"
        >
          <img src="/images/icon/arrow_left.svg" alt="" />
        </div>
      ) : null}
      {!disabled ? (
        <Flatpickr
          options={{
            disableMobile: true,
          }}
          ref={refDate}
          onYearChange={onChangeYear}
          value={renderValueFlat()}
          onChange={setValueDate}
          dateFormat="DD/MM/YYYY"
          render={(_, ref) => {
            return (
              <CustomInput
                allowNext={allowNext}
                placeholderColor={placeholderColor || tintColor}
                styleInput={styleInput}
                icon={icon}
                onlyYear={onlyYear}
                placeholder={placeholder}
                tintColor={tintColor}
                value={value}
                inputRef={ref}
              />
            );
          }}
        />
      ) : (
        <CustomInput
          disabled
          allowNext={allowNext}
          placeholderColor={placeholderColor || tintColor}
          styleInput={styleInput}
          icon={icon}
          placeholder={placeholder}
          tintColor={tintColor}
          value={value}
        />
      )}
      {value && allowNext ? (
        <div
          style={{ cursor: 'pointer' }}
          className="d-flex align-items-center"
          onClick={handlePreDate}
          role="button"
          tabIndex="0"
        >
          <img src="/images/icon/arrow_right.svg" alt="" />
        </div>
      ) : null}
      {value && allowClear ? (
        <div role="button" onClick={handleClearData} className="icon-date-remove-swap" tabIndex="0">
          <div className="icon-date-remove">
            <X width={13} fill="#fff" />
          </div>
        </div>
      ) : null}
    </div>
  );
}

CustomInput.propTypes = {
  value: PropTypes.any,
  inputRef: PropTypes.any,
  tintColor: PropTypes.string,
  allowNext: PropTypes.bool,
  onlyYear: PropTypes.bool,
  placeholder: PropTypes.string,
  placeholderColor: PropTypes.string,
  styleInput: PropTypes.object,
  icon: PropTypes.any,
};

DatePicker.propTypes = {
  defaultValue: PropTypes.any,
  value: PropTypes.any,
  allowClear: PropTypes.bool,
  onlyYear: PropTypes.bool,
  disabled: PropTypes.bool,
  allowNext: PropTypes.bool,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  placeholderColor: PropTypes.string,
  tintColor: PropTypes.string,
  styleInput: PropTypes.object,
  style: PropTypes.object,
  icon: PropTypes.any,
  onChange: PropTypes.func,
};

DatePicker.Range = RangePicker;

export default DatePicker;
