import React from 'react';
import { toast } from 'react-toastify';
import { XOctagon, Check } from 'react-feather';

export function toastSuccess(message) {
  toast(
    <div className="bg-white">
      <div className="toastify-header">
        <div className="title-wrapper d-flex align-items-center">
          <Check color="#28c76f" />
          <p className="toast-title font-weight-bolder text-success mb-0 ml-25">Success!</p>
        </div>
      </div>
      <div className="toastify-body mt-1">
        <span role="img" aria-label="toast-text text-info">
          {message}
        </span>
      </div>
    </div>,
  );
}

export function toastWarning(message) {
  toast(
    <div className="bg-white">
      <div className="toastify-header">
        <div className="title-wrapper d-flex align-items-center">
          <Check color="#F2994A" />
          <p className="toast-title font-weight-bolder text-success mb-0 ml-25">Warning!</p>
        </div>
      </div>
      <div className="toastify-body mt-1">
        <span role="img" aria-label="toast-text text-info">
          {message}
        </span>
      </div>
    </div>,
  );
}

const getMessageText = (error) => {
  if (typeof error === 'string') {
    return error;
  }
  const { response } = error;
  const { data } = response;
  const { message, title } = data;
  return `${title || message || 'Đã có lỗi xảy ra.'}`;
};

export function toastError(error) {
  const message = getMessageText(error);
  toast(
    <div className="bg-white">
      <div className="toastify-header">
        <div className="title-wrapper d-flex align-items-center">
          <XOctagon color="#ea5455" size={18} />
          <p className="toast-title font-weight-bolder text-danger mb-0 ml-25">Error!</p>
        </div>
      </div>
      <div className="toastify-body mt-1">
        <span role="img" aria-label="toast-text text-info">
          {message}
        </span>
      </div>
    </div>,
  );
}
