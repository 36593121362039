/* eslint-disable react/jsx-wrap-multilines */
import React, { useState } from 'react';
import { Col, Row } from 'reactstrap';
import NavigationBar from '@Components/NavigationBar';
import { TYPE_CS, TYPE_PS, TYPE_TPLUS } from '@Components/Layouts/PrivateLayout';
import Assignment from './Assignment';
import { listAssignProduct } from '@Apis/admin/user';
import useCustomQuery from '@Utils/useCustomQuery';

const tabs = [
  { id: 1, name: 'CK Cơ sở', type: TYPE_CS },
  { id: 2, name: 'CK T+', type: TYPE_TPLUS },
  { id: 3, name: 'Phái sinh', type: TYPE_PS },
];

const KSV = 'surveyor';
const GDV = 'tellers';

function EmployeeAssignment() {
  const [tab, setTab] = useState(1);

  const { data, refetch } = useCustomQuery(listAssignProduct, { product_id: tabs.filter((e) => e.id == tab)[0].type }, true);

  return (
    <div className="content-wrapper">
      <div className="d-flex align-items-center justify-content-between header-body mb-2">
        <p className="font-size-28 font-weight-bolder">Quản lý chia lệnh</p>
      </div>

      {data && (
        <div style={{ paddingTop: 10 }}>
          <NavigationBar
            className="custom-bar"
            tabs={tabs}
            activeTab={tab}
            setActiveTab={(v) => {
              setTab(v);
            }}
          />
          <div style={{ background: '#fff', padding: '10px' }}>
            <Row>
              <Col md="3">
                <Assignment
                  data={data}
                  tradeType={tabs.filter((e) => e.id == tab)[0].type}
                  role={KSV}
                  refetch={refetch}
                />
              </Col>
              <Col md="3">
                <Assignment
                  data={data}
                  tradeType={tabs.filter((e) => e.id == tab)[0].type}
                  role={GDV}
                  refetch={refetch}
                />
              </Col>
            </Row>
          </div>
        </div>
      )}
    </div>
  );
}

EmployeeAssignment.propTypes = {};

export default EmployeeAssignment;
