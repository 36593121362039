/* eslint-disable no-confusing-arrow */
import React, { useEffect, useCallback, useMemo } from 'react';
import shortid from 'shortid';
import { useDispatch, useSelector } from 'react-redux';
import PrivateLayout, { TYPE_CS, TYPE_HOME, TYPE_PS, TYPE_TPLUS } from '@Components/Layouts/PrivateLayout';
import { setProductType, setSideBar } from '@Lib/slices/uiSlice';
import HomeClient from '@Views/Client/HomeClient';
import { Navigate, useLocation, useNavigate, useRoutes } from 'react-router-dom';
import { adminConstant } from './adminConstants';
import getClientConstant from './clientConstants';
import Notification from '@Views/Client/Notification';
import Account from '@Views/Client/Account';
import PageNotFound from '@Views/PageNotFound';

export default function PrivateRouter() {
  const isAdmin = process.env.DASHBOARD_TYPE === 'admin';
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { role, productType } = useSelector((state) => ({
    sidebar: state.ui.sidebar,
    role: state.user.role,
    productType: state.ui.productType,
  }));

  const location = useLocation();
  const clientConstant = getClientConstant();

  useEffect(() => {
    if (location.pathname === '/home' && productType !== TYPE_HOME) {
      dispatch(setProductType(TYPE_HOME));
    }
  }, [location]);

  const setProductTypeFunc = useCallback((type) => {
    dispatch(setProductType(type));
    navigate(`/${type}/dashboard`);
  }, []);

  const newClientConstant = useMemo(() => {
    if (productType !== TYPE_HOME) {
      return clientConstant;
    }
    return [
      {
        path: '/',
        element: <PrivateLayout />,
        children: [
          {
            path: '/',
            element: <Navigate to="/home" />,
            hidden: true,
          },
          {
            name: 'Trang chủ',
            path: '/home',
            icon: 'dashboard',
            role: '',
            element: <HomeClient />,
          },
          {
            name: 'CK Cơ sở',
            icon: 'transaction',
            role: '',
            onClick: () => setProductTypeFunc(TYPE_CS),
          },
          {
            name: 'CK T+',
            icon: 'transaction',
            role: '',
            onClick: () => setProductTypeFunc(TYPE_TPLUS),
          },
          {
            name: 'CK Phái sinh',
            icon: 'transaction',
            role: '',
            onClick: () => setProductTypeFunc(TYPE_PS),
          },
          {
            name: 'Thông báo',
            path: '/notification',
            element: <Notification />,
            icon: 'notification',
          },
          {
            name: 'Tài khoản',
            path: '/account',
            element: <Account />,
            icon: 'user',
          },
        ],
      },
      { path: '*', element: <PageNotFound />, role: '' },
    ];
  }, [productType]);

  const privateConstant = isAdmin ? adminConstant : newClientConstant;

  const checkChild = useCallback(
    (childs, parentRole) => {
      if (!childs.length) {
        return undefined;
      }
      const newChilds = childs.filter((e) => {
        const roleE = e.role !== undefined ? e.role : parentRole;
        return (
          process.env.DASHBOARD_TYPE !== 'admin' ||
          roleE === '' ||
          role?.includes?.('*/*') ||
          role?.includes?.(roleE) ||
          role?.find?.(
            (eeee) => eeee.includes(roleE?.split?.('/')?.[0]) && eeee?.split?.('/')?.[1] === '*',
          ) ||
          role?.find?.((ro) =>
            roleE?.find?.(
              (eee) =>
                eee === ro ||
                (ro.includes(eee?.split?.('/')?.[0]) && ro?.split?.('/')?.[1] === '*'),
            ),
          )
        );
      });
      return newChilds.map((e) => {
        if (e.children) {
          return { ...e, children: checkChild(e.children, e.role || parentRole) };
        }
        return e;
      });
    },
    [role],
  );

  useEffect(() => {
    let sidebarConstant = [];

    if (isAdmin) {
      const cloneMenu = privateConstant[0];
      sidebarConstant = cloneMenu.children.map((item) => {
        if (item.children) {
          const newChildren = item.children.map((itemChild) => {
            return { ...itemChild, id: shortid.generate(4), element: undefined };
          });
          return { ...item, id: shortid.generate(4), element: undefined, children: newChildren };
        }
        return { ...item, id: shortid.generate(4), element: undefined };
      });
    } else {
      const filteredMenu = privateConstant.filter(e => !e.hidden);
      for(let i = 0; i < filteredMenu.length; i++) {
        const tmp = filteredMenu[i].children?.map((item) => {
          if (item.children) {
            const newChildren = item.children.map((itemChild) => {
              return { ...itemChild, id: shortid.generate(4), element: undefined };
            });
            return { ...item, id: shortid.generate(4), element: undefined, children: newChildren };
          }
          return { ...item, id: shortid.generate(4), element: undefined };
        });
        sidebarConstant = sidebarConstant.concat(tmp);
      }
    }

    dispatch(setSideBar(sidebarConstant));
  }, [privateConstant]);

  return useRoutes(
    role === undefined && isAdmin ? [] : checkChild(privateConstant),
  );
}
