import styled from 'styled-components';

export const DropdownWrapper = styled.div`
  .dropdown {
    height: 43px;
    border-radius: 4px;
    border: 1px solid #d8d6de;
    display: flex;
    align-items: center;
    .nav-link {
      padding: 11px 12px 9px 12px;
      padding-right: 5px;
    }
  }
`;
