/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-nested-ternary */
import React, { Component, useState } from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import { KeyboardArrowDown } from '@mui/icons-material';

function CollapTabel({
  data,
  roles = [],
  listRoles = [],
  allowEdit,
  onChange,
  handleChangeRoleUser,
  rolesUser,
}) {
  const { accessActions = [] } = data || {};
  const [view, setView] = useState(true);

  return (
    <>
      <tr className="collapse-tr-child" style={{ background: 'rgb(55, 92, 138)' }}>
        <td
          className="collapse-th"
          onClick={() => setView(!view)}
          style={{ background: 'rgb(55, 92, 138)' }}
        >
          <KeyboardArrowDown
            style={{ marginRight: 12, transform: `rotate(${view ? 0 : -90}deg)` }}
          />
          {data?.name}
        </td>
        {roles?.map((e, i) => {
          return <td key={i.toString()} style={{ width: 100 }} />;
        })}
        <td style={{ width: 100 }} />
      </tr>
      {view &&
        accessActions.map((role, i) => {
          return (
            <tr
              className="collapse-tr-child-child"
              key={i.toString()}
              style={{ background: i % 2 ? '#f8f8f8' : '#fff', borderBottom: '1px solid #ebeaed' }}
            >
              <td
                className="collapse-th"
                style={{ paddingLeft: 60, background: i % 2 ? '#f8f8f8' : '#fff' }}
              >
                {role.name}
              </td>
              {roles.map((e, i) => {
                const checked = !!listRoles[i]?.[role.id];
                return (
                  <td
                    key={i.toString()}
                    style={{ width: 100, textAlign: 'center', margin: '0 8px' }}
                  >
                    {allowEdit ? (
                      <Checkbox
                        onChange={(ev) => {
                          onChange?.(role.id, ev.target.checked, i);
                        }}
                        size="small"
                        checked={checked}
                      />
                    ) : checked ? (
                      <CheckIcon />
                    ) : null}
                  </td>
                );
              })}
              {!allowEdit && (
                <td key={i.toString()} style={{ width: 70, textAlign: 'center', margin: '0 8px' }}>
                  <Checkbox
                    onChange={(e) => handleChangeRoleUser?.(e.target.checked, role.id)}
                    checked={!!rolesUser.assign?.[role.id]}
                    size="small"
                  />
                </td>
              )}
            </tr>
          );
        })}
    </>
  );
}

class CollapseRole extends Component {
  render() {
    const {
      data,
      roles,
      allowEdit,
      listRoles = [],
      onChange,
      rolesUser = {},
      handleChangeRoleUser,
    } = this.props;
    const { accessActions = [] } = data || {};

    return (
      <CollapTabel
        data={data}
        roles={roles}
        listRoles={listRoles}
        onChange={onChange}
        handleChangeRoleUser={handleChangeRoleUser}
        rolesUser={rolesUser}
        allowEdit={allowEdit}
      >
        {/* {accessActions.map((role, i) => {
          return (
            <div className="swap-role-children" key={i.toString()}>
              <div className="role-children w-100">{role.name}</div>
              <div
                style={{ overflow: 'auto' }}
                className="d-flex align-items-center justify-content-center  w-100"
              >
                {roles.map((e, i) => {
                  const checked = !!listRoles[i]?.[role.id];
                  return (
                    <div
                      key={i.toString()}
                      style={{ width: 100, textAlign: 'center', margin: '0 8px' }}
                    >
                      {allowEdit ? (
                        <Checkbox
                          onChange={(ev) => {
                            onChange(role.id, ev.target.checked, i);
                          }}
                          size="small"
                          checked={checked}
                        />
                      ) : checked ? (
                        <CheckIcon />
                      ) : null}
                    </div>
                  );
                })}
                {!allowEdit && (
                  <div
                    key={i.toString()}
                    style={{ width: 70, textAlign: 'center', margin: '0 8px' }}
                  >
                    <Checkbox
                      onChange={(e) => handleChangeRoleUser?.(e.target.checked, role.id)}
                      checked={!!rolesUser.assign?.[role.id]}
                      size="small"
                    />
                  </div>
                )}
              </div>
            </div>
          );
        })} */}
      </CollapTabel>
    );
  }
}

CollapseRole.propTypes = {
  data: PropTypes.object,
  rolesUser: PropTypes.object,
  listRoles: PropTypes.array,
  roles: PropTypes.array,
  allowEdit: PropTypes.bool,
  onChange: PropTypes.func,
  handleChangeRoleUser: PropTypes.func,
};

CollapTabel.propTypes = {
  data: PropTypes.object,
  rolesUser: PropTypes.object,
  listRoles: PropTypes.array,
  roles: PropTypes.array,
  allowEdit: PropTypes.bool,
  onChange: PropTypes.func,
  handleChangeRoleUser: PropTypes.func,
};

export default CollapseRole;
