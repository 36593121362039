/* eslint-disable import/no-cycle */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useEffect, useCallback } from 'react';
import { Outlet, useLocation, Navigate, useNavigate } from 'react-router-dom';
import axiosService from '@Lib/axiosService';
import classnames from 'classnames';
import GlobalLoading from '@Components/GlobalLoading';
import { useDispatch, useSelector } from 'react-redux';
import { setProductType } from '@Lib/slices/uiSlice';
import Header from './Header';
import VerticalMenu from './VerticalMenu';
import './styles.scss';

export const TYPE_CS = 'base';
export const TYPE_TPLUS = 'spot';
export const TYPE_PS = 'derivative';
export const TYPE_HOME = 'Home';

export const typeConst = {
  [TYPE_CS]: {
    type: TYPE_CS,
    label: 'CK Cơ sở',
    color: '#2F69B3',
    background: 'rgba(47, 105, 179, 0.1)',
    logo: '/images/logo/logo_cs.svg',
  },
  [TYPE_TPLUS]: {
    type: TYPE_TPLUS,
    label: 'CK T+',
    color: '#5542F6',
    background: 'rgba(85, 66, 246, 0.1)',
    logo: '/images/logo/log_t_plus.svg',
  },
  [TYPE_PS]: {
    type: TYPE_PS,
    label: 'Phái sinh',
    color: '#E43925',
    background: 'rgba(237, 28, 36, 0.1)',
    logo: '/images/logo/logo_ps.svg',
  },
};

function PrivateLayout() {
  const [menuVisibility, setMenuVisibility] = useState(false);
  const [showSelectProduct, setShowSelectProduct] = useState(false);
  const productType = useSelector((state) => state.ui.productType);
  const [closeMenu, setCloseMenu] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const location = useLocation();

  const token = localStorage.getItem(process.env.TOKEN);

  if (token) {
    axiosService.setHeader('Authorization', `Bearer ${token}`);
  } else {
    return <Navigate to="/login" />;
  }

  const info = useSelector((state) => state.user.info);

  useEffect(() => {
    if (window !== undefined) {
      window.addEventListener('resize', handleWindowWidth);
    }
  }, [windowWidth]);

  const handleWindowWidth = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    if (menuVisibility && windowWidth < 1200) {
      setMenuVisibility(false);
    }
  }, [location]);

  const openSelectProductShow = useCallback(() => {
    setShowSelectProduct(true);
    window.addEventListener('scroll', closeSelectProductShow);
  }, []);

  const closeSelectProductShow = useCallback(() => {
    if (window.scrollY > 80) {
      setShowSelectProduct(false);
      window.removeEventListener('scroll', closeSelectProductShow);
    }
  }, []);

  const forceCloseSelectProductShow = useCallback(() => {
    setShowSelectProduct(false);
    window.removeEventListener('scroll', closeSelectProductShow);
  }, []);

  const onClickClose = useCallback(() => {
    if (showSelectProduct) {
      forceCloseSelectProductShow();
    }
  }, [showSelectProduct]);

  const setProductTypeFunc = useCallback(
    (type) => {
      dispatch(setProductType(type));
      forceCloseSelectProductShow();
    },
    [productType],
  );

  return info ? (
    <div
      className={classnames('wrapper vertical-layout navbar-floating footer-static', {
        'vertical-overlay-menu': windowWidth < 1200,
      })}
      style={{ position: 'relative' }}
    >
      <div role="button" tabIndex={0} onClick={onClickClose} style={{ cursor: 'default' }}>
        <div
          className={classnames('sidenav-overlay', {
            show: menuVisibility,
          })}
          onClick={() => setMenuVisibility(false)}
        />
        <VerticalMenu
          product={typeConst[productType]}
          menuVisibility={menuVisibility}
          closeMenu={closeMenu}
          setCloseMenu={(bool) => {
            if (bool && menuVisibility) {
              setMenuVisibility(false);
            } else {
              setCloseMenu(bool);
            }
          }}
        />
        <div className="app-content content" style={{ marginLeft: closeMenu ? 75 : 250 }}>
          <Header
            showSelectProduct={showSelectProduct}
            setShowSelectProduct={openSelectProductShow}
            setMenuVisibility={() => {
              if (closeMenu && !menuVisibility) {
                setCloseMenu(false);
              }
              setMenuVisibility(!menuVisibility);
            }}
          />
          <div className="content-overlay" />
          <Outlet />
        </div>
        <GlobalLoading />
      </div>
      {process.env.DASHBOARD_TYPE === 'admin' ? null : (
        <div
          className="select-product-nav d-flex align-items-center"
          style={{ height: showSelectProduct ? '' : 0, opacity: Number(showSelectProduct) }}
        >
          <div className="w-100 h-100 header-select-production" style={{ position: 'relative' }}>
            <div className="w-100 h-100 d-flex align-items-center justify-content-center">
              <ul
                className="h-100 d-flex menu-product-select-type"
                style={{ padding: 0, margin: 0, listStyle: 'none' }}
              >
                {Object.keys(typeConst).map((type) => (
                  <li
                    className="d-flex align-items-center justify-content-center h-100"
                    key={type}
                    style={{ width: 172 }}
                    onClick={() => {
                      setProductTypeFunc(type);
                      navigate(`/${type}/dashboard`);
                    }}
                  >
                    <img src={typeConst[type].logo} alt="" style={{ width: 36, height: 36 }} />
                    <span
                      style={{
                        marginLeft: 12,
                        fontWeight: 600,
                        color: typeConst[type].color,
                        fontSize: 24,
                      }}
                    >
                      {typeConst[type].label}
                    </span>
                  </li>
                ))}
              </ul>
            </div>
            <div
              role="button"
              tabIndex={0}
              onClick={() => {
                setProductTypeFunc(TYPE_HOME);
                navigate('/home');
              }}
              className="align-items-center justify-content-center button-home-select-product"
            >
              <img src="/images/logo/home_icon_select.svg" alt="" />
              <span style={{ marginLeft: 5, marginTop: 5 }}>Trang chủ</span>
            </div>
            <div
              style={{
                width: 70,
                height: '100%',
                cursor: 'pointer',
                position: 'absolute',
                top: 0,
                right: 0,
              }}
              role="button"
              tabIndex={0}
              onClick={() => setShowSelectProduct(false)}
              className="d-flex align-items-center justify-content-center button-close-select-product"
            >
              <img src="/images/logo/icon_remove.svg" alt="" />
            </div>
          </div>
        </div>
      )}
    </div>
  ) : (
    ''
  );
}

PrivateLayout.propTypes = {};

export default PrivateLayout;
