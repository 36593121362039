import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import SelectInput from '@Components/Input/SelectInput';
import TextInput from '@Components/Input/TextInput';
import { useSelector } from 'react-redux';
import { formatNumber } from '@Utils/helpers';

function TransactionItem({
  item,
  register,
  errors,
  setValue,
  optionsWarehouse,
  optionsTeller,
  watch,
  quantityDefault,
  setWareHouse,
  can_sell,
}) {
  const [defaultAccount, setDetaultAccount] = useState(0);
  const watchPurchaseAccount = watch(`${item.id}.purchase_account_id`);
  const watchQuantity = watch(`${item.id}.quantity`);

  console.log('watchQuantity', watchQuantity);

  const warehouse = useSelector((state) => state.transaction.warehouse);

  useEffect(() => {
    setWareHouse?.(watchPurchaseAccount, watchQuantity);
  }, [watchPurchaseAccount, watchQuantity]);

  useEffect(() => {
    const watchPurchaseAccountId = parseInt(watchPurchaseAccount);
    if (watchPurchaseAccountId) {
      const warehouseDetail = warehouse.find((item) => item.id === watchPurchaseAccountId);
      setDetaultAccount(warehouseDetail?.publicUser?.id);
    } else {
      setDetaultAccount('0');
    }
  }, [watchPurchaseAccount]);

  return (
    <Row>
      <Col>
        <SelectInput
          labelCol="12"
          inputCol="12"
          options={optionsWarehouse}
          errors={errors}
          name={`${item.id}.purchase_account_id`}
          register={{ ...register(`${item.id}.purchase_account_id`) }}
          labelName="Chọn kho DT"
          labelClassName="font-size-14"
          setValue={setValue}
          defaultValue=""
        />
        {watchPurchaseAccount ? (
          <div style={{ fontSize: 14, marginTop: -12 }}>
            KL khả dụng: {formatNumber(can_sell, '--')}
          </div>
        ) : null}
      </Col>
      <Col>
        <SelectInput
          labelCol="12"
          inputCol="12"
          options={optionsTeller}
          errors={errors}
          name={`${item.id}.tellers_user_id`}
          register={{ ...register(`${item.id}.tellers_user_id`, { required: true }) }}
          labelClassName="font-size-14 mb-50"
          labelName="GDV *"
          placeholder="GDV"
          setValue={setValue}
          defaultValue={defaultAccount}
        />
      </Col>
      <Col>
        <TextInput
          labelCol="12"
          inputCol="12"
          type="price"
          labelClassName="font-size-14 mb-50"
          labelName="Khối lượng"
          placeholder="Khối lượng"
          setValue={setValue}
          name={`${item.id}.quantity`}
          register={{ ...register(`${item.id}.quantity`, { required: true }) }}
          errors={errors}
          defaultValue={quantityDefault}
        />
      </Col>
    </Row>
  );
}

TransactionItem.propTypes = {
  item: PropTypes.object,
  register: PropTypes.func,
  errors: PropTypes.object,
  setValue: PropTypes.func,
  optionsWarehouse: PropTypes.array,
  optionsTeller: PropTypes.array,
  watch: PropTypes.func,
  dataWarehouse: PropTypes.array,
  setWareHouse: PropTypes.any,
  quantityDefault: PropTypes.number,
  can_sell: PropTypes.number,
};

export default TransactionItem;
