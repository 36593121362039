import styled from 'styled-components';

export const PopUpWrapper = styled.div`
  text-align: left;
  .nav-tabs {
    margin: auto;
    .nav-item:first-child {
      .nav-link.active {
        color: #14b13b;
      }
      .nav-link:after {
        background: #14b13b !important;
      }
    }
    .nav-item:last-child {
      .nav-link.active {
        color: #fc3400;
      }
      .nav-link:after {
        background: #fc3400 !important;
      }
    }
  }
  .modal-body {
  }

  .trade-buy {
  }
  .trade-sell {
    border-top: 1px solid #fc3400;
    .stock-trade {
      min-height: 492px;
    }
  }
  .trade-sell-left {
    border-right: 1px dashed #fc3400;
  }
  .bg-buy {
    background: rgba(20, 177, 59, 0.1);
  }
  .bg-sell {
    background: #fff6f6;
  }
  .trade-buuy-right {
    max-height: 250px;
    overflow-y: auto;
  }
  input {
    padding: 0.638rem 1.5rem;
  }
`;
