import React from 'react';
import { formatVND } from '@Utils/helpers';
import { Row, Col, Container } from 'reactstrap';
import PropTypes from 'prop-types';

function Summary({ data }) {
  return data ? (
    <Container className="data-sumary my-2 bg-white">
      <Row>
        <Col className="p-1">
          <div className="d-flex align-items-center mb-1">
            <img src="/images/employee/total-sale.svg" alt="Tổng Sale" className="mr-50" />
            <p className="font-weight-bolder">Tổng Sale</p>
          </div>
          <p className="font-size-28 font-weigth-bolder">{formatVND(data.total_sales_amount)}</p>
        </Col>
        <Col className="p-1">
          <div className="d-flex align-items-center mb-1">
            <img src="/images/employee/total-support.svg" alt="Tổng KSV" className="mr-50" />
            <p className="font-weight-bolder">Tổng KSV</p>
          </div>
          <p className="font-size-28 font-weigth-bolder">{formatVND(data.total_surveyor_amount)}</p>
        </Col>
        <Col className="p-1">
          <div className="d-flex align-items-center mb-1">
            <img src="/images/employee/total-tellers.svg" alt="Tổng GDV" className="mr-50" />
            <p className="font-weight-bolder">Tổng GDV</p>
          </div>
          <p className="font-size-28 font-weigth-bolder">{formatVND(data.total_tellers_amount)}</p>
        </Col>
        <Col className="p-1">
          <div className="d-flex align-items-center mb-1">
            <img src="/images/employee/total-user.svg" alt="Số nhân viên" className="mr-50" />
            <p className="font-weight-bolder">Số nhân viên</p>
          </div>
          <p className="font-size-28 font-weigth-bolder">{formatVND(data.count)}</p>
        </Col>
      </Row>
    </Container>
  ) : (
    ''
  );
}

Summary.propTypes = {
  data: PropTypes.object,
};

export default Summary;
