import { formatNumber, formatVND } from '@Utils/helpers';
import { renderVTHT } from '@Views/Client/TransactionTPlus';
import React from 'react';
import { Button, NavLink } from 'reactstrap';
import EditIcon from '@mui/icons-material/Edit';

export const columnsCustomerBase = ({ navigate, setUserChangePackage, listCustomerPackages }) => {
  return [
    {
      name: 'Id',
      cell: (row) => <div className="">{row.code}</div>,
      width: '100px',
      fixed: 'left',
      sortable: true,
      center: true,
      sortField: 'code',
    },
    {
      name: 'Mã Sale',
      cell: (row) => {
        return <div className="">{row.publicSaleUser?.id}</div>;
      },
      width: '80px',
      fixed: 'left',
      center: true,
    },
    {
      name: 'USERNAME',
      cell: (row) => (
        <div className=" font-weight-bolder text-uppercase">{row.username}</div>
      ),
      width: '200px',
      fixed: 'left',
    },
    {
      name: 'TỔNG GT CK',
      cell: (row) => (
        <div style={{ width: '100%', textAlign: 'right' }}>{formatVND(row.total_stock_value)}</div>
      ),
      width: '150px',
      center: true,
      // fixed: 'left',
      sortField: 'total_stock_value',
      sortable: true,
    },
    {
      name: 'NAV',
      cell: (row) => (
        <div style={{ width: '100%', textAlign: 'right' }}>
          {formatNumber(row.current_nav, '--')}
        </div>
      ),
      width: '150px',
      // fixed: 'left',
      center: true,
      sortField: 'current_nav',
      sortable: true,
    },
    {
      name: 'THIẾU/DƯ CỌC',
      center: true,
      cell: (row) => {
        const { withdraw_available } = row;
        return (
          <div
            style={{
              color: withdraw_available < 0 ? '#ED1C24' : '#14B13B',
              fontWeight: 500,
              width: '100%',
              textAlign: 'right',
            }}
          >
            {(withdraw_available > 0 ? '+' : '') + formatVND(withdraw_available)}
          </div>
        );
      },
      width: '160px',
      // fixed: 'left',
      sortField: 'withdraw_available',
      sortable: true,
    },
    {
      name: 'TL Tài khoản hiện tại',
      center: true,
      cell: (row) => {
        const { current_reality_margin_percent, margin_rate, call_rate } = row;
        let color = '';
        if (current_reality_margin_percent < call_rate) {
          color = '#FC3400';
        } else if (current_reality_margin_percent < margin_rate) {
          color = '#EC602E';
        }
        return (
            <div style={{ color, fontWeight: 500 }}>
              {(current_reality_margin_percent > 0 ? '+' : '') + formatNumber(current_reality_margin_percent, '--')}
              {current_reality_margin_percent ? '%' : ''}
            </div>
        );
      },
      width: '180px',
      // fixed: 'left',
      sortField: 'current_reality_margin_percent',
      sortable: true,
    },
    {
      name: 'TỶ LỆ ĐẢM BẢO',
      cell: (row) => <div className="">{row.current_safety_rate}%</div>,
      width: '120px',
      center: true,
      sortField: 'current_safety_rate',
      sortable: true,
    },
    {
      name: 'RTT',
      cell: (row) => <div className="">{formatNumber(row.total_stock_value > 0 ? (row.call_nav_on / row.total_stock_value) * 100 : 0)}%</div>,
      width: '120px',
      center: true,
      sortable: true,
    },
    {
      name: 'LÃI SUẤT/NGÀY',
      cell: (row) => <div className="">{row.interest_rate}%</div>,
      width: '140px',
      center: true,
      sortField: 'interest_rate',
      sortable: true,
    },
    {
      name: 'PHÍ MUA',
      cell: (row) => <div className="">{row.buy_rate}%</div>,
      width: '110px',
      center: true,
      sortField: 'buy_rate',
      sortable: true,
    },
    {
      name: 'PHÍ BÁN',
      cell: (row) => <div className="">{row.sell_rate}%</div>,
      width: '110px',
      center: true,
      sortField: 'sell_rate',
      sortable: true,
    },
    {
      name: 'Gói SP',
      cell: (row) => {
        const dt = listCustomerPackages.filter(e => e.value === row.member_package_id)[0] ?? {};
        return <div className="d-flex w-100 align-items-center justify-content-end">
          <span>{dt.label || '--'}</span>
          <Button onClick={() => {
              setUserChangePackage({
                id: row.id,
                name: row.username,
                member_package_id: row.member_package_id || 0,
              });
            }} color="primary" style={{ padding: 5, marginLeft: 10, maxHeight:30 }} size="sm" outline >
            <EditIcon fontSize="13px" />
          </Button>
        </div>;
      },
      width: '160px',
      center: true,
    },
    {
      name: '',
      cell: (row) => (
        <NavLink
          className="d-flex align-items-center"
          onClick={() => navigate(`/customer/detail/${row.primary_customer_id || row.id}`)}
        >
          <span className="text-danger font-weight-bolder mr-50">Chi tiết</span>
          <img src="/images/icon/arrow-box-red.svg" alt="view-detail" />
        </NavLink>
      ),
      width: '140px',
      fixed: 'right',
    },
  ];
};

export const columnsCustomerTPlus = ({ navigate, setUserChangePackage, listCustomerPackages }) => {
  return [
    {
      name: 'Id',
      cell: (row) => <div className="">{row.code}</div>,
      width: '100px',
      // fixed: 'left',
      sortable: true,
      center: true,
      sortField: 'code',
    },
    {
      name: 'Mã Sale',
      cell: (row) => {
        return <div className="">{row.publicSaleUser?.id}</div>;
      },
      width: '80px',
      // fixed: 'left',
      center: true,
    },
    {
      name: 'USERNAME',
      cell: (row) => (
        <div className="font-weight-bolder text-uppercase">{row.username}</div>
      ),
      width: '200px',
      // fixed: 'left',
    },
    {
      name: 'TỔNG GT CK',
      cell: (row) => (
        <div style={{ width: '100%', textAlign: 'right' }}>{formatVND(row.total_stock_value)}</div>
      ),
      width: '150px',
      center: true,
      // fixed: 'left',
      sortField: 'total_stock_value',
      sortable: true,
    },
    {
      name: 'NAV',
      cell: (row) => (
        <div style={{ width: '100%', textAlign: 'right' }}>
          {formatNumber(row.current_nav, '--')}
        </div>
      ),
      width: '150px',
      // fixed: 'left',
      center: true,
      sortField: 'current_nav',
      sortable: true,
    },
    {
      name: 'THIẾU/DƯ CỌC',
      center: true,
      cell: (row) => {
        const { withdraw_available } = row;
        return (
          <div
            style={{
              color: withdraw_available < 0 ? '#ED1C24' : '#14B13B',
              fontWeight: 500,
              width: '100%',
              textAlign: 'right',
            }}
          >
            {(withdraw_available > 0 ? '+' : '') + formatVND(withdraw_available)}
          </div>
        );
      },
      width: '160px',
      fixed: 'left',
      sortField: 'withdraw_available',
      sortable: true,
    },
    {
      name: 'Gói SP',
      cell: (row) => {
        const dt = listCustomerPackages.filter(e => e.value === row.member_package_id)[0] ?? {};
        return <div className="d-flex w-100 align-items-center justify-content-end">
          <span>{dt.label || '--'}</span>
          <Button onClick={() => {
              setUserChangePackage({
                id: row.id,
                name: row.username,
                member_package_id: row.member_package_id || 0,
              });
            }} color="primary" style={{ padding: 5, marginLeft: 10, maxHeight:30 }} size="sm" outline >
            <EditIcon fontSize="13px" />
          </Button>
        </div>;
      },
      width: '160px',
      center: true,
    },
    {
      name: '',
      cell: (row) => (
        <NavLink
          className="d-flex align-items-center"
          onClick={() => navigate(`/customer/detail/${row.primary_customer_id || row.id}`)}
        >
          <span className="text-danger font-weight-bolder mr-50">Chi tiết</span>
          <img src="/images/icon/arrow-box-red.svg" alt="view-detail" />
        </NavLink>
      ),
      right: true,
    },
  ];
};

export const columnsAll = ({ navigate, setUserChangePackage, listCustomerPackages }) => {
  return [
    {
      name: 'Id',
      cell: (row) => <div className="">{row.code}</div>,
      width: '100px',
      // fixed: 'left',
      sortable: true,
      center: true,
      sortField: 'code',
    },
    {
      name: 'USERNAME',
      cell: (row) => <div className="font-weight-bolder text-uppercase">{row.username}</div>,
      width: '200px',
      // fixed: 'left',
    },
    {
      name: 'NAV',
      cell: (row) => (
        <div style={{ width: '100%', textAlign: 'right' }}>
          {formatNumber(row.current_nav, '--')}
        </div>
      ),
      width: '150px',
      // fixed: 'left',
      center: true,
      sortField: 'current_nav',
      sortable: true,
    },
    {
      name: 'THIẾU/DƯ CỌC',
      center: true,
      cell: (row) => {
        const { withdraw_available } = row;
        return (
          <div
            style={{
              color: withdraw_available < 0 ? '#ED1C24' : '#14B13B',
              fontWeight: 500,
              width: '100%',
              textAlign: 'right',
            }}
          >
            {(withdraw_available > 0 ? '+' : '') + formatVND(withdraw_available)}
          </div>
        );
      },
      width: '160px',
      // fixed: 'left',
      sortField: 'withdraw_available',
      sortable: true,
    },
    {
      name: 'TLKQ hiện tại',
      cell: (row) => (
        <div className="">
          {row.total_stock_value ? (row.current_nav * 100 / row.total_stock_value).toFixed(2) + '%' : ''}
        </div>
      ),
      width: '130px',
      center: true,
    },
    {
      name: '',
      cell: (row) => (
        <NavLink
          className="d-flex align-items-center"
          onClick={() => navigate(`/customer/detail/${row.primary_customer_id || row.id}`)}
        >
          <span className="text-danger font-weight-bolder mr-50">Chi tiết</span>
          <img src="/images/icon/arrow-box-red.svg" alt="view-detail" />
        </NavLink>
      ),
      width: '140px',
      right: true,
    },
  ];
};

export const columnsCustomerPS = ({ navigate, setUserChangePackage, listCustomerPackages }) => {
  return [
    {
      name: 'Id',
      cell: (row) => <div className="">{row.code}</div>,
      width: '100px',
      sortable: true,
      center: true,
      sortField: 'code',
    },
    {
      name: 'USERNAME',
      cell: (row) => (
        <div className="font-weight-bolder text-uppercase">{row.username}</div>
      ),
      width: '200px',
    },
    {
      name: 'NAV',
      cell: (row) => (
        <div style={{ width: '100%', textAlign: 'right' }}>
          {formatNumber(row.current_nav, '--')}
        </div>
      ),
      width: '150px',
      center: true,
      sortField: 'current_nav',
      sortable: true,
    },
    {
      name: 'SL vị thế',
      cell: (row) => (
        <div>
          {row.activeCustomerStocks?.map((e) => (
            <div key={e.stock_id} className="d-flex" style={{ margin: '3px 0' }}>
              <span style={{ marginRight: 10 }}>{e.stock_id}: </span>
              <span>{renderVTHT(e.quantity)}</span>
            </div>
          ))}
        </div>
      ),
      center: true,
      width: 'auto',
    },
    {
      name: 'THIẾU/DƯ CỌC',
      center: true,
      cell: (row) => {
        const { withdraw_available } = row;
        return (
          <div
            style={{
              color: withdraw_available < 0 ? '#ED1C24' : '#14B13B',
              fontWeight: 500,
              width: '100%',
              textAlign: 'right',
            }}
          >
            {(withdraw_available > 0 ? '+' : '') + formatVND(withdraw_available)}
          </div>
        );
      },
      width: '160px',
      fixed: 'left',
      sortField: 'withdraw_available',
      sortable: true,
    },
    {
      name: 'TỶ LỆ KÝ QUỸ',
      cell: (row) => <div className="">{row.current_margin_percent}%</div>,
      width: '130px',
      center: true,
      sortField: 'margin_rate',
      sortable: true,
    },
    {
      name: 'Gói SP',
      cell: (row) => {
        const dt = listCustomerPackages.filter(e => e.value === row.member_package_id)[0] ?? {};
        return <div className="d-flex w-100 align-items-center justify-content-end">
          <span>{dt.label || '--'}</span>
          <Button onClick={() => {
              setUserChangePackage({
                id: row.id,
                name: row.username,
                member_package_id: row.member_package_id || 0,
              });
            }} color="primary" style={{ padding: 5, marginLeft: 10, maxHeight:30 }} size="sm" outline >
            <EditIcon fontSize="13px" />
          </Button>
        </div>;
      },
      width: '180px',
      center: true,
    },
    {
      name: '',
      cell: (row) => (
        <NavLink
          className="d-flex align-items-center"
          onClick={() => navigate(`/customer/detail/${row.primary_customer_id || row.id}`)}
        >
          <span className="text-danger font-weight-bolder mr-50">Chi tiết</span>
          <img src="/images/icon/arrow-box-red.svg" alt="view-detail" />
        </NavLink>
      ),
      right: true,
    },
  ];
};
