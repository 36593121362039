import { formatNumber } from '@Utils/helpers';
import React from 'react';
import { NavLink } from 'reactstrap';
import { RadioButtonChecked, RadioButtonUnchecked } from '@mui/icons-material';

const calcProfit = (row) => {
  // eslint-disable-next-line no-unsafe-optional-chaining
  const pnl_wait = row.stock?.price * row.quantity - row.lastTradingItem?.current_cost_price * row.quantity;
  return (
    <div style={{ color: pnl_wait >= 0 ? '#14B13B' : '#FC3400' }}>
      {pnl_wait >= 0 ? '+ ' : ''}
      {formatNumber(pnl_wait, '--')}
    </div>
  );
};

export const columns = ({ itemSelected, toggleItem }) => {
  return [
    {
      name: 'Mã CK',
      cell: (row) => <div className="">{row.stock_id}</div>,
      width: '70px',
      center: true,
    },
    {
      name: 'KL',
      cell: (row) => (
        <div style={{ width: '100%', textAlign: 'right' }}>{formatNumber(row.quantity)}</div>
      ),
      width: '80px',
    },
    {
      name: 'KL KD',
      cell: (row) => (
        <div style={{ width: '100%', textAlign: 'right' }}>
          {formatNumber(row.available_quantity - (row?.sellingQuantity?.selling_quantity || 0))}
        </div>
      ),
      width: '100px',
      center: true,
    },
    {
      name: 'Giá mua',
      cell: (row) => (
        <div style={{ width: '100%', textAlign: 'right' }}>
          {' '}
          {formatNumber(row?.lastTradingItem?.current_cost_price || 0, '--')}
        </div>
      ),
    },
    {
      name: 'Giá hiện tại',
      cell: (row) => (
        <div style={{ width: '100%', textAlign: 'right' }}>{formatNumber(row?.stock?.price)}</div>
      ),
      width: '110px',
      center: true,
    },
    {
      name: 'Lãi/Lỗ',
      cell: (row) => <div>{calcProfit(row)}</div>,
      width: '130px',
      center: true,
    },
    {
      name: 'Bán',
      cell: (row) => (
        <NavLink onClick={() => toggleItem(row)}>
          {itemSelected?.id === row.id ? (
            <RadioButtonChecked color="error" />
          ) : (
            <RadioButtonUnchecked />
          )}
        </NavLink>
      ),
    },
  ];
};
