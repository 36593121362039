/* eslint-disable no-confusing-arrow */
import { formatVND } from '@Utils/helpers';
import React from 'react';
import { NavLink } from 'reactstrap';

const renderRow = (row) => {
  if (row.status === 'active') {
    return <span style={{ color: 'rgb(20, 177, 59)' }}>Kích hoạt</span>;
  }
  return <span style={{ color: '#ea5455' }}>Dừng kích hoạt</span>;
};

export const columns = ({ navigate }) => {
  return [
    {
      name: 'Tên Kho',
      cell: (row) => <div className="white-space-norwrap font-weight-bolder">{row.name}</div>,
      width: '200px',
      fixed: 'left',
    },
    {
      name: 'GT CK',
      cell: (row) => <div className="w-100 text-align-end">{formatVND(row.stock_value)}</div>,
      width: '170px',
      center: true,
    },
    {
      name: 'NAV',
      cell: (row) => <div className="w-100 text-align-end">{formatVND(row.total_nav)}</div>,
      width: '170px',
      center: true,
    },
    {
      name: 'Nợ Margin',
      cell: (row) => <div className="w-100 text-align-end">{formatVND(row.margin_debt)}</div>,
      width: '170px',
      center: true,
    },
    {
      name: 'Sức mua tối đa',
      cell: (row) => <div className="w-100 text-align-end">{formatVND(row.buy_ability)}</div>,
      width: '170px',
      center: true,
    },
    {
      name: 'Cổ tức tiền',
      cell: (row) => <div className="w-100 text-align-end">{formatVND(row.dividend_amount)}</div>,
      width: '170px',
      center: true,
    },
    {
      name: 'Trạng thái',
      cell: (row) => (row.id ? renderRow(row) : ''),
      width: '140px',
      fixed: 'right',
      center: true,
    },
    {
      name: '',
      width: '170px',
      fixed: 'right',
      cell: (row) =>
        row.id ? (
          <NavLink
            className="d-flex align-items-center"
            onClick={() => navigate(`/warehouse/account/detail/${row.id}`)}
          >
            <span className="text-danger font-weight-bolder mr-50">Chi tiết</span>
            <img src="/images/icon/arrow-box-red.svg" alt="view-detail" />
          </NavLink>
        ) : (
          ''
        ),
    },
  ];
};
