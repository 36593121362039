import React, { useState, useEffect, useCallback } from 'react';
import InputSearch from '@Components/InputSearch';
import { Button } from 'reactstrap';
import FlatPickrDateRange from '@Components/FlatPickrDateRange';
import NavigationBar from '@Components/NavigationBar';
import DataTableBasic from '@Components/DataTableBasic';
import useCustomQuery from '@Utils/useCustomQuery';
import { toastError, toastSuccess } from '@Utils/toastrHelper';
import { paymentIndex, paymentReject, approve, paymentExport } from '@Apis/admin/paymentRequest';
import useCustomMutation from '@Utils/useCustomMuation';
import CustomeModal from '@Components/CustomeModal';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { SwapHorizontalCircle } from '@mui/icons-material';
import FilterComponent from '@Components/FilterComponent';
import SearchCustomer from '@Components/Input/SearchCustomer';
import SearchEmployer from '@Components/Input/SearchEmployer';
import { TABS } from './constants';
import { columns } from './components/Columns';
import PopupCreate from './components/PopupCreate';
import PopupDetail from './components/PopupDetail';

function PaymentRequest() {
  const [isOpenPopUpCreate, setIsOpenPopupCreate] = useState(false);
  const [itemDetail, setItemDetal] = useState(null);
  const [searchParams] = useSearchParams();
  const page = searchParams.get('page') || 1;
  const [activeTabId, setActiveTabId] = useState(0);
  const [filter, setFilter] = useState({
    date_range: null,
    customer_id: '',
    type: '',
    sort_by: 'created_at',
    order: 'DESC',
  });
  useEffect(() => {
    if (page) {
      setFilter({ ...filter, page });
    }
  }, [page]);
  const { data, refetch } = useCustomQuery(paymentIndex, filter, true);
  const { mutate, isSuccess, error } = useCustomMutation(approve, true);
  const {
    mutate: muatateReject,
    isSuccess: isSuccessReject,
    error: errorReject,
  } = useCustomMutation(paymentReject, true);

  const navigate = useNavigate();

  const [itemUpdate, setItemUpdate] = useState(null);

  useEffect(() => {
    const activeTab = TABS.find((item) => item.id === activeTabId);
    if (activeTab) {
      const { value } = activeTab;
      filter.type = value;
      setFilter({ ...filter, page: 1 });
    }
  }, [activeTabId]);

  const filterOnChange = (name, value) => {
    setFilter((prevValues) => {
      return {
        ...prevValues,
        [name]: value,
      };
    });
  };

  const changeStatus = (item, is_complete) => {
    item.status_change = is_complete ? 'completed' : 'rejected';
    setItemUpdate(item);
  };
  const onConfirm = () => {
    const { id, status_change } = itemUpdate;
    if (status_change === 'completed') {
      mutate({
        id,
        is_completed: true,
      });
    } else {
      muatateReject({
        id,
      });
    }
  };

  const onRowClicked = (row) => {
    setItemDetal(row);
  };

  useEffect(() => {
    if (isSuccess) {
      toastSuccess('Cập nhập yêu cầu thành công');
      setItemUpdate(null);
      refetch();
    }
    if (error) {
      toastError(error);
    }
  }, [isSuccess, error]);

  useEffect(() => {
    if (isSuccessReject) {
      toastSuccess('Huỷ yêu cầu thành công');
      setItemUpdate(null);
      refetch();
    }
    if (errorReject) {
      toastError(errorReject);
    }
  }, [isSuccessReject, errorReject]);

  const setOrderBy = useCallback((column, type = '') => {
    setFilter((prevValues) => {
      return {
        ...prevValues,
        sort_by: column.sortField,
        order: type.toUpperCase(),
      };
    });
  }, []);

  const onSearch = useCallback((fil) => {
    setFilter((pre) => ({ ...pre, ...fil }));
  }, []);

  const onExport = useCallback(
    async (fil) => {
      return paymentExport({ ...filter, ...fil });
    },
    [filter],
  );

  return (
    <div className="content-wrapper">
      <div className="d-flex align-items-center justify-content-between header-body mb-2">
        <p className="font-size-28 font-weight-bolder">Yêu cầu nộp/rút</p>
        <div className="d-flex align-items-center gap-20">
          <FilterComponent
            onSearch={onSearch}
            onExport={onExport}
            filters={[
              {
                key: 'id',
                label: 'Mã GD',
                width: 130,
              },
              {
                key: 'type',
                label: 'Loại',
                width: 130,
                options: [
                  {
                    label: 'Nộp tiền',
                    value: 'deposit',
                  },
                  {
                    label: 'Rút tiền',
                    value: 'withdraw',
                  },
                ],
              },
              {
                key: 'customer_id',
                label: 'Tên Khách hàng',
                width: 130,
                Component: SearchCustomer,
              },
              {
                key: 'user_id',
                label: 'Người duyệt',
                Component: SearchEmployer,
                width: 130,
              },
              {
                key: 'note',
                label: 'Ghi chú',
                width: 130,
                type: 'textarea',
              },
            ]}
          />
          <InputSearch
            placeholder="Nhập ID, tên KH"
            searchKey={filter.customer_id}
            name="keyword"
            setSearchKey={filterOnChange}
            width="240px"
          />
          <FlatPickrDateRange
            isOpen={false}
            dateRange={filter.date_range}
            setDateRange={filterOnChange}
            className="filter-date-range"
            labelDefault="Chọn ngày"
            name="date_range"
          />
          <Button color="primary" onClick={() => setIsOpenPopupCreate(true)}>
            <SwapHorizontalCircle size={13} color="white" />
            <span className="font-size-14 ml-50">Tạo lệnh nộp/rút</span>
          </Button>
        </div>
      </div>
      <NavigationBar
        tabs={TABS}
        activeTab={activeTabId}
        setActiveTab={(id) => {
          navigate('/payment-request');
          setActiveTabId(id);
        }}
      />
      <DataTableBasic
        page={Number(page)}
        columns={columns({ changeStatus, navigate })}
        data={data}
        onRowClicked={onRowClicked}
        onSort={setOrderBy}
        fixedHeader
      />

      <CustomeModal
        isOpen={itemUpdate !== null}
        confirm={onConfirm}
        closeModal={() => setItemUpdate(null)}
        buttonName="Xác nhận"
        type={itemUpdate?.status_change === 'completed' ? 'success' : 'delete'}
        mess={
          itemUpdate?.status_change === 'completed'
            ? 'Bạn chắc chắn yêu cầu này đã thành công ?'
            : 'Bạn chắc chắn muốn hủy yêu cầu này?'
        }
      />
      {itemDetail && (
        <PopupDetail
          item={itemDetail}
          isOpen={itemDetail !== null}
          closeModal={() => setItemDetal(null)}
          refetch={refetch}
        />
      )}
      {isOpenPopUpCreate && (
        <PopupCreate
          isOpen={isOpenPopUpCreate}
          closeModal={() => setIsOpenPopupCreate(false)}
          refetch={refetch}
        />
      )}
    </div>
  );
}

PaymentRequest.propTypes = {};

export default PaymentRequest;
