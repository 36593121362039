import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, Row, Col, Container, Button } from 'reactstrap';
import { transactionReject } from '@Apis/admin/transaction';
import { toastError, toastSuccess } from '@Utils/toastrHelper';

import useCustomMutation from '@Utils/useCustomMuation';
import CustomerInfo from './CustomerInfo';
import TransactionInfo from './TransactionInfo';
import TransactionForm from './TransactionForm';
import TransactionDetail from './TransactionDetail';

function PopUpEdit({
  isOpen,
  closeModal,
  refetch,
  item,
  setIsRefreshStatus,
  optionsWarehouse,
  optionsTeller,
}) {
  const [statusEdit, setStatusEdit] = useState(item.status);
  const { mutate, isSuccess, error } = useCustomMutation(transactionReject, true);

  const onReject = () => {
    mutate({
      id: item.id,
      status: statusEdit,
    });
  };

  useEffect(() => {
    if (isSuccess) {
      toastSuccess('Huỷ giao dịch thành công');
      refetch();
      closeModal();
      setIsRefreshStatus({ state: true });
    }
    if (error) {
      toastError(error);
    }
  }, [isSuccess, error]);

  return item ? (
    <Modal isOpen={isOpen} toggle={closeModal} className="text-center modal-admin-transaction">
      <Container>
        <Row>
          <Col md="4" className="border-right px-1 py-2">
            <CustomerInfo item={item} />
          </Col>
          <Col md="8" className="px-1 py-2">
            <TransactionInfo item={item} statusEdit={statusEdit} setStatusEdit={setStatusEdit} />
            {statusEdit === 'created' ? (
              <TransactionForm
                item={item}
                refetch={refetch}
                closeModal={closeModal}
                optionsWarehouse={optionsWarehouse}
                optionsTeller={optionsTeller}
                setIsRefreshStatus={setIsRefreshStatus}
              />
            ) : (
              <TransactionDetail item={item} />
            )}

            {item.status === 'created' && (statusEdit === 'expired' || statusEdit === 'cancelled') && (
              <div className="d-flex justify-content-center gap-20 py-3">
                <Button
                  type="button"
                  onClick={() => onReject()}
                  className="px-2"
                  size="md"
                  color="primary"
                >
                  Xác nhận
                </Button>
                <Button type="button" onClick={() => closeModal()}>
                  Hủy
                </Button>
              </div>
            )}
          </Col>
        </Row>
      </Container>
    </Modal>
  ) : (
    ''
  );
}

PopUpEdit.propTypes = {
  isOpen: PropTypes.bool,
  closeModal: PropTypes.func,
  refetch: PropTypes.func,
  item: PropTypes.object,
  optionsWarehouse: PropTypes.array,
  optionsTeller: PropTypes.array,
  setIsRefreshStatus: PropTypes.func,
};

export default PopUpEdit;
