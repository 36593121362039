/* eslint-disable react/jsx-wrap-multilines */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { InputLabel, Input, InputAdornment, IconButton } from '@mui/material';
import { VisibilityOff, Visibility } from '@mui/icons-material';
import FormControl from '@mui/material/FormControl';
import { getInputErrorsObject } from '@Utils/helpers';

function PasswordInput({ labelName, setValue, name, errors, validationText }) {
  const [inputValue, setInputValue] = useState('');
  const [isShow, setIsShow] = useState(false);
  const [errorDetail, setErrorDetail] = useState();

  const handleChange = (value) => {
    setInputValue(value);
    setValue(name, value);
  };

  useEffect(() => {
    if (name && errors) {
      setErrorDetail(getInputErrorsObject(name, errors));
    }
  }, [name, errors && Object.keys(errors)]);

  return (
    <FormControl fullWidth sx={{ m: 1 }} variant="standard" className="m-0 pb-2">
      <InputLabel htmlFor="standard-adornment-password">{labelName || 'Password'}</InputLabel>
      <Input
        id="standard-adornment-password"
        type={isShow ? 'text' : 'password'}
        value={inputValue}
        onChange={(e) => handleChange(e.target.value)}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={() => setIsShow(!isShow)}
              onMouseDown={() => {}}
            >
              {isShow ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        }
      />
      {errorDetail && (
        <p className="text-danger font-size-12 mb-0" style={{ paddingTop: '2px' }}>
          {errorDetail.type === 'validate'
            ? validationText
            : errorDetail.message || 'Trường bắt buộc'}
        </p>
      )}
    </FormControl>
  );
}

PasswordInput.propTypes = {
  name: PropTypes.string,
  labelName: PropTypes.string,
  setValue: PropTypes.func,
  errors: PropTypes.object,
  validationText: PropTypes.string,
};

export default PasswordInput;
