import styled from 'styled-components';

export const ConditionTableWrapper = styled.div`
  position: relative;
  .rdt_Table {
    overflow: inherit !important;
  }
  .badge-quantity {
    font-size: 12px;
    color: white;
    width: 20px;
    height: 20px;
    border-radius: 4px;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    justify-content: space-around;
    margin-left: 8px;
  }
  .dropdown-menu {
    /* top: -25px !important;
    right: -50px !important; */
    // position: static !important;
  }
  .dropdown {
    position: unset;
  }
  .custom-table-scroll-fixed-left > div {
    position: unset;
    overflow: unset;
  }
`;
