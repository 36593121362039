/* eslint-disable jsx-a11y/no-autofocus */
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { UncontrolledDropdown, DropdownMenu, DropdownItem, DropdownToggle } from 'reactstrap';
import { ArrowDropDown } from '@mui/icons-material';
import ReactDOM from 'react-dom';
import { DropdownWrapper } from './styles';
import './styles.scss';

class DropdownSelect extends Component {
  constructor(props) {
    super(props);
    const { options } = this.props;
    this.state = { isOpen: false, options };
    this.div = document.createElement('div');
    document.querySelector('body')?.append(this.div);
  }

  UNSAFE_componentWillReceiveProps(nProps) {
    const { options } = this.props;
    if (options !== nProps.options) {
      this.setState({ options: nProps.options });
    }
  }

  onSearch = (event) => {
    const { options, labelKey } = this.props;
    const newOptions = options.filter((e) =>
      e[labelKey]?.toLowerCase?.()?.includes(event.target.value.toLowerCase()),
    );
    this.setState({ options: newOptions });
  };

  getLabel = (value) => {
    const { labelKey = 'label', labelValue = 'value' } = this.props;
    const { options } = this.state;
    const item = options?.find((e) => e[labelValue]?.toString() === value?.toString());
    return item?.[labelKey];
  };

  handleSelect = (option) => {
    const { options, setValueSelected, name, labelValue = 'value' } = this.props;
    this.setState({ options });
    setValueSelected?.(name, option[labelValue]);
  };

  handleToggle = () => {
    const { isOpen } = this.state;
    const { options } = this.state;
    this.setState({ isOpen: !isOpen, options });
  };

  render() {
    const { isOpen } = this.state;
    const {
      className,
      valueSelected,
      labelShow = true,
      label,
      style,
      labelKey = 'label',
      labelValue = 'value',
      unArrow = false,
      renderItem,
      styleMenu,
      disabled,
      allowSearch,
      placeholder,
      styleWrap,
    } = this.props;
    const { options } = this.state;
    return (
      <DropdownWrapper
        style={styleWrap}
        className={`${allowSearch ? 'custom-dropdown-100' : ''}`}
        ref={(ref) => {
          this.divSwap = ref;
        }}
      >
        <div className={`${className || ''} ${disabled ? 'dropdown-disabled' : ''}`}>
          <UncontrolledDropdown
            isOpen={isOpen}
            onToggle={this.handleToggle}
            style={style}
            disabled={disabled}
          >
            <DropdownToggle nav>
              {isOpen && allowSearch ? (
                <input onChange={this.onSearch} autoFocus placeholder="Nhập kí tự tìm kiếm" />
              ) : (
                <div className="d-flex align-items-center justify-content-between">
                  <div className="d-flex align-items-center justify-content-between">
                    {labelShow ? (
                      <span
                        className="font-size-14 font-weight-bold color-matte-gray"
                        style={{ whiteSpace: 'break-spaces' }}
                      >
                        {label || 'Trạng thái:'}
                      </span>
                    ) : (
                      <span style={{ color: '#6e6b7b', fontSize: 14 }} className="uppercase">
                        {!valueSelected ? placeholder : ''}
                      </span>
                    )}
                    <span className="font-size-14 font-weight-bold uppercase">
                      {this.getLabel(valueSelected)}
                    </span>
                  </div>
                  {!unArrow && <ArrowDropDown color="disabled" />}
                </div>
              )}
            </DropdownToggle>
            <>
              {ReactDOM.createPortal(
                <DropdownMenu
                  style={{
                    maxHeight: 280,
                    overflow: 'auto',
                    ...styleMenu,
                    maxWidth: this.divSwap?.clientWidth,
                    zIndex: 10000,
                    marginTop: 0,
                  }}
                >
                  {options?.length ? (
                    options.map((option, i) => (
                      <DropdownItem
                        disabled={!!option.disabled}
                        key={i.toString()}
                        className={`w-100${
                          valueSelected === option[labelValue] ? ' item-dropdown-active' : ''
                        }`}
                        onClick={() => {
                          this.handleSelect(option);
                        }}
                      >
                        {renderItem ? (
                          renderItem(option)
                        ) : (
                          <p className="font-size-14 mb-0 mr-auto uppercase">{option[labelKey]}</p>
                        )}
                      </DropdownItem>
                    ))
                  ) : (
                    <div style={{ padding: '40px 10px', textAlign: 'center' }}>
                      Không có dữ liệu tìm kiếm
                    </div>
                  )}
                </DropdownMenu>,
                this.div,
              )}
            </>
          </UncontrolledDropdown>
        </div>
      </DropdownWrapper>
    );
  }
}

DropdownSelect.propTypes = {
  options: PropTypes.array,
  className: PropTypes.string,
  label: PropTypes.any,
  labelShow: PropTypes.bool,
  valueSelected: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  setValueSelected: PropTypes.func,
  name: PropTypes.string,
  labelKey: PropTypes.string,
  labelValue: PropTypes.string,
  placeholder: PropTypes.any,
  style: PropTypes.object,
  styleWrap: PropTypes.object,
  styleMenu: PropTypes.object,
  register: PropTypes.object,
  unArrow: PropTypes.bool,
  disabled: PropTypes.bool,
  allowSearch: PropTypes.bool,
  renderItem: PropTypes.func,
};

export default DropdownSelect;
