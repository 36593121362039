import { accountsHome } from '@Apis/client/home';
import { stockAsset } from '@Apis/client/stock';
import { closePosition, list } from '@Apis/client/transaction';
import DataTableBasic from '@Components/DataTableBasic';
import NavigationBar from '@Components/NavigationBar';
import useCustomQuery from '@Utils/useCustomQuery';
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { toastError } from '@Utils/toastrHelper';
import { columnsLichSuDongViThe, columnsViTheDong, columnsViTheMo } from './columns';

function ManagementPosition({ stockId }) {
  const [tab, setTab] = useState(1);
  const [filter, setFilter] = useState({});
  const [page, setStatePage] = useState(1);

  const productType = useSelector((state) => state.ui.productType);

  const { data: dataAccounts } = useCustomQuery(accountsHome);
  const account = dataAccounts?.accounts?.find((e) => e.trade_type === productType);

  const { data } = useCustomQuery(
    stockAsset,
    {
      ...filter,
      page,
      customer_id: account?.id,
    },
    false,
    null,
    5000,
  );

  const { data: dataVitheDongTrongNgay } = useCustomQuery(
    closePosition,
    {
      ...filter,
      page,
      customer_id: account?.id,
      today: 1,
      stock_id: stockId,
    },
    false,
    null,
    5000,
  );

  const { data: dataLichSuDongViThe } = useCustomQuery(
    closePosition,
    {
      ...filter,
      page,
      customer_id: account?.id,
      stock_id: stockId,
    },
    false,
    null,
    5000,
  );

  return (
    <div className="mt-1" style={{ background: '#fff' }}>
      <div style={{ padding: '24px 24px 10px' }}>
        <div className="title">Quản lý vị thế</div>
      </div>
      <NavigationBar
        className="transaction-navigator-bar"
        tabs={[
          {
            id: 1,
            name: 'Vị thế mở',
          },
          {
            id: 2,
            name: 'Vị thế đóng trong ngày',
          },
          {
            id: 3,
            name: 'Lịch sử đóng vị thế',
          },
        ]}
        activeTab={tab}
        setActiveTab={(t) => {
          setTab(t);
          setStatePage(1);
        }}
      />

      {tab === 1 ? (
        <DataTableBasic
          setPage={setStatePage}
          page={page}
          data={{ data: data?.assets, paging: data?.paging }}
          columns={columnsViTheMo}
        />
      ) : null}

      {tab === 2 ? (
        <DataTableBasic
          setPage={setStatePage}
          page={page}
          data={{ data: dataVitheDongTrongNgay, paging: data?.paging }}
          columns={columnsViTheDong}
        />
      ) : null}

      {tab === 3 ? (
        <DataTableBasic
          setPage={setStatePage}
          page={page}
          data={{ data: dataLichSuDongViThe, paging: data?.paging }}
          columns={columnsLichSuDongViThe}
        />
      ) : null}
    </div>
  );
}

ManagementPosition.propTypes = {
  stockId: PropTypes.any,
};

export default ManagementPosition;
