import axiosService from '@Lib/axiosService';
import { handleResponse } from '@Utils/helpers';

let host;
if (process.env.DASHBOARD_TYPE === 'customer') {
  host = `${process.env.API_CUSTOMER_URL}/payment-request`;
}

export const list = async (params) => {
  const res = await axiosService.get(`${host}/list`, params);
  return handleResponse(res);
};

export const save = async (dataReq) => {
  const res = await axiosService.post(`${host}/save`, dataReq);
  return handleResponse(res);
};

export const cancel = async (dataReq) => {
  const res = await axiosService.post(`${host}/cancel`, dataReq);
  return handleResponse(res);
};

export const localTransfer = async (dataReq) => {
  const res = await axiosService.post(`${host}/local-transfer`, dataReq);
  return handleResponse(res);
};
