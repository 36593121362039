import styled from 'styled-components';

export const SummaryWrapper = styled.div`
  .col {
    border-right: 1px solid #ebeaed;
    padding: 26px 24px;
  }
  .col:last-child {
    border-right: none;
  }
`;
