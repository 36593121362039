import React, { useState } from 'react';
import NavigationBar from '@Components/NavigationBar';
import PropTypes from 'prop-types';
import Payment from './Payment';
import WithDraw from './Withdraw';
import MoneyTransfer from './MoneyTransfer';
import PaymentAutomatic from './PaymentAutomatic';
import { useSelector } from 'react-redux';

function Request(props) {
  const { refechDateHistory, account } = props;
  const [activeTabId, setActiveTab] = useState(1);

  const isPreview = useSelector((state) => !!state.user?.info?.is_preview);
  // const isPreview = true;

  return (
    <>
      <NavigationBar
        setActiveTab={setActiveTab}
        activeTab={activeTabId}
        tabs={[
          { id: 1, name: 'Nộp tiền' },
          isPreview ? { id: 4, name: 'Nộp tiền tự động' } : null,
          { id: 2, name: 'Rút tiền' },
          { id: 3, name: 'Chuyển tiền nội bộ' },
        ].filter(Boolean)}
      />
      <div className="body-tab-content">
        {activeTabId === 1 && (
          <Payment account={account} refechDateHistory={refechDateHistory} />
        )}
        {activeTabId === 4 && (
          <PaymentAutomatic account={account} />
        )}
        {activeTabId === 2 && (
          <WithDraw account={account} refechDateHistory={refechDateHistory} />
        )}
        {activeTabId === 3 && (
          <MoneyTransfer account={account} refechDateHistory={refechDateHistory} />
        )}
      </div>
    </>
  );
}

Request.propTypes = {
  refechDateHistory: PropTypes.func,
  account: PropTypes.any,
};

export default Request;
