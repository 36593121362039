import { lazy } from 'react';
import shortid from 'shortid';

const privateCustomerConstant = [
  {
    id: shortid.generate(),
    name: 'Tổng quan',
    path: '/dashboard',
    // component: lazy(() => import('../views/Dashboard')),
    icon: 'dashboard',
  },
  {
    id: shortid.generate(),
    name: 'Giao dịch',
    path: '/transaction',
    component: lazy(() => import('../views/Admin/Transaction')),
    icon: 'transaction',
  },
  {
    id: shortid.generate(),
    name: 'Danh mục',
    path: '/instrument',
    component: lazy(() => import('../views/Admin/Instrument')),
    icon: 'instrument',
  },
  {
    id: shortid.generate(),
    name: 'Tài sản',
    path: '/asset',
    component: lazy(() => import('../views/Asset')),
    icon: 'asset',
  },
  {
    id: shortid.generate(),
    name: 'Thông báo',
    path: '/notification',
    component: lazy(() => import('../views/Notification')),
    icon: 'notification',
  },
  {
    id: shortid.generate(),
    name: 'Tài khoản',
    path: '/account',
    component: lazy(() => import('../views/Client/Account')),
    exact: true,
    icon: 'user',
  },
];

const privateAdminConstant = [
  {
    id: shortid.generate(),
    name: 'Tổng quan',
    path: '/dashboard',
    component: lazy(() => import('../views/Blank')),
    icon: 'dashboard',
  },
  {
    id: shortid.generate(),
    name: 'Giao dịch',
    path: '/transaction',
    component: lazy(() => import('../views/Blank')),
    icon: 'transaction',
  },
  {
    id: shortid.generate(),
    name: 'Khách hàng',
    path: '/customer',
    icon: 'customer',
    child: [
      {
        id: shortid.generate(),
        path: '/customer',
        component: lazy(() => import('../views/Admin/Customer')),
      },
      {
        id: shortid.generate(),
        path: '/customer/add-new',
        component: lazy(() => import('../views/Admin/CustomerDetail')),
        hidden: true,
      },
      {
        id: shortid.generate(),
        path: '/customer/:customerId',
        component: lazy(() => import('../views/Admin/CustomerDetail')),
        hidden: true,
      },
    ],
  },
  {
    id: shortid.generate(),
    name: 'Danh mục mã CK',
    path: '/stock',
    component: lazy(() => import('../views/Admin/Stock')),
    exact: true,
    icon: 'stock',
  },
  {
    id: shortid.generate(),
    name: 'Nhân viên',
    path: '/employee',
    exact: true,
    icon: 'employee',
    hasSub: true,
    child: [
      {
        id: shortid.generate(),
        path: '/employee',
        component: lazy(() => import('../views/Admin/Employee')),
        name: 'Danh sách nhân viên',
      },
      {
        id: shortid.generate(),
        path: '/employee/add-new',
        component: lazy(() => import('../views/Admin/EmployeeDetail')),
        name: 'Danh sách nhân viên',
        hidden: true,
      },
      {
        id: shortid.generate(),
        path: '/employee/:employeeId',
        component: lazy(() => import('../views/Admin/EmployeeDetail')),
        name: 'Danh sách nhân viên',
        hidden: true,
      },
      {
        id: shortid.generate(),
        path: '/employee-role',
        component: lazy(() => import('../views/Admin/EmployeeRole')),
        name: 'Phân quyền nhóm',
      },
    ],
  },
];

export const privateConstant =
  process.env.DASHBOARD_TYPE === 'admin' ? privateAdminConstant : privateCustomerConstant;
