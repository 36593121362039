import { buyAbility } from '@Apis/client/account';
import { formatNumber } from '@Utils/helpers';
import { toastError } from '@Utils/toastrHelper';
import React, { Component } from 'react';
import PropTypes from 'prop-types';

class BuyAblitity extends Component {
  constructor(props) {
    super(props);
    this.state = { data: {} };
  }

  UNSAFE_componentWillMount() {
    this.getData();
    this.interval = setInterval(this.getData, 3000);
  }

  shouldComponentUpdate(nProps, nState) {
    const { data } = this.state;
    const { quantity, available_quantity, price } = this.props;
    return (
      data?.buy_ability !== nState.data?.buy_ability ||
      price !== nProps.price ||
      quantity !== nProps.quantity ||
      available_quantity !== nProps.available_quantity
    );
  }

  componentWillUnmount() {
    this.interval && clearInterval(this.interval);
  }

  getData = () => {
    const { stock_id } = this.props;
    buyAbility({ stock_id })
      .then((data) => {
        this.setState({ data });
      })
      .catch(toastError);
  };

  getMaxQuantity = () => {
    const { data } = this.state;
    const { price } = this.props;
    return Math.floor(
      (data?.buy_ability || 0) / (price || 1) > 0 ? (data?.buy_ability || 0) / (price || 1) : 0,
    );
  };

  render() {
    const { data } = this.state;
    const { quantity, available_quantity } = this.props;
    return (
      <>
        <div className="d-flex justify-content-between align-items-center mb-2">
          <div className="w-50">
            Sức mua: <span>{formatNumber(data.buy_ability > 0 ? data.buy_ability : 0, '--')}</span>
          </div>
          <div className="w-50">Tổng KL: {formatNumber(quantity, '0')}</div>
        </div>
        <div className="d-flex justify-content-between align-items-center mb-2">
          <div className="w-50">KL khả dụng: {formatNumber(available_quantity, '0')}</div>
          <div className="w-50">KL tối đa: {formatNumber(this.getMaxQuantity())}</div>
        </div>
      </>
    );
  }
}

BuyAblitity.propTypes = {
  quantity: PropTypes.number,
  available_quantity: PropTypes.number,
  price: PropTypes.number,
  stock_id: PropTypes.number,
};

export default BuyAblitity;
