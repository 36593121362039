/* eslint-disable indent */
/* eslint-disable react/jsx-indent */
/* eslint-disable no-nested-ternary */
import React, { Fragment } from 'react';
import dayjs from 'dayjs';
import { NavLink } from 'reactstrap';
import { Edit, HighlightOff } from '@mui/icons-material';
import { formatNumber } from '@Utils/helpers';
import TooltipComment from '@Components/TooltipComment';
import { TRADE_STATUS } from '../constants';

function renderType(type) {
  return (
    <div className="">
      {type === 'buy' ? (
        <div style={{ background: 'rgba(32, 201, 172, 0.1)' }}>
          <p className="text-success font-size-14 font-weight-bolder py-25 px-1">Mua</p>
        </div>
      ) : (
        <div style={{ background: 'rgba(252, 52, 0, 0.1)' }}>
          <p className="text-danger font-size-14 font-weight-bolder py-25 px-1">Bán</p>
        </div>
      )}
    </div>
  );
}
function renderStatus(status) {
  const item = TRADE_STATUS.find((item) => item.value === status);
  if (item) {
    const { color, label } = item;
    return <div style={{ color }}>{label}</div>;
  }
  return '';
}

function renderTime(activeTabId, createdAt) {

  if(createdAt === null || createdAt === undefined) return '--';

  if (activeTabId === 1) {
    return dayjs.unix(createdAt).format('HH:mm');
  }
  return dayjs.unix(createdAt).format('HH:mm - DD/MM/YY');
}

function isShowButtonCancel(row) {
  const { status } = row;
  if (
    status === 'created' ||
    status === 'pending' ||
    status === 'part_completed' ||
    status === 'in_process'
  ) {
    return true;
  }
  return false;
}

function renderDCAPrice(row) {
  const { type, dca_sell_price, dca_purchase_price } = row;
  if (type === 'sell') {
    return dca_sell_price;
  }
  if (type === 'buy') {
    return dca_purchase_price;
  }
  return '--';
}

function renderPrice({ price_type, price, edit_price, is_edit_request }) {
  if (price_type !== 'price') {
    return (
      <div className="uppercase" style={{ textAlign: 'center' }}>
        <div style={{ background: 'rgba(32, 201, 172, 0.1)' }}>
          <p className="font-size-14 font-weight-bolder py-25 px-1 text-info">{price_type}</p>
        </div>
      </div>
    );
  }
  if( edit_price === null || edit_price === undefined || edit_price < 0) {
    return (
        <div>
            {formatNumber(price)}
        </div>
    );
  }
  return (
    <>
      <div>{formatNumber(edit_price)}</div>
      {(edit_price) > 0 && edit_price !== price ? (
          <span style={{ fontSize: 12, opacity: 0.5, textDecoration: 'line-through', marginLeft: 5 }}>
          {formatNumber(price)}
        </span>
      ) : (
        ''
      )}
    </>
  );
}

function renderAction({ row, setItemEdit, setItemDelete }) {
  return (
    <div className="d-flex align-items-center justify-content-between">
      {row.is_cancel_request || row.is_edit_request ? (
        <span
          style={{ color: '#FC3400', fontWeight: 600, fontSize: 14, padding: '0.358rem 0.5rem' }}
        >
          {row.is_edit_request && (row.status !== 'completed' && row.status !== 'cancelled' && row.status !== 'expired') ? 'Chờ Sửa' : ''}
          {row.is_cancel_request && (row.status !== 'completed' && row.status !== 'cancelled' && row.status !== 'expired') ? 'Chờ Huỷ' : ''}
        </span>
      ) : row.status === 'created' ||
        row.status === 'pending' ||
        row.status === 'in_process' ||
        row.status === 'part_completed' ? (
        <>
          <NavLink
            onClick={() => setItemEdit(row)}
            className="text-success d-flex align-items-center"
          >
            <span className="mr-25">Sừa lệnh</span>
            <Edit className="text-success" fontSize="14px" />
          </NavLink>
          <NavLink onClick={() => setItemDelete(row)} className="text-danger">
            <span className="mr-25">Huỷ</span>
            <HighlightOff color="error" fontSize="14px" />
          </NavLink>
        </>
      ) : null}
    </div>
  );
}

export const columns = ({ activeTabId, setItemEdit, setItemDelete }) => {
  return [
    {
      name: 'MÃ GD',
      cell: (row) => <div className="">{row.id}</div>,
      center: true,
      fixed: 'left',
      width: '100px',
    },
    {
      name: 'CỔ PHIẾU',
      cell: (row) => <div className="">{row.stock_id}</div>,
      center: true,
      fixed: 'left',
      width: '100px',
    },
    {
      name: 'LOẠI  LỆNH',
      cell: (row) => renderType(row.type),
      center: true,
    },
    {
      name: 'KL ĐẶT',
      cell: (row) => (
        <div style={{ width: '100%', textAlign: 'right' }}>
          <div>{formatNumber(row.quantity)} </div>
          {typeof row.edit_quantity === 'number' &&
          row.edit_quantity !== row.quantity &&
          row.is_edit_request ? (
            <span
              style={{ fontSize: 12, opacity: 0.5, textDecoration: 'line-through', marginLeft: 5 }}
            >
              {formatNumber(row.edit_quantity)}
            </span>
          ) : (
            ''
          )}
        </div>
      ),
      center: true,
    },
    {
      name: 'GIÁ ĐẶT',
      cell: (row) => <div style={{ width: '100%', textAlign: 'right' }}>{renderPrice(row)}</div>,
      center: true,
    },
    {
      name: 'TRẠNG THÁI',
      width: '120px',
      cell: (row) => <div className="">{renderStatus(row.status)}</div>,
      center: true,
    },
    {
      name: 'KL KHỚP',
      center: true,
      cell: (row) => {
        if (row.type === 'buy') {
          return (
            <div style={{ width: '100%', textAlign: 'right' }}>
              <span className={`${row.purchase_quantity === 0 ? '' : 'text-success'} `}>
                {formatNumber(row.purchase_quantity, '--')}
              </span>
            </div>
          );
        }
        return (
          <div style={{ width: '100%', textAlign: 'right' }}>
            <span className={`${row.sell_quantity === 0 ? '' : 'text-danger'} `}>
              {formatNumber(row.sell_quantity, '--')}
            </span>
          </div>
        );
      },
    },
    {
      name: 'GIÁ KHỚP',
      cell: (row) => (
        <div style={{ width: '100%', textAlign: 'right' }}>
          {formatNumber(renderDCAPrice(row), '--')}
        </div>
      ),
      width: '100px',
      center: true,
    },
    {
      name: 'THỜI GIAN',
      width: '140px',
      cell: (row) => <div className="">{renderTime(activeTabId, row.created_at)}</div>,
      center: true,
    },{
      name: 'TG KHỚP',
      width: '140px',
      cell: (row) => <div className="">{renderTime(activeTabId, row?.lastTradingItemTime?.created_at)}</div>,
      center: true,
    },

    {
      name: 'GHI CHÚ',
      width: '220px',
      cell: (row) => <TooltipComment item={row} />,
    },
    {
      name: 'HÀNH ĐỘNG',
      cell: (row) => (
        <div className="w-100 d-flex justify-content-center">
          {renderAction({ row, setItemEdit, setItemDelete })}
        </div>
      ),
      width: '200px',
    },
  ];
};
