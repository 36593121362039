import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import TextInput from '@Components/MUInput/TextInput';
import PasswordInput from '@Components/MUInput/PasswordInput';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import axiosService from '@Lib/axiosService';
import useCustomMutation from '@Utils/useCustomMuation';
import { login } from '@Apis/auth';
import ButtonSubmit from '@Components/ButtonSubmit';
import CustomeModal from '@Components/CustomeModal';
import { TYPE_HOME } from '@Components/Layouts/PrivateLayout';
import { CardTitle, ImageWrapper, LoginWrapper } from './styles';
import './styles.scss';

function Login() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();
  const navigate = useNavigate();

  const { mutate, isSuccess, data, error, isError, isLoading } = useCustomMutation(login);
  const [isOpen, setIsOpen] = useState(false);
  const productType = useSelector((state) => state.ui.productType);

  const onSubmit = (data) => {
    mutate(data);
  };

  useEffect(() => {
    if (isSuccess) {
      const { auth_key, base_role } = data;
      localStorage.setItem(process.env.TOKEN, auth_key);
      axiosService.setHeader('Authorization', `Bearer ${auth_key}`);
      let urlNavigate =
        productType === TYPE_HOME && process.env.DASHBOARD_TYPE !== 'admin'
          ? '/home'
          : '/dashboard';
      if (base_role === 'tellers') {
        urlNavigate = '/transaction-tellers';
      } else if (base_role === 'surveyor') {
        urlNavigate = '/transaction';
      }
      navigate(urlNavigate);
    }
  }, [isSuccess]);

  const newLocal = (
    <span style={{ fontSize: 18 }}>Vui lòng liên hệ CSKH để được hỗ trợ lấy lại mật khẩu.</span>
  );
  return (
    <div className="auth-wrapper auth-v2">
      <CustomeModal
        mess={newLocal}
        footer={null}
        isOpen={isOpen}
        closeModal={() => setIsOpen(false)}
        title="Quên mật khẩu?"
        icon={<img style={{ marginTop: 39 }} src="/images/login/modal_forgot.svg" alt="" />}
      />
      <div className="login-page ">
        <div className="login-image">
          <ImageWrapper>
            {process.env.DASHBOARD_TYPE === 'admin' ? (
              <img src="/images/login/login-BG-adm-1.jpg" alt="bg" />
            ) : (
              <img src="/images/login/login-BG.png" alt="bg" />
            )}
          </ImageWrapper>
        </div>
        <div className="login-form">
          <LoginWrapper className="wrap-login-form">
            <div className="h-100 d-flex align-items-center">
              <div className="w-100">
                <CardTitle className="mb-1">
                  Đăng nhập {process.env.DASHBOARD_TYPE === 'admin' ? '- Admin' : ''}
                </CardTitle>
                <p className="font-size-14">
                  Chào mừng bạn quay lại. Vui lòng đăng nhập tài khoản{' '}
                </p>
                <form onSubmit={handleSubmit(onSubmit)}>
                  {process.env.DASHBOARD_TYPE === 'admin' ? (
                    <TextInput
                      labelCol="12"
                      inputCol="12"
                      type="text"
                      setValue={setValue}
                      labelName="Username"
                      placeholder="Username"
                      name="username"
                      errors={errors}
                      register={{ ...register('username', { required: true }) }}
                    />
                  ) : (
                    <TextInput
                      labelCol="12"
                      inputCol="12"
                      type="text"
                      setValue={setValue}
                      labelName="Tên đăng nhập"
                      placeholder="Email"
                      name="email"
                      errors={errors}
                      register={{ ...register('email', { required: true }) }}
                    />
                  )}

                  <PasswordInput
                    labelCol="12"
                    inputCol="12"
                    type="password"
                    setValue={setValue}
                    labelName="Mật khẩu"
                    placeholder="Mật khẩu"
                    name="password"
                    errors={errors}
                    register={{ ...register('password', { required: true }) }}
                  />

                  <div className="wrap-button">
                    <ButtonSubmit
                      name="Đăng nhập"
                      className="px-2"
                      size="md"
                      color="primary"
                      isLoading={isLoading}
                    />
                    {/* <Button color="link" type="button" onClick={() => setIsOpen(true)}>
                      Quên mật khẩu
                    </Button> */}
                  </div>
                  {isError && <p className="text-danger my-50 font-size-14">{error}</p>}
                </form>
              </div>
            </div>
            {process.env.DASHBOARD_TYPE === 'admin' ? (
              ''
            ) : (
              <p className="font-size-14" style={{ paddingBottom: 50 }}>
                <span className="font-weight-600"> Bạn chưa có tài khoản?</span> Vui lòng liên hệ:{' '}
                <span className="font-weight-700">0934.221.661 Ms Hương</span> để được hướng dẫn
                đăng ký.
              </p>
            )}
          </LoginWrapper>
        </div>
      </div>
    </div>
  );
}

Login.propTypes = {};

export default Login;
