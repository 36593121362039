import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { warehouseDetail } from '@Apis/admin/warehouse';
import { holdByCustomer } from '@Apis/admin/stock';
import { NavLink } from 'reactstrap';
import { ArrowBack } from '@mui/icons-material';
import useCustomQuery from '@Utils/useCustomQuery';
import DataTableBasic from '@Components/DataTableBasic';
import { columns } from './Columns';
import { SubTitle } from './styles';

function WarehouseAccountStockDetail() {
  const naviagate = useNavigate();
  const { warehouseId, stock } = useParams();
  const [filter, setFilter] = useState({
    purchase_account_id: warehouseId,
    stock_id: stock,
    keyword: '',
  });
  const [page, setPage] = useState(0);

  const { data: wareHouseDetail } = useCustomQuery(warehouseDetail, { id: warehouseId }, true, {
    enabled: !!warehouseId,
  });
  const { data: dataTable } = useCustomQuery(holdByCustomer, filter);
  useEffect(() => {
    if (page) {
      setFilter({ ...filter, page });
    }
  }, [page]);

  return (
    <div className="content-wrapper">
      <p className="font-size-28 font-weight-bolder">Chi tiết kho CK {wareHouseDetail?.name}</p>

      <SubTitle>
        <div className="d-flex align-items-center">
          <NavLink onClick={() => naviagate(`/warehouse/account/detail/${warehouseId}`)}>
            <ArrowBack style={{ color: '#828282' }} />
          </NavLink>

          <p className="font-size-24 font-weight-bold ml-50">
            Danh sách khách sở hữu mã CK:
            <span className="text-uppercase text-secondary"> {stock}</span>
          </p>
        </div>
      </SubTitle>
      <div className="pt-2">
        <DataTableBasic
          columns={columns()}
          data={dataTable}
          isPageEffect
          page={page}
          setPage={setPage}
        />
      </div>
    </div>
  );
}

export default WarehouseAccountStockDetail;
