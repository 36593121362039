import React from 'react';
import PropTypes from 'prop-types';
import {
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
  Button,
} from 'reactstrap';
import { Square } from '@mui/icons-material';
import { STATUS_BUTTON } from '../../constants';

function ButtonStatus({ changeStatus, status, item }) {
  const renderMenu = () => {
    const statusMenu = [item.status, 'rejected'];
    const dropdownMenu = statusMenu.map((statusItem, i) => {
      if (statusItem !== status) {
        const statusObj = STATUS_BUTTON.find((item) => item.value === statusItem);
        return (
          <DropdownItem
            key={i.toString()}
            className="w-100"
            onClick={() => changeStatus(statusItem)}
          >
            <Square
              style={{
                color: statusObj.color,
              }}
            />
            <span
              className="align-middle font-size-14 font-weight-bolder ml-50 text-uppercase"
              style={{ color: statusObj.color }}
            >
              {statusObj.nameBtnEdit}
            </span>
          </DropdownItem>
        );
      }
    });
    return dropdownMenu;
  };

  const objStatus = STATUS_BUTTON.find((item) => item.value === status);

  const { color, name, value, nameBtnEdit } = objStatus;

  return item.status === 'pending' ? (
    <UncontrolledDropdown className="nav-item">
      <DropdownToggle
        color="none"
        style={{ background: color }}
        className="font-size-14 text-white text-uppercase"
        onClick={(e) => e.preventDefault()}
        caret
      >
        {nameBtnEdit}
      </DropdownToggle>
      <DropdownMenu right>{renderMenu()}</DropdownMenu>
    </UncontrolledDropdown>
  ) : (
    <Button className="text-white" color="none" style={{ background: color }}>
      {nameBtnEdit}
    </Button>
  );
}

ButtonStatus.propTypes = {
  status: PropTypes.string,
  item: PropTypes.object,
  changeStatus: PropTypes.func,
};

export default ButtonStatus;
