import React, { useState, useEffect, useCallback, useRef } from 'react';
import useCustomQuery from '@Utils/useCustomQuery';
import { stockIndex, stockAsset } from '@Apis/client/stock';
import { buyStock } from '@Apis/client/transaction';
import { Container, Row, Col, Button } from 'reactstrap';
import InputSearch from '@Components/InputSearch';
import DataTableBasic from '@Components/DataTableBasic';
import { useForm } from 'react-hook-form';
import TextInput from '@Components/Input/TextInput';
import useCustomMutation from '@Utils/useCustomMuation';
import { toastSuccess, toastError } from '@Utils/toastrHelper';
import SelectInput from '@Components/Input/SelectInput';
import ButtonSubmit from '@Components/ButtonSubmit';
import CustomeModal from '@Components/CustomeModal';
import PropTypes from 'prop-types';
import { formatNumber } from '@Utils/helpers';
import shortid from 'shortid';
import { columns } from './BuyColumns';
import { TableWrapper } from '../styles';
import BuyAblitity from './BuyAbility';

function Buy({ setIdRefresh }) {
  const [page, setPage] = useState(0);
  const [filter, setFilter] = useState({
    keyword: '',
    per_page: 6,
  });
  const refBuy = useRef();
  const [formDataSubmit, setOpenModalWarning] = useState();
  const [itemSelected, setItemSelected] = useState();
  const { data, refetch } = useCustomQuery(stockIndex, filter, true);
  const { data: dataAsset } = useCustomQuery(stockAsset, { stock_id: itemSelected?.id }, true, {
    enabled: !!itemSelected,
  });
  const { mutate, isSuccess, error } = useCustomMutation(buyStock, true);

  const filterOnChange = (name, value) => {
    setFilter((prevValues) => {
      return {
        ...prevValues,
        [name]: value,
        page: 1,
      };
    });
  };

  const selectItem = useCallback((item) => {
    setItemSelected(item);
    if (window.innerWidth < 768) {
      if (window.scrollY < refBuy.current?.getBoundingClientRect()?.y) {
        window.scrollTo(0, refBuy.current?.getBoundingClientRect()?.y);
      }
    }
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm(itemSelected || {});
  const price = watch('price');

  const onSubmit = (formData) => {
    if (
      itemSelected?.price &&
      (Math.abs(formData.price - itemSelected.price) * 100) / itemSelected.price >= 15 &&
      formData.price_type === 'price'
    ) {
      setOpenModalWarning(formData);
    } else {
      mutate(formData);
    }
  };

  const onConfirm = useCallback(() => {
    setOpenModalWarning(undefined);
  }, [formDataSubmit]);

  const priceType = watch('price_type');

  useEffect(() => {
    if (priceType !== 'price') {
      setValue('price', itemSelected?.price);
    }
  }, [priceType]);

  useEffect(() => {
    setValue('price', itemSelected?.price);
  }, [itemSelected?.price]);

  useEffect(() => {
    if (page) {
      setFilter({ ...filter, page });
    }
  }, [page]);

  useEffect(() => {
    if (isSuccess) {
      toastSuccess('Gửi yêu cầu đặt lệnh thành công');
      setItemSelected(null);
      refetch();
      setIdRefresh(shortid.generate(4));
    }
    if (error) {
      toastError(error);
    }
  }, [isSuccess, error]);

  return (
    <Container className="transaction-buy">
      <Row>
        <Col
          md="5"
          className="search-ticker"
          style={{ borderRight: '1px solid #14b13b', padding: 0 }}
        >
          <div style={{ padding: 16 }}>
            <InputSearch
              placeholder="Nhập mã CK"
              searchKey={filter.keyword}
              name="keyword"
              setSearchKey={filterOnChange}
              width="100%"
            />
          </div>
          <TableWrapper>
            <DataTableBasic
              conditionalRowStyles={[
                {
                  when: (row) => row.id === itemSelected?.id,
                  style: {
                    background: 'rgba(47, 105, 179, 0.1)',
                  },
                },
              ]}
              onRowClicked={selectItem}
              columns={columns({ itemSelected, setItemSelected: selectItem })}
              data={data}
              fixedHeader
              fixedHeaderScrollHeight="500px"
              isPageEffect
              page={page}
              setPage={setPage}
            />
          </TableWrapper>
        </Col>
        <Col
          md="7"
          className=" bg-buy d-flex justify-content-center flex-column align-items-start"
          style={{ padding: 24 }}
        >
          <div ref={refBuy} className="w-100 h-100">
            {itemSelected && (
              <form className="w-100" onSubmit={handleSubmit(onSubmit)} style={{ height: '100%' }}>
                <BuyAblitity
                  stock_id={itemSelected.id}
                  price={Number(price)}
                  available_quantity={dataAsset?.available_quantity}
                  quantity={dataAsset?.quantity}
                />
                <div className="reponsive-row">
                  <div className="reponsive-col">
                    <TextInput
                      labelCol="12"
                      inputCol="12"
                      type="text"
                      labelClassName="font-size-14 mb-50"
                      labelName="Mã CK *"
                      placeholder="Mã CK"
                      setValue={setValue}
                      name="stock_id"
                      register={{ ...register('stock_id', { required: true }) }}
                      errors={errors}
                      defaultValue={itemSelected ? itemSelected.name : ''}
                      disabled
                    />
                  </div>
                  <div className="reponsive-col">
                    <div className="d-flex align-items-end">
                      <SelectInput
                        styleInput={{
                          borderTopRightRadius: 0,
                          borderBottomRightRadius: 0,
                          borderRightWidth: 0.5,
                          textTransform: 'none',
                        }}
                        labelName="Giá mua *"
                        labelCol="12"
                        inputCol="12"
                        inputClassName="uppercase"
                        options={[
                          {
                            label: 'Giá',
                            value: 'price',
                          },
                          {
                            label: 'MP',
                            value: 'mp',
                          },
                          {
                            label: 'ATO',
                            value: 'ato',
                          },
                          {
                            label: 'ATC',
                            value: 'atc',
                          },
                        ]}
                        placeholder="Giá mua *"
                        errors={errors}
                        labelClassName="font-size-14"
                        name="price_type"
                        register={register('price_type')}
                        setValue={setValue}
                        defaultValue="price"
                      />
                      <TextInput
                        styleInput={{
                          borderTopLeftRadius: 0,
                          borderBottomLeftRadius: 0,
                          borderLeftWidth: 0.5,
                          opacity: priceType !== 'price' ? 0 : 1,
                        }}
                        disabled={priceType !== 'price'}
                        placeholder="Giá mua *"
                        labelCol="12"
                        inputCol="12"
                        type="price"
                        labelClassName="font-size-14 mb-50"
                        setValue={setValue}
                        name="price"
                        register={{ ...register('price', { required: true }) }}
                        errors={errors}
                        defaultValue={price && price !== undefined ? price : itemSelected?.price}
                      />
                    </div>
                  </div>
                  <div className="reponsive-col">
                    <TextInput
                      labelCol="12"
                      inputCol="12"
                      type="price"
                      labelClassName="font-size-14 mb-50"
                      labelName="Khối lượng *"
                      placeholder="Khối lượng *"
                      setValue={setValue}
                      name="quantity"
                      register={{
                        ...register('quantity', {
                          required: true,
                        }),
                      }}
                      errors={errors}
                      defaultValue=""
                    />
                  </div>
                </div>
                <Row>
                  <Col>
                    <TextInput
                      labelCol="12"
                      inputCol="12"
                      type="textarea"
                      labelClassName="font-size-14 mb-50"
                      labelName="Ghi chú "
                      placeholder="Ghi chú "
                      setValue={setValue}
                      name="note"
                      register={{ ...register('note') }}
                      errors={errors}
                    />
                  </Col>
                </Row>
                <ButtonSubmit
                  name="Mua"
                  className="px-2"
                  size="md"
                  color="success"
                  // isLoading={isLoading}
                />
              </form>
            )}
          </div>
        </Col>
      </Row>
      <CustomeModal
        isOpen={!!formDataSubmit}
        confirm={onConfirm}
        closeModal={() => setOpenModalWarning(undefined)}
        type="delete"
        mess={`Giá quý khách nhập (${formatNumber(
          price,
        )}) chênh lệch quá lớn so với giá hiện tại (${formatNumber(
          itemSelected?.price,
        )}). Vui lòng kiểm tra lại khoảng giá?`}
        footer={
          // eslint-disable-next-line react/jsx-wrap-multilines
          <Button color="danger" size="sm" className="mt-1 py-1 px-2 mr-1" onClick={onConfirm}>
            Đồng ý
          </Button>
        }
      />
    </Container>
  );
}

Buy.propTypes = {
  setIdRefresh: PropTypes.func,
};

export default Buy;
