import axiosService from '@Lib/axiosService';
import { handleResponse } from '@Utils/helpers';

let url = '';
if (process.env.DASHBOARD_TYPE === 'admin') {
  url = `${process.env.API_ADMIN_URL}/paybook`;
}

export const loanIndex = async (query) => {
  const { type } = query;
  const urlEnd = type === 'loan' ? '/loan-list' : '/debt-list';
  const res = await axiosService.get(`${url}${urlEnd}`, { ...query, type: undefined });
  return handleResponse(res);
};

export const save = async (dataReq) => {
  const res = await axiosService.post(`${url}/save`, dataReq);
  return handleResponse(res);
};

export const exportLoan = async (filter) => {
  const res = await axiosService.get(`${url}/loan-export`, filter);
  return handleResponse(res);
};

export const expotDebt = async (filter) => {
  const res = await axiosService.get(`${url}/debt-export`, filter);
  return handleResponse(res);
};

export const saveLoan = async (dataReq) => {
  const res = await axiosService.post(`${url}/save-loan`, dataReq);
  return handleResponse(res);
};

export const saveDebt = async (dataReq) => {
  const res = await axiosService.post(`${url}/save-debt`, dataReq);
  return handleResponse(res);
};

export const edit = async (dataReq) => {
  const res = await axiosService.post(`${url}/set-paid`, dataReq);
  return handleResponse(res);
};

export const editLoan = async (dataReq) => {
  const res = await axiosService.post(`${url}/set-paid-loan`, dataReq);
  return handleResponse(res);
};

export const editDebt = async (dataReq) => {
  const res = await axiosService.post(`${url}/set-paid-debt`, dataReq);
  return handleResponse(res);
};

export const deleteLoan = async (dataReq) => {
  const res = await axiosService.post(`${url}/delete`, dataReq);
  return handleResponse(res);
};

export const deleteLoanNew = async (dataReq) => {
  const res = await axiosService.post(`${url}/delete-loan`, dataReq);
  return handleResponse(res);
};

export const deleteLoanDebt = async (dataReq) => {
  const res = await axiosService.post(`${url}/delete-debt`, dataReq);
  return handleResponse(res);
};

export const loanSummary = async (query) => {
  const res = await axiosService.get(`${url}/summary`, query);
  return handleResponse(res);
};

export const loanCustomerSummary = async (query) => {
  const res = await axiosService.get(`${url}/customer-summary`, query);
  return handleResponse(res);
};
