import React from 'react';
import dayjs from 'dayjs';
import { formatNumber } from '@Utils/helpers';

export const columnsLichSuDongViTheTPlus = [
  {
    name: 'Ngày',
    cell: (row) => <div className="">{dayjs.unix(row.unix_time_id).format(' DD/MM/YY')}</div>,
    width: '100px',
    center: true,
  },
  {
    name: 'Mã CK',
    cell: (row) => <div className="">{row.stock_id}</div>,
    width: '100px',
    center: true,
  },
  {
    name: 'Loại GD',
    cell: (row) => <div className=""> {row.trade_type} </div>,
    width: '100px',
    center: true,
  },
  {
    name: 'KL mua',
    cell: (row) => <div className="">{row.total_buy_quantity}</div>,
    width: '130px',
    right: true,
  },
  {
    name: 'Giá mua TB',
    cell: (row) => <div className="">{formatNumber(row?.avg_buy)}</div>,
    width: '130px',
    right: true,
  },
  {
    name: 'KL bán',
    cell: (row) => <div className="">{row.total_sell_quantity}</div>,
    width: '130px',
    right: true,
  },
  {
    name: 'Giá bán TB',
    cell: (row) => <div className="">{formatNumber(row?.avg_sell)}</div>,
    width: '130px',
    right: true,
  },
  {
    name: 'P/L',
    cell: (row) => <div className="">{formatNumber(row?.profit)}</div>,
    width: '130px',
    right: true,
  },
];
