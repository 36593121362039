import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useCustomQuery from '@Utils/useCustomQuery';
import { users, userSummary } from '@Apis/admin/user';
import { Button } from 'reactstrap';
import { Add } from '@mui/icons-material';
import FlatPickrDateRange from '@Components/FlatPickrDateRange';
import InputSearch from '@Components/InputSearch';
import { roleSelect } from '@Apis/admin/role';
import DataTableBasic from '@Components/DataTableBasic';
import NavigationBar from '@Components/NavigationBar';
import FilterComponent from '@Components/FilterComponent';
import { list, employeeExport } from '@Apis/admin/accessRole';
import { columns } from './component/Columns';
import Summary from './component/Summary';
import { TABS } from './constants';

function Employee() {
  const navigate = useNavigate();
  const [activeTabId, setActiveTabId] = useState(0);
  const [filter, setFilter] = useState({
    date_range: null,
    keyword: '',
    status: '',
  });

  const { data } = useCustomQuery(users, filter, true);
  const { data: roles } = useCustomQuery(list, { page_per: 10000 }, true);

  const { data: dataRole } = useCustomQuery(roleSelect);
  const { data: dataSumary } = useCustomQuery(userSummary);

  const filterOnChange = (name, value) => {
    setFilter((prevValues) => {
      return {
        ...prevValues,
        [name]: value,
      };
    });
  };

  useEffect(() => {
    navigate('/employee');
    const activeTab = TABS.find((item) => item.id === activeTabId);
    if (activeTab) {
      const { value } = activeTab;
      filter.status = value;
      setFilter({ ...filter, page: 1 });
    }
  }, [activeTabId]);

  const setOrderBy = useCallback((column, type = '') => {
    setFilter((prevValues) => {
      return {
        ...prevValues,
        sort_by: column.sortField,
        order: type.toUpperCase(),
      };
    });
  }, []);

  const onSearch = useCallback((fil) => {
    setFilter((pre) => ({ ...pre, ...fil }));
  }, []);

  const onExport = useCallback(
    async (fil) => {
      return employeeExport({ ...filter, ...fil });
    },
    [filter],
  );

  return (
    <div className="content-wrapper">
      <div className="d-flex align-items-center justify-content-between header-body mb-2">
        <p className="font-size-28 font-weight-bolder">Nhân viên</p>
        <div className="d-flex align-items-center gap-20">
          <FilterComponent
            onSearch={onSearch}
            onExport={onExport}
            filters={[
              {
                key: 'base_role',
                label: 'Vị trí',
                options: roles,
                labelKey: 'name',
                labelValue: 'id',
              },
            ]}
          />
          <FlatPickrDateRange
            isOpen={false}
            dateRange={filter.date_range}
            setDateRange={filterOnChange}
            className="filter-date-range"
            labelDefault="Chọn ngày"
            name="date_range"
          />
          <InputSearch
            placeholder="Tìm kiếm ID, tên nhân viên"
            searchKey={filter.keyword}
            name="keyword"
            setSearchKey={filterOnChange}
            width="300px"
          />
          <Button color="primary" onClick={() => navigate('/employee/add-new')}>
            <Add size={13} color="white" />
            <span className="font-size-14">Thêm mới</span>
          </Button>
        </div>
      </div>
      <Summary data={dataSumary} />
      <NavigationBar tabs={TABS} activeTab={activeTabId} setActiveTab={setActiveTabId} />
      <DataTableBasic onSort={setOrderBy} columns={columns({ navigate, dataRole })} data={data} />
    </div>
  );
}

Employee.propTypes = {};

export default Employee;
