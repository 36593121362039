/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable prefer-object-spread */
import React, { useState } from 'react';
import Title from '@Components/Title';
import { Modal, Button } from 'reactstrap';
import PropTypes from 'prop-types';
import FlatPickrDateRange from '@Components/FlatPickrDateRange';
import * as XLSX from 'xlsx-js-style';
import html2canvas from 'html2canvas';
import JsPDF from 'jspdf';
import { TYPE_PS } from '@Components/Layouts/PrivateLayout';
import { TABS } from '../../constant';
import BalanceSheet from '../BalanceSheet';
import ProfitLoss from '../ProfitLoss';
import Commission from '../Commission';
import TransactionReport from '../TransactionReport';
import '../../styles.scss';

function ModalExport({ isOpen, closeModal, tab, dateRange, filterOnChange, types }) {
  const tabDetail = TABS.find((item) => item.id === parseInt(tab));
  const [dataExcel, setDataExcel] = useState(null);
  const [productType, setProductType] = useState();

  const handleSetDataExcel = (data, type) => {
    setDataExcel(data);
    setProductType(type);
  };

  const exportReport = () => {
    const funcByTab = {
      2: exportProfitReport,
      3: exportTransactionReport,
      4: exportCommission,
    };
    const funcExport = funcByTab[tab];
    funcExport();
  };

  const exportProfitReport = () => {
    const isPS = productType == TYPE_PS;

    if (isPS) {
      let totalDoanhThuHDKD = 0;
      let totalChiPhiHDKD = 0;

      const { headers, rows } = dataExcel;
      const headerExcel = ['Chi tiêu', 'Tổng', ...headers];
      const dataRows = Object.keys(rows).map((keyRow) => {
        let total = 0;
        if (keyRow === 'DOANH THU HĐKD') {
          total += rows['Phí GD'].data.reduce((a, b) => a + b.total, 0);
          total += rows['Phí QL VT'].data.reduce((a, b) => a + b.total, 0);
          total += rows['Lãi vay ký quỹ'].data.reduce((a, b) => a + b.total, 0);
          totalDoanhThuHDKD = total;
        } else if (keyRow === 'CHI PHÍ HĐKD') {
          total += rows['Phí GD VCD'].data.reduce((a, b) => a + b.total, 0);
          total += rows['Phí QL vị thế VCD'].data.reduce((a, b) => a + b.total, 0);
          total += rows['Phí GD Kho'].data.reduce((a, b) => a + b.total, 0);
          total += rows['Thuế'].data.reduce((a, b) => a + b.total, 0);
          totalChiPhiHDKD = total;
        } else if (keyRow === 'LỢI NHUẬN HĐKD') {
          total = totalDoanhThuHDKD - totalChiPhiHDKD;
        } else if (keyRow === 'CHI PHÍ CHUNG') {
          total += rows['Phí QL TS ký quỹ'].data.reduce((a, b) => a + b.total, 0);
          total += rows['Phí nộp rút tiền'].data.reduce((a, b) => a + b.total, 0);
          total += rows['Phí hoa hồng KD'].data.reduce((a, b) => a + b.total, 0);
        } else {
          total = rows[keyRow]?.data?.reduce((a, b) => a + b.total, 0);
        }
        const obj = Object.assign({}, rows[keyRow].data);
        const newObject = { 0: keyRow, 1: total };
        Object.keys(obj).forEach((k) => {
          let totalCol = 0;
          if (keyRow == 'LỢI NHUẬN HĐKD') {
            totalCol += rows['Phí GD'].data?.[k]?.total || 0;
            totalCol += rows['Phí QL VT'].data?.[k]?.total || 0;
            totalCol += rows['Lãi vay ký quỹ'].data?.[k]?.total || 0;

            totalCol -= rows['Phí GD VCD'].data?.[k]?.total || 0;
            totalCol -= rows['Phí QL vị thế VCD'].data?.[k]?.total || 0;
            totalCol -= rows['Phí GD Kho'].data?.[k]?.total || 0;
            totalCol -= rows['Thuế'].data?.[k]?.total || 0;
          } else {
            totalCol = obj[k].total;
          }

          newObject[String(Number(k + 1)) + 1] = totalCol;
        });
        return newObject;
      });

      const ws = XLSX.utils.json_to_sheet(dataRows);
      const wb = XLSX.utils.book_new();
      ws['!cols'] = [{ width: 50 }];
      XLSX.utils.sheet_add_aoa(ws, [headerExcel], { origin: 'A1' });
      XLSX.utils.book_append_sheet(wb, ws);
      XLSX.writeFile(wb, `${tabDetail.slug}.xlsx`);
    } else {
      let totalDathuchien = 0;
      let totalChuathuchien = 0;
      let totalChiphiTaichinh = 0;
      let totalChiphiHD = 0;

      const { headers, rows } = dataExcel;
      const headerExcel = ['Chi tiêu', 'Tổng', ...headers];
      const dataRows = Object.keys(rows).map((keyRow) => {
        let total = 0;
        if (keyRow === 'DOANH THU ĐÃ THỰC HIỆN') {
          total += rows['Lãi vay'].data.reduce((a, b) => a + b.total, 0);
          total += rows['Phí giao dịch'].data.reduce((a, b) => a + b.total, 0);
          total += rows['Lãi vay cọc thiếu'].data.reduce((a, b) => a + b.total, 0);
          total += rows['Hoa hồng môi giới'].data.reduce((a, b) => a + b.total, 0);
          totalDathuchien = total;
        } else if (keyRow.includes('lũy kế')) {
          total = rows[keyRow]?.data?.[rows[keyRow].data.length - 1]?.total;
        } else if (keyRow === 'TỔNG DOANH THU') {
          total = totalChuathuchien + totalDathuchien;
        } else if (keyRow === 'Phí bán tạm thu') {
          total = rows['Phí bán tạm thu'].data?.[rows['Phí bán tạm thu'].data?.length - 1]?.total;
        } else if (keyRow === 'DOANH THU CHƯA THỰC HIỆN') {
          total = rows['Phí bán tạm thu'].data?.[rows['Phí bán tạm thu'].data?.length - 1]?.total;
          totalChuathuchien = total;
        } else if (keyRow === 'CHI PHÍ TÀI CHÍNH') {
          total += rows['Lãi huy động'].data.reduce((a, b) => a + b.total, 0);
          total += rows['Phí giao dịch trả CTCK'].data.reduce((a, b) => a + b.total, 0);
          total += rows['Lãi vay margin'].data.reduce((a, b) => a + b.total, 0);
          totalChiphiTaichinh = total;
        } else if (keyRow === 'CHI PHÍ HOẠT ĐỘNG') {
          total += rows['Phí Hợp động tư vấn dịch vụ'].data.reduce((a, b) => a + b.total, 0);
          total += rows['Hoa hồng Kho'].data.reduce((a, b) => a + b.total, 0);
          totalChiphiHD = total;
        } else if (keyRow === 'TỔNG CHI PHÍ') {
          total = totalChiphiHD + totalChiphiTaichinh;
        } else if (keyRow === 'LỢI NHUẬN THUẦN TỪ HĐKD') {
          total = totalChuathuchien + totalDathuchien - (totalChiphiHD + totalChiphiTaichinh);
        } else {
          total = rows[keyRow]?.data?.reduce((a, b) => a + b.total, 0);
        }
        const obj = Object.assign({}, rows[keyRow].data);
        const newObject = { 0: keyRow, 1: total };
        Object.keys(obj).forEach((k) => {
          newObject[String(Number(k + 1)) + 1] = obj[k].total;
        });
        return newObject;
      });
      const ws = XLSX.utils.json_to_sheet(dataRows);
      const wb = XLSX.utils.book_new();
      ws['!cols'] = [{ width: 50 }];
      XLSX.utils.sheet_add_aoa(ws, [headerExcel], { origin: 'A1' });
      XLSX.utils.book_append_sheet(wb, ws);
      XLSX.writeFile(wb, `${tabDetail.slug}.xlsx`);
    }
  };

  const exportTransactionReport = () => {
    const isPS = productType == TYPE_PS;
    const { customers, titles } = dataExcel;
    const dateArray = titles?.map((e) => e.label);

    const header = [];
    const titleRow = [];
    titles.map((item) => {
      header.push(isPS ? `${item.total} | ${item.total_quantity_overnight}` : item.total);
      titleRow.push(item.label);
    });
    const headerExcel = isPS
      ? ['TỔNG GTGD PS:', '', '', '', '', ...header]
      : ['TỔNG GTGD Cơ sở:', '', '', ...header];
    const titleExcel = isPS
      ? ['ID', 'Khách hàng', 'Mã sale', 'Tổng vị thế', 'Tổng VT qua đêm', ...titleRow]
      : ['ID', 'Khách hàng', 'Tổng GTGD', ...titleRow];
    const titleExcelObj = Object.assign({}, titleExcel);

    const customersExcell = customers.map((customer) => {
      const { code, username, reports } = customer;
      const arrReport = reports
        .map((item) => {
          if (dateArray.includes(item.date)) {
            return isPS
              ? `${item.total ?? 0} | ${item.total_quantity_overnight ?? 0}`
              : item.total ?? 0;
          }
          return 'not_included';
        })
        .filter((e) => e != 'not_included');
      const customerMapping = isPS
        ? [
          code,
          String(username)?.toUpperCase(),
          '-',
          reports?.reduce?.((a, b) => a + b.total, 0),
          reports?.reduce?.((a, b) => a + b.total_quantity_overnight, 0),
          ...arrReport,
        ]
        : [
          code,
          String(username)?.toUpperCase(),
          reports?.reduce?.((a, b) => a + b.total, 0),
          ...arrReport,
        ];
      const customerExcel = Object.assign({}, customerMapping);
      return customerExcel;
    });
    const dataRows = [titleExcelObj, ...customersExcell];
    const ws = XLSX.utils.json_to_sheet(dataRows);
    const wb = XLSX.utils.book_new();
    ws['!cols'] = [{ width: 20 }, { width: 30 }];
    XLSX.utils.sheet_add_aoa(ws, [headerExcel], { origin: 'A1' });
    XLSX.utils.book_append_sheet(wb, ws);
    XLSX.writeFile(wb, `${tabDetail.slug}.xlsx`);
  };

  const exportCommission = () => {
    const { customers, titles } = dataExcel;
    const header = [];
    const titleRow = [];
    titles.map((item) => {
      header.push(item.total);
      titleRow.push(item.label);
    });
    const headerExcel = ['TỔNG GTGD:', '', ...header];
    const titleExcel = ['STT', 'Khách hàng', 'TỔNG', ...titleRow];
    const titleExcelObj = Object.assign({}, titleExcel);

    const customersExcell = customers.map((customer, index) => {
      const { username, reports } = customer;
      const arrReport = reports.map((item) => item.total);
      const customerMapping = [
        index + 1,
        username?.toUpperCase(),
        reports?.reduce?.((a, b) => a + b.total, 0),
        ...arrReport,
      ];
      const customerExcel = Object.assign({}, customerMapping);
      return customerExcel;
    });
    const dataRows = [titleExcelObj, ...customersExcell];
    const ws = XLSX.utils.json_to_sheet(dataRows);
    const wb = XLSX.utils.book_new();
    ws['!cols'] = [{ width: 20 }, { width: 30 }];
    XLSX.utils.sheet_add_aoa(ws, [headerExcel], { origin: 'A1' });
    XLSX.utils.book_append_sheet(wb, ws);
    XLSX.writeFile(wb, `${tabDetail.slug}.xlsx`);
  };

  const exportPdfBalance = () => {
    const input = document.getElementById('balance-report');
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL('img/png');
      const pdf = new JsPDF('landscape', 'pt', 'a4');
      pdf.addImage(imgData, 'PNG', 0, 0, 1185 * 0.7, 600 * 0.6, '', 'SLOW');
      pdf.save('balance-report.pdf');
    });
  };

  return (
    <Modal style={{ maxWidth: 1185 }} className="modal-export" toggle={closeModal} isOpen={isOpen}>
      <div style={{ padding: '0 1rem' }}>
        <div style={{ textAlign: 'center' }}>
          <Title name={`Xuất báo cáo ${tabDetail.name}`} />
        </div>
        <div
          className={`d-flex align-items-center mt-1 ${
            tab === 1 ? 'justify-content-end ' : 'justify-content-between'
          }`}
        >
          {tab !== 1 && (
            <div className="d-flex justify-content-between align-items-center">
              <span className="font-size-14 mr-50">Thời gian báo cáo</span>
              <FlatPickrDateRange
                isOpen={false}
                dateRange={dateRange}
                setDateRange={filterOnChange}
                className="filter-date-range"
                labelDefault="Chọn ngày"
                name="date_range"
              />
            </div>
          )}
          <div className="d-flex justify-content-between align-items-center">
            {tab === 1 ? (
              <Button outline color="danger" onClick={() => exportPdfBalance()}>
                <div className="">
                  <img src="/images/report/pdf.svg" alt="" />
                  <span className="ml-50">Xuất báo cáo</span>
                </div>
              </Button>
            ) : (
              <Button outline color="primary" onClick={() => exportReport()} disabled={!dataExcel}>
                <div className="">
                  <img src="/images/report/excel.svg" alt="" />
                  <span className="ml-50">Xuất báo cáo</span>
                </div>
              </Button>
            )}
          </div>
        </div>
      </div>
      <div
        className="mt-1"
        style={{
          textAlign: 'center',
          background: 'rgba(47, 105, 179, 0.1)',
          height: 48,
          padding: '14px 0 12px 0',
        }}
      >
        Xem trước
      </div>
      <div className="" id="balance-report">
        <div style={{ textAlign: 'center' }} className="mt-1">
          <Title name={`Báo cáo ${tabDetail.name}`} />
        </div>
        <div style={{ textAlign: 'center', marginTop: 2, fontSize: 14, color: '#000000' }}>
          {/* 18/09/2021 */}
        </div>
        {tab === 1 && <BalanceSheet isExport />}
        {tab === 2 && (
          <ProfitLoss
            dateRange={dateRange}
            isExport
            setDataExcel={(data) => {
              console.log('1212', data);
              handleSetDataExcel(data);
            }}
          />
        )}
        {tab === 3 && (
          <TransactionReport
            dateRange={dateRange}
            setDataExcel={(data) => {
              console.log('222', data);
              handleSetDataExcel(data);
            }}
          />
        )}
        {tab === 4 && (
          <Commission
            dateRange={dateRange}
            isExport
            types={types}
            setDataExcel={(data) => {
              console.log('333', data);
              handleSetDataExcel(data);
            }}
          />
        )}
      </div>
    </Modal>
  );
}
ModalExport.propTypes = {
  isOpen: PropTypes.bool,
  closeModal: PropTypes.func,
  tab: PropTypes.number,
  dateRange: PropTypes.string,
  filterOnChange: PropTypes.func,
  types: PropTypes.string,
};

export default ModalExport;
