export const TABS = [
  {
    id: 0,
    name: 'Hoa hồng',
  },
  {
    id: 1,
    name: 'Thông tin chung',
  },
  {
    id: 2,
    name: 'Phân quyền',
  },
];
