/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { formatVND } from '@Utils/helpers';

export const columns = () => [
  {
    name: 'Khách hàng',
    cell: (row) => <div className="uppercase">{row.username || '-'}</div>,
    left: true,
    width: '200px',
  },
  {
    name: 'Tổng giá trị GD',
    cell: (row) => <div className="uppercase">{formatVND(row.sum) || '-'}</div>,
    right: true,
    width: '180px',
    sortable: true,
    sortField: 'sum',
  },
  {
    name: 'Giá trị CKTB',
    cell: (row) => <div className="uppercase">{formatVND(row.avg) || '-'}</div>,
    right: true,
    width: '150px',
    sortable: true,
    sortField: 'avg',
  },
  {
    name: 'Tổng lãi vay',
    cell: (row) => <div className="uppercase">{formatVND(row.interest_amount) || '-'}</div>,
    right: true,
    width: '150px',
    sortable: true,
    sortField: 'avg',
  },
  {
    name: 'Tổng phí mua',
    cell: (row) => <div className="uppercase">{formatVND(row.buy_fee) || '-'}</div>,
    right: true,
    width: '150px',
    sortable: true,
    sortField: 'avg',
  },
  {
    name: 'Tổng phí bán',
    cell: (row) => <div className="uppercase">{formatVND(row.sell_fee) || '-'}</div>,
    right: true,
    width: '150px',
    sortable: true,
    sortField: 'avg',
  },
];
