import React from 'react';
import { Navigate } from 'react-router-dom';
import PrivateLayout from '@Components/Layouts/PrivateLayout';
import Dashboard from '@Views/Admin/Dashboard';
import Customer from '@Views/Admin/Customer';
import CustomerDetail from '@Views/Admin/CustomerDetail';
import Employee from '@Views/Admin/Employee';
import EmployeeDetail from '@Views/Admin/EmployeeDetail';
import EmployeeRole from '@Views/Admin/EmployeeRole';
import Stock from '@Views/Admin/Stock';
import StockDetail from '@Views/Admin/StockDetail';
import PageNotFound from '@Views/PageNotFound';
import WarehouseAccountDetail from '@Views/Admin/WarehouseAccountDetail';
import WarehouseAccount from '@Views/Admin/WarehouseAccount';
import WarehouseLoan from '@Views/Admin/WarehouseLoan';
import WarehouseSource from '@Views/Admin/WarehouseSource';
import WarehouseAccountStockDetail from '@Views/Admin/WarehouseAccountStockDetail';
import PaymentRequest from '@Views/Admin/PaymentRequest';
import Transaction from '@Views/Admin/Transaction';
import TransactionTeller from '@Views/Admin/TransactionTelles';
import Notification from '@Views/Client/Notification';
import Report from '@Views/Admin/Report';
import Setting from '@Views/Admin/Setting';
import ExportManagement from '@Views/Admin/ExportManagement';
import PurchaseAccountGroup from '@Views/Admin/PurchaseAccountGroup';
import MyExport from '@Views/Admin/MyExport';
import ProductMangement from '@Views/Admin/ProductMangement';
import EmployeeAssignment from '@Views/Admin/EmployeeAssignment';
import TransactionStats from '@Views/Admin/TransactionStats';

export const adminConstant = [
  {
    path: '/',
    element: <PrivateLayout />,
    role: '',
    children: [
      {
        path: '/',
        element: <Navigate to="/dashboard" />,
        hidden: true,
        role: '',
      },
      {
        name: 'Tổng quan',
        path: '/dashboard',
        icon: 'dashboard',
        element: <Dashboard />,
        role: '',
      },
      {
        name: 'Giao dịch (KSV)',
        path: '/transaction',
        icon: 'transaction',
        element: <Transaction />,
        role: 'trade/surveyor-transactions',
      },
      {
        name: 'Giao dịch (GDV)',
        path: '/transaction-tellers',
        icon: 'transaction',
        element: <TransactionTeller />,
        role: 'trade/teller-transactions',
      },
      {
        name: 'Yêu cầu nộp/rút',
        path: '/payment-request',
        icon: 'payment-request',
        element: <PaymentRequest />,
        role: 'payment-request/list',
      },
      {
        name: 'Khách hàng',
        path: '/customer',
        relatedPath: '/customer',
        icon: 'customer',
        role: 'customer/list',
        hasSub: true,
        children: [
          {
            name: 'Khách hàng',
            path: '/customer',
            element: <Customer />,
            role: 'customer/list',
          },
          {
            name: 'QL Gói sản phẩm',
            path: '/customer/manager-product',
            element: <ProductMangement />,
            role: '',
          },
          {
            name: 'Thống kê giao dịch',
            path: '/customer/transaction-stats',
            element: <TransactionStats />,
            role: '',
          },
          {
            path: '/customer/add-new',
            element: <CustomerDetail />,
            name: 'Thêm mới khách hàng',
            role: 'customer/save',
            hidden: true,
          },
          {
            path: '/customer/detail/:customerId',
            element: <CustomerDetail />,
            hidden: true,
            name: 'Chỉnh sửa khách hàng',
            role: 'customer/save',
          },
        ],
      },
      {
        name: 'Danh mục mã CK',
        path: '/stock',
        icon: 'stock',
        relatedPath: '/stock',
        role: ['stock/list'],
        children: [
          {
            path: '/stock',
            element: <Stock />,
            role: 'stock/list',
          },
          {
            path: '/stock/:trade_type/:id',
            element: <StockDetail />,
            role: 'stock/list',
            hidden: true,
          },
        ],
      },
      {
        name: 'Quản lý kho',
        icon: 'warehouse',
        path: '/',
        hasSub: true,
        relatedPath: '/warehouse',
        role: ['purchase-account/list', 'paybook/list'],
        children: [
          {
            name: 'Tài khoản kho',
            path: '/warehouse/account',
            role: ['purchase-account/list'],
            children: [
              {
                path: '/warehouse/account',
                element: <WarehouseAccount />,
                role: 'purchase-account/list',
              },
              {
                path: '/warehouse/account/detail/:warehouseId',
                element: <WarehouseAccountDetail />,
              },
              {
                path: '/warehouse/account/detail/:warehouseId/:stock',
                element: <WarehouseAccountStockDetail />,
              },
            ],
          },
          {
            name: 'Nhóm tài khoản',
            path: '/warehouse/account/group/purchase',
            element: <PurchaseAccountGroup />,
            role: 'purchase-account/group-list',
          },
          {
            name: 'Kho cho vay',
            path: '/warehouse/loan',
            element: <WarehouseLoan />,
            role: 'paybook/loan-list',
          },
          {
            name: 'Kho huy động',
            path: '/warehouse/source',
            element: <WarehouseSource />,
            role: 'paybook/debt-list',
          },
        ],
      },
      {
        name: 'Nhân viên',
        path: '/',
        relatedPath: '/employee',
        hasSub: true,
        icon: 'employee',
        role: ['user/list', 'access-role/list'],
        children: [
          {
            name: 'Danh sách nhân viên',
            path: '/employee',
            role: 'user/list',
            children: [
              {
                path: '/employee',
                element: <Employee />,
              },
              {
                path: '/employee/add-new',
                element: <EmployeeDetail />,
                hidden: true,
              },
              {
                path: '/employee/detail/:employeeId',
                element: <EmployeeDetail />,
                name: 'Chỉnh sửa nhân viên',
                hidden: true,
              },
            ],
          },
          {
            name: 'Phân quyền nhóm',
            path: '/employee/role',
            element: <EmployeeRole />,
            role: 'access-role/list',
          },
          {
            name: 'Quản lý chia lệnh',
            path: '/employee/assignment',
            element: <EmployeeAssignment />,
            role: 'access-role/list',
          },
        ],
      },
      {
        name: 'Thông báo',
        path: '/notification',
        element: <Notification />,
        icon: 'notification',
        role: '',
      },
      {
        name: 'Báo cáo',
        path: '/report',
        element: <Report />,
        icon: 'report',
        role: 'report/get-item',
      },
      {
        name: 'Quản lý xuất file',
        path: '/export-management',
        element: <ExportManagement />,
        icon: 'exportManagement',
        role: 'export/list',
      },
      {
        name: 'File của bạn',
        path: '/my-export-management',
        element: <MyExport />,
        icon: 'exportManagement',
        role: '*',
      },
      {
        name: 'Thiết lập',
        path: '/setting',
        element: <Setting />,
        icon: 'setting',
        role: 'day-off/list',
      },
    ],
  },
  { path: '*', element: <PageNotFound />, role: '' },
];
