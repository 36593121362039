import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { NavLink } from 'reactstrap';
import Tooltip from '@Components/TooltipNew';
import { useSelector } from 'react-redux';

function MenuChild(props) {
  const { notificationCountAdmin } = useSelector(({ ui }) => ({
    notificationCountAdmin: ui.notificationCountAdmin,
  }));
  const { notificationCount } = useSelector(({ ui }) => ({
    notificationCount: ui.notificationCount,
  }));

  const { item, pathname, closeMenu } = props;

  const navigate = useNavigate();

  const handleClickChildItem = (item) => {
    navigate(item.path);
  };

  const isActive =
    pathname === item.path ||
    item.children?.find((e) => {
      return (
        e.path === pathname || (e.path.includes('/:') && pathname.includes(e.path.split(':')[0]))
      );
    });

  useEffect(() => {
    if (isActive) {
      if (process.env.DASHBOARD_TYPE === 'admin') {
        const unread =
          notificationCountAdmin.un_read === undefined ? 0 : notificationCountAdmin.un_read;
        const request =
          (notificationCountAdmin.created_transaction === undefined
            ? 0
            : notificationCountAdmin.created_transaction) +
          (notificationCountAdmin.pending_transaction === undefined
            ? 0
            : notificationCountAdmin.pending_transaction);
        document.title = `(${unread}-${request}) ${item.name} - Admin Vietcaplink FS`;
      } else {
        const unread = notificationCount === undefined ? 0 : notificationCount;
        document.title = `(${unread}) ${item.name} - Vietcaplink`;
      }
    }
  });

  return (
    <li
      className={`nav-item d-flex align-items-center ${isActive ? 'active' : ''} `}
      key={item.id}
      onClick={() => handleClickChildItem(item)}
      aria-hidden="true"
    >
      {closeMenu ? (
        <Tooltip
          align="left"
          content={<div style={{ width: 100 }}>{item.name}</div>}
          style={{ maxWidth: '100%' }}
          trigger="hover"
        >
          <NavLink className="font-size-14">{item.name}</NavLink>
        </Tooltip>
      ) : (
        <NavLink className="font-size-14">{item.name}</NavLink>
      )}
    </li>
  );
}

MenuChild.propTypes = {
  item: PropTypes.object,
  pathname: PropTypes.string,
  closeMenu: PropTypes.bool,
};

export default MenuChild;
