/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable import/extensions */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import 'flatpickr/dist/themes/light.css';
import flatpickr from 'flatpickr/dist/flatpickr.js';
import { Calendar, X } from 'react-feather';
import dayjs from 'dayjs';
import shortid from 'shortid';

import { FlatPickrWrapper } from './styles';

export default function FlatPickrDate({ placeholder, name, date, setDate, className }) {
  const flatPickrId = `flatpickr-${shortid.generate(4)}`;
  useEffect(() => {
    flatpickr(`#${flatPickrId}`, {
      showMonths: 1,
      dateFormat: 'Y-m-d',
      defaultDate: [date ? dayjs.unix(date).format('DD/MM/YYYY') : ''],
      allowInput: true,
      onChange: (selectedDates) => {
        setDate(name, dayjs(selectedDates[0]));
      },
    });
  }, [flatPickrId]);

  return (
    <FlatPickrWrapper className={className}>
      <div id={flatPickrId} className="w-100">
        <div className="d-flex align-items-center justify-content-between w-100">
          <div className="pd-r-20 d-flex">
            <p className="mb-0">{date ? dayjs.unix(date).format('DD/MM/YYYY') : placeholder}</p>
          </div>
          <Calendar className="ml-50" size={14} />
        </div>
      </div>
      {date ? (
        <X color="#eb5757" className="ml-50" size={18} onClick={() => setDate(name, null)} />
      ) : (
        ''
      )}
    </FlatPickrWrapper>
  );
}

FlatPickrDate.propTypes = {
  name: PropTypes.string,
  date: PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.number]),
  setDate: PropTypes.func,
  className: PropTypes.string,
  placeholder: PropTypes.string,
};
