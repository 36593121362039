import React, { useCallback, useEffect, useRef, useState } from 'react';
import { KeyboardArrowDown } from '@mui/icons-material';
import Ptypes from 'prop-types';
import DataTableBasic from '@Components/DataTableBasic';
import { loanIndex } from '@Apis/admin/loan';
import { toastError } from '@Utils/toastrHelper';
import { columns } from '../columns';

let timeout;

function TableDrop(props) {
  const { title, color, style, filter, handleSelect, refReload } = props;
  const [data, setData] = useState({});
  const [page, setPage] = useState(1);

  const [filterSort, setFilterSort] = useState({});

  const refSwap = useRef();
  const refTable = useRef();
  const refSvg = useRef();

  const reload = useCallback(() => {
    loadData();
  }, [page, filter, filterSort]);

  useEffect(() => {
    reload();
  }, [filterSort]);

  useEffect(() => {
    if (refReload) {
      refReload(reload);
    }
  }, [refReload, reload]);

  useEffect(() => {
    if (refSwap.current && refTable.current && refSvg.current) {
      if (refSwap.current.clientHeight > 0) {
        setTimeout(() => {
          const height = refTable.current.clientHeight;
          refSwap.current.style.height = `${height}px`;
          refSvg.current.style.transform = 'rotate(-180deg)';
        }, 0);
      }
    }
  }, [data]);

  useEffect(() => {
    loadData();
  }, [page, ...Object.keys(filter).map((e) => filter[e])]);

  const loadData = useCallback(() => {
    loanIndex({ ...filter, ...filterSort, type: 'debt', per_page: 20, page })
      .then(setData)
      .catch((e) => {
        toastError(e);
      });
  }, [page, filter, filterSort]);

  const toggleOpen = useCallback(() => {
    if (refSwap.current && refTable.current && refSvg.current) {
      if (refSwap.current.clientHeight > 0) {
        refSwap.current.style.height = '0px';
        refSvg.current.style.transform = 'rotate(0deg)';
      } else {
        const height = refTable.current.clientHeight;
        const { y } = refTable.current.getBoundingClientRect();
        refSwap.current.style.height = `${height}px`;
        refSvg.current.style.transform = 'rotate(-180deg)';
        if (timeout) {
          clearTimeout(timeout);
          timeout = undefined;
        }
        timeout = setTimeout(() => {
          timeout = undefined;
          if (y + height > window.innerHeight + window.scrollY) {
            window.scrollTo({
              top: y + height + 80 - window.innerHeight + window.scrollY,
              behavior: 'smooth',
            });
          }
        }, 300);
      }
    }
  }, [refSwap, refTable, refSvg]);

  const handleSelectChange = useCallback(
    (v, r) => {
      handleSelect?.(v, r);
    },
    [handleSelect],
  );

  const sortOrderBy = useCallback((column, type = '') => {
    setFilterSort((prevValues) => {
      return {
        ...prevValues,
        sort_by: column.sortField,
        order: type.toUpperCase(),
      };
    });
  }, []);

  return (
    <div className="table-drop-collapse" style={style}>
      <div className="header-table-collapse" onClick={toggleOpen} role="button" tabIndex="0">
        <KeyboardArrowDown style={{ marginRight: 12 }} ref={refSvg} />
        <span style={{ fontWeight: 600, color }}>{title}</span>
        {data?.data?.length ? (
          <div className="table-collapse-badge" style={{ background: color }}>
            {data?.paging?.total}
          </div>
        ) : null}
      </div>
      <div className="body-table-collapse" ref={refSwap}>
        <div className="swap-table" ref={refTable}>
          <DataTableBasic
            onRowClicked={(record) => {
              handleSelect(undefined, record, true);
            }}
            onSort={sortOrderBy}
            empty={{ style: { minHeight: 300 } }}
            page={page}
            setPage={setPage}
            isPageEffect
            columns={columns(handleSelectChange)}
            data={data}
          />
        </div>
      </div>
    </div>
  );
}

TableDrop.propTypes = {
  title: Ptypes.any,
  color: Ptypes.string,
  handleSelect: Ptypes.func,
  handleRemove: Ptypes.func,
  style: Ptypes.object,
  filter: Ptypes.object,
  refReload: Ptypes.any,
  data: Ptypes.oneOf([Ptypes.array, Ptypes.object]),
};

export default TableDrop;
