import React from 'react';
import PropTypes from 'prop-types';

function PaymentType({ type }) {
  if (type === 'deposit') {
    return (
      <div style={{ background: 'rgba(32, 201, 172, 0.1)' }}>
        <p className="text-success font-size-14  font-weight-bold py-25 px-25">Nộp tiền</p>
      </div>
    );
  }
  return (
    <div style={{ background: 'rgba(252, 52, 0, 0.1)' }}>
      <p className="text-danger font-size-14 font-weight-bold py-25 px-25">Rút tiền</p>
    </div>
  );
}

PaymentType.propTypes = {
  type: PropTypes.string,
};

export default PaymentType;
