import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './styles.scss';
import { formatNumber } from '@Utils/helpers';

class CharJS extends Component {
  constructor(props) {
    super(props);
    this.chartJS = undefined;
  }

  componentDidMount() {
    this.handleChart(this.props);
  }

  UNSAFE_componentWillReceiveProps(nProps) {
    const { data } = nProps;
    if (this.chartJS) {
      this.chartJS.data.labels = data.labels;
      this.chartJS.data.datasets.forEach((dataset, i) => {
        if (i) {
          data.dataPoint2.forEach((e) => {
            dataset.data.push(e);
          });
        } else {
          data.dataPoint.forEach((e) => {
            dataset.data.push(e);
          });
        }
      });
      this.chartJS.update();
    }
  }

  handleChart = (props) => {
    const { data } = props;
    const ctx = document.getElementById('myChart');
    const Chart = window.Chart;
    this.chartJS = new Chart(ctx, {
      type: 'line',
      data: {
        labels: data.labels,
        datasets: [
          {
            label: 'NAV',
            data: data.dataPoint,
            borderColor: '#ED1C24',
            borderWidth: 1,
            fill: false,
            cubicInterpolationMode: 'monotone',
            displayColors: false,
            pointRadius: 0,
          },
          {
            label: 'TLKQ',
            data: data.dataPoint2,
            borderWidth: 1,
            borderColor: '#2F69B3',
            fill: false,
            cubicInterpolationMode: 'monotone',
            displayColors: false,
            pointRadius: 0,
          },
        ],
      },
      options: {
        responsive: true,
        plugins: {
          legend: {
            display: false,
          },
          title: { display: false },
          tooltip: {
            backgroundColor: '#2F69B3',
            yAlign: 'bottom',
            titleAlign: 'center',
            displayColors: false,
            titleColor: '#EBEAED',
            titleFont: {
              weight: 500,
              size: 12,
              family: 'Manrope',
            },
            callbacks: {
              label: (context) => {
                let label = context.dataset.label || '';
                if (label) {
                  label += ': ';
                }
                if (context.parsed.y !== null) {
                  label += `${formatNumber(context.parsed.y)}%`;
                }
                return label;
              },
            },
          },
        },
        interaction: {
          intersect: false,
        },
        scales: {
          x: {
            display: true,
            title: {
              display: true,
            },
          },
          y: {
            display: true,
            title: {
              display: false,
            },
            ticks: {
              callback: (val) => (val ? `${formatNumber(val)}%` : val),
            },
          },
        },
      },
    });
  };

  render() {
    return (
      <div className="charjs-custom" style={{ height: 400 }}>
        <canvas id="myChart" style={{ width: '100%', height: 400, maxHeight: 400 }} />
      </div>
    );
  }
}

CharJS.propTypes = {
  data: PropTypes.object,
  maxRow: PropTypes.number,
  maxColumn: PropTypes.number,
};

export default CharJS;
