/* eslint-disable no-confusing-arrow */
import { formatNumber } from '@Utils/helpers';
import React from 'react';
import { NavLink } from 'reactstrap';

export const columns = ({ navigate, dataRole }) => {
  return [
    {
      name: 'Id',
      cell: (row) => <div className="">{row.id}</div>,
      width: '100px',
      sortable: true,
      center: true,
      sortField: 'id',
    },
    {
      name: 'HỌ TÊN',
      cell: (row) =>
        row.status === 'active' ? (
          <div className="font-weight-bolder uppercase">{row.username}</div>
        ) : (
          <del className="font-weight-bolder uppercase">{row.username}</del>
        ),
      width: '200px',
      sortable: true,
      sortField: 'username',
    },
    {
      name: 'VỊ TRÍ',
      cell: (row) => {
        if (dataRole) {
          const role =
            dataRole && dataRole.length > 0 && dataRole.find((item) => item.id === row.base_role);
          return role?.name;
        }
        return row.base_role;
      },
      sortable: true,
      sortField: 'base_role',
    },
    {
      name: '% HH SALE',
      cell: (row) => <div className="">{row.sales_commission_rate}</div>,
      sortable: true,
      center: true,
      sortField: 'sales_commission_rate',
    },
    {
      name: '% HH KSV',
      cell: (row) => <div className="">{row.surveyor_commission_rate}</div>,
      sortable: true,
      center: true,
      sortField: 'surveyor_commission_rate',
    },
    {
      name: '% HH  GDV',
      cell: (row) => <div className="">{row.tellers_commission_rate}</div>,
      sortable: true,
      center: true,
      sortField: 'tellers_commission_rate',
    },
    {
      name: 'HH SALE',
      cell: (row) => (
        <div className="w-100 text-align-end">{formatNumber(row.salesCommision?.amount)}</div>
      ),
    },
    {
      name: 'HH KSV',
      cell: (row) => (
        <div className="w-100 text-align-end">{formatNumber(row.surveyorCommision?.amount)}</div>
      ),
    },
    {
      name: 'HH GDV',
      cell: (row) => (
        <div className="w-100 text-align-end">{formatNumber(row.tellersCommision?.amount)}</div>
      ),
    },
    {
      name: 'TỔNG HH',
      cell: (row) => (
        <div className="w-100 text-align-end">
          {formatNumber(
            (row.salesCommision !== null ? row.salesCommision.amount : 0) +
              (row.surveyorCommision !== null ? row.surveyorCommision.amount : 0) +
              (row.tellersCommision !== null ? row.tellersCommision.amount : 0),
          )}
        </div>
      ),
    },
    {
      name: '',
      cell: (row) => (
        <NavLink
          className="d-flex align-items-center"
          onClick={() => navigate(`/employee/detail/${row.id}`)}
        >
          <span className="text-danger font-weight-bolder mr-50">Chi tiết</span>
          <img src="/images/icon/arrow-box-red.svg" alt="view-detail" />
        </NavLink>
      ),
    },
  ];
};
