import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import useCustomQuery from '@Utils/useCustomQuery';
import { holdByCustomer } from '@Apis/admin/stock';
import { Row, Col } from 'reactstrap';
import { formatNumber } from '@Utils/helpers';
import { customerDetail } from '@Apis/admin/customer';

function BuyStockInfo({ customerId, stockId }) {
  const [info, setInfo] = useState(null);
  const { data } = useCustomQuery(
    holdByCustomer,
    {
      customer_id: customerId,
      stock_id: stockId,
    },
    false,
    {
      enabled: !!stockId,
    },
  );
  useEffect(() => {
    if (data) {
      setInfo(data);
    }
  }, [data]);

  const { data: customer } = useCustomQuery(
    customerDetail,
    { id: customerId, stock_id: stockId },
    false,
    {
      enabled: !!customerId,
    },
  );

  const getMaxQuantity = () => {
    if (!customer || !info) {
      return '-';
    }
    const total = Math.floor(customer.buy_ability / info.stock.price);

    return formatNumber(total);
  };
  return (
    <div className="pb-2">
      <Row className="pb-1">
        <Col sm="6">Sức mua: {customer ? formatNumber(customer.buy_ability, 0) : '-'}</Col>
        <Col sm="6">Tổng KL: {info ? formatNumber(info.quantity, '0') : '-'}</Col>
      </Row>
      <Row>
        <Col sm="6">KL khả dụng: {info ? formatNumber(info.avaiable_quantity, '0') : '-'}</Col>
        <Col sm="6">KL tối đa: {getMaxQuantity()}</Col>
      </Row>
    </div>
  );
}

BuyStockInfo.propTypes = {
  customerId: PropTypes.any,
  stockId: PropTypes.string,
};

export default BuyStockInfo;
