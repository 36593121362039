import { listDeposit } from '@Apis/profile/bank-account';
import React, { useCallback, useEffect, useState } from 'react';
import { toastError, toastSuccess } from '@Utils/toastrHelper';
import PropTypes from 'prop-types';
import DropdownSelect from '@Components/DropdownSelect';
import FieldInput from '../FieldInput';
import qrScan from './images/qr-scan.png';
import { Col, Row } from 'reactstrap';
import './styles.scss';

function PaymentAutomatic(props) {
  const [bank, setBank] = useState();
  const [bankData, setBankData] = useState({});

  const getBankData = useCallback(() => {
    listDeposit({ bank_id: bank })
      .then((res) => {
        bank
          ? setBankData({
              account_name: 'Nguyen Van A',
              account_number: '0000 0000 0000',
              message_bank: 'ABCDEF12',
              qr_code: 'qr_code_here',
            })
          : null;
      })
      .catch((e) => {
        toastError(e);
      });
  }, [bank]);

  useEffect(() => {
    getBankData();
  }, [bank]);

  // const handleSubmit = useCallback(() => {
  //   if (!amount && amount !== 0) {
  //     setAmount({ errorMessage: 'Vui lòng nhập số tiền đã nộp' });
  //   } else if (listData[active]?.id) {
  //     mutate(
  //       {
  //         customer_id: account.id,
  //         type: 'deposit',
  //         bank_account_id: listData[active]?.id,
  //         amount: Number(amount),
  //         note: '',
  //       },
  //       {
  //         onSuccess: () => {
  //           navigate(`/${productType}/recharge?page=1`);
  //           setAmount({ amount: '' });
  //           refechDateHistory();
  //           toastSuccess('Cập nhật yêu cầu thành công');
  //         },
  //       },
  //     );
  //   }
  // }, [amount, active, listData]);

  return (
    <div className="payment-automatic">
      <Row>
        <Col md="6">
          <FieldInput
            className="automatic-label d-flex align-items-center mb-2"
            styleInput={{ minWidth: 250 }}
            label="Chọn ngân hàng"
            labelDropdown="Ngân hàng: "
            required
            Component={DropdownSelect}
            options={[
              {
                label: 'BIDV',
                value: 1,
              },
              {
                label: 'VCB',
                value: 2,
              },
              {
                label: 'VPBANK',
                value: 3,
              },
            ]}
            valueSelected={bank}
            setValueSelected={(_, v) => setBank(v)}
          />

          <FieldInput
            className="automatic-label d-flex align-items-center"
            value={bankData?.account_name || ''}
            label="Chủ tài khoản:"
            type="text"
            canCopy
            disabled
          />
          <FieldInput
            className="automatic-label d-flex align-items-center"
            value={bankData?.account_number || ''}
            label="Số tài khoản:"
            type="text"
            canCopy
            disabled
          />
          <FieldInput
            className="automatic-label d-flex align-items-center"
            value={bankData?.message_bank || ''}
            label="Nội dung chuyển khoản:"
            type="text"
            canCopy
            disabled
          />
          {bankData?.message_bank && (
            <p className="notice">
              Vui lòng nhập đúng nội dung chuyển khoản để được xác nhận nhanh chóng.
            </p>
          )}
        </Col>
        {bankData?.qr_code && (
          <Col md="6">
            <h4 className="pc-hidden mt-3">Hoặc quét mã QR để thanh toán</h4>
            <div className="d-flex qr-code">
              <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/d/d0/QR_code_for_mobile_English_Wikipedia.svg/440px-QR_code_for_mobile_English_Wikipedia.svg.png" />
              <div className="qr-content__desc">
                <h4 className="mb-hidden">Hoặc quét mã QR để thanh toán</h4>
                <div className="step-description">
                  <span className="step-number">1</span>
                  <span>
                    Mở <b>ứng dụng ngân hàng hỗ trợ VNPAY-QR</b> trên điện thoại
                  </span>
                </div>
                <div className="step-description">
                  <span className="step-number">2</span>
                  <span>
                    Trên ứng dụng chọn tính năng
                    <img style={{ maxWidth: 20, margin: '0 5px' }} src={qrScan} alt="icon" />
                    <b>Quét mã QR</b>
                  </span>
                </div>
                <div className="step-description">
                  <span className="step-number">3</span>
                  <span>Quét mã QR ở trang này và thanh toán</span>
                </div>
              </div>
            </div>
          </Col>
        )}
      </Row>

      <br />
    </div>
  );
}

PaymentAutomatic.propTypes = {
  refechDateHistory: PropTypes.func,
  account: PropTypes.any,
};

export default PaymentAutomatic;
