import React from 'react';
import PropsType from 'prop-types';
import { NavLink } from 'reactstrap';
import { Menu } from 'react-feather';
import { useSelector } from 'react-redux';
import { getLastName } from '@Utils/helpers';
import UserDropdown from './UserDropdown';
import NotificationDropDown from './NotificationDropDown';

function Header({ setMenuVisibility, setShowSelectProduct, showSelectProduct }) {
  const info = useSelector((state) => state.user.info);

  return (
    <div className="header-navbar navbar navbar-expand-lg align-items-center">
      <div className="navbar-container d-flex content">
        <NavLink className="d-block d-lg-none" onClick={() => setMenuVisibility()}>
          <Menu />
        </NavLink>
        <p className="font-size-20 m-0 d-none d-xl-block">
          Chào <span style={{ textTransform: 'uppercase' }}>{getLastName(info.full_name)}</span>,
          chúc bạn một ngày đầu tư hiệu quả!
        </p>

        <ul className="nav navbar-nav align-items-center ml-auto">
          {process.env.DASHBOARD_TYPE === 'admin' ? null : (
            <div
              className="d-flex align-items-center"
              style={{ color: '#2F69B3', cursor: 'pointer', marginRight: 20 }}
              onClick={setShowSelectProduct}
              role="button"
              tabIndex={0}
            >
              <img
                src="/images/logo/select_product.svg"
                alt=""
                style={{
                  transition: 'all 1s',
                  transform: `rotate(${showSelectProduct ? 360 : 0}deg)`,
                }}
              />
              <span style={{ marginLeft: 12, fontSize: 16, fontWeight: 600 }}>Sản phẩm khác</span>
            </div>
          )}
          <NotificationDropDown />
          <UserDropdown user={info} />
        </ul>
      </div>
    </div>
  );
}

Header.propTypes = {
  user: PropsType.object,
  setMenuVisibility: PropsType.func,
  setShowSelectProduct: PropsType.func,
  showSelectProduct: PropsType.bool,
};

export default Header;
