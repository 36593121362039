import styled from 'styled-components';

export const PopupWrrapper = styled.div`
  text-align: left;
  .table {
    text-align: center;
    background-color: #fff;
    th,
    td {
      padding: 10px 0;
      border-bottom: 1px solid #ebe9f1;
    }
    th {
      font-size: 12px;
      border-bottom: 1px solid #ebe9f1;
      border-top: none;
      color: #84818a;
      font-weight: 500;
    }
  }
`;
