/* eslint-disable import/no-cycle */
/* eslint-disable jsx-a11y/anchor-is-valid */
import DatePicker from '@Components/DatePicker';
import Title from '@Components/Title';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { TYPE_CS } from '@Components/Layouts/PrivateLayout';
import Transaction from '@Views/Admin/CustomerDetail/component/Assets/Transaction';
import CardInfo from './CardInfo';
import Info from './Info';
import TableList from './TableList';
import './styles.scss';
import TableListPlus from './TableListPlus';

class AssetManager extends Component {
  handleSetInfo = (res) => {
    this.info?.setState({ responseData: res });
  };

  render() {
    const { productType, type, accounts } = this.props;
    const isAdmin = process.env.DASHBOARD_TYPE === 'admin';
    const prodType = type || productType;
    const account = accounts?.find((e) => e.trade_type === prodType);

    return (
      <div className="asset-management" style={isAdmin ? { padding: 0 } : null}>
        {isAdmin ? null : (
          <div className="header-asset">
            <Title name="Tài sản" />
            <DatePicker.Range placeholder="Chọn ngày" />
          </div>
        )}
        <div className="body-asset position-relative" style={isAdmin ? { paddingTop: 0 } : null}>
          <CardInfo accounts={accounts} type={type} handleSetInfo={this.handleSetInfo} />
        </div>
        <Info
          productType={prodType}
          ref={(ref) => {
            this.info = ref;
          }}
        />

        {isAdmin && (
          <>
            <br />
            <Transaction accountId={account?.id} />
          </>
        )}

        {productType === TYPE_CS ? (
          <TableList />
        ) : (
          <TableListPlus accounts={accounts} type={type} />
        )}
      </div>
    );
  }
}

AssetManager.propTypes = {
  productType: PropTypes.string,
  accounts: PropTypes.any,
  type: PropTypes.string,
};

export default connect(({ ui }) => ({ productType: ui.productType }))(AssetManager);
