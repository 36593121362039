import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { showGlobalLoading, hideGlobalLoading } from '@Lib/slices/uiSlice';
import { useQuery } from 'react-query';

export default function useCustomQuery(
  callApi,
  params,
  isShowGlobalLoading,
  enabled,
  refetchInterval,
) {
  let conditions = {
    refetchOnMount: true,
    refetchOnWindowFocus: false,
    refetchInterval,
    refetchOnReconnect: false,
    retry: 0,
    fetchPolicy: 'no-cache',
  };
  if (enabled) {
    conditions = { conditions, ...enabled };
  }
  const res = useQuery(
    callApi ? [`${callApi.name}`, params] : [],
    () => callApi(params),
    conditions,
  );
  const dispatch = useDispatch();
  const { isLoading } = res;

  useEffect(() => {
    if (isShowGlobalLoading) {
      if (isLoading) {
        dispatch(showGlobalLoading());
      } else {
        dispatch(hideGlobalLoading());
      }
    }
  }, [isLoading]);

  return res;
}
