import React, { useState, useRef, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { usersSelect, userDetail } from '@Apis/admin/user';
import useCustomQuery from '@Utils/useCustomQuery';
import SuggestSearch from '.';

function EmployerSuggest({
  name,
  labelName,
  labelCol,
  labelClassName,
  inputCol,
  selected,
  setSelected,
  errorDetail,
  defaultValue,
  style,
  allowAll,
}) {
  const typingRef = useRef(null);
  const [value, setValue] = useState('');
  const [search, setSearch] = useState('');
  const [suggestions, setSuggestions] = useState([]);

  const getSuggestionValue = (suggestion) => {
    if (suggestion) {
      setSelected(suggestion.id);
    } else {
      setSelected('');
    }
    return suggestion.username;
  };

  useEffect(() => {
    if (!search) {
      setSelected('');
    }
  }, [search]);

  const { data } = useCustomQuery(usersSelect, { keyword: search }, false, {
    enabled: !selected,
  });
  const { data: dataDefault } = useCustomQuery(userDetail, { id: defaultValue }, false, {
    enabled: !search,
  });

  const onChange = useCallback((event, { newValue }) => {
    if (event.type == 'change') setSelected();

    setValue(newValue);
    if (typingRef.current) {
      clearTimeout(typingRef.current);
    }
    typingRef.current = setTimeout(() => {
      setSearch(newValue);
    }, 300);
  }, []);

  useEffect(() => {
    if (data && !selected) {
      setSuggestions(data);
    } else {
      setSuggestions([]);
    }
  }, [data]);

  useEffect(() => {
    if (defaultValue == '*') {
      setValue('Tất cả');
      setSelected('*');
    }

    if (dataDefault) {
      setValue(dataDefault.username);
      setSelected(dataDefault.id);
    }
  }, [dataDefault]);

  const renderSuggestion = (suggestion) => {
    return (
      <div>
        <p className="mb-0 uppercase" style={{ fontSize: 14 }}>
          {suggestion.username}
        </p>
      </div>
    );
  };

  const inputProps = {
    placeholder: 'Chọn nhân viên',
    type: 'search',
    value,
    onChange,
  };

  let suggestionsNew = suggestions;
  if (allowAll) {
    suggestionsNew =
      suggestions.length > 0
        ? [{ username: 'Tất cả', id: '*' }, ...suggestionsNew]
        : suggestionsNew;
  }

  return (
    <SuggestSearch
      allowAll={allowAll}
      style={style}
      name={name}
      labelName={labelName}
      labelCol={labelCol}
      labelClassName={labelClassName}
      inputCol={inputCol}
      errorDetail={errorDetail}
      suggestions={suggestionsNew}
      inputProps={inputProps}
      getSuggestionValue={getSuggestionValue}
      renderSuggestion={renderSuggestion}
    />
  );
}

EmployerSuggest.propTypes = {
  name: PropTypes.string,
  labelName: PropTypes.string,
  labelClassName: PropTypes.string,
  labelCol: PropTypes.string,
  inputCol: PropTypes.string,
  selected: PropTypes.any,
  setSelected: PropTypes.func,
  errorDetail: PropTypes.object,
  style: PropTypes.object,
  defaultValue: PropTypes.any,
  allowAll: PropTypes.bool,
};

export default EmployerSuggest;
