import React, { useState } from 'react';
import NavigationBar from '@Components/NavigationBar';
import { useNavigate, useSearchParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { TABS } from './constants';
import Buy from './components/Buy';
import Sell from './components/Sell';
import { TransactionWrapper } from './styles';
import TradeHistory from './components/TradeHistory';
import './styles.scss';
import { useSelector } from 'react-redux';

function Transaction({ accountId }) {
  // const [activeTabId, setActiveTabId] = useState(2);
  const [idRefresh, setIdRefresh] = useState(false);
  const navigate = useNavigate();
  const productType = useSelector((state) => state.ui.productType);

  const [searchParams] = useSearchParams();

  const tab = Number(searchParams.get('tab')) || 1;

  return (
    <div className="content-wrapper transaction-wrapper">
      <div className="d-flex align-items-center justify-content-between header-body mb-2">
        <p className="font-size-28 font-weight-bolder">Đặt lệnh</p>
      </div>
      <TransactionWrapper>
        <NavigationBar
          className="transaction-navigator-bar"
          tabs={TABS}
          activeTab={tab}
          setActiveTab={(id) => {
            navigate(`/${productType}/transaction?tab=${id}`);
          }}
        />
        {tab === 1 && <Buy setIdRefresh={() => setIdRefresh(!idRefresh)} />}
        {tab === 2 && <Sell setIdRefresh={() => setIdRefresh(!idRefresh)} />}
      </TransactionWrapper>
      <TradeHistory accountId={accountId} idRefresh={idRefresh} />
    </div>
  );
}

Transaction.propTypes = {
  accountId: PropTypes.any,
};

export default Transaction;
