import axiosService from '@Lib/axiosService';
import axios from 'axios';
import { handleResponse } from '@Utils/helpers';

let host;
if (process.env.DASHBOARD_TYPE === 'admin') {
  host = `${process.env.API_ADMIN_URL}`;
} else {
  host = `${process.env.API_CUSTOMER_URL}`;
}

const url = `${host}/auth`;

export const login = (params) => {
  return axiosService.post(`${url}/auth`, params).then((res) => handleResponse(res));
};

export const author = () => {
  const access_token = localStorage.getItem(process.env.TOKEN);
  return axios
    .post(`${url}/author`, {
      access_token,
    })
    .then((res) => handleResponse(res));
};

export const currentRole = () => {
  return axiosService.get(`${url}/my-access-actions`).then((res) => handleResponse(res));
};

export const changePass = (req) => {
  return axiosService.post(`${url}/change-password`, req);
};
