/* eslint-disable react/state-in-constructor */
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { Button } from 'reactstrap';
import { list, currentRole, saveUserAssign } from '@Apis/admin/accessRole';
import { toastError, toastSuccess } from '@Utils/toastrHelper';
import ButtonSubmit from '@Components/ButtonSubmit';
import ContentRolePermission from '../../EmployeeRole/ContentRolePermission';
import './role.scss';

class WrapPramsRole extends Component {
  state = { dataInitListRole: [], loading: true, curRole: {}, currentRoleInit: {} };

  UNSAFE_componentWillMount() {
    this.getList();
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  getList = () => {
    const { params } = this.props;
    Promise.all([list(), currentRole({ id: params.employeeId })])
      .then((data) => {
        this.setState({ dataInitListRole: data[0], curRole: data[1], currentRoleInit: data[1] });
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  };

  handleResize = () => {
    this.divTable.style.height = `${window.innerHeight - 325}px`;
  };

  handleEditRoleCurrent = (checked, id) => {
    const { curRole } = this.state;
    const { roles } = curRole;
    const newRoles = { ...roles };
    if (checked) {
      newRoles[id + 1] = true;
    } else {
      delete newRoles[id + 1];
    }
    this.setState({ curRole: { ...curRole, roles: newRoles } });
  };

  handleSave = () => {
    const { curRole, currentRoleInit } = this.state;
    const { params } = this.props;
    saveUserAssign({ user: { id: params.employeeId, ...curRole } })
      .then(() => {
        this.setState({ currentRoleInit: curRole });
        toastSuccess('Lưu phân quyền user thành công');
      })
      .catch((e) => {
        toastError(e);
        this.setState({ curRole: currentRoleInit });
      });
  };

  handleChangeRoleUser = (checked, role) => {
    const { curRole } = this.state;
    if (checked) {
      this.setState({ curRole: { ...curRole, assign: { ...curRole.assign, [role]: true } } });
    } else {
      const { curRole } = this.state;
      const { assign } = curRole;
      const newassign = { ...assign };
      delete newassign[role];
      this.setState({ curRole: { ...curRole, assign: newassign } });
    }
  };

  handleCheckAllRoleUser = (checked, list) => {
    const assign = {};
    const { curRole } = this.state;
    if (checked) {
      list.forEach((el) => {
        el.accessActions.forEach((k) => {
          assign[k.id] = true;
        });
      });
    }
    this.setState({ curRole: { ...curRole, assign } });
  };

  render() {
    const { dataInitListRole, loading, curRole, currentRoleInit } = this.state;
    if (loading) {
      return null;
    }
    return (
      <>
        <div className="role">
          <ContentRolePermission
            handleCheckAllRoleUser={this.handleCheckAllRoleUser}
            handleChangeRoleUser={this.handleChangeRoleUser}
            setEdit={this.handleEditRoleCurrent}
            allowEdit={false}
            rolesUser={curRole}
            dataInitListRole={dataInitListRole}
          />
        </div>
        <div className="d-flex justify-content-center mt-1 mb-1">
          <Button color="primary" className="font-size-14 " onClick={this.handleSave}>
            Lưu thay đổi
          </Button>
          <ButtonSubmit
            onClick={() => {
              this.setState({ curRole: currentRoleInit });
            }}
            name="Huỷ"
            outline
            style={{ background: '#fff', marginLeft: 15 }}
            className="font-size-14 "
          />
        </div>
      </>
    );
  }
}

function Role() {
  const params = useParams();
  return <WrapPramsRole params={params} />;
}

WrapPramsRole.propTypes = {
  params: PropTypes.object,
};

export default Role;
