import Title from '@Components/Title';
import React, { useCallback, useState } from 'react';
import { Button } from 'reactstrap';
import { AddCircleOutline } from '@mui/icons-material';
import NavigationBar from '@Components/NavigationBar';
import DataTableBasic from '@Components/DataTableBasic';
import useCustomQuery from '@Utils/useCustomQuery';
import { memberPackages } from '@Apis/admin/memberPackage';
import { columns } from './columns';
import ModalAddCS from './ModalAddCS';
import ModalAddPS from './ModalAddPS';
import './styles.scss';
import ModalConfigPackage from './ModalConfigPackage';

function ProductMangement() {
  const [activeTabId, setActiveTab] = useState('base');
  const [itemConfig, setItemConfig] = useState({});
  const [isOpenAddCs, setIsOpenCs] = useState(false);
  const [isOpenAddSpot, setIsOpenSpot] = useState(false);
  const [isOpenAddPs, setIsOpenPs] = useState(false);
  const [itemEdit, setItemEdit] = useState({});

  const { data, refetch } = useCustomQuery(
    memberPackages,
    { per_page: 100000000, trade_type: activeTabId },
    true,
  );

  const onConfig = useCallback(
    (item) => {
      setItemConfig(item);
    },
    [activeTabId],
  );

  const onEdit = useCallback(
    (item) => {
      setItemEdit(item);
      if (item.trade_type === 'base') {
        setIsOpenCs(true);
      } else if (item.trade_type === 'spot') {
        setIsOpenSpot(true);
      } else {
        setIsOpenPs(true);
      }
    },
    [activeTabId],
  );

  const onClose = useCallback(() => {
    setIsOpenPs(false);
    setIsOpenCs(false);
    setIsOpenSpot(false);
    setItemEdit({});
    setItemConfig({});
    refetch();
  }, []);

  return (
    <div className="product-management">
      <div className="d-flex align-items-center justify-content-between">
        <Title name="QL Gói sản phẩm" />
        <div className="d-flex align-items-center" style={{ gap: 16 }}>
          <Button color="primary" onClick={() => setIsOpenCs(true)}>
            <AddCircleOutline color="primay" fontSize="14px" />
            <span className="font-size-13 ml-25">Thêm gói Cơ sở</span>
          </Button>
          <Button color="primary" onClick={() => setIsOpenSpot(true)}>
            <AddCircleOutline color="primay" fontSize="14px" />
            <span className="font-size-13 ml-25">Thêm gói T+</span>
          </Button>
          <Button color="danger" onClick={() => setIsOpenPs(true)}>
            <AddCircleOutline color="primay" fontSize="14px" />
            <span className="font-size-13 ml-25">Thêm gói Phái sinh</span>
          </Button>
        </div>
      </div>
      <div className="mt-1">
        <NavigationBar
          setActiveTab={(v) => {
            setActiveTab(v);
            setItemEdit({});
          }}
          style={{ background: 'transparent', paddingLeft: 0 }}
          activeTab={activeTabId}
          tabs={[
            { id: 'base', name: 'Cơ sở' },
            { id: 'spot', name: 'T+' },
            { id: 'derivative', name: 'Phái sinh' },
          ]}
        />
      </div>
      <div className="mt-1">
        <DataTableBasic hasFixed columns={columns(activeTabId, onEdit, onConfig)} data={data} />
      </div>
      {isOpenAddCs ? (
        <ModalAddCS init={itemEdit} modalType="base" isOpen={isOpenAddCs} onClose={onClose} />
      ) : null}
      {isOpenAddSpot ? (
        <ModalAddCS init={itemEdit} modalType="spot" isOpen={isOpenAddSpot} onClose={onClose} />
      ) : null}
      {isOpenAddPs ? <ModalAddPS init={itemEdit} isOpen={isOpenAddPs} onClose={onClose} /> : null}

      {Object.keys(itemConfig).length > 0 && (
        <ModalConfigPackage
          packageConfig={itemConfig}
          isOpen
          onClose={onClose}
          tradeType={activeTabId}
        />
      )}
    </div>
  );
}

export default ProductMangement;
