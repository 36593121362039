import React from 'react';
import PropTypes from 'prop-types';

function TableEmpty(props) {
  const { style, text } = props;
  return (
    <div className="empty-datatable" style={style}>
      <div style={{ textAlign: 'center' }}>
        {/* <img src="/images/page/empty-table.svg" alt="empty" /> */}
        <div className="text-center font-size-20 color-matte-gray">{text || 'Chưa có dữ liệu'}</div>
      </div>
    </div>
  );
}

TableEmpty.propTypes = {
  style: PropTypes.object,
  text: PropTypes.any,
};

export default TableEmpty;
