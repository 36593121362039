/* eslint-disable react/jsx-wrap-multilines */
import { usersSelect } from '@Apis/admin/user';
import DropdownSelect from '@Components/DropdownSelect';
import useCustomQuery from '@Utils/useCustomQuery';
import FieldInput from '@Views/Client/Recharge/BodyTab/Request/FieldInput';
import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

function SelectUser(props) {
  const {
    value,
    onChange,
    api,
    labelKey = 'username',
    labelValue = 'id',
    label = 'Nhân viên',
    disabled,
    style,
    required,
    errorMessage,
    filter = {},
    placeholder = 'Chọn nhân viên',
  } = props;
  const { data } = useCustomQuery(api || usersSelect, filter, false);

  return (
    <FieldInput
      errorMessage={errorMessage}
      className="select-user"
      required={required}
      component={
        <DropdownSelect
          allowSearch
          placeholder={placeholder}
          style={style}
          disabled={disabled}
          setValueSelected={(_, v) => onChange?.(v)}
          valueSelected={value}
          styleMenu={{ maxHeight: 200, overflow: 'auto', width: '100%' }}
          options={data}
          labelKey={labelKey}
          labelValue={labelValue}
          labelShow={false}
        />
      }
      label={label}
    />
  );
}

SelectUser.propTypes = {
  onChange: PropTypes.func,
  api: PropTypes.func,
  labelKey: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.any,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  labelValue: PropTypes.string,
  value: PropTypes.any,
  errorMessage: PropTypes.any,
  style: PropTypes.object,
  filter: PropTypes.object,
};

export default SelectUser;
