/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { commissionReport } from '@Apis/admin/report';
import useCustomQuery from '@Utils/useCustomQuery';
import TableEmpty from '@Components/DataTableBasic/TableEmpty';
import { Container, Table } from 'reactstrap';
import { formatNumber } from '@Utils/helpers';
import { TableWrapper } from '../../styled';

function Commission({ dateRange, types, setDataExcel, view_by }) {
  const { data, refetch } = useCustomQuery(
    commissionReport,
    {
      date_range: dateRange,
      view_by,
      types,
    },
    true,
  );

  useEffect(() => {
    refetch();
  }, [dateRange]);

  useEffect(() => {
    if (data && setDataExcel) {
      setDataExcel(data);
    }
  }, [data, setDataExcel]);

  const total = data?.titles?.reduce?.((a, b) => a + b.total, 0);

  return (
    <Container>
      {data ? (
        <TableWrapper style={{ marginLeft: 540 }}>
          <Table>
            <thead>
              <tr>
                <th
                  className="headcol font-size-16 font-weight-bold color-black"
                  style={{ height: '45px' }}
                />
                <th
                  className="headcol font-size-12 text-center"
                  style={{
                    textAlign: 'center',
                    boxShadow: '7px 0px 15px rgb(153 153 153 / 12%)',
                    left: 290,
                    margin: 0,
                  }}
                >
                  Tổng
                </th>
                {data.titles &&
                  data.titles.length > 0 &&
                  data.titles.map((item) => (
                    <th key={item.label} className="text-center">
                      {item.label}
                    </th>
                  ))}
              </tr>
            </thead>
            <tbody>
              <tr className="" style={{ background: '#375c8a' }}>
                <th className="headcol sub-head" style={{ background: '#375c8a', color: '#fff' }}>
                  <div className="d-flex align-items-center">
                    <span className="" style={{ color: '#fff' }}>
                      STT
                    </span>
                    <span className="">KHÁCH HÀNG</span>
                  </div>
                </th>
                <th
                  style={{
                    boxShadow: '7px 0px 15px rgb(153 153 153 / 12%)',
                    left: 290,
                    margin: 0,
                    height: 45,
                    background: '#375c8a',
                    color: '#fff',
                    paddingRight: 100,
                    textAlign: 'end',
                  }}
                  className="headcol sub-head"
                >
                  <span style={{ fontSize: 14, color: '#fff' }}>
                    {formatNumber(`${total}`, '--')}
                  </span>
                </th>
                {data.titles &&
                  data.titles.length > 0 &&
                  data.titles.map((item) => (
                    <th key={item.to} style={{ color: '#fff', textAlign: 'end' }}>
                      {formatNumber(item.total, '--')}
                    </th>
                  ))}
              </tr>
              {data.customers &&
                data.customers.length > 0 &&
                data.customers.map((item, index) => {
                  const { reports } = item;
                  const total = reports?.reduce?.((a, b) => {
                    return a + b.total;
                  }, 0);
                  return (
                    <tr
                      className=""
                      key={index.toString()}
                      style={{ background: !(index % 2) ? '#f8f8f8' : '#fff' }}
                    >
                      <th
                        className="headcol sub-head"
                        style={{ background: !(index % 2) ? '#f8f8f8' : '#fff' }}
                      >
                        <div className="d-flex align-items-center">
                          <span className="">{index + 1}</span>
                          <span className="color-black uppercase">{item.username}</span>
                        </div>
                      </th>
                      <th
                        className="headcol headcol-child"
                        style={{
                          boxShadow: '7px 0px 15px rgb(153 153 153 / 12%)',
                          left: 290,
                          margin: 0,
                          background: !(index % 2) ? '#f8f8f8' : '#fff',
                          textAlign: 'end',
                          paddingRight: 100,
                        }}
                      >
                        {formatNumber(total)}
                      </th>
                      {reports &&
                        reports.length > 0 &&
                        reports.map((report, i) => {
                          return (
                            <td key={i.toString()} style={{ textAlign: 'end' }}>
                              {formatNumber(report.total)}
                            </td>
                          );
                        })}
                    </tr>
                  );
                })}
            </tbody>
          </Table>
        </TableWrapper>
      ) : (
        <TableEmpty />
      )}
    </Container>
  );
}

Commission.propTypes = {
  dateRange: PropTypes.string,
  types: PropTypes.string,
  view_by: PropTypes.any,
  setDataExcel: PropTypes.func,
};

export default Commission;
