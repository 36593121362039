// import Chart from '@Views/Client/Dashboard/Chart';
import React, { useState } from 'react';
import Title from '@Components/Title';
import ButtonSubmit from '@Components/ButtonSubmit';
import { holdByCustomer, stocks } from '@Apis/admin/stock';
import useCustomQuery from '@Utils/useCustomQuery';
import { overview } from '@Apis/admin/dashboard';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import NavigationBar from '@Components/NavigationBar';
import DataTableBasic from '@Components/DataTableBasic';
import CardInfo from './CardInfo';
import StatisRepo from './StatisRepo';
import ListDashboard from './ListDashboard';
import PopUpCreate from '../Transaction/components/PopUpCreate';
import './styles.scss';
import TableTPlusDOM from '@Views/Client/AssetManager/TableListPlus/TableTPlus/TableTPlusDOM';
import { TYPE_CS, TYPE_PS, TYPE_TPLUS } from '@Components/Layouts/PrivateLayout';
import { tplusActiveColumns } from '../Stock/components/TplusActiveStockColumns';
import { columnsAdminViTheMoPS } from '@Views/Client/TransactionPS/columns';
import { columnsDashboardPS } from './columnsDashboardPS';
import { defaultPaging } from '@Views/Client/TransactionTPlus';
import {
  dataStateBase1,
  dataStateBase2,
  dataStateBase3,
  dataStateDerivative1,
  dataStateDerivative2,
  dataStateDerivative3,
  dataStateSpot1,
  dataStateSpot2,
} from './dataState';

export const TAB_PANEL = [
  { id: 1, name: 'Tổng quan' },
  { id: 2, name: 'CK Cơ sở' },
  { id: 3, name: 'CK T+' },
  { id: 4, name: 'CK Phái sinh' },
];

function Dashboard() {
  const warehouse = useSelector((state) => state.transaction.warehouse);
  const tellers = useSelector((state) => state.transaction.tellers);
  const [isOpenPopUpCreate, setIsOpenPopupCreate] = useState(false);
  const [activeTab, setActiveTab] = useState(1);
  const [page, setPage] = useState(0);

  const { data } = useCustomQuery(
    overview,
    {
      trade_type:
        activeTab === 4 ? TYPE_PS : activeTab === 3 ? TYPE_TPLUS : activeTab === 2 ? TYPE_CS : '',
    },
    false,
    null,
    6000,
  );

  const { data: dataStocksTPlus } = useCustomQuery(stocks, { trade_type: TYPE_TPLUS }, true);
  const { data: dataStocksPS } = useCustomQuery(stocks, { trade_type: TYPE_PS }, true);
  const { data: dataPositionTPlus } = useCustomQuery(
    holdByCustomer,
    { trade_type: TYPE_TPLUS },
    true,
  );
  const { data: dataPositionPS } = useCustomQuery(holdByCustomer, { trade_type: TYPE_PS }, true);

  const dataDerivative = {};
  if (activeTab === 4 && dataPositionPS) {
    dataPositionPS.forEach((row) => {
      if (!dataDerivative[row.stock_id] || dataDerivative[row.stock_id].length == 0) {
        dataDerivative[row.stock_id] = [];
      }
      dataDerivative[row.stock_id].push(row);
    });
  }

  const navigate = useNavigate();

  return (
    <div id="dashboard" className="dashboard asset-management dashboard-admin">
      <div className="header-asset mb-1">
        <Title name="Tổng quan" />
        <div className="button-search">
          <ButtonSubmit
            onClick={() => setIsOpenPopupCreate(true)}
            name="Lập lệnh"
            className="px-2"
            size="md"
            color="primary"
            icon={<img src="/images/dashboard/swap_horiz.svg" alt="" />}
          />
        </div>
      </div>
      <NavigationBar activeTab={activeTab} tabs={TAB_PANEL} setActiveTab={setActiveTab} />

      {(activeTab == 1 || activeTab == 2) && (
        <>
          <CardInfo
            styleMoney={{
              color:
                data?.total_stock_value !== data?.total_stock_value_purchase_account ||
                data?.total_stock_value !== data?.total_stock_value_stocks ||
                data?.total_stock_value_purchase_account !== data?.total_stock_value_stocks
                  ? '#ea5455'
                  : '#2e2c34',
            }}
            dataState={dataStateBase1(data)}
          />
          <CardInfo
            styleMoney={{
              color:
                data?.total_stock_value !== data?.total_stock_value_purchase_account ||
                data?.total_stock_value !== data?.total_stock_value_stocks ||
                data?.total_stock_value_purchase_account !== data?.total_stock_value_stocks
                  ? '#ea5455'
                  : '#2e2c34',
            }}
            dataState={dataStateBase2(data)}
            style={{ paddingTop: 5 }}
          />
          <CardInfo
            style={{ paddingTop: 5 }}
            dataState={dataStateBase3(data)}
            styleMoney={{
              color:
                data?.total_stock_value !== data?.total_stock_value_purchase_account ||
                data?.total_stock_value !== data?.total_stock_value_stocks ||
                data?.total_stock_value_purchase_account !== data?.total_stock_value_stocks
                  ? '#ea5455'
                  : '#2e2c34',
            }}
          />
        </>
      )}

      {activeTab == 3 && (
        <>
          <CardInfo dataState={dataStateSpot1(data)} />
          <CardInfo dataState={dataStateSpot2(data)} style={{ paddingTop: 5 }} />
        </>
      )}

      {activeTab == 4 && (
        <>
          <CardInfo dataState={dataStateDerivative1(data)} />
          <CardInfo dataState={dataStateDerivative2(data)} style={{ paddingTop: 5 }} />
          <CardInfo dataState={dataStateDerivative3(data)} style={{ paddingTop: 5 }} />
        </>
      )}

      {(activeTab == 1 || activeTab == 2) && (
        <div className="swap-transaction-listMenu">
          <StatisRepo />
        </div>
      )}
      <div>
        {(activeTab === 1 || activeTab === 2) && (
          <>
            <br />
            <ListDashboard filterAdmin={{ found_only: 1 }} api={stocks} title="Danh mục mã CK" />
          </>
        )}
        {(activeTab === 1 || activeTab === 3) && (
          <div>
            <br />
            <h4>Danh sách mã hoạt động T+</h4>
            <DataTableBasic
              defaultSortFieldId="id"
              defaultSortAsc
              columns={tplusActiveColumns().filter(Boolean)}
              data={dataStocksTPlus}
              isPageEffect
              page={page}
              setPage={setPage}
            />

            <br />
            <h4>Danh sách vị thế T+</h4>
            <TableTPlusDOM data={dataPositionTPlus} />
          </div>
        )}
        {(activeTab === 1 || activeTab === 4) && (
          <>
            <br />
            <h4>Danh sách hợp đồng PS</h4>
            <DataTableBasic
              defaultSortFieldId="id"
              defaultSortAsc
              columns={columnsDashboardPS()}
              data={dataStocksPS}
              isPageEffect
              page={page}
              setPage={setPage}
            />

            {Object.keys(dataDerivative || {}).length > 0 ? (
              <>
                {Object.keys(dataDerivative || {}).map((stockId) => {
                  const rows = dataDerivative[stockId];
                  return (
                    <div key={stockId}>
                      <br />
                      <br />
                      <h4>Danh sách vị thế hợp đồng {stockId}</h4>
                      <DataTableBasic
                        setPage={setPage}
                        page={page}
                        data={{ data: rows || [], paging: defaultPaging }}
                        columns={columnsAdminViTheMoPS}
                      />
                    </div>
                  );
                })}
              </>
            ) : null}
          </>
        )}
      </div>
      {isOpenPopUpCreate && (
        <PopUpCreate
          isOpen
          closeModal={() => setIsOpenPopupCreate(false)}
          refetch={() => {
            navigate('/transaction');
          }}
          setIsRefreshStatus={() => null}
          optionsWarehouse={[
            {
              label: 'Chọn',
              value: 0,
            },
            ...warehouse,
          ]}
          optionsTeller={[
            {
              label: 'Chọn',
              value: 0,
            },
            ...tellers,
          ]}
        />
      )}
    </div>
  );
}

export default Dashboard;
