/* eslint-disable import/no-cycle */
import { TYPE_CS, TYPE_HOME, TYPE_PS, TYPE_TPLUS } from '@Components/Layouts/PrivateLayout';
import { createSlice, createSelector } from '@reduxjs/toolkit';

const urlProductType = window.location.pathname.split('/')[1];
const typeArray = [TYPE_CS, TYPE_TPLUS, TYPE_PS];

const uiSlice = createSlice({
  name: 'ui',
  initialState: {
    isGlobalLoading: false,
    sidebar: [],
    notificationCount: 0,
    notificationCountAdmin: {
      un_read: 0,
      created_transaction: 0,
      pending_transaction: 0,
    },
    productType:
      (urlProductType && typeArray.includes(urlProductType) ? urlProductType : null) || TYPE_HOME,
  },
  reducers: {
    pushNotificaiton: (state, action) => {
      state.notificationCount = action.payload;
    },
    pushNotificaitonAdmin: (state, action) => {
      state.notificationCountAdmin = action.payload;
    },
    showGlobalLoading: (state) => {
      state.isGlobalLoading = true;
    },
    hideGlobalLoading: (state) => {
      state.isGlobalLoading = false;
    },
    setProductType: (state, action) => {
      state.productType = action.payload;
    },
    setSideBar: (state, action) => {
      const sidebar = action.payload;
      state.sidebar = sidebar;
    },
    sidebarActive: (state, action) => {
      const sidebarId = action.payload;

      const { sidebar } = state;
      const setActiveSidebar = sidebar.map((item) => {
        if (!item) return item;
        if (item.id === sidebarId) {
          item.isActive = true;
          item.isOpen = true;
        } else {
          item.isActive = false;
          item.isOpen = false;
        }

        if (item.child) {
          const activeChild = item.children.map((child) => {
            const isActive = false;
            return { ...child, isActive };
          });
          item.child = activeChild;
        }

        return item;
      });
      state.sidebar = [...setActiveSidebar];
    },
    toggleMenuHasChild: (state, action) => {
      const sideBarItem = action.payload;
      const { sidebar } = state;
      const setActiveSidebar = sidebar.map((item) => {
        if (item.id === sideBarItem.id) {
          item.isOpen = !item.isOpen;
        } else {
          item.isOpen = false;
        }
        return item;
      });
      state.sidebar = [...setActiveSidebar];
    },
    sidebarChildActive: (state, action) => {
      const { parentId, childId } = action.payload;
      const { sidebar } = state;
      const setActiveSidebar = sidebar.map((item) => {
        if (item.id === parentId) {
          item.isOpen = true;
          item.isActive = true;
          const activeChild = item.children.map((child) => {
            let isActive = false;
            if (child.id === childId) {
              isActive = true;
            }
            return { ...child, isActive };
          });
          item.children = activeChild;
        } else {
          item.isOpen = false;
          item.isActive = false;
        }
        return item;
      });

      state.sidebar = [...setActiveSidebar];
    },
  },
});

const { reducer } = uiSlice;

export const selectUi = createSelector(
  (state) => ({
    isGlobalLoading: state.ui.isGlobalLoading,
  }),
  (state) => state,
);

export const {
  showGlobalLoading,
  hideGlobalLoading,
  setSideBar,
  sidebarActive,
  toggleMenuHasChild,
  sidebarChildActive,
  pushNotificaiton,
  pushNotificaitonAdmin,
  setProductType,
} = uiSlice.actions;

export default reducer;
