import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Container, Row, Col, Button } from 'reactstrap';
import InputSearch from '@Components/InputSearch';
import useCustomQuery from '@Utils/useCustomQuery';
import { stockCanSell, sellStock, sellByStock } from '@Apis/client/transaction';
import { stockAsset } from '@Apis/client/stock';
import DataTableBasic from '@Components/DataTableBasic';
import TextInput from '@Components/Input/TextInput';
import ButtonSubmit from '@Components/ButtonSubmit';
import { useForm } from 'react-hook-form';
import SelectInput from '@Components/Input/SelectInput';
import useCustomMutation from '@Utils/useCustomMuation';
import { toastSuccess, toastError } from '@Utils/toastrHelper';
import CustomeModal from '@Components/CustomeModal';
import { formatNumber } from '@Utils/helpers';
import PropTypes from 'prop-types';
import { TableWrapper } from '../styles';
import { columns } from './SellColumns';

function Sell({ setIdRefresh }) {
  const [itemSelected, setItemSelected] = useState(null);
  const [page, setPage] = useState(0);
  const [filter, setFilter] = useState({
    keyword: '',
    per_page: 1000,
  });
  const refBuy = useRef();
  const [formDataSubmit, setOpenModalWarning] = useState();
  const { data } = useCustomQuery(stockCanSell, filter, true);
  const { data: dataAsset = {}, refetch } = useCustomQuery(
    stockAsset,
    filter,
    // { stock_id: itemSelected?.stock_id },
    // true,
    // {
    //   enabled: !!itemSelected,
    // },
  );

  useEffect(() => {
    if (page) {
      setFilter({ ...filter, page });
    }
  }, [page]);

  const filterOnChange = (name, value) => {
    setFilter((prevValues) => {
      return {
        ...prevValues,
        [name]: value,
        page: 1,
      };
    });
  };

  const selectItem = useCallback((item) => {
    setItemSelected(item);
    if (item) {
      if (window.innerWidth < 768) {
        if (window.scrollY < refBuy.current?.getBoundingClientRect()?.y) {
          window.scrollTo(0, refBuy.current?.getBoundingClientRect()?.y);
        }
      }
    }
  }, []);

  const getCallSell = (item) => {
    if (item?.available_quantity <= 0) {
      return 0;
    }
    const pending =
      itemSelected.sellingQuantity === undefined || itemSelected.sellingQuantity === null
        ? 0
        : itemSelected.sellingQuantity.selling_quantity;
    return item.available_quantity - pending;
  };

  const toggleItem = (item) => {
    if (item.id === itemSelected?.id) {
      selectItem(null);
    } else {
      selectItem(item);
    }
  };

  const { mutate, isSuccess, error } = useCustomMutation(sellByStock, true);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm({ defaultValues: {} });
  const price = watch('price');
  const priceType = watch('price_type');
  useEffect(() => {
    if (priceType !== 'price') {
      setValue('price', itemSelected?.stock?.price);
    }
  }, [priceType]);

  useEffect(() => {
    setValue('price', itemSelected?.stock?.price);
  }, [itemSelected?.stock?.price]);

  const onSubmit = (formData) => {
    formData.id = itemSelected.id;
    if (
      itemSelected?.price &&
      (Math.abs(formData.price - itemSelected.stock.price) * 100) / itemSelected.stock.price >=
        15 &&
      formData.price_type === 'price'
    ) {
      setOpenModalWarning(formData);
    } else {
      mutate(formData);
    }
  };

  const onConfirm = useCallback(() => {
    setOpenModalWarning(undefined);
  }, [formDataSubmit]);

  useEffect(() => {
    if (isSuccess) {
      toastSuccess('Gửi yêu cầu đặt lệnh thành công');
      setItemSelected(null);
      setIdRefresh();
      refetch();
    }
    if (error) {
      toastError(error);
    }
  }, [isSuccess, error]);

  return (
    <Container className="transaction-sell">
      <Row className="">
        <Col md="8" style={{ borderRight: '1px solid #fc3400', padding: 0 }}>
          <div style={{ padding: 16, paddingBottom: 0 }}>
            <InputSearch
              placeholder="Nhập mã CK"
              searchKey={filter.keyword}
              name="keyword"
              setSearchKey={filterOnChange}
              width="100%"
            />
          </div>
          <p className="pt-1 pb-50 font-weight-bolder" style={{ padding: '0 16px' }}>
            Danh sách mã có thể bán
          </p>
          <TableWrapper>
            <DataTableBasic
              conditionalRowStyles={[
                {
                  when: (row) => row.id === itemSelected?.id,
                  style: {
                    background: 'rgba(47, 105, 179, 0.1)',
                  },
                },
              ]}
              onRowClicked={toggleItem}
              columns={columns({ itemSelected, toggleItem })}
              // data={data?.filter(
              //   (e) => (e?.totaAvailabilityQuantity?.quantity || 0) - (e?.sell_quantity || 0) > 0,
              // )}
              data={{
                ...dataAsset,
                data: dataAsset.assets?.filter(
                  (e) => e.available_quantity - (e.lock_quantity || 0),
                ),
              }}
              fixedHeader
              fixedHeaderScrollHeight="500px"
              isPageEffect
              page={page}
              setPage={setPage}
            />
          </TableWrapper>
        </Col>
        <Col md="4" className="pt-1 pb-1 bg-sell d-flex justify-content-center flex-column">
          <div ref={refBuy} className="w-100 h-100">
            {itemSelected && (
              <form onSubmit={handleSubmit(onSubmit)} style={{ height: '100%' }}>
                <div className="pb-2">
                  <Row className="pb-1">
                    <Col sm="6">Tổng KL: {formatNumber(itemSelected?.quantity, '0')}</Col>
                    <Col sm="6">KL khả dụng: {formatNumber(getCallSell(itemSelected), '0')}</Col>
                  </Row>
                </div>
                <div className="reponsive-row">
                  <div className="reponsive-col">
                    <TextInput
                      labelCol="12"
                      inputCol="12"
                      type="text"
                      labelClassName="font-size-14 mb-50"
                      labelName="Mã CK *"
                      placeholder="Mã CK"
                      setValue={setValue}
                      name="stock_id"
                      register={{ ...register('stock_id') }}
                      errors={errors}
                      defaultValue={itemSelected.stock_id || ''}
                      disabled
                    />
                  </div>
                  <div className="reponsive-col">
                    <div className="d-flex align-items-end">
                      <SelectInput
                        styleInput={{
                          borderTopRightRadius: 0,
                          borderBottomRightRadius: 0,
                          borderRightWidth: 0.5,
                          textTransform: 'none',
                          minWidth: 65,
                        }}
                        labelName="Giá bán *"
                        labelCol="12"
                        inputCol="12"
                        inputClassName="uppercase"
                        options={[
                          {
                            label: 'Giá',
                            value: 'price',
                          },
                          {
                            label: 'MP',
                            value: 'mp',
                          },
                          {
                            label: 'ATO',
                            value: 'ato',
                          },
                          {
                            label: 'ATC',
                            value: 'atc',
                          },
                        ]}
                        placeholder="Giá bán *"
                        errors={errors}
                        labelClassName="font-size-14"
                        name="price_type"
                        register={register('price_type')}
                        setValue={setValue}
                        defaultValue="price"
                      />
                      <TextInput
                        styleInput={{
                          borderTopLeftRadius: 0,
                          borderBottomLeftRadius: 0,
                          borderLeftWidth: 0.5,
                          opacity: priceType !== 'price' ? 0 : 1,
                        }}
                        labelCol="12"
                        inputCol="12"
                        type="price"
                        labelClassName="font-size-14 mb-50"
                        placeholder="Giá bán *"
                        setValue={setValue}
                        name="price"
                        register={{ ...register('price', { required: true }) }}
                        errors={errors}
                        defaultValue={price !== undefined ? price : itemSelected?.price}
                      />
                    </div>
                  </div>
                  <div className="reponsive-col">
                    <TextInput
                      labelCol="12"
                      inputCol="12"
                      type="price"
                      labelClassName="font-size-14 mb-50"
                      labelName="Khối lượng *"
                      placeholder="Khối lượng *"
                      setValue={setValue}
                      name="quantity"
                      register={{
                        ...register('quantity', {
                          required: true,
                          validate: (value) => {
                            if (
                              value >
                              (itemSelected?.available_quantity || 0) -
                                (itemSelected?.sell_quantity || 0)
                            ) {
                              return 'Không thể lớn hơn KL khả dụng';
                            }
                          },
                        }),
                      }}
                      errors={errors}
                    />
                  </div>
                </div>
                <Row>
                  <Col>
                    <TextInput
                      labelCol="12"
                      inputCol="12"
                      type="textarea"
                      labelClassName="font-size-14 mb-50"
                      labelName="Ghi chú "
                      placeholder="Ghi chú "
                      setValue={setValue}
                      name="note"
                      register={{ ...register('note') }}
                      errors={errors}
                    />
                  </Col>
                </Row>
                <ButtonSubmit
                  name="Bán"
                  className="px-2"
                  size="md"
                  color="danger"
                  // isLoading={isLoading}
                />
              </form>
            )}
          </div>
        </Col>
      </Row>
      <CustomeModal
        isOpen={!!formDataSubmit}
        confirm={onConfirm}
        closeModal={() => setOpenModalWarning(undefined)}
        type="delete"
        mess={`Giá quý khách nhập (${formatNumber(
          price,
        )}) chênh lệch quá lớn so với giá hiện tại (${formatNumber(
          itemSelected?.stock?.price,
        )}). Vui lòng kiểm tra lại khoảng giá?`}
        footer={
          // eslint-disable-next-line react/jsx-wrap-multilines
          <Button color="danger" size="sm" className="mt-1 py-1 px-2 mr-1" onClick={onConfirm}>
            Đồng ý
          </Button>
        }
      />
    </Container>
  );
}

Sell.propTypes = {
  setIdRefresh: PropTypes.func,
};

export default Sell;
