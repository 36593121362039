import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Container } from 'reactstrap';
import { formatVND } from '@Utils/helpers';
import { SummaryWrapper } from './styles';

function WarehouseSummary({ data }) {
  return (
    <SummaryWrapper>
      <Container className="with-border y-2 bg-white" style={{ marginBottom: 5 }}>
        <Row>
          <Col className="p-1">
            <div className="d-flex align-items-center mb-1">
              <p className="font-weight-bold font-size-12 color-matte-gray">TỔNG GIÁ TRỊ CK</p>
            </div>
            <p className="font-size-28 font-weigth-bolder">{formatVND(data.stock_value)}</p>
          </Col>
          <Col className="p-1">
            <div className="d-flex align-items-center mb-1">
              <p className="font-weight-bold font-size-12 color-matte-gray">TỔNG NAV</p>
            </div>
            <p className="font-size-28 font-weigth-bolder">{formatVND(data.total_nav)}</p>
          </Col>
          <Col className="p-1">
            <div className="d-flex align-items-center mb-1">
              <p className="font-weight-bold font-size-12 color-matte-gray">TỔNG NỢ MARGIN</p>
            </div>
            <p className="font-size-28 font-weigth-bolder">{formatVND(data.margin_debt)}</p>
          </Col>
          <Col className="p-1">
            <div className="d-flex align-items-center mb-1">
              <p className="font-weight-bold font-size-12 color-matte-gray">TÔNG SỨC MUA TỐI ĐA</p>
            </div>
            <p className="font-size-28 font-weigth-bolder">{formatVND(data.buy_ability)}</p>
          </Col>
        </Row>
      </Container>
      <Container className="y-2">
        <Row className="gap-20">
          <Col className="p-1 bg-white">
            <div className="d-flex align-items-center mb-1">
              <p className="font-weight-bold font-size-12 color-matte-gray">
                TỔNG TIỀN BÁN CHỜ VỀ CHƯA ỨNG
              </p>
            </div>
            <p className="font-size-28 font-weigth-bolder">{formatVND(data.wait_come_amount)}</p>
          </Col>
          <Col className="p-1 bg-white">
            <div className="d-flex align-items-center mb-1">
              <p className="font-weight-bold font-size-12 color-matte-gray">TỔNG CỔ TỨC TIỀN</p>
            </div>
            <p className="font-size-28 font-weigth-bolder">{formatVND(data.dividend_amount)}</p>
          </Col>
          <Col className="p-1 bg-white">
            <div className="d-flex align-items-center mb-1">
              <p className="font-weight-bold font-size-12 color-matte-gray">
                TỔNG GIÁ TRỊ GIAO DỊCH THÁNG
              </p>
            </div>
            <p className="font-size-28 font-weigth-bolder">
              {formatVND(data.total_month_trading_value)}
            </p>
          </Col>
          <Col className="p-1 bg-white">
            <div className="d-flex align-items-center mb-1">
              <p className="font-weight-bold font-size-12 color-matte-gray">
                TỔNG GIÁ TRỊ GIAO DỊCH LŨY KẾ
              </p>
            </div>
            <p className="font-size-28 font-weigth-bolder">{formatVND(data.total_trading_value)}</p>
          </Col>
        </Row>
      </Container>
    </SummaryWrapper>
  );
}

WarehouseSummary.propTypes = {
  data: PropTypes.object,
};

export default WarehouseSummary;
