import styled from 'styled-components';

export const TableWrapper = styled.div`
  margin-top: 32px;
  max-width: 100%;
  overflow-x: auto;
  margin-left: 480px;
  overflow-y: visible;
  background-color: white;
  /* margin-left: 10px; */
  table {
    margin-bottom: 0;
    td,
    th {
      /* white-space: nowrap; */
      margin: 0;
      font-size: 14px;
      font-weight: 500;
    }
    .headcol {
      position: absolute;
      width: 290px;
      left: 0;
      top: auto;
      border-top-width: 2px;
      margin-left: 40px;
      /* background-color: #fff; */
      border: none;
      box-shadow: 7px 0px 15px rgba(153, 153, 153, 0.12);
    }
    .headcol.title {
      color: #2e2c34;
      font-weight: 700;
    }
    .headcol.child:before {
      padding-left: 5px;
      content: '• ';
    }
    .headcol-child.child:before {
      padding-left: 5px;
      content: '';
    }
    thead {
      .headcol {
        border-bottom: 1px solid #ebeaed !important;
        padding-bottom: 15px;
      }
    }
    tbody {
      .headcol {
        border-bottom: 1px solid #ebeaed !important;
      }
    }
    .sub-head {
      span:first-child {
        width: 40%;
        color: #84818a;
      }
      span:last-child {
        width: 60%;
      }
    }
  }
`;

export const ReportWrapper = styled.div`
  .tooltip-custom-wrap {
    min-width: 250px;
  }
`;
