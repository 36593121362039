import React from 'react';
import PropTypes from 'prop-types';
import {
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
  Button,
} from 'reactstrap';
import { Square } from '@mui/icons-material';
import { canEditTransactionTellers } from '@Utils/tradeHelpers';
import { TRADE_STATUS } from '../../constants';

function TransactionButton({ changeStatus, status, itemTransaction }) {
  const renderMenu = () => {
    const statusMenu = [itemTransaction.status, 'expired', 'cancelled'];
    const dropdownMenu = statusMenu.map((statusItem) => {
      if (statusItem !== status) {
        const { is_cancel_request } = itemTransaction;
        let statusObj;
        if (is_cancel_request && (statusItem === 'pending' || statusItem === 'in_process')) {
          statusObj = TRADE_STATUS.find((item) => item.value === 'request_cancel');
        } else {
          statusObj = TRADE_STATUS.find((item) => item.value === statusItem);
        }

        return (
          <DropdownItem
            className="w-100"
            onClick={() => changeStatus(statusItem)}
            key={statusObj.label}
          >
            <Square
              style={{
                color: statusObj.color,
              }}
            />
            <span
              className="align-middle font-size-14 font-weight-bolder ml-50 text-uppercase"
              style={{ color: statusObj.color }}
            >
              {statusObj.label}
            </span>
          </DropdownItem>
        );
      }
    });
    return dropdownMenu;
  };

  if (!status) {
    return '';
  }
  let objStatus = {
    color: status,
    label,
    value,
  };
  const { is_cancel_request } = itemTransaction;
  if (is_cancel_request) {
    objStatus = TRADE_STATUS.find((item) => item.value === 'request_cancel');
  } else {
    objStatus = TRADE_STATUS.find((item) => item.value === status);
  }
  const { color, label, value } = objStatus;

  return canEditTransactionTellers(itemTransaction) && !is_cancel_request ? (
    <UncontrolledDropdown className="nav-item">
      <DropdownToggle
        color="none"
        style={{ background: color }}
        className="font-size-14 text-white text-uppercase"
        onClick={(e) => e.preventDefault()}
        caret
      >
        {label}
      </DropdownToggle>
      <DropdownMenu right>{renderMenu()}</DropdownMenu>
    </UncontrolledDropdown>
  ) : (
    <Button className="text-white" color="none" style={{ background: color }}>
      {label}
    </Button>
  );
}

TransactionButton.propTypes = {
  status: PropTypes.string,
  item: PropTypes.object,
  changeStatus: PropTypes.func,
  itemTransaction: PropTypes.object,
};

export default TransactionButton;
