import React, { useCallback, useMemo, useRef, useState } from 'react';
import { Row, Col } from 'reactstrap';
import PropType from 'prop-types';
import TextInput from '@Components/MUInput/TextInput';
import { useForm } from 'react-hook-form';
import ButtonSubmit from '@Components/ButtonSubmit';
import dayjs from 'dayjs';
import './styles.scss';
import { upload } from '@Apis/client/upload';
import { toastError, toastSuccess } from '@Utils/toastrHelper';

const arrayFieldProfile = [
  { title: 'Họ tên', name: 'full_name' },
  { title: 'Mã khách hàng', name: 'code' },
  { title: 'Email', name: 'email' },
  { title: 'Số điện thoại', name: 'phone' },
  {
    title: 'Ngày sinh',
    name: 'birthday',
    render: (v) => (v ? dayjs(v).format('DD/MM/YYYY') : undefined),
  },
  { title: 'Địa chỉ', name: 'address' },
  { title: 'CMT', name: 'identity_card' },
  {
    title: 'Ngày đăng ký',
    name: 'member_at',
    render: (v) => (v ? dayjs(v).format('DD/MM/YYYY') : undefined),
  },
];

function Info({ profile }) {
  const [file, setFile] = useState();
  const [loading, setLoading] = useState(false);

  const defaultValues = useMemo(() => {
    const data = {};
    arrayFieldProfile.forEach((key) => {
      data[key.name] = key.render ? key.render(profile[key.name]) : profile[key.name];
    });
    return data;
  }, [profile]);

  const { register } = useForm({ defaultValues });
  const refInputFile = useRef();

  const handleUploadFile = useCallback(() => {
    refInputFile.current.click?.();
  }, [refInputFile]);

  const handleChangeFile = useCallback((e) => {
    const image = e.target.files[0];
    if (image && image.type.includes('image/')) {
      if (image.size > 1000000) {
        return toastError('Chỉ có thể upload file bé hơn 1MB');
      }
      setFile(image);
    }
    e.target.value = '';
  }, []);

  const handleSave = useCallback(async () => {
    setLoading(true);
    const formData = new FormData();
    formData.append('file', file);
    await upload(formData).then(toastSuccess).catch(toastError);
    setLoading(false);
    setFile();
  }, [file]);

  if (!profile) {
    return null;
  }

  const url = file && URL.createObjectURL(file);

  return (
    <div className="profile">
      <div className="profile-title">Thông tin cá nhân</div>
      <Row className="profile-detail">
        <Col md="4" className="avatar-before mt-2">
          <div className="profile-avatar">
            {/* <img alt="" src={} /> */}
            <div
              className="img"
              style={{
                backgroundImage: `url(${
                  url || profile.avatar_url || '/images/profile/defaultAvatar.svg'
                })`,
              }}
            />
            <div className="mt-1 font-size-12">Dung lượng tối đa 1MB</div>
            <div style={{ marginTop: 16 }}>
              <ButtonSubmit
                style={{ width: '100%' }}
                outline
                styleText={{ fontSize: 14 }}
                name="Tải ảnh"
                size="md"
                color="primary"
                onClick={handleUploadFile}
              />
            </div>
            <div className="button-remove-avatar mb-3">
              <ButtonSubmit
                onClick={() => setFile()}
                outline
                styleText={{ fontSize: 14 }}
                name="Xoá ảnh"
                size="md"
                bordered={false}
              />
            </div>
          </div>
        </Col>
        <Col md="8">
          <form>
            {arrayFieldProfile.map((f) => (
              <TextInput
                key={f.name}
                labelName={f.title}
                placeholder={f.title}
                name={f.name}
                disabled
                register={register(f.name)}
              />
            ))}
          </form>
        </Col>
        <Col md="4" className="avatar-after">
          <div className="profile-avatar">
            {/* <img alt="" src={url || profile.avatar_url || '/images/profile/defaultAvatar.svg'} /> */}
            <div
              className="img"
              style={{
                backgroundImage: `url(${
                  url || profile.avatar_url || '/images/profile/defaultAvatar.svg'
                })`,
              }}
            />
            <div className="mt-1 font-size-12">Dung lượng tối đa 1MB</div>
            <div style={{ marginTop: 16 }}>
              <ButtonSubmit
                style={{ width: '100%' }}
                outline
                styleText={{ fontSize: 14 }}
                name="Tải ảnh"
                size="md"
                color="primary"
                onClick={handleUploadFile}
              />
            </div>
            <div className="button-remove-avatar">
              <ButtonSubmit
                onClick={() => setFile()}
                outline
                styleText={{ fontSize: 14 }}
                name="Xoá ảnh"
                size="md"
                bordered={false}
              />
            </div>
          </div>
        </Col>
      </Row>
      <div className="profile-footer">
        <ButtonSubmit
          styleText={{ fontSize: 14, textAlign: 'center' }}
          name="Lưu thay đổi"
          size="md"
          color="primary"
          onClick={handleSave}
          disabled={!file}
          isLoading={loading}
        />
        <ButtonSubmit
          style={{ marginLeft: 16 }}
          outline
          styleText={{ fontSize: 14, color: '#2E2C34' }}
          name="Huỷ"
          size="md"
          onClick={() => setFile()}
        />
      </div>
      <input
        accept="image/*"
        onChange={handleChangeFile}
        ref={refInputFile}
        type="file"
        style={{ display: 'none' }}
      />
    </div>
  );
}

Info.propTypes = {
  profile: PropType.object,
};

export default Info;
