import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from '@mui/material';

class NameCheckbox extends Component {
  render() {
    const { name, onChange, listRole, data = [], checkUser, allowEdit, checkedAll } = this.props;
    const checked =
      data?.length && data.every((elem) => elem.accessActions.every((e) => listRole[e.id]));
    return (
      <div style={{ margin: '0 8px' }}>
        <div className="text-ellipsis" style={{ paddingBottom: 4, textAlign: 'center' }}>
          {name}
        </div>
        <div className="d-flex justify-content-center align-items-center">
          <Checkbox
            onChange={onChange}
            style={{ padding: 0 }}
            size="small"
            checked={(allowEdit && !!checked) || (!allowEdit && checkUser) || checkedAll}
          />
        </div>
      </div>
    );
  }
}

NameCheckbox.propTypes = {
  name: PropTypes.string,
  allowEdit: PropTypes.bool,
  checkedAll: PropTypes.bool,
  checkUser: PropTypes.bool,
  data: PropTypes.array,
  onChange: PropTypes.func,
  listRole: PropTypes.object,
};

export default NameCheckbox;
