import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
  Button,
} from 'reactstrap';
import { Square } from '@mui/icons-material';
import { TRADE_STATUS } from '../../views/Admin/Transaction/constants';

function TransactionStatusButton({ changeStatus, status, itemTransaction }) {
  if (!status) {
    return '';
  }
  const objStatus = TRADE_STATUS.find((item) => item.value === status);
  if (!objStatus) {
    return '';
  }
  const { color, label } = objStatus;

  return itemTransaction.status === 'created' ? (
    <UncontrolledDropdown className="nav-item">
      <DropdownToggle
        color="none"
        style={{ background: color }}
        className="font-size-14 text-white text-uppercase"
        onClick={(e) => e.preventDefault()}
        caret
      >
        {label}
      </DropdownToggle>
      <DropdownMenu right>
        {status !== 'created' && (
          <DropdownItem className="w-100" onClick={() => changeStatus('created')}>
            <Square color="primary" />
            <span className="align-middle font-size-14 font-weight-bolder ml-50 text-primary">
              CHỜ DUYỆT
            </span>
          </DropdownItem>
        )}
        {status !== 'expired' && (
          <DropdownItem className="w-100" onClick={() => changeStatus('expired')}>
            <Square color="disable" />
            <span className="align-middle font-size-14 font-weight-bolder ml-50 text-secondary">
              HẾT HẠN
            </span>
          </DropdownItem>
        )}
        {status !== 'cancelled' && (
          <DropdownItem className="w-100" onClick={() => changeStatus('cancelled')}>
            <Square color="error" />
            <span className="align-middle font-size-14 font-weight-bolder ml-50 text-danger">
              HỦY
            </span>
          </DropdownItem>
        )}
      </DropdownMenu>
    </UncontrolledDropdown>
  ) : (
    <Button className="text-white" color="none" style={{ background: color }}>
      {label}
    </Button>
  );
}

TransactionStatusButton.propTypes = {
  status: PropTypes.string,
  itemTransaction: PropTypes.object,
  changeStatus: PropTypes.func,
};

export default TransactionStatusButton;
