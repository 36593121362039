import React, { useEffect, useMemo, useState } from 'react';
import Title from '@Components/Title';
import ButtonSubmit from '@Components/ButtonSubmit';
import { useNavigate } from 'react-router-dom';
import { io } from 'socket.io-client';
import { useSelector } from 'react-redux';
import { TYPE_TPLUS } from '@Components/Layouts/PrivateLayout';
import { accountsHome } from '@Apis/client/home';
import CardInfo from '../AssetManager/CardInfo';
import './styles.scss';
import Chart from './Chart';
import TransactionDashboard from './Transaction';
import TableListDashboard from './Tablelist';

function Dashboard() {
  const navigate = useNavigate();

  const productType = useSelector((state) => state.ui.productType);
  const [dataAccounts, setDataAccounts] = useState({ accounts: [] });
  const account = useMemo(
    () => dataAccounts?.accounts?.find((e) => e.trade_type === productType),
    [dataAccounts],
  );

  useEffect(async () => {
    const data = await accountsHome();
    setDataAccounts(data);
  }, []);

  useEffect(async () => {
    const data = await accountsHome();
    setDataAccounts(data);
  }, []);

  return (
    <div className="dashboard asset-management">
      <div className="header-asset">
        <Title name="Tổng quan" />
        <div className="button-search">
          <ButtonSubmit
            onClick={() => navigate(`/${productType}/transaction`)}
            name="Đặt lệnh"
            className="px-2"
            size="md"
            color="primary"
            icon={<img src="/images/dashboard/swap_horiz.svg" alt="" />}
          />
          <ButtonSubmit
            onClick={() => navigate(`/${productType}/recharge`)}
            color="none"
            style={{ marginLeft: 12, background: '#14B13B', color: '#fff' }}
            name="Nộp/rút tiền"
            className="px-2"
            size="md"
            icon={<img src="/images/dashboard/swap_vert.svg" alt="" />}
          />
        </div>
      </div>
      <div className="body-asset">
        {dataAccounts?.accounts && <CardInfo accounts={dataAccounts?.accounts} />}
      </div>
      {productType !== TYPE_TPLUS ? <Chart accountId={account?.id} /> : null}
      <div className="swap-transaction-listMenu">
        <TransactionDashboard accountId={account?.id} />
        <TableListDashboard accountId={account?.id} />
      </div>
    </div>
  );
}

export default Dashboard;
