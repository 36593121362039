import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, Row, Col, Container } from 'reactstrap';
import { renderType } from '@Utils/tradeHelpers';
import { formatNumber, formatVND } from '@Utils/helpers';
import useCustomMutation from '@Utils/useCustomMuation';
import { transactionConfirm } from '@Apis/admin/transaction';
import { toastError, toastSuccess } from '@Utils/toastrHelper';
import { useSelector } from 'react-redux';

function PopUpConfirm({ isOpen, closeModal, item, refetch, setIsRefreshStatus }) {
  const { mutate, isSuccess, error } = useCustomMutation(transactionConfirm, true);
  const warehouse = useSelector((state) => state.transaction.warehouse);

  const getWareHouseName = () => {
    const warehouseSelected = warehouse.find(
      (obj) => obj.id === parseInt(item.itemConfirm.purchase_account_id),
    );
    return warehouseSelected.name;
  };

  const confirmTransaction = () => {
    const { id } = item.transaction;
    const { quantity, price, purchase_account_id, is_completed } = item.itemConfirm;
    const dataConfirm = {
      id,
      quantity,
      price,
      purchase_account_id,
      is_completed,
    };
    mutate(dataConfirm);
  };

  useEffect(() => {
    if (isSuccess) {
      toastSuccess('Duyệt lệnh thành công');
      closeModal();
      refetch();
      setIsRefreshStatus({ state: true });
    }
    if (error) {
      toastError(error);
    }
  }, [isSuccess, error]);

  return (
    <Modal isOpen={isOpen} toggle={closeModal} className="text-center modal-confirm-transaction">
      <Container>
        <div className="d-flex align-items-center justify-content-between">
          <div className="w-100">
            <p className="font-size-24 font-weight-bolder text-center pt-1 color-dark-gray">
              XÁC NHẬN GIAO DỊCH
            </p>
            <p className="text-center font-size-24 font-weight-normal py-2">
              <span className="uppercase">{item?.transaction?.publicCustomer?.username} </span>
              <span className="color-matte-gray">({item?.transaction?.publicCustomer?.code})</span>
            </p>
            <div className="d-flex align-items-center justify-content-center gap-20">
              <div className="color-matte-gray font-size-14">LOẠI LỆNH</div>
              <div className="text-uppercase">{renderType(item.transaction.type)}</div>
            </div>
          </div>
        </div>
        <div className="confirm-content pt-2">
          <Row>
            <Col className="mr-3">
              <p className="text-danger font-weight-bolder text-left pb-1">
                KIỂM SOÁT VIÊN YÊU CẦU
              </p>
              <div className="d-flex pb-1 justify-content-between">
                <p className="font-weight-bold color-matte-gray">CỔ PHIẾU:</p>
                <p className="font-weight-bolder">{item?.transaction?.stock_id}</p>
              </div>
              <div className="d-flex pb-1 justify-content-between">
                <p className="font-weight-bold color-matte-gray">KHỐI LƯỢNG:</p>
                <p className="">{formatVND(item?.transaction?.quantity)}</p>
              </div>
              <div className="d-flex pb-1 justify-content-between">
                <p className="font-weight-bold color-matte-gray">GIÁ MUA:</p>
                <p className="">{formatNumber(item?.transaction?.price)}</p>
              </div>
            </Col>
            <Col className="pl-3">
              <p className="text-success font-weight-success text-left pb-1">
                LỆNH BẠN ĐÃ THỰC HIỆN
              </p>
              <div className="d-flex pb-1 justify-content-between">
                <p className="font-weight-bold color-matte-gray">CỔ PHIẾU:</p>
                <p className="font-weight-bolder">{item?.transaction?.stock_id}</p>
              </div>
              <div className="d-flex pb-1 justify-content-between">
                <p className="font-weight-bold color-matte-gray">KL KHỚP:</p>
                <p className="text-success">{formatVND(item?.itemConfirm?.quantity)}</p>
              </div>
              <div className="d-flex pb-1 justify-content-between">
                <p className="font-weight-bold color-matte-gray">GIÁ KHỚP</p>
                <p className="text-success">{formatNumber(item?.itemConfirm?.price)}</p>
              </div>
            </Col>
          </Row>
          <Row className="">
            <div
              className="w-100"
              style={{ background: '#F2F2F2', margin: '20px', padding: '10px' }}
            >
              <span className="mr-1 color-matte-gray font-weight-normal">KHO DT:</span>
              <span className="color-dark-gray font-weight-normal">
                {item.transaction.purchaseAccount
                  ? item.transaction.purchaseAccount.name
                  : getWareHouseName()}
              </span>
            </div>
          </Row>
        </div>
      </Container>
      <div className="d-line-dashed " />
      <div className="pt-2 d-flex gap-20 align-items-center justify-content-center">
        <Button className="px-2" color="primary" type="button" onClick={() => confirmTransaction()}>
          Xác nhận
        </Button>
        <Button type="button" onClick={() => closeModal()}>
          Hủy
        </Button>
      </div>
    </Modal>
  );
}

PopUpConfirm.propTypes = {
  isOpen: PropTypes.bool,
  closeModal: PropTypes.func,
  item: PropTypes.object,
  refetch: PropTypes.func,
  setIsRefreshStatus: PropTypes.func,
};

export default PopUpConfirm;
