/* eslint-disable import/no-cycle */
import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import shortid from 'shortid';
import { Button, Container, NavLink } from 'reactstrap';
import { Layers, LayersClear } from '@mui/icons-material';
import { useForm } from 'react-hook-form';
import ButtonSubmit from '@Components/ButtonSubmit';
import { transactionApprove } from '@Apis/admin/transaction';
import useCustomMutation from '@Utils/useCustomMuation';
import useCustomQuery from '@Utils/useCustomQuery';
import { toastError, toastSuccess } from '@Utils/toastrHelper';
import { wareHouseIndex } from '@Apis/admin/warehouse';
import TransactionItem from './TransactionItem';

function TransactionForm({
  item,
  refetch,
  closeModal,
  // optionsWarehouse,
  optionsTeller,
  setIsRefreshStatus,
}) {
  const [itemsForm, setItemsForm] = useState([
    {
      id: shortid.generate(4),
      warehouse_id: 0,
      quantity: 0,
    },
  ]);

  const [wareHouseCansell, setWareHouseCansell] = useState({});
  const [errorQuantity, setErrorQuantity] = useState('');
  const filterAdd = item?.type === 'buy' ? { status: 'active' } : {};
  const { data: optionsWarehouse = [] } = useCustomQuery(wareHouseIndex, {
    stock_id: item?.stock_id,
    trade_type: item?.trade_type,
    ...filterAdd,
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    unregister,
    watch,
  } = useForm();

  const { mutate, isSuccess, error } = useCustomMutation(transactionApprove, true);
  const onSubmit = (formData) => {
    const transactions = Object.values(formData);
    let quantityTotal = 0;
    transactions.map((item) => {
      quantityTotal += item.quantity;
    });
    if (quantityTotal > item.quantity) {
      setErrorQuantity('Tổng khối lượng vượt quá khối lượng của giao dịch giao dịch');
      return;
    }
    const { id } = item;
    mutate({
      id,
      transactions,
    });
  };

  useEffect(() => {
    if (isSuccess) {
      toastSuccess('Duyệt lệnh thành công');
      closeModal();
      refetch();
      setIsRefreshStatus({ state: true });
    }
    if (error) {
      toastError(error);
    }
  }, [isSuccess, error]);

  const splitTransaction = () => {
    const item = {
      id: shortid.generate(4),
      warehouse_id: 0,
      quantity: 0,
    };
    setItemsForm([...itemsForm, item]);
  };

  const clearTransaction = (id) => {
    unregister(id);
    const transactionItems = [];
    itemsForm.map((item) => {
      if (item.id !== id) {
        transactionItems.push(item);
      }
    });
    setItemsForm(transactionItems);
  };

  const getWareHouseAvailable = useCallback(
    (rowId, wareHouse, watchQuantity, index) => {
      const ware = optionsWarehouse.find((e) => e.id.toString() === wareHouse);
      if (!ware) {
        return;
      }
      const newWareHouseCansell = {};
      const newData = {
        ...wareHouseCansell,
        [rowId]: {
          quantity: watchQuantity || 0,
          wareHouse,
          index,
          can_sell: getAvailable(index, wareHouse, wareHouseCansell, ware.stock_can_sell || 0),
        },
      };
      Object.keys(newData).forEach((eKey) => {
        if (newData.index <= index || newData[eKey].wareHouse !== wareHouse) {
          newWareHouseCansell[eKey] = newData[eKey];
        } else {
          newWareHouseCansell[eKey] = {
            ...newData[eKey],
            can_sell: getAvailable(
              newData[eKey].index,
              wareHouse,
              newData,
              ware.stock_can_sell || 0,
            ),
          };
        }
      });
      setWareHouseCansell(newWareHouseCansell);
    },
    [wareHouseCansell, optionsWarehouse],
  );

  const getAvailable = useCallback((key, wareH, wareHouseCansell, can_sell = 0) => {
    const avail = itemsForm.reduce((total, item, i) => {
      if (i < key && wareHouseCansell?.[item.id]?.wareHouse === wareH) {
        total -= wareHouseCansell?.[item.id]?.quantity || 0;
      }
      return total;
    }, can_sell);
    return avail > 0 ? avail : 0;
  }, []);

  const checkDisabled = Object.keys(wareHouseCansell).some((key) => {
    if (item.trade_type !== 'base') {
        return false;
    }
    if (item.type === 'buy') {
      return false;
    }
    return wareHouseCansell[key]?.quantity > wareHouseCansell[key]?.can_sell;
  });

  return (
    <div className="choice-warehouse">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Container className={`${item.type === 'buy' ? 'bg-buy' : 'bg-sell'}`}>
          {itemsForm.map((itemRow, index) => {
            return (
              <div className="d-flex align-items-center gap-20" key={itemRow.id}>
                <TransactionItem
                  // can_sell={wareHouseCansell?.[itemRow.id]?.can_sell}
                  can_sell={wareHouseCansell?.[itemRow.id]?.can_sell}
                  setWareHouse={(v, quantity) => {
                    getWareHouseAvailable(itemRow.id, v, quantity, index);
                  }}
                  item={itemRow}
                  register={register}
                  errors={errors}
                  setValue={setValue}
                  optionsWarehouse={[
                    {
                      label: 'Chọn',
                      value: '',
                    },
                  ].concat(
                    optionsWarehouse.map((e) => ({
                      label: `${e.name} (${e.stock_can_sell}) `,
                      value: e.id,
                    })),
                  )}
                  optionsTeller={optionsTeller}
                  watch={watch}
                  quantityDefault={index === 0 ? item.quantity : 0}
                />
                <NavLink className="clear-layer" onClick={() => clearTransaction(itemRow.id)}>
                  {index !== 0 && <LayersClear color="error" />}
                </NavLink>
              </div>
            );
          })}
          {errorQuantity && <div className="text-danger font-size-14">{errorQuantity}</div>}
          <NavLink onClick={() => splitTransaction()}>
            <Layers color="primary" />
            <span className="ml-25 font-size-14 font-weight-bold text-primary">Tách lệnh</span>
          </NavLink>
        </Container>
        <div className="d-flex justify-content-center gap-20 py-3">
          <ButtonSubmit
            disabled={checkDisabled}
            name="Duyệt"
            className="px-2"
            size="md"
            color="primary"
          />
          <Button type="button" onClick={() => closeModal()}>
            Hủy
          </Button>
        </div>
      </form>
    </div>
  );
}

TransactionForm.propTypes = {
  item: PropTypes.object,
  refetch: PropTypes.func,
  closeModal: PropTypes.func,
  optionsWarehouse: PropTypes.array,
  optionsTeller: PropTypes.array,
  setIsRefreshStatus: PropTypes.func,
};

export default TransactionForm;
