/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { io } from 'socket.io-client';
import Title from '@Components/Title';
import DataTableBasic from '@Components/DataTableBasic';
import { TYPE_PS, TYPE_TPLUS } from '@Components/Layouts/PrivateLayout';
import { columnsViTheMo } from '@Views/Client/TransactionPS/columns';
import TableTPlus from '@Views/Client/AssetManager/TableListPlus/TableTPlus';
import { stockAsset } from '@Apis/client/stock';
import { columns } from './columns';

function TableListDashboard({ accountId }) {
  const navigate = useNavigate();
  const productType = useSelector((state) => state.ui.productType);
  const [data, setData] = useState({});

  const getData = async () => {
    const data = await stockAsset({ page: 1, per_page: 20, customer_id: accountId });
    setData(data);
  };

  useEffect(() => {
    getData();
    const token = localStorage.getItem(process.env.TOKEN);
    const socket = io(`${process.env.BACKEND_SOCKET}/customer/stock/assets`, {
      query: { token },
      transports: ['websocket'],
    });
    socket.on('connect', () => {
      socket.emit('register', 'your-client-id', { page: 1, per_page: 7, customer_id: accountId });
    });
    socket.on('data', ({ data: dataSocket }) => {
      setData(dataSocket);
    });

    return () => {
      socket.disconnect();
    };
  }, [accountId]);

  const { assets = [] } = data || {};

  return (
    <div className="table-list">
      <div
        className="d-flex justify-content-between align-items-center"
        style={{ marginBottom: 20 }}
      >
        <div>
          {productType === TYPE_PS || productType === TYPE_TPLUS ? (
            <Title name="Danh mục vị thế" style={{ fontSize: 18 }} />
          ) : (
            <Title name="Danh mục của bạn" style={{ fontSize: 18 }} />
          )}
        </div>
        <div
          className="d-flex justify-content-end"
          role="button"
          tabIndex="0"
          onClick={() => navigate(`/${productType}/asset-management`)}
        >
          <span style={{ color: '#2F69B3' }}>Xem tất cả</span>
        </div>
      </div>
      <div>
        {productType === TYPE_PS ? (
          <DataTableBasic columns={columnsViTheMo} page={1} pageSize={5} data={assets} />
        ) : productType === TYPE_TPLUS ? (
          <div className="table-list-plus">
            <TableTPlus accountId={accountId} />
          </div>
        ) : (
          <DataTableBasic columns={columns} page={1} pageSize={5} data={assets} />
        )}
      </div>
    </div>
  );
}

TableListDashboard.propTypes = {
  accountId: PropTypes.any,
};

export default TableListDashboard;
