import React, { useCallback, useState } from 'react';
import {
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
  Modal,
  Row,
  Col,
} from 'reactstrap';
import PropsType from 'prop-types';
import { Power, ChevronDown } from 'react-feather';
import { getLastName } from '@Utils/helpers';
import { Button } from '@mui/material';
import { changePass } from '@Apis/auth';
import { toastError, toastSuccess } from '@Utils/toastrHelper';
import { Settings } from '@mui/icons-material';
import { useForm } from 'react-hook-form';
import ButtonSubmit from '@Components/ButtonSubmit';
import { useEffect } from 'react';
import PasswordInput from '@Components/Input/PasswordInput';
import { useSelector } from 'react-redux';

export default function UserDropdown({ user }) {
  const [isChangePass, setChangePass] = useState(false);
  const [isSubmitBtnLoading, setIsSubmitBtnLoading] = useState(false);
  const loggedinUserInfo = useSelector((state) => state.user.info);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    getValues,
    setError,
  } = useForm();

  const handleLogout = () => {
    localStorage.removeItem(process.env.TOKEN);
    window.location.href = '/login';
  };

  useEffect(() => {
    if (loggedinUserInfo.is_change_password_require) {
      setChangePass(true);
    }
  }, [loggedinUserInfo]);
  
  useEffect(() => {
    reset();
  }, [isChangePass]);

  const changePassword = useCallback(() => {
    setChangePass(true);
  }, []);

  const onSubmit = (formData) => {
    setIsSubmitBtnLoading(true);
    changePass({ current_password: formData.current_password, new_password: formData.new_password })
      .then((res) => {
        setIsSubmitBtnLoading(false);
        const { data } = res;
        if (data.success) {
          toastSuccess('Thay đổi mật khẩu thành công. Vui lòng đăng nhập lại');

          setTimeout(() => {
            handleLogout();
          }, 3000);
        } else {
          if (data.title == 'Mật khẩu cũ không chính xác, vui lòng thử lại') {
            setError('current_password', { type: 'not_correct_password', message: data.title });
          } else {
            toastError('Đã có lỗi xảy ra. Vui lòng thử lại.');
          }
        }
      })
      .catch((e) => {
        setIsSubmitBtnLoading(false);
        toastError(e);
      });
  };

  return (
    <UncontrolledDropdown tag="li" className="dropdown-user nav-item">
      {user && (
        <>
          <DropdownToggle
            href="/"
            tag="a"
            className="nav-link dropdown-user-link"
            onClick={(e) => e.preventDefault()}
            caret
          >
            <span className="b-avatar badge-minimal badge-light-primary rounded-circle mr-50">
              <span className="">
                <img
                  style={{ width: 32, height: 32, borderRadius: 100 }}
                  src={`${user.avatar_url ? user.avatar_url : '/images/icon/avatar.svg'}`}
                  alt="avatar"
                />
              </span>
            </span>
            <div className="d-flex align-items-center">
              <div className="user-info d-none d-md-block">
                <p className="user-name mb-0 color-black mr-25 uppercase">
                  {getLastName(user.username)}
                </p>
                <ChevronDown size={14} />
              </div>
            </div>
          </DropdownToggle>
          <DropdownMenu right>
            {/* <DropdownItem className="w-100" onClick={() => navigate('/setting')}>
              <Settings size={14} className="mr-75" />
              <span className="align-middle">Settings</span>
            </DropdownItem> */}
            <DropdownItem className="w-100" onClick={changePassword}>
              <Settings size={14} className="mr-75" />
              <span className="align-middle">Đổi mật khẩu</span>
            </DropdownItem>
            <DropdownItem className="w-100" onClick={handleLogout}>
              <Power size={14} className="mr-75" />
              <span className="align-middle">Đăng xuất</span>
            </DropdownItem>
          </DropdownMenu>
        </>
      )}
      <Modal isOpen={isChangePass} backdrop="static" centered>
        <div style={{ fontWeight: 700, fontSize: 21 }}>
          Thay đổi mật khẩu
          <span
            style={{
              float: 'right',
              fontSize: 17,
              cursor: 'pointer',
              marginTop: -8,
            }}
            onClick={() => setChangePass(false)}
          >
            x
          </span>
        </div>
        {loggedinUserInfo.is_change_password_require && (
          <p className="mt-1 text-danger font-size-14">
            Bạn chưa đổi mật khẩu sau 3 tháng. Vui lòng thay đổi mật khẩu.
          </p>
        )}
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="mt-1">
            <Row>
              <Col md="12">
                <PasswordInput
                  labelCol="12"
                  inputCol="12"
                  type="password"
                  labelClassName="font-size-14 mb-50"
                  labelName="Nhập mật khẩu cũ *"
                  placeholder="Nhập mật khẩu cũ"
                  setValue={setValue}
                  name="current_password"
                  register={{ ...register('current_password', { required: true }) }}
                  errors={errors}
                />
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <PasswordInput
                  labelCol="12"
                  inputCol="12"
                  type="password"
                  labelClassName="font-size-14 mb-50"
                  labelName="Nhập mật khẩu mới *"
                  placeholder="Nhập mật khẩu mới"
                  setValue={setValue}
                  name="new_password"
                  register={{
                    ...register('new_password', {
                      required: true,
                      minLength: {
                        value: 6,
                        message: 'Số kí tự phải lớn hơn 6.',
                      },
                    }),
                  }}
                  errors={errors}
                />
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <PasswordInput
                  labelCol="12"
                  inputCol="12"
                  type="password"
                  labelClassName="font-size-14 mb-50"
                  labelName="Nhập lại mật khẩu mới *"
                  placeholder="Nhập lại mật khẩu mới"
                  setValue={setValue}
                  name="new_password_confirmation"
                  register={{
                    ...register('new_password_confirmation', {
                      required: true,
                      validate: (value) => {
                        const { new_password } = getValues();
                        return new_password === value || 'Nhập lại mật khẩu mới chưa chính xác.';
                      },
                    }),
                  }}
                  errors={errors}
                />
              </Col>
            </Row>
          </div>
          <Row className="d-flex justify-content-end gap-20" style={{ paddingBottom: 20 }}>
            <Button variant="outlined" onClick={() => setChangePass(false)}>
              Hủy
            </Button>
            <ButtonSubmit
              isLoading={isSubmitBtnLoading}
              style={{ marginRight: 16 }}
              name="Xác nhận"
              color="primary"
            />
          </Row>
        </form>
      </Modal>
    </UncontrolledDropdown>
  );
}

UserDropdown.propTypes = {
  user: PropsType.object,
};
