export const dataStateBase1 = (data) => [
  {
    icon: '/images/assetManager/Dola.svg',
    text: 'Tổng GTCK Khách hàng',
    money: data?.total_stock_value,
    onClick: (navigate) => {
      navigate?.('/customer');
    },
  },
  {
    icon: '/images/assetManager/stock_value.svg',
    text: 'Tổng nợ margin',
    money: data?.total_debt,
    onClick: (navigate) => {
      navigate?.('/warehouse/account');
    },
  },
  {
    icon: '/images/assetManager/balance.svg',
    text: 'Tổng tiền mặt',
    money: data?.total_cash,
  },
];

export const dataStateBase2 = (data) => [
  {
    icon: '/images/assetManager/Dola.svg',
    text: 'Tổng GTCK Kho',
    money: data?.total_stock_value_purchase_account,
    onClick: (navigate) => {
      navigate?.('/warehouse/account');
    },
  },
  {
    icon: '/images/assetManager/debt.svg',
    text: 'Tổng tiền huy động',
    money: data?.total_loan,
    onClick: (navigate) => {
      navigate?.('/warehouse/source');
    },
  },
  {
    icon: '/images/assetManager/stock_value.svg',
    text: 'Tổng NAV khách',
    money: data?.total_customer_nav,
    onClick: (navigate) => {
      navigate?.('/customer');
    },
  },
];

export const dataStateBase3 = (data) => [
  {
    icon: '/images/assetManager/Dola.svg',
    text: 'Tổng GTCK Danh mục',
    money: data?.total_stock_value_stocks,
    onClick: (navigate) => {
      navigate?.('/stock');
    },
  },
  {
    icon: '/images/assetManager/debt.svg',
    text: 'Tổng sức mua',
    money: data?.buy_ability,
  },
];

export const dataStateSpot1 = (data) => [
  {
    icon: '/images/assetManager/stock_value.svg',
    text: 'Tổng GT vị thế',
    money: data?.total_spot_value,
    onClick: (navigate) => {
      navigate?.('/warehouse/account');
    },
  },
  {
    icon: '/images/assetManager/Dola.svg',
    text: 'Tổng ký quỹ yêu cầu',
    money: data?.total_deposit,
    onClick: (navigate) => {
      navigate?.('/customer');
    },
  },
  {
    icon: '/images/assetManager/balance.svg',
    text: 'Tổng NAV',
    money: data?.total_nav,
  },
  {
    icon: '/images/assetManager/Dola.svg',
    text: 'Tổng dư cọc',
    money: data?.total_withdraw_avaiable,
  },
];

export const dataStateSpot2 = (data) => [
  {
    icon: '/images/assetManager/Dola.svg',
    text: 'Tổng phí giao dịch (Tháng)',
    money: data?.total_transaction_item_fee_month,
  },
  {
    icon: '/images/assetManager/Dola.svg',
    text: 'Tổng lãi vay (Tháng)',
    money: data?.total_interest_item_fee_month,
  },
  {
    icon: '/images/assetManager/Dola.svg',
    text: 'Tổng phí giao dịch',
    money: data?.total_transaction_item_fee,
  },
  {
    icon: '/images/assetManager/Dola.svg',
    text: 'Tổng lãi vay',
    money: data?.total_interest_item_fee,
  },
  // {
  //   icon: '/images/assetManager/debt.svg',
  //   text: 'Tổng ký quỹ T2',
  //   money: data?.total_t2_deposit,
  // },
  // {
  //   icon: '/images/assetManager/stock_value.svg',
  //   text: 'Tổng GT vị thế T2 đang mở',
  //   money: data?.total_t2_value,
  // },
];

export const dataStateDerivative1 = (data) => [
  {
    icon: '/images/assetManager/Dola.svg',
    text: 'Tổng phí giao dịch',
    money: data?.today_transaction_fee,
    onClick: (navigate) => {
      navigate?.('/customer');
    },
  },
  {
    icon: '/images/assetManager/balance.svg',
    text: 'Tổng NAV khách',
    money: data?.total_nav,
  },
  {
    icon: '/images/assetManager/Dola.svg',
    text: 'Tổng tiền có thể rút',
    money: data?.withdraw_ability,
  },
];

export const dataStateDerivative2 = (data) => [
  {
    icon: '/images/assetManager/stock_value.svg',
    text: 'Phí giao dịch qua đêm',
    money: data?.today_interest_fee < 0 ? data?.today_interest_fee * (-1) : data?.today_interest_fee,
  },
  {
    icon: '/images/assetManager/debt.svg',
    text: 'Lãi lỗ vị thế đóng',
    money: data?.profit_sell,
  },
  {
    icon: '/images/assetManager/stock_value.svg',
    text: 'Lãi lỗ vị thế mở',
    money: data?.profit_buy,
  },
];

export const dataStateDerivative3 = (data) => [
  {
    icon: '/images/assetManager/Dola.svg',
    text: 'Tổng tiền mặt các kho',
    money: data?.total_cash,
  },
];
