/* eslint-disable no-confusing-arrow */
/* eslint-disable react/prop-types */
import dayjs from 'dayjs';
import React from 'react';
import {
  renderType,
  getPriceMatching,
  renderProduct,
  getQuantityMatchingTeller,
  renderConditionOrder,
} from '@Utils/tradeHelpers';
import { formatVND, formatNumber } from '@Utils/helpers';
import {
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
  Button,
} from 'reactstrap';
import { Square } from '@mui/icons-material';
import { TRADE_STATUS } from '../constants';
import { TYPE_TPLUS } from '@Components/Layouts/PrivateLayout';
function renderTime(activeTabId, createdAt) {

  if(createdAt === null || createdAt === undefined) return '--';

  if (activeTabId === 1) {
    return dayjs.unix(createdAt).format('HH:mm');
  }
  return dayjs.unix(createdAt).format('HH:mm - DD/MM/YY');
}
function StatusButton({ status, changeStatus, item }) {
  let isButtonDropDown = false;
  if (!status) {
    return '';
  }
  const { is_cancel_request } = item;
  if (status === 'pending' || status === 'in_process' || is_cancel_request) {
    isButtonDropDown = true;
  }

  let objStatus = {};
  if (
    is_cancel_request &&
    status !== 'rejected' &&
    status !== 'cancelled' &&
    status !== 'expired'
  ) {
    objStatus = TRADE_STATUS.find((item) => item.value === 'request_cancel');
  } else {
    objStatus = TRADE_STATUS.find((item) => item.value === status);
  }

  if (!objStatus) {
    return '';
  }
  const { color, label } = objStatus;
  return isButtonDropDown ? (
    <UncontrolledDropdown className="nav-item">
      <DropdownToggle
        color="none"
        style={{ background: color }}
        className="font-size-14 text-white text-uppercase"
        onClick={(e) => e.preventDefault()}
        caret
      >
        {label}
      </DropdownToggle>
      <DropdownMenu right>
        {item.status !== 'in_process' && (
          <DropdownItem className="w-100" onClick={() => changeStatus(item, 'in_process')}>
            <Square color="primary" />
            <span className="align-middle font-size-14 font-weight-bolder ml-50 text-primary">
              ĐANG VÀO LỆNH
            </span>
          </DropdownItem>
        )}
        <DropdownItem className="w-100" onClick={() => changeStatus(item, 'expired')}>
          <Square color="disable" />
          <span className="align-middle font-size-14 font-weight-bolder ml-50 text-secondary">
            HẾT HẠN
          </span>
        </DropdownItem>
        <DropdownItem className="w-100" onClick={() => changeStatus(item, 'cancelled')}>
          <Square color="error" />
          <span className="align-middle font-size-14 font-weight-bolder ml-50 text-danger">
            HỦY
          </span>
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  ) : (
    <Button
      color="none"
      className="text-white text-uppercase font-size-14 white-space-nowrap"
      style={{ background: color }}
    >
      {label}
    </Button>
  );
}

function renderPrice({ price_type, price }) {
  if (price_type !== 'price') {
    return (
      <div className="uppercase" style={{ textAlign: 'right', width: '100%' }}>
        <div style={{ background: 'rgba(32, 201, 172, 0.1)' }}>
          <p className="font-size-14 font-weight-bolder py-25 px-1 text-info">{price_type}</p>
        </div>
      </div>
    );
  }
  return <div style={{ width: '100%', textAlign: 'right' }}>{formatNumber(price)}</div>;
}

export const columns = ({ editStatus, navigate }) => {
  return [
    {
      name: 'MÃ GD',
      cell: (row) =>
        `${row.source_transaction_item_id || ''}${row.source_transaction_item_id ? '-' : ''}${
          row.id
        }`,
      center: true,
      width: '100px',
    },
    {
      name: 'THỜI GIAN',
      cell: (row) => dayjs.unix(row.created_at).format('HH:mm - DD/MM/YY'),
      width: '140px',
      center: true,
      sortable: true,
      sortField: 'created_at',
    },
    {
      name: 'ID KH',
      cell: (row) => row.publicCustomer.code,
      width: '100px',
      center: true,
    },
    {
      name: 'KHÁCH HÀNG',
      cell: (row) => {
        const tradeType =
          row.trade_type == 't2' || row.trade_type == 't0' ? TYPE_TPLUS : row.trade_type;
        return (
          <div
            onClick={() =>
              navigate('/customer/detail/' + row?.publicCustomer?.id + '?trade_type=' + tradeType)
            }
            style={{ textTransform: 'uppercase', cursor: 'pointer', color: '#2F69B3' }}
          >
            {row?.publicCustomer?.username}
          </div>
        );
      },
      width: '170px',
    },
    {
      name: 'LOẠI SP',
      selector: (row) => renderProduct(row.trade_type),
      width: '100px',
      center: true,
    },
    {
      name: 'LOẠI LỆNH',
      selector: (row) => renderType(row.type),
      width: '100px',
      center: true,
    },
    {
      name: 'CP',
      cell: (row) => row.stock_id,
      width: '60px',
      center: true,
    },
    {
      name: 'GIÁ',
      cell: renderPrice,
      width: '80px',
      center: true,
      sortable: true,
      sortField: 'price',
    },
    {
      name: 'KL',
      cell: (row) => (
        <div style={{ width: '100%', textAlign: 'right' }}>{formatVND(row.quantity)}</div>
      ),
      width: '110px',
      center: true,
      sortable: true,
      sortField: 'quantity',
    },
    {
      name: 'LỆNH ĐIỀU KIỆN',
      cell: (row) =>
        row.condition_type ? renderConditionOrder(row.condition_type, row.condition_price) : null,
      width: '120px',
      center: true,
    },
    {
      name: 'KHO',
      cell: (row) => row.purchaseAccount?.name,
      width: '120px',
    },
    {
      name: 'GIÁ KHỚP',
      cell: (row) => {
        const priceMatching = getPriceMatching(row);
        return (
          <div style={{ width: '100%', textAlign: 'right' }}>
            {formatNumber(priceMatching, '--')}
          </div>
        );
      },
      center: true,
      width: '110px',
      sortable: true,
      sortField: 'dca_price',
    },
    {
      name: 'TG KHỚP',
      width: '140px',
      cell: (row) => <div className="">{renderTime(2, row?.lastTradingSubItemTime?.created_at)}</div>,
      center: true,
    },
    {
      name: 'KL KHỚP',
      cell: (row) => {
        const quantityMatching = getQuantityMatchingTeller(row);

        return (
          <div style={{ width: '100%', textAlign: 'right' }}>
            <span className={`font-weight-bolder ${row.is_completed ? '' : 'text-danger'}`}>
              {' '}
              {formatNumber(quantityMatching, '--')}
            </span>
            {row.status === 'part_completed' && (
              <span>{`/${formatNumber(row.quantity, '--')}`}</span>
            )}
          </div>
        );
      },
      center: true,
      width: '120px',
      sortable: true,
      sortField: 'final_quantity',
    },
    {
      name: '',
      cell: (row) => (
        <div className="" style={{ maxWidth: '200px' }}>
          <StatusButton status={row.status} changeStatus={editStatus} item={row} />
        </div>
      ),
      center: true,
    },
  ];
};
