import { formatNumber, formatVND } from '@Utils/helpers';
import React from 'react';

export const columnsPS = () => {
  return [
    {
      name: 'ID KH',
      cell: (row) => row.protectedCustomer?.code,
      width: '110px',
      fixed: 'left',
      center: true,
    },
    {
      name: 'Khách hàng',
      cell: (row) => <div className="uppercase">{row.protectedCustomer?.username}</div>,
      width: '180px',
      fixed: 'left',
    },
    {
      name: 'VỊ THẾ CÒN LẠI',
      cell: (row) => formatVND(row.quantity),
      width: '150px',
      center: true,
    },
    {
      name: 'KL khả dụng',
      cell: (row) => formatVND(row.available_quantity),
      width: '170px',
      center: true,
    },
    {
      name: 'TỈ LỆ KÝ QUỸ',
      cell: (row) => `${formatVND(row.protectedCustomer?.current_margin_percent)}%`,
      width: '130px',
      center: true,
    },
    {
      name: 'NAV',
      cell: (row) => formatVND(row.protectedCustomer?.current_nav),
      width: '160px',
      fixed: 'right',
    },
  ];
};
