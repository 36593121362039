import axiosService from '@Lib/axiosService';
import { handleResponse } from '@Utils/helpers';

let url = '';
if (process.env.DASHBOARD_TYPE === 'admin') {
  url = `${process.env.API_ADMIN_URL}/export`;
}

export const list = async (query) => {
  const res = await axiosService.get(`${url}/list`, query);
  return handleResponse(res);
};

export const myList = async (query) => {
  const res = await axiosService.get(`${url}/my-list`, query);
  return handleResponse(res);
};

export const getExport = async (id) => {
  const res = await axiosService.get(`${url}/download?id=${id}`);
  return handleResponse(res);
};
