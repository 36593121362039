import { createPurchase, listPurchase } from '@Apis/admin/groupPurchase';
import DataTableBasic from '@Components/DataTableBasic';
import InputSearch from '@Components/InputSearch';
import { Button, Modal } from 'reactstrap';
import useCustomQuery from '@Utils/useCustomQuery';
import React, { useCallback, useState } from 'react';
import Switch from '@mui/material/Switch';
import { Add } from '@mui/icons-material';
import FieldInput from '@Views/Client/Recharge/BodyTab/Request/FieldInput';
import { useDispatch } from 'react-redux';
import { hideGlobalLoading, showGlobalLoading } from '@Lib/slices/uiSlice';
import './styles.scss';
import { toastSuccess } from '@Utils/toastrHelper';

const renderRow = (row) => {
  if (row.is_active) {
    return <span style={{ color: 'rgb(20, 177, 59)' }}>Kích hoạt</span>;
  }
  return <span style={{ color: '#ea5455' }}>Dừng kích hoạt</span>;
};

const columns = [
  {
    name: 'Tên Group',
    cell: (row) => <div className="white-space-norwrap font-weight-bolder">{row.name}</div>,
  },
  {
    name: 'Trạng thái',
    cell: (row) => renderRow(row),
    center: true,
  },
];

function PurchaseAccountGroup() {
  const [groupAdd, setOpenCreate] = useState();
  const dispatch = useDispatch();
  const [filter, setFilter] = useState({});
  const { data, refetch } = useCustomQuery(listPurchase, filter);

  const setOrderBy = useCallback((column, type = '') => {
    setFilter((prevValues) => {
      return {
        ...prevValues,
        sort_by: column.sortField,
        order: type.toUpperCase(),
      };
    });
  }, []);

  const filterOnChange = useCallback((name, value) => {
    setFilter((prevValues) => {
      return {
        ...prevValues,
        [name]: value,
      };
    });
  }, []);

  const openCreate = useCallback((data) => {
    setOpenCreate(data);
  }, []);

  const onChangeName = useCallback((value) => {
    setOpenCreate((pre) => ({ ...pre, name: value, error: value ? '' : 'Vui lòng nhập tên' }));
  }, []);

  const onChangeStatus = useCallback((event) => {
    setOpenCreate((pre) => ({ ...pre, is_active: event.target.checked }));
  }, []);

  const onCreate = useCallback(async () => {
    if (groupAdd?.error) {
      return;
    }
    delete groupAdd?.error;
    dispatch(showGlobalLoading());
    try {
      await createPurchase(groupAdd);
      toastSuccess(`${groupAdd?.id ? 'Cập nhật' : 'Thêm'} dữ liệu thành công`);
      // eslint-disable-next-line no-empty
    } catch (e) {}
    openCreate();
    dispatch(hideGlobalLoading());
    refetch();
  }, [groupAdd]);

  return (
    <div className="purchase-account-group">
      <div className="input-search-purchase">
        <InputSearch
          placeholder="Tìm kiếm tên.."
          searchKey={filter.keyword}
          name="keyword"
          setSearchKey={filterOnChange}
          width="240px"
        />
        <Button
          className="d-flex align-items-center"
          style={{ height: 43.39, marginLeft: 10 }}
          color="primary"
          onClick={() => openCreate({ is_active: true })}
        >
          <Add size={13} color="white" />
          <span className="font-size-14">Thêm mới</span>
        </Button>
      </div>
      <DataTableBasic onSort={setOrderBy} onRowClicked={openCreate} columns={columns} data={data} />
      <Modal isOpen={!!groupAdd}>
        <div className="modal-create-group">
          <div className="modal-create-title">{groupAdd?.name ? 'Chỉnh sửa nhóm tài khoản kho' : 'Thêm mới nhóm tài khoản kho'}</div>
          <FieldInput
            type="name"
            value={groupAdd?.name || ''}
            onChange={onChangeName}
            label="Tên group"
            required
            errorMessage={groupAdd?.error || ''}
            placeholder="Nhập tên group"
          />
          <div className="d-flex align-items-center">
            <div>Trạng thái:</div>
            <Switch checked={!!groupAdd?.is_active} onChange={onChangeStatus} />
          </div>
          <div className="d-flex justify-content-center mt-3">
            <Button
              style={{ height: 43.39, marginRight: 5 }}
              className="mr-1"
              onClick={() => openCreate()}
            >
              <span className="font-size-14">Đóng</span>
            </Button>
            <Button
              className="d-flex align-items-center"
              style={{ height: 43.39, marginLeft: 5 }}
              color="primary"
              onClick={onCreate}
            >
              {groupAdd?.id ? null : <Add size={13} color="white" />}
              <span className="font-size-14">{groupAdd?.id ? 'Sửa' : 'Thêm'}</span>
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default PurchaseAccountGroup;
