import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'reactstrap';
import TextInput from '@Components/Input/TextInput';
import { useForm } from 'react-hook-form';
import { formatNumber } from '@Utils/helpers';

function TransactionDetail({ item }) {
  const { setValue } = useForm();
  const [itemsDetail, setItemsDetail] = useState([]);
  useEffect(() => {
    if (item) {
      const {
        subTransactionItems,
        type,
        status,
        purchaseAccount,
        quantity,
        id,
        publicTellersUser,
      } = item;
      if (subTransactionItems.length > 0) {
        setItemsDetail(subTransactionItems);
      } else {
        setItemsDetail([
          {
            id,
            type,
            status,
            purchaseAccount,
            quantity,
            publicTellersUser,
          },
        ]);
      }
    }
  }, [item]);
  return (
    <div className="choice-warehouse">
      <Container className={`${item.type === 'buy' ? 'bg-buy' : 'bg-sell'}`}>
        {itemsDetail.map((itemDetail) => (
          <Row key={itemDetail.id}>
            <Col>
              <TextInput
                labelCol="12"
                inputCol="12"
                type="text"
                labelClassName="font-size-14 mb-50"
                labelName="Kho DT"
                placeholder="Kho DT"
                name={`${item.id}.purchase_account_id`}
                defaultValue={itemDetail.purchaseAccount ? itemDetail.purchaseAccount.name : ''}
                disabled
                setValue={setValue}
              />
            </Col>
            <Col>
              <TextInput
                labelCol="12"
                inputCol="12"
                type="text"
                labelClassName="font-size-14 mb-50"
                labelName="GDV"
                placeholder="GDV"
                name={`${item.id}.tellers_user_id`}
                defaultValue={
                  itemDetail.publicTellersUser ? itemDetail.publicTellersUser.username : ''
                }
                disabled
                setValue={setValue}
              />
            </Col>
            <Col>
              <TextInput
                labelCol="12"
                inputCol="12"
                type="quantity"
                labelClassName="font-size-14 mb-50"
                labelName="Khối lượng"
                placeholder="Khối lượng"
                name={`${item.id}.quantity`}
                disabled
                defaultValue={formatNumber(itemDetail.quantity)}
                setValue={setValue}
              />
            </Col>
          </Row>
        ))}
      </Container>
    </div>
  );
}

TransactionDetail.propTypes = {
  item: PropTypes.object,
};

export default TransactionDetail;
