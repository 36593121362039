import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { Container, Row, Col, Button } from 'reactstrap';
import { useForm } from 'react-hook-form';
import TextInput from '@Components/Input/TextInput';
import FlatPickrInput from '@Components/Input/FlatPickrInput';
import { customerInsert } from '@Apis/admin/customer';
import SelectInput from '@Components/Input/SelectInput';
import ButtonSubmit from '@Components/ButtonSubmit';
import PasswordInput from '@Components/Input/PasswordInput';
import { useSelector } from 'react-redux';
import useCustomMutation from '@Utils/useCustomMuation';
import { toastError, toastSuccess } from '@Utils/toastrHelper';
import SearchEmployer from '@Components/Input/SearchEmployer';
import { Percent } from '@mui/icons-material';

function Modify({ item, refForm }) {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm();

  const { mutate, isSuccess, error } = useCustomMutation(customerInsert, true);
  useEffect(() => {
    if (isSuccess) {
      const message = item ? 'Cập nhập khách hàng thành công' : 'Tạo mới khách hàng thành công';
      toastSuccess(message);
      if (!item) {
        navigate('/customer');
      }
    }
    if (error) {
      toastError(error);
    }
  }, [isSuccess, error]);
  const bankList = useSelector((state) => state.user.bankList);

  const onSubmit = (formData) => {
    const {
      account_name_1,
      account_number_1,
      bank_id_1,
      account_name_2,
      account_number_2,
      bank_id_2,
      support_user_id,
      user_id,
      tellers_user_id,
    } = formData;

    let payoutBankAccounts = [];
    let payoutBank1 = {};
    let payoutBank2 = {};
    if (item) {
      const { id } = item;
      payoutBankAccounts = item.payoutBankAccounts;
      payoutBank1 = payoutBankAccounts[0] || {};
      payoutBank2 = payoutBankAccounts[1] || {};
      formData.id = id;
    }
    if (account_name_1) {
      payoutBank1.account_name = account_name_1;
      payoutBank1.account_number = account_number_1;
      payoutBank1.bank_id = bank_id_1;
    }
    if (account_name_2) {
      payoutBank2.account_name = account_name_2;
      payoutBank2.account_number = account_number_2;
      payoutBank2.bank_id = bank_id_2;
    }

    formData.user_id = user_id === '0' ? null : parseInt(user_id);
    formData.support_user_id = support_user_id === '0' ? null : parseInt(support_user_id);
    formData.tellers_user_id = tellers_user_id === '0' ? null : parseInt(tellers_user_id);

    formData.payoutBankAccounts = [payoutBank1, payoutBank2];
    mutate(formData);
  };

  const optionsBank = () => {
    const itemDefaul = {
      label: 'Chọn',
      value: 0,
    };
    let options = [];
    if (bankList) {
      options = bankList.map((item) => ({ label: item.name, value: item.id }));
    }
    options.unshift(itemDefaul);
    return options;
  };

  return (
    <Container className="pt-2">
      <form onSubmit={handleSubmit(onSubmit)} ref={refForm}>
        <Row className="mb-3">
          <Col md="4" xs="12">
            <p className="font-size-20 mb-1">Thông tin cá nhân</p>
          </Col>
          <Col md="8" xs="12">
            <Row>
              <Col md="6">
                <TextInput
                  labelCol="12"
                  inputCol="12"
                  type="text"
                  labelClassName="font-size-14 mb-50"
                  labelName="Họ tên *"
                  placeholder="Họ tên"
                  setValue={setValue}
                  name="full_name"
                  register={{ ...register('full_name', { required: true }) }}
                  errors={errors}
                  defaultValue={item ? item.full_name : ''}
                />
              </Col>
              <Col md="6">
                <TextInput
                  labelCol="12"
                  inputCol="12"
                  type="email"
                  labelClassName="font-size-14 mb-50"
                  labelName="Email *"
                  placeholder="Email"
                  setValue={setValue}
                  name="email"
                  register={{ ...register('email', { required: true }) }}
                  errors={errors}
                  defaultValue={item ? item.email : ''}
                />
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <TextInput
                  labelCol="12"
                  inputCol="12"
                  type="number"
                  labelClassName="font-size-14 mb-50"
                  labelName="Số CMT/CCCD"
                  placeholder="Số CMT/CCCD"
                  setValue={setValue}
                  name="identity_card"
                  register={{ ...register('identity_card') }}
                  errors={errors}
                  defaultValue={item ? item.identity_card : ''}
                />
              </Col>
              <Col md="6">
                <FlatPickrInput
                  name="birthday"
                  register={{ ...register('birthday') }}
                  labelName="Ngày sinh"
                  placeholder="dd/mm/yy"
                  setValue={setValue}
                  defaultValue={item ? item.birthday : ''}
                  errors={errors}
                />
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <TextInput
                  labelCol="12"
                  inputCol="12"
                  type="phone"
                  labelClassName="font-size-14 mb-50"
                  labelName="Số điện thoại 1 *"
                  placeholder="Số điện thoại 1"
                  setValue={setValue}
                  name="phone"
                  register={{ ...register('phone', { required: true }) }}
                  errors={errors}
                  defaultValue={item ? item.phone : ''}
                />
              </Col>
              <Col md="6">
                <TextInput
                  labelCol="12"
                  inputCol="12"
                  type="phone"
                  labelClassName="font-size-14 mb-50"
                  labelName="Số điện thoại 2"
                  placeholder="Số điện thoại 2"
                  setValue={setValue}
                  name="backup_phone"
                  register={{ ...register('backup_phone') }}
                  errors={errors}
                  defaultValue={item ? item.backup_phone : ''}
                />
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <TextInput
                  labelCol="12"
                  inputCol="12"
                  type="text"
                  labelClassName="font-size-14 mb-50"
                  labelName="Địa chỉ"
                  placeholder="Địa chỉ"
                  setValue={setValue}
                  name="address"
                  register={{ ...register('address') }}
                  errors={errors}
                  defaultValue={item ? item.address : ''}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col md="4" xs="12">
            <p className="font-size-20 mb-25">Tài khoản rút tiền</p>
            <p className="font-size-14 color-matte-gray mb-1">
              Khách hàng chỉ có tối 2 đa tài khoản
            </p>
          </Col>
          <Col md="8" xs="12">
            <Row>
              <Col md="6" xs="12">
                <TextInput
                  labelCol="12"
                  inputCol="12"
                  type="text"
                  labelClassName="font-size-14 mb-50"
                  labelName="Tên chủ tài khoản 1"
                  placeholder="Tên chủ tài khoản 1"
                  setValue={setValue}
                  name="account_name_1"
                  register={{
                    ...register('account_name_1', {
                      validate: (value) => {
                        if (value) {
                          return;
                        }
                        if (watch('account_number_1') || watch('bank_id_1')) {
                          return 'Trường bắt buộc';
                        }
                      },
                    }),
                  }}
                  errors={errors}
                  defaultValue={item ? item.payoutBankAccounts?.[0]?.account_name : ''}
                />
              </Col>
              <Col md="6" xs="12">
                <TextInput
                  labelCol="12"
                  inputCol="12"
                  type="text"
                  labelClassName="font-size-14 mb-50"
                  labelName="Số tài khoản 1"
                  placeholder="Số tài khoản 1"
                  setValue={setValue}
                  name="account_number_1"
                  register={{
                    ...register('account_number_1', {
                      validate: (value) => {
                        if (value) {
                          return;
                        }
                        if (watch('bank_id_1') || watch('account_name_1')) {
                          return 'Trường bắt buộc';
                        }
                      },
                    }),
                  }}
                  errors={errors}
                  defaultValue={item ? item.payoutBankAccounts?.[0]?.account_number : ''}
                />
              </Col>
            </Row>
            <Row>
              <Col md="12" xs="12">
                <SelectInput
                  labelCol="12"
                  inputCol="12"
                  inputClassName="uppercase"
                  options={optionsBank()}
                  errors={errors}
                  labelClassName="font-size-14"
                  name="bank_id_1"
                  register={{
                    ...register('bank_id_1', {
                      validate: (value) => {
                        if (value) {
                          return;
                        }
                        if (watch('account_number_1') || watch('account_name_1')) {
                          return 'Trường bắt buộc';
                        }
                      },
                    }),
                  }}
                  labelName="Ngân hàng 1"
                  setValue={setValue}
                  defaultValue={item ? item.payoutBankAccounts?.[0]?.bank_id : ''}
                />
              </Col>
            </Row>
            <div className="d-line my-2" />
            <Row>
              <Col md="6" xs="12">
                <TextInput
                  labelCol="12"
                  inputCol="12"
                  type="text"
                  labelClassName="font-size-14 mb-50"
                  labelName="Tên chủ tài khoản 2"
                  placeholder="Tên chủ tài khoản 2"
                  setValue={setValue}
                  name="account_name_2"
                  register={{
                    ...register('account_name_2', {
                      validate: (value) => {
                        if (value) {
                          return;
                        }
                        if (watch('account_number_2') || watch('bank_id_2')) {
                          return 'Trường bắt buộc';
                        }
                      },
                    }),
                  }}
                  errors={errors}
                  defaultValue={item ? item.payoutBankAccounts?.[1]?.account_name : ''}
                />
              </Col>
              <Col md="6" xs="12">
                <TextInput
                  labelCol="12"
                  inputCol="12"
                  type="text"
                  labelClassName="font-size-14 mb-50"
                  labelName="Số tài khoản 2"
                  placeholder="Số tài khoản 2"
                  setValue={setValue}
                  name="account_number_2"
                  register={{
                    ...register('account_number_2', {
                      validate: (value) => {
                        if (value) {
                          return;
                        }
                        if (watch('account_name_2') || watch('bank_id_2')) {
                          return 'Trường bắt buộc';
                        }
                      },
                    }),
                  }}
                  errors={errors}
                  defaultValue={item ? item.payoutBankAccounts?.[1]?.account_number : ''}
                />
              </Col>
            </Row>
            <Row>
              <Col md="12" xs="12">
                <SelectInput
                  inputClassName="uppercase"
                  labelCol="12"
                  inputCol="12"
                  options={optionsBank()}
                  errors={errors}
                  labelClassName="font-size-14"
                  name="bank_id_2"
                  register={{
                    ...register('bank_id_2', {
                      validate: (value) => {
                        if (value) {
                          return;
                        }
                        if (watch('account_number_2') || watch('account_name_2')) {
                          return 'Trường bắt buộc';
                        }
                      },
                    }),
                  }}
                  labelName="Ngân hàng 1"
                  setValue={setValue}
                  defaultValue={item ? item.payoutBankAccounts?.[1]?.bank_id : ''}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col md="4" xs="12">
            <p className="font-size-20 mb-1">Thông tin đăng nhập</p>
          </Col>
          <Col md="8" xs="12">
            <Row>
              <Col md="6" xs="12">
                <TextInput
                  labelCol="12"
                  inputCol="12"
                  type="text"
                  labelClassName="font-size-14 mb-50"
                  labelName="Tên đăng nhập *"
                  placeholder="Tên đăng nhập *"
                  setValue={setValue}
                  name="username"
                  register={{ ...register('username', { required: true }) }}
                  errors={errors}
                  defaultValue={item ? item.username : ''}
                />
              </Col>
              <Col md="6" xs="12">
                <PasswordInput
                  labelCol="12"
                  inputCol="12"
                  type="password"
                  labelClassName="font-size-14 mb-50"
                  inputClassName=""
                  labelName={`Mật khẩu ${item ? '' : '*'}`}
                  placeholder="Mật khẩu"
                  setValue={setValue}
                  name="new_password"
                  register={{ ...register('new_password', { required: !item }) }}
                  errors={errors}
                  defaultValue={item ? item.new_password : ''}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <div className="d-line" />
        <Row className="justify-content-center gap-20 py-3">
          <ButtonSubmit
            name="Lưu thay đổi"
            className="px-2"
            size="md"
            color="primary"
            // isLoading={isLoading}
          />
          <Button outline type="button">
            Hủy
          </Button>
        </Row>
      </form>
    </Container>
  );
}

Modify.propTypes = {
  item: PropTypes.object,
  refForm: PropTypes.object,
};

export default Modify;
