import React, { Component } from 'react';
import PropTypes from 'prop-types';
import NameCheckbox from './NameCheckbox';

class HeaderRole extends Component {
  onChange = (ev, i) => {
    const { onChange } = this.props;
    onChange?.(ev.target.checked, i);
  };

  render() {
    const {
      allowEdit,
      roles = [],
      listRoles,
      data,
      rolesUser = {},
      handleCheckAllRoleUser,
      checkedAll,
    } = this.props;

    return (
      <thead>
        <tr className="header-role collapse-tr">
          <th className="header-col">Tính năng</th>
          {roles?.map((e, i) => {
            return (
              <th
                key={e.id}
                style={{ minWidth: 100, color: '#84818a', textTransform: 'uppercase' }}
              >
                <NameCheckbox
                  checkUser={!!rolesUser.roles?.[e.id]}
                  data={data}
                  listRole={listRoles?.[i] || {}}
                  onChange={(ev) => this.onChange(ev, i)}
                  allowEdit={allowEdit}
                  key={i.toString()}
                  name={e.name}
                />
              </th>
            );
          })}
          <th style={{ width: 100 }}>
            {!allowEdit && (
              <NameCheckbox
                checkedAll={checkedAll}
                onChange={(ev) => handleCheckAllRoleUser?.(ev.target.checked)}
                data={data}
                listRole={{}}
                allowEdit={allowEdit}
                name="Tuỳ biến"
              />
            )}
          </th>
        </tr>
      </thead>
    );
  }
}

HeaderRole.propTypes = {
  allowEdit: PropTypes.bool,
  checkedAll: PropTypes.bool,
  data: PropTypes.array,
  rolesUser: PropTypes.object,
  roles: PropTypes.array,
  onChange: PropTypes.func,
  handleCheckAllRoleUser: PropTypes.func,
  listRoles: PropTypes.array,
};

export default HeaderRole;
