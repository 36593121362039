import React from 'react';
import { useParams } from 'react-router-dom';
import { holdByCustomer } from '@Apis/admin/stock';
import useCustomQuery from '@Utils/useCustomQuery';
import Breadcrumb from '@Components/Breadcrumb';
import DataTableBasic from '@Components/DataTableBasic';
import { columns } from './components/Columns';
import { TYPE_PS, TYPE_TPLUS } from '@Components/Layouts/PrivateLayout';
import TableTPlusDOM from '@Views/Client/AssetManager/TableListPlus/TableTPlus/TableTPlusDOM';
import { columnsPS } from './components/ColumnsPS';

function StockDetail() {
  const { id, trade_type } = useParams();
  const { data } = useCustomQuery(holdByCustomer, { stock_id: id, trade_type });

  return (
    <div className="content-wrapper">
      <div className="d-flex align-items-center justify-content-between header-body mb-2">
        <Breadcrumb
          childName={
            trade_type === TYPE_PS
              ? 'Danh sách khách hàng còn vị thế mở mã hợp đồng '
              : 'Danh sách khách sở hữu mã CK '
          }
          title={
            trade_type === TYPE_PS
              ? `Danh sách khách hàng còn vị thế mở mã hợp đồng ${id}`
              : `Danh sách khách sở hữu mã CK: ${id}`
          }
        />
      </div>

      {data && trade_type !== TYPE_TPLUS && (
        <DataTableBasic columns={trade_type === TYPE_PS ? columnsPS() : columns()} data={data} />
      )}
      {data && trade_type === TYPE_TPLUS && <TableTPlusDOM data={data} />}
    </div>
  );
}

StockDetail.propTypes = {};

export default StockDetail;
