import styled from 'styled-components';

export const TransactionWrapper = styled.div`
  .transaction-dropdown {
    .dropdown {
      border-radius: 4px;
      border: 1px solid #ebeaed;
      display: flex;
      background: #fff;
      align-items: center;
      height: 48px !important;
      .nav-link {
        /* height: 100%; */
      }
    }
  }
  .transaction-expand {
    width: 50%;
  }
  .rdt_Table {
    background-color: transparent;
    border-bottom: none;
  }
  .rdt_TableHeadRow {
    background: #f8f8f8;
    border-bottom: none;
  }
  .rdt_TableHeadRow > div:first-child {
    // padding-left: 10px !important;
    display: none;
  }
  .rdt_TableRow > div:first-child {
    // padding-left: 10px !important;
    display: none;
  }
  .rdt_TableRow {
    border-bottom: none;
    border-radius: 4px;
    box-shadow: 1px 4px 4px #88888217;
    border: 1px solid #2f69b3;
  }
  .rdt_ExpanderRow {
    padding-top: 16px;
    background: #efefef;
    .table th,
    .table td {
      padding: 5px 10px;
    }
    thead {
      th {
        background-color: #efefef !important;
        font-weight: normal;
      }
    }
    tbody {
      tr {
        font-size: 14px;
      }
    }
  }
  .button-swap-ck {
    background-color: #d37e17 !important;
  }
`;
