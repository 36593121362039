/* eslint-disable jsx-a11y/no-static-element-interactions */
import { formatNumber } from '@Utils/helpers';
import React from 'react';
import P from 'prop-types';
import { useNavigate } from 'react-router-dom';

function CardInfo(props) {
  const { dataState = [], style, styleMoney, styleCard, childrenCustom } = props;
  const navigate = useNavigate();
  return (
    <div className="body-asset" style={{ ...style, paddingBottom: 0 }}>
      {dataState.map((d, i) => {
        const newStyle = !i ? styleMoney : {};
        return (
          <div
            onClick={() => d.onClick?.(navigate)}
            className="card-info"
            key={i.toString()}
            style={{ ...styleCard, cursor: d.onClick ? 'pointer' : '' }}
          >
            <div className="d-flex">
              <img src={d.icon} alt="" height={30} />
              <span style={{ marginLeft: 12, whiteSpace: 'pre' }}>{d.text}</span>
            </div>
            <div style={{ marginTop: 10, fontWeight: 600, fontSize: 28, ...newStyle, ...d.style }}>
              {formatNumber(d.money)}
            </div>
          </div>
        );
      })}
      {childrenCustom}
    </div>
  );
}

CardInfo.propTypes = {
  dataState: P.array,
  style: P.object,
  styleCard: P.object,
  styleMoney: P.object,
  childrenCustom: P.any,
};

export default CardInfo;
