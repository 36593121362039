/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-wrap-multilines */
import DropdownSelect from '@Components/DropdownSelect';
import Tooltip from '@Components/TooltipNew';
import { formatNumber } from '@Utils/helpers';
import dayjs from 'dayjs';
import React from 'react';
import { TitleDownline } from '../WarehouseLoan/components/Columns';

export const columns = (handleSelect) => [
  {
    name: 'ID',
    cell: (r) => (
      <span style={{ fontWeight: 600 }} className="uppercase">
        #{r.id || '--'}
      </span>
    ),
    width: '100px',
    fixed: 'left',
    sortable: true,
    sortField: 'id',
    center: true,
  },
  {
    name: 'Loại nguồn',
    cell: (r) => (
      <span style={{ fontWeight: 600 }} className="uppercase">
        {r.publicCustomer?.full_name || '--'}
      </span>
    ),
    width: '100px',
    fixed: 'left',
  },
  {
    name: 'Số tiền',
    cell: (r) => <div className="w-100 text-align-end">{formatNumber(r.amount)}</div>,
    center: true,
    width: '130px',
    sortable: true,
    sortField: 'amount',
    fixed: 'left',
  },
  {
    name: <TitleDownline lineOne="Ngày" lineTwo="Cấp vay" />,
    cell: (r) => dayjs.unix(r.start_at).format('DD/MM/YYYY'),
    center: true,
    width: '110px',
    fixed: 'left',
    sortable: true,
    sortField: 'start_at',
  },
  {
    name: <TitleDownline lineOne="Lãi suất" lineTwo="(Ngày)" />,
    cell: (r) => `${formatNumber(r.interest_rate, '--')}${r.interest_rate ? '%' : ''}`,
    center: true,
    width: '100px',
    sortable: true,
    sortField: 'interest_rate',
  },
  {
    name: 'Ngày kết thúc',
    cell: (r) => (r.due_at ? dayjs.unix(r.due_at).format('DD/MM/YYYY') : '--'),
    center: true,
    width: '120px',
    sortable: true,
    sortField: 'due_at',
  },
  {
    name: 'Ngày tất toán',
    cell: (r) => (r.settlement_at ? dayjs.unix(r.settlement_at).format('DD/MM/YYYY') : '--'),
    center: true,
    width: '120px',
    sortable: true,
    sortField: 'settlement_at',
  },
  {
    name: <TitleDownline lineOne="Thời gian" lineTwo="huy động" />,
    cell: (r) => formatNumber(r.number_of_days, '--'),
    center: true,
    width: '100px',
    sortable: true,
    sortField: 'number_of_days',
  },
  {
    name: <TitleDownline lineOne="Thời gian" lineTwo="Còn lại" />,
    cell: (r) => formatNumber(r.is_settlement === 0 ? r.number_of_days - r.current_days : 0, '--'),
    center: true,
    width: '100px',
    sortable: true,
    sortField: 'remaining_days',
  },
  {
    name: 'gốc & lãi',
    cell: (r) => (
      <div className="w-100 text-align-end">{formatNumber(r.amount + r.interest_amount)}</div>
    ),
    center: true,
    width: '130px',
    sortable: true,
    sortField: 'interest_amount',
  },
  {
    name: 'NV Sale',
    cell: (r) => <div className="uppercase">{r.publicUser?.username || '--'}</div>,
    width: '130px',
    sortable: true,
    sortField: 'public_user',
  },
  {
    name: <TitleDownline lineOne="Hoa hồng" lineTwo="Huy động" />,
    cell: (r) => (
      <div className="w-100 text-align-end">{formatNumber(r.commission_amount, '--')}</div>
    ),
    center: true,
    width: '130px',
    sortable: true,
    sortField: 'commission_amount',
  },
  {
    name: <TitleDownline lineOne="Lãi chưa" lineTwo="Tất toán" />,
    cell: (r) => (
      <div className="w-100 text-align-end">{formatNumber(r.provisional_amount, '--')}</div>
    ),
    center: true,
    width: '130px',
    sortable: true,
    sortField: 'provisional_amount',
  },
  {
    name: 'Lãi đã tất toán',
    cell: (r) => (
      <div className="w-100 text-align-end">
        {formatNumber(r.interest_amount - r.provisional_amount, '--')}
      </div>
    ),
    center: true,
    width: '130px',
  },
  {
    name: <TitleDownline lineOne="Lãi đã" lineTwo="Thanh toán" />,
    cell: (r) => <div className="w-100 text-align-end">{formatNumber(r.paid_amount, '--')}</div>,
    center: true,
    width: '130px',
    fixed: 'right',
    sortable: true,
    sortField: 'paid_amount',
  },
  {
    name: 'Ghi chú',
    fixed: 'right',
    width: '270px',
    cell: (r) => {
      const options = [
        {
          value: 1,
          label: <img src="/images/warehouseSource/edit.svg" alt="" />,
          disabled: r.is_settlement,
        },
        {
          value: 2,
          disabled: r.is_settlement,
          label: (
            <img
              src={
                r.is_settlement
                  ? '/images/warehouseSource/unpaid_disabled.svg'
                  : '/images/warehouseSource/unpaid.svg'
              }
              alt="unpaid"
            />
          ),
        },
        {
          disabled: r.is_paid,
          value: 3,
          label: (
            <img
              src={
                r.is_paid
                  ? '/images/warehouseSource/paid_disabled.svg'
                  : '/images/warehouseSource/paid.svg'
              }
              alt="paid"
            />
          ),
        },
      ];

      if (r.status === 'unpaid' && r.is_settlement !== 1) {
        options.push({
          value: 4,
          label: <img src="/images/warehouseSource/group_delete.svg" alt="group_delete" />,
        });
      }
      return (
        <div className="d-flex w-100" style={{ minWidth: 108 }}>
          <div style={{ minWidth: 60 }} className="d-flex align-items-center">
            {r.note ? (
              r.note.length > 60 ? (
                <Tooltip content={r.note}>
                  <span className="ml-25">{`${r.note.substr(0, 60)}...`}</span>
                </Tooltip>
              ) : (
                r.note
              )
            ) : (
              <div
                className="d-flex align-items-center"
                style={{ cursor: 'pointer', fontSize: 12, color: '#84818A' }}
              >
                <img
                  src="/images/warehouseSource/comment_empty.svg"
                  alt=""
                  style={{ marginRight: 10 }}
                />
                0
              </div>
            )}
          </div>
          <DropdownSelect
            setValueSelected={(_, v) => handleSelect?.(v, r)}
            unArrow
            style={{ border: 'none' }}
            options={options}
            labelValue="value"
            label={
              <div style={{ marginLeft: 10, cursor: 'pointer' }}>
                <img src="/images/warehouseSource/more_horiz.svg" alt="" />
              </div>
            }
          />
        </div>
      );
    },
  },
];
