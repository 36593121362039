/* eslint-disable jsx-a11y/no-autofocus */
import React from 'react';
import PropTypes from 'prop-types';
import { Button, Table } from 'reactstrap';
import SearchEmployer from '@Components/Input/SearchEmployer';
import { useState } from 'react';
import { deleteAssignProduct, saveAssignProduct } from '@Apis/admin/user';
import { hideGlobalLoading, showGlobalLoading } from '@Lib/slices/uiSlice';
import { useDispatch } from 'react-redux';
import { toastError, toastSuccess } from '@Utils/toastrHelper';
import CustomeModal from '@Components/CustomeModal';

function Assignment({ data, role, tradeType, refetch }) {
  const [isAdd, setIsAdd] = useState(false);
  const [idRemove, setIdRemove] = useState();

  const dispatch = useDispatch();

  const setValue = (key, value) => {
    if (value) {
      dispatch(showGlobalLoading());
      saveAssignProduct({
        user_id: value,
        type: role,
        product_id: tradeType,
      })
        .then(async (res) => {
          dispatch(hideGlobalLoading());
          setTimeout(() => setIsAdd(false), 200);
          refetch();
          toastSuccess('Thêm mới thành công.');
        })
        .catch((e) => {
          toastError(e);
          dispatch(hideGlobalLoading());
        });
    }
  };

  const handleConfirmRemoveAssign = () => {
    dispatch(showGlobalLoading());
    setIdRemove();
    deleteAssignProduct({ id: idRemove })
      .then(async (res) => {
        dispatch(hideGlobalLoading());
        refetch();
        toastSuccess('Xóa thành công.');
      })
      .catch((e) => {
        toastError(e);
        dispatch(hideGlobalLoading());
      });
  };

  const dataAssignment = data[role]?.filter((e) => e.product_id == tradeType) || [];
  return (
    <>
      <Table className="table-bordered">
        <thead>
          <tr>
            <th className="text-center">
              <div>
                <span>{role == 'surveyor' ? 'KSV' : 'GDV'}</span>
                <Button
                  style={{
                    position: 'absolute',
                    right: '25px',
                    top: '7px',
                  }}
                  color={isAdd ? 'warning' : 'primary'}
                  className="btn-sm"
                  onClick={() => setIsAdd(!isAdd)}
                >
                  {isAdd ? '-' : '+'}
                </Button>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          {isAdd && (
            <tr>
              <td style={{ backgroundColor: '#f2f2f2' }}>
                <SearchEmployer
                  style={{ marginBottom: 0 }}
                  labelClassName="font-size-14 mb-50"
                  name="user_id"
                  setValue={setValue}
                />
              </td>
            </tr>
          )}

          {dataAssignment && dataAssignment.length > 0 ? (
            dataAssignment.map((row) => {
              return (
                <tr key={row.id}>
                  <td style={{ position: 'relative' }}>
                    <div>
                      <span className="text-uppercase">{row.publicUser?.username}</span>
                      <div
                        style={{ position: 'absolute', right: '12px', top: '15px' }}
                        className="d-flex align-items-center"
                        onClick={() => setIdRemove(row.id)}
                        role="button"
                        tabIndex="0"
                      >
                        <img
                          style={{ marginLeft: 4 }}
                          src="/images/payment-request/cancel.svg"
                          alt=""
                        />
                      </div>
                    </div>
                  </td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td>...</td>
            </tr>
          )}
        </tbody>
      </Table>
      <CustomeModal
        isOpen={!!idRemove}
        confirm={handleConfirmRemoveAssign}
        closeModal={() => setIdRemove()}
        buttonName="Xoá"
        type="delete"
        mess="Bạn muốn xoá thiết lập này?"
      />
    </>
  );
}

Assignment.propTypes = {
  data: PropTypes.any,
  role: PropTypes.any,
  tradeType: PropTypes.any,
  refetch: PropTypes.func,
};

export default Assignment;
