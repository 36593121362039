export const TAB_PANEL = [
  {
    id: 1,
    name: 'Thông tin cá nhân',
    icon: '/images/account/info.svg',
    iconActive: '/images/account/info-active.svg',
  },
  {
    id: 2,
    name: 'Phí & tỷ lệ',
    icon: '/images/account/fees-rates.svg',
    iconActive: '/images/account/fees-rates-active.svg',
  },
  {
    id: 3,
    name: 'Tài khoản rút tiền',
    icon: '/images/account/bank-account.svg',
    iconActive: '/images/account/bank-account-active.svg',
  },
  {
    id: 4,
    name: 'Đổi mật khẩu',
    icon: '/images/account/change-password.svg',
    iconActive: '/images/account/change-password-active.svg',
  },
];
