import React, { Component, useCallback } from 'react';
import PropTypes from 'prop-types';
import Flatpickr from 'react-flatpickr';
import dayjs from 'dayjs';
import { X } from 'react-feather';
import 'flatpickr/dist/flatpickr.css';
import '../styles.scss';

function CustomInput({
  value,
  tintColor = '#2F69B3',
  placeholder = 'DD/MM/YYYY',
  inputRef,
  styleInput,
  icon,
  allowNext,
}) {
  const valuePreview1 = value[1] ? ` - ${dayjs(value[1]).format('DD/MM/YYYY')}` : '';
  const valuePreview2 = value[0] ? dayjs(value[0]).format('DD/MM/YYYY') : '';
  const valuePreview = `${valuePreview2}${valuePreview1}`;

  const renderChild = useCallback(() => {
    if (allowNext) {
      return (
        <>
          {icon || (
            <img src="/images/icon/IconDate.svg" alt="" style={{ marginRight: 5, width: 20 }} />
          )}
          {valuePreview || placeholder}
        </>
      );
    }
    return (
      <div className="d-flex justify-content-between w-100">
        {valuePreview || placeholder}
        {icon || (
          <img src="/images/icon/IconDate.svg" alt="" style={{ marginRight: 5, width: 20 }} />
        )}
      </div>
    );
  }, [allowNext, icon, valuePreview, placeholder]);

  return (
    <div ref={inputRef} className="swap-input-date">
      <div
        dir="ltr"
        className={`font-size-14 input-date${!allowNext ? ' w-100' : ''}`}
        style={{ color: tintColor, ...styleInput }}
      >
        {renderChild()}
      </div>
    </div>
  );
}

class RangePicker extends Component {
  constructor(props) {
    super(props);
    const { value, defaultValue } = props;
    this.state = { value: value || defaultValue || [] };
  }

  UNSAFE_componentWillReceiveProps(nProps) {
    const { value } = nProps;
    const { value: valueProps } = this.props;
    const { value: valueState = [] } = this.state;
    if ((value?.length !== valueState.length || value !== valueState) && value && valueProps) {
      if (!value?.length) {
        this.refFlat?.flatpickr?.clear?.();
      }
      this.setState({ value });
    }
  }

  handleClearData = () => {
    const { onChange } = this.props;
    onChange?.([]);
    this.setState({ value: [] });
    this.refFlat?.flatpickr?.clear?.();
  };

  setValue = (v) => {
    this.setState({ value: v });
  };

  handleClose = (selecteds) => {
    const { onChange } = this.props;
    if (selecteds.length === 2) {
      onChange?.(selecteds);
    } else {
      this.setState({ value: [] });
    }
  };

  render() {
    const {
      className = '',
      styleInput,
      icon,
      placeholder,
      tintColor,
      allowClear = true,
      style,
      allowNext = true,
      disabled,
    } = this.props;
    const { value } = this.state;
    return (
      <div
        className={`date-picker-custom date-picker-range ${className}`}
        style={{ paddingLeft: value?.length ? 21 : 0, ...style }}
      >
        <Flatpickr
          disableMobile
          ref={(ref) => {
            this.refFlat = ref;
          }}
          disabled={disabled}
          onClose={this.handleClose}
          options={{ mode: 'range' }}
          onChange={this.setValue}
          dateFormat="DD/MM/YYYY"
          render={(_, ref) => {
            return (
              <CustomInput
                allowNext={allowNext}
                styleInput={styleInput}
                icon={icon}
                placeholder={placeholder}
                tintColor={tintColor}
                value={value}
                inputRef={ref}
              />
            );
          }}
        />
        {value?.filter?.(Boolean)?.length === 2 && allowClear ? (
          <div
            role="button"
            onClick={this.handleClearData}
            className="icon-date-remove-swap"
            tabIndex="0"
          >
            <div className="icon-date-remove">
              <X width={13} fill="#fff" />
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

CustomInput.propTypes = {
  value: PropTypes.any,
  inputRef: PropTypes.any,
  tintColor: PropTypes.string,
  placeholder: PropTypes.string,
  styleInput: PropTypes.object,
  icon: PropTypes.any,
  allowNext: PropTypes.bool,
};

RangePicker.propTypes = {
  allowClear: PropTypes.bool,
  allowNext: PropTypes.bool,
  defaultValue: PropTypes.any,
  value: PropTypes.any,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  tintColor: PropTypes.string,
  styleInput: PropTypes.object,
  style: PropTypes.object,
  icon: PropTypes.any,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};

export default RangePicker;
