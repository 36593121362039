import React from 'react';
import Login from '../../views/Login';

export const publicConstant = [
  {
    name: 'Login',
    path: '/login',
    element: <Login />,
  },
];
