import { createSlice, createSelector, createAsyncThunk } from '@reduxjs/toolkit';
import { author, currentRole } from '@Apis/auth';
import { bankList } from '@Apis/profile/bank-account';
import { adminBankList } from '@Apis/admin/bank';

export const getProfile = createAsyncThunk('user/getInfo', async (thunkApi) => {
  try {
    return await author();
  } catch (error) {
    return thunkApi.rejectWithValue({ error: error.message });
  }
});

export const getCurrentRole = createAsyncThunk('auth/my-access-actions', async (thunkApi) => {
  try {
    return await currentRole();
  } catch (error) {
    return thunkApi.rejectWithValue({ error: error.message });
  }
});

export const handleBankList = createAsyncThunk('user/handleBankList', async (thunkApi) => {
  try {
    if (process.env.DASHBOARD_TYPE === 'admin') {
      return await adminBankList();
    }
    return await bankList();
  } catch (error) {
    return thunkApi.rejectWithValue({ error: error.message });
  }
});

const userSlice = createSlice({
  name: 'user',
  initialState: {
    info: null,
    isLoading: true,
    bankList: [],
  },
  extraReducers: {
    [getProfile.pending]: (state) => {
      state.isLoading = true;
    },
    [getProfile.fulfilled]: (state, action) => {
      const data = action.payload;
      state.info = data;
      state.isLoading = false;
    },
    [getProfile.rejected]: (state, action) => {
      localStorage.removeItem(process.env.TOKEN);
      document.location = '/login';
    },
    [handleBankList.fulfilled]: (state, action) => {
      const data = action.payload;
      state.bankList = data;
    },
    [getCurrentRole.fulfilled]: (state, action) => {
      const data = action.payload;
      state.role = data;
    },
  },
});

const { reducer } = userSlice;
export const selectUser = createSelector(
  (state) => ({
    info: state.user.info,
    isLoading: state.user.isLoading,
    bankList: state.user.bankList,
    role: state.user.role,
  }),
  (state) => state,
);

export default reducer;
