import axiosService from '@Lib/axiosService';
import { handleResponse } from '@Utils/helpers';

let host;
if (process.env.DASHBOARD_TYPE === 'customer') {
  host = `${process.env.API_CUSTOMER_URL}/trade`;
} else {
  host = `${process.env.API_ADMIN_URL}/trade`;
}

export const trade = async (params) => {
  const res = await axiosService.post(`${host}/trade`, params);
  return handleResponse(res);
};

export const tradeTPlus = async (params) => {
  const res = await axiosService.post(`${host}/trade-tplus`, params);
  return handleResponse(res);
};

export const warningTransaction = async (params) => {
  const res = await axiosService.get(`${host}/warning-transaction`, params);
  return handleResponse(res);
};
