import React from 'react';
import { formatNumber } from '@Utils/helpers';
import dayjs from 'dayjs';

function renderType(type, arrayText = ['Nộp tiền', 'Rút tiền']) {
  if (type === 'deposit') {
    return (
      <div className="span-type span-buy span-color-buy">
        <span>{arrayText[0]}</span>
      </div>
    );
  }
  return (
    <div className="span-type span-sell span-color-sell">
      <span>{arrayText[1]}</span>
    </div>
  );
}

function renderStatus(type) {
  if (type === 'completed') {
    return (
      <div className="span-color-buy">
        <span>Đã duyệt</span>
      </div>
    );
  }
  if (type === 'pending') {
    return (
      <div className="span-color-pending">
        <span>Chờ duyệt</span>
      </div>
    );
  }
  return (
    <div className="span-color-sell">
      <span>Đã Huỷ</span>
    </div>
  );
}

export const columns = (page, pageSize, onOpenModal) => [
  {
    name: 'Stt',
    cell: (_, index) => <div className="">{index + 1 + (page - 1) * pageSize}</div>,
    center: true,
    width: '50px',
  },
  {
    name: 'Loại',
    cell: (row) => <div className="">{renderType(row.type)}</div>,
    center: true,
    width: '120px',
  },
  {
    name: 'Số tiền',
    cell: (row) => (
      <div style={{ width: '100%', textAlign: 'right' }}>{formatNumber(row.amount, '--')}</div>
    ),
    width: '130px',
    center: true,
  },
  {
    name: 'NH  nộp/rút',
    cell: (row) => {
      const account = row.type === 'deposit' ? row.processBankAccount : row.bankAccount;
      return <div className="font-weight-bolder">{account?.bank?.name || '--'}</div>;
    },
    width: '120px',
  },
  {
    name: 'Ngày nộp/rút ',
    cell: (row) => (
      <div className="font-weight-bolder">
        {row.created_at ? dayjs.unix(row.created_at).format('DD/MM/YYYY') : '--'}
      </div>
    ),
    center: true,
    width: '140px',
  },
  {
    name: 'Trạng thái',
    cell: (row) => <div className="font-weight-bolder">{renderStatus(row.status)}</div>,
    width: '120px',
  },
  {
    name: 'Ghi chú',
    cell: (row) => <div className="font-weight-bolder">{row.note || '--'}</div>,
  },
  {
    name: '',
    cell: (row) => {
      if (row.status === 'pending') {
        return (
          <div className="d-flex justify-content-end w-100">
            <div
              style={{ color: '#FC3400', fontWeight: 600, cursor: 'pointer' }}
              onClick={() => onOpenModal({ isOpenModal: true, typeOpen: 'delete', rowId: row.id })}
              role="button"
              tabIndex="0"
            >
              <span>Hủy</span>
              <img style={{ marginLeft: 4 }} src="/images/payment-request/cancel.svg" alt="" />
            </div>
          </div>
        );
      }
    },
    width: '65px',
  },
];
