import styled from 'styled-components';

export const TableWrapper = styled.div`
  border-top: 1px solid #ebeaed;
  .rdt_TableCol:nth-child(0) {
    // border-left;
  }
  .warehouse-left {
    flex-grow: 1;
    text-align: center;
    div:nth-child(1) {
      flex-basis: 50px;
    }
    div:nth-child(2) {
      flex-basis: 50px;
    }
  }
  .warehouse-center {
    flex-grow: 1;
    text-align: center;
    /* div:nth-child(1) {
      flex-basis: 70px;
    } */
    div:nth-child(4) {
      flex-basis: 100px;
    }
  }
`;
