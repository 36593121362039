import React, { useCallback, useState } from 'react';
import NavigationBar from '@Components/NavigationBar';
import FilterComponent from '@Components/FilterComponent';
import { tradeExport } from '@Apis/admin/trade';
import { paymentExport } from '@Apis/admin/paymentRequest';
import { customerExport } from '@Apis/admin/customer';
import PropTypes from 'prop-types';
import { useSearchParams } from 'react-router-dom';
import DatePicker from '@Components/DatePicker';
import dayjs from 'dayjs';
import TransactionTab3 from './TransactionTab3';
import TransactionStock from './TransactionStock';
import TransactionRequestPayment from './TransactionRequestPayment';

function Transaction({ accountId }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [activeTabId, setActiveTabId] = useState(1);
  const [filter, setFilter] = useState();

  const onSearch = useCallback((fil) => {
    setFilter((pre) => ({ ...pre, ...fil }));
  }, []);

  const onExport = useCallback(
    async (fil) => {
      let api = paymentExport;
      if (activeTabId === 1) {
        api = tradeExport;
      }
      if (activeTabId === 2) {
        api = customerExport;
      }
      return api({ ...filter, ...fil });
    },
    [filter, activeTabId],
  );

  const renderFilter = useCallback(() => {
    let searchArray = [];
    if (activeTabId === 1) {
      searchArray = [
        {
          key: 'type',
          label: 'Loại',
          width: 130,
          options: [
            {
              label: 'Mua',
              value: 'buy',
            },
            {
              label: 'Bán',
              value: 'sell',
            },
          ],
        },
        {
          key: 'stock_id',
          label: 'Mã Chứng khoán',
          width: 130,
        },
      ];
    }
    if (activeTabId === 0) {
      searchArray = [
        {
          key: 'type',
          label: 'Loại',
          width: 130,
          options: [
            {
              label: 'Nộp tiền',
              value: 'deposit',
            },
            {
              label: 'Rút tiền',
              value: 'withdraw',
            },
          ],
        },
      ];
    }

    if (activeTabId === 2) {
      searchArray = [
        {
          key: 'type',
          label: 'Loại',
          width: 130,
          options: [
            {
              label: 'Nộp tiền',
              value: 'deposit',
            },
            {
              label: 'Chuyển nội bộ',
              value: 'transfer-out',
            },
            {
              label: 'Nhận nội bộ',
              value: 'transfer-in',
            },
            {
              label: 'Rút tiền',
              value: 'withdraw',
            },
            {
              label: 'Phí giao dịch',
              value: 'trade-fee',
            },
            {
              label: 'Lãi vay',
              value: 'interest',
            },
            {
              label: 'Lãi thiếu cọc',
              value: 'paybook',
            },
          ],
        },
      ];
    }

    return [
      ...searchArray,
      {
        key: 'date_range',
        label: 'Chọn ngày',
        width: 130,
        Component: DatePicker.Range,
        type: 'onChange',
        parseChange: (v) =>
          v
            .map?.((e) => dayjs(e).unix())
            ?.toString()
            ?.replace(',', '-'),
        parseValue: (v) => (v ? v?.split('-')?.map((e) => dayjs.unix(e).toDate()) : []),
      },
    ];
  }, [activeTabId]);

  return (
    <div className="bg-white p-2 mb-2">
      <div className="d-flex align-items-center justify-content-between">
        <p className="font-size-24">Giao dịch</p>
        <FilterComponent onExport={onExport} onSearch={onSearch} filters={renderFilter()} />
      </div>
      <div className="mb-1">
        <NavigationBar
          tabs={[
            {
              id: 1,
              name: 'Sổ lệnh',
            },
            {
              id: 0,
              name: 'Nộp/Rút',
            },
            {
              id: 2,
              name: 'Giao dịch tiền',
            },
          ]}
          activeTab={activeTabId}
          setActiveTab={(id) => {
            setActiveTabId(id);
            setFilter();
            searchParams.delete('type');
            searchParams.delete('stock_id');
            searchParams.delete('date_range');
            setSearchParams(searchParams);
            // navigate(`/customer/detail/${accountId || params.customerId}`);
          }}
        />
      </div>
      {activeTabId === 0 && (
        <TransactionRequestPayment
          accountId={accountId}
          filter={filter}
          setWrapFilter={setFilter}
        />
      )}
      {activeTabId === 1 && (
        <TransactionStock accountId={accountId} filter={filter} setWrapFilter={setFilter} />
      )}
      {activeTabId === 2 && (
        <TransactionTab3 accountId={accountId} filter={filter} setWrapFilter={setFilter} />
      )}
    </div>
  );
}

Transaction.propTypes = {
  accountId: PropTypes.any,
};

export default Transaction;
