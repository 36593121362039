/* eslint-disable jsx-a11y/control-has-associated-label */
import { stockAsset } from '@Apis/client/stock';
import useCustomQuery from '@Utils/useCustomQuery';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import DataTableBasic from '@Components/DataTableBasic';
import { columnsViTheMo } from '@Views/Client/TransactionPS/columns';
import { customerAssets } from '@Apis/admin/customer';
import { TYPE_PS } from '@Components/Layouts/PrivateLayout';

function TablePS({ accountId }) {
  const [page, setStatePage] = useState(1);

  const { data } = useCustomQuery(process.env.DASHBOARD_TYPE === 'admin' ? customerAssets : stockAsset, {
    trade_type: TYPE_PS,
    customer_id: accountId,
  });

  return (
    <div className="transaction-wrapper">
      <DataTableBasic
        setPage={setStatePage}
        page={page}
        data={{data: data?.assets, paging: data?.paging}}
        columns={columnsViTheMo}
      />
    </div>
  );
}

TablePS.propTypes = {
  accountId: PropTypes.any,
};

export default TablePS;
