/* eslint-disable react/jsx-wrap-multilines */
import FieldInput from '@Views/Client/Recharge/BodyTab/Request/FieldInput';
import React, { Component } from 'react';
import P from 'prop-types';
import DatePicker from '@Components/DatePicker';
import ButtonSubmit from '@Components/ButtonSubmit';
import dayjs from 'dayjs';
import { selectCustomer } from '@Apis/admin/customer';
import { saveDebt } from '@Apis/admin/loan';
import { toastError, toastSuccess } from '@Utils/toastrHelper';
import SelectUser from './SelectUser';

class FormUpdate extends Component {
  constructor(props) {
    super(props);
    this.state = { values: props.data, errors: {} };
  }

  UNSAFE_componentWillReceiveProps(nProps) {
    const { data } = this.props;
    const { values } = this.state;
    if (data?.optionsSelect !== nProps.data?.optionsSelect) {
      this.setState({
        values: { ...values, optionsSelect: nProps.data?.optionsSelect },
      });
    }
  }

  handleGetSet = (values) => {
    if (values.optionsSelect === 'unpaid') {
      return 0;
    }
    return 1;
  };

  handleGetStatus = (values) => {
    if (values.optionsSelect === 'provisional') {
      return values.status;
    }
    return values.optionsSelect;
  };

  handleClick = () => {
    const { values } = this.state;
    const { onClose, isUpdate, reload } = this.props;
    const errors = {};
    if (!values?.customer_id && values?.customer_id !== 0) {
      errors.customer_id = 'Vui lòng chọn loại nguồn';
    }
    if (!values?.user_id && values?.user_id !== 0) {
      errors.user_id = 'Vui lòng chọn nhân viên';
    }
    if (!values?.start_at) {
      errors.start_at = 'Vui lòng chọn ngày chọn vay';
    }
    if (!values?.amount && values?.amount !== 0) {
      errors.amount = 'Vui lòng nhập số tiền';
    }
    if (!values?.due_at) {
      errors.due_at = 'Vui lòng chọn ngày kết thúc';
    }
    if (!values?.interest_rate && values?.interest_rate !== 0) {
      errors.interest_rate = 'Vui lòng nhập lãi suất';
    }
    this.setState({ errors });
    if (!Object.keys(errors).length) {
      const dataReq = {
        customer_id: values.customer_id,
        start_at: values.start_at,
        settlement_at: values.settlement_at,
        interest_rate: values.interest_rate,
        due_at: dayjs.unix(values.due_at).endOf('d').unix(),
        note: values.note,
        is_settlement: this.handleGetSet(values),
        amount: values.amount,
        comission_percent: values.comission_percent,
        user_id: values.user_id,
        status: this.handleGetStatus(values),
        type: 'loan',
        interest_type: 'year',
      };
      if (values.id) {
        dataReq.id = values.id;
      }
      if (isUpdate) {
        // delete dataReq.type;
        // delete dataReq.status;
        // delete dataReq.customer_id;
        // delete dataReq.user_id;
        // edit(dataReq)
        //   .then(() => {
        //     toastSuccess('Cập nhật thành công');
        //     reload?.();
        //   })
        //   .catch(toastError);
      }
      // else {
      saveDebt(dataReq)
        .then(() => {
          toastSuccess('Tạo mới thành công');
          reload?.();
        })
        .catch(toastError);
      // }
      onClose();
    }
  };

  onChangeField = (field, value) => {
    const { values, errors } = this.state;
    this.setState({
      values: { ...values, [field]: value },
      errors: { ...errors, [field]: undefined },
    });
  };

  render() {
    const { isUpdate, onClose, isDetail } = this.props;
    const { values, errors } = this.state;
    const styleDisabled = { background: '#EBEAED' };
    return (
      <div>
        <div className="form-update" style={{ paddingTop: 35 }}>
          <div className="row1">
            <div className="w-100" style={{ marginRight: 12 }}>
              <SelectUser
                placeholder="Chọn loại nguồn"
                filter={{ type: 'debt' }}
                errorMessage={errors?.customer_id}
                required
                onChange={(v) => this.onChangeField('customer_id', v)}
                api={selectCustomer}
                label="Loại nguồn"
                value={values?.customer_id}
                labelKey="full_name"
              />
            </div>
            <div className="w-100" style={{ margin: '0 12px' }}>
              <FieldInput
                styleInput={isDetail ? styleDisabled : {}}
                disabled={isDetail}
                required
                type="number"
                placeholder="Số tiền"
                label="Số tiền"
                value={values?.amount}
                onChange={(v) => this.onChangeField('amount', v)}
                errorMessage={errors?.amount}
              />
            </div>
            <div className="w-100" style={{ margin: '0 12px' }}>
              <FieldInput
                disabled={!!isDetail}
                required
                styleInput={isDetail ? styleDisabled : {}}
                errorMessage={errors?.interest_rate}
                type="number"
                value={values?.interest_rate}
                placeholder="Lãi suất (ngày)"
                label="Lãi suất (ngày)"
                init="%"
                onChange={(v) => this.onChangeField('interest_rate', v)}
              />
            </div>
          </div>
          <div className="row1" style={{ marginTop: 24 }}>
            <div className="w-100" style={{ marginRight: 12 }}>
              <FieldInput
                required
                errorMessage={errors?.start_at}
                component={
                  <DatePicker
                    allowNext={false}
                    placeholder="--/--/--"
                    onChange={(v) => this.onChangeField('start_at', dayjs(v).unix())}
                    allowClear={false}
                    defaultValue={values?.start_at ? dayjs.unix(values.start_at) : undefined}
                    placeholderColor="#7d7d7d"
                  />
                }
                placeholder="Chọn ngày"
                label="Ngày cấp vay"
              />
            </div>
            <div className="w-100" style={{ margin: '0 12px' }}>
              <FieldInput
                required
                errorMessage={errors?.due_at}
                component={
                  <DatePicker
                    disabled={isDetail}
                    allowNext={false}
                    placeholder="--/--/--"
                    onChange={(v) => this.onChangeField('due_at', dayjs(v).unix())}
                    allowClear={false}
                    value={values?.due_at ? dayjs.unix(values.due_at) : undefined}
                    placeholderColor="#7d7d7d"
                  />
                }
                placeholder="Chọn ngày"
                label="Ngày kết thúc"
              />
            </div>
            <div className="w-100" style={{ margin: '0 12px' }}>
              <SelectUser
                label="Nhân viên Sale"
                required
                errorMessage={errors?.user_id}
                onChange={(v) => this.onChangeField('user_id', v)}
                value={values?.user_id}
              />
            </div>
            <div className="w-100" style={{ margin: '0 12px' }}>
              <FieldInput
                styleInput={isDetail ? styleDisabled : {}}
                disabled={isDetail}
                required
                type="number"
                placeholder="% hoa hồng"
                label="% hoa hồng"
                value={values?.comission_percent}
                onChange={(v) => this.onChangeField('comission_percent', v)}
                errorMessage={errors?.comission_percent}
              />
            </div>
          </div>
          <div className="row1" style={{ marginTop: 12 }}>
            <div className="w-100" style={{ marginRight: 12 }}>
              <FieldInput
                value={(values?.interest_amount || 0) + (values?.amount || 0)}
                disabled
                type="number"
                label="Gốc & Lãi"
                styleInput={styleDisabled}
              />
            </div>
            <div className="w-100" style={{ margin: '0 12px' }}>
              <FieldInput
                value={values?.provisional_amount}
                styleInput={styleDisabled}
                disabled
                type="number"
                label="Lãi chưa tất toán"
              />
            </div>
            <div className="w-100" style={{ margin: '0 12px' }}>
              <FieldInput
                styleInput={styleDisabled}
                disabled
                type="number"
                value={values?.unpaid_amount}
                label="Lãi tất toán"
              />
            </div>
            <div className="w-100" style={{ margin: '0 12px' }}>
              <FieldInput
                value={values?.commission_amount}
                styleInput={styleDisabled}
                disabled
                type="number"
                label="Hoa hồng huy động"
              />
            </div>
          </div>
          <div className="w-100" style={{ border: '1px dashed #84818A', margin: '23px 0' }} />
          <div className="row1" style={{ marginTop: 12 }}>
            <div className="w-100">
              <FieldInput.TextArea
                styleInput={{ textTransform: 'uppercase' }}
                disabled={isDetail}
                onChange={(v) => this.onChangeField('note', v)}
                label="Ghi chú"
                value={values?.note}
              />
            </div>
          </div>
        </div>
        <div style={{ border: '1px solid #EBEAED', margin: '22px 0' }} className="w-100" />
        <div style={{ paddintTop: 6 }} className="d-flex justify-content-center">
          {!isDetail && (
            <ButtonSubmit
              onClick={this.handleClick}
              name={isUpdate ? 'Lưu' : 'Thêm'}
              style={{ marginRight: 14, minWidth: 164, height: 48 }}
              color="primary"
            />
          )}
          <ButtonSubmit
            onClick={onClose}
            name={isDetail ? 'Đóng' : 'Huỷ'}
            style={{ marginLeft: 14, minWidth: 164, height: 48 }}
          />
        </div>
      </div>
    );
  }
}

FormUpdate.propTypes = {
  isUpdate: P.bool,
  isDetail: P.bool,
  onClose: P.func,
  reload: P.func,
  data: P.object,
};

export default FormUpdate;
