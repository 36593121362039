import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import TextInput from '@Components/Input/TextInput';
import {
  getQuantityMatching,
  getPriceMatching,
  getQuantityMatchPartCompleted,
} from '@Utils/tradeHelpers';
import { TransactionBuyedWrapper } from '../../styles';

function TransactionBuyed({ register, setValue, errors, itemDetail, priceMatching }) {
  const [items, setItems] = useState([]);
  const quantityMatchPartCompleted = itemDetail ? getQuantityMatchPartCompleted(itemDetail) : 0;

  useEffect(() => {
    const { buyItems, ...restData } = itemDetail;
    const combined = [].concat(restData, buyItems);
    setItems(combined);
  }, [itemDetail]);
  return (
    <TransactionBuyedWrapper>
      <div className="items-buyed">
        {items.map((item) => {
          const quantityMatching = getQuantityMatching(item);
          if (quantityMatching > 0) {
            return (
              <div className="item-buyed" key={item.id}>
                <TextInput
                  labelCol="12"
                  inputCol="12"
                  name={`quantity-${item.id}-${getQuantityMatching(item)}`}
                  type="quantity"
                  inputClassName="item-content"
                  placeholder="KL Khớp"
                  setValue={setValue}
                  defaultValue={quantityMatching}
                  disabled
                />
                <TextInput
                  labelCol="12"
                  inputCol="12"
                  type="price"
                  name={`price-${item.id}`}
                  inputClassName="item-content"
                  placeholder="Giá Khớp"
                  setValue={setValue}
                  defaultValue={getPriceMatching(item)}
                  disabled
                />
              </div>
            );
          }
          return '';
        })}
        {!itemDetail.is_completed && (
          <div className="item-buyed">
            <TextInput
              labelCol="12"
              inputCol="12"
              type="price"
              inputClassName="item-content"
              placeholder="KL Khớp"
              setValue={setValue}
              name="quantity"
              register={{
                ...register('quantity', {
                  required: true,
                  validate: (value) => {
                    if (value > itemDetail.quantity - quantityMatchPartCompleted) {
                      return 'Khới lượng không hợp lệ';
                    }
                  },
                }),
              }}
              errors={errors}
              defaultValue={itemDetail.quantity - quantityMatchPartCompleted}
            />
            <TextInput
              labelCol="12"
              inputCol="12"
              type="price"
              inputClassName="item-content"
              placeholder="Giá Khớp"
              setValue={setValue}
              name="price"
              register={{ ...register('price', { required: true }) }}
              errors={errors}
              defaultValue={priceMatching}
            />
          </div>
        )}
      </div>
    </TransactionBuyedWrapper>
  );
}

TransactionBuyed.propTypes = {
  items: PropTypes.array,
  register: PropTypes.func,
  errors: PropTypes.object,
  setValue: PropTypes.func,
  itemDetail: PropTypes.object,
  priceMatching: PropTypes.number,
};

export default TransactionBuyed;
