/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'reactstrap';
import { formatNumber } from '@Utils/helpers';
import TableEmpty from '@Components/DataTableBasic/TableEmpty';
import { TableWrapper } from '../../styled';

function ReportTable({ data, isPS }) {
  const dateArray = data?.titles?.map((e) => e.label);
  const totalEntire = data?.titles?.reduce?.((a, b) => a + b.total, 0);
  const totalPositionEntire = data?.titles?.reduce?.((a, b) => a + b.total, 0);
  const totalPositionOvernightEntire = data?.titles?.reduce?.(
    (a, b) => a + b.total_quantity_overnight || 0,
    0,
  );

  return (
    <>
      {data ? (
        <TableWrapper style={{ marginLeft: isPS ? 650 : 540 }}>
          <Table>
            <thead>
              <tr className="">
                <th className="headcol sub-head" style={{ minHeight: 45 }} />
                {isPS ? (
                  <>
                    <th
                      style={{
                        textAlign: 'center',
                        boxShadow: '7px 0px 15px rgb(153 153 153 / 12%)',
                        left: 290,
                        width: 100,
                        margin: 0,
                        height: 45,
                      }}
                      className="headcol sub-head"
                    ></th>
                    <th
                      style={{
                        textAlign: 'center',
                        boxShadow: '7px 0px 15px rgb(153 153 153 / 12%)',
                        left: 390,
                        width: 150,
                        padding: '0.72rem 10px',
                        margin: 0,
                        height: 45,
                      }}
                      className="headcol sub-head"
                    >
                      TỔNG VỊ THẾ
                    </th>
                    <th
                      style={{
                        textAlign: 'center',
                        boxShadow: '7px 0px 15px rgb(153 153 153 / 12%)',
                        left: 540,
                        margin: 0,
                        height: 45,
                        width: 150,
                        padding: '0.72rem 10px',
                      }}
                      className="headcol sub-head"
                    >
                      TỔNG VT QUA ĐÊM
                    </th>
                  </>
                ) : (
                  <th
                    style={{
                      textAlign: 'center',
                      boxShadow: '7px 0px 15px rgb(153 153 153 / 12%)',
                      left: 290,
                      margin: 0,
                      height: 45,
                    }}
                    className="headcol sub-head"
                  >
                    TỔNG GTGD
                  </th>
                )}

                {data.titles &&
                  data.titles.length > 0 &&
                  data.titles.map((item) => (
                    <th colSpan={isPS ? 2 : 1} key={item.to} style={{ borderRight: isPS ? '1px solid #ddd' : '' }}>
                      {item.label}
                    </th>
                  ))}
              </tr>
            </thead>
            <tbody>
              <tr style={{ background: '#375c8a' }}>
                <th
                  className="headcol sub-head"
                  style={{ height: '45px', background: '#375c8a', color: '#fff' }}
                >
                  <div className="d-flex align-items-center">
                    <span
                      className=""
                      style={{ color: '#fff', textAlign: 'center', paddingRight: 25 }}
                    >
                      ID
                    </span>
                    <span className="">KHÁCH HÀNG</span>
                  </div>
                </th>

                <th
                  className="headcol text-center"
                  style={{
                    textAlign: 'center',
                    boxShadow: '7px 0px 15px rgb(153 153 153 / 12%)',
                    left: 290,
                    width: 100,
                    padding: '0.72rem 10px',
                    margin: 0,
                    minHeight: 45,
                    fontWeight: 600,
                    background: '#375c8a',
                    color: '#fff',
                  }}
                >
                  <span style={{ fontSize: 14 }}>MÃ SALE</span>
                </th>

                {isPS && (
                  <th
                    className="headcol text-center"
                    style={{
                      textAlign: 'center',
                      boxShadow: '7px 0px 15px rgb(153 153 153 / 12%)',
                      left: 390,
                      width: 150,
                      margin: 0,
                      minHeight: 45,
                      fontWeight: 600,
                      background: '#375c8a',
                      color: '#fff',
                    }}
                  >
                    <span style={{ fontSize: 14 }}>
                      {formatNumber(`${totalPositionEntire}`, '--')}
                    </span>
                  </th>
                )}

                <th
                  className="headcol text-center"
                  style={{
                    textAlign: 'center',
                    boxShadow: '7px 0px 15px rgb(153 153 153 / 12%)',
                    left: isPS ? 540 : 290,
                    width: isPS ? 150 : 290,
                    margin: 0,
                    minHeight: 45,
                    fontWeight: 600,
                    background: '#375c8a',
                    color: '#fff',
                  }}
                >
                  <span style={{ fontSize: 14 }}>
                    {formatNumber(`${isPS ? totalPositionOvernightEntire : totalEntire}`)}
                  </span>
                </th>
                {data.titles &&
                  data.titles.length > 0 &&
                  data.titles.map((item) => {
                    if (isPS) {
                      return (
                        <React.Fragment key={item.label}>
                          <th style={{ textAlign: 'end', color: '#fff' }}>
                            {formatNumber(item.total)}
                          </th>
                          <th style={{ textAlign: 'end', color: '#fff', borderRight: '1px solid #ddd' }}>
                            {formatNumber(item.total_quantity_overnight)}
                          </th>
                        </React.Fragment>
                      );
                    } else {
                      return (
                        <th key={item.label} style={{ textAlign: 'end', color: '#fff' }}>
                          {formatNumber(item.total)}
                        </th>
                      );
                    }
                  })}
              </tr>
              {data.customers &&
                data.customers.length > 0 &&
                data.customers.map((item, ii) => {
                  const { reports } = item;
                  const total = reports?.reduce?.((a, b) => {
                    return a + b.total;
                  }, 0);
                  const totalPosition = reports?.reduce?.((a, b) => {
                    return a + b.total;
                  }, 0);
                  const totalPositionOvernight = reports?.reduce?.((a, b) => {
                    return a + b.total_quantity_overnight || 0;
                  }, 0);
                  return (
                    <tr
                      className=""
                      key={ii.toString()}
                      style={{ background: !(ii % 2) ? '#f8f8f8' : '#fff' }}
                    >
                      <th
                        style={{ background: !(ii % 2) ? '#f8f8f8' : '#fff' }}
                        className="headcol sub-head"
                      >
                        <div className="d-flex align-items-center">
                          <span className="">{item.code}</span>
                          <span className="color-black uppercase" style={{ zIndex: 1 }}>
                            {item.username}
                          </span>
                        </div>
                      </th>
                      <th
                        className="headcol headcol-child"
                        style={{
                          boxShadow: '7px 0px 15px rgb(153 153 153 / 12%)',
                          left: 290,
                          width: 100,
                          margin: 0,
                          background: !(ii % 2) ? '#f8f8f8' : '#fff',
                          textAlign: 'center',
                        }}
                      >
                        -
                      </th>
                      {isPS && (
                        <th
                          className="headcol headcol-child"
                          style={{
                            boxShadow: '7px 0px 15px rgb(153 153 153 / 12%)',
                            left: 390,
                            width: 150,
                            margin: 0,
                            background: !(ii % 2) ? '#f8f8f8' : '#fff',
                            textAlign: 'center',
                          }}
                        >
                          {formatNumber(totalPosition)}
                        </th>
                      )}
                      <th
                        className="headcol headcol-child"
                        style={{
                          boxShadow: '7px 0px 15px rgb(153 153 153 / 12%)',
                          left: isPS ? 540 : 290,
                          width: isPS ? 150 : 290,
                          margin: 0,
                          background: !(ii % 2) ? '#f8f8f8' : '#fff',
                          textAlign: isPS ? 'center' : 'end',
                          paddingRight: isPS ? null : 100,
                        }}
                      >
                        {formatNumber(isPS ? totalPositionOvernight : total)}
                      </th>
                      {reports &&
                        reports.length > 0 &&
                        reports.map((report, i) => {
                          if (dateArray.includes(report.date)) {
                            if (isPS) {
                              return (
                                <React.Fragment key={i.toString()}>
                                  <td style={{ textAlign: 'end' }}>{formatNumber(report.total)}</td>
                                  <td style={{ textAlign: 'end', borderRight: '1px solid #ddd' }}>
                                    {formatNumber(report.total_quantity_overnight)}
                                  </td>
                                </React.Fragment>
                              );
                            } else {
                              return (
                                <td key={i.toString()} style={{ textAlign: 'end' }}>
                                  {formatNumber(report.total)}
                                </td>
                              );
                            }
                          }
                        })}
                    </tr>
                  );
                })}
            </tbody>
          </Table>
        </TableWrapper>
      ) : (
        <TableEmpty />
      )}
    </>
  );
}

ReportTable.propTypes = {
  data: PropTypes.any,
  isPS: PropTypes.any,
};

export default ReportTable;
