/* eslint-disable indent */
import React from 'react';
import { formatNumber, parseNumber, parsePrice } from '@Utils/helpers';
import { Link } from 'react-router-dom';
import { renderVTHT } from '@Views/Client/TransactionTPlus';
import { TYPE_TPLUS } from '@Components/Layouts/PrivateLayout';

export const tplusActiveColumns = () => {
  const trade_type = TYPE_TPLUS;
  return [
    {
      name: 'MÃ CK',
      cell: (row) => <div className="font-weight-bolder">{row.stock_id || row.id}</div>,
      center: true,
      sortable: true,
      sortField: 'id',
      width: '170px',
    },
    {
      name: 'GIÁ HIỆN TẠI',
      cell: (row) => (
        <div className="w-100 text-align-end">
          {parsePrice(typeof row.price === 'number' ? row.price : row.stock?.price)}
        </div>
      ),
      right: true,
      sortable: true,
      sortField: 'price',
      width: '170px',
    },
    {
      name: 'TỔNG VỊ THẾ',
      cell: (row) => (
        <div className="w-100 text-align-end">{renderVTHT(row.spotTotalQuantity?.quantity)}</div>
      ),
      center: true,
      width: '170px',
      sortable: true,
    },
    {
      name: 'SỐ KH SỞ HỮU',
      cell: (row) => (
        <div className="text-primary">{formatNumber(row.spotCustomerCount?.count, 0)}</div>
      ),
      right: true,
      width: '170px',
      sortable: true,
      sortField: 'customer_count',
    },
    {
      name: '',
      left: true,
      cell: (row) => (
        <Link className="d-flex align-items-center ml-1" to={`/stock/${trade_type}/${row.id}`}>
          <span className="text-primary font-weight-bolder mr-50">Chi tiết</span>
          <img src="/images/icon/arrow-box.svg" alt="view-detail" />
        </Link>
      ),
    },
  ];
};
