/* eslint-disable no-confusing-arrow */
/* eslint-disable no-nested-ternary */
import { formatNumber } from '@Utils/helpers';
import React from 'react';

export const columns = (navigate) => [
  {
    name: 'Tên Kho',
    cell: (row) => (row.name ? row.name : row.id ? '--' : ''),
    width: 'auto',
  },
  {
    name: 'GT CK',
    cell: (row) => (
      <div style={{ width: '100%', textAlign: 'right' }}>{formatNumber(row.stock_value, '--')}</div>
    ),
    center: true,
    width: 'auto',
  },
  {
    name: 'NAV',
    cell: (row) => (
      <div style={{ width: '100%', textAlign: 'right' }}>{formatNumber(row.total_nav, '--')}</div>
    ),
    center: true,
    width: 'auto',
  },
  {
    name: 'Nợ Margin',
    cell: (row) => (
      <div style={{ width: '100%', textAlign: 'right' }}>{formatNumber(row.margin_debt, '--')}</div>
    ),
    center: true,
    width: 'auto',
  },
  {
    name: 'Sức mua tối đa',
    cell: (row) => (
      <div style={{ width: '100%', textAlign: 'right' }}>{formatNumber(row.buy_ability, '--')}</div>
    ),
    center: true,
    width: '140px',
  },
  {
    name: '',
    cell: (row) =>
      row.id ? (
        <div
          role="button"
          tabIndex="0"
          style={{ width: '100%', display: 'flex', justifyContent: 'flex-end', marginRight: 26 }}
          onClick={() => navigate('/warehouse/account')}
        >
          <span className="text-danger font-weight-bolder mr-50">Chi tiết</span>
          <img src="/images/icon/arrow-box-red.svg" alt="view-detail" />
        </div>
      ) : (
        ''
      ),
  },
];
