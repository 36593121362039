import React, { useRef, useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useCustomQuery from '@Utils/useCustomQuery';
import { userDetail, userLock } from '@Apis/admin/user';
import Breadcrumb from '@Components/Breadcrumb';
import NavigationBar from '@Components/NavigationBar';
import { Button } from 'reactstrap';
import { Lock } from '@mui/icons-material';
import useCustomMutation from '@Utils/useCustomMuation';
import CustomeModal from '@Components/CustomeModal';
import { toastError, toastSuccess } from '@Utils/toastrHelper';
import { TABS } from './constants';
import Commission from './component/Commission';
import Modify from './component/Modify';
import Role from './component/Role';

function EmployeeDetail() {
  const { employeeId } = useParams();
  const navigate = useNavigate();
  const refForm = useRef();
  const submitForm = () => {
    refForm.current.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
  };
  const [employerLock, setEmployerLock] = useState(null);

  const { data } = useCustomQuery(userDetail, { id: employeeId }, true);
  const [activeTabId, setActiveTabId] = useState(1);

  const {
    mutate: mutateLock,
    isSuccess: isSuccessLock,
    error: errorLock,
  } = useCustomMutation(userLock, true);

  const onConfirm = () => {
    const { id, status } = data;
    mutateLock({
      id,
      is_lock: status === 'active' ? true : false,
    });
  };

  useEffect(() => {
    if (isSuccessLock) {
      const { status } = data;
      const state = status === 'active' ? 'Khoá' : 'Mở khoá';
      toastSuccess(`${state} nhân viên thành công`);
      navigate('/employee');
    }
    if (errorLock) {
      toastError(errorLock);
    }
  }, [isSuccessLock, errorLock]);

  return (
    <div className="content-wrapper detail-customer">
      {data ? (
        <>
          <div className="d-flex align-items-center justify-content-between header-body mb-2">
            <Breadcrumb
              childName="Chi tiết nhân viên"
              title={`${data.username} (ID: ${data.id})`}
            />
            <div className="d-flex align-items-center gap-20">
              {activeTabId !== 1 ? null : (
                <Button color="primary" className="font-size-14 " onClick={() => submitForm()}>
                  Lưu thay đổi
                </Button>
              )}
              <Button
                color={`${data.status === 'active' ? 'secondary' : 'success'}`}
                className="font-size-14"
                onClick={() => setEmployerLock(data)}
              >
                <Lock fontSize="14px" className="mr-50" />
                {data.status === 'active' ? 'Khoá' : 'Mở khoá'}
              </Button>
            </div>
          </div>
          <div className="d-line" />
          <div className="mb-2">
            <NavigationBar tabs={TABS} activeTab={activeTabId} setActiveTab={setActiveTabId} />
          </div>
          {activeTabId === 0 && <Commission />}
          {activeTabId === 1 && <Modify refForm={refForm} item={data} />}
          {activeTabId === 2 && <Role item={data} />}
        </>
      ) : (
        <>
          <div className="d-flex align-items-center justify-content-between header-body mb-2">
            <Breadcrumb childName=" Thêm nhân viên" title="Thêm nhân viên" />
            <div className="d-flex align-items-center gap-20">
              <Button
                color="primary"
                className="font-size-14"
                style={{ height: 48 }}
                onClick={() => submitForm()}
              >
                Lưu thay đổi
              </Button>
              <Button
                style={{ height: 48 }}
                outline
                className="font-size-14"
                onClick={() => navigate('/employee')}
              >
                Hủy
              </Button>
            </div>
          </div>
          <Modify refForm={refForm} item={data} />
        </>
      )}
      <CustomeModal
        isOpen={employerLock !== null}
        confirm={onConfirm}
        closeModal={() => setEmployerLock(null)}
        buttonName="Đồng ý"
        type="delete"
        mess={`${employerLock?.status === 'active' ? 'Khoá' : 'Mở khoá'} nhân viên ${
          employerLock?.username
        } `}
      />
    </div>
  );
}

EmployeeDetail.propTypes = {};

export default EmployeeDetail;
