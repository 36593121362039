/* eslint-disable jsx-a11y/no-autofocus */
import DataTableBasic from '@Components/DataTableBasic';
import React from 'react';
import PropTypes from 'prop-types';
import CancelIcon from '@mui/icons-material/Cancel';
import EditIcon from '@mui/icons-material/Edit';
import { formatNumber } from '@Utils/helpers';

const columns = (editRow, onSave, onRemove) => [
  {
    name: 'STT',
    cell: (_, i) => <div className="">{i}</div>,
    width: '80px',
    center: true,
  },
  {
    name: 'NHÓM QUYỀN',
    cell: (row) => {
      if (!row.isEdit) {
        return <div className="uppercase">{row.name}</div>;
      }
      return (
        <input
          autoFocus
          style={{
            border: 'none',
            borderBottom: '1px solid #EBEAED',
            padding: 5,
            textAlign: 'center',
            outline: 'none',
            width: '100%',
            textTransform: 'uppercase',
          }}
          defaultValue={row.name}
          onBlur={(e) => onSave(row, e.target.value)}
        />
      );
    },
    width: '160px',
    center: true,
  },
  {
    name: 'SỐ NHÂN VIÊN',
    cell: (row) => <div className="">{formatNumber(row?.userCount?.count, '--')}</div>,
    width: '120px',
    center: true,
  },
  {
    name: '',
    cell: (row, i) => (
      <div className="d-flex justify-content-end w-100">
        <div style={{ paddingRight: 20 }} className="d-flex align-items-center">
          <div
            style={{ color: '#2F69B3', fontSize: 14, cursor: 'pointer' }}
            className="d-flex align-items-center mr-2"
            onClick={() => editRow(i)}
            role="button"
            tabIndex="0"
          >
            <span style={{ marginRight: 7 }}>Sửa tên</span>
            <EditIcon style={{ width: 16, height: 16 }} />
          </div>
          <div
            style={{ color: '#ED1C24', fontSize: 14, cursor: 'pointer' }}
            className="d-flex align-items-center"
            role="button"
            tabIndex="0"
            onClick={() => onRemove?.(row)}
          >
            <span style={{ marginRight: 7 }}>Xoá</span>
            <CancelIcon style={{ width: 16, height: 16 }} />
          </div>
        </div>
      </div>
    ),
    center: true,
  },
];

function GroupRole({ data, changeEditRow, onSave, onRemove }) {
  return <DataTableBasic data={data} columns={columns(changeEditRow, onSave, onRemove)} />;
}

GroupRole.propTypes = {
  data: PropTypes.array,
  changeEditRow: PropTypes.func,
  onSave: PropTypes.func,
  onRemove: PropTypes.func,
};

export default GroupRole;
