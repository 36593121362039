import React, { useCallback, useEffect, useState } from 'react';
import { Button, Col, Modal, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';

import PropTypes from 'prop-types';
import FieldInput from '@Views/Client/Recharge/BodyTab/Request/FieldInput';
import { useDispatch } from 'react-redux';
import { hideGlobalLoading, showGlobalLoading } from '@Lib/slices/uiSlice';
import {
  getMemberPackageConfigKeys,
  getMemberPackageConfigs,
  memberPackageSaveConfigs,
} from '@Apis/admin/memberPackage';
import { toastError, toastSuccess } from '@Utils/toastrHelper';
import useCustomQuery from '@Utils/useCustomQuery';
import { AddCircleOutline } from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/Delete';

function ModalConfigPackage({ isOpen, onClose, packageConfig, tradeType }) {
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState('margin_per_contract');
  const [configs, setConfigs] = useState({});

  const { data: dataConfig, refetch } = useCustomQuery(getMemberPackageConfigs, {
    member_package_id: packageConfig.id,
  });
  const { data: dataConfigKeys } = useCustomQuery(getMemberPackageConfigKeys, {
    trade_type: tradeType,
  });

  useEffect(() => {
    if (dataConfig && dataConfig.length > 0) {
      dataConfig.map((conf) => {
        const fee = conf.type;
        setConfigs((pre) => ({
          ...pre,
          [fee]: [...(pre[fee] || []), conf],
        }));
      });
    }
  }, [dataConfig]);
  // console.log('configs state: ', configs);

  const onSubmit = useCallback(async () => {
    dispatch(showGlobalLoading());
    try {
      const arrays = Object.keys(configs || {}).map((fee) => configs[fee]);
      var merged = [].concat.apply([], arrays);

      await memberPackageSaveConfigs({ member_package_id: packageConfig.id, configs: merged });
      toastSuccess('Lưu cấu hình thành công.');
    } catch (e) {
      toastError(e);
    }

    setConfigs({});
    refetch();

    // onClose();
    dispatch(hideGlobalLoading());
  }, [configs, onClose]);

  const onChangeConfig = (fee, index, typeChange, v) => {
    const tmp = { ...configs };
    tmp[fee][index] = {
      ...tmp[fee][index],
      [typeChange]: v,
    };
    setConfigs(tmp);
  };

  const handleAddConfig = (fee) => {
    setConfigs((pre) => ({
      ...pre,
      [fee]: [
        ...(pre[fee] || []),
        {
          type: fee,
          min_price: 0,
          value: 0,
        },
      ],
    }));
  };

  const handleRemoveConfig = (fee, index) => {
    const tmp = { ...configs };

    tmp[fee].splice(index, 1);
    setConfigs(tmp);
  };

  const toggleFee = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  return (
    <Modal size="lg" wrapClassName="modal-config-package" isOpen={isOpen} centered>
      <div className="fw-600 text-align-center" style={{ fontSize: 32 }}>
        Cấu hình gói SP: {packageConfig.name}
      </div>
      <div className="mt-3 config-keys">
        <Row>
          <Col xs="6" sm="4" md="4">
            <Nav tabs vertical>
              {Object.keys(dataConfigKeys?.[0] || {}).map((fee) => {
                const title = dataConfigKeys?.[0][fee];
                return (
                  <NavItem key={fee} className="" style={{ position: 'relative' }}>
                    <NavLink active={activeTab === fee} onClick={() => toggleFee(fee)}>
                      <span>{title}</span>
                    </NavLink>
                  </NavItem>
                );
              })}
            </Nav>
          </Col>
          <Col xs="6" sm="8" md="8" className="config-content">
            <TabContent activeTab={activeTab}>
              {Object.keys(dataConfigKeys?.[0] || {}).map((fee) => {
                const title = dataConfigKeys?.[0][fee];
                return (
                  <TabPane key={fee} tabId={fee}>
                    <h5 className="mb-1">Loại phí: {title}</h5>
                    <div>
                      {configs[fee]?.length > 0 && (
                        <Row className="text-center">
                          <Col md="5">
                            <p>Mốc</p>
                          </Col>
                          <Col md="5">
                            <p>Giá trị</p>
                          </Col>
                          <Col md="2"></Col>
                        </Row>
                      )}

                      {configs[fee]?.map((conf, index) => {
                        return (
                          <Row key={index}>
                            <Col md="5">
                              <FieldInput
                                onChange={(v) => onChangeConfig(fee, index, 'min_price', v)}
                                value={conf.min_price}
                                type="number"
                              />
                            </Col>
                            <Col md="5">
                              <FieldInput
                                onChange={(v) => onChangeConfig(fee, index, 'value', v)}
                                value={conf.value}
                                type="number"
                              />
                            </Col>
                            <Col md="2">
                              <div
                                style={{ lineHeight: '40px' }}
                                onClick={() => handleRemoveConfig(fee, index)}
                                role="button"
                              >
                                <DeleteIcon color="error" fontSize="26px" />
                              </div>
                            </Col>
                          </Row>
                        );
                      })}

                      <Button
                        size="sm"
                        outline
                        color="primary"
                        onClick={() => handleAddConfig(fee)}
                      >
                        <AddCircleOutline color="primay" fontSize="14px" />
                        <span className="font-size-13 ml-25">Thêm</span>
                      </Button>
                    </div>
                  </TabPane>
                );
              })}
            </TabContent>
          </Col>
        </Row>

        <br></br>
      </div>

      <div className="mt-1 mb-2 d-flex justify-content-center" style={{ gap: 16 }}>
        <Button color="primary" style={{ minWidth: 160, height: 48 }} onClick={onSubmit}>
          Lưu
        </Button>
        <Button style={{ minWidth: 160, height: 48 }} onClick={onClose}>
          Hủy
        </Button>
      </div>
    </Modal>
  );
}

ModalConfigPackage.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  packageConfig: PropTypes.object,
  tradeType: PropTypes.string,
};

export default ModalConfigPackage;
