/* eslint-disable react/no-unused-class-component-methods */
import React, { Component } from 'react';
import { toastError, toastSuccess } from '@Utils/toastrHelper';
import PropTypes from 'prop-types';
import { edit, deleteLoanDebt, editDebt } from '@Apis/admin/loan';
import CustomeModal from '@Components/CustomeModal';

class ModalWarningDelete extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isOpen: false,
      dataRemove: undefined,
      type: 'delete',
      mess: 'Bạn muốn xoá kho huy động này?',
    };
  }

  handleDelete = () => {
    const { dataRemove } = this.state;
    const { reload } = this.props;
    deleteLoanDebt({ id: dataRemove.id })
      .then(() => {
        toastSuccess('Xoá kho thành công');
      })
      .catch(toastError)
      .finally(() => {
        this.close();
        reload?.();
      });
  };

  handleSettlement = () => {
    const { reload } = this.props;
    const { dataRemove } = this.state;
    edit({ id: dataRemove.id, is_settlement: true })
      .then(() => {
        toastSuccess('Cập nhật thành công');
      })
      .catch(toastError)
      .finally(() => {
        this.close();
        reload?.();
      });
  };

  handlePaid = () => {
    const { reload } = this.props;
    const { dataRemove } = this.state;
    editDebt({ id: dataRemove.id, is_paid: true })
      .then(() => {
        toastSuccess('Cập nhật thành công');
      })
      .catch(toastError)
      .finally(() => {
        this.close();
        reload?.();
      });
  };

  handleConfirm = () => {
    const { type, mess } = this.state;
    if (type === 'delete') {
      return this.handleDelete();
    }
    if (mess === 'Bạn muốn đổi trạng thái sang đã thanh toán?') {
      return this.handlePaid();
    }
    return this.handleSettlement();
  };

  close = () => {
    this.setState({ isOpen: false, dataRemove: undefined, type: 'delete' });
  };

  open = (dataRemove, type = 'delete', mess = 'Bạn muốn xoá kho huy động này?') => {
    this.setState({ isOpen: true, dataRemove, type, mess });
  };

  render() {
    const { isLoading, isOpen, type, mess } = this.state;
    if (!isOpen) {
      return null;
    }
    return (
      <CustomeModal
        isLoading={isLoading}
        isOpen={isOpen}
        confirm={this.handleConfirm}
        closeModal={this.close}
        buttonName={type === 'delete' ? 'Xoá' : 'Cập nhật'}
        type={type}
        mess={mess}
      />
    );
  }
}

ModalWarningDelete.propTypes = {
  reload: PropTypes.func,
};

export default ModalWarningDelete;
