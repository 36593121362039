import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import SelectInput from '@Components/Input/SelectInput';
import useCustomQuery from '@Utils/useCustomQuery';
import { customerBanks } from '@Apis/admin/customer';
import { listDeposit } from '@Apis/admin/paymentRequest';

function BankReceive({
  labelName,
  labelCol,
  inputCol,
  name,
  register,
  setValue,
  errors,
  watch,
  type,
}) {
  const customerId = watch('customer_id');
  const [options, setOptions] = useState([
    {
      label: 'Chọn tài khoản',
      value: '',
    },
  ]);

  const { data } = useCustomQuery(
    type === 'Deposit' ? listDeposit : customerBanks,
    type === 'Deposit' ? {} : { id: customerId },
    false,
    type === 'Deposit' ? undefined : { enabled: !!customerId },
  );

  useEffect(() => {
    if (data) {
      const valueDefault = data.find((e) => e.is_default);
      if (valueDefault) {
        setValue(name, valueDefault.id);
      }
      let optionsCustomer = [];
      if (data && data.length > 0) {
        optionsCustomer = data.map((item) => ({
          label: `${item.bank?.name || item.bank_id} - ${item.account_number}`,
          value: item.id,
        }));
      }
      setOptions([...options, ...optionsCustomer]);
    } else {
      setOptions([
        {
          label: 'Chọn tài khoản',
          value: '',
        },
      ]);
    }
  }, [data]);

  const value = watch(name);

  return (
    <SelectInput
      inputClassName="uppercase"
      labelCol={labelCol}
      inputCol={inputCol}
      options={options}
      errors={errors}
      name={name}
      register={register}
      labelName={labelName}
      labelClassName="font-size-14"
      setValue={setValue}
      value={value}
    />
  );
}

BankReceive.propTypes = {
  labelName: PropTypes.string,
  name: PropTypes.string,
  inputCol: PropTypes.string,
  labelCol: PropTypes.string,
  type: PropTypes.string,
  register: PropTypes.object,
  setValue: PropTypes.func,
  errors: PropTypes.object,
  watch: PropTypes.func,
};

export default BankReceive;
