/* eslint-disable no-confusing-arrow */
import React from 'react';
import { getQuantityMatching, renderType } from '@Utils/tradeHelpers';
import { formatNumber, formatVND } from '@Utils/helpers';
import dayjs from 'dayjs';

export const columns = () => {
  return [
    {
      name: 'ID',
      cell: (row) => <div className="word-break-normal">{row.id}</div>,
      center: true,
      width: '60px',
      sortable: true,
      sortField: 'id',
    },
    {
      name: 'Loại',
      cell: (row) => renderType(row.type),
      center: true,
      width: '110px',
    },
    {
      name: 'Mã CK',
      cell: (row) => row.stock_id,
      center: true,
    },
    {
      name: 'Giá',
      cell: (row) => (
        <div className="w-100 text-align-end">
          {row.type === 'buy' ? formatNumber(row.dca_purchase_price) : formatNumber(row.dca_sell_price)}
        </div>
      ),
      width: '140px',
      sortable: true,
      sortField: 'dca_price',
    },
    {
      name: 'KL',
      cell: (row) => <div className="w-100 text-align-end">{formatNumber(row.quantity)}</div>,
      sortable: true,
      sortField: 'quantity',
    },
    {
      name: 'KL Khớp',
      cell: (row) => (
        <div className="w-100 text-align-end">{formatNumber(getQuantityMatching(row))}</div>
      ),
      sortable: true,
      sortField: 'final_quantity',
    },
    {
      name: 'Ngày GD',
      center: true,
      cell: (row) => dayjs.unix(row.created_at).format('DD/MM/YYYY'),
      sortable: true,
      sortField: 'created_at',
    },
  ];
};
